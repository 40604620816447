export const processReorderUpsellClick = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const { productID = '', productName: prodName = '', category: productCategory = '' } = data
  const dataToReturn = {
    productId: productID,
    productName: prodName,
    category: productCategory
  }
  return dataToReturn
}

export const processReorderATC = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const { productInfoList = [] } = data
  const ids = productInfoList.map((product) => {
    return product.productId
  }).join(',')

  const productNames = productInfoList.map((product) => {
    return product.productName
  }).join(',')

  const amounts = productInfoList.map((product) => {
    return product.amount
  }).join(',')

  const categories = productInfoList.map((product) => {
    return product.category
  }).join(',')

  const dataToReturn = {
    productId: ids,
    productName: productNames,
    amount: amounts,
    category: categories

  }
  return dataToReturn
}