import styled from 'styled-components'

export const CategoryProductCardCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-8);

  .product-card-list {
    display: flex;
    flex-direction: row;
    gap: var(--dls-size-4);
    overflow-x: auto;
    :first-child {
      padding-left: var(--dls-size-16);
    }
    :last-child {
      padding-right: var(--dls-size-16);
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .card-container {
      min-width: 40%;
      width: 150px;
    }
  }

  @media screen and (min-width: 768px) {
    .product-card-list {
      gap: var(--dls-size-12);
    }
  }
`
