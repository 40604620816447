import styled from 'styled-components'

export const PillsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-16);

  .pills-wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--dls-size-8);
    flex-wrap: wrap;
    padding: 0 var(--dls-size-16);
  }

  .divider {
    height: var(--dls-size-8);
    background-color: var(--dls-backgound-color-100);
  }
  .titleText {
    padding-left: var(--dls-size-16);
  }
  @media (min-width: 768px) {
    .titleText {
      padding-left: var(--dls-size-32);
    }
    .divider {
      display: none;
    }
    .pills-wrapper {
      display: flex;
      flex-direction: row;
      gap: var(--dls-size-8);
      flex-wrap: wrap;
      padding: 0 var(--dls-size-32);
    }
  }
`
