import React from 'react'
import Typography from '../../../../common/Typography/Typography'
import {CustomerSayWrapper} from './CustomerSay.styles'
import OptimizedImage from '../../../../common/OptimizedImage'
import {ICustomerSayProps} from './CustomerSay.types'

const CustomerSay = (props: ICustomerSayProps) => {
  const {
    title = '',
    summary = '',
    subText = '',
    tags = [],
    verifiedImage = '',
  } = props || {}

  return (
    <CustomerSayWrapper>
      <div className="customer-say">
        <div className="verified-image-container">
          <OptimizedImage
            source={verifiedImage}
            aspectWidth="14"
            aspectHeight="14"
            desktopViewWidth="SMALL"
            mobileViewWidth="SMALL"
            altText="verified"
            loading="lazy"
            customClassName="verified-image"
          />
          <Typography
            text={subText}
            variant="x-small"
            customClassName="customer-say-subText"
          />
        </div>

        <Typography
          text={title}
          variant="subheading-bold"
          customClassName="customer-say-title"
        />

        <Typography
          text={summary}
          variant="body-base-regular"
          customClassName="customer-say-description"
        />

        <div className="customer-say-tags-container">
          {tags.map((tag, index) => (
            <div key={`custom-tag-${index}`} className="tag-link">
              <Typography
                text={tag?.label}
                variant="tag-text"
                customClassName="customer-tag"
              />
            </div>
          ))}
        </div>
      </div>
    </CustomerSayWrapper>
  )
}

export default CustomerSay
