import styled from 'styled-components'

export const RatingsCompositionWrapper = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
  .ratings_overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .rating_container {
      margin-bottom: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      span {
        color: var(--category-primary-color);
        font-weight: 700;
        font-size: 38px;
        @media (max-width: 768px) {
          font-size: 28px;
        }
      }
      .star {
        margin-bottom: 5px;
        margin-left: 5px;
        @media (max-width: 768px) {
          margin-bottom: 0px;
          margin-left: 0px;
        }
      }
    }
    .total_reviews {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      span {
        line-height: 31.2px;
        width: 100%;
        color: #6c6c6c;
        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 14.4px;
          text-align: center;
          color: #9e9e9e;
        }
      }
    }
  }
`
