export const checkIfDataExpired = () => {
  const expirationDuration = 300000 // 5 Minutes
  const nudgeData = JSON.parse(window.localStorage.getItem('nudgeData') || '{}')
  const currentTime = new Date().getTime()
  const prevAccepted = nudgeData.timestamp
  const notAccepted = prevAccepted === undefined
  const prevAcceptedExpired =
    prevAccepted !== undefined &&
    currentTime - prevAccepted > expirationDuration

  return notAccepted || prevAcceptedExpired
}
