import styled from 'styled-components'

export const AccordionItemContainer = styled.div`
  padding: var(--dls-size-16) 0px;

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .title-text {
    margin-right: var(--dls-size-24);
    color: var(--dls-primary-text-colors);
  }

  .content-text {
    margin-top: 0;
    color: var(--dls-secondary-text-colors);
    max-height: 0;
    transition: max-height 0.3s, margin-top 0.3s;
    overflow: hidden;
  }

  &[data-isopen='true'] {
    .content-text {
      margin-top: var(--dls-size-16);
      max-height: 1000px; /* Using a large value to accommodate any large content, doesn't mess the UI */
    }
  }
`
