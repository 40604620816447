export interface IProduct {
  id: number
  isFree: boolean
  isOutOfStock: boolean
  item: Item
  quantity: number
  upSellData: UpSellData
}
export interface Item {
  category: string
  currentPrice: number
  discountPercentage: number
  id: number
  image: string
  isMiniCard: boolean
  isRx: boolean
  isVirtual: boolean
  name: string
  originalPrice: number
  outOfStock: boolean
  productFor: string
  productURL: string
  productUrl: string
  rating: string
  sku: string
  slugUrl: string
  types?: string[] | null
}
export interface UpSellData {
  fact: string
  items?: string[] | null
  label: string
  modalTitle: string
  offerTagLabel: string
}

export interface IProductForATC {
  [key: string]: number
}

export const getProductsForATC = (products: IProduct[]) => {
  const finalProducts: IProductForATC = {}
  products.forEach(({quantity, item: {productURL = ''}}) => {
    finalProducts[productURL] = quantity
  })
  return finalProducts
}
