import styled from 'styled-components'

export const KitBreakdownContainer = styled.div`
  width: 100%;

  .generic-slider {
    gap: 0;
  }

  .kit-modal {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 7;
    transition: opacity 0.2s ease-out;
  }

  .kit-modal-content {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    height: 60vh;
    background-color: var(--dls-white-color);
    border-top-left-radius: var(--dls-size-16);
    border-top-right-radius: var(--dls-size-16);
    position: relative;
    overflow: hidden;
    transition: transform 0.2s ease-out;
  }

  .scroll-container {
    overflow-y: scroll;
    height: 100%;
  }

  .modal-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .kit-modal-data {
    padding: var(--dls-size-16) var(--dls-size-16) var(--dls-size-88)
      var(--dls-size-16);
  }

  .rating-and-price-details {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .rating-and-price-details-item {
    color: var(--dls-secondary-text-colors);
  }

  .ratings {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    line-height: 1;
  }

  .rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .divider {
    width: 1px;
    height: 6px;
    background-color: var(--dls-divider-light-color);
    margin: 0 var(--dls-size-8);
  }

  .kit-modal-product-name {
    margin-bottom: var(--dls-size-16);
  }

  .description-text {
    color: var(--dls-secondary-text-colors);
  }

  .how-to-use-title {
    margin: var(--dls-size-16) 0;
  }

  .okay-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--dls-white-color);
    padding: var(--dls-size-16);
    box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.1);
  }

  .okay-button {
    outline: 0;
    border: 0;
    background-color: var(--dls-brand-primary-color-500);
    color: var(--dls-white-color);
    width: 100%;
    border-radius: var(--dls-size-8);
    padding: 14px 0;
  }

  .close-button-container {
    height: var(--dls-size-32);
    width: var(--dls-size-32);
    border-radius: 50%;
    font-size: 24px;
    position: absolute;
    right: 3%;
    top: 3%;
    background-color: var(--dls-white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }

  &[data-ismodalopen='true'] {
    .kit-modal {
      opacity: 1;
    }

    .kit-modal-content {
      transform: translateY(0%);
    }
  }

  &[data-ismodalopen='false'] {
    .kit-modal {
      opacity: 0;
    }

    .kit-modal-content {
      transform: translateY(100%);
    }
  }
`
