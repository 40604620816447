// export const getFormattedReviews = (review) => {
//   const {images = []} = review

//   return images.map((image, index) => {
//     const img = image.image
//     const alt = image.altText
//     return {
//       thumbnail: img,
//       imageUrl: img,
//       altText: alt,
//       review: {
//         ...review,
//         id: `${review.id}-inner-id-${index}}`,
//       },
//     }
//   })
// }

export const getFormattedReviews = (review) => {
  const reviewImages = review.images?.map((image) => ({
    ...image,
    reviewId: review.id,
  }))
  const reviewMap = {
    [review.id]: {
      ...review,
    },
  }

  return {reviewImages, reviewMap}
}
