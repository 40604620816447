import styled from 'styled-components'

export const ProductListingCardContainer = styled.a<{outOfStock: boolean}>`
  display: flex;
  justify-content: flex-start;
  gap: var(--dls-size-8);
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  border: var(--dls-size-1) solid var(--dls-divider-light-color);
  border-radius: var(--dls-size-12);
  padding: var(--dls-size-8);
  background-color: white;

  .img-container {
    border: var(--dls-size-1) solid var(--dls-divider-light-color);
    overflow: hidden;
    position: relative;
    border-radius: var(--dls-size-8);
    width: var(--dls-size-88);
    height: var(--dls-size-88);
    aspect-ratio: 1;
    flex-shrink: 0;
    opacity: ${(props) => (props.outOfStock ? 0.4 : 1)};
  }
  .product-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
  }
  .content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--dls-size-4);
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-4);
  }
  .product-for-text {
    color: var(--dls-brand-primary-color-400);
  }
  .product-name-container {
    color: var(--brand-primary-text-color);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-space;
  }
  .pricing-and-cta-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--dls-size-6);
  }
  .pricing-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: var(--dls-size-4);
  }
  .discounted-price-text {
    text-decoration: line-through;
    color: var(--dls-disabled-text-colors);
  }

  .qty-button-container {
    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-brand-primary-color-500);
    height: var(--dls-size-32);
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--dls-brand-primary-color-100);
    min-width: var(--dls-size-88);
  }

  .qty-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: 100%;
    flex: 1;
    display: flex;
  }

  .qty-btn:disabled {
    opacity: 0.3;
  }
  .remove-btn {
    justify-content: flex-start;
    align-items: center;
    padding-left: var(--dls-size-8);
  }
  .qty-label-container {
    flex-shrink: 1;
    padding: 0 var(--dls-size-8);
  }
  .qty-label {
    color: var(--dls-brand-primary-color-500);
  }
  .add-btn {
    justify-content: flex-end;
    align-items: center;
    padding-right: var(--dls-size-8);
  }
  .atc-btn {
    margin-top: auto;
    border-radius: var(--dls-size-8);
    outline: none;
    border: 1px solid var(--dls-brand-primary-color-500);
    background-color: var(--brand-primary-white-color);
    white-space: nowrap;
    transition: all 0.5s ease;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--dls-brand-primary-color-500);
    overflow: hidden;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;
    padding: var(--dls-size-8);
    height: var(--dls-size-32);
    min-width: var(--dls-size-88);
  }
  .atc-label {
    color: var(--dls-brand-primary-color-500);
  }
  @media (min-width: 768px) {
    .atc-label {
      font-size: 14px;
    }
  }
`
