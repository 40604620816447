import {IColor} from '../types'

export const Color: IColor = {
  PRIMARY: {
    500: '#2F815C',
    400: '#599A7D',
    300: '#82B39D',
    200: '#ACCDBE',
    100: '#D5E6DE',
  },
  SECONDARY: {
    500: '#FEEFD9',
    400: '#FEF2E1',
    300: '#FEF5E8',
    200: '#FFF9F0',
    100: '#FFFCF7',
  },
  NUTRITION: {
    500: '#2F815C',
    400: '#599A7D',
    300: '#82B39D',
    200: '#ACCDBE',
    100: '#D5E6DE',
  },
  BATH_AND_BODY: {
    500: '#89B0EA',
    400: '#A1C0EE',
    300: '#B8D0F2',
    200: '#D0DFF7',
    100: '#E7EFFB',
  },
  PEEPA_PIG: {
    500: ' #FF94AE',
    400: '#FFA9BE',
    300: '#FFBFCE',
    200: '#FFD4DF',
    100: '#FFEAEF',
  },
  ORAL_CARE: {
    500: '#FA6863',
    400: '#FB8682',
    300: '#FCA4A1',
    200: '#FDC3C1',
    100: '#FEE1E0',
  },
  HYGIENE: {
    500: '#588ECE',
    400: '#79A5D8',
    300: '#9BBBE2',
    200: '#BCD2EB',
    100: '#DEE8F5',
  },
  SUCCESS: {
    500: '#379F8E',
    400: '#5FB2A5',
    300: '#87C5BB',
    200: '#AFD9D2',
    100: '#D7ECE8',
  },
  WARNING: {
    500: '#FF9800',
    400: '#FFAD33',
    300: '#FFC166',
    200: '#FFD699',
    100: '#FFF6E9',
  },
  ERROR: {
    500: '#DF555B',
    400: '#E5777C',
    300: '#EC999D',
    200: '#F2BBBD',
    100: '#F9DDDE',
  },
  PILLS: {
    BACKGROUND_SELECTED: '#2F815C',
    BACKGROUND_UNSELECTED: '#EDFCF5',
    BACKGROUND_DISABLED: '#E3E3E3',
    TEXT_ICON_SELECTED: '#FFFFFF',
    TEXT_ICON_UNSELECTED: '#2F815C',
    TEXT_ICON_DISABLED: '#9E9E9E',
  },
  BACKGROUND: {
    500: '#FFFFFF',
    400: '#FFFFFF',
    300: '#FFFFFF',
    200: '#FFFFFF',
    100: '#F5F5F5',
  },
  TEXT: {
    BRAND_TEXT: '#2F815C',
    PRIMARY_TEXT: '#212121',
    SECONDARY_TEXT: '#666666',
    DISABLED_TEXT: '#9E9E9E',
  },
  NEUTRALS: {
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    TEXT_FIELD_STROKE: '#C4C4C4',
  },
  DIVIDERS: {
    DARK: '#2E2E2E',
    LIGHT: '#E3E3E3',
  },
  RATING_STAR: {
    LIGHT: '#F6CA4C',
    DARK: '#F6CA4C',
  },
  TAG: {
    HIGHLIGHTED_TAG_BACKGROUND: '#FFEC6B',
    HIGHLIGHTED_TAG_TEXT: '#121212',
    DISCOUNT_TAG_BACKGROUND: '#FFEC6B',
    DISCOUNT_TAG_TEXT: '#379F8E',
    DEFAULT_TAG_BACKGROUND: '#F2F2E8',
    DEFAULT_TAG_TEXT: '#2F815C',
  },
}
