import isEmpty from 'lodash/isEmpty'

import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import {useLocalisationContext} from '../../../Context/Localisation/Localisation'
import OptimizedImage from '../../../common/OptimizedImage'
import Typography from '../../../common/Typography/Typography'
import {SectionHeading} from '../SectionHeading'
import {Card, CardContainer, ImageContainer, StyledContainer} from './styles'

/**
 *
 * @param {Object} param
 * @param {Array<{title:string, description:string, image:string}>} param.state
 * @returns
 */
function HowItWorksPDP(props) {
  const {
    heading = '',
    steps = [],
    headingsFor = {},
    showStepText = true,
  } = props.state || {}
  const {STEP_TEXT = ''} = useLocalisationContext()
  const {cards: cardsHeadingLevel = 3} = headingsFor
  if (isEmpty(props.state) || isEmpty(steps)) {
    return null
  }

  return (
    <StyledContainer data-scrollSection="howItWorks">
      <SectionHeading state={{heading}} />
      <CardContainer>
        {steps.map((item, index) => {
          const {title = '', image = '', description = ''} = item || {}
          return (
            <Card key={index}>
              {!!item.image && (
                <ImageContainer>
                  <OptimizedImage
                    source={image}
                    aspectWidth="250"
                    aspectHeight="250"
                    desktopViewWidth="THIRD"
                    mobileViewWidth="THIRD"
                    altText={title}
                    loading="lazy"
                    customClassName="how-it-works-image"
                  />
                  {showStepText && (
                    <span>
                      {STEP_TEXT} {index + 1}
                    </span>
                  )}
                </ImageContainer>
              )}
              <Typography
                text={title}
                variant="body-base-bold"
                customClassName="how-it-works__card-title"
                headingLevel={cardsHeadingLevel}
              />
              <div className="how-it-works__card-description">
                <Typography text={description} variant="body-base-regular" />
              </div>
            </Card>
          )
        })}
      </CardContainer>
    </StyledContainer>
  )
}

export default withCustomErrorBoundary(HowItWorksPDP, 'HowItWorksPDP')
