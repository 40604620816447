import React from 'react'
import {CalloutWithImageContainer} from './CalloutWithImage.styles'
import {ICalloutWithImageProps} from './CalloutWithImage.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'

function CalloutWithImage(props: ICalloutWithImageProps) {
  const {id, type, widgetData, layout} = props
  const {image = '', text = ''} = widgetData || {}

  if (!text) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <CalloutWithImageContainer>
        {!!image && (
          <div className="callout-image-container">
            <OptimizedImage source={image} aspectHeight="42" aspectWidth="42" />
          </div>
        )}
        <Typography
          variant={TypographyVariants.SUBHEADING_BOLD}
          customClassName="callout-text"
        >
          {text}
        </Typography>
      </CalloutWithImageContainer>
    </BaseWidgetComponent>
  )
}

export default CalloutWithImage
