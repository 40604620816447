import React from 'react'
import { LoadingState } from '../../shared/src/ComponentsV2/common'
import { GenericStyledLoading } from './GenericLoading.styles'
import Responsive from '../../shared/src/ComponentsV2/HOC/Responsive'

function GenericSkeleton({ isMobile = false }) {
    return (
        <>
            {
                isMobile ? (
                    <GenericStyledLoading>
                        <LoadingState cardHeight={760} />
                    </GenericStyledLoading >
                ) : (
                    <GenericStyledLoading>
                        <LoadingState cardHeight={1080} />
                    </GenericStyledLoading>
                )
            }
        </>

    )
}
export default Responsive(GenericSkeleton)