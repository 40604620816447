import noop from 'lodash/noop'
import {useMemo} from 'react'

import {getOptimizedImageKitLink} from '../../../helpers/getOptimizedImageKitLink'
import Responsive from '../../HOC/Responsive'

function OptimizedImage(props) {
  const {
    source = '',
    aspectWidth = '',
    aspectHeight = '',
    desktopViewWidth = 'FULL',
    mobileViewWidth = 'FULL',
    altText = '',
    loading = 'lazy',
    customClassName = '',
    isMobile = false,
    onImageClick = noop,
    screenWidth,
    ...rest
  } = props

  const modifiedSrc = useMemo(() => {
    return getOptimizedImageKitLink(
      source,
      desktopViewWidth,
      mobileViewWidth,
      isMobile
    )
  }, [source, desktopViewWidth, mobileViewWidth, isMobile])

  if (!modifiedSrc) return null

  return (
    <img
      src={modifiedSrc}
      className={customClassName}
      height={aspectHeight}
      width={aspectWidth}
      loading={loading}
      alt={altText}
      onClick={onImageClick}
      decoding="async"
      {...rest}
    />
  )
}

export default Responsive(OptimizedImage)
