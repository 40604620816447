import styled from 'styled-components'

export const FomoCardContainer = styled.div`
  margin: 0 10px;
  background-color: var(--brand-primary-white-color);
  width: 100%;
  border-radius: 20px;
  padding: 20px 16px;
  max-width: 400px;

  .title {
    font-size: 18px;
    font-weight: var(--brand-font-weight-dark);
    line-height: 1;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    margin-bottom: 24px;
    line-height: 1.5;

    b {
      color: var(--brand-primary-teal-color);
    }
  }

  .info-section-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .info-section {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    :not(:last-child) {
      border-right: 1px solid var(--brand-primary-silver-color);
    }

    .text {
      font-size: 40px;
      font-weight: var(--brand-font-weight-dark);
      margin-bottom: 16px;
      color: var(--brand-primary-blue-color);
    }

    .image {
      width: 65%;
      height: auto;
      margin-bottom: 12px;
    }

    .label {
      font-size: 12px;
      line-height: 1.5;
      font-weight: var(--brand-font-weight-medium);
      width: 70%;
    }
  }

  .button {
    outline: 0;
    border: 0;
    font-family: var(---brand-font-family-text);
    font-size: 14px;
    font-weight: var(--brand-font-weight-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 0;
    background-color: transparent;
  }

  .primary-cta {
    border-radius: 12px;
    background-color: var(--brand-primary-color);
    color: var(--brand-primary-white-color);
    margin-bottom: 4px;
  }

  .secondary-cta {
    color: var(--brand-primary-color);
  }
`
