import noop from 'lodash/noop'
import React from 'react'

import {ParentComponentProps} from './ChildDetails.interface'

const ParentSelector = (props: ParentComponentProps) => {
  const {parentOptions, selectedParent} = props.state || {}
  const {handleParentChange = noop} = props.actions || {}

  if (!Array.isArray(parentOptions) || parentOptions.length === 0) {
    return null
  }

  return (
    <>
      {parentOptions.map((parent: {id: any; title: any}) => (
        <label className="parentSelector" key={parent.id}>
          <input
            type="radio"
            name="parent"
            className="parentOption"
            value={parent.id}
            checked={selectedParent === parent.id}
            onChange={handleParentChange}
            required
          />
          <span className="sm-text">{parent.title}</span>
        </label>
      ))}
    </>
  )
}

export default ParentSelector
