import {COUNTRY_CODE} from './../../../utils/countryCode'
export const processUserDataFetched = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    city,
    email,
    firstName,
    lastName,
    repeatUser,
    phoneNumber,
    id,
    emailRegistered,
  } = data
  const dataToReturn = {
    Phone: `${COUNTRY_CODE}${phoneNumber}`, // Phone (with the country code)
    'Phone No': phoneNumber, // Phone (with the country code)
    USER_ID: id, // String or number
    Identity: id, // String or number
    // optional fields. controls whether the user will be sent email, push etc.
    'MSG-email': true, // Disable email notifications
    'MSG-push': true, // Enable push notifications
    'MSG-sms': true, // Enable sms notifications
    'MSG-whatsapp': true, // Enable WhatsApp notifications
    user: repeatUser ? 'repeat' : 'new',
    ...(emailRegistered && {
      Email: email,
      'First Name': firstName,
      'Last Name': lastName,
      Name: `${firstName} ${lastName}`,
    }),
  }

  return dataToReturn
}
export const processLogin = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    source: window.location.href || '',
    phoneNumber: dLayer.user.phoneNumber || '',
    'Verification Source': data.verificationSource || 'OTP',
    'Truecaller Seen': dLayer.truecallerSeen ? 'Yes' : 'No',
  }

  return dataToReturn
}
export const processSignup = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    source: window.location.href || '',
    phoneNumber: dLayer.user.phoneNumber || '',
    'Verification Source': data.verificationSource || 'OTP',
    'Truecaller Seen': dLayer.truecallerSeen ? 'Yes' : 'No',
  }

  return dataToReturn
}

export const processOtp = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {type = '', status = '', a = '', p = ''} = data || {}
  const dataToReturn = {
    source: window.location.href || '',
    type,
    status,
    a,
    p,
  }

  return dataToReturn
}

export const proccessContactUsViewed = (
  data: any,
  _: typeof window.customDataLayer
) => {
  const dataToReturn = {
    isLoggedIn: data.isLoggedIn,
  }
  return dataToReturn
}

export const processLoginPageRedirectionButtonClicked = (data: any) => {
  const {link} = data
  const dataToReturn = {
    'Redirected To': link,
  }
  return dataToReturn
}
