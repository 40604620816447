import noop from 'lodash/noop'
import {SCREEN_PATH_NAMES} from './constants/screenPathNames'
import {logError} from './logger'

export const onMobileNumberSubmit = async (args) => {
  const {getMobileDetails, setStatus, phoneNumber, setAuthDetails} = args

  try {
    const response = await getMobileDetails(phoneNumber)

    if (!response.data) return new Error('Data is not found. Try again!')
    // const {exists, emailRegistered} = response.data
    let isExistingUser = response.data.exists

    // if (isExistingUser && emailRegistered !== undefined) {
    //   isExistingUser = emailRegistered
    // }
    setAuthDetails({phoneNumber, isExistingUser})
    if (isExistingUser) {
      setStatus('otp')
    } else {
      setStatus('signup')
    }
  } catch (err) {}
}

export const onEmailSubmit = async (args) => {
  const {email, accountExist, setStatus, setAuthDetails} = args

  try {
    const response = await accountExist(email)
    if (!response.data) return new Error('Data is not found. Try again!')
    const {exists, emailRegistered, firstName, lastName} = response.data || {}
    let isExistingUser = exists

    if (isExistingUser) {
      isExistingUser = emailRegistered
    }
    if (isExistingUser) {
      setAuthDetails({
        firstName,
        lastName,
        email,
        isExistingUser,
      })
      setStatus('otp')
    } else {
      const currentLocation =
        typeof window !== 'undefined' ? window.location.href : ''
      currentLocation.includes(SCREEN_PATH_NAMES.CHECKOUT)
        ? setStatus('otp')
        : setStatus('signup')
    }
  } catch (err) {
    logError(err)
  }
}

export const onOtpSubmit = async (args) => {
  const {
    verifyOtp,
    setStatus,
    otp,
    mobileNumber,
    toggleLogin,
    otpError,
    resetAuthDetails,
    handleLogin,
  } = args

  try {
    const response = await verifyOtp(mobileNumber, otp, noop, otpError)

    if (!response.data) return new Error('Data is not found. Try again!')

    const otpValid = response.data.isOTPValid

    if (otpValid) {
      toggleLogin()
      resetAuthDetails()
      if (handleLogin) {
        handleLogin()
      }
    } else {
      setStatus('wrong_otp')
    }
  } catch (err) {}
}

export const onVerifyOTPWithDetailsSubmit = async (args) => {
  const {
    setStatus,
    userDetails,
    verifyOTPWithDetails,
    toggleLogin,
    otpError,
    handleLogin,
  } = args
  const {phoneNumber, otp, firstName, lastName, email} = userDetails

  try {
    const response = await verifyOTPWithDetails(
      phoneNumber,
      otp,
      firstName,
      lastName,
      email,
      noop,
      otpError
    )

    const otpValid = response.isOTPValid

    if (otpValid) {
      toggleLogin()
      if (handleLogin) {
        handleLogin()
      }
    } else {
      setStatus('wrong_otp')
    }
    return otpValid
  } catch (err) {}
}

export const onVerifyPassword = async (args) => {
  const {
    setStatus,
    email,
    password,
    firstName,
    lastName,
    verifyPassword,
    toggleLogin,
  } = args || {}

  try {
    const response = await verifyPassword(email, password, firstName, lastName)

    const emailValid = response.data.isEmailValid

    if (emailValid) {
      toggleLogin()
    } else {
      setStatus('wrong_otp')
    }
    return emailValid
  } catch (err) {
    logError(err)
  }
}
