import styled from 'styled-components'

export const StyledModalBackground = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;

  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
`
