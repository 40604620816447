import styled from 'styled-components'

export const BadgeContainer = styled.div`
  align-items: start;
  border-radius: 50px;
  background: var(--dls-divider-light-color);
  align-self: stretch;
  position: relative;
  display: flex;
  gap: var(--dls-size-8);
  color: var(--dls-black-color);
  justify-content: start;
  margin: auto 0;
  padding: var(--dls-size-8) 10px var(--dls-size-8) 20px;

  .badgeIcon {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 28px;
    position: absolute;
    z-index: 0;
    left: -5px;
    top: 55%;
    transform: translateY(-50%);
    height: 28px;
  }

  .badgeContent {
    z-index: 1;
    margin-left: 10px;
  }
`

export const BackGroundGridImg = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`

export const CardContainer = styled.section`
  border-radius: 0;
  display: flex;
  max-width: 328px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  z-index: 9;
  position: relative;
  max-height: 360px;
  padding-bottom: 77px;

  @media (min-width: 768px) {
    min-width: 400px;
    max-height: initial;
    padding-bottom: 40px;
    overflow-y: inherit;
  }

  .rounded {
    width: var(--dls-size-56);
    height: 60px;
    object-fit: cover;
  }

  .small {
    width: var(--dls-size-24);
  }
  .title {
    color: var(--dls-black-color);
    text-align: center;
    margin-top: 34px;
    margin-bottom: 20px;
  }

  .title-bottom {
    margin-bottom: 20px;
  }

  .subtitle {
    color: var(--dls-brand-primary-color-500);
    text-align: center;
    margin-top: var(--dls-size-40);
    margin-bottom: 10px;
  }

  .image-group {
    display: flex;
    align-items: center;
    gap: var(--dls-size-8);
    justify-content: center;
    padding-top: 35px;
  }

  .badge-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .badge-container {
    align-items: start;
    border-radius: 813px;
    background: var(--dls-divider-light-color);
    align-self: stretch;
    position: relative;
    display: flex;
    gap: var(--dls-size-8);
    color: var(--dls-black-color);
    justify-content: start;
    margin: auto 0;
    padding: var(--dls-size-8) 10px var(--dls-size-8) 20px;

    .badgeIcon {
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center;
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: 0;
      left: -5px;
      top: 55%;
      transform: translateY(-50%);
    }

    .badgeContent {
      z-index: 1;
      margin-left: 10px;
    }
  }

  .styled-button {
    width: 100%;
    border-radius: var(--dls-size-8);
    background: var(--dls-brand-primary-color-500);
    min-height: var(--dls-size-48);
    color: var(--dls-white-color);
    padding: 0 var(--dls-size-16);
    border: none;
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid var(--dls-white-color);
      outline-offset: 2px;
    }

    @media (max-width: 768px) {
      position: fixed;
      bottom: 20px;
      left: 0;
      right: 0;
      z-index: 10;
      max-width: 90%;
      margin: auto;
    }
  }
`
