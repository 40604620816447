import styled from 'styled-components'

export const StyledDoctorDetails = styled.div`
  .detail-card-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: var(--dls-size-16);
    cursor: pointer;
    color: var(--dls-primary-text-colors);
  }

  .left-container {
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--dls-size-12);
    .avatar-wrapper {
      flex: 1;
      border-radius: var(--dls-size-8);
      border: 0.5px solid var(--dls-divider-light-color);
      overflow: hidden;
      width: 100%;
      position: relative;
      .avatar-icon {
        width: 100%;
        height: 130px;
        @media (min-width: 768px) {
          height: 160px;
        }
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 50%;
        padding: var(--dls-size-8);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.02) 4.7%,
          rgba(0, 0, 0, 0.04) 8.9%,
          rgba(0, 0, 0, 0.07) 12.8%,
          rgba(0, 0, 0, 0.1) 16.56%,
          rgba(0, 0, 0, 0.14) 20.37%,
          rgba(0, 0, 0, 0.18) 24.4%,
          rgba(0, 0, 0, 0.23) 28.83%,
          rgba(0, 0, 0, 0.29) 33.84%,
          rgba(0, 0, 0, 0.35) 39.6%,
          rgba(0, 0, 0, 0.43) 46.3%,
          rgba(0, 0, 0, 0.52) 54.1%,
          rgba(0, 0, 0, 0.62) 63.2%,
          rgba(0, 0, 0, 0.73) 73.76%,
          rgba(0, 0, 0, 0.86) 85.97%,
          #000 100%
        );
        .name {
          color: var(--brand-primary-white-color);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
        .stats-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: var(--dls-size-4);
          row-gap: var(--dls-size-4);
          padding-top: var(--dls-size-4);
          .stat-container {
            border-radius: var(--dls-size-4);
            padding: var(--dls-size-2) var(--dls-size-4);
            background-color: var(--dls-backgound-color-100);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--dls-size-1);
            .stat-icon {
              height: var(--dls-size-12);
              width: var(--dls-size-12);
              object-fit: cover;
            }
          }
        }
      }
      .status-wrapper {
        position: absolute;
        top: var(--dls-size-4);
        left: var(--dls-size-4);
        border-radius: var(--dls-size-16);
        padding: var(--dls-size-2) var(--dls-size-4);
        display: flex;

        &[data-status='ACTIVE'] {
          color: var(--brand-primary-white-color);
          background-color: #198775;
        }

        &[data-status='AWAY'] {
          color: var(--brand-primary-white-color);
          background-color: var(--dls-background-disabled-color);
        }

        &[data-status='BUSY'] {
          color: var(--brand-primary-white-color);
          background-color: var(--dls-warning-color-500);
        }
      }
    }
    @media (min-width: 768px) {
      width: 160px;
    }
  }

  .notifyme-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: var(--dls-size-16);
    padding-top: var(--dls-size-12);
    .notifyme-label {
      width: 130px;
      color: var(--dls-brand-primary-color-500);
      padding: var(--dls-size-8) var(--dls-size-4);
      border-radius: 0 var(--dls-size-8) var(--dls-size-8) 0;
      background: linear-gradient(270deg, #ebf1f9 0%, #fff 39.5%, #fff 100%);
      white-space: nowrap;
      @media (min-width: 768px) {
        width: 160px;
      }
    }
    .notifyme-cta {
      flex: 1;
    }
  }
  .right-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: var(--dls-size-12);
    .details-container {
      min-height: 130px;
      width: 100%;
    }
    .tag-header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .tag-container {
        .tag-icon {
          height: var(--dls-size-16);
          width: auto;
        }
      }
      .right-icon {
        padding: var(--dls-size-4);
        display: flex;
      }
    }
    .qualification {
      color: var(--brand-light-secondary-text-color);
    }
    .languages-wrapper {
      padding-top: var(--dls-size-12);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: var(--dls-size-4);
      row-gap: var(--dls-size-4);
      .language-container {
        border: 0.5px solid var(--dls-brand-primary-color-200);
        background-color: var(--dls-brand-primary-color-100);
        border-radius: var(--dls-size-40);
        padding: var(--dls-size-2) var(--dls-size-8);
        display: flex;
      }
    }
  }
`
