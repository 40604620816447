import {useCallback} from 'react'
import {useConfigStore} from '@mosaic-wellness/redux-action-library'

export const useExplicitATCTracking = () => {
  const {analytics} = useConfigStore()

  const trackAddToCart = useCallback(
    (product, source = '', otherOptions = {}) => {
      analytics.trigger('addToCartClick', {
        ...product,
        source,
        ...otherOptions,
      })
    },
    [analytics]
  )

  return {
    trackAddToCart,
  }
}
