import {useQuestionnaire} from '@mosaic-wellness/redux-action-library'

import {IUseDaQuestionnaireReturnType} from './directAssessment.interface'

function useDaQuestionnaire(): IUseDaQuestionnaireReturnType {
  const {state, actions} = useQuestionnaire()

  return [state, actions]
}

export {useDaQuestionnaire}
