import {useEffect} from 'react'
import {useRouter} from 'next/router'

function useSetPrimaryUtmSource() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const utm_source = urlParams.get('utm_source')

    async function setPrimaryUtmSource() {
      const primaryUtmSource = await window.sessionStorage.getItem(
        'primary_utm_source'
      )

      if (!primaryUtmSource && utm_source) {
        window.sessionStorage.setItem(
          'primary_utm_source',
          utm_source as string
        )
      }
    }
    setPrimaryUtmSource()
  }, [])
}

export {useSetPrimaryUtmSource}
