import React from 'react'
import propTypes from 'prop-types'

import Play from './assets/playIcon.svg'
import {StyledPlayIcon} from './videoEmbed.styles'

function PlayIcon({iconUrl = Play}) {
  return (
    <StyledPlayIcon className="play-icon">
      <img src={iconUrl} alt="play" loading="lazy" />
    </StyledPlayIcon>
  )
}

PlayIcon.propTypes = {
  width: propTypes.number,
  height: propTypes.number,
}
export default PlayIcon
