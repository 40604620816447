import styled from 'styled-components'

export const MediaWithFooterSliderContainer = styled.div`
  width: 100%;

  .modal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 7;
  }

  .close-button {
    outline: 0;
    border: 0;
    background-color: transparent;
    margin-left: auto;
    padding: 0;
  }

  .modal-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    padding: var(--dls-size-16);
    background-color: var(--dls-white-color);
    border-radius: var(--dls-size-16);
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .expanded-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .expanded-description {
    color: var(--dls-secondary-text-colors);
    margin-top: var(--dls-size-16);
  }

  .mini-ctas-container {
    margin-top: var(--dls-size-8);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: var(--dls-size-4);
  }
`
