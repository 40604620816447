import noop from 'lodash/noop'
import {StyledDoctorDetails} from './DoctorDetails.styles'
import {DoctorDetailsProps} from './DoctorDetails.interface'
import {
  AccordionRight,
  OptimizedImage,
  Spacer,
  Typography,
  TypographyVariants,
} from '@web-components'

const DoctorDetails = (props: DoctorDetailsProps) => {
  const {
    displayName = '',
    qualifications = [],
    avatar = '',
    cardActions = [],
    handleDoctorCardClick = noop,
  } = props || {}

  return (
    <StyledDoctorDetails
      data-hasActions={cardActions?.length > 0}
      onClick={handleDoctorCardClick}
    >
      <div className="details-wrapper">
        <OptimizedImage source={avatar} customClassName="avatar" />
        <div>
          {displayName && (
            <Typography
              variant={TypographyVariants.BODY_BASE_BOLD}
              customClassName="display-name"
            >
              {displayName}
            </Typography>
          )}
          <Spacer variant="x-small" />
          {qualifications?.length > 0 && (
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="qualification"
            >
              {qualifications}
            </Typography>
          )}
        </div>
      </div>
      {cardActions?.length > 0 && (
        <AccordionRight
          height={32}
          width={32}
          stroke="var(--dls-brand-primary-color-500)"
        />
      )}
    </StyledDoctorDetails>
  )
}

export default DoctorDetails
