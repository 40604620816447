import isEmpty from 'lodash/isEmpty'

export const getCardCopy = ({
  bookAppointmentCardCopy = {},
  rxConsultType = '',
  iaSelected = false,
  scheduleSelected = false,
  scheduleSelectedAndIaAvailable = false,
  isPseudoRx = false,
}) => {
  const bookAppointmentCardConfig =
    bookAppointmentCardCopy?.[rxConsultType] || bookAppointmentCardCopy

  switch (true) {
    case isEmpty(bookAppointmentCardConfig): {
      return {
        title: '',
        subtitle: '',
        consultationStartText: '',
        switchCta: {
          label: '',
          ctaText: '',
        },
      }
    }

    case iaSelected: {
      return bookAppointmentCardConfig?.iaSelected
    }

    case scheduleSelectedAndIaAvailable: {
      return bookAppointmentCardConfig?.scheduleSelectedAndIaAvailable
    }

    case scheduleSelected: {
      return bookAppointmentCardConfig?.scheduleSelected
    }

    case isPseudoRx: {
      return bookAppointmentCardConfig?.pseudoRx
    }

    default:
      return {
        title: '',
        subtitle: '',
        consultationStartText: '',
        switchCta: {
          label: '',
          ctaText: '',
        },
      }
  }
}
