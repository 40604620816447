import styled from 'styled-components'

export const StyledWrapper = styled.div`
  padding: 8px 20px 0;
  width: 100%;
  order: 2;

  @media (min-width: 768px) {
    max-width: 464px;
    padding: 10px 0 0;
  }

  &[data-show-all-details='true'] {
    padding: 0;
    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
`

export const StyledTabs = styled.div`
  height: 44px;
  display: flex;
  align-items: center;

  .tab {
    display: inline-block;
    background: transparent;
    border: none;
    height: 40px;
    padding-bottom: 10px;
    width: max-content;
    cursor: pointer;
    transition: all 0.4s;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
    color: var(--brand-primary-grey-color);
    &[data-active='true'] {
      opacity: 1;
      color: var(--brand-primary-black-color);
      border-bottom: 4px solid var(--category-primary-color);
    }

    &:not(:last-child) {
      margin-right: 21px;
    }
  }

  .all-details {
    font-size: 14px;
    color: red;
    margin-left: auto;
    margin-bottom: 10px;
    color: var(--category-primary-color);
    font-weight: var(--brand-font-weight-400);
    cursor: pointer;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`

export const StyledContent = styled.div`
  .product-description-tab {
    padding-top: 10px;
    &[data-is-padding] {
      padding-top: 20px;
    }
  }
`

export const StyledTextContent = styled.div`
  padding-top: 10px;
  &[data-is-padding] {
    padding-top: 20px;
  }
`

export const StyledBoosts = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 464px;
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 8px;
  }

  .title {
    font-weight: var(--brand-font-weight-700);
  }

  .items {
    list-style: none;
    list-style-type: none;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0;
    margin: 12px 0 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const BoostItem = styled.li`
  width: 78px;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  overflow: hidden;
  flex-shrink: 0;
  img {
    overflow: hidden;
    width: 70px;
    height: 70px;
  }

  .boost-label {
    font-size: 14px;
    line-height: 16px;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  @media (min-width: 768px) {
    &:not(:last-child) {
      margin-right: 18px;
    }

    .boost-label {
      font-size: 16px;
      line-height: 18px;
    }
  }
`
