import noop from 'lodash/noop'
import {useCallback, useState} from 'react'

import empty_down_vote from '../../../../../../assets/icons/down-vote-empty.svg'
import fill_down_vote from '../../../../../../assets/icons/down-vote-fill.svg'
import empty_up_vote from '../../../../../../assets/icons/up-vote-empty.svg'
import fill_up_vote from '../../../../../../assets/icons/up-vote-fill.svg'
import {getFormattedDate} from '../../../../../../helpers/date'
import OptimizedImage from '../../../../../common/OptimizedImage'
import Typography from '../../../../../common/Typography/Typography'
import {Wrapper} from './styles'

const ReviewAuthor = (props) => {
  const {
    id = '',
    author = '',
    dateCreated = '1/1/2022',
    countVoteUp = 0,
    countVoteDown = 0,
    isDownvoteClicked = false,
    isUpvoteClicked = false,
    votesCount = {voteUp: 0, voteDown: 0},
    showVotes = false,
  } = props.state || {}

  const {handleDownvote = noop, handleUpvote = noop} = props.actions || {}
  const [isVotesButtonDisabled, setIsVotesButtonDisabled] = useState(false)
  const [voteClicked, setVoteClicked] = useState({
    upVoteClicked: false,
    downVoteClicked: false,
  })

  const voteDownIcon =
    votesCount.voteDown > 0 ? fill_down_vote : empty_down_vote

  const voteUpIcon = votesCount.voteUp > 0 ? fill_up_vote : empty_up_vote

  const upVoteClickHandler = useCallback(async () => {
    setIsVotesButtonDisabled(true)
    handleUpvote(id)
    setTimeout(() => {
      setIsVotesButtonDisabled(false)
    }, 4500)
  }, [handleUpvote, id])

  const downVoteClickHandler = useCallback(async () => {
    setIsVotesButtonDisabled(true)
    handleDownvote(id)
    setTimeout(() => {
      setIsVotesButtonDisabled(false)
    }, 4500)
  }, [handleDownvote, id])

  return (
    <Wrapper>
      <div className="ratings-and-reviews__bottom-left">
        <div className="ratings-and-reviews__author">
          <Typography
            text={author}
            variant="body-base-light"
            customClassName="author_name"
          />
          <span className="verified-image-container">
            <OptimizedImage
              source="https://i.mscwlns.co/mosaic-wellness/image/upload/v1629700121/staging/Home/Images/Group_2298.png"
              aspectWidth="16"
              aspectHeight="16"
              desktopViewWidth="SMALL"
              mobileViewWidth="SMALL"
              altText="verified-mark"
              loading="lazy"
              customClassName="verified-image"
            />
          </span>
        </div>
        <div className="ratings-and-reviews__date">
          {getFormattedDate(dateCreated)}
        </div>
      </div>

      {showVotes && (
        <div className="ratings-and-reviews__votes">
          <div className="ratings-and-reviews__up-vote">
            <p data-selected={countVoteUp > 0 || votesCount.voteUp > 0}>
              {votesCount.voteUp || countVoteUp}
            </p>
            <button
              disabled={isVotesButtonDisabled}
              onClick={upVoteClickHandler}
            >
              <img src={voteUpIcon} alt="up vote" />
            </button>
          </div>
          <div className="ratings-and-reviews__down-vote">
            <p data-selected={countVoteDown > 0 || votesCount.voteDown > 0}>
              {votesCount.voteDown || countVoteDown}
            </p>
            <button
              disabled={isVotesButtonDisabled}
              onClick={downVoteClickHandler}
            >
              <img src={voteDownIcon} alt="down vote" />
            </button>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default ReviewAuthor
