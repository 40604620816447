import {MediaWithFooterSliderContainer} from './MediaWithFooterSlider.styles'
import {BaseWidgetComponent} from '../BaseWidget'
import {IMediaWithFooterSliderProps} from './MediaWithFooterSlider.interface'
import isEmpty from 'lodash/isEmpty'
import MediaWithFooterCard from './MediaWithFooterCard/MediaWithFooterCard'
import {GenericSlider, OptimizedImage, Typography} from '@web-components'
import {useCallback, useState} from 'react'
import {useGenericActions} from '@web-core'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'
import {GenericAction} from '@mosaic-wellness/fe-types'

function MediaWithFooterSlider(props: IMediaWithFooterSliderProps) {
  const {type, widgetData, header, layout, id} = props || {}
  const {sliderConfig, slides} = widgetData || {}
  const {
    aspectRatio = 1,
    slidesToShow = 1,
    textAlign = 'LEFT',
    modalEnabled = false,
  } = sliderConfig || {}

  const [isModalOpen, setIsModalOpen] = useState<{
    isOpen: boolean
    cardData: any
  }>({
    isOpen: false,
    cardData: null,
  })
  const {cardData} = isModalOpen
  const {
    image,
    description,
    imageDimension: {width, height} = {height: '100', width: '100'},
  } = cardData || {}

  const {genericAction} = useGenericActions()

  const handleAction = useCallback(
    (action: GenericAction) => {
      genericAction(interceptAndEnhanceAction(action, id))
    },
    [genericAction, id]
  )

  const onCardClick = useCallback(
    (cardData: any) => {
      if (!modalEnabled) {
        return
      }

      setIsModalOpen((data) => ({
        ...data,
        isOpen: !data.isOpen,
        cardData,
      }))
    },
    [modalEnabled]
  )

  if (isEmpty(slides)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithFooterSliderContainer>
        <GenericSlider
          noOfSlides={slidesToShow}
          aspectRatio={aspectRatio}
          textAlign={textAlign}
        >
          {({imageDimensions}: {imageDimensions: number[]}) =>
            slides.map((slide) => {
              return (
                <MediaWithFooterCard
                  key={slide.header}
                  onCardClick={onCardClick}
                  handleAction={handleAction}
                  {...slide}
                  imageDimensions={{
                    width: imageDimensions[0]?.toString(),
                    height: imageDimensions[1]?.toString(),
                  }}
                />
              )
            })
          }
        </GenericSlider>
        {isModalOpen.isOpen && (
          <div className="modal" onClick={onCardClick}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="close-button" onClick={onCardClick}>
                <Typography variant="cta-label-lg">×</Typography>
              </button>
              <OptimizedImage
                source={image}
                aspectWidth={width}
                aspectHeight={height}
                customClassName="expanded-image"
              />
              <Typography
                variant="tag-regular"
                customClassName="expanded-description"
              >
                {description}
              </Typography>
            </div>
          </div>
        )}
      </MediaWithFooterSliderContainer>
    </BaseWidgetComponent>
  )
}

export default MediaWithFooterSlider
