import React, {useState, useCallback} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {PrescriptionPreviewContainer} from './PrescriptionPreview.styles'
import Modal from '../../../ComponentsV2/common/Modal'

const genderMap = {
  F: 'Female',
  M: 'Male',
}

const PrescriptionPreview = (props) => {
  const {
    crossButtonImage = '',
    downloadButtonImage = '',
    pdfUrl = '',
    rxSymbolImage = '',
    name = '',
    qualification = '',
    registrationNumberText = '',
    registrationNumber = '',
    patientDetailsText = '',
    patientName = '',
    patientAge = '',
    patientGender = '',
    referenceNumberText = '',
    referenceNo = '',
    dateTimeText = '',
    appointmentTime = '',
    symptomsText = '',
    symptoms = '',
    diagnosisText = '',
    diagnosis = '',
    medicinesColumnHeading = '',
    directionsColumnHeading = '',
    medicines = [],
    generalInstructionsText = '',
    generalInstructions = {},
    nextAppointmentText = '',
    nextAppointment = '',
    doctorSign = '',
    showModal = false,
    lifestyleText = 'Lifestyle',
    specialConsultText = 'Specialist Consult',
    suggestDiagnosticsText = 'Suggested Diagnostics',
    suggestProcedureText = 'Suggested Procedure',
  } = props.state || {}

  const {handleModalClose = noop, trackPrescriptionDownload = noop} =
    props.actions || {}

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <PrescriptionPreviewContainer>
      <Modal
        showModal={showModal}
        onClose={handleModalClose}
        hideCloseButton={true}
        additionalContentClass="prescription-preview__modal-content"
        additionalModalClass="prescription-preview__modal"
      >
        <div className="prescription-preview__heading-button-container">
          <a
            onClick={trackPrescriptionDownload}
            href={pdfUrl}
            target="_blank"
            className="prescription-preview__download-button-container"
            rel="noreferrer"
            download
          >
            <img
              src={downloadButtonImage}
              alt="download"
              className="prescription-preview__download-button"
            />
          </a>
          <img
            src={crossButtonImage}
            alt="close"
            className="prescription-preview__cross-button"
            onClick={handleModalClose}
          />
        </div>
        <div className="prescription-preview__prescription-container">
          <div className="prescription-preview__prescription-heading">
            <img
              src={rxSymbolImage}
              alt="Rx"
              className="prescription-preview__prescription-rx-symbol"
            />
            <div>
              <div className="prescription-preview__prescription-doctor-name">
                {name}
              </div>
              <div className="prescription-preview__prescription-doctor-qualification">
                {qualification}
              </div>
              <div className="prescription-preview__prescription-registration-number">
                {registrationNumberText} {registrationNumber}
              </div>
            </div>
          </div>
          <div className="prescription-preview__prescription-details-separation-line"></div>
          <div className="prescription-preview__prescription-details-outer-container">
            <div className="prescription-preview__prescription-details-inner-container">
              <div className="prescription-preview__prescription-details-heading">
                {patientDetailsText}
              </div>
              <div className="prescription-preview__prescription-details-data">
                {patientName}
              </div>
              <div className="prescription-preview__prescription-details-data">
                {patientAge} {', '} {genderMap[patientGender]}
              </div>
            </div>
            <div className="prescription-preview__prescription-details-inner-container">
              <div className="prescription-preview__prescription-details-same-line">
                <div className="prescription-preview__prescription-details-heading">
                  {referenceNumberText}
                </div>
                <div className="prescription-preview__prescription-details-data">
                  {referenceNo}
                </div>
              </div>
              <div className="prescription-preview__prescription-details-same-line">
                <div className="prescription-preview__prescription-details-heading">
                  {dateTimeText}
                </div>
                <div className="prescription-preview__prescription-details-data">
                  {appointmentTime}
                </div>
              </div>
            </div>
          </div>
          <div className="prescription-preview__prescription-details-padding-top-15">
            <div className="prescription-preview__prescription-details-heading">
              {symptomsText}
            </div>
            <div className="prescription-preview__prescription-details-data">
              {symptoms}
            </div>
          </div>
          <div className="prescription-preview__prescription-details-padding-top-15">
            <div className="prescription-preview__prescription-details-heading">
              {diagnosisText}
            </div>
            <div className="prescription-preview__prescription-details-data">
              {diagnosis}
            </div>
          </div>
          <div className="prescription-preview__prescription-details-table-container">
            <table>
              <tr>
                <th>{medicinesColumnHeading}</th>
                <th>{directionsColumnHeading}</th>
              </tr>
              {medicines.map((medicine, index) => {
                return (
                  <tr>
                    <td className="column-data-bold">
                      {index + 1}
                      {'. '}
                      {medicine.product_name}
                    </td>
                    <td>{medicine.frequency}</td>
                  </tr>
                )
              })}
            </table>
          </div>
          <div className="prescription-preview__prescription-details-footer">
            <div>
              <div className="prescription-preview__prescription-details-padding-top-15">
                <div className="prescription-preview__prescription-details-heading">
                  {generalInstructionsText}
                </div>
                <div className="prescription-preview__prescription-details-data">
                  {generalInstructions?.lifestyle && (
                    <div>
                      <div>
                        <div className="prescription-preview__prescription-details-heading">
                          {lifestyleText}
                        </div>
                        <div className="prescription-preview__prescription-details-data">
                          {generalInstructions.lifestyle}
                        </div>
                      </div>
                    </div>
                  )}
                  {generalInstructions?.specialConsult && (
                    <div>
                      <div>
                        <div className="prescription-preview__prescription-details-heading">
                          {specialConsultText}
                        </div>
                        <div className="prescription-preview__prescription-details-data">
                          {generalInstructions.specialConsult}
                        </div>
                      </div>
                    </div>
                  )}
                  {generalInstructions?.suggestDiagnostics && (
                    <div>
                      <div>
                        <div className="prescription-preview__prescription-details-heading">
                          {suggestDiagnosticsText}
                        </div>
                        <div className="prescription-preview__prescription-details-data">
                          {generalInstructions.suggestDiagnostics}
                        </div>
                      </div>
                    </div>
                  )}
                  {generalInstructions?.suggestProcedure && (
                    <div>
                      <div>
                        <div className="prescription-preview__prescription-details-heading">
                          {suggestProcedureText}
                        </div>
                        <div className="prescription-preview__prescription-details-data">
                          {generalInstructions.suggestProcedure}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="prescription-preview__prescription-details-padding-top-15">
                <div className="prescription-preview__prescription-details-heading">
                  {nextAppointmentText}
                </div>
                <div className="prescription-preview__prescription-details-data">
                  {nextAppointment} {'Days'}
                </div>
              </div>
            </div>
            <img
              src={doctorSign}
              alt="doctorSign"
              className="prescription-preview__prescription-details-doctor-sign"
            />
          </div>
        </div>
      </Modal>
    </PrescriptionPreviewContainer>
  )
}

export default PrescriptionPreview
