import {Arrow, Close, SearchInput} from '@web-components'
import {RightIconContainer, SearchInputContainer} from './SearchBar.styles'
import noop from 'lodash/noop'
import {SearchBarProps} from './SearchBar.types'

const RightIcon = ({showCrossIcon}: {showCrossIcon: boolean}) => {
  return (
    showCrossIcon && (
      <RightIconContainer>
        <Close
          width={20}
          height={20}
          stroke="var(--dls-secondary-text-colors)"
        />
      </RightIconContainer>
    )
  )
}

const SearchBar = (props: SearchBarProps) => {
  const {
    searchQuery = '',
    placeHolder = '',
    handleSearchQueryChange = noop,
    handleBackClick = noop,
    handleCrossClick = noop,
    searchInputRef,
    handleFocus = noop,
    showCrossIcon = true,
  } = props

  return (
    <SearchInputContainer>
      <SearchInput
        inputRef={searchInputRef}
        leftIcon={
          <div className="left-icon">
            <Arrow
              rotateByDeg="270"
              width={20}
              height={20}
              stroke="var(--dls-secondary-text-colors)"
            />
          </div>
        }
        rightIcon={<RightIcon showCrossIcon={showCrossIcon} />}
        leftIconAction={handleBackClick}
        rightIconAction={handleCrossClick}
        inputData={{
          value: searchQuery,
          placeholder: placeHolder,
          onChange: handleSearchQueryChange,
          onFocus: handleFocus,
        }}
        customClassName="search-input"
      />
    </SearchInputContainer>
  )
}

export default SearchBar
