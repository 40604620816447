export const processVideoShowCaseClicked = (
  originalPayload: any,
  dLayer: typeof window.customDataLayer
) => {
  const {label = '', link = ''} = originalPayload || {}
  const dataToTrack = {
    label: label,
    link: link,
  }
  return dataToTrack
}
