import {HttpClient} from '@mosaic-wellness/redux-action-library'
import {useCallback, useRef, useState} from 'react'
import {IUseCheckPrescriptionV2Response} from './useCheckPrescriptionV2.interface'
import {API_END_POINTS} from 'src/constants/apiEndPoints'

function useCheckPrescriptionV2() {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [checkPrescriptionData, setCheckPrescriptionData] = useState<
    IUseCheckPrescriptionV2Response['data'] | null
  >(null)
  const isInitialCheckPrescriptionCallMade = useRef<boolean>(false)

  const checkPrescription = useCallback(
    async (params: {categories: string[]; language: string}) => {
      const {categories = [], language = ''} = params || {}
      const axios = HttpClient.getAxiosInstance()

      try {
        setIsError(false)
        setIsLoading(true)
        const {data} = await axios.post<IUseCheckPrescriptionV2Response>(
          API_END_POINTS.CHECK_PRESCRIPTION_V2,
          {
            categories,
            language,
          }
        )
        if (data?.data) {
          setCheckPrescriptionData(data.data)
        }
        return data.data
      } catch (err) {
        setIsError(true)
      } finally {
        setIsLoading(true)
        setIsLoading(false)
        isInitialCheckPrescriptionCallMade.current = true
      }
    },
    []
  )

  const resetCheckPrescriptionData = useCallback(() => {
    setIsError(false)
    setIsLoading(false)
    setCheckPrescriptionData(null)
  }, [])

  return [
    {
      isLoading,
      isError,
      checkPrescriptionData,
      isInitialCheckPrescriptionCallMade,
    },
    {checkPrescription, resetCheckPrescriptionData},
  ] as const
}

export default useCheckPrescriptionV2
