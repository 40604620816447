import {Typography, TypographyVariants} from '@web-components'
import {useRef, useEffect} from 'react'

interface VideoModal {
  isOpen: boolean
  onClose: () => void
  videoUrl: string
}

const VideoModal = ({isOpen, onClose, videoUrl}: VideoModal) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (isOpen && videoRef.current) {
      videoRef.current.play()
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div onClick={onClose}>
          <Typography
            variant={TypographyVariants.HEADING_MEDIUM_BOLD}
            customClassName="close-button"
          >
            x
          </Typography>
        </div>
        <video
          ref={videoRef}
          width="100%"
          controls
          controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
          className="video-player"
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default VideoModal
