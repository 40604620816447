import styled from 'styled-components'

export const ShopStyledLoading = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  
  .shop-block {
    width: 33%;
    margin-right: 10px;
  }
  .shop-horizontal-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .filter-block {
    width: 60%;
  }
  .filter-horizontal-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .home-left {
    width: 30%;
    margin-right: 10px;
  }

  .home-mid {
    width: 50%;
    margin-right: 10px;
  }

  .home-right {
    width: 20%;
    align-self: flex-end;
    margin-left: 10px;
  }

  .home-left-half {
    width: 50%;
    margin-right: 10px;
  }
  .home-right-half {
    width: 50%;
    margin-right: 10px;
    align-self: flex-end;
  }
  
  @media (min-width: 768px) {
  
    .shop-block {
      width: 20%;
      margin-right: 10px;
    }
    .home-left {
      width: 20%;
    }
    .home-mid {
      width: 50%;
    }
    .home-right {
      width: 30%;
    }

    .home-left-half {
      width: 33%;
      margin-right: 10px;
    }
    .home-right-half {
      width: 33%;
      margin-right: 10px;
      align-self: flex-end;
    }
  }
`
