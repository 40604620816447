import {useCallback, useMemo} from 'react'
import {Product} from '../CategoryProductCardGrid/CategoryProductCard/CategoryProductCard.interface'
import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'
import {ProductCardGridProps} from './ProductCardGrid.types'
import {useGenericActions} from '../../hooks'

const useProductCardGrid = (props: ProductCardGridProps) => {
  const {source: widgetDataSource = '', id} = props
  const {updateItemToCart} = useUpdateItemToCart()
  const {genericAction} = useGenericActions()
  const source = useMemo(() => widgetDataSource || id, [id, widgetDataSource])
  const handleUpdateCartItem = useCallback(
    (id: number, sku: string, quantity: number) => {
      updateItemToCart({
        sku,
        quantity,
        isMiniCart: true,
      })
    },
    [updateItemToCart]
  )

  const handleCtaClick = useCallback(
    (product: Product) => {
      const {action = '', actionData = {}} = product?.cta || {}
      if (!action) return
      genericAction({
        actionName: action,
        params: {...product, source: source, ...(actionData || {})},
      })
    },
    [genericAction, source]
  )

  const handleCardClick = useCallback(
    (product: Product) => {
      genericAction({
        actionName: 'OPEN_PDP',
        params: {...product, source: source},
      })
    },
    [genericAction, source]
  )
  return {handleUpdateCartItem, handleCtaClick, handleCardClick}
}

export default useProductCardGrid
