import styled from 'styled-components'

export const InputSectionWrapper = styled.div`
  .input-label {
    font-size: 0.875rem;
    color: var(--brand-primary-green-dark-color);
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    display: block;
  }

  .input-disabled {
    cursor: not-allowed;
    background-color: var(--brand-primary-black-light-color);
    outline: none;
  }

  .input-box {
    font-family: inherit;
    font-size: 1rem;
    padding: 1rem;
    width: 100%;
    border-radius: 16px;
    color: var(--brand-primary-black-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .error-field {
    font-size: 0.625rem;
    margin-left: 1.25rem;
    color: var(--brand-primary-red-color);
  }

  .input-box-error {
    border: 1px solid var(--brand-primary-red-color);
  }

  .input-box::placeholder {
    color: var(--brand-primary-grey-color);
    opacity: 0.5;
    font-weight: 300;
  }

  .input-section {
    margin-bottom: 1.25rem;
  }
`
