import {CategoryProductCardCarouselContainer} from './CategoryProductCardCarousel.styles'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {useGenericActions} from '../../hooks'
import {Product} from '../CategoryProductCardGrid/CategoryProductCard/CategoryProductCard.interface'
import {CategoryProductCard} from '../CategoryProductCardGrid/CategoryProductCard'
import useCategoryProductCardCarousel from './useCategoryProductCardCarousel'
import noop from 'lodash/noop'
import {CategoryProductCardCarouselProps} from './CategoryProductCardCarousel.types'

const CategoryProductCardCarousel = (
  props: BaseWidgetProps<CategoryProductCardCarouselProps>
) => {
  const {layout, id, type, header, widgetData} = props
  const {products, enableQuantityIndicator = false} = widgetData || {}
  const [
    {},
    {
      handleCardClick = noop,
      handleCtaClick = noop,
      handleUpdateCartItem = noop,
    },
  ] = useCategoryProductCardCarousel(props)
  const {genericAction} = useGenericActions()

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <CategoryProductCardCarouselContainer>
        <div className="product-card-list">
          {products?.map((product: Product) => {
            return (
              <div className="card-container" key={product.urlKey}>
                <CategoryProductCard
                  product={{...product, genericAction}}
                  handleCardClick={handleCardClick}
                  handleCtaClick={handleCtaClick}
                  handleUpdateCartItem={handleUpdateCartItem}
                  enableQuantityIndicator={enableQuantityIndicator}
                />
              </div>
            )
          })}
        </div>
      </CategoryProductCardCarouselContainer>
    </BaseWidgetComponent>
  )
}

export default CategoryProductCardCarousel
