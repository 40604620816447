export const processGetCustomerInfo = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    category = '',
    language = '',
    iaBooked = false,
    name = '',
    email = '',
    appointmentId = '',
  } = data

  return {
    Category: category,
    Language: language,
    Name: name,
    'IA Booked': iaBooked ? 'Y' : 'N',
    'Appointment Id': appointmentId,
  }
}
