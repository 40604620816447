import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import CredBanner from './CredBanner/CredBanner'

function DoctorListingCredBanner(props: BaseWidgetProps<{}>) {
  return <CredBanner {...props} />
}

export default DoctorListingCredBanner
