import {LottiePlayer, OptimizedImage} from '@web-components'
import {Container, GridContainer} from './Grid.styles'
import {IGrid, IGridProps} from './Grid.types'

const RenderGrid = (grid: IGrid & {onItemPress: () => void}) => {
  const {imageLink = '', animationLink = '', onItemPress} = grid || {}

  return (
    <Container onClick={onItemPress}>
      {imageLink && (
        <OptimizedImage source={imageLink} customClassName="imageorAnimation" />
      )}
      {animationLink && (
        //@ts-ignore
        <LottiePlayer
          src={animationLink}
          autoplay
          loop
          style={{width: '100%', height: '100%'}}
        />
      )}
    </Container>
  )
}
const Grid = (props: IGridProps) => {
  const {items = [], onGridItemPress} = props

  return (
    <GridContainer>
      {items?.map((item, index) => (
        <RenderGrid
          key={`grid_` + index}
          {...item}
          onItemPress={onGridItemPress(item)}
        />
      ))}
    </GridContainer>
  )
}

export default Grid
