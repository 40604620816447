import {useState, useEffect} from 'react'

const useSessionStorage = (name: string) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(window.sessionStorage.getItem(name) || '')
  }, [])

  return value
}

export {useSessionStorage}
