import noop from 'lodash/noop'
import {useCallback, useEffect, useState} from 'react'

import OptimizedVideoV2 from '../../../common/OptimizedVideoV2/OptimizedVideoV2'
import VideoEmbed, {PlayIcon} from '../../../common/VideoEmbed'

function CarouselVideo(props) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const {state = {}, actions = {}} = props
  const {videoLink = '', isActive = false, customVideo = {}, index = 0} = state
  const {isEmbedded = true} = customVideo || {}
  const {onVideoPlay = noop} = actions

  useEffect(() => {
    if (isVideoPlaying) {
      onVideoPlay()
    }
  }, [isVideoPlaying, onVideoPlay])

  const playVideo = useCallback(() => {
    setIsVideoPlaying(true)
  }, [])

  const pauseVideo = useCallback(() => {
    setIsVideoPlaying(false)
  }, [])

  useEffect(() => {
    if (isVideoPlaying && !isActive) {
      pauseVideo()
    }
  }, [isActive])

  if (isEmbedded) {
    return (
      <VideoEmbed
        videoLink={videoLink}
        playIcon={<PlayIcon />}
        playing={isVideoPlaying}
        onPlay={playVideo}
        onPause={pauseVideo}
        controls={true}
      />
    )
  } else {
    return (
      <OptimizedVideoV2
        state={{
          ...customVideo,
          videoComponentClassName: `video-carousel-${index}`,
          autoPlay: false,
          playsInline: true,
        }}
      />
    )
  }
}

export default CarouselVideo
