import {Typography} from '@web-components'
import {IHtmlTextDataType} from './HtmlText.types'

const HtmlText = (props: IHtmlTextDataType) => {
  const {text, style, variant = 'body-base-regular'} = props || {}
  if (!text) return null
  return (
    <div style={style}>
      <Typography variant={variant}>
        <span dangerouslySetInnerHTML={{__html: text}} />
      </Typography>
    </div>
  )
}

export default HtmlText
