import styled from 'styled-components'

export const ScheduleScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  /* padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0;
  } */
`
