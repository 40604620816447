import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {IKitProductProps} from './KitProduct.interface'
import {KitProductContainer} from './KitProduct.styles'
import noop from 'lodash/noop'
import {useCallback} from 'react'

function KitProduct(props: IKitProductProps) {
  const {
    image = '',
    imageHeight = '100',
    imageWidth = '100',
    modalData,
    name = '',
    priceLabel = '',
    isLastElement = false,
    onKitProductClick = noop,
  } = props

  const handleKitProductClick = useCallback(() => {
    onKitProductClick(modalData)
  }, [modalData, onKitProductClick])

  return (
    <KitProductContainer onClick={handleKitProductClick}>
      <div className="kit-product-data">
        <div className="product-image-container">
          <OptimizedImage
            customClassName="product-image"
            source={image}
            aspectWidth={imageWidth}
            aspectHeight={imageHeight}
          />
        </div>
        <div className="product-details-container">
          <Typography
            customClassName="product-name"
            variant={TypographyVariants.TAG_BOLD}
          >
            {name}
          </Typography>
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {priceLabel}
          </Typography>
        </div>
      </div>
      <div className="plus-sign" data-islastelement={isLastElement}>
        +
      </div>
    </KitProductContainer>
  )
}

export default KitProduct
