import * as React from 'react'

function Menu({height = 24, width = 24, stroke = '#212121'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 12h18M3 6h18M9 18h12"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={1}
      />
    </svg>
  )
}

export default Menu
