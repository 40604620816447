import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 16px;
  z-index: 1;
  .loader {
    height: 100% !important;
  }
`

const Shimmer = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`

const RowFlexContainer = styled.div<{
  isGrid: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  flex-wrap: ${({isGrid}) => (isGrid ? 'wrap' : 'nowrap')};
`

const CarouselContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  gap: 16px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;

  > * {
    scroll-snap-align: start;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }
`

const Item = styled.div<{height: number; width: string; isFlex?: boolean}>`
  height: ${({height}) => height}px;
  width: ${({width}) => width};
  flex: ${({isFlex}) => (isFlex ? 1 : 'none')};
`

export {
  Container,
  LoaderContainer,
  RowFlexContainer,
  CarouselContainer,
  Item,
  Shimmer,
}
