export const processMiniAssessmentOptionsSelected = (originalPayload: any) => {
  const {question = '', selectedOptions = ''} = originalPayload || {}

  const dataToTrack = {
    question,
    answers: Object.keys(selectedOptions)
      .map((option) => selectedOptions[option].value)
      .join(','),
    score: Object.keys(selectedOptions)
      .map((option) => selectedOptions[option].score)
      .reduce((acc, item) => acc + item, 0),
  }

  return dataToTrack
}
