import React from 'react'
import {EmptyStateContainer} from './NoProductsFound.styles'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'

export const NoProductsFound = ({emptyState}: any) => {
  const {
    title = 'Stay Tuned!',
    subtitle = 'We’re adding exciting new items to this category soon. Thank you for your interest!',
    image = 'https://i.mscwlns.co/media/misc/others/empty_79da2x.png?tr=w-600',
  } = emptyState || {}

  return (
    <EmptyStateContainer>
      <OptimizedImage
        source={image}
        desktopViewWidth="SMALL"
        mobileViewWidth="SMALL"
        altText="category-image"
        loading="lazy"
        customClassName="image"
      />
      <div className="title-container">
        <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
          {title}
        </Typography>
      </div>
      <div className="subtitle-container">
        <Typography variant={TypographyVariants.TAG_REGULAR}>
          {subtitle}
        </Typography>
      </div>
    </EmptyStateContainer>
  )
}

export default NoProductsFound
