import {HttpClient, useConfigStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useState} from 'react'
import {logError} from 'src/utils/logger'

export const CouponQueryParamsKeys = {
  utmCampaign: 'utm_campaign',
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
}

let GET_ALL_COUPONS = 'offers/cart'
export async function getQueryParamsValue(storage: any, key: string) {
  try {
    const source = await storage.getItem(key)
    if (source) {
      const sourceObj = JSON.parse(source)
      const sourceValue = sourceObj.value
      return sourceValue
    }
  } catch (error) {
    logError(error as Error, {
      location: 'Apply Coupons',
    })
  }
}

export const useGetAvailableCoupons = () => {
  const [couponDataRes, setCouponDataRes] = useState({})
  const {storage} = useConfigStore()
  const axiosInstance = HttpClient.getAxiosInstance()
  const getData = useCallback(
    async function getData() {
      try {
        const CouponSearchParams = new URLSearchParams()
        const utmCampaignValue = await getQueryParamsValue(
          storage,
          CouponQueryParamsKeys.utmCampaign
        )
        if (utmCampaignValue) {
          CouponSearchParams.append(
            CouponQueryParamsKeys.utmCampaign,
            utmCampaignValue
          )
        }

        const utmSourceValue = await getQueryParamsValue(
          storage,
          CouponQueryParamsKeys.utmSource
        )
        if (utmSourceValue) {
          CouponSearchParams.append(
            CouponQueryParamsKeys.utmSource,
            utmSourceValue
          )
        }
        const utmMediumValue = await getQueryParamsValue(
          storage,
          CouponQueryParamsKeys.utmMedium
        )
        if (utmMediumValue) {
          CouponSearchParams.append(
            CouponQueryParamsKeys.utmMedium,
            utmMediumValue
          )
        }

        const CouponQueryString = CouponSearchParams.toString()

        if (CouponQueryString) {
          GET_ALL_COUPONS = `${GET_ALL_COUPONS}?${CouponQueryString}`
        }

        const response = await axiosInstance.get(GET_ALL_COUPONS)
        const {data} = response?.data || {}
        if (data) {
          setCouponDataRes(data)
        }
      } catch (error) {
        setCouponDataRes({})
      }
    },
    [axiosInstance]
  )

  return {couponDataRes, getData}
}
