import React from 'react'
import styled from 'styled-components'

export const StyledChevron = styled.svg`
  circle {
    fill: ${(props) =>
      props.themeFill ? 'var(--brand-primary-color)' : '#000000'};
  }
`
function ChevronDown({width = 48, height = 48, themeFill}) {
  return (
    <StyledChevron
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 48"
      themeFill={themeFill}
    >
      <circle cx="24" cy="24" r="24" transform="rotate(90 24 24)"></circle>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M16.801 21.4l7.2 7.2 7.2-7.2"
      ></path>
    </StyledChevron>
  )
}

export default ChevronDown
