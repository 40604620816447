import React from 'react'
import StarRatings from 'react-star-ratings'

const star =
  'M14 2L17.8206 9.74139L26.3637 10.9828L20.1819 17.0086L21.6412 25.5172L14 21.5L6.35879 25.5172L7.81813 17.0086L1.63627 10.9828L10.1794 9.74139L14 2Z'
const starViewBox = '0 0 28 27'

const bigStar =
  'M14 2L17.8206 9.74139L26.3637 10.9828L20.1819 17.0086L21.6412 25.5172L14 21.5L6.35879 25.5172L7.81813 17.0086L1.63627 10.9828L10.1794 9.74139L14 2Z'
const bigStarViewBox = '0 0 28 27'

const StarRating = ({rating, starColor, starType}) => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <StarRatings
        name="rate1"
        numberOfStars={5}
        rating={rating}
        starRatedColor={starColor || 'rgb(228, 174, 44)'}
        svgIconPath={starType !== 'bigStar' ? star : bigStar}
        svgIconViewBox={starType !== 'bigStar' ? starViewBox : bigStarViewBox}
        starDimension={starType !== 'bigStar' ? '1em' : '25px'}
        starSpacing="1px"
      />
    </div>
  )
}

export default StarRating
