import styled from 'styled-components'

export const StyledLoading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  .container {
    margin-top: 30px;
  }
  .left {
    width: 49%;
    margin-right: 10px;
  }
  .center {
    width: 29%;
    margin-left: 40%;
    margin-top: 10px;
  }
  .left-half {
    width: 25%;
    margin-right: 10px;
  }
  .full-width {
    margin-bottom: 20px;
  }
  .images {
    margin-top: -10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  @media screen and (max-width: 768px) {
    padding: 0px 15px;
  }
`
