import React from 'react'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {GenericCarousel, useDeviceTypeContext} from '@web-components'
import {TestimonialProductCard} from './TestimonialProductCard'
import {RichProductCardsWapper} from './TestimonialProductCardCarousel.styles'

const TestimonialProductCardCarousel = (props: BaseWidget) => {
  const {type, widgetData, header, layout, id} = props || {}

  const {isMobile} = useDeviceTypeContext()
  const {testimonialProducts = []} = widgetData || {}

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <RichProductCardsWapper>
        <div className="carousel">
          <GenericCarousel
            cardData={testimonialProducts}
            Component={TestimonialProductCard}
            showDots={isMobile ? true : false}
            arrowPositionY={300}
          />
        </div>
      </RichProductCardsWapper>
    </BaseWidgetComponent>
  )
}

export default TestimonialProductCardCarousel
