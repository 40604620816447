import {useCallback} from 'react'
import noop from 'lodash/noop'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faTimes} from '@fortawesome/free-solid-svg-icons'

import {TopBarContainer} from './TopBar.styles'
import {TopSheet} from '../TopSheet'
import Responsive from '../../../ComponentsV2/HOC/Responsive'

function TopBar(props) {
  const {state = {}, actions = {}, isMobile = false} = props
  const {
    showBackButton = false,
    className = '',
    showTopSheet = true,
    topSheetData = {},
  } = state
  const {
    onBackClick = noop,
    onDismissClick = noop,
    onTopSheetOpen = noop,
    daExitClicked = noop,
    daBackClicked = noop,
  } = actions

  const handleBackClicked = useCallback(() => {
    daBackClicked()
    onBackClick()
  }, [onBackClick, daBackClicked])

  const handleDismissClicked = useCallback(() => {
    daExitClicked()
    onDismissClick()
  }, [onDismissClick, daExitClicked])

  return (
    <TopBarContainer className={className}>
      <div className="top-bar-data">
        {showBackButton ? (
          <button
            onClick={handleBackClicked}
            className="top-bar-button back-button"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="chevron-icon" />{' '}
            Back
          </button>
        ) : (
          <div className="dummy-placeholder"></div>
        )}

        {showTopSheet && !isMobile && (
          <TopSheet
            state={{...topSheetData}}
            actions={{onTopSheetOpen}}
            className="appointment-details-top-sheet"
          />
        )}

        <button
          className="top-bar-button close-button"
          onClick={handleDismissClicked}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      {showTopSheet && isMobile && (
        <TopSheet
          state={{...topSheetData}}
          actions={{onTopSheetOpen}}
          className="appointment-details-top-sheet"
        />
      )}
    </TopBarContainer>
  )
}

export default Responsive(TopBar)
