import React, {useCallback} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {ViewPrescriptionCardContainer} from './ViewPrescriptionCard.styles'
import {LoadingState} from '../../../ComponentsV2/common'
import Star from '../../../ComponentsV2/common/RatingStars/star.jsx'

const ViewPrescriptionCard = (props) => {
  const {
    diagnosis = '',
    name = '',
    image = '',
    qualification = '',
    noOfConsults = '',
    doctorRating = '',
    heading = '',
    noOfConsultText = '',
    diagnosisHeading = '',
    viewPrescriptionButton = '',
    fallBackDoctorImage = '',
    isPrescriptionLoading = false,
  } = props.state || {}

  const {handleModalOpen = noop, trackPrescriptionViewed = noop} =
    props.actions || {}

  const handleViewPrescriptionClicked = useCallback(() => {
    handleModalOpen()
    trackPrescriptionViewed()
  }, [handleModalOpen, trackPrescriptionViewed])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <ViewPrescriptionCardContainer>
      {isPrescriptionLoading ? (
        <LoadingState cardHeight={100} />
      ) : (
        <div className="view-prescription-card__heading-container">
          <div className="view-prescription-card__image-container">
            <img
              src={image || fallBackDoctorImage}
              alt="doctor-face"
              className="view-prescription-card__image"
            />
            <div className="view-prescription-card__rating-container">
              <div className="view-prescription-card__rating">
                {doctorRating}
              </div>
              <Star height="12px" width="12px" />
            </div>
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{__html: heading}}
              className="view-prescription-card__heading"
            />
            <div className="view-prescription-card__name">{name}</div>
            <div className="view-prescription-card__qualification">
              {qualification}
            </div>
            <div className="view-prescription-card__no-of-consult">
              {noOfConsults} {noOfConsultText}
            </div>
          </div>
        </div>
      )}
      {isPrescriptionLoading ? (
        <LoadingState cardHeight={20} />
      ) : (
        <div>
          <div className="view-prescription-card__diagnosis-heading">
            {diagnosisHeading}
          </div>
          <div className="view-prescription-card__diagnosis">{diagnosis}</div>
        </div>
      )}
      {isPrescriptionLoading ? (
        <LoadingState cardHeight={28} />
      ) : (
        <button
          className="view-prescription-card__button"
          onClick={handleViewPrescriptionClicked}
        >
          <div>{viewPrescriptionButton}</div>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}
    </ViewPrescriptionCardContainer>
  )
}

export default ViewPrescriptionCard
