import styled from 'styled-components'

export const ScheduleHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  .schedule-header__title {
    font-size: 20px;
    font-weight: var(--brand-font-weight-bold);
    color: var(--brand-primary-black-color);
    margin: 0 0 0 4px;
  }
  .schedule-header__subTitle {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    color: var(--brand-primary-teal-color);
    margin: 4px 0 0 0;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
    .schedule-header__title {
      font-size: 28px;
      margin: 0 0 0 6px;
    }
    .schedule-header__subTitle {
      font-size: 18px;
      margin: 6px 0 0 0;
    }
  }
`
