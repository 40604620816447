import styled from 'styled-components'

export const GetCustomerInfoContainer = styled.div`
  padding: 24px 20px;
  max-width: 450px;
  margin: auto;
  margin-bottom: 60px;

  .title-container {
    margin-bottom: 30px;
  }

  .title {
    font-size: 20px;
    margin: 0 0 8px 0;
  }

  .subtitle {
    font-size: 14px;
    line-height: 1.2;
    color: var(--brand-primary-grey-color);
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .customer-info-input {
    display: flex;
    width: 100%;

    :first-child {
      flex-basis: 48%;
    }

    :nth-child(2) {
      flex-basis: 48%;
    }

    .input-section {
      width: 100%;
    }

    .input-label {
      margin: 0 0 8px 0;
      font-size: 16px;
      line-height: 16px;
      color: var(--category-primary-color);
    }

    .input-box {
      padding: 16px 14px;
      border-radius: 12px;
      font-size: 16px;
    }

    .error-field {
      margin-left: 4px;
      margin-top: 4px;
      font-size: 12px;
    }
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 90px;
  }
`
