import noop from 'lodash/noop'

import Card from './Components/ReviewCard'
import {Wrapper} from './Review.styles'

const RatingsAndReviewBottom = (props) => {
  const {data = {}, actions = {}} = props
  const {
    reviews = [],
    category = 'hair',
    moreReviewsToLoad,
    searchTerm,
    loadedReviews,
    votesCount = {},
    showVotes = false,
  } = data
  const {
    handleUpvote = noop,
    handleDownvote = noop,
    onImageClick = noop,
    disableBodyScroll = noop,
    enableBodyScroll = noop,
  } = actions

  //TODO: For qna section even after "loadMore", the scroll position is maintained without having to do anything explicitly. Investigate later why that is not the case with reviews section /

  if (
    searchTerm &&
    moreReviewsToLoad === false &&
    loadedReviews?.length === 0
  ) {
    return (
      <Wrapper>
        <p className="empty-results">No Results Found</p>
      </Wrapper>
    )
  }

  const length = reviews.length
  return (
    <Wrapper data-product-category={category}>
      <div className="ratings-and-reviews__reviews-container">
        {reviews.map((review, i) => {
          const isLastElement = i === length - 1
          const args = {
            state: {
              review,
              isLastElement,
              isUpvoteClicked: false,
              isDownvoteClicked: false,
              votesCount,
              showVotes,
            },
            actions: {
              handleUpvote,
              handleDownvote,
              onImageClick,
              disableBodyScroll,
              enableBodyScroll,
            },
          }
          return <Card {...args} key={review.id} />
        })}
      </div>
    </Wrapper>
  )
}

export default RatingsAndReviewBottom
