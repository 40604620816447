import {Typography, TypographyVariants} from '@web-components'
import {StyledOutOfStockBanner} from './styles'
import {OutOfStockBannerProps} from './types'

const OutOfStockBanner = (props: OutOfStockBannerProps) => {
  const {outOfStock} = props

  if (!outOfStock) {
    return null
  }

  return (
    <StyledOutOfStockBanner {...props}>
      <Typography variant={TypographyVariants.TAG_BOLD}>
        Out of stock
      </Typography>
    </StyledOutOfStockBanner>
  )
}

export default OutOfStockBanner
