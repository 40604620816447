import styled from 'styled-components'

export const StickyFooterCtaContainer = styled.div`
  padding: var(--dls-size-16);
  max-width: 500px;
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: var(--dls-size-8);
  background-color: var(--dls-white-color);
  border-top: 1px solid var(--dls-divider-light-color);

  .cta-button {
    width: 100%;
    flex: 1;
    outline: 0;
    border: 0;
    padding: var(--dls-size-16);
    border-radius: var(--dls-size-8);

    &[data-type='PRIMARY'] {
      background-color: var(--dls-category-primary-color-500);
      color: var(--dls-white-color);
    }

    &[data-type='SECONDARY'] {
      color: var(--dls-category-primary-color-500);
      border: 1px solid var(--dls-divider-light-color);
      background-color: var(--dls-white-color);
    }
  }
`
