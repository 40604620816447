import styled, {css} from 'styled-components'

export const StyledRxPreviewWidget = styled.div<{isPaymentPending: boolean}>`
  border-radius: 12px;
  background: var(--brand-primary-white-color);
  overflow: hidden;
  position: relative;
  ${({isPaymentPending}) =>
    isPaymentPending &&
    css`
      max-height: 50vh;
    `}
`

export const StyledTreatmentPlan = styled.div`
  padding: 12px 10px;
  background: var(--brand-primary-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  & > * {
    width: 100%;
  }
  .habit-banner {
    height: 180px;
    width: 100%;
  }
  .habit-banner + .habit-details {
    margin-top: -10px;
  }
`

export const StyledProductsList = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  & > * {
    width: 100%;
  }
`
