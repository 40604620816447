import styled from 'styled-components'

export const StyledLoader = styled.div`
  margin-bottom: 16px;
  padding: var(--dls-size-16);
  .loading-card {
    height: 100vh;
    border-radius: 16px;
    margin-bottom: 12px;
  }
  .shimmer {
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`
