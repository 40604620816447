import {useCallback, useState} from 'react'
import {StyledVideoSliderContainer} from './VideoSlider.styles'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {GenericSlider, OptimizedImage} from '@web-components'
import VideoModal from './VideoModal'

interface VideoSliderWidget {
  items: {
    thumbnailImage: string
    videoUrl: string
  }[]
  aspectRatio: number
  slidesToShow: number
}

const VideoSlider = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<VideoSliderWidget>) => {
  const {items = [], aspectRatio = 1, slidesToShow = 1} = widgetData

  const [isOpen, setIsOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')

  const onThumbnailClick = useCallback((videoUrl: string) => {
    setVideoUrl(videoUrl)
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setVideoUrl('')
    setIsOpen(false)
  }, [])

  if (!items || isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledVideoSliderContainer>
        <GenericSlider noOfSlides={slidesToShow} aspectRatio={aspectRatio}>
          {({imageDimensions}: {imageDimensions: number[]}) =>
            items.map((video, index) => {
              const {thumbnailImage = '', videoUrl = ''} = video || {}
              return (
                <div
                  className="slider-card"
                  key={index}
                  onClick={() => onThumbnailClick(videoUrl)}
                >
                  <OptimizedImage
                    source={thumbnailImage}
                    aspectWidth={imageDimensions[0]?.toString()}
                    aspectHeight={imageDimensions[1]?.toString()}
                    desktopViewWidth="FULL"
                    mobileViewWidth="FULL"
                    altText="oops"
                    loading="lazy"
                    customClassName="thumbnail"
                  />
                </div>
              )
            })
          }
        </GenericSlider>
        {videoUrl && (
          <VideoModal
            isOpen={isOpen}
            onClose={handleClose}
            videoUrl={videoUrl}
          />
        )}
      </StyledVideoSliderContainer>
    </BaseWidgetComponent>
  )
}

export default VideoSlider
