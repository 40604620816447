import isEmpty from 'lodash/isEmpty'

import Down from '../../../common/Icons/CarouselArrows/Down'
import Up from '../../../common/Icons/CarouselArrows/Up'
import {GreenBorderThumbnail} from './ImageCarousalnew.styles'
import MemoizedImageCarouselSlide from './ImageCarouselSlide'

const Desktop = (props) => {
  const {state, actions} = props || {}
  const {
    carouselImages,
    currentIndex,
    handleThumbnailClick,
    handlePrev,
    handleNext,
    handleThumbnailScroll,
    containerRef,
    thumbnailContainerRef,
    showBannerCta = true,
  } = state || {}

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <>
      <div className="thumbnail-container" ref={thumbnailContainerRef}>
        {carouselImages.map((image, index) => {
          const {thumbnail} = image || {}
          return (
            <div className="thumbnail-wrapper" key={index}>
              <GreenBorderThumbnail
                src={thumbnail}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => handleThumbnailClick(index)}
                active={currentIndex === index}
              />
            </div>
          )
        })}
      </div>
      {currentIndex !== 0 && (
        <button
          className="nav-button-side up-button"
          onClick={() => {
            handlePrev()
            handleThumbnailScroll('up')
          }}
        >
          <Up />
        </button>
      )}

      <button
        className="nav-button-side down-button"
        onClick={() => {
          handleNext()
          handleThumbnailScroll('down')
        }}
      >
        <Down />
      </button>
      <div className="image-container" ref={containerRef}>
        {carouselImages.map((image, index) => {
          const {
            original = '',
            cta = '',
            type = '',
            customVideo = {},
            videoLink = '',
          } = image || {}
          return (
            <MemoizedImageCarouselSlide
              key={index}
              state={{
                videoLink: videoLink,
                type,
                src: original,
                alt: `Image ${index + 1}`,
                isActive: currentIndex === index,
                cta: cta,
                customVideo: customVideo,
                index,
                isMobile: false,
                showBannerCta,
              }}
              currentIndex={currentIndex}
              actions={actions}
            />
          )
        })}
      </div>
    </>
  )
}

export default Desktop
