import styled from 'styled-components'

export const ProductsGridContainer = styled.div<{
  showMobileViewOnDesktop: boolean
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid var(--dls-divider-light-color);
  .product-card-container {
    border-bottom: 1px solid var(--dls-divider-light-color);
    :nth-child(odd) {
      border-right: 1px solid var(--dls-divider-light-color);
    }
  }

  ${(props) =>
    !props.showMobileViewOnDesktop
      ? `
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: var(--dls-size-12);
      border: 0;
      .product-card-container {
        border-bottom: none;
        :nth-child(odd) {
          border-right: none;
        }
      }
    }

    @media screen and (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      .product-card-container {
        :nth-child(odd) {
          border-right: none;
        }
      }
    }
  `
      : `@media screen and (min-width: 768px) {
      border: 0;
      gap: var(--dls-size-12);
      padding: var(--dls-size-32);
      .product-card-container {
        border-bottom: none;
        :nth-child(odd) {
          border-right: none;
        }
      }
    }`}
`
