import noop from 'lodash/noop'

import {WebViewHeaderContainer} from './WebViewHeader.styles'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import Menu from './Menu'
import BackIcon from '../../../icons/BackIcon'

function WebViewHeader(props) {
  const {state = {}, actions = {}} = props

  const {
    title = '',
    isHeaderVisible = false,
    showMenu = false,
    hideBack = false,
  } = state
  const {onBackClick = noop, onMenuClick = noop} = actions

  if (!isHeaderVisible) {
    return null
  }

  return (
    <WebViewHeaderContainer>
      {!hideBack && 
      <button onClick={onBackClick} className="back-button">
        <BackIcon icon={faChevronLeft} />
      </button>
      }
      <span className="header-title">{title}</span>
      {showMenu && (
        <div onClick={onMenuClick}>
          <Menu />
        </div>
      )}
    </WebViewHeaderContainer>
  )
}

export default WebViewHeader
