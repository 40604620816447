import {getQueryParamFromUrl} from 'src/utils/url'

export const processMyProfileView = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    utm_source: getQueryParamFromUrl('utm_source'),
    utm_medium: getQueryParamFromUrl('utm_medium'),
  }

  return dataToReturn
}
