export const processConfirmationScreenV2Viewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    entry = '',
    appoinmentLabel = '',
    appointmentType,
    categoryTitle = '',
  } = data
  return {
    Entry: entry,
    'IA Booked': appointmentType === 'S' ? 'No' : 'Yes',
    Category: categoryTitle,
    'Appointment ID': appoinmentLabel,
  }
}

export const processConfirmationScreenV2DownloadAppClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    entry = '',
    appoinmentLabel = '',
    appointmentType,
    categoryTitle = '',
  } = data
  return {
    Entry: entry,
    'IA Booked': appointmentType === 'S' ? 'No' : 'Yes',
    Category: categoryTitle,
    'Appointment ID': appoinmentLabel,
  }
}

export const processConfirmationScreenV2EditAppointmentClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {entry = '', appointmentId, category, appointmentType} = data
  return {
    Entry: entry,
    'IA Booked': appointmentType === 'S' ? 'No' : 'Yes',
    Category: category,
    'Appointment ID': appointmentId,
  }
}

export const processConfirmationScreenV2RescheduleClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {entry = '', appointmentId, category, appointmentType} = data
  return {
    Entry: entry,
    'IA Booked': appointmentType === 'S' ? 'No' : 'Yes',
    Category: category,
    'Appointment ID': appointmentId,
  }
}

export const processConfirmationScreenV2AppointmentCancelled = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {entry = '', appointmentId, category, appointmentType} = data
  return {
    Entry: entry,
    'IA Booked': appointmentType === 'S' ? 'No' : 'Yes',
    Category: category,
    'Appointment ID': appointmentId,
  }
}
