import noop from 'lodash/noop'
import {useCallback, useRef} from 'react'

import {StyledModalBackground} from './styles'

/**
 * Use this component when you are creating a modal.
 * Pass the modal content inside of the modal background component.
 * put flex-grow: 1 in the css for the modal context
 */

export default function ModalBackground({
  children,
  clickHandler = noop,
  dismissHandler = null,
}) {
  const modalBackgroundRef = useRef()

  const handleBlur = useCallback(
    (event) => {
      if (dismissHandler) {
        if (modalBackgroundRef.current?.contains(event.target)) {
          dismissHandler()
        }
      } else {
        clickHandler(event)
      }
    },
    [dismissHandler, clickHandler]
  )

  return (
    <StyledModalBackground
      onClick={handleBlur}
      ref={modalBackgroundRef}
      className="modal-background"
    >
      {children}
    </StyledModalBackground>
  )
}
