import styled from 'styled-components'

export const SelectCategoryContainer = styled.div`
  padding: 0 16px;
  max-width: 1120px;
  margin: 24px auto 0 auto;

  .category-header {
    margin: 0 0 30px 0;
    text-align: center;
  }

  .category-header__title {
    font-size: 20px;
    font-weight: var(--brand-font-weight-bold);
    color: var(--brand-primary-black-color);
    line-height: 1;
    margin: 0 0 8px 0;
  }

  .category-header__subtitle {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    color: var(--brand-primary-teal-color);
    margin: 0;
    line-height: 1.5;
  }

  @media screen and (min-width: 768px) {
    .category-header__title {
      font-size: 28px;
      margin-bottom: 12px;
    }

    .category-header {
      margin: 0 0 40px 0;
    }

    .category-header__subtitle {
      font-size: 18px;
    }
  }
`

export const CategoryCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 16px;

  @media screen and (min-width: 768px) {
    place-content: center;
  }
`

export const CategoryCardTextBanner = styled.div`
  color: var(--brand-primary-white-color);
  font-size: 16px;
  font-weight: var(--brand-font-weight-semiBold);
  height: 48px;

  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .category-card-text {
    margin-right: 6px;
  }
`

export const CategoryScreenCardsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 768px) {
    max-width: 450px;
    margin: 30px auto 0 auto;
  }

  ${({order}) => {
    let sectionStyles = ''

    order.forEach((item, index) => {
      const isLastSection = index === order.length - 1

      if (isLastSection) {
        sectionStyles += `[data-section='${item}'] {order: ${index}; margin-bottom: 0 !important}`
        return
      }

      sectionStyles += `[data-section='${item}'] {order: ${index};}`
    })

    return sectionStyles
  }}
`
