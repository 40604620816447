import styled from 'styled-components'

export const TestimonialsWrapper = styled.div<{
  backgroundColor?: string
  labelColor?: string
  noOfSlides: number
}>`
  width: 100%;
  ${({backgroundColor}) =>
    backgroundColor ? `background: ${backgroundColor}` : ''};
  padding-bottom: var(--dls-size-0);
  border-radius: var(--dls-size-24) var(--dls-size-24) 0 0;

  .review_card_lists {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;
    gap: var(--dls-size-8);
  }

  .review_card_item {
    display: flex;
    flex-shrink: 0;
    padding: var(--dls-size-16);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--dls-size-8);
    border-radius: var(--dls-size-16);
    border: 1px solid var(--dls-divider-light-color);
    background: var(--dls-brand-secondary-color-300);

    ${({noOfSlides}) => {
      const slideValue = `calc((100% - ${
        16 + Math.floor(noOfSlides) * 8
      }px)/${noOfSlides})`

      return `width: ${slideValue};
      flex: 0 0 ${slideValue}
      `
    }};

    &:first-child {
      margin-left: var(--dls-size-16);
    }
    &:last-child {
      margin-right: var(--dls-size-16);
    }
  }
  .review_card_lists::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .review_card_imgs_container {
    width: 100%;
    display: flex;
  }

  .review_card_img {
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: var(--dls-size-16);
  }

  .review_card_details {
    display: grid;
    gap: var(--dls-size-8);
  }

  .review_card_details_title {
    ${({labelColor}) => (labelColor ? `color: ${labelColor}` : '')};
  }

  .review_card_details_pill {
    width: fit-content;
    display: flex;
    padding: var(--dls-size-4) var(--dls-size-8);
    justify-content: center;
    align-items: center;
    border-radius: var(--dls-size-16);
    border: 0.5px solid #e3e3e3;
    background: #f2f2e8;
    color: var(--dls-brand-primary-color-300);
  }

  .review_card_details_description {
    margin: 0;
    color: var(--Light-Text-Primary-text, #212121);
    align-self: stretch;
    line-height: 150%;
  }

  .author-container {
    display: flex;
    align-items: center;
    gap: var(--dls-size-4);
  }
  .author-text {
    display: flex;
    align-items: center;
    gap: var(--dls-size-2);
  }
  .rating-container {
    display: flex;
    align-items: center;
    gap: var(--dls-size-2);
  }
`
