export const loadScript = async (src, onLoadCallback) => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      onLoadCallback()
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.head.appendChild(script)
  })
}
