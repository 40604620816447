export const imageWidthMappings = {
  desktop: {
    FULL: 1080,
    HALF: 800,
    THIRD: 500,
    SMALL: 150,
    TINY: 50,
  },
  mobile: {
    FULL: 800,
    HALF: 500,
    THIRD: 300,
    SMALL: 150,
    TINY: 50,
  },
}
