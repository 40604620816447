import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: min(20px, calc(16px + (20 - 16) * ((100vw - 360px) / (1440 - 360))));
  flex-direction: column;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);
  font-family: var(--brand-font-family-text);

  &:last-child {
    border-bottom: none;
  }

  .web__bottom {
    flex-direction: column;
    display: flex;
    width: 100%;
  }

  .mob__top {
    display: flex;
    flex-direction: row;
  }

  .mob__title {
    margin-top: 12px;
  }

  .review__body {
    margin-top: 4px;
    color: var(--brand-primary-black-color);
    width: 100%;
  }

  .reply {
    margin-top: 12px;
    color: var(--brand-primary-grey-color);
    font-size: 14px;
    width: 100%;
    border-left: 2px solid var(--brand-primary-grey-lighter-color);
    padding-left: 10px;
  }

  .ratings-and-reviews__star-box {
    display: flex;
    height: fit-content;
    justify-content: center;
    align-items: center;
    font-size: min(
      16px,
      calc(12px + (16 - 16) * ((100vw - 360px) / (1440 - 360)))
    );
    background-color: var(--category-primary-color);
    color: var(--brand-primary-white-color);
    border-radius: min(
      6px,
      calc(4px + (6 - 4) * ((100vw - 360px) / (1440 - 360)))
    );
    margin-right: min(
      16px,
      calc(8px + (16 - 8) * ((100vw - 360px) / (1440 - 360)))
    );
    padding: 2px
      min(6px, calc(4px + (6 - 4) * ((100vw - 360px) / (1440 - 360))));
  }
  .ratings-and-reviews__star-box span {
    font-size: min(
      16px,
      calc(10px + (16 - 16) * ((100vw - 360px) / (1440 - 360)))
    );
  }
  .ratings-and-reviews__star-box p {
    margin: 2px 2px 0 0;
  }

  @media (min-width: 768px) {
    .reply {
      font-size: 20px;
    }
  }
`
