import styled from 'styled-components'

export const AppointmentConfirmedTopSheetWrapper = styled.div`
  .appointment-confirmed-top-sheet-modal-backdrop {
    align-items: baseline;
    align-content: baseline;
  }
  .appointment-confirmed-top-sheet-modal-content {
    padding: 0px;
    border-radius: 0px 0px 30px 30px;
  }
  .content-backdrop-color {
    position: absolute;
    height: 55px;
    width: 100%;
    background: var(--category-primary-color);
  }
  .card-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px;
    flex-direction: column;
  }
  .card {
    border: 1px solid var(--brand-primary-white-color);
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    background: var(--brand-primary-white-color);
    width: 100%;
    padding: 12px;
    z-index: 1;
  }
  .image-container {
    display: flex;
    justify-content: center;
  }
  .tick-image {
    width: 60px;
    height: 64px;
  }
  .title {
    color: var(--brand-primary-teal-color);
    font-weight: var(--brand-font-weight-heavy);
    font-size: 20px;
    line-height: 120%;
    text-align: center;
  }
  .appointment-details-container {
    display: flex;
    justify-content: space-around;
    margin-top: 6px;
  }
  .appointment-details-heading {
    font-weight: var(--brand-font-weight-light);
    font-size: 12px;
    line-height: 140%;
    opacity: 0.7;
    color: var(--brand-primary-grey-color);
  }
  .appointment-details {
    font-weight: var(--brand-font-weight-normal);
    font-size: 14px;
    line-height: 120%;
    margin-top: 4px;
    margin-bottom: 10px;
    color: var(--brand-primary-black-color);
  }
  .description {
    font-weight: var(--brand-font-weight-normal);
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    padding: 6px 8px;
    color: var(--brand-primary-black-color);
  }
  .subtitle {
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    color: var(--brand-primary-grey-color);
    opacity: 0.6;
  }
  .bottom-rectangle-container {
    display: flex;
    justify-content: center;
    padding-top: 16px;
  }
  .bottom-rectangle {
    width: 32px;
    height: 4px;
    background: #22548a;
    opacity: 0.2;
    border-radius: 4px;
  }
  @media screen and (min-width: 768px) {
    .appointment-confirmed-top-sheet-modal-content {
      padding: 0px;
      border-radius: 20px;
      margin: 30px;
    }
    .appointment-confirmed-top-sheet-modal-button {
    }
    .content-backdrop-color {
      height: 133px;
      border-radius: 20px 20px 0px 0px;
    }
    .card-container {
      padding: 30px 20px;
    }
    .card {
      width: 410px;
    }
    .tick-image {
      width: 112px;
      height: 120px;
    }
    .title {
      font-size: 24px;
      line-height: 120%;
    }
    .appointment-details-heading {
      font-size: 16px;
      line-height: 16px;
    }
    .appointment-details {
      font-size: 18px;
      line-height: 18px;
    }
    .description {
      font-size: 16px;
      line-height: 130%;
    }
    .subtitle {
      font-size: 14px;
    }
  }
`
