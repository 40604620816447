import React from 'react'
import {ComparisonTableContainer} from './ComparisonTable.styles'
import {IComparisonTableProps} from './ComparisonTable.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import {isEmpty} from 'lodash'
import {OptimizedImage, Typography} from '@web-components'

function ComparisonTable(props: IComparisonTableProps) {
  const {id = '', type = '', widgetData, header, layout} = props

  const {tableData, tableHeader, icons} = widgetData || {}
  const {us = 'Us', others = 'Others'} = tableHeader || {}
  const {checkIcon = '', crossIcon = ''} = icons || {}

  if (isEmpty(tableData)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <ComparisonTableContainer>
        <div className="header-container">
          <div className="header-item header-item-us">
            <Typography variant="cta-label-sm">{us}</Typography>
          </div>
          <div className="header-item header-item-others">
            <Typography variant="cta-label-sm"> {others}</Typography>
          </div>
        </div>
        <div className="comparison-container">
          {tableData.map((item) => (
            <div className="table-data-row">
              <div className="table-data-row-item">
                <OptimizedImage
                  source={checkIcon}
                  aspectHeight="16"
                  aspectWidth="16"
                />
                <Typography variant="body-base-regular">{item.us}</Typography>
              </div>
              <div className="table-data-row-item">
                <OptimizedImage
                  source={crossIcon}
                  aspectHeight="16"
                  aspectWidth="16"
                />
                <Typography variant="body-base-regular">
                  {item.others}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </ComparisonTableContainer>
    </BaseWidgetComponent>
  )
}

export default ComparisonTable
