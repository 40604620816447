import styled from 'styled-components'

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--dls-size-64);
  margin-bottom: var(--dls-size-64);
  padding: 0px var(--dls-size-56);
  text-align: center;

  .image {
    height: 110px;
    width: 110px;
  }

  .title-container {
    margin-top: var(--dls-size-16);
  }

  .subtitle-container {
    margin-top: var(--dls-size-4);
    color: var(--brand-light-secondary-text-color);
  }
`
