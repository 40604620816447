import React from 'react'
import isEmpty from 'lodash/isEmpty'

import InfoCard from '../InfoCard'
import {InfoCardTitle, InfoCardsWrapper} from './InfoCardSection.styles'

function InfoCardSection(props) {
  const {infoCardTitle = '', infoCards = ''} = props.state || {}

  if (isEmpty(infoCards)) {
    return null
  }

  return (
    <InfoCardsWrapper data-section="infoCardSection">
      <InfoCardTitle>{infoCardTitle}</InfoCardTitle>
      {infoCards.map((infoCard) => (
        <InfoCard key={infoCard.text} state={{...infoCard}} />
      ))}
    </InfoCardsWrapper>
  )
}

export default InfoCardSection
