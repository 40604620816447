import styled from 'styled-components'
import {MobileCategoryPaletteContainerProps} from './MobilePaletteCard.interface'

export const MobileCategoryPaletteContainer = styled.a<MobileCategoryPaletteContainerProps>`
  margin: 0 15px 16px 15px;
  display: flex;
  padding-bottom: 0;
  background-color: ${(props) => props.bgColor};
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;

  .content-container {
    display: flex;
    flex-direction: column;
    font-family: var(--dls-brand-font-family);
    font-style: normal;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: center;
    width: 60%;
    text-decoration: none;
    color: var(--dls-black-color);

    .categoryName {
      display: inline;
      margin-right: 14px;
    }
    .description {
      color: var(--dls-secondary-text-colors);
    }
  }

  .image-container {
    width: 40%;
    height: 100%;
    object-fit: contain;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: ${(props) => props.imageBgColor};
    padding: 10px 12px;
    padding-bottom: 0;
    .card-image {
      width: 120px;
      height: 140px;
      height: auto;
    }
  }
`
