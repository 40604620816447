import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import Heading from './Heading/Heading.component'
import {IHeader} from './Heading/Heading.types'

function DoctorListingHeading(props: BaseWidgetProps<IHeader>) {
  const {type, widgetData, header, layout, id} = props || {}
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <Heading {...widgetData} />
    </BaseWidgetComponent>
  )
}

export default DoctorListingHeading
