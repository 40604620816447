export const processVerifyVpaClicked = (data: any) => {
  const {userVpa = ''} = data || {}

  return {
    'User UPI Vpa': userVpa,
  }
}

export const processVerifyVpaResolved = (data: any) => {
  const {userVpa = '', valid = false} = data || {}

  return {
    'User UPI Vpa': userVpa,
    'Vpa Valid': valid,
  }
}
