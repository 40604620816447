import React from 'react'
import {LoadingState} from '../../shared/src/ComponentsV2/common'
import {StyledLoading} from './AllQuestionsLoading.styles'
import Responsive from '../../shared/src/ComponentsV2/HOC/Responsive'

function AllQuestionsTopSkeleton() {
  return (
    <StyledLoading>
      <div className="header">
        <div className="center">
          <LoadingState cardHeight={30} numberOfCards={1} />
        </div>
        <div className="full-width">
          <LoadingState cardHeight={36} />
        </div>
        <div className="images">
          <LoadingState cardHeight={40} cardWidth="100px" />
          <LoadingState cardHeight={40} cardWidth="100px" />
          <LoadingState cardHeight={40} cardWidth="100px" />
        </div>
        <div className="left-half">
          <LoadingState cardHeight={30} numberOfCards={1} />
        </div>
        <br />
      </div>
    </StyledLoading>
  )
}
export default Responsive(AllQuestionsTopSkeleton)
