import React from 'react'
import {MiniAssessmentContainer} from './MiniAssessment.styles'
import {IMiniAssessmentProps} from './MiniAssessment.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import useMiniAssessment from './useMiniAssessment'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import OptionsList from './OptionsList/OptionsList'
import SuggestedProductCard from './SuggestedProductCard/SuggestedProductCard'
import {isEmpty} from 'lodash'

function MiniAssessment(props: IMiniAssessmentProps) {
  const {id = '', type = '', widgetData, header, layout} = props
  const {
    action,
    ctaLabel = 'Next →',
    loadingGif = 'https://i.mscwlns.co/media/misc/others/loading_ji76do.gif?tr=w-100',
  } = widgetData || {}

  const [
    {
      currentQuestionData,
      selectedIds,
      isFormComplete,
      normalizedPdpData,
      isNextButtonDisabled,
      isFetchingPdpData,
    },
    {onValueChange, onNextPressed, onRetakeTest, onViewProductClick},
  ] = useMiniAssessment({action})
  const {title = '', options = []} = currentQuestionData || {}

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <MiniAssessmentContainer>
        <div className="header-container">
          <BaseWidgetComponent.Header header={header} />
          <button className="retake-test-button" onClick={onRetakeTest}>
            <Typography variant={TypographyVariants.TAG_BOLD}>
              {'Retake test'}
            </Typography>
          </button>
        </div>
        <div className="assessment-container">
          {!isFormComplete && (
            <>
              <Typography
                customClassName="assessment-questions-title"
                variant={TypographyVariants.SUBHEADING_BOLD}
              >
                {title}
              </Typography>

              <OptionsList
                options={options}
                onValueChange={onValueChange}
                values={selectedIds}
              />

              <button
                disabled={isNextButtonDisabled}
                className="next-button"
                onClick={onNextPressed}
              >
                <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
                  {ctaLabel}
                </Typography>
              </button>
            </>
          )}

          {isFetchingPdpData && (
            <div className="loader">
              <OptimizedImage
                source={loadingGif}
                aspectHeight={'48'}
                aspectWidth={'48'}
                customClassName="loading-gif"
              />
              <Typography
                variant={TypographyVariants.SUBHEADING_BOLD}
                customClassName="loading-text"
              >
                {'Finding the right product for you'}
              </Typography>
            </div>
          )}

          {!isEmpty(normalizedPdpData) && (
            <SuggestedProductCard
              {...normalizedPdpData}
              onViewProductClick={onViewProductClick}
            />
          )}
        </div>
      </MiniAssessmentContainer>
    </BaseWidgetComponent>
  )
}

export default MiniAssessment
