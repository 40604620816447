import {useCallback, useState} from 'react'
import noop from 'lodash/noop'

import {checkIsApp} from 'src/utils/checkIsApp'
import {useGenericCta, useRedirection} from '..'
import {IUseDaFomoCardReturnType} from './directAssessment.interface'
import {useCommsActiveRoute} from '@mosaic-wellness/redux-action-library'

function useDaFomoCard(): IUseDaFomoCardReturnType {
  const [showDismissModal, setShowDismissModal] = useState<boolean>(false)
  const isApp = checkIsApp()
  const {handleCta = noop} = useGenericCta()
  const [{data}] = useCommsActiveRoute()

  const [{currentConsultVersion = ''}, {handleRedirection = noop}] =
    useRedirection()

  const toggleDismissModal = useCallback(
    () => setShowDismissModal((isVisible) => !isVisible),
    []
  )

  const onFomoCardSecondaryCtaClick = useCallback(() => {
    if (isApp) {
      handleRedirection({
        action: 'SPA_LINK',
        link: data?.link,
        version: currentConsultVersion,
      })
      return
    }
    window.location.href = window.location.origin
  }, [currentConsultVersion, data?.link, handleRedirection, isApp])

  return [{showDismissModal}, {toggleDismissModal, onFomoCardSecondaryCtaClick}]
}

export {useDaFomoCard}
