import styled from 'styled-components'

export const WeGotAnswersContainer = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: auto;
  padding: 0px 16px;
  margin-bottom: 40px;

  .heading {
    margin: 0;
  }
  .subHeading {
    font-weight: var(--brand-font-weight-normal);
    font-size: 14px;
    line-height: 19px;
    color: var(--brand-primary-grey-color);
    opacity: 0.6;
  }
  @media screen and (min-width: 768px) {
    padding: 0px;
    margin-bottom: 100px;
  }
`
