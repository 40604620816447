import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import React, {useCallback, useState} from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {ImageSliderContainer} from './ImageSlider.styles'
import {GenericSlider, OptimizedImage} from '@web-components'
import {ImageSliderProps} from './ImageSlider.interface'

function ImageSlider({
  widgetData,
  id,
  header,
  layout,
  type,
}: BaseWidgetProps<ImageSliderProps>) {
  const {
    images = [],
    aspectRatio = 1,
    slidesToShow = 1,
    startFromSlide = 1,
    showDots = false,
    leftSpace = false,
  } = widgetData

  const [activeSlide, setActiveSlide] = useState(0)
  const showCarouselDots = showDots && images.length > 1
  const onSlideChange = useCallback((index: number) => {
    setActiveSlide(index)
  }, [])

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ImageSliderContainer data-is-LeftSpace={leftSpace}>
        <GenericSlider
          noOfSlides={slidesToShow}
          aspectRatio={aspectRatio}
          startFromSlide={startFromSlide}
          onSlideChange={onSlideChange}
        >
          {({imageDimensions}: {imageDimensions: number[]}) =>
            images.map((image, index) => {
              return (
                <OptimizedImage
                  source={image}
                  aspectWidth={imageDimensions[0]?.toString()}
                  aspectHeight={imageDimensions[1]?.toString()}
                  desktopViewWidth="THIRD"
                  mobileViewWidth="HALF"
                  altText="oops"
                  loading={
                    index === 0 || (startFromSlide && index === 1)
                      ? 'eager'
                      : 'lazy'
                  }
                  customClassName="slider-image"
                  key={index}
                />
              )
            })
          }
        </GenericSlider>
        {showCarouselDots && (
          <div className="dots">
            {images.map((image, index) => (
              <div
                key={index}
                className="dot"
                data-is-active={activeSlide === index}
              ></div>
            ))}
          </div>
        )}
      </ImageSliderContainer>
    </BaseWidgetComponent>
  )
}

export default ImageSlider
