export function generateRandomString(stringLength) {
  function e() {
    return n ? 15 & n[r++] : (16 * Math.random()) | 0
  }
  var n = null,
    r = 0,
    o = window.crypto || window.msCrypto
  o &&
    o.getRandomValues &&
    Uint8Array &&
    (n = o.getRandomValues(new Uint8Array(stringLength)))
  for (var i = [], a = 0; a < stringLength; a++) i.push(e().toString(16))
  return i.join('')
}
