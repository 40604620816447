import React from 'react'
import {StyledLoader} from './SearchResultsLoader.styles'

const SearchResultsLoader = () => {
  return (
    <StyledLoader className={`loading-container`}>
      <div className="loading-card shimmer" />
    </StyledLoader>
  )
}

export default SearchResultsLoader
