import React, {useEffect} from 'react'
import {Backdrop} from '../Drawer'
import {StyledDrawerWrapper} from './styles'

const TopDrawer = ({state, children, handleOnClose, transitionTime}) => {
  const backdropClickHandler = handleOnClose

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (state.isOpen) {
        return (window.document.body.style.overflow = 'hidden')
      }
      return (window.document.body.style.overflow = 'auto')
    }
  }, [state.isOpen])

  useEffect(() => {
    return () => {
      window.document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <StyledDrawerWrapper
      className="drawer__wrapper"
      transitionTime={transitionTime || 500}
    >
      <div
        data-drawer-open={state.isOpen?.toString() ?? 'false'}
        className="drawer open"
      >
        {children}
      </div>
      <Backdrop
        transitionTime={transitionTime || 500}
        isOpen={state.isOpen}
        close={backdropClickHandler}
      />
    </StyledDrawerWrapper>
  )
}

export default TopDrawer
export {Backdrop}
