import NextButton from '../NextButton'
import AppointmentCard from './AppointmentCard'
import InfoCardSection from './InfoCardSection/InfoCardSection'
import ScheduleHeader from './ScheduleHeader'
import {ScheduleScreenContainer} from './ScheduleScreen.styles'
import {faPhoneAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

function ScheduleScreen(props) {
  const {state = {}, actions = {}} = props

  const {
    schedule_token = '',
    // consult config data
    scheduleScreenUIData = {},
    // slots data
    isLoggedIn = false,
    slotData,
    bookData,
    bookError,
    isLoading,
    email,
    phoneNumber,
    category = '',
    brand = '',
    isReschedule = false,
    // reschedule data
    isRescheduleInProgress,
    rescheduleAppointmentData,
    isBookSlotInProgress = false,
    secondaryUtmSource = '',
    primaryUtmSource = '',
    FUQuestions = {},
    recommendedProducts = [],
    pendingOrderAppointment = {},
    pendingOrderAppointmentAvailable = false,
    showInfoCards = false,
    source = 'DA',
    sourceId = 'website',
    rxCategories = null,
    getRescheduledDataUsingGetAppointmentDetails = false,
    isRescheduleDisabled = false,
    analyticsDataForCommsReschedule = {},
    userDiagnosis = {},
    /* flags to Order flow (checkout-v2 usecase) */
    isOrderFlow = false,
    languageSelectedByUser = '',
    languageListForOrderFlow = null,
    skipSlotsTransformation = false,
    /* flags to Order flow (checkout-v2 usecase) - end */
  } = state

  const {
    getSlotsData = noop,
    bookSlotsData = noop,
    setShowLoginModal = noop,
    toast = noop,
    handleNextStep = noop,
    updateAppointmentDetails = noop,
    rescheduleAppointment = noop,
    resetRescheduleAppointmentData = noop,
    closeRescheduleModal = noop,
    checkFutureAppointments = noop,
    setIsAppointmentScheduled = noop,
    //analytics trigger callback
    trackScheduleScreen = noop,
    removeDAViewed = noop,
    updateStep = noop,
    onSelfAssessmentAppointmentSubmitted = noop,
    getAppointmentDetails = noop,
    onRescheduleLanguageChanged = noop,
    onRescheduleScreenConfirmClicked = noop,
    getFloatingIslandNudgeData = noop,
    onCommsRescheduleConfirmed = noop,
    onPendingOrderRescheduleSubmit = noop,
    onPendingOrderRescheduleConfirmed = noop,
    /* callback for Order flow (checkout-v2) usecase */
    onSaveChangesClicked = noop,
    onLanguageChangeForCheckPrescription = noop,
    /* callback for Order flow (checkout-v2) usecase - end */
  } = actions

  const {
    instantAppointment = {},
    scheduleAppointment = {},
    errorMessages = {},
  } = scheduleScreenUIData

  const {
    selectedSlotNotAvailable = '',
    IANotAvailable = '',
    SlotNotAvailableInSelectedLanguage = '',
    IANotAvailableInSelectedLanguage = '',
  } = errorMessages

  const [selectedLanguage, setSelectedLanguage] = useState(
    languageSelectedByUser ? languageSelectedByUser : 'English'
  )
  const [selectedDate, setSelectedDate] = useState('Not Available')
  const [selectedTime, setSelectedTime] = useState('Not Available')
  const [selectedSlot, setSelectedSlot] = useState('Not Available')
  const [doctorId, setDoctorId] = useState('')
  const [lsdSelected, setLsdSelected] = useState(false)
  const [error, setError] = useState(0)
  const [isIAAvailable, setIsIAAvailable] = useState(false)
  const [wasIAAvailableBefore, setWasIAAvailableBefore] = useState(false)
  const [toggle, setToggle] = useState(false)

  const isNextButtonDisabled = useMemo(() => {
    switch (true) {
      case isIAAvailable && !toggle:
        return false

      default:
        return selectedDate === 'Not Available'
    }
  }, [isIAAvailable, selectedDate, toggle])

  const triggerToggle = useCallback(() => {
    trackScheduleScreen({type: 'toggle', toggleOn: !toggle})
    setToggle(!toggle)
  }, [trackScheduleScreen, toggle])

  useEffect(() => {
    setToggle(!isIAAvailable)
  }, [isIAAvailable])

  // Error messages wrapper
  const errorMessagesSwitch = useMemo(() => {
    switch (error) {
      case 1:
        return selectedSlotNotAvailable
      case 2:
        return IANotAvailable
      case 3:
        return SlotNotAvailableInSelectedLanguage
      case 4:
        return IANotAvailableInSelectedLanguage
      default:
        return ''
    }
  }, [
    error,
    selectedSlotNotAvailable,
    IANotAvailable,
    SlotNotAvailableInSelectedLanguage,
    IANotAvailableInSelectedLanguage,
  ])

  const normalCategory = useMemo(() => {
    return !isEmpty(rxCategories) ? rxCategories : [category]
  }, [category, rxCategories])

  const slotPayload = useMemo(() => {
    const payload = {
      category: pendingOrderAppointmentAvailable
        ? pendingOrderAppointment?.rxCategories
        : normalCategory,
      slot_time_period: category === 'weight' ? 30 : 10,
      brand,
      email,
      phone: phoneNumber,
      language: selectedLanguage,
      secondary_source: secondaryUtmSource,
      primary_source: primaryUtmSource,
      source,
      source_id: sourceId,
    }
    return payload
  }, [
    pendingOrderAppointmentAvailable,
    pendingOrderAppointment?.rxCategories,
    normalCategory,
    category,
    brand,
    email,
    phoneNumber,
    selectedLanguage,
    secondaryUtmSource,
    primaryUtmSource,
    source,
    sourceId,
  ])

  const handleLanguageChange = useCallback(
    (item) => {
      trackScheduleScreen({type: 'languageChanged', language: item})
      onRescheduleLanguageChanged({
        language: item,
        categoryTitle: analyticsDataForCommsReschedule?.categoryTitle,
        appointmentReference:
          analyticsDataForCommsReschedule?.appointmentReference,
        imageUploaded: analyticsDataForCommsReschedule?.imageUploaded,
        basicDetailsUpdated:
          analyticsDataForCommsReschedule?.basicDetailsUpdated,
        source: analyticsDataForCommsReschedule?.source,
        appointmentType: analyticsDataForCommsReschedule?.appointmentType,
        mode: analyticsDataForCommsReschedule?.mode,
      })
      setSelectedLanguage(item)
      onLanguageChangeForCheckPrescription(item)
    },
    [
      analyticsDataForCommsReschedule,
      onRescheduleLanguageChanged,
      trackScheduleScreen,
      onLanguageChangeForCheckPrescription,
    ]
  )

  const handleTimeChange = useCallback((item) => {
    setSelectedTime(item)
  }, [])

  const handleSlotChanged = useCallback((item) => {
    setSelectedSlot(item)
  }, [])

  const handleDoctorId = useCallback((item) => {
    setDoctorId(item)
  }, [])

  const handleLsdSelected = useCallback(() => {
    setLsdSelected(!lsdSelected)
  }, [lsdSelected])

  const handleErrorChange = useCallback((item) => {
    setError(item)
  }, [])

  const handleDateChange = useCallback(
    (item) => {
      setSelectedDate(item)
      if (item === 'Not Available') {
        handleErrorChange(3)
      } else if (error === 3) {
        handleErrorChange(0)
      }
    },
    [error, handleErrorChange]
  )

  const shouldFetchSlotsData = useMemo(() => {
    return (
      !isEmpty(slotPayload?.category) &&
      !slotPayload?.category.every((item) => item === '')
    )
  }, [slotPayload?.category])

  useEffect(() => {
    if (shouldFetchSlotsData) {
      getSlotsData(slotPayload)
    }
  }, [slotPayload])

  useEffect(() => {
    if (slotData && slotData.isIAAvailable) {
      setIsIAAvailable(true)
      return
    }
    setIsIAAvailable(false)
  }, [slotData])

  useEffect(() => {
    if (slotData && slotData.isIAAvailable) {
      setIsIAAvailable(true)
      setWasIAAvailableBefore(true)
      handleErrorChange(0)
    } else if (wasIAAvailableBefore && error !== 2) {
      setIsIAAvailable(false)
      handleErrorChange(4)
    } else {
      setIsIAAvailable(false)
    }
  }, [error, slotData, wasIAAvailableBefore])

  // Post booking flow handle
  useEffect(() => {
    if (bookData) {
      setIsAppointmentScheduled()
      //update appointment details in context using callback
      updateAppointmentDetails({
        ...bookData,
        showAppointmentCardButtons: false,
        type: !toggle ? 'IA' : 'S',
        language: selectedLanguage,
      })
      trackScheduleScreen({
        type: 'booked',
        language: selectedLanguage,
        slot: selectedSlot,
        iaBooked: !toggle,
        phoneNumber: bookData?.phone,
        appointmentId: bookData?.id,
        diagnosisData: bookData?.diagnosisData,
        imageAvailable: bookData?.imageAvailable,
      })
      removeDAViewed()
      //Refetch nudges data for bottom floating bar
      getFloatingIslandNudgeData()
      handleNextStep({
        daType: !toggle ? 'IA' : 'S',
        daScheduleToken: bookData?.schedule_token || '',
      })
    }
    if (bookError) {
      isIAAvailable ? handleErrorChange(2) : handleErrorChange(1)
      getSlotsData(slotPayload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookData,
    bookError,
    updateAppointmentDetails,
    isIAAvailable,
    slotPayload,
  ])

  const bookIntentShowByUser = useRef({intentShown: false, data: {}})

  const handleConfirm = useCallback(
    (data) => {
      trackScheduleScreen({
        type: 'submit',
        slot: data?.slot,
        language: data?.language,
        iaBooked: data?.immediate ? true : false,
      })
      onSelfAssessmentAppointmentSubmitted()
      if (!isLoggedIn) {
        bookIntentShowByUser.current = {intentShown: true, data}
        setShowLoginModal({isOpen: true, triggeredFrom: ''})
        return
      }
      bookSlotsData({
        ...data,
        ...slotPayload,
        FU_ques: FUQuestions,
        recommended_products: recommendedProducts,
        source,
        userDetails: userDiagnosis,
      })
    },
    [
      bookSlotsData,
      isLoggedIn,
      setShowLoginModal,
      slotPayload,
      trackScheduleScreen,
      FUQuestions,
      recommendedProducts,
      onSelfAssessmentAppointmentSubmitted,
      source,
      userDiagnosis,
    ]
  )

  const futureAppointmentDataLoaded = useRef(false)

  useEffect(() => {
    async function fetchFutureAppointmentData() {
      if (
        bookIntentShowByUser.current.intentShown &&
        isLoggedIn &&
        !futureAppointmentDataLoaded.current
      ) {
        await checkFutureAppointments()
        futureAppointmentDataLoaded.current = true
      }
    }
    fetchFutureAppointmentData()
  }, [checkFutureAppointments, isLoggedIn])

  useEffect(() => {
    if (
      bookIntentShowByUser.current.intentShown &&
      isLoggedIn &&
      futureAppointmentDataLoaded.current
    ) {
      if (pendingOrderAppointmentAvailable) {
        handleNextStep()
        updateStep('scheduleConfirmation')
        return
      }
      bookSlotsData({
        ...bookIntentShowByUser.current.data,
        ...slotPayload,
        userDetails: userDiagnosis,
      })
    }
  }, [isLoggedIn, slotPayload, pendingOrderAppointmentAvailable, userDiagnosis])

  const handleBooksSlots = useCallback(() => {
    let bookSlotsPayload = {
      language: selectedLanguage,
      date: selectedDate,
      slot: selectedSlot,
    }
    if (isIAAvailable && !toggle) {
      bookSlotsPayload = {
        language: selectedLanguage,
        immediate: true,
      }
    }

    if (lsdSelected) {
      bookSlotsPayload = {
        ...bookSlotsPayload,
        doctor_id: doctorId,
      }
    }

    handleConfirm(bookSlotsPayload)
  }, [
    doctorId,
    handleConfirm,
    isIAAvailable,
    lsdSelected,
    selectedDate,
    selectedLanguage,
    selectedSlot,
    toggle,
  ])

  const reschedulePayload = useMemo(() => {
    return {
      language: selectedLanguage,
      appointment_date: selectedDate,
      slot: selectedSlot,
      schedule_token: pendingOrderAppointmentAvailable
        ? pendingOrderAppointment?.scheduleToken
        : schedule_token,
      mode: 'bw_call',
      reschedule_by: 'patient',
      selected_doctor_id_for_reschedule: lsdSelected ? doctorId : '',
      immediate: !toggle,
    }
  }, [
    doctorId,
    lsdSelected,
    pendingOrderAppointment,
    pendingOrderAppointmentAvailable,
    schedule_token,
    selectedDate,
    selectedLanguage,
    selectedSlot,
    toggle,
  ])
  const slot = useMemo(() => {
    if (reschedulePayload.immediate) {
      return ''
    } else {
      return `${selectedDate}, ${selectedSlot}`
    }
  }, [reschedulePayload, selectedDate, selectedSlot])

  useEffect(() => {
    if (rescheduleAppointmentData) {
      const {SlotFull = ''} = rescheduleAppointmentData
      if (pendingOrderAppointmentAvailable) {
        const isIA = reschedulePayload.immediate
        // this is for analytics
        onPendingOrderRescheduleConfirmed({
          category,
          language: selectedLanguage,
          ia: isIA,
          slot,
        })
      }
      if (SlotFull === 'true') {
        toggle ? handleErrorChange(1) : handleErrorChange(2)
        return
      } else if (getRescheduledDataUsingGetAppointmentDetails) {
        getAppointmentDetails({scheduleToken: schedule_token})
        //Refetch nudges data for bottom floating bar
        getFloatingIslandNudgeData()
      } else {
        checkFutureAppointments()
        //Refetch nudges data for bottom floating bar
        getFloatingIslandNudgeData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rescheduleAppointmentData])

  const handleReschedule = useCallback(async () => {
    const pendingOrderCategory = pendingOrderAppointment?.category
    const isIA = reschedulePayload.immediate
    onPendingOrderRescheduleSubmit({
      category: pendingOrderCategory,
      language: selectedLanguage,
      ia: isIA,
      slot,
    })
    await rescheduleAppointment(reschedulePayload)
    resetRescheduleAppointmentData()
    closeRescheduleModal()
    onRescheduleScreenConfirmClicked({
      ...reschedulePayload,
      category: slotPayload.category,
    })
    onCommsRescheduleConfirmed({
      categoryTitle: analyticsDataForCommsReschedule?.categoryTitle,
      appointmentReference:
        analyticsDataForCommsReschedule?.appointmentReference,
      imageUploaded: analyticsDataForCommsReschedule?.imageUploaded,
      basicDetailsUpdated: analyticsDataForCommsReschedule?.basicDetailsUpdated,
      source: analyticsDataForCommsReschedule?.source,
      appointmentType: analyticsDataForCommsReschedule?.appointmentType,
      mode: analyticsDataForCommsReschedule?.mode,
      appointmentState: analyticsDataForCommsReschedule?.isFuture
        ? 'Future'
        : 'Pending',
    })
  }, [
    pendingOrderAppointment?.category,
    reschedulePayload,
    onPendingOrderRescheduleSubmit,
    selectedLanguage,
    slot,
    rescheduleAppointment,
    resetRescheduleAppointmentData,
    closeRescheduleModal,
    onRescheduleScreenConfirmClicked,
    slotPayload?.category,
    analyticsDataForCommsReschedule,
    onCommsRescheduleConfirmed,
  ])

  /* Wrapper for onSaveChangesClicked */
  const handleSaveChangesClicked = useCallback(() => {
    onSaveChangesClicked({
      language: selectedLanguage,
      date: selectedDate,
      slot: selectedSlot,
      immediate: !toggle,
    })
  }, [
    selectedDate,
    selectedLanguage,
    selectedSlot,
    toggle,
    onSaveChangesClicked,
  ])

  const ctaButtonCallback = useMemo(() => {
    if (isReschedule) {
      return handleReschedule
    }

    if (isOrderFlow) {
      return handleSaveChangesClicked
    }

    return handleBooksSlots
  }, [
    handleBooksSlots,
    handleReschedule,
    handleSaveChangesClicked,
    isOrderFlow,
    isReschedule,
  ])

  const consultUIData = useMemo(() => {
    const {subtitle: iaSubtitle, card: iaCard} = instantAppointment
    const {subtitle, card: scheduleCard} = scheduleAppointment
    const {title = '', infoCards = '', infoCardTitle = ''} = instantAppointment
    return {
      title,
      infoCards,
      infoCardTitle,
      subtitle: isIAAvailable && !toggle ? iaSubtitle : subtitle,
      card:
        isIAAvailable && !toggle
          ? {
              ...iaCard,
              languageList: languageListForOrderFlow || iaCard.languageList,
            }
          : {
              ...scheduleCard,
              languageList:
                languageListForOrderFlow || scheduleCard?.languageList,
            },
    }
  }, [
    instantAppointment,
    isIAAvailable,
    languageListForOrderFlow,
    scheduleAppointment,
    toggle,
  ])

  useEffect(() => {
    if (errorMessagesSwitch && !isLoading) {
      toast('warning', errorMessagesSwitch)
    }
  }, [errorMessagesSwitch, isLoading, toast])

  const appointmentCardProps = useMemo(() => {
    return {
      state: {
        card: consultUIData.card,
        selectedLanguage,
        selectedDate,
        selectedTime,
        selectedSlot,
        isLoggedIn,
        slotData,
        bookData,
        bookError,
        isIAAvailable,
        isLoading,
        toggle,
        doctorId,
        lsdSelected,
        skipSlotsTransformation,
      },
      actions: {
        getSlotsData,
        bookSlotsData,
        handleLanguageChange,
        handleDateChange,
        handleTimeChange,
        handleSlotChanged,
        handleDoctorId,
        handleLsdSelected,
        handleConfirm,
        handleErrorChange,
        triggerToggle,
        trackScheduleScreen,
      },
    }
  }, [
    consultUIData.card,
    selectedLanguage,
    selectedDate,
    selectedTime,
    selectedSlot,
    isLoggedIn,
    slotData,
    bookData,
    bookError,
    isIAAvailable,
    isLoading,
    toggle,
    doctorId,
    lsdSelected,
    getSlotsData,
    bookSlotsData,
    handleLanguageChange,
    handleDateChange,
    handleTimeChange,
    handleSlotChanged,
    handleDoctorId,
    handleLsdSelected,
    handleConfirm,
    handleErrorChange,
    triggerToggle,
    trackScheduleScreen,
    skipSlotsTransformation,
  ])

  return (
    <ScheduleScreenContainer>
      <ScheduleHeader
        state={{
          title: consultUIData.title,
          subtitle: consultUIData?.subtitle,
        }}
      />
      <AppointmentCard {...appointmentCardProps} />

      {!isReschedule ||
        (showInfoCards && (
          <InfoCardSection
            state={{
              infoCardTitle: consultUIData.infoCardTitle,
              infoCards: consultUIData.infoCards,
            }}
          />
        ))}
      <NextButton
        state={{
          label: isOrderFlow ? (
            <span>Save Changes</span>
          ) : (
            <>
              <FontAwesomeIcon icon={faPhoneAlt} />
              &nbsp; Book
              <span style={{color: 'yellow', margin: '0 5px'}}>Free</span>
              Consult
            </>
          ),
          isLoading: isRescheduleInProgress || isBookSlotInProgress,
          isDisabled: isNextButtonDisabled || isRescheduleDisabled,
        }}
        actions={{
          onClick: ctaButtonCallback,
        }}
      />
    </ScheduleScreenContainer>
  )
}

export default ScheduleScreen
