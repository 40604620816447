import noop from 'lodash/noop'
import {useCallback, useState} from 'react'

import {useInternationalizationContext} from '../../Context/InternationalizationContext.jsx'
import Modal from '../Modal'
import OptimizedImage from '../OptimizedImage/OptimizedImage.jsx'
import {PaymentSummary} from '../PaymentSummary'
import Spinner from '../Spinner/Spinner.jsx'
import {Typography} from '../TypographyV2'
import TickIcon from './TickIcon.jsx'
import {WalletSectionWrapper} from './WalletSection.styles.js'

const WalletSection = (props) => {
  const {selectedAddressId, slotData, showLoader} = props || {}
  const {
    image = '',
    title = '',
    walletPoints,
    discountedPrice,
    payableText = '',
    infoIcon = '',
    callout = '',
    isApplied = false,
    isApplyingDiscount = false,
    method,
    paymentPartner,
    buttonText = 'Place Order',
    callGokwikCod,
    requestType = '',
  } = props.state || {}

  const {
    onWalletToggle = noop,
    walletToggledAnalytics = noop,
    onPayClick = noop,
  } = props.actions || {}

  // eslint-disable-next-line no-undef
  const [showPaymentSummary, setShowPaymentSummary] = useState(false)

  const {getAmountWithCurrency} = useInternationalizationContext()

  const handleWalletCheck = useCallback(async () => {
    await onWalletToggle(isApplied ? '' : 'apply')
    walletToggledAnalytics(!isApplied)
  }, [onWalletToggle, isApplied, walletToggledAnalytics])

  const handlePayButtonClick = useCallback(() => {
    onPayClick({
      amount: 0,
      requestType,
      paymentPartner,
      paymentMethod: paymentPartner,
      subPaymentInfo: {
        paymentMethod: 'wallet',
        paymentType: 'wallet',
        paymentSubType: 'wallet',
      },
      method,
      addressId: selectedAddressId,
      bookSlotsData: slotData,
      callGokwikCod,
    })
  }, [
    method,
    onPayClick,
    paymentPartner,
    selectedAddressId,
    slotData,
    callGokwikCod,
    requestType,
  ])

  if (!walletPoints) return null

  return (
    <WalletSectionWrapper walletChecked={isApplied}>
      <div className="mainContainer">
        <div className="topContainer">
          <div className="container">
            {!!image && (
              <div className="imageContainer">
                <OptimizedImage
                  className="iconimage"
                  source={image}
                  desktopViewWidth="THIRD"
                  mobileViewWidth="THIRD"
                  aspectWidth="40"
                  aspectHeight="40"
                />
              </div>
            )}
            <div className="textContainer">
              {!!title && (
                <Typography variant="tag-regular" customClassName="title-text">
                  {title}
                </Typography>
              )}
              {!isApplyingDiscount && (
                <Typography variant="body-base-bold">
                  {getAmountWithCurrency(walletPoints)}
                </Typography>
              )}
            </div>
          </div>
          {isApplyingDiscount ? (
            <div className="checkBoxLoader">
              <Spinner size={20} />
            </div>
          ) : (
            <div className="checkBoxContainer" onClick={handleWalletCheck}>
              {isApplied && <TickIcon />}
            </div>
          )}
        </div>
        <div className="horizontalLine" />
        {isApplied ? (
          <div className="bottomContainer">
            <div className="bottomInfoContainer">
              {!!payableText && (
                <Typography variant="body-base-regular" customClassName="text">
                  {payableText}
                </Typography>
              )}
              {!!infoIcon && (
                <div
                  className="infoIconContainer"
                  onClick={() => setShowPaymentSummary(true)}
                >
                  <OptimizedImage
                    className="infoIcon"
                    source={infoIcon}
                    desktopViewWidth="SMALL"
                    mobileViewWidth="SMALL"
                    aspectWidth="18"
                    aspectHeight="18"
                  />
                </div>
              )}
            </div>
            <Typography variant="body-base-bold">
              {getAmountWithCurrency(discountedPrice)}
            </Typography>
          </div>
        ) : (
          <div className="calloutContainer">
            <label className={callout}>{callout}</label>
          </div>
        )}
        {discountedPrice === 0 && (
          <button
            className="zero-order-button"
            onClick={handlePayButtonClick}
            disabled={showLoader}
          >
            {showLoader ? (
              <Spinner size={20} color={'white'} />
            ) : (
              <Typography variant="cta-label-sm">{buttonText}</Typography>
            )}
          </button>
        )}
      </div>
      {showPaymentSummary && (
        <Modal
          showModal={showPaymentSummary}
          onClose={() => setShowPaymentSummary(false)}
          additionalContentClass="payment-summary-wrap"
          additionalModalClass="payment-summary-modal"
          addtionalButtonClass="payment-summary-button-wrap"
          cancelCloseIcon
        >
          <PaymentSummary
            context={props.cartContext}
            isPaymentSummary={true}
            isBillingSummary={false}
          />
        </Modal>
      )}
    </WalletSectionWrapper>
  )
}

export default WalletSection
