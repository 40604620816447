import React from 'react'

function Star({fill = '#6c6c6c', stroke = '#6c6c6c'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="33"
      fill="none"
      viewBox="0 0 36 33"
      className="empty-star"
    >
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        className="star-path"
        d="M18 2l4.702 9.528 10.515 1.528-7.608 7.416 1.796 10.472L18 26l-9.405 4.944 1.796-10.472-7.608-7.416 10.515-1.528L18 2z"
        opacity="0.2"
      ></path>
    </svg>
  )
}

export default Star
