import {da} from 'date-fns/locale'
import {handleReadingTimedStorage} from '../../utils/storage'

export const processGrowthAppDownloadModalSeen = async (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const maxAmount = data?.configData?.initialAmount
  const utmSource = handleReadingTimedStorage('utm_source')
  const utmMedium = handleReadingTimedStorage('utm_medium')
  const dataToReturn = {
    ['Max Reward']: maxAmount,
    page: window.location.href,
    UTM_SOURCE: utmSource,
    UTM_MEDIUM: utmMedium,
  }

  return dataToReturn
}
export const processGrowthAppDownloadModalClosed = (data: any) => {
  const maxAmount = data?.configData?.initialAmount
  const utmSource = handleReadingTimedStorage('utm_source')
  const utmMedium = handleReadingTimedStorage('utm_medium')
  const dataToReturn = {
    ['Max Reward']: maxAmount,
    page: window.location.href,
    UTM_SOURCE: utmSource,
    UTM_MEDIUM: utmMedium,
  }

  return dataToReturn
}
export const processGrowthAppDownloadModalSubmitted = (data: any) => {
  const {submissionData, configData} = data || {}

  const {phoneNumber: phone, rewardAmount = 0} = submissionData || {}
  const {initialAmount: maxAmount = 0} = configData || {}
  const utmSource = handleReadingTimedStorage('utm_source')

  const utmMedium = handleReadingTimedStorage('utm_medium')
  const dataToReturn = {
    ['Max Reward']: maxAmount,
    page: window.location.href,
    UTM_SOURCE: utmSource,
    UTM_MEDIUM: utmMedium,
    phone,
    ['Reward Amount']: rewardAmount,
  }

  return dataToReturn
}

export const processAppDownloadBottomSheetSeen = () => {
  const dataToReturn = {
    Source: window.location.href,
  }

  return dataToReturn
}

export const processAppDownloadBottomSheetSubmitted = (data: any) => {
  const {link = ''} = data || {}
  const dataToReturn = {
    Source: window.location.href,
    URL: link,
  }

  return dataToReturn
}
