import React, {useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'

import {AppointmentConfirmedTopSheetWrapper} from './AppointmentConfirmedTopSheet.styles'

const AppointmentConfirmedTopSheet = (props) => {
  const {
    title = '',
    appointmentDateHeading = '',
    appointmentTimeHeading = '',
    description = '',
    subtitle = '',
    image = '',
    alt = '',
    appointmentDate = '',
    appointmentTime = '',
  } = props.state || {}

  const showAppointmentData = useMemo(() => {
    return (
      appointmentDateHeading &&
      appointmentTimeHeading &&
      appointmentDate &&
      appointmentTime
    )
  }, [
    appointmentDate,
    appointmentDateHeading,
    appointmentTime,
    appointmentTimeHeading,
  ])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <AppointmentConfirmedTopSheetWrapper>
      <div className="content-backdrop-color"></div>
      <div className="card-container">
        <div className="card">
          <div className="image-container">
            <img src={image} alt={alt} className="tick-image" />
          </div>
          <div className="title">{title}</div>
          {showAppointmentData && (
            <div className="appointment-details-container">
              <div>
                <div className="appointment-details-heading">
                  {appointmentDateHeading}
                </div>
                <div className="appointment-details">{appointmentDate}</div>
              </div>
              <div>
                <div className="appointment-details-heading">
                  {appointmentTimeHeading}
                </div>
                <div className="appointment-details">{appointmentTime}</div>
              </div>
            </div>
          )}
          <div className="description">{description}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        <div className="bottom-rectangle-container">
          <div className="bottom-rectangle"></div>
        </div>
      </div>
    </AppointmentConfirmedTopSheetWrapper>
  )
}

export default AppointmentConfirmedTopSheet
