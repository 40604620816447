import forEach from 'lodash/forEach'
import noop from 'lodash/noop'
import React, {useCallback, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'

import {regexPattern} from '../../../../constants/regexPattern'
import Spinner from '../../../common/Spinner'
import InputSection from './InputSection'
import RatingInput from './RatingInput'
import {
  StyledButtonGroup,
  StyledCancel,
  StyledSave,
  StyledWriteReviewWrapper,
} from './WriteReview.styles'

function WriteReview({actions, data}) {
  const [uploadedImages, setUploadedImages] = useState({})
  const {
    productUrl,
    productName,
    productImage,
    category,
    cancelButtonText,
    saveButtonText,
    isLoading = false,
  } = data
  const {onCancelReview = noop, onSubmitReview = noop} = actions

  const onFormSubmit = useCallback(
    (data) => {
      const formData = new FormData()
      formData.append('author', data.name)
      formData.append('reviewRating', data.rating)
      formData.append('email', data.email)
      formData.append('reviewTitle', data.reviewTitle)
      formData.append('reviewMessage', data.reviewDescription)
      formData.append('productName', productName)
      formData.append('productUrl', productUrl)
      formData.append('productImageUrl', productImage)
      forEach(uploadedImages, (image) => formData.append('files', image))

      onSubmitReview(formData)
    },
    [onSubmitReview, uploadedImages]
  )
  const nameValidate = useCallback((val) => {
    return regexPattern.NAME.test(val)
  }, [])
  const {
    register,
    handleSubmit,
    watch,
    formState: {isSubmitting, isDirty, isValid, errors},
    control,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  })

  return (
    <StyledWriteReviewWrapper
      className="write-review__wrapper"
      data-product-category={category}
    >
      {productName && (
        <div className="write-review__product-snippet">
          {productImage && (
            <span>
              <img src={productImage} alt="" />
            </span>
          )}
          <p>{productName}</p>
        </div>
      )}
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="write-review__form"
      >
        <Controller
          control={control}
          name="rating"
          rules={{
            required: true,
          }}
          render={({
            field: {onChange, onBlur, value, name, ref},
            fieldState: {invalid, isTouched, isDirty, error},
            formState,
          }) => (
            <RatingInput
              label="Rate this Product"
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />
        <InputSection
          label="Name"
          id="write-review-name"
          type="text"
          placeholder="Your Name"
          registerKey="name"
          error={errors['name']}
          register={register('name', {
            required: true,
            onChange: (e) => nameValidate(e),
            validate: (val) => nameValidate(val),
          })}
          errorText="Valid Name is required"
        />
        <InputSection
          label="Email"
          id="write-review-email"
          type="email"
          placeholder="Your Email"
          registerKey="email"
          error={errors['email']}
          register={register('email', {
            required: true,
            pattern: {
              value: regexPattern.EMAIL,
              message: 'Please enter a valid email',
            },
          })}
          errorText="Email is required"
        />
        <InputSection
          label="Review Title"
          id="write-review-title"
          type="text"
          placeholder="Type title here..."
          registerKey="reviewTitle"
          error={errors['reviewTitle']}
          register={register('reviewTitle', {required: true})}
          errorText="Review Title is required"
        />
        {/* <UploadImages
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
        /> */}
        <InputSection
          isTextArea
          label="Review Description"
          id="write-review-description"
          placeholder="Type detailed review here..."
          registerKey="reviewDescription"
          error={errors['reviewDescription']}
          register={register('reviewDescription', {required: true})}
          errorText="Review Description is required"
        />
        <StyledButtonGroup>
          <StyledCancel type="button" onClick={onCancelReview}>
            {cancelButtonText || 'Cancel'}
          </StyledCancel>
          <StyledSave
            type="submit"
            disabled={isLoading}
            data-disabled={!isDirty || !isValid}
          >
            {isLoading ? <Spinner size="18" /> : saveButtonText || 'Save'}
          </StyledSave>
        </StyledButtonGroup>
      </form>
    </StyledWriteReviewWrapper>
  )
}

export default WriteReview
