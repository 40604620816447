import {LOCAL_STORAGE_CONSTANTS, localStorageMethods} from 'src/utils'
import {checkIsApp, checkIsAppViaParam} from 'src/utils/checkIsApp'
import {docCookies} from 'src/utils/cookie'
import {errorToastAndEvent, logError} from 'src/utils/logger'

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  if (typeof navigator === 'undefined') {
    return ''
  }
  if (typeof window === 'undefined') {
    return ''
  }
  //@ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //@ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return 'unknown'
}

export const requestInterceptor = async (request: any, isApp = false) => {
  const isWebView = isApp || checkIsApp() || checkIsAppViaParam()
  const webViewPlatform =
    localStorageMethods.get(LOCAL_STORAGE_CONSTANTS.APP_PLATFORM) || ''

  try {
    const lang =
      docCookies.getItem({
        cookieName: 'mwlang',
        skipPrefix: true,
        skipDomain: false,
      }) || 'en'
    const repeatUserFromCookie =
      docCookies.getItem({
        cookieName: 'mw_isRept',
        skipPrefix: true,
        skipDomain: false,
      }) || '0'

    //if webview is true then add mwwebview and platform will be passed so backend can make decision based on platform if required
    if (isWebView) {
      request.headers = {
        ...request.headers,
        mwwebview: webViewPlatform || getMobileOperatingSystem(),
      }
    }
    request.headers = {
      ...request.headers,
      mwlang: lang,
      repeatuser: repeatUserFromCookie === '1' ? 'true' : 'false',
    }

    return request
  } catch (error) {
    logError(error as Error, {})
  }
}

/* TODO: add support for toasts in response handler */
export const responseInterceptor = async () => {}

/* TODO: handle 401s */
export const responseErrorInterceptor = async (error: any) => {
  const {
    showErrorToast = false,
    url = '',
    analyticsEventName = '',
    triggerAnalytics = false,
    analyticsData = {},
  } = error.response?.config || {}

  errorToastAndEvent({
    error,
    showToast: showErrorToast,
    api: url,
    event: analyticsEventName,
    triggerAnalytics,
    analyticsData,
  })
}
