import {useCallback, useState} from 'react'

export function useTabs(initialActiveTab) {
  const [activeTab, setActiveTab] = useState(initialActiveTab)

  const changeTab = useCallback((tabToSelect) => {
    setActiveTab(tabToSelect)
  }, [])

  return {
    activeTab,
    changeTab,
  }
}
