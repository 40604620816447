import React from 'react'

import OptimizedImage from '../OptimizedImage'
import {StyledWrapper} from './ReasonsToBuy.styles'

function ReasonsToBuy({reasons = []}) {
  if (!reasons.length > 0) {
    return null
  }

  return (
    <StyledWrapper>
      {reasons.map((reason, index) => {
        const {iconUrl, lineOne, lineTwo} = reason

        return (
          <div className="reason" key={index}>
            <OptimizedImage
              source={iconUrl}
              aspectWidth="28"
              aspectHeight="28"
              desktopViewWidth="TINY"
              mobileViewWidth="TINY"
              altText="reason to buy"
              loading="lazy"
              customClassName="reason-icon"
            />
            {lineOne && <span className="reason-text">{lineOne}</span>}
            {lineTwo && <span className="reason-text">{lineTwo}</span>}
          </div>
        )
      })}
    </StyledWrapper>
  )
}

export default ReasonsToBuy
