import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import noop from 'lodash/noop'

import {AppointmentConfirmedCardContainer} from './TopSheet.styles'
import {AppointmentConfirmedTopSheet} from '../AppointmentConfirmedTopSheet'
import {useCallback, useEffect, useRef, useState} from 'react'
import TopDrawer from '../../../Components/Common/TopDrawer'

function TopSheet(props) {
  const {state = {}, className = '', actions = {}} = props
  const [countdown, setCountdown] = useState({minutes: 5, seconds: 0})
  const intervalRef = useRef()

  const {
    // static date
    ia = {},
    schedule = {},

    // dynamic data
    isIAAvailable = false,
    appointmentDate = '',
    appointmentTime = '',
    topSheetTimeoutInSec = '',
  } = state

  const {onTopSheetOpen = noop} = actions

  const uiData = isIAAvailable ? ia : schedule

  const appointmentConfirmedTopSheetProps = {
    state: {
      ...uiData,
      appointmentDate,
      appointmentTime,
    },
  }

  const [showTopSheet, setShowTopSheet] = useState(true)

  const handleTopSheet = useCallback(() => {
    if (showTopSheet) {
      //analytics trigger
      onTopSheetOpen({type: 'topSheetViewed'})
    }
    setShowTopSheet(!showTopSheet)
  }, [onTopSheetOpen, showTopSheet])

  // Timer logic
  useEffect(() => {
    if (isIAAvailable) {
      const intervalId = setInterval(() => {
        const {seconds, minutes} = countdown

        if (seconds > 0) {
          setCountdown((data) => ({
            ...data,
            seconds: data.seconds - 1,
          }))
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(intervalId)
          } else {
            setCountdown((data) => ({
              ...data,
              minutes: data.minutes - 1,
              seconds: 59,
            }))
          }
        }
      }, 1000)

      intervalRef.current = intervalId
    }

    return () => clearInterval(intervalRef.current)
  }, [countdown, isIAAvailable])

  //close top sheet after timeout seconds over
  useEffect(
    () =>
      setTimeout(() => {
        setShowTopSheet(false)
      }, topSheetTimeoutInSec * 1000),
    []
  )

  return (
    <AppointmentConfirmedCardContainer className={className}>
      <div className="detail-pill">
        {isIAAvailable ? 'Consult Ongoing' : 'Appointment Booked'}
        {isIAAvailable && (
          <div className="timer-pill">
            {countdown.minutes} :{' '}
            {countdown.seconds > 9
              ? countdown.seconds
              : `0${countdown.seconds}`}
          </div>
        )}
        <button className="know-more" onClick={handleTopSheet}>
          <span>Know More</span>
          <div className="down-arrow-container">
            <FontAwesomeIcon icon={faChevronDown} className="down-arrow" />
          </div>
        </button>

        <TopDrawer
          state={{isOpen: showTopSheet}}
          handleOnClose={handleTopSheet}
        >
          <AppointmentConfirmedTopSheet
            {...appointmentConfirmedTopSheetProps}
          />
        </TopDrawer>
      </div>
    </AppointmentConfirmedCardContainer>
  )
}

export default TopSheet
