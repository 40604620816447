import React from 'react'

const Switch = ({isOn, handleToggle, selected}) => {
  return (
    <label className="react-switch">
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        type="checkbox"
      />
      <div className="react-switch-button" />
      <div className="react-switch-labels">
        <span className={`${selected == 'feet' ? 'active' : ''}`}>ft</span>
        <span className={`${selected == 'cms' ? 'active' : ''}`}>cm</span>
      </div>
    </label>
  )
}

export default Switch
