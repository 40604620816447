import {InputSectionWrapper} from './styles'

export default function InputSection({
  label,
  id,
  type = 'text',
  placeholder,
  registerKey,
  errors,
  register,
  errorText,
  isTextArea = false,
  action = '',
  className = '',
}) {
  const isReadOnly = action === 'edit' ? true : false

  return (
    <InputSectionWrapper className={className}>
      <div className="input-section">
        <label className="input-label" htmlFor={id}>
          {label}
        </label>
        {isTextArea ? (
          <textarea
            className={`input-box ${errors[registerKey] && 'input-box-error'}`}
            id={id}
            placeholder={placeholder}
            {...register}
          />
        ) : (
          <input
            className={`input-box ${
              errors[registerKey] ? 'input-box-error' : ''
            } ${isReadOnly ? 'input-disabled' : ''}`}
            type={type}
            id={id}
            placeholder={placeholder}
            {...register}
            readOnly={
              (registerKey === 'phoneNumber' && isReadOnly) ||
              (registerKey === 'email' && isReadOnly) ||
              (registerKey === 'firstName' && isReadOnly) ||
              (registerKey === 'lastName' && isReadOnly)
                ? true
                : false
            }
          />
        )}
        {errors[registerKey] && (
          <span className="error-field">
            {errors[registerKey].message || errorText}
          </span>
        )}
      </div>
    </InputSectionWrapper>
  )
}
