import {StyledDoctorListingCard} from './DoctorListingCard.styles'
import {DoctorListingCardProps} from './DoctorListingCard.interface'
import {DoctorDetails} from './DoctorDetails'
import useDoctorListingCard from './useDoctorListingCard'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {Button} from '@web-components'

const DoctorListingCard = (props: BaseWidgetProps<DoctorListingCardProps>) => {
  const {type, widgetData, header, layout, id} = props || {}
  const {footerCtas = [], ...restProps} = widgetData || {}

  const {
    handleDoctorListingCardClick,
    handleNotifyMeClick,
    handleFooterCtaClick,
  } = useDoctorListingCard(widgetData)

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledDoctorListingCard>
        <DoctorDetails
          {...restProps}
          handleNotifyMeClick={handleNotifyMeClick}
          handleCardClick={handleDoctorListingCardClick}
        />
        {footerCtas?.length > 0 && (
          <div className="footer-wrapper">
            {footerCtas?.map((item, index: number) => (
              <Button
                key={'appointment_card_cta' + index}
                customClassName="footer-cta"
                data-isfullwidth={item.fullWidth}
                {...item}
                onClick={handleFooterCtaClick(item.actions)}
              />
            ))}
          </div>
        )}
        <div className="divider" />
      </StyledDoctorListingCard>
    </BaseWidgetComponent>
  )
}

export default DoctorListingCard
