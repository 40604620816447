import {analyticsTriggerBypassProcessor} from 'src/analytics'

type TrackAtcBottomSheetSeenProps = {
  source: string
  productId: string
}

export const trackAtcBottomSheetSeen = (data: Record<string, any>) => {
  analyticsTriggerBypassProcessor('ATC Bottom Sheet Seen', {
    Source: data.source,
    'Product ID': data.productId,
  })
}
