import styled from 'styled-components'

export const SuggestedProductCardContainer = styled.div`
  .recommended-label {
    color: var(--dls-brand-primary-color-500);
    text-align: center;
    margin-bottom: var(--dls-size-8);
  }

  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .product-name {
    margin-top: var(--dls-size-16);
    margin-bottom: var(--dls-size-4);
  }

  .price-container {
    display: flex;
    flex-direction: column;
  }

  .price {
    display: flex;
    gap: var(--dls-size-4);
    align-items: center;
  }

  .tax-label {
    color: var(--dls-disabled-text-colors);
  }

  .view-product-button {
    outline: 0;
    border: 0;
    background-color: var(--dls-brand-primary-color-500);
    border-radius: var(--dls-size-8);
    color: var(--dls-white-color);
    width: 100%;
    padding: 12px 0;
    margin-top: var(--dls-size-16);
  }

  .actual-price {
    text-decoration: line-through;
    color: var(--dls-secondary-text-colors);
  }

  .discount-tag {
    display: flex;
    flex-shrink: 0;
    position: relative;
  }

  .discount-tag-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: var(--dls-white-color);
  }
`
