import {useMemo} from 'react'
import {useRouter} from 'next/router'
import {
  PAGES_WITH_OUT_FOOTER,
  PAGES_WITH_OUT_HEADER,
  PAGES_WITH_OUT_SIDE_CART,
} from 'src/constants/headerAndFooterConfig'

export const useHeaderAndFooter = () => {
  const {pathname} = useRouter()

  const showHeader = useMemo(() => {
    const pathMatches = PAGES_WITH_OUT_HEADER.some((path) => path === pathname)

    return !pathMatches
  }, [pathname])

  const showFooter = useMemo(() => {
    const pathMatches = PAGES_WITH_OUT_FOOTER.some((path) => path === pathname)

    return !pathMatches
  }, [pathname])

  const showSideCart = useMemo(() => {
    const pathMatches = PAGES_WITH_OUT_SIDE_CART.some(
      (path) => path === pathname
    )

    return !pathMatches
  }, [pathname])

  return {
    showFooter,
    showHeader,
    showSideCart,
  }
}
