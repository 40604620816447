import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: auto;
  margin-bottom: 40px;
  padding: 16px 0px 0px 16px;
  @media screen and (min-width: 768px) {
    padding: 0px;
    margin-bottom: 100px;
  }
`

export const CardContainer = styled.div`
  display: flex;
  padding: 20px 16px 0px 0px;
  overflow: scroll;
  gap: 16px;
  @media screen and (min-width: 768px) {
    padding: 30px 16px 0px 0px;
    gap: 45px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }
`

export const Card = styled.div`
  .how-it-works__card-title {
    margin-bottom: 0;
    width: 250px;
  }

  .how-it-works__card-description {
    margin-top: 5px;
    color: var(--brand-primary-grey-color);
    width: 250px;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  img {
    border-radius: 30px;
    height: 250px;
    width: 250px;
  }

  span {
    position: absolute;
    left: 20px;
    bottom: 25px;
    background-color: var(--brand-primary-white-color);
    color: var(--brand-primary-blue-color);
    font-weight: var(--brand-font-weight-heavy);
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 22px;
  }
`
