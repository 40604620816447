import styled from 'styled-components'

export const StickyCartSummaryContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  z-index: 2;
  background-color: var(--dls-white-color);
  box-shadow: rgba(0, 0, 0, 0.333) 0px 20px 20px 13px;

  .cart-container {
    max-width: 932px;
    display: flex;
    background-color: var(--brand-primary-white-color);
    padding: var(--dls-size-16);
    flex-direction: row;
    align-items: center;
    margin: auto;
  }

  .product-image {
    width: var(--dls-size-40);
    height: var(--dls-size-40);
    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-divider-light-color);
  }

  .cart-details {
    margin-left: var(--dls-size-8);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .cart-subtitle {
    color: var(--dls-brand-primary-color-500);
  }

  .primary-btn-container {
    margin-left: var(--dls-size-8);
  }

  .primary-btn-label {
    white-space: nowrap;
  }

  .clear {
    clear: both;
    height: 60px;
  }

  @media screen and (min-width: 768px) {
    .cart-container {
      padding: var(--dls-size-16) 0px;
    }
  }
`
export const ClearDivider = styled.div`
  clear: both;
  height: var(--dls-size-4);
`
