import styled from 'styled-components'
export const InfoCardTitle = styled.div`
  font-size: 16px;
  color: var(--category-primary-color);
  align-self: center;
  margin: 8px 0;
  align-self: center;
  font-weight: var(---brand-font-weight-bold);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 80%;

  @media (min-width: 768px) {
    margin: 15px 0 16px 0;
    font-size: 28px;
  }
`

export const InfoCardsWrapper = styled.div`
  margin: 15px 0;
  width: 328px;
  width: 90%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    margin: 30px 0;
    width: 450px;
  }
`
