function LanguageIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M3.33337 5.33331L6.66671 8.66665M2.66671 9.33331L6.66671 5.33331L8.00004 3.33331M1.33337 3.33331H9.33337M4.66671 1.33331H5.33337M8.60874 11.3333H13.3913M8.60874 11.3333L7.33337 14M8.60874 11.3333L10.5189 7.33933C10.6728 7.01749 10.7498 6.85658 10.8551 6.80572C10.9467 6.7615 11.0534 6.7615 11.145 6.80572C11.2503 6.85658 11.3273 7.01749 11.4812 7.33933L13.3913 11.3333M13.3913 11.3333L14.6667 14"
        stroke="#121212"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LanguageIcon
