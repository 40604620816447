import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {IMediaWithHeaderSliderProps} from './MediaWithHeaderSlider.interface'
import MediaWithHeaderCard from './MediaWithHeaderCard/MediaWithHeaderCard'
import {MediaWithHeaderSliderContainer} from './MediaWithHeaderSlider.styles'
import {GenericSlider} from '@web-components'

function MediaWithHeaderSlider(props: IMediaWithHeaderSliderProps) {
  const {type, widgetData, header, layout, id} = props
  const {sliderConfig, slides} = widgetData || {}
  const {
    aspectRatio = 1,
    slidesToShow = 1,
    slidesUiType = 'FLAT',
    textAlign = 'LEFT',
    cardBackground = '',
  } = sliderConfig || {}

  if (isEmpty(slides)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithHeaderSliderContainer>
        <GenericSlider
          noOfSlides={slidesToShow}
          aspectRatio={aspectRatio}
          textAlign={textAlign}
        >
          {({imageDimensions}: {imageDimensions: number[]}) =>
            slides.map((slide) => {
              return (
                <MediaWithHeaderCard
                  key={slide.header}
                  {...slide}
                  uiType={slidesUiType || 'FLAT'}
                  cardBackground={cardBackground}
                  imageDimensions={{
                    width: imageDimensions[0]?.toString(),
                    height: imageDimensions[1]?.toString(),
                  }}
                />
              )
            })
          }
        </GenericSlider>
      </MediaWithHeaderSliderContainer>
    </BaseWidgetComponent>
  )
}

export default MediaWithHeaderSlider
