// @ts-nocheck
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {
  createRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import StarRating from '../../../../Atomic/StarRating'
import Responsive from '../../../../HOC/Responsive'
import Icon from './Icon'
import {StyledPhotoPreviewContainer, PhotoPreviewImage} from './styles'

const PhotoPreview = (props) => {
  const {
    data,
    actions = {},
    screenWidth,
    startIndex = 0,
    onClose = noop,
  } = props || {}
  const {
    loadedPhotosPreview = [],
    loadedReviewsMap = {},
    moreImagesToLoad = false,
    showImageSlider = true,
  } = data || {}

  const [photoPreview, setPhotoPreview] = useState(
    loadedPhotosPreview[startIndex]
  )
  const sliderRef = createRef(null)
  const {handleLoadMoreReviewsWithImages = noop} = actions || {}
  const reviewPreview = useMemo(() => {
    return photoPreview?.reviewId
      ? loadedReviewsMap[photoPreview?.reviewId]
      : loadedReviewsMap
  }, [photoPreview, loadedReviewsMap])
  const {
    voteUp,
    voteDown,
    dateCreated,
    author,
    rating,
    title,
    body,
    originalProduct,
  } = reviewPreview || {}
  const [isShowArrows, setShowArrows] = useState(true)

  useEffect(() => {
    if (loadedPhotosPreview && loadedPhotosPreview.length) {
      setShowArrows(loadedPhotosPreview.length > 1)
    } else {
      setShowArrows(false)
    }
  }, [loadedPhotosPreview])

  const handleCarouselClick = useCallback((item) => {
    setPhotoPreview(item)
  }, [])

  const handleNextClick = useCallback(
    (e) => {
      e.stopPropagation()
      const index = loadedPhotosPreview.indexOf(photoPreview)

      if (index === loadedPhotosPreview.length - 1) {
        if (moreImagesToLoad) {
          return handleLoadMoreReviewsWithImages()
        }

        setPhotoPreview(loadedPhotosPreview[0])
        if (sliderRef.current) sliderRef.current.scrollIntoView()
      } else {
        setPhotoPreview(loadedPhotosPreview[index + 1])
      }
    },
    [loadedPhotosPreview, photoPreview, handleLoadMoreReviewsWithImages]
  )

  const handlePrevClick = useCallback(
    (e) => {
      e.stopPropagation()
      const index = loadedPhotosPreview.indexOf(photoPreview)
      if (index === 0) {
        setPhotoPreview(loadedPhotosPreview[loadedPhotosPreview.length - 1])
        if (sliderRef.current)
          sliderRef.current.scrollLeft = sliderRef.current.scrollWidth
      } else {
        setPhotoPreview(loadedPhotosPreview[index - 1])
      }
    },
    [loadedPhotosPreview, photoPreview]
  )

  const formatDate = useCallback((date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('/')
  }, [])

  const handleStopClose = useCallback((e) => {
    e.stopPropagation()
  }, [])

  if (isEmpty(loadedPhotosPreview) || !loadedPhotosPreview.length) return null

  return (
    <StyledPhotoPreviewContainer onClick={onClose}>
      <div className="top">
        {isShowArrows && (
          <div className="left_arrow icon" onClick={handlePrevClick}>
            <Icon direction="left" />
          </div>
        )}
        <section className="center" onClick={handleStopClose}>
          {/* Cross Icon  */}
          <div className="cross_icon" onClick={onClose}>
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3274 2.18057C13.7038 1.7865 13.7038 1.16614 13.3274 0.772062C12.9257 0.351524 12.2543 0.351524 11.8526 0.772062L7 5.85292L2.14738 0.772062C1.74573 0.351524 1.07427 0.351524 0.67262 0.772062C0.296247 1.16614 0.296247 1.7865 0.67262 2.18057L5.59 7.32924L0.67262 12.4779C0.296247 12.872 0.296247 13.4923 0.67262 13.8864C1.07427 14.307 1.74573 14.307 2.14738 13.8864L7 8.80556L11.8526 13.8864C12.2543 14.307 12.9257 14.307 13.3274 13.8864C13.7038 13.4923 13.7038 12.872 13.3274 12.4779L8.41 7.32924L13.3274 2.18057Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="image">
            <PhotoPreviewImage
              width={700}
              height={700}
              loading="lazy"
              isAppendHost={true}
              src={photoPreview?.image}
              alt={photoPreview?.altText || 'uploaded product'}
            />
          </div>
          <div className="review_container">
            <div className="review_section">
              <h3 className="name">{author}</h3>
              <div className="ratings_section">
                <StarRating rating={rating} starType="bigStar" />
              </div>
              <div className="review_heading_container">
                <p className="review_title">{title}</p>
                <p className="review_date-created">{formatDate(dateCreated)}</p>
              </div>
              <p className="review">{body}</p>
              {/* commenting votes for future use */}

              {/* <div className="votes-container">
                <span className="voteUp-wrapper active-vote">
                  <p className="voteUp-count">{voteUp}</p>
                  {true ? <FaThumbsUp /> : <FaRegThumbsDownegThumbsUp />}
                </span>
                <span className="voteDown-wrapper">
                  <p className="voteUp-count">{voteDown}</p>
                  {false ? <FaThumbsDown /> : <FaRegThumbsDown />}
                </span>
              </div> */}
            </div>
            <hr />
            <div className="original_product" onClick={handleStopClose}>
              <div className="product_image">
                <PhotoPreviewImage
                  width={100}
                  height={100}
                  src={originalProduct?.imageUrl}
                  loading="lazy"
                  alt={originalProduct?.slug || 'original product'}
                />
              </div>
              <span className="product_name">{originalProduct?.name}</span>
            </div>
          </div>
        </section>
        {isShowArrows && (
          <div className="right_arrow icon" onClick={handleNextClick}>
            <Icon direction={'right'} />
          </div>
        )}
      </div>
      {showImageSlider && screenWidth > 768 && (
        <div className="bottom">
          <div className="image_slider" ref={sliderRef}>
            {loadedPhotosPreview.map((item, index) => {
              return (
                <div
                  key={index}
                  className="image_slide"
                  onClick={handleStopClose}
                >
                  <PhotoPreviewImage
                    width={90}
                    height={90}
                    onClick={() => handleCarouselClick(item)}
                    src={item.image}
                    opacity={
                      photoPreview?.review?.id === item?.review?.id ? 1 : 0.5
                    }
                    loading="lazy"
                    alt={item.altText || 'product'}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </StyledPhotoPreviewContainer>
  )
}

export default Responsive(PhotoPreview)
