import React from 'react'

import {Typography} from '../../../ComponentsV2/common/TypographyV2'
import {StyledButton} from './Button.styled'

interface ButtonWithChildrenProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'PRIMARY' | 'SECONDARY' | 'TERTIARY'
  onClick: () => void
  disabled?: boolean
  size?: 'SMALL' | 'MEDIUM' | 'LARGE'
  customClassName?: string
  fullWidth?: boolean
  customLabelClassName?: string
}

function ButtonWithChildren(
  props: React.PropsWithChildren<ButtonWithChildrenProps>
) {
  const {
    type = 'button',
    children,
    variant = 'PRIMARY',
    size = 'MEDIUM',
    disabled = false,
    label,
    fullWidth = false,
    onClick,
    customLabelClassName = '',
    customClassName = '',
  } = props

  const labelTypographyVariant = React.useMemo(() => {
    switch (size) {
      case 'SMALL':
        return 'cta-label-sm'
      case 'LARGE':
      case 'MEDIUM':
      default:
        return 'cta-label-lg'
    }
  }, [size])
  if (!children) {
    return null
  }

  return (
    <StyledButton
      type={type}
      data-variant={variant}
      data-size={size}
      data-disabled={disabled}
      disabled={disabled}
      data-isfullwidth={fullWidth}
      onClick={onClick}
      className={customClassName}
    >
      {children}
    </StyledButton>
  )
}

export default ButtonWithChildren
