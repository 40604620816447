export const processCalloutClick = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    Page: window.location.href,
  }

  return dataToReturn
}
