import styled from 'styled-components'

export const RatingsSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .overall-rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .overall-rating {
    display: flex;
    gap: var(--dls-size-4);
  }

  .overall-rating-text {
    line-height: 1;
  }

  .total-reviews-text {
    color: var(--dls-disabled-text-colors);
  }

  .rating-stats-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-4);
  }

  .rating-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--dls-size-8);

    --figure: attr(data-figure);
    --fillcolor: attr(data-fillcolor);

    .filled-strip {
      border-radius: 4px;
      height: 6px;
      width: var(--figure);
      background-color: var(--fillcolor);
    }
  }

  .rating-number-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  .strip {
    border-radius: 4px;
    background-color: #e8f4ff;
    height: 6px;
    flex: 1;
  }

  .trailing-text-container {
    text-align: left;
    width: 36px;
  }

  .trailing-text {
    color: var(--dls-secondary-text-colors);
  }
`
