import styled from 'styled-components'

export const ProductSwitchContainer = styled.div`
  width: 100%;
  border: 1px solid var(--dls-divider-light-color);
  border-radius: 12px;
  padding: var(--dls-size-16);

  .subheading-bold {
    text-align: center;
  }

  .title {
    color: var(--dls-category-primary-color-500);
    margin-bottom: 12px;
  }

  .cta-grid {
    display: flex;
    gap: var(--dls-size-16);
    max-width: 272px;
    margin: auto;
  }

  .cta-grid-item {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
    align-items: center;
    flex: 1;

    &[data-isselected='true'] {
      .image-container {
        background-color: var(--dls-category-primary-color-100);
        border: 1px solid var(--dls-category-primary-color-500);
      }
    }
  }

  .image-container {
    display: flex;
    border-radius: var(--dls-size-8);
    padding: 10px;
    background-color: var(--dls-backgound-color-100);
  }

  .cta-image {
    object-fit: cover;
  }

  .cta-label {
    flex-shrink: 0;
  }
`
