import noop from 'lodash/noop'
import dynamic from 'next/dynamic'
import {useEffect} from 'react'
import useATCBottomSheetParam from 'src/hooks/product/ATCBottomSheet/useATCBottomSheet'
import {trackAtcBottomSheetSeen} from './atcBottomSheetTracking'

const ProductsBottomSheetOnAtc = dynamic(
  () =>
    import(
      /* webpackChunkName: "ATCBottomSheet" */ 'src/components/shared/src/Screens/PDP/ProductsBottomSheetOnAtc/ProductsBottomSheetOnAtc'
    ),
  {
    ssr: false,
  }
)

const ATCBottomSheetContainer = () => {
  const [
    {
      showAtcBottomSheet,
      bottomSheetProducts,
      atcProductImage,
      hideGoToCartButton,
      atcBottomSheetSourcePage,
      baseProductUrlKey,
      atcBottomSheetSource,
    },

    {
      handleAtcBottomSheetClose = noop,
      handleAddToCartBottomSheet = noop,
      onProductCardClick = noop,
      onGoToCartClick = noop,
    },
  ] = useATCBottomSheetParam()

  useEffect(() => {
    if (showAtcBottomSheet && atcBottomSheetSourcePage && baseProductUrlKey) {
      trackAtcBottomSheetSeen({
        source: atcBottomSheetSourcePage,
        productId: baseProductUrlKey,
      })
    }
  }, [atcBottomSheetSourcePage, baseProductUrlKey, showAtcBottomSheet])

  return (
    <ProductsBottomSheetOnAtc
      state={{
        ...bottomSheetProducts,
        showModal: showAtcBottomSheet,
        productImage: atcProductImage,
        hideGoToCartButton,
        atcBottomSheetSource,
      }}
      actions={{
        onAddToCart: handleAddToCartBottomSheet,
        closeBottomSheet: handleAtcBottomSheetClose,
        onProductCardClick,
        onGoToCartClick,
      }}
    />
  )
}

export default ATCBottomSheetContainer
