import noop from 'lodash/noop'
import {memo, useMemo} from 'react'

import {useIntersection} from '../../../../hooks/useIntersection'
import Responsive from '../../../HOC/Responsive'
import OptimizedImage from '../../../common/OptimizedImage'
import CarouselVideo from '../CarouselVideo'
import MediaRibbon from './MediaRibbon'

const thursholdValue = 0
const rootMarginValue = '0px 150px 0px 0px'
const ImageCarouselSlide = ({state, actions, isMobile, ...rest}) => {
  const {
    videoLink,
    src,
    index,
    currentIndex,
    isActive,
    alt,
    cta,
    type,
    customVideo,
    showBannerCta = true,
  } = state || {}

  const {handleVideoPlay = noop} = actions

  const heightForAspectRatio = useMemo(() => (isMobile ? 270 : 375), [isMobile])
  const widthForAspectRatio = useMemo(() => (isMobile ? 360 : 500), [isMobile])

  const {isIntersecting: isVisible, nodeRef} = useIntersection({
    rootMargin: rootMarginValue,
    threshold: thursholdValue,
  })
  return (
    <div className={`image index-${index}`} ref={nodeRef}>
      {index === 0 || isVisible ? (
        <>
          {type === 'video' ? (
            <CarouselVideo
              state={{
                videoLink,
                customVideo,
                index,
                isActive,
                type,
              }}
              actions={{
                onVideoPlay: () => handleVideoPlay(currentIndex),
              }}
            />
          ) : (
            <OptimizedImage
              source={src}
              customClassName="inner-image"
              alt={alt}
              loading={index === 0 ? 'eager' : 'lazy'}
              fetchpriority={index === 0 ? 'high' : 'auto'}
              aspectWidth={widthForAspectRatio}
              aspectHeight={heightForAspectRatio}
              desktopViewWidth="THIRD"
              mobileViewWidth="HALF"
              {...rest}
            />
          )}
          {showBannerCta && cta && (
            <MediaRibbon actions={actions} data={cta} key={currentIndex} />
          )}
        </>
      ) : null}
    </div>
  )
}

const MemoizedImageCarouselSlide = memo(
  ImageCarouselSlide,
  (prevProps, nextProps) => {
    return prevProps.state.index === nextProps.state.index
  }
)

export default Responsive(MemoizedImageCarouselSlide)
