import styled from 'styled-components'

export const PrescriptionPreviewContainer = styled.div`
  .prescription-preview__modal-content {
    padding: 0px;
    max-height: 80vh;
    overflow: scroll;
    position: relative;
  }
  .prescription-preview__modal-content::-webkit-scrollbar {
    display: none;
  }
  .prescription-preview__heading-button-container {
    padding: 13px 11px 13px 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--brand-primary-white-color);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 14px 14px 0px 0px;
    position: sticky;
    width: 100%;
    top: 0px;
    left: 0px;
  }
  .prescription-preview__download-button-container {
    height: 30px;
  }
  .prescription-preview__download-button {
    height: 30px;
    width: 108px;
  }
  .prescription-preview__cross-button {
    width: 30px;
    height: 30px;
  }
  .prescription-preview__prescription-container {
    box-sizing: border-box;
    border: 1px solid #dadada;
    border-radius: 16px;
    padding: 9px;
    margin-bottom: 100px;
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
  }
  .prescription-preview__prescription-heading {
    display: flex;
    padding-top: 11px;
    padding-bottom: 18px;
    justify-content: space-between;
  }
  .prescription-preview__prescription-rx-symbol {
    width: 41px;
    height: 64px;
  }
  .prescription-preview__prescription-doctor-name {
    font-size: 16px;
    line-height: 16px;
    font-weight: var(--brand-font-weight-semiBold);
    margin-bottom: 4px;
  }
  .prescription-preview__prescription-doctor-qualification {
    font-size: 12px;
    line-height: 12px;
    font-weight: var(--brand-font-weight-semiBold);
    margin-bottom: 6px;
  }
  .prescription-preview__prescription-registration-number {
    font-size: 12px;
    line-height: 12px;
    font-weight: var(--brand-font-weight-semiBold);
    color: var(--brand-primary-subheading-color);
  }
  .prescription-preview__prescription-details-separation-line {
    padding-bottom: 14px;
    border-top: 1px solid;
  }
  .prescription-preview__prescription-details-outer-container {
    display: flex;
    justify-content: space-between;
  }
  .prescription-preview__prescription-details-same-line {
    display: flex;
    gap: 4px;
  }
  .prescription-preview__prescription-details-heading {
    font-weight: var(--brand-font-weight-medium);
    font-size: 10px;
    line-height: 10px;
    color: var(--brand-primary-subheading-color);
    padding-bottom: 5px;
  }
  .prescription-preview__prescription-details-data {
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 10px;
    line-height: 10px;
    padding-bottom: 5px;
  }
  .prescription-preview__prescription-details-padding-top-15 {
    padding-top: 20px;
  }
  .prescription-preview__prescription-details-table-container {
    padding-top: 22px;
  }
  table {
    border-spacing: 0px;
    border-collapse: collapse;
    text-align: center;
  }
  th {
    border: 1px solid;
    text-align: left;
    padding: 9px;
    width: 50%;
    text-align: center;
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 10px;
    line-height: 10px;
  }
  td {
    border-left: 1px solid;
    border-right: 1px solid;
    padding: 20px 17px;
    width: 50%;
    text-align: center;
    font-weight: var(--brand-font-weight-medium);
    font-size: 10px;
    line-height: 118%;
  }
  tr:last-child {
    border-bottom: 1px solid;
  }
  .column-data-bold {
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 12px;
    line-height: 12px;
    text-align: left;
  }
  .prescription-preview__prescription-details-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .prescription-preview__prescription-details-doctor-sign {
    width: 150px;
    height: 100px;
  }
  @media screen and (min-width: 768px) {
    .prescription-preview__modal-content {
      padding: 11px;
    }

    .prescription-preview__modal-content {
      width: 720px;
      max-height: 60vh;
    }
    .prescription-preview__heading-button-container {
      position: relative;
      top: 0;
      box-shadow: none;
    }
    .prescription-preview__prescription-container {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`
