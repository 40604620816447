import React from 'react'
import { LoadingState } from '../../shared/src/ComponentsV2/common'
import { HomeStyledLoading } from './HomeLoading.styles'
import Responsive from '../../shared/src/ComponentsV2/HOC/Responsive'


function HomeSkeleton({ isMobile = false }) {
    return (
        <>
            {isMobile ? (<HomeStyledLoading>
                <LoadingState cardHeight={61} />
                <LoadingState cardHeight={44} />
                <LoadingState cardHeight={22} />
                <LoadingState cardHeight={82} numberOfCards={2} />
                <LoadingState cardHeight={28} />
                <div className='home-horizontal-container'>
                    <div className='home-right-one'>
                        <LoadingState cardHeight={100} />
                    </div>
                    <div className='home-right-one'>
                        <LoadingState cardHeight={100} />
                    </div>
                    <div className='home-right-one'>
                        <LoadingState cardHeight={100} />
                    </div>
                    <div className='home-right-one'>
                        <LoadingState cardHeight={100} />
                    </div>
                </div>
                <LoadingState cardHeight={384} />
                <LoadingState cardHeight={149} />
                <LoadingState cardHeight={34} />
                <LoadingState cardHeight={14} />
            </HomeStyledLoading>)
                : (<HomeStyledLoading>
                    <div className='home-horizontal-container'>
                        <div className='home-left'>
                            <LoadingState cardHeight={121} />
                            <LoadingState cardHeight={56} />
                            <LoadingState cardHeight={28} />
                            <LoadingState cardHeight={81} numberOfCards={2} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={430} />
                        </div>

                        <div className='home-right-two'>
                            <LoadingState cardHeight={190} numberOfCards={2} />
                        </div>
                    </div>
                    <LoadingState cardHeight={28} />
                    <div className='home-horizontal-container'>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>
                        <div className='home-right-one'>
                            <LoadingState cardHeight={147} />
                        </div>

                    </div>
                    <LoadingState cardHeight={319} />
                    <LoadingState cardHeight={330} />
                    <LoadingState cardHeight={58} />
                    <LoadingState cardHeight={30} />
                </HomeStyledLoading>)
            }
        </>

    )
}
export default Responsive(HomeSkeleton)