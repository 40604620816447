import styled from 'styled-components'

export const InformationGridStripContainer = styled.div`
  padding: 12px 0;
  background-color: var(--dls-category-primary-color-100);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .information-item {
    padding: 0px var(--dls-size-24);
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :not(:last-child) {
      border-right: 1px solid var(--dls-category-primary-color-200);
    }
  }
`
