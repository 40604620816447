import styled from 'styled-components'

export const OptionCardContainer = styled.div`
  border: 1px solid rgba(var(--category-primary-color-value), 0.37);
  display: flex;
  border-radius: 16px;
  height: 68px;
  align-items: center;
  padding: 20px 12px 20px 20px;
  max-width: 450px;
  margin: 15px auto 0 auto;
  cursor: pointer;

  &[data-is-image-container='true'] {
    padding: 0 20px 0 0;
  }

  .option_text {
    font-size: 16px;
    font-weight: var(--brand-font-weight-400);
    margin-right: auto;

    &[data-is-image-container='true'] {
      margin-left: 16px;
    }
  }
  .option_image__container {
    display: flex;
    margin-right: auto;
    height: 68px;
    width: 68px;
    border: 1px solid rgba(var(--category-primary-color-value), 0.37);
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    background-color: var(--category-primary-bg1-color);
  }
  &[data-is-category-screen='true'] {
    .option_image__container {
      height: 60px;
      width: 60px;
      border: 1px solid var(--brand-primary-white-color);
    }
    padding-left: 4px;
    .option_image {
      height: 100%;
      width: 100%;
      border-radius: 16px;
    }
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  }
  .option_image {
    height: 45px;
    width: 45px;
  }
  .option_wrapper {
    margin-right: auto;
    display: flex;
    align-items: center;
  }
  .icon_container {
    margin-left: auto;
  }

  .chevron-icon {
    color: var(--category-primary-color);
  }

  .checkbox {
    height: 28px;
    width: 28px;
    border-radius: 10px;
    border: 1px solid rgba(var(--category-primary-color-value), 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check-icon {
    color: var(--brand-primary-white-color);
  }

  &[data-is-selected='true'] {
    background-color: var(--category-primary-bg1-color);
    border: 1px solid var(--category-primary-color);

    .checkbox {
      background-color: var(--category-primary-color);
      border: 0px;
    }
  }

  &[data-is-selected='false'] {
    .check-icon {
      display: none;
    }
  }

  &[data-is-checkbox='true'] {
    background-color: transparent;
  }

  .option_checkbox {
    border: 1px solid var(--category-primary-bg1-color);
    border-radius: 10px;
    height: 28px;
    width: 28px;
  }

  @media screen and (min-width: 768px) {
    padding: 24px 14px 24px 30px;

    .option_text {
      font-size: 18px;
    }
  }
`
