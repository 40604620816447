import {StyledDoctorAppointmentCard} from './DoctorAppointmentCard.styles'
import {
  DoctorAppointmentCardProps,
  FooterCtaItem,
} from './DoctorAppointmentCard.interface'
import {DoctorDetails} from './DoctorDetails'
import {DoctorDetailsProps} from './DoctorDetails/DoctorDetails.interface'
import useDoctorAppointmentCard from './useDoctorAppointmentCard'
import {
  Button,
  OptimizedImage,
  Spacer,
  Typography,
  TypographyVariants,
} from '@web-components'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'

const DoctorAppointmentCard = (
  props: BaseWidgetProps<DoctorAppointmentCardProps>
) => {
  const {type, widgetData, header, layout, id} = props || {}
  const {
    title = '',
    referenceId = '',
    details = [],
    ctas = [],
    doctorDetails = {},
  } = widgetData || {}

  const {handleDoctorCardClick, handleFooterCtaClick} =
    useDoctorAppointmentCard(widgetData)

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledDoctorAppointmentCard>
        <div className="header">
          {title && (
            <Typography variant={TypographyVariants.TAG_BOLD}>
              {title}
            </Typography>
          )}
          {referenceId && (
            <Typography
              variant={TypographyVariants.X_SMALL}
              customClassName="reference-tag"
            >
              {referenceId}
            </Typography>
          )}
        </div>
        <Spacer variant="small" />
        <div className="details-wrapper">
          {details?.map((item, index) => (
            <div
              className="detail-container"
              key={'appointment_detail_' + index}
            >
              {item?.icon && (
                <OptimizedImage
                  source={item.icon}
                  customClassName="detail-icon"
                />
              )}
              {item?.label && (
                <Typography
                  variant={TypographyVariants.CTA_LABEL_SMALL}
                  customClassName="detail-label"
                >
                  {item?.label}
                </Typography>
              )}
            </div>
          ))}
        </div>
        {doctorDetails && (
          <>
            <div className="divider" />
            <DoctorDetails
              {...(doctorDetails as DoctorDetailsProps)}
              handleDoctorCardClick={handleDoctorCardClick}
            />
          </>
        )}
        <Spacer variant="medium" />
        <div className="footer-wrapper">
          {ctas?.map((item: FooterCtaItem, index: number) => (
            <Button
              key={'appointment_card_cta' + index}
              customClassName="footer-cta"
              data-isfullwidth={item.fullWidth}
              {...item}
              onClick={handleFooterCtaClick(item.actions)}
            />
          ))}
        </div>
      </StyledDoctorAppointmentCard>
    </BaseWidgetComponent>
  )
}

export default DoctorAppointmentCard
