import styled from 'styled-components'

export const RichProductCardsWapper = styled.div`
  .carousel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: var(--dls-size-8);
    position: relative;
  }

  @media (min-width: 768px) {
    max-width: 1120px;
    margin: auto;
    margin-top: var(--dls-size-16);
  }
`
