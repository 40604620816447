import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  .text {
    white-space: nowrap;
  }
  .section {
    width: 3px;
    height: 20px;
    background-color: #000;
    margin-right: var(--dls-size-8);
  }
  .spacer {
    padding-left: 12px;
  }
`
