const getGridItemWidth = (
  index: number,
  items: any[], //change type
  itemsPerRow: number,
  parentWidth: number
) => {
  const gap = 16
  const totalItems = items.length

  const currentRow = Math.ceil((index + 1) / itemsPerRow)
  const lastRow = Math.ceil(totalItems / itemsPerRow)
  const itemsInCurrentRow =
    currentRow !== lastRow
      ? itemsPerRow
      : totalItems % itemsPerRow || itemsPerRow

  // availableWidth is width of parent - gap between items in row
  const availableWidth = parentWidth - (itemsInCurrentRow - 1) * gap

  return availableWidth / itemsInCurrentRow
}

export {getGridItemWidth}
