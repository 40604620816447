export const BREAKPOINTS = {
  SmallMobile: 320,
  Mobile: 425,
  Tablet: 768,
  Desktop: 1024,
  LargeDesktop: 1440,
}
export const BACKEND_BASE_PATH =
  process.env.NEXT_PUBLIC_BACKEND || 'https://stg.api.ourlittlejoys.com/portal/'

export const BACKEND_BASE_PATH_ORIGIN =
  process.env.NEXT_PUBLIC_BACKEND_ORIGIN || 'https://stg.api.ourlittlejoys.com/'
