import styled from 'styled-components'

export const Accordion = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: var(--brand-primary-white-color);
  padding: 23px 16px 20px 20px;
  margin-top: 20px;
  &:first-child {
    margin-top: 0px;
  }

  @media (min-width: 768px) {
    padding: 40px 45px 40px 40px;
    border-radius: 40px;
    margin-top: 20px;

    &:first-child {
      margin-top: 0px;
    }
  }
`

export const AccordionTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  .icon-image {
    width: 100%;
    height: auto;

    .default {
      border-radius: 50%;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-open='true'] {
    .icon {
      transform: rotateZ(180deg);
    }
  }

  .icon {
    width: 25px;
    height: 25px;
    overflow: hidden;
    transition: all 0.4s;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 768px) {
    font-weight: bold;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 20px;

    .icon {
      width: 48px;
      height: 48px;
    }
  }
`

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  flex-grow: 1;

  .title-icon {
    width: 28px;
    height: 28px;
    margin-right: 12px;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    font-weight: bold;
    font-size: 50px;
    line-height: 57px;

    .title-icon {
      width: 60px;
      height: 60px;
      margin-right: 16px;
    }
  }
`

export const StyledContent = styled.div`
  .right {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 33px;
    img {
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    align-items: flex-start;

    .right,
    .left {
      flex-grow: 1;
      max-width: 50%;
      /* display: flex; */
      /* flex */
    }

    .left {
      margin-right: 96px;
    }

    .right {
      width: 340px;
      height: 340px;
      max-width: 340px;
      max-height: 340px;
      margin-top: 0;
      align-items: flex-start;

      img {
        width: 100%;
        height: auto;
        max-height: 100%;
      }
    }
  }
`
export const AccordionContent = styled.div`
  transition: opacity 0.2s, margin 0.4s 0.1s, max-height 0.4s 0.1s;
  max-height: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  font-weight: 400;
  font-size: 14px;

  &[data-open='true'] {
    transition: max-height 0.2s, margin 0.2s, opacity 0.4s 0.2s;
    margin: 23px 41px 0 0;
    height: auto;
    max-height: 800px;
    opacity: 1;
  }

  @media (min-width: 768px) {
    margin: 0;
    font-size: 18px;
    line-height: 22px;

    &[data-open='true'] {
      margin: 50px 76px 0 0;
      max-height: 800px;
    }
  }
`
