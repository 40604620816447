import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {TilesProps} from './Tiles.interface'
import {TilesWrapper} from './Tiles.styles'
import {useTiles} from './useTiles'

function Tiles({
  widgetData,
  id,
  header,
  layout,
  type,
}: BaseWidgetProps<TilesProps>) {
  const {
    noOfColumns,
    handleCtaClick,
    tiles,
    aspectRatio = 1,
    scaleFullWidthDesktop = false,
  } = useTiles({...widgetData, id})

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <TilesWrapper
        noOfColumns={noOfColumns}
        scaleFullWidthDesktop={scaleFullWidthDesktop}
      >
        <div className="tiles-container">
          {tiles.map((tile, index) => {
            const {icon, uiType, label, cta, slug} = tile || {}

            return (
              <a
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleCtaClick(cta)
                }}
                className="tile-container"
                key={`${index}-${slug}`}
                href={slug}
              >
                <OptimizedImage
                  source={icon}
                  aspectWidth={150}
                  aspectHeight={150 / aspectRatio}
                  desktopViewWidth="THIRD"
                  mobileViewWidth="THIRD"
                  altText="oops"
                  loading="lazy"
                  customClassName={`tile-image ${uiType}`}
                />
                {label && (
                  <div className="text-container">
                    <Typography variant={TypographyVariants.X_SMALL}>
                      {label}
                    </Typography>
                  </div>
                )}
              </a>
            )
          })}
        </div>
      </TilesWrapper>
    </BaseWidgetComponent>
  )
}

export default Tiles
