import noop from 'lodash/noop'
import React, {useCallback, useState} from 'react'

import CloseIcon from '../../../../../assets/icons/close.svg'
import Typography from '../../../../common/Typography/Typography'
import {CloseButton, StyledCta, StyledModal, StyledWrapper} from './styles'

function AllIngredients({state, actions}) {
  const {buttonText, ingredientNames} = state || {}
  const {disableBodyScroll = noop, enableBodyScroll = noop} = actions || {}
  const [showFullList, setShowFullList] = useState(false)

  const openFullList = useCallback(() => {
    disableBodyScroll()
    setShowFullList(true)
  }, [disableBodyScroll])

  const closeFullList = useCallback(() => {
    enableBodyScroll()
    setShowFullList(false)
  }, [enableBodyScroll])

  const stopProp = useCallback((e) => {
    e.stopPropagation()
  }, [])

  if (!ingredientNames) {
    return null
  }

  return (
    <StyledWrapper>
      <StyledCta onClick={openFullList}>
        <Typography text={buttonText} variant="cta-label-sm" />
      </StyledCta>
      {showFullList && (
        <StyledModal onClick={closeFullList}>
          <div className="content" onClick={stopProp}>
            <CloseButton onClick={closeFullList}>
              <img src={CloseIcon} loading="lazy" />
            </CloseButton>
            <h3>ALL INGREDIENTS</h3>
            <div className="modal-body">{ingredientNames}</div>
          </div>
        </StyledModal>
      )}
    </StyledWrapper>
  )
}

export default AllIngredients
