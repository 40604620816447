import styled from 'styled-components'

export const StyledDrawerWrapper = styled.div`
  .drawer {
    scrollbar-width: none;
    -ms-overflow-style: none;
    height: 100%;
    background: var(--brand-primary-white-color);
    position: fixed;
    top: 0;
    right: 0;
    width: 91%;
    max-width: 496px;
    z-index: 10;
    box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.5);
    overflow-y: visible;
    overflow-x: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .drawer::-webkit-scrollbar {
    width: 0px;
  }

  .drawer[data-drawer-open='true'] {
    body {
      overflow: hidden;
    }

    transition: transform
      ${(props) => `${Number.parseInt(props.transitionTime) || 500}ms`} ease;
  }

  .drawer[data-drawer-open='false'] {
    transform: translateX(100%);
    transition: transform
      ${(props) => `${Number.parseInt(props.transitionTime) || 500}ms`} ease;
  }
`
