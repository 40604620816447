export const processConcernClick = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    Category: data.category,
    Type: data.type,
  }

  return dataToReturn
}
