import {ImageGridContainer} from './ImageGrid.styles'
import {IImageGridProps} from './ImageGrid.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import isEmpty from 'lodash/isEmpty'
import {OptimizedImage} from '@web-components'

function ImageGrid(props: IImageGridProps) {
  const {id = '', type = '', widgetData, header, layout} = props || {}
  const {images = []} = widgetData || {}

  if (isEmpty(images)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <ImageGridContainer>
        {images.map((image) => (
          <OptimizedImage
            key={image.imageUrl}
            source={image.imageUrl}
            aspectHeight="56"
            aspectWidth="160"
            customClassName="image-item"
          />
        ))}
      </ImageGridContainer>
    </BaseWidgetComponent>
  )
}

export default ImageGrid
