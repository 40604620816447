import { useCallback } from 'react'
import { analyticsTrigger } from 'src/analytics'
import { EVENT_MAP } from 'src/analytics/eventMap'
export const useProductClickTracking = () => {
    const trackProductCardClick = useCallback(
        (productObj: Object) => {
            analyticsTrigger(EVENT_MAP.PDP_CLICKED, productObj)
        }, [])
    return {
        trackProductCardClick
    }
}