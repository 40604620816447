import noop from 'lodash/noop'
import {useCallback, useEffect} from 'react'

import Responsive from '../../HOC/Responsive'
import Modal from '../Modal/modal'
import {Typography} from '../TypographyV2'
import {ChildDetailsBottomSheetProps} from './ChildDetails.interface'
import {
  StyledAppDownloadWrapper,
  ToastWrapper,
} from './ChildDetailsBottomSheet.styles'
import ChildForm from './ChildForm'
import ParentSelector from './ParentSelector'

function ChildDetailsBottomSheet(props: ChildDetailsBottomSheetProps) {
  const {isMobile = false} = props || {}

  const {
    redirect = false,
    isApp = false,
    childDetailsData = {},
    showChildBottomSheet = false,
    childDetails = [],
    selectedParent = '',
    expandedIndex = 0,
    showModalFlag = false,
    showModal = false,
    initialState = [],
    isError = false,
  } = props.state || {}
  const {
    backgroundColor = '#fff',
    titleColor = '',
    subtitleColor = '',
    buttonColor = '',
    buttonTextColor = '',
    errorText = 'Please fill all fields !',
    webData = {},
  } = childDetailsData || {}

  const {
    submitForm = noop,
    handleUpdateLater = noop,
    removeChild = noop,
    handleHeightUnitChange = noop,
    handleAddChild = noop,
    handleParentChange = noop,
    handleChildGenderChange = noop,
    setChildDetails = noop,
    setShowModal = noop,
    toggleAccordionItem = noop,
    handleChildDetailChange = noop,
    setIsError = noop,
  } = props.actions || {}

  const {
    childForm = {},
    parentSection = [],
    bottomText = {},
    headerIcon = '',
    titleText = '',
    subTitleText = '',
    addChildBtnData = {},
  } = webData || {}
  const {submitTitle = '', progessText = ''} = bottomText || {}
  const {chooseGenders, formData = []} = childForm || {}

  const firstParentSection = parentSection?.[0] || {}

  const {label: parentLabel = '', options: parentOptions = []} =
    firstParentSection || {}

  const {id: childSection, label: childLabel, genders} = chooseGenders || {}
  const {text = 'Add Child'} = addChildBtnData || {}

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [showModal])

  useEffect(() => {
    if (showModalFlag) {
      setShowModal(true)
    }
  }, [isApp, showModalFlag])

  useEffect(() => {
    setTimeout(() => {
      setIsError(false)
    }, 2000)
  }, [isError])

  useEffect(() => {
    // only for initial render
    setChildDetails([
      ...childDetails,
      {
        ...initialState,
        childGender: '',
        dob: '',
        currentHeight: {unit: 'feet', feet: '', inches: '', cms: ''},
        deleteIcon: false,
        updateLatercurrentHeight: false,
        updateLatercurrentWeight: false,
      },
    ])
  }, [])

  if (!showChildBottomSheet || redirect || !isMobile) {
    return null
  }

  return (
    <>
      <StyledAppDownloadWrapper
        backgroundColor={backgroundColor}
        titleColor={titleColor}
        subtitleColor={subtitleColor}
        buttonColor={buttonColor}
        buttonTextColor={buttonTextColor}
        className="app-download-bottom-sheet"
      >
        <Modal
          showModal={showChildBottomSheet}
          onClose={handleClose}
          additionalContentClass="app-download-bottom-sheet-content-wrap"
          additionalModalClass={`${
            showChildBottomSheet
              ? 'app-download-bottom-sheet-modal'
              : 'app-download-bottom-sheet-modal-hide'
          }`}
          addtionalButtonClass="app-download-bottom-sheet-button-wrap"
          hideCloseButton
        >
          <form className="container">
            <div className="headerContainer">
              {headerIcon && <img src={headerIcon} alt="headerIcon" />}
              <Typography customClassName="title">{titleText}</Typography>
            </div>

            <Typography variant="body-base-regular" customClassName="subtitle">
              {subTitleText}
            </Typography>

            {/* Parent Options */}
            <div className="parentContainer">
              <Typography customClassName="divTitle">{parentLabel}</Typography>
              <div className="parent-options">
                {parentOptions && (
                  <ParentSelector
                    state={{parentOptions, selectedParent}}
                    actions={{handleParentChange}}
                  />
                )}
              </div>
            </div>

            {/* Accordion Forms*/}
            <ChildForm
              state={{
                childDetails: childDetails,
                expandedIndex,
                childSection,
                childLabel,
                genders,
                formData,
              }}
              actions={{
                toggleAccordionItem,
                removeChild,
                handleChildGenderChange,
                handleChildDetailChange,
                handleUpdateLater,
                handleHeightUnitChange,
              }}
            />

            <button type="button" className="addChild" onClick={handleAddChild}>
              <Typography variant="cta-label-sm">{text}</Typography>
            </button>
          </form>
          <div className="buttonContainer">
            <div className="progressText">{progessText}</div>
            <button
              className={`submitBtn ${!selectedParent ? 'btnDisabled' : ''}`}
              type="submit"
              onClick={submitForm}
              disabled={!selectedParent}
            >
              <Typography variant="cta-label-sm">{submitTitle}</Typography>
            </button>
          </div>
          {isError && (
            <ToastWrapper>
              <p>{errorText}</p>
            </ToastWrapper>
          )}
        </Modal>
      </StyledAppDownloadWrapper>
    </>
  )
}

export default Responsive(ChildDetailsBottomSheet)
