import {useCallback, useEffect} from 'react'
import noop from 'lodash/noop'
import useSATracking from '../tracking/useSATracking'

import {IUseSaHomePageReturnType} from './selfAssessment.interface'

function useSaHomePage(props: any): IUseSaHomePageReturnType {
  const {handleNextStep = noop} = props

  const {onSelfAssessmentViewed, onSelfAssessmentStarted} = useSATracking()

  useEffect(() => {
    onSelfAssessmentViewed()
  }, [onSelfAssessmentViewed])

  const onStartAssessmentClicked = useCallback(() => {
    onSelfAssessmentStarted()
    handleNextStep()
  }, [onSelfAssessmentStarted, handleNextStep])

  return [{}, {onStartAssessmentClicked}]
}

export {useSaHomePage}
