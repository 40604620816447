import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {IHtmlTextDataType} from './HtmlText/HtmlText.types'
import HtmlText from './HtmlText/HtmlText.component'

function DoctorListingHtmlText(props: BaseWidgetProps<IHtmlTextDataType>) {
  const {type, widgetData, header, layout, id} = props || {}
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <HtmlText {...widgetData} />
    </BaseWidgetComponent>
  )
}

export default DoctorListingHtmlText
