import noop from 'lodash/noop'
import React, {useCallback, useMemo, useState} from 'react'

import {getOptimizedImageKitLink} from '../../../../helpers/getOptimizedImageKitLink'
import Responsive from '../../../HOC/Responsive'
import {StyledWrapper} from './VideoPlayer.styles'

function VideoPlayer(props) {
  const {state = {}, isMobile = false, actions = {}} = props || {}
  const {
    autoplay = false,
    muted = true,
    loop = false,
    playsInline = false,
    controls = false,
    preload = 'metadata',
    mobileWidth,
    mobileHeight,
    desktopWidth,
    desktopHeight,
    additionalClassNames = '',
    loading = 'lazy',
    mobileSource = '',
    desktopSource = '',
    type = 'video/mp4',
    desktopViewWidth = 'FULL',
    mobileViewWidth = 'HALF',
    posterImage = '',
    disablePictureInPicture = true,
    playIcon = '',
    borderRadiusDesktop = 0,
    borderRadiusMobile = 0,
    customStyle = {},
  } = state

  const {playPauseVideo = noop} = actions

  const [isVideoPlaying, setIsVideoPlaying] = useState(autoplay)

  const source = useMemo(() => {
    return isMobile ? mobileSource : desktopSource
  }, [desktopSource, isMobile, mobileSource])

  const [height, width] = useMemo(() => {
    return isMobile
      ? [mobileHeight, mobileWidth]
      : [desktopHeight, desktopWidth]
  }, [desktopHeight, desktopWidth, isMobile, mobileHeight, mobileWidth])

  const modifiedSrc = useMemo(() => {
    return getOptimizedImageKitLink(
      source,
      desktopViewWidth,
      mobileViewWidth,
      isMobile
    )
  }, [source, desktopViewWidth, mobileViewWidth, isMobile])

  const videoBorderRadius = useMemo(() => {
    return isMobile ? borderRadiusMobile : borderRadiusDesktop
  }, [borderRadiusDesktop, borderRadiusMobile, isMobile])

  const showControls = useMemo(() => {
    return isVideoPlaying && controls
  }, [controls, isVideoPlaying])

  const onOverlayClick = useCallback(
    (e) => {
      e.preventDefault()
      let videos = document.querySelectorAll(`.${additionalClassNames}`)
      videos.forEach((video) => {
        if (video.paused) {
          playPauseVideo()
          setIsVideoPlaying(true)
        } else {
          video.pause()
          setIsVideoPlaying(false)
        }
      })
    },
    [additionalClassNames, playPauseVideo]
  )

  return (
    <StyledWrapper
      borderRadius={videoBorderRadius}
      dynamicWidth={width}
      dynamicHeight={height}
    >
      <video
        autoPlay={autoplay}
        muted={muted}
        loop={loop}
        playsInline={playsInline}
        preload={preload}
        className={`video ${additionalClassNames}`}
        loading={loading}
        poster={posterImage}
        controls={showControls}
        controlsList="nodownload noremoteplayback noplaybackrate foobar"
        disablePictureInPicture={disablePictureInPicture}
        onPlay={() => setIsVideoPlaying(true)}
        onPause={() => setIsVideoPlaying(false)}
        style={customStyle}
      >
        <source id="my-video" src={modifiedSrc} type={type} />
      </video>
      {playIcon && controls && (
        <div className="video-overlay" onClick={onOverlayClick}>
          <div className="video-label-btn-container">
            <img src={isVideoPlaying ? '' : playIcon} alt="" />
          </div>
        </div>
      )}
    </StyledWrapper>
  )
}

export default Responsive(VideoPlayer)
