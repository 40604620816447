import React, {useCallback, useEffect, useState} from 'react'
import {VideoImageProductContainer} from './VideoImageProductCard.styles'
import {
  OptimizedImage,
  OptimizedVideo,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useGenericActions} from '../../../hooks'
import {VideoImageProductCardProps} from '../VideoImageProductCardSlider.interface'

function VideoImageProductCard({
  product,
  imageDimensions,
  aspectRatio,
  videoMutedIcon = '',
  videoUnMutedIcon = '',
  isActive,
  setActiveVideo,
  handleAction,
}: VideoImageProductCardProps) {
  const {
    baseImage = '',
    baseVideo = '',
    tag = '',
    productImage = '',
    title = '',
    buttonText = '',
    cta,
  } = product

  const [videoMuted, setVideoMuted] = useState(true)

  // When the active video changes, ensure that this video is muted if it's not active
  useEffect(() => {
    setVideoMuted(!isActive)
  }, [isActive])

  const handleOnClick = useCallback(() => {
    const defaultCardAction = {actionName: 'OPEN_PDP', params: product}
    handleAction(cta || defaultCardAction)
  }, [cta, handleAction, product])

  const toggleMute = () => {
    if (!isActive) {
      setActiveVideo() // Set this video as active in parent component
    }
    setVideoMuted((muted) => !muted)
  }

  return (
    <VideoImageProductContainer>
      {baseImage && (
        <div className="image-container">
          <div className="image-overlay" />
          <OptimizedImage
            source={baseImage}
            aspectWidth={imageDimensions[0]?.toString()}
            aspectHeight={imageDimensions[1]?.toString()}
            desktopViewWidth="FULL"
            mobileViewWidth="FULL"
            altText="oops"
            loading="lazy"
            customClassName="base-image"
          />
        </div>
      )}
      {baseVideo && (
        <div
          style={{
            flex: 1,
            width: '100%',
            aspectRatio: aspectRatio.toString(),
          }}
        >
          <OptimizedVideo
            state={{
              autoplay: true,
              muted: videoMuted,
              loop: true,
              playsInline: true,
              source: baseVideo,
              preload: 'metadata',
              width: imageDimensions[0]?.toString(),
              height: imageDimensions[1]?.toString(),
              additionalClassNames: 'video-player',
            }}
          />
          <div onClick={toggleMute} className="video-mute-button">
            {videoMuted ? (
              <OptimizedImage
                source={videoMutedIcon}
                aspectWidth={24}
                aspectHeight={24}
                desktopViewWidth="TINY"
                mobileViewWidth="TINY"
                altText="oops"
                loading="lazy"
              />
            ) : (
              <OptimizedImage
                source={videoUnMutedIcon}
                aspectWidth={24}
                aspectHeight={24}
                desktopViewWidth="TINY"
                mobileViewWidth="TINY"
                altText="oops"
                loading="lazy"
              />
            )}
          </div>
        </div>
      )}
      {tag && (
        <div className="tag-container">
          <Typography variant={TypographyVariants.TAG_BOLD}>{tag}</Typography>
        </div>
      )}
      {productImage && (
        <div className="product-image-container">
          <OptimizedImage
            source={productImage}
            aspectWidth={80}
            aspectHeight={80}
            desktopViewWidth="SMALL"
            mobileViewWidth="SMALL"
            altText="oops"
            loading="lazy"
            customClassName="product-image"
          />
          <div className="text-container">
            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {title}
            </Typography>
          </div>
          <button className="button" onClick={handleOnClick}>
            <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
              {buttonText}
            </Typography>
          </button>
        </div>
      )}
    </VideoImageProductContainer>
  )
}

export default VideoImageProductCard
