import styled from 'styled-components'

export const SearchResultProductListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-12);
  width: 100%;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
