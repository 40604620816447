import styled from 'styled-components'

export const StyledOrderSummaryWrapper = styled.div`
  .heading-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 20px;
    padding-bottom: var(--dls-size-16);

    @media screen and (min-width: 768px) {
    }
  }
  .subtitle {
    padding-bottom: var(--dls-size-8);
  }
  .item-container {
    padding: var(--dls-size-16) var(--dls-size-16) var(--dls-size-0)
      var(--dls-size-16);
    border-radius: var(--dls-size-16);
    border-width: var(--dls-size-1);
    border-style: solid;
    border-color: var(--dls-divider-light-color);
    background: var(--dls-white-color);
    &[data-is-bottomcallout='true'] {
      border-radius: var(--dls-size-16) var(--dls-size-16) var(--dls-size-0)
        var(--dls-size-0);
      border-width: var(--dls-size-1) var(--dls-size-1) var(--dls-size-0)
        var(--dls-size-1);
    }
  }

  &[data-isnewcartdesign='true'] {
    .item-container {
      border: none !important;
    }
  }
  @media screen and (min-width: 768px) {
    .heading-container {
      padding-top: 32px;
    }
  }
`

interface StyledLineItemComponent {
  labelColor: string
  valueColor: string
  subTextColor: string
}

export const StyledLineItem = styled.div<StyledLineItemComponent>`
  padding-bottom: var(--dls-size-16);
  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .label {
    color: ${(props) => props.labelColor || ''};
  }
  .value {
    color: ${(props) => props.valueColor || ''};
  }
  .sub-text-container {
    margin-top: var(--dls-size-8);
  }
  .subText {
    color: ${(props) => props.subTextColor || ''};
  }
`

interface StyledBottomCallOutComponent {
  backgroundColor: string
}

export const StyledBottomCallOut = styled.div<StyledBottomCallOutComponent>`
  background: ${(props) => props.backgroundColor || ''};
  padding: var(--dls-size-16);
  border-radius: var(--dls-size-0) var(--dls-size-0) var(--dls-size-16)
    var(--dls-size-16);
  border: var(--dls-size-1) solid var(--dls-divider-light-color);
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--dls-success-color-500);

  @media screen and (min-width: 768px) {
    padding: var(--dls-size-24) var(--dls-size-16) var(--dls-size-24)
      var(--dls-size-16);
  }
`
