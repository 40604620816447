import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'

import {useGenericActions} from '../../hooks'
import {GenericSlider, OptimizedImage, ProductCardV2} from '@web-components'
import {IProductsProps} from './ProductCardV2Slider.interface'
import {ProductCardV2SliderContainer} from './ProductCardV2Slider.style'

function ProductCardV2Slider(props: IProductsProps) {
  const {type, widgetData, header, layout, id} = props || {}
  const {products, slidesToShow, introCard = ''} = widgetData || {}
  const {genericAction} = useGenericActions()

  if (isEmpty(products)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductCardV2SliderContainer>
        <GenericSlider noOfSlides={slidesToShow} aspectRatio={1}>
          {() => (
            <>
              <OptimizedImage
                source={introCard}
                aspectWidth="160"
                aspectHeight="259"
                desktopViewWidth="THIRD"
                mobileViewWidth="THIRD"
                altText="oops"
                loading="lazy"
                customClassName="intro-image"
              />
              {products.map((product) => {
                return (
                  <ProductCardV2
                    key={product.id}
                    product={{...product, genericAction}}
                  />
                )
              })}
            </>
          )}
        </GenericSlider>
      </ProductCardV2SliderContainer>
    </BaseWidgetComponent>
  )
}

export default ProductCardV2Slider
