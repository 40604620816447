import React, {useCallback} from 'react'
import {OptimizedImage, TypographyVariants} from '@web-components'
import {Typography} from '@web-components'
import {MobileCategoryPaletteContainer} from './MobilePaletteCard.styles'
import {ChevronRight} from '@web-components'
import {
  MobileCategoryPaletteCardProps,
  MobileCategoryPaletteContainerProps,
} from './MobilePaletteCard.interface'

const MobilePaletteCard = (props: MobileCategoryPaletteCardProps) => {
  const {cardData, handleAction} = props
  const {
    image = '',
    imageHeight = '',
    imageWidth = '',
    bgColor = '',
    categoryName = '',
    description = '',
    imageBgColor = '',
    cta,
  } = cardData || {}

  const handleClick = useCallback(
    (e: React.TouchEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      handleAction(cta)
    },
    [handleAction, cta]
  )

  return (
    <MobileCategoryPaletteContainer
      href="#"
      bgColor={bgColor}
      imageBgColor={imageBgColor}
      onClick={handleClick}
    >
      <div className="image-container">
        <OptimizedImage
          source={image}
          aspectWidth={imageWidth || 120}
          aspectHeight={imageHeight || 120}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          altText="celeb-image"
          loading="lazy"
          customClassName="card-image"
        />
      </div>
      <div className="content-container">
        <div className="content-top">
          <Typography
            variant={TypographyVariants.HEADING_SMALL_BOLD}
            customClassName="categoryName"
          >
            {categoryName}
          </Typography>
          <ChevronRight />
        </div>
        <div className="content-bottom">
          <Typography
            variant={TypographyVariants.SUBHEADING_REGULAR}
            customClassName="description"
          >
            {description}
          </Typography>
        </div>
      </div>
    </MobileCategoryPaletteContainer>
  )
}

export default MobilePaletteCard
