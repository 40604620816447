import React from 'react'

function StarFilled({
  fill = '#FFC820',
  stroke = '#FFC820',
  width = 36,
  height = 33,
  expand = false,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={expand ? width * 1.4 : width}
      height={expand ? height * 1.4 : height}
      fill="none"
      viewBox="0 0 36 33"
    >
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M18 2l4.702 9.528 10.515 1.528-7.608 7.416 1.796 10.472L18 26l-9.405 4.944 1.796-10.472-7.608-7.416 10.515-1.528L18 2z"
        opacity="1"
      ></path>
    </svg>
  )
}

export default StarFilled
