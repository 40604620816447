import React from 'react'
import { LoadingState } from '../../shared/src/ComponentsV2/common'
import { ShopStyledLoading } from './ShopLoading.styles'
import Responsive from '../../shared/src/ComponentsV2/HOC/Responsive'


function ShopSkeleton({ isMobile = false, }) {
    return (
        <>
            {isMobile ? (<ShopStyledLoading>
                <LoadingState cardHeight={149} />
                <div className='shop-horizontal-container'>
                    <div className='shop-block'>
                        <LoadingState cardHeight={40} />
                    </div>
                    <div className='shop-block'>
                        <LoadingState cardHeight={40} />
                    </div>
                    <div className='shop-block'>
                        <LoadingState cardHeight={40} />
                    </div>

                </div>
                <div className='filter-horizontal-container'>
                    <div className='filter-block'>
                        <LoadingState cardHeight={28} />
                    </div>
                </div>
                <div className='shop-horizontal-container'>
                    <div className='home-left'>
                        <LoadingState cardHeight={28} />
                    </div>
                    <div className='home-mid'>

                    </div>
                    <div className='home-right'>
                        <LoadingState cardHeight={16} />
                    </div>
                </div>
                <div className='shop-horizontal-container'>
                    <div className='home-left-half'>
                        <LoadingState cardHeight={326} />
                    </div>
                    <div className='home-right-half'>
                        <LoadingState cardHeight={326} />
                    </div>
                </div>
                <div className='shop-horizontal-container'>
                    <div className='home-left-half'>
                        <LoadingState cardHeight={326} />
                    </div>
                    <div className='home-right-half'>
                        <LoadingState cardHeight={326} />
                    </div>
                </div>
            </ShopStyledLoading>)
                : (<ShopStyledLoading>
                    <LoadingState cardHeight={275} />
                    <div className='shop-horizontal-container'>
                        <div className='shop-block'>
                            <LoadingState cardHeight={59} />
                        </div>
                        <div className='shop-block'>
                            <LoadingState cardHeight={59} />
                        </div>
                        <div className='shop-block'>
                            <LoadingState cardHeight={59} />
                        </div>
                        <div className='shop-block'>
                            <LoadingState cardHeight={59} />
                        </div>
                        <div className='shop-block'>
                            <LoadingState cardHeight={59} />
                        </div>
                    </div>
                    <div className='shop-horizontal-container'>
                        <div className='home-left'>
                            <LoadingState cardHeight={55} />
                            <LoadingState cardHeight={21} />
                        </div>
                        <div className='home-mid'>

                        </div>
                        <div className='home-right'>
                            <LoadingState cardHeight={48} />
                        </div>
                    </div>
                    <div className='filter-horizontal-container'>
                        <div className='home-left-half'>
                            <LoadingState cardHeight={531} />
                        </div>
                        <div className='home-left-half'>
                            <LoadingState cardHeight={531} />
                        </div>
                        <div className='home-right-half'>
                            <LoadingState cardHeight={531} />
                        </div>
                    </div>
                    <div className='filter-horizontal-container'>
                        <div className='home-left-half'>
                            <LoadingState cardHeight={531} />
                        </div>
                        <div className='home-left-half'>
                            <LoadingState cardHeight={531} />
                        </div>
                        <div className='home-right-half'>
                            <LoadingState cardHeight={531} />
                        </div>
                    </div>
                </ShopStyledLoading>)
            }
        </>

    )
}
export default Responsive(ShopSkeleton)