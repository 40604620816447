import React from 'react'
import {
  Button,
  OptimizedImage,
  Typography,
  TypographyVariants,
  ImageOrAnimationBanner,
  Modal,
} from '@web-components'
import {CardContainer, BackGroundGridImg} from './CredClaimSheet.styles'
import {useClaimSheet} from './useClaimSheet'
import {ICredClaimSheetProps} from './CredClaimSheet.types'

const CredClaimSheet = (props: ICredClaimSheetProps) => {
  const {state, action} = props || {}
  const {showClaimSheet, claimSheetData, availableCoins} = state
  const {handleShowClaimSheet} = action
  const {
    backgroundImage = '',
    brandLogo = '',
    credLogo = '',
    equalToImage = ' ',
    sheetTextContent = '',
    coinImage = '',
    healthPassImage = '',
    healthPassText = '',
    footerContent = '',
    cta = {},
  } = claimSheetData || {}

  const {
    credValidationData,
    credValidationResponse,
    loading,
    handleConsultationClick,
  } = useClaimSheet()

  const {isUserValidated = false, healthPassBannerData = {}} =
    credValidationData || {}

  return (
    <Modal
      showModal={showClaimSheet}
      onClose={handleShowClaimSheet}
      isPadding={false}
      cancelCloseIcon={true}
    >
      <BackGroundGridImg>
        <OptimizedImage
          source={backgroundImage}
          aspectWidth="100%"
          customClassName="bgCustomClass"
        />
      </BackGroundGridImg>

      <CardContainer>
        {!isUserValidated ? (
          <>
            <div className="image-group">
              <OptimizedImage
                source={brandLogo}
                alt="Consultation service icon"
                customClassName="rounded"
              />
              <OptimizedImage
                source={equalToImage}
                alt="Connection icon"
                customClassName="small"
              />
              <OptimizedImage
                source={credLogo}
                alt="Expert consultation icon"
                customClassName="rounded"
              />
            </div>
            <Typography
              variant={TypographyVariants.HEADING_SMALL_BOLD}
              customClassName="title"
            >
              {sheetTextContent}
            </Typography>
            <div className="badge-wrapper">
              <div className="badge-container">
                <Typography
                  variant={TypographyVariants.BODY_BASE_REGULAR}
                  customClassName="badgeContent"
                >
                  {availableCoins}
                </Typography>
                <OptimizedImage
                  source={coinImage}
                  customClassName="badgeIcon"
                />
              </div>
              <Typography variant={TypographyVariants.HEADING_LARGE_REGULAR}>
                =
              </Typography>
              <div className="badge-container">
                <Typography
                  variant={TypographyVariants.BODY_BASE_REGULAR}
                  customClassName="badgeContent"
                >
                  {healthPassText}
                </Typography>
                <OptimizedImage
                  source={healthPassImage}
                  customClassName="badgeIcon"
                />
              </div>
            </div>
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="subtitle"
            >
              {footerContent}
            </Typography>
          </>
        ) : (
          <>
            <ImageOrAnimationBanner {...healthPassBannerData} />
            <Typography
              variant={TypographyVariants.HEADING_SMALL_BOLD}
              customClassName="title-bottom"
            >
              {credValidationData?.sheetTextContent}
            </Typography>
          </>
        )}
        <Button
          variant="PRIMARY"
          customClassName="styled-button"
          onClick={
            !isUserValidated
              ? () => {
                  credValidationResponse(cta?.action)
                }
              : handleConsultationClick
          }
          loading={loading}
          size="LARGE"
          label={!isUserValidated ? cta?.label : credValidationData?.cta?.label}
        />
      </CardContainer>
    </Modal>
  )
}

export default CredClaimSheet
