import React, {useMemo} from 'react'

import Responsive from '../../../ComponentsV2/HOC/Responsive'
import OptimizedImage from '../OptimizedImage'
import {SectionWithOffsetContainer} from './SectionWithOffset.styles'

function SectionWithOffset(props) {
  const {children, isMobile = false, additionalClass = ''} = props
  const {
    backgroundImageDesktop = '',
    backgroundImageMobile = '',
    alt = '',
  } = props.state || {}

  const imageToShow = useMemo(
    () => (isMobile ? backgroundImageMobile : backgroundImageDesktop),
    [backgroundImageDesktop, backgroundImageMobile, isMobile]
  )

  return (
    <SectionWithOffsetContainer
      data-showbackground={!!imageToShow}
      className={additionalClass}
    >
      <div className="image-container">
        {imageToShow && (
          <>
            <OptimizedImage
              source={imageToShow}
              aspectWidth="400"
              aspectHeight="299"
              desktopViewWidth="FULL"
              mobileViewWidth="HALF"
              altText={alt}
              loading="eager"
              customClassName="image"
            />
            <div className="overlay"></div>
          </>
        )}
      </div>

      <div className="content">{children}</div>
    </SectionWithOffsetContainer>
  )
}

export default Responsive(SectionWithOffset)
