import React from 'react'
import isEmpty from 'lodash/isEmpty'

import {RescheduleAppointmentCardWrapper} from './RescheduleAppointmentCard.styles'
import {LoadingState} from '../../../../ComponentsV2/common'

const RescheduleAppointmentCard = (props) => {
  const {
    title = '',
    subTitle = '',
    appointmentDateHeading = '',
    appointmentTimeHeading = '',
    appointmentDate = '',
    appointmentTime = '',
    appoinmentLabel = '',
    isAppointmentDataLoading = false,
    showCancelButton = false,
  } = props.state || {}

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <RescheduleAppointmentCardWrapper
      data-is-cancel-button-visible={!showCancelButton}
    >
      <div className="appointment-card__container">
        <div className="appointment-card">
          <div className="appointment-card__heading-container">
            <div className="appointment-card__title">
              Reschedule {appoinmentLabel}
            </div>
            <div className="appointment-card__sub-title">{subTitle}</div>
          </div>
          <div className="appointment-card__line"></div>

          <div className="appointment-card__row">
            <div className="appointment-card__col">
              <div className="appointment-card__col-heading">
                {appointmentDateHeading}
              </div>
              {isAppointmentDataLoading ? (
                <LoadingState cardHeight={10} />
              ) : (
                <div className="appointment-card__col-data">
                  {appointmentDate}
                </div>
              )}
            </div>
            <div className="appointment-card__col">
              <div className="appointment-card__col-heading">
                {appointmentTimeHeading}
              </div>

              {isAppointmentDataLoading ? (
                <LoadingState cardHeight={10} />
              ) : (
                <div className="appointment-card__col-data">
                  {appointmentTime}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </RescheduleAppointmentCardWrapper>
  )
}
export default RescheduleAppointmentCard
