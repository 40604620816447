import {useCallback, useMemo, useState, useEffect} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {StyledWrapper} from './styles'
import {PrescriptionPreview} from '../../../Screens/RxCart'

function ViewPrescriptionCard({
  config = {},
  prescriptionAvailable = false,
  isPseudoRxFlow = false,
  trackPrescriptionViewed = noop,
  prescriptionData,
  prescriptionPreview = {},
}) {
  const [showPrescription, setShowPrescription] = useState(false)
  const {
    image = '',
    doctorText = '',
    highlightText = '',
    ctaLabel = '',
    statistics = [],
    prescriptionExistsText = '',
  } = config
  const {doctor} = prescriptionData || {}
  const {name = ''} = doctor || {}

  const isPrescriptionInfoAvailable = useMemo(
    () => !isEmpty(prescriptionData),
    [prescriptionData]
  )

  const toggleViewPrescription = useCallback(() => {
    setShowPrescription((current) => !current)
  }, [])

  useEffect(() => {
    trackPrescriptionViewed()
  }, [])

  if (isPseudoRxFlow && !prescriptionAvailable) {
    return null
  }

  return (
    <>
      {prescriptionAvailable && (
        <StyledWrapper>
          {isPrescriptionInfoAvailable ? (
            <>
              <div className="card-top-data">
                <div className="card-image-container">
                  <img src={image} alt="doctor icon" />
                </div>
                <div className="card-top-left-data">
                  <div className="doctor-text">
                    {doctorText} <b>{name || 'Your Doctor'}</b>
                  </div>

                  <div className="statistics-container">
                    {statistics.map((item) => (
                      <div key={item.stat} className="statistic-container">
                        <span className="stat">{item.stat}</span>
                        <span className="label">{item.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="highlight-text">{highlightText}</div>

              <button
                type="button"
                onClick={toggleViewPrescription}
                className="action-btn"
              >
                {ctaLabel}
              </button>
            </>
          ) : (
            <p className="already-prescribed">{prescriptionExistsText}</p>
          )}
        </StyledWrapper>
      )}

      <PrescriptionPreview
        state={{
          ...prescriptionData,
          ...prescriptionData?.doctor,
          ...prescriptionData?.prescription,
          ...prescriptionPreview,
          showModal: showPrescription,
        }}
        actions={{
          handleModalClose: toggleViewPrescription,
        }}
      />
    </>
  )
}

export default ViewPrescriptionCard
