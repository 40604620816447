const LocalStorage = {
  setItem(key: string, value: any) {
    window.localStorage.setItem(key, JSON.stringify(value))
  },
  getItem(key: string) {
    return JSON.parse(window.localStorage.getItem(key) || 'null')
  },
  removeItem(key: string) {
    window.localStorage.removeItem(key)
  },
}

export {LocalStorage}
