import styled from 'styled-components'

export const StyledWrapper = styled.div`
  background-color: var(--category-primary-color);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  font-family: var(--brand-font-family-text);

  .card-top-data {
    display: flex;
    margin-bottom: 12px;
    width: 100%;
  }

  .card-image-container {
    height: 58px;
    width: 58px;
    border-radius: 10px;
    display: block;
    margin-right: 16px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    @media screen and (min-width: 768px) {
      height: 80px;
      width: 80px;
    }
  }

  .doctor-text {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 18px;
    margin-bottom: 8px;
    color: var(--brand-primary-white-color);
    text-align: left;

    b {
      font-weight: var(--brand-font-weight-heavy);
    }

    @media screen and (min-width: 768px) {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  .statistics-container {
    display: flex;
    align-items: baseline;

    .statistic-container {
      :not(:last-child) {
        margin-right: 6px;
      }

      :not(:nth-child(1)) {
        padding-left: 10px;
        border-left: 1px solid var(--brand-primary-white-color);
      }
    }

    .stat {
      font-size: 12px;
      color: var(--brand-primary-light-dark-yellow-color);
      font-weight: var(--brand-font-weight-bold);
      margin-right: 8px;
    }

    .label {
      font-size: 12px;
      font-weight: var(--brand-font-weight-medium);
      color: rgba(255, 255, 255, 0.6);
    }

    @media screen and (min-width: 768px) {
      .stat,
      .label {
        font-size: 18px;
      }
    }
  }

  .highlight-text {
    font-size: 14px;
    font-weight: var(--brand-font-weight-heavy);
    color: var(--brand-primary-white-color);
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  .status-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.01em;
    color: var(--brand-primary-white-color);

    opacity: 0.7;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
      font-size: 24px;
    }
  }

  .action-btn {
    cursor: pointer;
    border-radius: 12px;
    width: 100%;
    color: var(--brand-primary-white-color);
    background-color: var(--brand-primary-teal-color);
    font-weight: var(--brand-font-weight-700);
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: var(--brand-primary-white-color);
    padding: 12px;
    border: none;
    outline: none;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      padding: 20px 12px;
    }
  }

  .already-prescribed {
    font-size: 14px;
    color: var(--brand-primary-white-color);
    font-weight: 500;
    line-height: 120%;
    margin: 0;

    @media screen and (min-width: 768px) {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 744px;
    padding: 20px;
  }
`

export const StyledModalWrapper = styled.div`
  .prescription-modal-content {
    max-height: 65vh;
  }
`

export const StyledPrescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .download-btn {
    border: none;
    border-radius: 10px;
    padding: 0;
    background-color: transparent;
    width: 48px;
    height: 48px;
    cursor: pointer;
  }

  .prescription-iframe {
    background-color: #fff;

    embed {
      background-color: #fff;
    }
  }
`
