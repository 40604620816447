const getDataExistsValue = (formId: number | undefined, completed: boolean) => {
  switch (true) {
    case formId && completed: {
      return 'Y'
    }

    case formId && !completed: {
      return 'Partial'
    }

    default:
      return 'N'
  }
}

export const processBasicDetailsViewed = (
  dLayer: typeof window.customDataLayer
) => {
  const {lastFormIdData} = dLayer
  const {formId = undefined, completed = false} = lastFormIdData || {}

  return {
    'Data exists': getDataExistsValue(formId, completed),
  }
}

export const processDetailSubmitted = (
  dLayer: typeof window.customDataLayer
) => {
  const {lastFormIdData} = dLayer
  const {formId = undefined, completed = false} = lastFormIdData || {}

  return {
    'Data exists': getDataExistsValue(formId, completed),
  }
}

export const processDetailsQuestionAnswered = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {lastFormIdData} = dLayer
  const {formId = undefined, completed = false} = lastFormIdData || {}
  const {question, answer} = data

  return {
    Question: question,
    Response: answer,
    'Data exists': getDataExistsValue(formId, completed),
  }
}
