import isEmpty from 'lodash/isEmpty'
import ImageGallery from 'react-image-gallery'

import {StyledWrapper} from './VideoReviews.styles'
import RenderItem from './Video'
import Responsive from '../../../../HOC/Responsive'
import {useCallback, useMemo, useState} from 'react'
import {LeftNav, RightNav} from '../../../HomePage/FirstFoldCarousel'
import OptimizedVideoV2 from '../../../../common/OptimizedVideoV2/OptimizedVideoV2'

const Testimonials = (props) => {
  const {videos = [], isMobile = 0} = props
  const [activeVideoIndex, setActiveVideoIndex] = useState(0)

  const handleSlide = useCallback((currentIndex) => {
    setActiveVideoIndex(currentIndex)
  }, [])

  const renderItem = useCallback((index) => {
    return (item) => {
      return (
        <OptimizedVideoV2
          state={{
            ...item,
            videoComponentClassName: `video-review-${index}`,
          }}
        />
      )
    }
  }, [])

  const mappedVideos = useMemo(() => {
    return videos?.map((video, index) => {
      const {isEmbedded = true} = video || {}
      if (isEmbedded) {
        const videoHasThumbNails = video && typeof video !== 'string'
        const videoItem = videoHasThumbNails ? video : {video}
        return {
          ...videoItem,
          renderItem: (videoItem) => {
            const isActive = index === activeVideoIndex

            return <RenderItem {...videoItem} isActive={isActive} />
          },
        }
      } else {
        return {
          ...video,
          renderItem: renderItem(index),
        }
      }
    })
  }, [videos, activeVideoIndex, renderItem])

  if (isEmpty(videos)) return null

  const isNavShow = !isMobile

  return (
    <StyledWrapper>
      <ImageGallery
        additionalClass="img-gallery"
        showNav={isNavShow}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        infinite={false}
        startIndex={activeVideoIndex}
        showBullets={true}
        onSlide={handleSlide}
        items={mappedVideos}
        renderLeftNav={(onClick, disabled) => {
          return <LeftNav disabled={disabled} onclick={onClick} />
        }}
        renderRightNav={(onClick, disabled) => {
          return <RightNav disabled={disabled} onclick={onClick} />
        }}
      />
    </StyledWrapper>
  )
}

export default Responsive(Testimonials)
