/**
 * ! CLIENT_SIDE only
 * @description: client-side only
 */
export const getQueryParamFromUrl = (key: string) => {
  if (
    typeof window === 'undefined' ||
    typeof window.location === 'undefined' ||
    typeof window.location.search === 'undefined'
  ) {
    return ''
  }
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params: Record<string, string> = {}

  urlSearchParams.forEach((value, key) => {
    params[key] = value
  })

  return params[key] || undefined
}
