import {useCallback, useEffect, useMemo, useRef} from 'react'
import isEmpty from 'lodash/isEmpty'
import ImageGallery from 'react-image-gallery'
import {LeftNav, RenderItem, RightNav} from './index'
import {StyledWrapper} from './styles'

import Responsive from '../../../HOC/Responsive'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'

const FirstFoldCarousel = (props) => {
  const {state = {}, isMobile} = props
  const {mobile = {}, desktop = {}} = state || {}
  const imageGalleryRef = useRef()

  useEffect(() => {
    const startAutoPlay = () => {
      setTimeout(() => {
        if (window.requestIdleCallback) {
          window.requestIdleCallback(() => {
            imageGalleryRef.current?.play()
          })
        } else {
          imageGalleryRef.current?.play()
        }
      }, 7000)
    }
    if (document.readyState === 'complete') {
      startAutoPlay()
    } else {
      window.addEventListener('load', startAutoPlay)
      return () => document.removeEventListener('load', startAutoPlay)
    }
  }, [])
  let images = useMemo(() => {
    if (isMobile) {
      return mobile?.images || []
    }
    return desktop?.images || []
  }, [isMobile, mobile, desktop])

  const renderItem = useCallback((index) => {
    return (item) => {
      return <RenderItem {...item} lazyLoad={index !== 0} />
    }
  }, [])

  const mappedImages = useMemo(() => {
    return images.map((image, index) => {
      return {
        ...image,
        renderItem: renderItem(index),
      }
    })
  }, [images, renderItem])
  const isNavShow = !isMobile

  if (isEmpty(images)) {
    return null
  }
  return (
    <StyledWrapper>
      <ImageGallery
        ref={imageGalleryRef}
        showNav={isNavShow}
        showThumbnails={false}
        showFullscreenButton={false}
        infinite={true}
        slideInterval={5000}
        autoPlay={false}
        showPlayButton={false}
        showBullets={images.length >= 2}
        items={mappedImages}
        renderLeftNav={(onClick, disabled) => {
          return <LeftNav disabled={disabled} onclick={onClick} />
        }}
        renderRightNav={(onClick, disabled) => {
          return <RightNav disabled={disabled} onclick={onClick} />
        }}
      />
    </StyledWrapper>
  )
}

export default Responsive(
  withCustomErrorBoundary(FirstFoldCarousel, 'FirstFoldCarousel')
)
