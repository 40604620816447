import React, {useCallback} from 'react'
import {DesktopCategoryPaletteWarpper} from './DesktopCategoryPalette.styles'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {DesktopCategoryPaletteCard} from './DesktopCategoryPaletteCard'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'
import {useGenericActions} from '../../hooks'

function DesktopCategoryPalette(props: BaseWidget) {
  const {type, widgetData, header, layout, id} = props || {}
  const {items = []} = widgetData || {}

  const {genericAction} = useGenericActions()

  const handleAction = useCallback(
    (action: GenericAction) => {
      genericAction(interceptAndEnhanceAction(action, id))
    },
    [genericAction, id]
  )

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <DesktopCategoryPaletteWarpper>
        {items.map((data: any, index: number) => (
          <DesktopCategoryPaletteCard
            cardData={data}
            handleAction={handleAction}
            key={index}
          />
        ))}
      </DesktopCategoryPaletteWarpper>
    </BaseWidgetComponent>
  )
}

export default DesktopCategoryPalette
