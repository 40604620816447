/**
 * Taken inspiration from https://developer.mozilla.org/en-US/docs/web/api/document/cookie
 */
const DOMAIN_KEY = process.env.NEXT_PUBLIC_BRAND || 'MW'

const getCookieKey = (name: string, skipDomain = false, skipPrefix = false) => {
  if (skipPrefix) return name
  if (skipDomain) {
    return `mw_${name}`
  }
  return `${DOMAIN_KEY}_${name}`
}

export const docCookies = {
  getItem: function ({
    cookieName,
    skipDomain = false,
    skipPrefix = false,
  }: {
    cookieName: string
    skipDomain: boolean
    skipPrefix?: boolean
  }): string | null {
    try {
      return (
        decodeURIComponent(
          document.cookie.replace(
            new RegExp(
              '(?:(?:^|.*;)\\s*' +
                encodeURIComponent(
                  getCookieKey(cookieName, skipDomain, skipPrefix)
                ).replace(/[\-\.\+\*]/g, '\\$&') +
                '\\s*\\=\\s*([^;]*).*$)|^.*$'
            ),
            '$1'
          )
        ) || null
      )
    } catch (error) {
      // TODO: Log Error
    }

    return null
  },
  setItem: function ({
    cookieName,
    cookieValue,
    expiry,
    cookiePath,
    cookieDomain,
    isSecure,
    skipDomain = false,
    skipPrefix = false,
  }: {
    cookieName: string
    cookieValue: string | number
    expiry?: number | string | Date
    cookiePath?: string
    cookieDomain?: string
    isSecure?: boolean
    skipDomain: boolean
    skipPrefix?: boolean
  }): void {
    try {
      if (
        !cookieName ||
        /^(?:expires|max\-age|path|domain|secure)$/i.test(cookieName)
      ) {
        return
      }
      const cookieKey = getCookieKey(cookieName, skipDomain, skipPrefix)

      let sExpires = ''
      if (expiry) {
        switch (expiry.constructor) {
          case Number:
            sExpires =
              expiry === Infinity
                ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                : '; max-age=' + expiry
            break
          case String:
            sExpires = '; expires=' + expiry
            break
          case Date:
            sExpires = '; expires=' + (expiry as Date).toUTCString()
            break
        }
      }
      document.cookie =
        encodeURIComponent(cookieKey) +
        '=' +
        encodeURIComponent(cookieValue) +
        sExpires +
        (cookieDomain ? '; domain=' + cookieDomain : '') +
        (cookiePath ? '; path=' + cookiePath : '') +
        (isSecure ? '; secure' : '')
    } catch (error) {
      // TODO: log error setting a cookie
    }
  },
  removeItem: function ({
    cookieName,
    cookiePath,
    cookieDomain,
    skipDomain = false,
    skipPrefix = false,
  }: {
    cookieName: string
    cookiePath?: string
    cookieDomain?: string
    skipDomain: boolean
    skipPrefix?: boolean
  }): void {
    try {
      if (!cookieName || !this.hasItem(cookieName)) {
        return
      }
      const cookieKey = getCookieKey(cookieName, skipDomain, skipPrefix)
      document.cookie =
        encodeURIComponent(cookieKey) +
        '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
        (cookieDomain ? '; domain=' + cookieDomain : '') +
        (cookiePath ? '; path=' + cookiePath : '')
    } catch (error) {
      // TODO: log error removing a cookie
    }
  },
  hasItem: function (cookieName: string): boolean {
    try {
      const cookieKey = getCookieKey(cookieName)
      return new RegExp(
        '(?:^|;\\s*)' +
          encodeURIComponent(cookieKey).replace(/[\-\.\+\*]/g, '\\$&') +
          '\\s*\\='
      ).test(document.cookie)
    } catch (error) {
      // TODO: log error checking for a cookie
    }

    return false
  },
}
