import styled from 'styled-components'

export const NextButtonContainer = styled.div`
  width: 100%;
  padding: 6px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--brand-primary-white-color);
  box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .next-button {
    border: 0;
    outline: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--brand-font-family-text);
    font-size: 16px;
    color: var(--brand-primary-white-color);
    background-color: var(--brand-primary-color);
    line-height: 1;
    padding: 16px 0px;
    border-radius: 16px;
    z-index: 1;

    :disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 12px 20px;

    .next-button {
      font-size: 20px;
      padding: 20px 0;
      border-radius: 20px;
    }
  }
`
