import {Typography, TypographyVariants} from '@web-components'
import {PillsSectionContainer} from './PillsSection.styles'
import Pill from './Pill/Pill'
import noop from 'lodash/noop'
import {PillsSectionProps} from './PillsSection.types'
import {Pill as PillType} from '../SearchInputWithRecommendation.types'

const PillsSection = (props: PillsSectionProps) => {
  const {title = '', pills = [], handlePillClick = noop} = props
  return (
    <PillsSectionContainer>
      <Typography
        customClassName="titleText"
        variant={TypographyVariants.HEADING_SMALL_BOLD}
      >
        {title}
      </Typography>
      <div className="pills-wrapper">
        {pills.map((pill: PillType, index: number) => {
          return (
            <Pill
              key={`${index}-${pill?.value}`}
              {...{pill, handlePillClick}}
            />
          )
        })}
      </div>

      <div className="divider"></div>
    </PillsSectionContainer>
  )
}

export default PillsSection
