
import { HttpClient } from '@mosaic-wellness/redux-action-library';
import {AxiosRequestConfig} from 'axios';
import {useMemo} from 'react';
import {QueryKey, useInfiniteQuery, useMutation, useQuery} from 'react-query';

interface UseFetch extends AxiosRequestConfig {
  queryKey: QueryKey;
  url: string;
  disabled?: boolean;
  cacheTime?: number;
}

export const useFetch = <T>({
  queryKey,
  url,
  disabled = false,
  cacheTime = 5 * 60 * 1000,
  ...rest
}: UseFetch) => {
  const axiosInstance = useMemo(() => {
    const axios = HttpClient.getAxiosInstance();
    return axios;
  }, []);

  const query = useQuery<T>({
    queryKey: queryKey,
    queryFn: () => {
      return axiosInstance.get(url, {...rest});
    },
    enabled: !disabled,
    cacheTime,
  });

  return query;
};

interface ApiResponse {
  data: {
    pages: number;
  };
}

interface UsePaginatedFetch extends AxiosRequestConfig {
  queryKey: string | string[];
  url: string;
  disabled?: boolean;
  // Include any other parameters you need
}

export const usePaginatedFetch = <T extends ApiResponse>({
  queryKey,
  url,
  disabled = false,
  ...rest
}: UsePaginatedFetch) => {
  const axiosInstance = useMemo(() => {
    const axios = HttpClient.getAxiosInstance();
    return axios;
  }, []);

  const query = useInfiniteQuery<T, Error>(
    queryKey,
    ({pageParam = 1}) => {
      const newParams = {...rest, params: {...rest.params, page: pageParam}};

      return axiosInstance.get(`${url}`, {
        ...newParams,
      });
    },
    {
      enabled: !disabled,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return nextPage <= lastPage.data.pages ? nextPage : false;
      },
    },
  );

  return query;
};

interface UsePost extends AxiosRequestConfig {
  url: string;
}

export const usePost = ({url, ...config}: UsePost) => {
  const axiosInstance = useMemo(() => {
    const axios = HttpClient.getAxiosInstance();
    return axios;
  }, []);

  return useMutation({
    mutationFn: (input: AxiosRequestConfig) => {
      return axiosInstance.post(url, {
        ...config,
        ...input,
      });
    },
  });
};
