import styled from 'styled-components'

export const InputSectionWrapper = styled.div`
  font-family: var(--brand-font-family-text);
  margin-top: 24px;

  .input-label {
    font-weight: var(--brand-font-weight-bold);
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.380208px;
    color: var(--category-primary-color);
    display: block;
    margin-left: 8px;
  }

  .input-box {
    margin-top: 10px;
    font-weight: var(--brand-font-weight-normal);
    font-size: 16px;
    line-height: 100%;
    padding: 10px 16px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:focus-visible {
      outline-color: var(--category-primary-color);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  textarea.input-box {
    resize: none;
    font-family: var(--brand-font-family-text);
  }

  .error-field {
    font-size: 12px;
    line-height: 14px;
    font-weight: var(--brand-font-weight-normal);
    color: #e86669;
    margin-left: 8px;
    margin-top: 2px;
  }

  .input-box-error {
    border: 1px solid var(--brand-primary-red-color);
  }

  .input-box::placeholder {
    color: var(--brand-primary-grey-color);
    opacity: 0.5;
    font-weight: 300;
  }

  /* .input-section {
    margin-bottom: 1.25rem;
  } */
`
