import styled from 'styled-components'

export const StyledImageWithDescriptionSlider = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }
  .arrow-right-container {
    path {
      stroke: var(--dls-brand-primary-color-500) !important;
    }
  }
  .arrow-left-container {
    path {
      stroke: var(--dls-brand-primary-color-500) !important;
    }
  }
`
