import React, {useCallback} from 'react'
import {BannerContainer} from './Banner.styles'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {useGenericActions} from '../../hooks'
import {LottiePlayer, OptimizedImage} from '@web-components'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

export interface BannerWidgetProps {
  imageUrl: string
  altText: string
  aspectRatio: number
  action: GenericAction
  lottieUrl: string
  desktopWidth?: string
}

const Banner = ({
  widgetData,
  layout,
  header,
  type,
  id = '',
  ...rest
}: BaseWidgetProps<BannerWidgetProps>) => {
  const {
    imageUrl = '',
    altText = '',
    desktopWidth = '100%',
    aspectRatio = 1,
    action,
    lottieUrl = '',
  } = widgetData ?? {}

  const {genericAction} = useGenericActions()
  const targetLink = (action?.params?.path as string) || '#'
  const handleBannerClick: React.MouseEventHandler<HTMLAnchorElement> =
    useCallback(
      (event) => {
        event.preventDefault()
        if (action) {
          genericAction(interceptAndEnhanceAction(action, id))
        }
      },
      [action, genericAction, id]
    )

  if (!imageUrl && !lottieUrl) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <BannerContainer
        href={targetLink}
        onClick={handleBannerClick}
        desktopWidth={desktopWidth}
      >
        {lottieUrl ? (
          <LottiePlayer
            src={lottieUrl}
            style={{height: 'auto', width: '100%'}}
          />
        ) : (
          <OptimizedImage
            source={imageUrl}
            aspectWidth={360}
            aspectHeight={360 / aspectRatio}
            desktopViewWidth="FULL"
            mobileViewWidth="FULL"
            altText={altText}
            loading="lazy"
            customClassName="banner-image"
          />
        )}
      </BannerContainer>
    </BaseWidgetComponent>
  )
}

export default Banner
