import {Router} from 'next/router'
import {useEffect} from 'react'
import {isBrandLJ} from 'src/utils/isBrandLJ'
const BRAND = process.env.NEXT_PUBLIC_BRAND || 'lj'

/**
 *
 * ! start of experimental code
 */
const namespace = 'rcl'
export const useInterceptNextDataHref = ({router}: {router: Router}) => {
  useEffect(() => {
    if (!isBrandLJ && router.pageLoader?.getDataHref) {
      const originalGetDataHref = router.pageLoader.getDataHref
      router.pageLoader.getDataHref = function (...args: any[]) {
        const r = originalGetDataHref.call(router.pageLoader, ...args)
        return r && r.startsWith('/_next/data') ? `/${namespace}${r}` : r
      }
    }
  }, [router])
}
/**
 *
 * ! enb of experimental code
 */
