import styled from 'styled-components'

export const InfoCardContainer = styled.div`
  height: 50px;
  width: 100%;
  border-radius: 10px;
  border: 1.5px solid var(--category-primary-bg1-color);
  display: flex;
  margin: 8px 0;
  color: var(--category-primary-color);
  align-items: center;

  .info-card__text {
    margin-left: 8px;
    font-size: 14px;
    align-self: flex-end;
  }

  .info-card__ctaNumber {
    font-size: 24px;
    font-weight: var(--brand-font-weight-semiBold);
  }

  .info-card__image {
    height: 20px;
    width: 20px;
  }

  .info-card__imageContainer {
    display: grid;
    place-content: center;
    height: 100%;

    width: 55px;
    border-radius: 10px 18px 18px 10px;
    background-color: var(--category-primary-bg1-color);
  }

  .info-card__textContainer {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 30px;
    border: 1px solid transparent;
  }

  @media (min-width: 768px) {
    height: 70px;
    border-radius: 20px;

    .info-card__imageContainer {
      width: 70px;
      border-radius: 20px;
    }

    .info-card__image {
      height: 32px;
      width: 32px;
    }

    .info-card__ctaNumber {
      font-size: 32px;
    }

    .info-card__text {
      margin-left: 12px;
      font-size: 20px;
    }

    .info-card__textContainer {
      display: flex;
      align-items: center;
      margin-left: 30px;
    }
  }
`
