import styled, {createGlobalStyle} from 'styled-components'

export const GlobalStampedModalStyles = createGlobalStyle`
 .stamped-ugc-modal-content {
  font-family: var(--brand-font-family-text);
}

.stamped-ugc-modal-wrapper {
  height: 75vh !important;
  border-radius: 20px;

  ::after {
    border-radius: 20px !important;
  }

  @media only screen and (max-width: 790px), only screen and (max-device-width: 790px) {
    height: 100% !important
  }
}

.stamped-ugc-modal-user-username {
  font-size: 15px;
}

.stamped-ugc-modal-caption-title {
  font-size: 14px;
}

.stamped-ugc-modal-caption-body {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .stamped-ugc-modal-user-username {
    font-size: 24px;
    }

  .stamped-ugc-modal-caption-title {
    font-size: 18px;
  }

  .stamped-ugc-modal-caption-body {
    font-size: 16px;
  }
}

.stamped-ugc-modal-votes {
  display: none;
}

.stamped-modal-close {
  width: 24px !important;
  height: 24px !important;
} 

.stamped-fa-angle-right, .stamped-fa-angle-left {
    color: var(--brand-primary-white-color) !important
  }

  .stamped-ugc-modal-image {
    border-radius: 20px;
    overflow: hidden;
  }
`

export const StyledReviewImagesWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0 16px;

  #stamped-reviews-widget.carousel {
    margin: 20px 0;
  }

  .stamped-visual-gallery-media-block {
    padding: 4px !important;
    height: 120px !important;
    width: 120px !important;
    border-radius: 20px !important;

    @media screen and (min-width: 768px) {
      height: 150px !important;
      width: 150px !important;
    }
  }

  .stamped-visual-gallery-feed,
  .stamped-reviews-widget {
    height: 120px !important;

    @media screen and (min-width: 768px) {
      height: 150px !important;
    }
  }

  .stamped-visual-gallery-media {
    border-radius: 20px;
  }
`
