import {
  StyledRxPreviewWidget,
  StyledTreatmentPlan,
  StyledProductsList,
} from './styles'
import {
  BaseWidgetProps,
  ExpandableSectionProps,
  GenericAction,
  ImageOrAnimationBannerProps,
  InfoBannerProps,
  PrescribedProductCardData,
  RxDiagnosisProps,
  RxDoctorDetailsProps,
  RxDoctorSignatureProps,
  RxHabitInstructionsProps,
  RxInfoListProps,
  RxPatientDetailsProps,
  RxSectionHeaderProps,
} from '@mosaic-wellness/fe-types'
import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {useCallback} from 'react'
import {
  RxPreviewWidgetItem,
  RxPreviewWidgetProps,
  RxTreatmentPlanItem,
} from './types'
import {
  ConsultPendingDuesOverlay,
  ExpandableSection,
  ImageOrAnimationBanner,
  InfoBanner,
  PrescribedProductCard,
  RxDiagnosis,
  RxDoctorDetails,
  RxDoctorSignature,
  RxHabitInstructions,
  RxInfoList,
  RxPatientDetails,
  RxSectionHeader,
} from '@web-components'
import {useGenericActions} from '../../hooks'

const RxPreview = ({
  widgetData,
  layout,
  header,
  type,
}: BaseWidgetProps<RxPreviewWidgetProps>) => {
  const {
    items = [],
    isPaymentPending = false,
    paymentPendingDetails,
  } = widgetData
  const {genericAction} = useGenericActions()

  const handleProductClick = useCallback(
    (action: GenericAction) => () => {
      if (action) {
        genericAction(action)
      }
    },
    [genericAction]
  )

  const handleDuesCtaClick = useCallback(() => {
    const {action, actionData} = paymentPendingDetails?.cta || {}
    if (action) {
      genericAction({actionName: action, params: actionData})
    }
  }, [genericAction, paymentPendingDetails?.cta])

  const onPlanSectionToggle = useCallback(
    (data: any) => {
      genericAction({
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'Consult Section Clicked - SO',
          eventData: data,
        },
      })
    },
    [genericAction]
  )

  const renderTreatmentPlanItems = useCallback(
    (item: RxTreatmentPlanItem, index: number) => {
      const {type, itemData} = item
      const key = 'rx-preview-treatment-plan-section-' + index

      if (type === 'DIAGNOSIS') {
        return <RxDiagnosis {...(itemData as RxDiagnosisProps)} key={key} />
      } else if (type === 'DOCTOR_SIGNATURE') {
        return (
          <RxDoctorSignature
            {...(itemData as RxDoctorSignatureProps)}
            key={key}
          />
        )
      } else if (type === 'INFO_BANNER') {
        return <InfoBanner {...(itemData as InfoBannerProps)} key={key} />
      } else if (type === 'BANNER') {
        return (
          <div className="habit-banner">
            <ImageOrAnimationBanner
              key={key}
              {...(itemData as ImageOrAnimationBannerProps)}
            />
          </div>
        )
      } else if (type === 'HABIT_DETAILS') {
        return (
          <div className="habit-details" key={key}>
            <RxHabitInstructions {...(itemData as RxHabitInstructionsProps)} />
          </div>
        )
      } else if (type === 'INFO_SECTION_CARD') {
        return (
          <ExpandableSection
            {...(itemData as ExpandableSectionProps)}
            key={key}
            children={
              <RxInfoList
                infoItems={(itemData as RxInfoListProps)?.infoItems || []}
              />
            }
            onListToggle={onPlanSectionToggle}
          />
        )
      } else if (type === 'PRESCRIBED_PRODUCTS') {
        const {products = []} =
          (itemData as {products: PrescribedProductCardData[]}) || {}
        return (
          <ExpandableSection
            {...(itemData as ExpandableSectionProps)}
            key={key}
            onListToggle={onPlanSectionToggle}
            children={
              <StyledProductsList>
                {products.map(
                  (
                    product: PrescribedProductCardData,
                    productIndex: number
                  ) => (
                    <PrescribedProductCard
                      {...product}
                      key={key + '-product-' + productIndex}
                      onClick={handleProductClick(product?.action)}
                    />
                  )
                )}
              </StyledProductsList>
            }
          />
        )
      }

      return null
    },
    [handleProductClick, onPlanSectionToggle]
  )

  const renderItems = useCallback(
    (item: RxPreviewWidgetItem, index: number) => {
      const {type, itemData} = item
      const key = 'rx-preview-section-' + index

      if (type === 'DOCTOR_DETAILS') {
        return (
          <RxDoctorDetails {...(itemData as RxDoctorDetailsProps)} key={key} />
        )
      } else if (type === 'HEADING') {
        return (
          <RxSectionHeader {...(itemData as RxSectionHeaderProps)} key={key} />
        )
      } else if (type === 'PATIENT_DETAILS') {
        return (
          <RxPatientDetails
            {...(itemData as RxPatientDetailsProps)}
            key={key}
          />
        )
      } else if (type === 'TREATMENT_PLAN') {
        return (
          <StyledTreatmentPlan key={key}>
            {(itemData as {items: RxTreatmentPlanItem[]})?.items?.map(
              renderTreatmentPlanItems
            )}
          </StyledTreatmentPlan>
        )
      } else if (type === 'BANNER') {
        return (
          <ImageOrAnimationBanner
            key={key}
            {...(itemData as ImageOrAnimationBannerProps)}
          />
        )
      }

      return null
    },
    [renderTreatmentPlanItems]
  )

  if (!items || isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type}>
      <BaseWidgetComponent.Header header={header} />
      <StyledRxPreviewWidget isPaymentPending={isPaymentPending}>
        {items.map(renderItems)}
        {isPaymentPending && (
          <ConsultPendingDuesOverlay
            onCtaClick={handleDuesCtaClick}
            {...paymentPendingDetails}
          />
        )}
      </StyledRxPreviewWidget>
    </BaseWidgetComponent>
  )
}

export default RxPreview
