import React, {useCallback} from 'react'
import {CtaButtonContainer} from './CtaButton.styles'
import {ICtaButtonProps} from './CtaButton.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import isEmpty from 'lodash/isEmpty'
import {Typography, TypographyVariants} from '@web-components'
import {useGenericActions} from '../../hooks'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function CtaButton(props: ICtaButtonProps) {
  const {id, type, widgetData, layout} = props
  const {cta, label = '', isSticky = false} = widgetData || {}
  const {genericAction} = useGenericActions()

  const handleOnClick = useCallback(() => {
    genericAction(interceptAndEnhanceAction(cta, id))
  }, [cta, genericAction, id])

  if (isEmpty(cta) || !label) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetId={id} widgetType={type}>
      <CtaButtonContainer data-issticky={isSticky}>
        <button className="cta-button" onClick={handleOnClick}>
          <Typography variant={TypographyVariants.CTA_LABEL_BIG}>
            {label}
          </Typography>
        </button>
      </CtaButtonContainer>
    </BaseWidgetComponent>
  )
}

export default CtaButton
