import {useCallback} from 'react'
export const useIsAppointmentScheduled = () => {
  const setIsAppointmentScheduled = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(
        'isAppointmentScheduled',
        JSON.stringify(true)
      )
    }
  }, [])

  const getIsAppointmentScheduled = useCallback(() => {
    if (typeof window !== 'undefined') {
      return JSON.parse(
        window.localStorage.getItem('isAppointmentScheduled') || 'false'
      )
    }
    return false
  }, [])

  const removeIsAppointmentScheduled = useCallback(() => {
    if (typeof window !== 'undefined')
      window.localStorage.removeItem('isAppointmentScheduled')
  }, [])

  return {
    setIsAppointmentScheduled,
    getIsAppointmentScheduled,
    removeIsAppointmentScheduled,
  }
}
