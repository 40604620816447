const orderTypeMap: {[key: string]: string} = {
  OTC: 'OTC',
  OPT_OTC: 'Pseudo Rx',
  RX: 'RX',
}

export const processThankYouChatQuestion = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {question = '', items = [], questionType = '', type = ''} = data

  return {
    Question: question,
    'Question type': questionType,
    'Chat product': items.map((item: any) => item?.name).join(','),
    'Order Type': orderTypeMap[type],
  }
}
