import {useCallback, useEffect} from 'react'
import {Nudge} from 'nudge_core_browser_v2'
import {getExternalNudgeToken} from 'src/analytics/externalNudge/utils'
import {recordToExternalNudge} from 'src/analytics/externalNudge'

const NUDGE_API_KEY = process.env.NEXT_PUBLIC_EXTERNAL_NUDGE_API_KEY

export const useExternalNudge = () => {
  const handleNudgeInitialization = useCallback(async () => {
    if (NUDGE_API_KEY) {
      try {
        const nudge = new Nudge({apiKey: NUDGE_API_KEY})
        // @ts-ignore
        if (window.__MWSC__) {
          // @ts-ignore
          window.__MWSC__.externalNudge = nudge

          await nudge.userIdentifier({
            properties: {},
          })
          recordToExternalNudge('initiated', {type: 'user'})
        }
      } catch (error) {
        // TODO: log error
      }
    }
  }, [])

  useEffect(() => {
    handleNudgeInitialization()
  }, [handleNudgeInitialization])
}
