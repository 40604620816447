import {
  MinusIcon,
  OptimizedImage,
  PlusIcon,
  Spinner,
  Typography,
  TypographyVariants,
} from '@web-components'
import {ProductListingCardContainer} from './ProductListingCard.styles'
import OutOfStockBanner from '../../CategoryProductCardGrid/CategoryProductCard/OutOfStockBanner'
import {ProductListingCardProps} from './ProductListingCard.types'
import useProductListingCard from './useProductListingCard'

const ProductListingCard = (props: ProductListingCardProps) => {
  const {product} = props
  const {
    alttext = '',
    discountPriceLabel = '',
    name = '',
    priceLabel = '',
    ctaLabel = 'Add',
    For = '',
    image = '',
    slugUrl = '',
    outOfStock = false,
  } = product || {}

  const [
    {showLoader, showDiscountedPrice, showQuantity, quantity},
    {onCardClick, onCtaClick, onIncreaseQuantity, onReduceQuantity},
  ] = useProductListingCard(props)

  if (!product) return null

  return (
    <ProductListingCardContainer
      outOfStock={outOfStock}
      href={slugUrl}
      onClick={onCardClick}
    >
      <div className="img-container">
        <OutOfStockBanner outOfStock={outOfStock} top={30} />
        <OptimizedImage
          source={image}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          altText={alttext || name}
          customClassName="product-image"
        />
      </div>
      <div className="content-container">
        <div className="content-wrapper">
          <Typography
            customClassName="product-for-text"
            variant={TypographyVariants.X_SMALL}
          >
            {For}
          </Typography>
          <div className="product-name-container">
            <Typography variant={TypographyVariants.TAG_BOLD}>
              {name}
            </Typography>
          </div>
        </div>

        <div className="pricing-and-cta-container">
          <div className="pricing-container">
            {showDiscountedPrice ? (
              <>
                <Typography variant={TypographyVariants.TAG_BOLD}>
                  {discountPriceLabel}
                </Typography>
                <Typography
                  customClassName="discounted-price-text"
                  variant={TypographyVariants.X_SMALL}
                >
                  {priceLabel}
                </Typography>
              </>
            ) : (
              <Typography variant={TypographyVariants.TAG_BOLD}>
                {priceLabel}
              </Typography>
            )}
          </div>

          <div>
            {showQuantity ? (
              <div className="qty-button-container">
                <button
                  onClick={onReduceQuantity}
                  className="qty-btn remove-btn"
                  data-isdisabled={showLoader}
                  disabled={showLoader}
                >
                  <MinusIcon
                    height={20}
                    width={20}
                    fill={'var(--dls-brand-primary-color-500)'}
                  />
                </button>
                <div className="qty-label-container">
                  {showLoader ? (
                    <Spinner
                      color={'var(--dls-brand-primary-color-500)'}
                      size={'20'}
                    />
                  ) : (
                    <Typography
                      variant={TypographyVariants.CTA_LABEL_SMALL}
                      customClassName="qty-label"
                    >
                      {quantity}
                    </Typography>
                  )}
                </div>
                <button
                  onClick={onIncreaseQuantity}
                  className="qty-btn add-btn"
                  data-isdisabled={showLoader}
                  disabled={showLoader}
                >
                  <PlusIcon
                    height={20}
                    width={20}
                    fill={'var(--dls-brand-primary-color-500)'}
                  />
                </button>
              </div>
            ) : (
              <button
                className="atc-btn"
                data-location="product-card"
                onClick={onCtaClick}
              >
                {showLoader ? (
                  <Spinner
                    color={'var(--dls-brand-primary-color-500)'}
                    size={'20'}
                  />
                ) : (
                  <Typography
                    variant={TypographyVariants.CTA_LABEL_SMALL}
                    customClassName="atc-label"
                  >
                    {ctaLabel}
                  </Typography>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </ProductListingCardContainer>
  )
}

export default ProductListingCard
