import {create} from 'zustand'

type SearchInputStore = {
  searchQuery: string
  setSearchQuery: (newSearchTerm: string) => void
}

export const useSearchInputStore = create<SearchInputStore>()((set) => ({
  searchQuery: '',
  setSearchQuery: (newSearchParam) => set({searchQuery: newSearchParam}),
}))
