import {isEmpty} from 'lodash'
import {useEffect, useMemo} from 'react'
import MiniProductPageBottomSheet from 'src/components/shared/src/Screens/PDP/MiniProductPageBottomSheet'
import {useGenericCtaV2} from 'src/hooks'
import {useMiniProductPageBottomSheetStore} from 'src/hooks/product/useMiniProductPageBottomSheet.store'

export default function MiniProductPageBottomSheetContainer() {
  const {isVisible, data, closeMiniProductPageBottomSheet, ctaConfig} =
    useMiniProductPageBottomSheetStore((store) => store)
  const {urlKey = '', source = '', name = ''} = data || {}
  const {handleCtaV2} = useGenericCtaV2()

  const modifiedCtaConfig = useMemo(() => {
    if (!isEmpty(ctaConfig?.clickAction) && ctaConfig?.isGenericAction) {
      return {
        ...ctaConfig,
        onClick: () => {
          handleCtaV2(ctaConfig?.clickAction || {})
          closeMiniProductPageBottomSheet()
        },
      }
    }
    return ctaConfig
  }, [closeMiniProductPageBottomSheet, ctaConfig, handleCtaV2])

  useEffect(() => {
    return closeMiniProductPageBottomSheet
  }, [])

  if (!isVisible && !urlKey) return null

  return (
    <MiniProductPageBottomSheet
      state={{
        urlKey,
        name,
        source,
        showModal: isVisible,
        cta: modifiedCtaConfig,
      }}
      actions={{handleCloseModal: closeMiniProductPageBottomSheet}}
    />
  )
}
