import {Search, Typography, TypographyVariants} from '@web-components'
import {PillContainer} from './Pill.styles'
import noop from 'lodash/noop'
import {PillProps} from './Pill.types'
import {useCallback} from 'react'

const Pill = (props: PillProps) => {
  const {pill, handlePillClick = noop} = props
  const {value = ''} = pill || {}

  const pillClick = useCallback(() => {
    handlePillClick(pill)
  }, [handlePillClick, pill])
  return (
    <PillContainer onClick={pillClick}>
      <div className="icon-container">
        <Search
          width={16}
          height={16}
          stroke="var(--dls-secondary-text-colors)"
        />
      </div>

      <Typography
        customClassName="pill-value-text"
        variant={TypographyVariants.TAG_REGULAR}
      >
        {value}
      </Typography>
    </PillContainer>
  )
}

export default Pill
