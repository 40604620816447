import React from 'react'

import {WebViewHeader} from 'src/components/shared/src/ComponentsV2/common'
import {useWebViewHeader} from 'src/hooks/useWebViewHeader/useWebViewHeader'

interface IWebViewHeaderWrapperProps {
  config: Record<string, any>
}

function WebViewHeaderWrapper(props: IWebViewHeaderWrapperProps) {
  const {config} = props

  const {
    showHeader: {showHeader, title},
    onBackClick,
  } = useWebViewHeader({...config})

  return (
    <WebViewHeader
      state={{isHeaderVisible: showHeader, title}}
      actions={{
        onBackClick,
      }}
    />
  )
}

export default WebViewHeaderWrapper
