import {GenericAction} from '@mosaic-wellness/fe-types'
import React from 'react'
import {CoreContext, CoreContextProviderProps} from './types'

const DEFAULT_CORE_CONTEXT: CoreContext = {
  genericAction: async (action: GenericAction) => {
    // TODO: Implement generic action
  },
  handleGenericActions: async (actions: GenericAction[]) => {
    // TODO: Implement generic action fallback
  },
  parentAppBaseLink: '',
}

const CORE_CONTEXT = React.createContext<CoreContext>(DEFAULT_CORE_CONTEXT)

const CoreContextProvider = ({
  children,
  genericAction,
  parentAppBaseLink,
  handleGenericActions,
  user,
}: React.PropsWithChildren<CoreContextProviderProps>) => {
  const contextValue = React.useMemo(() => {
    return {
      user,
      genericAction,
      handleGenericActions,
      parentAppBaseLink,
    }
  }, [genericAction, handleGenericActions, parentAppBaseLink, user])

  return (
    <CORE_CONTEXT.Provider value={contextValue}>
      {children}
    </CORE_CONTEXT.Provider>
  )
}

const useCoreContext = () => {
  const context = React.useContext(CORE_CONTEXT)

  if (!context) {
    throw new Error('coreContext has not been initialized with a provider')
  }

  return {
    genericAction: context.genericAction,
    parentAppBaseLink: context.parentAppBaseLink,
    handleGenericActions: context.handleGenericActions,
    user: context.user,
  } as const
}

export {CORE_CONTEXT, CoreContextProvider, useCoreContext}
