import {useState, useCallback, useMemo, useRef, useEffect} from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  useAuth,
  useCartStore,
  useCheckoutConfig,
  useConfigStore,
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import {useCheckoutCartPage} from '../checkoutV2/useCheckoutCart'
import {useFetchProduct} from '../product/useFetchProduct'
import {useGenericCta} from '../useGenericCta'
import {useExplicitATCTracking} from '../tracking/useExplicitATCTracking'
import {useGetAvailableCoupons} from '../checkout/getAvailableCoupons'
import {IUseSaCartProps} from './selfAssessment.interface'
import useSATracking from '../tracking/useSATracking'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useUpdateItemToCart} from '../updateCart/useUpdateItemToCart'
import sessionStorageMethods from 'src/utils/sessionStorage/sessionStorageMethods'
import {getProductsForATC} from 'src/utils/getProductsForMultiAddATC'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useSaCart(props: IUseSaCartProps = {}) {
  /* Custom Hooks */
  const {cart} = useCartStore()
  const {addToCart, removeFromCart, updateItemToCart} = useUpdateItemToCart()
  const {
    cartLoading: {
      isCartApplyDiscount,
      isCartUpdating,
      isCartHydration,
      cartInitialHydrationDone,
    },
  } = useLoadingStore()
  const isApp = checkIsApp()

  const CHECKOUT_CONFIG = useCheckoutConfig()
  const {
    state: {productData, isLoading: isProductLoading},
    actions: {fetchPdpData},
  } = useFetchProduct()
  const {handleCta} = useGenericCta()
  const {trackAddToCart} = useExplicitATCTracking()
  const [checkoutCartState, checkoutCartActions] = useCheckoutCartPage({
    callCheckPrescription: true,
    source: 'Form Cart',
  })
  const {
    user: {isLoggedIn},
  } = useUserStore()
  const {
    actions: {setShowLoginModal},
  } = useAuth()
  const {couponDataRes = {}, getData} = useGetAvailableCoupons()
  const {
    trackProductInfoViewed,
    trackPdpViewedFromRxCart,
    trackCheckoutClicked,
    onSelfAssessmentPlanViewed,
  } = useSATracking()
  const {
    environment: {toast},
  } = useConfigStore()

  /* Refs */
  const multiProductAtcDone = useRef(false)
  const checkoutIntentShownByUser = useRef(false)

  /* States */
  const [currentProductInPreview, setCurrentProductInPreview] = useState(null)
  const [removedProducts, setRemovedProducts] = useState<Array<any>>([])
  const [showProductPreviewModal, setShowProductPreviewModal] = useState(false)

  const {formWebViewData = {}} = props || {}

  const {
    handleApplyDiscountOpen,
    onProductCardClick,
    handleRewardUnlocked,
    onCartUpdateDiscount,
    handleCouponDiscount,
    handleIncentiveButtonClicked,
    onAppointmentCardClick,
    onAppointmentCardShown,
    toggleScheduleFormOpen,
    onPseudoRxCancelClick,
    onSaveChangesClicked,
    onLanguageChangeForCheckPrescription,
  } = checkoutCartActions

  const {
    cartState,
    bookSlotsData,
    checkPrescriptionData,
    showCartAppointmentCardLoader,
    isScheduleFormOpen,
    prescriptionData,
    isLoadingCheckPrescriptionData,
    languageForCheckPrescription,
    isInitialCheckPrescriptionCallMade,
  } = checkoutCartState || {}
  const {
    totalAmount,
    savedAmount,
    recommended: recommendedProducts,
    products,
    displayTotalAmount,
  } = cartState || {}

  const {cartConfig = {}} = CHECKOUT_CONFIG || {}
  const {availableCoupons, offerText = ''} = cartConfig
  const {
    productInfo = {},
    prod_img = '',
    card_for_with = {
      For: '',
    },
    howToUse = {},
    concern = '',
  } = productData

  /*Memoized Variables */
  const isCartLoading = useMemo(() => {
    if (isCartHydration) {
      return true
    }

    if (isCartUpdating.status && !multiProductAtcDone.current) {
      multiProductAtcDone.current = true
      return true
    }

    return false
  }, [isCartUpdating?.status, isCartHydration])

  const {upSellData, showRecommendations} = useMemo(() => {
    let recommendationsData = {}
    const recommendationsExist =
      Array.isArray(recommendedProducts) && !isEmpty(recommendedProducts)
    if (cartConfig.productRecommendations) {
      recommendationsData = {...cartConfig.productRecommendations}
    }

    if (recommendedProducts) {
      recommendationsData.products = recommendedProducts
    }

    return {
      upSellData: recommendationsData,
      showRecommendations: recommendationsExist,
    }
  }, [cartConfig, recommendedProducts])

  const pdpData = useMemo(() => {
    return {
      ...productInfo,
      productImageUrl: prod_img,
      howToUse,
      productFor: card_for_with?.For,
      concern,
    }
  }, [productInfo, prod_img, card_for_with, concern, howToUse])

  /*Memoized Callbacks */
  const handleLogin = useCallback(() => {
    checkoutIntentShownByUser.current = true

    setShowLoginModal({
      isOpen: true,
      triggeredFrom: 'CHECKOUT_FLOW',
    })
  }, [setShowLoginModal])

  const addRemovedProducts = useCallback((data) => {
    setRemovedProducts((removedProducts) => [...removedProducts, data])
  }, [])

  const deleteRemovedProducts = useCallback((data) => {
    setRemovedProducts((removedProducts) =>
      removedProducts.filter((item) => item.id !== data.id)
    )
  }, [])

  const handleAddToCart = useCallback(
    (data) => {
      const {sku = ''} = data || {}
      trackAddToCart(data, window.location.href)
      addToCart({
        sku,
        isMiniCart: false,
      })
    },
    [addToCart, trackAddToCart]
  )

  const onCartUpdateProduct = useCallback(
    (id: string, sku: string, quantity: number) => {
      updateItemToCart({
        sku,
        quantity: quantity,
        isMiniCart: false,
      })
    },
    [updateItemToCart]
  )

  const onRemoveFromCart = useCallback(
    (id: string, sku: string, quantity: number) => {
      removeFromCart({
        sku,
        isMiniCart: false,
        quantity,
      })
    },
    [removeFromCart]
  )

  const trackApplyDiscountClick = useCallback(() => {}, [])

  const handlePlaceOrder = useCallback(() => {
    trackCheckoutClicked({products, totalAmount})

    if (!isLoggedIn) {
      handleLogin()
      return
    }

    if (isApp) {
      //@ts-ignore
      const finalProductsForATC = getProductsForATC(products)
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: formWebViewData?.action,
          actionData: {
            ...formWebViewData?.actionData,
            bookSlotsData,
            products: finalProductsForATC,
            isWebView: true,
          },
        })
      )
      return
    } else {
      const link = `/checkout-v2?step=address`
      handleCta({action: 'LINK', link})
    }
  }, [
    bookSlotsData,
    formWebViewData?.action,
    formWebViewData?.actionData,
    handleCta,
    handleLogin,
    isApp,
    isLoggedIn,
    products,
    totalAmount,
    trackCheckoutClicked,
  ])

  const onProductNameOrImageClick = useCallback(
    ({productURL, name}) => {
      trackProductInfoViewed({id: productURL, name})
      setShowProductPreviewModal(true)
      setCurrentProductInPreview(productURL)
    },
    [trackProductInfoViewed]
  )

  const onAllDetailsClick = useCallback(
    ({link, name}) => {
      trackPdpViewedFromRxCart({id: link, name})
      if (isApp) {
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            actions: 'OPEN_PDP',
            actionData: {
              isWebView: true,
              productKey: link,
            },
          })
        )
        return
      }

      handleCta({
        action: 'LINK',
        link: `/product/${link}`,
      })
    },
    [handleCta, isApp, trackPdpViewedFromRxCart]
  )

  const closeProductPreviewModal = useCallback(() => {
    setShowProductPreviewModal(false)
    setCurrentProductInPreview(null)
  }, [])

  const onWalletSubmit = useCallback(
    (
      walletSubmitData: {
        amount?: any
        bonus?: any
        source?: string
      } | null = null
    ) => {
      const submitDataValue = walletSubmitData
      const {amount, bonus, source = 'recharge'} = submitDataValue || {}
      analyticsTrigger(EVENT_MAP.WALLET_PAY_CLICK, {
        amount,
        bonus,
        source,
      })
      sessionStorageMethods.set({
        keyName: 'walletPaymentDetails',
        value: {amount, bonus},
      })
      handleCta({action: 'SPA_LINK', link: '/wallet-payment'})
    },
    [handleCta]
  )

  const onRecharge = useCallback(
    (data = null) => {
      sessionStorageMethods.set({keyName: 'isWalletCoupon', value: true})
      onWalletSubmit({...data, source: 'sa-cart'})
    },
    [onWalletSubmit]
  )

  useEffect(() => {
    if (checkoutIntentShownByUser.current && isLoggedIn) {
      if (cart?.rxStatus?.hasRx || cart?.rxStatus?.hasInstantRx) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        return
      }

      const link = `/checkout-v2?step=address`
      handleCta({action: 'LINK', link})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    if (
      cartInitialHydrationDone &&
      !isCartLoading &&
      window.customDataLayer?.cart &&
      (cart?.rxStatus?.hasRx ? isInitialCheckPrescriptionCallMade : true)
    ) {
      // added a set timeout as there is some time take for window to update with cart values
      setTimeout(
        () =>
          onSelfAssessmentPlanViewed({
            selectLanguage: checkPrescriptionData?.selectLanguage,
          }),
        1000
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartLoading, cartInitialHydrationDone])

  const formCartProps = useMemo(() => {
    return {
      saCartState: {
        cart,
        isCartApplyDiscount,
        CHECKOUT_CONFIG,
        isProductLoading,
        currentProductInPreview,
        removedProducts,
        showProductPreviewModal,
        totalAmount,
        savedAmount,
        availableCoupons,
        offerText,
        upSellData,
        showRecommendations,
        pdpData,
        couponDataRes,
        cartState,
        products,
        isCartLoading,
        displayTotalAmount,
        bookSlotsData,
        checkPrescriptionData,
        showCartAppointmentCardLoader,
        isScheduleFormOpen,
        prescriptionData,
        isLoadingCheckPrescriptionData,
        languageForCheckPrescription,
        isCartUpdating,
      },
      saCartActions: {
        addRemovedProducts,
        deleteRemovedProducts,
        handleAddToCart,
        trackApplyDiscountClick,
        handlePlaceOrder,
        onProductNameOrImageClick,
        onAllDetailsClick,
        closeProductPreviewModal,
        handleApplyDiscountOpen,
        onProductCardClick,
        onAddToCart: handleAddToCart,
        handleRewardUnlocked,
        fetchPdpData,
        updateCartProduct: onCartUpdateProduct,
        removeFromCart: onRemoveFromCart,
        getData,
        onCartUpdateDiscount,
        handleCouponDiscount,
        handleIncentiveButtonClicked,
        onRecharge,
        onAppointmentCardClick,
        onAppointmentCardShown,
        toggleScheduleFormOpen,
        onPseudoRxCancelClick,
        onSaveChangesClicked,
        onLanguageChangeForCheckPrescription,
      },
    } as const
  }, [
    cart,
    isCartApplyDiscount,
    CHECKOUT_CONFIG,
    isProductLoading,
    currentProductInPreview,
    removedProducts,
    showProductPreviewModal,
    totalAmount,
    savedAmount,
    availableCoupons,
    offerText,
    upSellData,
    showRecommendations,
    pdpData,
    couponDataRes,
    cartState,
    products,
    isCartLoading,
    displayTotalAmount,
    bookSlotsData,
    checkPrescriptionData,
    showCartAppointmentCardLoader,
    isScheduleFormOpen,
    prescriptionData,
    isLoadingCheckPrescriptionData,
    addRemovedProducts,
    deleteRemovedProducts,
    handleAddToCart,
    trackApplyDiscountClick,
    handlePlaceOrder,
    onProductNameOrImageClick,
    onAllDetailsClick,
    closeProductPreviewModal,
    handleApplyDiscountOpen,
    onProductCardClick,
    handleRewardUnlocked,
    fetchPdpData,
    onCartUpdateProduct,
    onRemoveFromCart,
    getData,
    onCartUpdateDiscount,
    handleCouponDiscount,
    handleIncentiveButtonClicked,
    onRecharge,
    onAppointmentCardClick,
    onAppointmentCardShown,
    toggleScheduleFormOpen,
    onPseudoRxCancelClick,
    onSaveChangesClicked,
    onLanguageChangeForCheckPrescription,
    languageForCheckPrescription,
    isCartUpdating,
  ])

  return formCartProps
}

export {useSaCart}
