import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {EmptySearchResultConfig} from '../../SearchInputWithRecommendation.types'
import {EmptySearchResultContainer} from './EmptySearchResult.styles'

const EmptySearchResult = (props: EmptySearchResultConfig) => {
  const {image, title = '', subTitle = ''} = props

  const {url = '', alttext = ''} = image || {}

  return (
    <EmptySearchResultContainer>
      <div className="img-container">
        <OptimizedImage
          source={url}
          altText={alttext}
          customClassName="product-image"
        />
      </div>
      <div className="content-container">
        <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
          {title}
        </Typography>
        <Typography
          customClassName="sub-title-text"
          variant={TypographyVariants.TAG_REGULAR}
        >
          <span dangerouslySetInnerHTML={{__html: subTitle}} />
        </Typography>
      </div>
    </EmptySearchResultContainer>
  )
}
export default EmptySearchResult
