import {ToastType} from '@mosaic-wellness/fe-types'
import {toast} from 'react-toastify'

const showToast = (type: ToastType, message: string) => {
  if (type === ToastType.ERROR) {
    return toast.error(message)
  } else if (type === ToastType.SUCCESS) {
    return toast.success(message)
  }

  return toast(message)
}

export {showToast}
