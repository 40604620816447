import styled from 'styled-components'

export const CtaButtonContainer = styled.div`
  padding: 0 var(--dls-size-32);
  width: 100%;

  .cta-button {
    outline: 0;
    border: 0;
    background-color: var(--dls-brand-primary-color-500);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 14px 0;
    color: var(--dls-white-color);
  }

  &[data-issticky='true'] {
    padding: var(--dls-size-16);
    max-width: 500px;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    z-index: 2;
  }
`
