import React, {useCallback, useMemo, useRef, useState} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {AppointmentCardWrapper} from './AppointmentCard.styles'
import {LoadingState} from '../../../../ComponentsV2/common'
import {EditIcon} from '../EditIcon'

const AppointmentCard = (props) => {
  const {
    title = '',
    subTitle = '',
    appointmentDateHeading = '',
    appointmentTimeHeading = '',
    appointmentIDHeading = '',
    categoryHeading = '',
    cancelButtonText = '',
    rescheduleButtonText = '',
    appointmentDate = '',
    appointmentTime = '',
    appointmentId = '',
    appointmentType = '',
    category = '',
    showAppointmentCardButtons = true,
    isAppointmentDataLoading = false,
    showCancelButton = false,
    isOnGoing = false,
    disableRescheduleButton = false,
  } = props.state || {}
  const {
    handleCancelClicked = noop,
    handleRescheduleClicked = noop,
    trackSuccessScreen = noop,
    trackConfirmationScreenV2 = noop,
  } = props.actions || {}

  const [editButtonClicked, setEditButtonClicked] = useState(false)

  const ctaButtonsRef = useRef()

  const showEditButton = useMemo(() => {
    return showAppointmentCardButtons && !isOnGoing
  }, [isOnGoing, showAppointmentCardButtons])

  const showCtaButtons = useMemo(() => {
    return editButtonClicked && showAppointmentCardButtons && !isOnGoing
  }, [editButtonClicked, showAppointmentCardButtons, isOnGoing])

  const handleEditButtonClicked = useCallback(
    (e) => {
      e?.stopPropagation()
      setEditButtonClicked((value) => !value)
      if (!editButtonClicked) {
        trackSuccessScreen({type: 'editAppointment'})
        trackConfirmationScreenV2({
          type: 'edit',
          data: {appointmentId, category, appointmentType},
        })
      }
    },
    [
      editButtonClicked,
      trackSuccessScreen,
      trackConfirmationScreenV2,
      appointmentId,
      category,
      appointmentType,
    ]
  )

  const handleRescheduleCtaClicked = useCallback(() => {
    handleEditButtonClicked()
    handleRescheduleClicked()
    trackSuccessScreen({type: 'reschedule'})
    trackConfirmationScreenV2({
      type: 'reschedule',
      data: {
        entry: document.referrer,
        appointmentId,
        category,
        appointmentType,
      },
    })
  }, [
    appointmentId,
    appointmentType,
    category,
    handleEditButtonClicked,
    handleRescheduleClicked,
    trackConfirmationScreenV2,
    trackSuccessScreen,
  ])

  const handleCancelCtaClicked = useCallback(() => {
    handleEditButtonClicked()
    handleCancelClicked()
    trackSuccessScreen({
      type: 'cancel',
      data: {appointmentId, category, appointmentType},
    })
    trackConfirmationScreenV2({
      type: 'cancel',
      data: {
        entry: document.referrer,
        appointmentId,
        category,
        appointmentType,
      },
    })
  }, [
    appointmentId,
    appointmentType,
    category,
    handleCancelClicked,
    handleEditButtonClicked,
    trackConfirmationScreenV2,
    trackSuccessScreen,
  ])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <AppointmentCardWrapper data-is-cancel-button-visible={!showCancelButton}>
      <div className="appointment-card__container">
        <div className="appointment-card__heading-container">
          <div className="appointment-card__heading">
            <div className="appointment-card__title">{title}</div>
            <div className="appointment-card__sub-title">{subTitle}</div>
          </div>
          {showEditButton && (
            <button
              className="edit-appointment-button"
              onClick={handleEditButtonClicked}
            >
              <EditIcon />
            </button>
          )}
        </div>
        <div className="appointment-card__line"></div>
        <div className="appointment-card__row">
          <div className="appointment-card__col">
            <div className="appointment-card__col-heading">
              {appointmentDateHeading}
            </div>
            {isAppointmentDataLoading ? (
              <LoadingState cardHeight={10} />
            ) : (
              <div className="appointment-card__col-data">
                {appointmentDate}
              </div>
            )}
          </div>
          <div className="appointment-card__col">
            <div className="appointment-card__col-heading">
              {appointmentTimeHeading}
            </div>

            {isAppointmentDataLoading ? (
              <LoadingState cardHeight={10} />
            ) : (
              <div className="appointment-card__col-data">
                {appointmentTime}
              </div>
            )}
          </div>
          <div className="appointment-card__col">
            <div className="appointment-card__col-heading">
              {appointmentIDHeading}
            </div>

            {isAppointmentDataLoading ? (
              <LoadingState cardHeight={10} />
            ) : (
              <div className="appointment-card__col-data appointment-id">
                {appointmentId}
              </div>
            )}
          </div>
          <div className="appointment-card__col">
            <div className="appointment-card__col-heading">
              {categoryHeading}
            </div>

            {isAppointmentDataLoading ? (
              <LoadingState cardHeight={10} />
            ) : (
              <div className="appointment-card__col-data">{category}</div>
            )}
          </div>
        </div>
        {showCtaButtons && (
          <div className="cta-buttons" ref={ctaButtonsRef}>
            <button
              className="cta-button"
              onClick={handleRescheduleCtaClicked}
              disabled={disableRescheduleButton}
            >
              {rescheduleButtonText}
            </button>
            {showCancelButton && (
              <>
                <div className="line"></div>
                <button className="cta-button" onClick={handleCancelCtaClicked}>
                  {cancelButtonText}
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {showCtaButtons && (
        <div className="overlay" onClick={handleEditButtonClicked}></div>
      )}
    </AppointmentCardWrapper>
  )
}
export default AppointmentCard
