import React, {useCallback} from 'react'
import noop from 'lodash/noop'
import {ChevronRight, OptimizedImage, TypographyVariants} from '@web-components'
import {Typography} from '@web-components'
import {MedicalTeamCardContainer} from './ImageWithDescriptionCard.styles'
import {MeetOurExpertsCardProps} from './ImageWithDescriptionCard.interface'

const ImageWithDescriptionCard = (props: MeetOurExpertsCardProps) => {
  const {widgetData} = props
  const {
    image = '',
    altText = '',
    name = '',
    designation = '',
    description = '',
    buttonText = '',
    cta = '',
    themeToUse = '',
    descriptionBgColor = '',
    onButtonClick = noop,
  } = widgetData || {}

  const handleOnButtonClick = useCallback(
    () => onButtonClick(cta),
    [onButtonClick, cta]
  )

  return (
    <MedicalTeamCardContainer
      descriptionBgColor={descriptionBgColor}
      data-category={themeToUse}
    >
      <div className="medical-team-card__card-container">
        <OptimizedImage
          source={image}
          aspectWidth="40%"
          aspectHeight="auto"
          desktopViewWidth="HALF"
          mobileViewWidth="HALF"
          altText={altText}
          loading="lazy"
          className="medical-team-card__image"
        />
        <div className="medical-team-card__data-container">
          <Typography
            variant={TypographyVariants.SUBHEADING_BOLD}
            customClassName="medical-team-card__tilte"
          >
            {name}
          </Typography>
          <div className="medical-team-card__subtitle">{designation}</div>
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            <span
              className="medical-team-card__description"
              dangerouslySetInnerHTML={{__html: description}}
            ></span>
          </Typography>
          {buttonText && (
            <div className="medical-team-card__button-container">
              <button
                className="medical-team-card__button"
                onClick={handleOnButtonClick}
              >
                <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
                  {buttonText}
                </Typography>
                <ChevronRight color="var(--dls-category-primary-color-500)" />
              </button>
            </div>
          )}
        </div>
      </div>
    </MedicalTeamCardContainer>
  )
}

export default ImageWithDescriptionCard
