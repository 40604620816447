import styled from 'styled-components'

export const StyledDoctorListingCard = styled.div`
  background: var(--brand-primary-white-color);
  padding: var(--dls-size-16);
  padding-bottom: 0;

  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: var(--dls-size-12);
    gap: var(--dls-size-12);
    row-gap: var(--dls-size-12);
    flex-wrap: wrap;
    .footer-cta {
      width: calc((100% - var(--dls-size-12)) / 2);
      &[data-isfullwidth='true'] {
        width: 100%;
      }
    }
  }

  .divider {
    border-bottom: var(--dls-size-1) dashed var(--dls-divider-light-color);
    padding: 0 var(--dls-size-16);
    padding-top: var(--dls-size-16);
  }
`
