import {useState, useCallback, useMemo} from 'react'
import {BACKEND_BASE_PATH} from 'src/utils/constants/breakPoints'
import {IProductData} from './useFetchProduct.interface'

function useFetchProduct() {
  const [productData, setProductData] = useState<IProductData>()
  const [isLoading, setIsLoading] = useState(false)

  const howToUse = useMemo(() => {
    if (productData?.rx?.description) {
      return productData.rx.description
    }

    return productData?.description
  }, [productData])

  const fetchPdpData = useCallback(async (urlKey: string) => {
    setIsLoading(true)

    const params = {
      theme: 'rcl',
    }
    try {
      const res = await fetch(
        `${BACKEND_BASE_PATH}page/mwsc/product/${urlKey}?${new URLSearchParams(
          params
        )}`
      ).then((res) => res.json())
      const productData = res?.data
      setProductData(productData)
      setIsLoading(false)
    } catch (error) {
      // do nothing
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    state: {
      productData: {
        howToUse,
        productInfo: productData?.productInfo,
        prod_img: productData?.prod_img,
        card_for_with: productData?.card_for_with,
        concern: productData?.concern,
      },
      isLoading,
    },
    actions: {
      fetchPdpData,
    },
  }
}

export {useFetchProduct}
