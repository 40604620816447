import React from 'react'

function DownArrow({width = 10, height = 6}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="#030303"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 1l4 4 4-4"
      ></path>
    </svg>
  )
}

export default DownArrow
