import React, {useEffect, useCallback, useState, useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {SuccessScreenWrapper} from '../SuccessScreen/SuccessScreen.styles'
import AppointmentCard from '../SuccessScreen/AppointmentCard'
import CancelCard from '../SuccessScreen/CancelCard'
import Modal from '../../../ComponentsV2/common/Modal'
import {ScheduleScreen} from '..'
import CheckIcon from '../SuccessScreen/CheckIcon'
import {UploadCallout} from '../ImageUpload'
import TrustMarkers from '../../../ComponentsV2/Screens/HomePage/TrustMarkers'
import {CustomerTestimonials} from '../../../ComponentsV2/common/CustomerTestimonials'

const SuccessScreenV2 = (props) => {
  const {
    bannerData = {},
    appointmentCard = {},
    cancelCard = {},
    infoCard = {},
    downloadApp = {},
    trustMarkers = {},
    customerTestimonials = {},
    firstName = '',
    // cancel card data
    showAppointmentCardButtons = true,
    scheduleScreenProps = {},
    rescheduleAppointmentData = {},
    isRescheduleInProgress = false,
    scheduleToken,
    appointmentDetailsFromScheduleToken,
    isAppointmentDataLoading,
  } = props.state || {}

  const {
    handleCta = noop,
    cancelAppointment = noop,
    checkFutureAppointments = noop,
    rescheduleAppointment = noop,
    resetRescheduleAppointmentData = noop,
    onDownloadCtaClick = noop,
    getAppointmentDetails = noop,
    trackConfirmationScreenV2 = noop,
    getFloatingIslandNudgeData = noop,
  } = props.actions || {}

  const {
    appointmentDate = '',
    appointmentTime = '',
    appoinmentLabel = '',
    categoryTitle = '',
    categories = [],
    source = '',
    appointmentType = '',
    isOngoing = false,
  } = appointmentDetailsFromScheduleToken || {}

  const {
    heading = '',
    confettiLink = '',
    subHeading = '',
    offerText = '',
  } = bannerData

  const {callout = {}, downloadCta = {}} = downloadApp
  const {cardTitle = '', steps = []} = infoCard

  const [showModal, setShowModal] = useState(false)
  const [showCancelCard, setShowCancelCard] = useState(false)

  const appointmentCardData = useMemo(() => {
    return {
      appointmentDate,
      appointmentTime,
      appointmentId: appoinmentLabel,
      category: categoryTitle,
      appointmentType,
    }
  }, [
    appointmentDate,
    appointmentTime,
    appoinmentLabel,
    categoryTitle,
    appointmentType,
  ])

  const showCancelButton = useMemo(() => {
    return appoinmentLabel.split('-')[1]?.toLowerCase() === 'o' ? false : true
  }, [appoinmentLabel])

  const handleShowModal = useCallback(
    () => setShowModal(!showModal),
    [showModal]
  )

  const disableRescheduleButton = useMemo(() => {
    return isEmpty(scheduleToken)
  }, [scheduleToken])

  const closeRescheduleModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleCancelClicked = useCallback(() => {
    setShowCancelCard(true)
  }, [])

  const onSecondaryCtaClick = useCallback(async () => {
    await cancelAppointment({
      appointmentId: appoinmentLabel.split('-')[2],
    })

    //refetch nudge data on cancel appointment
    await getFloatingIslandNudgeData()
    handleCta({action: 'LINK', link: '/'})
  }, [
    appoinmentLabel,
    cancelAppointment,
    handleCta,
    getFloatingIslandNudgeData,
  ])

  const onPrimaryCtaClick = useCallback(() => {
    setShowCancelCard(false)
  }, [])

  const handleDownloadCtaClicked = useCallback(() => {
    onDownloadCtaClick(downloadCta?.link)
    trackConfirmationScreenV2({
      type: 'downloadClicked',
      data: {...appointmentDetailsFromScheduleToken, entry: document.referrer},
    })
  }, [
    appointmentDetailsFromScheduleToken,
    downloadCta?.link,
    onDownloadCtaClick,
    trackConfirmationScreenV2,
  ])

  useEffect(() => {
    if (scheduleToken) {
      getAppointmentDetails({scheduleToken})
    }
  }, [getAppointmentDetails, scheduleToken])

  useEffect(() => {
    if (appointmentDetailsFromScheduleToken) {
      trackConfirmationScreenV2({
        type: 'screenViewed',
        data: {
          ...appointmentDetailsFromScheduleToken,
          entry: document.referrer,
        },
      })
    }
  }, [appointmentDetailsFromScheduleToken, trackConfirmationScreenV2])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <SuccessScreenWrapper confettiLink={confettiLink}>
      <div className="success-screen__heading-card">
        <div className="success-screen__heading-container">
          <CheckIcon />
          <div className="success-screen__heading-text">{heading}</div>
          <div className="success-screen__heading-text">{firstName}</div>
        </div>
        <div className="success-screen__sub-heading">{subHeading}</div>
        <div className="success-screen__offer-container">
          <div
            dangerouslySetInnerHTML={{__html: offerText}}
            className="success-screen__offer-text"
          />
        </div>
      </div>

      <div className="success-screen__content">
        {/* <AppointmentCard
          state={{
            ...appointmentCard,
            ...appointmentCardData,
            showAppointmentCardButtons,
            showCancelButton,
            isOnGoing: isOngoing,
            isAppointmentDataLoading,
            disableRescheduleButton,
          }}
          actions={{
            handleCancelClicked,
            handleRescheduleClicked: handleShowModal,
            onDownloadCtaClick,
            trackConfirmationScreenV2,
          }}
        /> */}

        <div className="success-screen__download-app">
          <UploadCallout
            state={{
              ...callout,
              className: 'success-screen__callout',
            }}
          />

          <button
            className="success-screen__convert-to-IA-button"
            onClick={handleDownloadCtaClicked}
          >
            <img src={downloadCta?.icon} alt={downloadCta?.alt} />
            <div>{downloadCta?.text}</div>
          </button>
        </div>

        <TrustMarkers state={{...trustMarkers, className: 'trust-markers'}} />

        <div className="success-screen__customer-testimonials">
          <CustomerTestimonials state={customerTestimonials} />
        </div>

        <div className="success-screen__info-card-container">
          <div className="success-screen__info-card">
            <div className="success-screen__info-card-title">{cardTitle}</div>
            {steps.map((step, index) => {
              return (
                <div
                  dangerouslySetInnerHTML={{__html: step}}
                  className="success-screen__info-card-step"
                  key={index}
                />
              )
            })}
          </div>
        </div>
      </div>
      <Modal
        onClose={handleShowModal}
        showModal={showModal}
        additionalContentClass="reschedule-modal__wrapper"
      >
        <div className="schedule-screen-content">
          <ScheduleScreen
            state={{
              ...scheduleScreenProps.state,
              isReschedule: true,
              schedule_token: scheduleToken,
              rescheduleAppointmentData,
              isRescheduleInProgress,
              loadingFutureAppointments: isAppointmentDataLoading,
              rxCategories: categories,
              getRescheduledDataUsingGetAppointmentDetails: true,
            }}
            actions={{
              ...scheduleScreenProps.actions,
              resetRescheduleAppointmentData,
              rescheduleAppointment,
              closeRescheduleModal,
              checkFutureAppointments,
              getAppointmentDetails,
              handleCta,
              getFloatingIslandNudgeData,
            }}
          />
        </div>
      </Modal>
      <CancelCard
        state={{...cancelCard, showCancelCard}}
        actions={{onPrimaryCtaClick, onSecondaryCtaClick}}
      />
    </SuccessScreenWrapper>
  )
}

export default SuccessScreenV2
