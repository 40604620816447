import React from 'react'

import {Typography} from '../../../ComponentsV2/common/TypographyV2'
import {StyledButton} from './Button.styled'
import {ImageOrAnimationBannerProps} from '@mosaic-wellness/fe-types'
import {ImageOrAnimationBanner} from '@web-components'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'PRIMARY' | 'SECONDARY' | 'TERTIARY'
  onClick: () => void
  disabled?: boolean
  size?: 'SMALL' | 'MEDIUM' | 'LARGE'
  customClassName?: string
  label: string
  fullWidth?: boolean
  customLabelClassName?: string
  leftIconProps?: ImageOrAnimationBannerProps
  rightIconProps?: ImageOrAnimationBannerProps
  centerIconProps?: ImageOrAnimationBannerProps
}

function Button(props: ButtonProps) {
  const {
    type = 'button',
    variant = 'PRIMARY',
    size = 'MEDIUM',
    disabled = false,
    label,
    fullWidth = false,
    onClick,
    customLabelClassName = '',
    customClassName = '',
    leftIconProps = null,
    rightIconProps = null,
    centerIconProps = null,
  } = props

  const labelTypographyVariant = React.useMemo(() => {
    switch (size) {
      case 'SMALL':
        return 'cta-label-sm'
      case 'LARGE':
      case 'MEDIUM':
      default:
        return 'cta-label-lg'
    }
  }, [size])
  if (!label) {
    return null
  }

  return (
    <StyledButton
      type={type}
      data-variant={variant}
      data-size={size}
      data-disabled={disabled}
      disabled={disabled}
      data-isfullwidth={fullWidth}
      onClick={onClick}
      className={customClassName}
    >
      {leftIconProps && <ImageOrAnimationBanner {...leftIconProps} />}
      <div className="center-data-wrapper">
        {centerIconProps && <ImageOrAnimationBanner {...centerIconProps} />}
        <Typography
          variant={labelTypographyVariant}
          customClassName={`cta-label ${customLabelClassName}`}
        >
          <span dangerouslySetInnerHTML={{__html: label}} />
        </Typography>
      </div>
      {rightIconProps && <ImageOrAnimationBanner {...rightIconProps} />}
    </StyledButton>
  )
}

export default Button
