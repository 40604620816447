import {useEffect} from 'react'
import {useRouter} from 'next/router'

function useSetSecondaryUtmSource() {
  const {pathname} = useRouter()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const utm_source = urlParams.get('utm_source')

    async function setSecondaryUtmSource() {
      if (utm_source) {
        window.sessionStorage.setItem(
          'secondary_utm_source',
          utm_source as string
        )
      }
    }

    setSecondaryUtmSource()
  }, [pathname])
}

export {useSetSecondaryUtmSource}
