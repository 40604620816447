import {Product} from '../../CategoryProductCardGrid/CategoryProductCard/CategoryProductCard.interface'
import ProductListingCard from '../../ProductLisiting/ProductListingCard/ProductListingCard'
import EmptySearchResult from './EmptySearchResult/EmptySearchResult'
import {SearchResultProductListContainer} from './SearchResultProductList.styles'
import {SearchResultProductListProps} from './SearchResultProductList.types'

const SearchResultProductList = (props: SearchResultProductListProps) => {
  const {
    products = [],
    handleCardClick,
    handleCtaClick,
    emptySearchResultConfig,
    handleIncreaseQuantity,
    handleReduceQuantity,
    enableQuantityIndicator,
  } = props

  if (!products) return null

  return products.length > 0 ? (
    <SearchResultProductListContainer>
      {products?.map((product: Product) => {
        return (
          <ProductListingCard
            key={product?.urlKey}
            product={product}
            handleCtaClick={handleCtaClick}
            handleCardClick={handleCardClick}
            handleIncreaseQuantity={handleIncreaseQuantity}
            handleReduceQuantity={handleReduceQuantity}
            enableQuantityIndicator={enableQuantityIndicator}
          />
        )
      })}
    </SearchResultProductListContainer>
  ) : (
    <EmptySearchResult {...emptySearchResultConfig} />
  )
}
export default SearchResultProductList
