import {create} from 'zustand'

export interface NavTarget {
  link: string
  [key: string]: any
}
interface AuthStore {
  navTarget: NavTarget | null
  setNavTarget: (navTarget: NavTarget) => void
  resetNavTarget: () => void
}

export const useAuthorizationStore = create<AuthStore>()((set) => ({
  navTarget: null,
  setNavTarget: (navTarget) => set({navTarget}),
  resetNavTarget: () => set({navTarget: null}),
}))
