import styled from 'styled-components'

export const ProductPreviewModalContainer = styled.div`
  .modal {
    padding: 0px;
    z-index: 9;
  }

  .product-preview-wrapper {
    height: 70vh;
    overflow-y: scroll;
    width: 100%;

    @media screen and (min-width: 768px) {
      height: auto;
      max-height: 70vh;
      overflow-y: auto;
    }
  }

  .modal-content {
    max-width: 720px;
    padding: 20px 10px;

    @media screen and (min-width: 768px) {
      padding: 16px;
    }
  }

  .product-preview-modal__product-container {
    display: flex;
    flex-direction: column;
  }

  .product-preview-modal__product-header {
    display: flex;
  }

  .product-preview-modal__product-image-container {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    background-color: var(--category-primary-bg1-color);
  }

  .product-preview-modal__product-info-container {
    margin-left: 10px;
    flex-grow: 1;
  }

  .product-preview-modal__product-for {
    font-size: 12px;
    font-weight: var(--brand-font-weight-400);
    margin: 8px 0;
    span {
      color: var(--category-primary-color);
    }
  }

  .product-preview-modal__product-pricing {
    border: 1px solid red;
  }

  .product-preview-modal__product-concern {
    margin: 20px 0;
    background-color: var(--category-primary-bg1-color);
    border-radius: 12px;
    padding: 4px 10px;
    color: var(--category-primary-color);
    font-size: 12px;
    font-weight: var(--brand-font-weight-400);
  }

  .product-preview-modal__product-rating {
    border: 1px solid red;
  }

  .main-title {
    font-weight: 500;
    font-size: 16px;
    font-weight: var(--brand-font-weight-semiBold);

    margin: 0;
    margin-top: 4px;

    @media (min-width: 768px) {
      font-size: 20px;
      font-weight: var(--brand-font-weight-bold);
      line-height: 37px;
    }
  }

  .rating-section {
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    font-weight: var(--brand-font-weight-normal);
    margin-top: 16px;
    color: var(--brand-primary-grey-color);
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
  }

  .rating-section__rating_container {
    display: flex;

    .rating {
      display: inline-block;
      font-size: 12px;
      font-weight: var(--brand-font-weight-normal);
      line-height: 14px;
      color: #6c6c6c;
      margin-right: 3px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }

  .rating-section__divider {
    width: 1px;
    margin: 0 8px;
    height: 10px;
    margin-bottom: 2px;
    font-size: 16px;
    line-height: 16px;
    background-color: var(--brand-primary-grey-color);
    font-weight: var(--brand-font-weight-light);
  }

  .price-section {
    font-weight: var(--brand-font-weight-normal);
    box-sizing: border-box;
    display: flex;
    margin-top: 5px;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 23px;
      font-weight: var(--brand-font-weight-light);
    }
  }

  .price-section__rupee {
    font-weight: var(--brand-font-weight-bold);
    font-size: 16px;
  }
  .price-section__discounted_price {
    font-weight: var(--brand-font-weight-bold);
    font-size: 16px;
  }
  .price-section__actual_price {
    text-decoration: line-through;
    margin-left: 8px;
    margin-bottom: 2px;
    color: var(--brand-primary-grey-color);
  }
  .price-section__discount_text {
    margin-left: 10px;
    color: var(--brand-primary-black-color);
    margin-bottom: 2px;
    border-radius: 5px;
    padding: 3px 5px;
    background: var(--category-primary-color);
    background-color: var(--brand-primary-light-dark-yellow-color);
    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: 768px) {
    .product-preview-modal__product-image-container {
      width: 140px;
      height: 140px;
    }

    .product-preview-modal__product-concern {
      font-size: 16px;
    }

    .product-preview-modal__product-for {
      font-size: 16px;
    }

    .price-section__rupee {
      font-size: 20px;
      font-weight: var(--brand-font-weight-heavy);
      align-self: flex-start;
    }
    .price-section__actual_price {
      margin-bottom: 6px;
      font-size: 20px;
    }

    .price-section__discounted_price {
      font-size: 20px;
    }
    .price-section__discount_text {
      margin-left: 10px;
      margin-bottom: 8px;
      border-radius: 5px;
      padding: 3px 5px;
      font-size: 16px;
    }

    .rating-section {
      font-size: 16px;
      line-height: 23px;
      font-weight: var(--brand-font-weight-normal);
      margin-top: 16px;
      justify-content: flex-start;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .rating-section__divider {
      height: 16px;
    }

    .loading-container {
      width: 680px;
    }
  }
`
