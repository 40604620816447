export const processFutureAppointment = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {appointmentId = '', category = '', source = '', type = ''} = data

  return {
    'Appointment Id': appointmentId,
    Source: source,
    Category: category,
    Type: type,
  }
}
