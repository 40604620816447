import React from 'react'

import PaymentSummaryComponent from './PaymentSummaryComponent'

function PaymentSummary({
  context,
  isPaymentSummary = true,
  isBillingSummary = false,
  isNewCartDesign = false,
}: any) {
  const {state} = React.useContext(context)
  const {billingSummary} = state || {}

  return (
    <PaymentSummaryComponent
      state={{
        ...billingSummary,
        isPaymentSummary,
        isBillingSummary,
        isNewCartDesign,
      }}
    />
  )
}

export default PaymentSummary
