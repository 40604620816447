import styled from 'styled-components'

export const UploadCalloutContainer = styled.div`
  padding: 12px 16px;
  background-color: ${({backgroundColor = '#F9F9F4'}) => backgroundColor};
  border-radius: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  margin: 20px 0px;

  .callout-icon-container {
    height: 24px;
    width: 24px;
    margin-right: 24px;
    flex-shrink: 0;
  }

  .callout-icon {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .callout-text {
    font-weight: var(--dls-size-0);
  }
`
