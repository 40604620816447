import styled from 'styled-components'

export const ScheduleAppointmentFormContainer = styled.div`
  background-color: var(--brand-primary-white-color);
  border-radius: 20px 20px 0px 0px;

  @media screen and (min-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .tabs {
    display: flex;
    justify-content: space-between;

    .tab {
      padding: 20px 0px;
      font-size: 18px;
      font-weight: 400;
      color: var(--brand-primary-grey-color);
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      &[data-is-active='true'] {
        font-weight: 700;
        color: var(--brand-primary-color);
        border-bottom: 3px solid var(--brand-primary-color);
      }
    }
  }

  .cta-button {
    font-family: var(--brand-font-family-text);
    width: 100%;
    border: 0px;
    outline: 0px;
    background-color: var(--brand-primary-color);
    color: var(--brand-primary-white-color);
    font-weight: 600;
    font-size: 18px;
    padding: 16px;
    line-height: 1;
    text-align: center;
    border-radius: 16px;

    :disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .modal-content,
  .drawer {
    padding: 0px !important;
  }

  .schedule-screen-content {
    height: 70vh;
    overflow-y: scroll;
    padding: 0 !important;
  }

  @media screen and (min-width: 768px) {
    .schedule-screen-content {
      height: 70vh;
      overflow-y: scroll;
      padding: var(--dls-size-24) var(--dls-size-24) var(--dls-size-0)
        var(--dls-size-24) !important;
    }
  }
`
