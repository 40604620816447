export const processTopBar = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = '', url = '', appointmentBooked = false} = data

  return {
    Category: category,
    URL: url,
    'Appointment Booked': appointmentBooked ? 'Y' : 'N',
  }
}
