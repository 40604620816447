import {SESSION_STORAGE_CONSTANTS} from './sessionStorageConstants'

type Keys = keyof typeof SESSION_STORAGE_CONSTANTS
type keyName = typeof SESSION_STORAGE_CONSTANTS[Keys]

interface ISessionStorageSet {
  keyName: keyName
  value: boolean | Record<string, any>
}

const sessionStorageMethods = {
  set: (props: ISessionStorageSet) => {
    try {
      if (typeof window !== 'undefined')
        window.sessionStorage.setItem(
          props.keyName,
          JSON.stringify(props.value)
        )
    } catch (e) {
      console.error(e)
    }
  },
  remove: (keyName: keyName) => {
    try {
      if (typeof window !== 'undefined')
        window.sessionStorage.removeItem(keyName)
    } catch (e) {
      console.error(e)
    }
  },
  get: (keyName: keyName) => {
    try {
      if (typeof window !== 'undefined')
        return JSON.parse(window.sessionStorage.getItem(keyName) || 'null')
      else return null
    } catch (e) {
      console.error(e)
    }
  },
}

export default sessionStorageMethods
