import React from 'react'
import {ITypography} from './Typography.types'
import {HeadingWrapper, TypographyWrapper} from './Typography.styles'
import {HEADING_TAGS_MAPPING} from '../../../constants/headingTagsMapping'

/**
 * Please import Typrography from TypographyV2
 *@deprecated
 */

const Typography: React.FC<ITypography> = (props) => {
  const {
    text = '',
    variant = 'body-base-regular',
    isDangerouslySet = false,
    customClassName,
    headingLevel,
    dataTestId = '',
  } = props

  if (isDangerouslySet) {
    return (
      <TypographyWrapper
        className={`${variant} ${customClassName}`}
        dangerouslySetInnerHTML={{__html: text}}
        data-testid={dataTestId}
      />
    )
  }

  if (headingLevel) {
    return (
      <HeadingWrapper
        as={HEADING_TAGS_MAPPING[headingLevel]}
        className={`${variant} ${customClassName}`}
        data-testid={dataTestId}
      >
        {text}
      </HeadingWrapper>
    )
  }

  return (
    <TypographyWrapper
      className={`${variant} ${customClassName}`}
      data-testid={dataTestId}
    >
      {text}
    </TypographyWrapper>
  )
}

export default Typography
