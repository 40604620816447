import styled from 'styled-components'

const StyledHybridLayoutItem = styled.div<{objectFit: string}>`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  .animationOrImage {
    width: 100%;
    height: 100%;
    object-fit: ${({objectFit}) => objectFit || 'cover'};
  }
`

export {StyledHybridLayoutItem}
