import {getQueryParamFromUrl} from 'src/utils/url'

export const processDaSuccessDownloadAppClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    type = '',
    category = '',
    appointmentId = '',
  } = dLayer?.futureAppointmentData || {}

  return {
    'IA Booked': type === 'IA' ? 'Y' : 'N',
    Category: category,
    'Appointment ID': appointmentId,
    utm_source: getQueryParamFromUrl('utm_source'),
  }
}

export const processDaSuccessEditAppointmentClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    type = '',
    category = '',
    appointmentId = '',
  } = dLayer?.futureAppointmentData || {}

  return {
    'IA Booked': type === 'IA' ? 'Y' : 'N',
    Category: category,
    'Appointment ID': appointmentId,
    utm_source: getQueryParamFromUrl('utm_source'),
  }
}

export const processDaSuccessRescheduleClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    type = '',
    category = '',
    appointmentId = '',
  } = dLayer?.futureAppointmentData || {}

  return {
    'IA Booked': type === 'IA' ? 'Y' : 'N',
    Category: category,
    'Appointment ID': appointmentId,
    utm_source: getQueryParamFromUrl('utm_source'),
  }
}

export const processDaSuccessAppointmentCancelled = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    type = '',
    category = '',
    appointmentId = '',
  } = dLayer?.futureAppointmentData || {}

  return {
    'IA Booked': type === 'IA' ? 'Y' : 'N',
    Category: category,
    'Appointment ID': appointmentId,
    utm_source: getQueryParamFromUrl('utm_source'),
  }
}
