import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'

import {useGenericActions} from '../../hooks'
import {ProductsGridContainer} from './CategoryProductCardGrid.styles'
import {CategoryProductCard} from './CategoryProductCard'
import {useCallback, useMemo} from 'react'
import NoProductsFound from './NoProductsFound'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {Product} from './CategoryProductCard/CategoryProductCard.interface'
import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'

function CategoryProductCardGrid(props: BaseWidget) {
  const {type, widgetData, header, layout, id} = props || {}
  const {
    products = [],
    showEmptyState = true,
    emptyState,
    enableQuantityIndicator = false,
    source: widgetDataSource = '',
    showMobileViewOnDesktop = false,
    productImageAspectRatio = 1,
  } = widgetData || {}
  const {genericAction} = useGenericActions()
  const source = useMemo(() => widgetDataSource || id, [id, widgetDataSource])

  // TODO: remove hook
  const {updateItemToCart} = useUpdateItemToCart()

  const showNoProductsEmptyState = useMemo(
    () => isEmpty(products) && showEmptyState,
    [products, showEmptyState]
  )
  const handleCtaClick = useCallback(
    (product: Product) => {
      const {action = '', actionData = {}} = product?.cta || {}
      if (!action) return
      genericAction({
        actionName: action,
        params: {...product, ...(actionData || {})},
      })
    },
    [genericAction]
  )

  const handleCardClick = useCallback(
    (product: Product) => {
      genericAction({actionName: 'OPEN_PDP', params: product})
    },
    [genericAction]
  )

  const handleUpdateCartItem = useCallback(
    (id: number, sku: string, quantity: number) => {
      updateItemToCart({
        sku,
        quantity,
        isMiniCart: true,
      })
    },
    [updateItemToCart]
  )

  if (isEmpty(products)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductsGridContainer showMobileViewOnDesktop={showMobileViewOnDesktop}>
        {!showNoProductsEmptyState &&
          products.map((product: any, index: number) => (
            <div
              className="product-card-container"
              key={product.urlKey + index}
            >
              <CategoryProductCard
                product={{
                  ...product,
                  genericAction,
                  source,
                  productImageAspectRatio,
                }}
                handleCardClick={handleCardClick}
                handleCtaClick={handleCtaClick}
                handleUpdateCartItem={handleUpdateCartItem}
                enableQuantityIndicator={enableQuantityIndicator}
              />
            </div>
          ))}
      </ProductsGridContainer>
      {showNoProductsEmptyState && <NoProductsFound emptyState={emptyState} />}
    </BaseWidgetComponent>
  )
}

export default CategoryProductCardGrid
