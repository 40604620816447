import styled from 'styled-components'

export const AccordionContainer = styled.div`
  padding: 0 var(--dls-size-16);

  .accordion-item {
    :first-child {
      padding-top: 0;
    }

    :not(:last-child) {
      border-bottom: 1px solid var(--dls-divider-light-color);
    }
  }
`
