import {useLoadingStore} from '@mosaic-wellness/redux-action-library'
import React from 'react'
import CartLoadingContextProvider from 'src/components/shared/src/ComponentsV2/Context/CartUpdatingContext'

const CartLoadingContextProviderWithProps: React.FC = ({children}) => {
  const {
    cartLoading: {
      isCartUpdating: {status: isCartUpdating, itemId: itemBeingAddedToCart},
    },
  } = useLoadingStore()

  return (
    <CartLoadingContextProvider
      isCartUpdating={isCartUpdating}
      itemBeingAddedToCart={itemBeingAddedToCart}
    >
      {children}
    </CartLoadingContextProvider>
  )
}

export default CartLoadingContextProviderWithProps
