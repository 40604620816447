import {useCallback} from 'react'
import {
  useConfigStore,
  useFloatingIslandNudgesData,
  useUploadImagesV3,
} from '@mosaic-wellness/redux-action-library'

import {IUseDaImageUploadReturnType} from './directAssessment.interface'

function useDaImageUpload(): IUseDaImageUploadReturnType {
  const {
    actions: {imageUpload},
    state: {uploaded, uploadError, isLoading},
  } = useUploadImagesV3()
  const {
    environment: {toast},
  } = useConfigStore()
  const [{}, {getFloatingIslandNudgeData}] = useFloatingIslandNudgesData()

  const onImageSizeExceeded = useCallback(
    ({message}) => {
      toast(
        'warning',
        message || 'Image size too large, kindly upload the image below 10 MB.'
      )
    },
    [toast]
  )

  return [
    {
      uploaded,
      uploadError,
      isLoading,
    },
    {
      imageUpload,
      onImageSizeExceeded,
      getFloatingIslandNudgeData,
    },
  ]
}

export {useDaImageUpload}
