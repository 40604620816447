import {useRouter} from 'next/router'
import React from 'react'
import {SCREEN_PATH_NAMES} from 'src/utils/constants/screenPathNames'

function getScreenNameFromPath(value: string): string | undefined {
  return Object.keys(SCREEN_PATH_NAMES).find(
    (key) => SCREEN_PATH_NAMES[key as keyof typeof SCREEN_PATH_NAMES] === value
  )
}

const useScreenName = () => {
  const {pathname} = useRouter()
  const screenName = getScreenNameFromPath(pathname) || pathname
  return {screenName}
}

export default useScreenName
