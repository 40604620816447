export const processTwidRewardSelected = (data: any) => {
  const {
    applicableOnPaymentMethod = '',
    title,
    additionalPaymentData,
  } = data || {}

  return {
    'Reward Type': applicableOnPaymentMethod === 'card' ? 'Card' : 'Non Card',
    'Reward Partner': title,
    'Reward Amount': additionalPaymentData?.split_amount,
  }
}

export const processTwidRewardFailure = (data: any) => {
  const {title, additionalPaymentData} = data || {}

  return {
    'Reward Partner': title,
    'Reward Amount': additionalPaymentData?.split_amount,
  }
}
