const ALGOLIA_CONSTANTS = {
  SEARCH_API_KEY:
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY ||
    'e090f1596679e29408ae2fd325fec51e',
  APPLICATION_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '4FC0B8WS4D',
  SEARCH_INDEX:
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || 'manmatters_products_prod',
  EVENT_URL: 'https://insights.algolia.io/1/events',
  LOCAL_STORAGE_KEY_NAME: 'searchItems',
  USER_TOKEN: 'algolia_user_token',
  QUERY_ID: 'algolia_query_id',
}

const ALGOLIA_EVENTS = {
  ADD_TO_CART: {
    eventType: 'click',
    eventName: 'Product Added',
    location: 'search',
  },
  PRODUCT_VIEWED: {
    eventType: 'click',
    eventName: 'Product Viewed',
    location: 'search',
  },
  PAY_CLICKED: {
    eventType: 'conversion',
    eventName: 'Product Ordered',
    location: 'search and cart',
  },
}

export {ALGOLIA_CONSTANTS, ALGOLIA_EVENTS}
