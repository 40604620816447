import {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import noop from 'lodash/noop'

import {GetCustomerInfoContainer} from './GetCustomerInfo.styles'
import {InputSection} from '../../../ComponentsV2/common'
import {ConsultStepsCard} from './ConsultStepsCard'
import NextButton from '../NextButton'
import {AGE_PATTERN, EMAIL_PATTERN} from './GetCustomerInfo.constants'

function GetCustomerInfo(props) {
  const {state = {}, actions = {}} = props

  const {
    title = '',
    subtitle = '',
    consultSteps = {},
    ctaLabel = '',
    isLoading = false,
    isSaveWithEmailSuccess = false,
  } = state

  const {
    onCtaClick = noop,
    handleNextStep = noop,
    onGetCustomerInfoSubmit = noop,
    setAnalyticsUserDetails = noop,
  } = actions

  const {
    register,
    formState: {errors, isValid, isDirty},
    handleSubmit,
  } = useForm({mode: 'onChange', reValidateMode: 'onBlur'})

  const handleCtaClicked = useCallback(
    (data) => {
      //analytics trigger
      onGetCustomerInfoSubmit({
        name: data.name,
        email: data.email,
      })

      onCtaClick({
        firstName: data.name.split(' ')[0] || ' ',
        lastName: data.name.split(' ')[1] || ' ',
        updatedEmail: data.email,
      })

      setAnalyticsUserDetails({
        firstName: data.name.split(' ')[0] || ' ',
        lastName: data.name.split(' ')[1] || ' ',
        updatedEmail: data.email,
      })
    },
    [onCtaClick, onGetCustomerInfoSubmit, setAnalyticsUserDetails]
  )

  useEffect(() => {
    if (isSaveWithEmailSuccess && !isLoading) {
      handleNextStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveWithEmailSuccess, isLoading])

  return (
    <GetCustomerInfoContainer>
      <div className="title-container">
        <h1 className="title">{title}</h1>
        <p className="subtitle">{subtitle}</p>
      </div>

      <div className="form-container">
        <InputSection
          label="Name"
          id="first-name"
          type="text"
          placeholder="Ram"
          registerKey="name"
          register={register('name', {required: true})}
          errors={errors}
          className="customer-info-input"
          errorText="Name is required"
        />

        <InputSection
          label="Age"
          id="age"
          type="text"
          placeholder="25"
          registerKey="age"
          register={register('age', {
            required: true,
            pattern: {
              value: AGE_PATTERN,
              message: 'Please enter a valid age',
            },
          })}
          errors={errors}
          className="customer-info-input"
          errorText="Age is required"
        />

        <InputSection
          label="Email ID"
          id="email"
          type="text"
          placeholder="ramgopal@gmail.com"
          registerKey="email"
          register={register('email', {
            required: true,
            pattern: {
              value: EMAIL_PATTERN,
              message: 'Please enter a valid email',
            },
          })}
          errors={errors}
          className="customer-info-input"
          errorText="Email is required"
        />

        <ConsultStepsCard state={{...consultSteps}} />

        <NextButton
          state={{label: ctaLabel, isLoading, isDisabled: !isDirty || !isValid}}
          actions={{onClick: handleSubmit(handleCtaClicked)}}
        />
      </div>
    </GetCustomerInfoContainer>
  )
}

export default GetCustomerInfo
