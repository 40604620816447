import {Typography, TypographyVariants} from '@web-components'
import {Container} from './Heading.styles'
import {IHeader} from './Heading.types'

const Heading = (props: IHeader) => {
  if (!props.label) return null
  return (
    <Container>
      <div className="section">
        <div className="spacer">
          <Typography
            variant={props.variant || TypographyVariants.SUBHEADING_BOLD}
            customClassName="text"
          >
            {props.label}
          </Typography>
        </div>
      </div>
    </Container>
  )
}

export default Heading
