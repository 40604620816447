import {getCartDetailsForAnalytics} from 'src/utils/getCartDetailsForAnalytics'
import {getProductDetailsForAnalytics} from 'src/utils/getProductDetailsForAnalytics'

export const processAddToCart = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    cardPosition = -1,
    query = '',
    tagPosition = -1,
    whyThisWorks = [],
    deeplinkUrl = '',
    baseProductUrlKey = '',
  } = data

  const productData = getProductDetailsForAnalytics(data)

  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: productData.source,
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    'source URL of ATC': productData.url,
    cardPosition,
    query,
    tagPosition,
    'Self diagnosis category': productData?.category || '',
    'Self diagnosis stage': productData?.config?.diagnosisStage || '',
    'Self diagnosis concern': productData?.config?.diagnosisConcern || '',
    'Self diagnosis severity': productData?.config?.diagnosisSeverity || '',
    'Concern Type':
      whyThisWorks.map((value: any) => value?.text).join(',') || '',
    deeplinkUrl: deeplinkUrl || '',
    basePdpId: baseProductUrlKey,
  }
  return dataToReturn
}

export const processAddToCartPD = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {cardPosition = -1, query = '', tagPosition = -1} = dLayer.product

  const {product} = dLayer || {}

  const productData = getProductDetailsForAnalytics(product)

  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: 'PDP',
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    'source URL of ATC': productData.url,
    cardPosition,
    query,
    tagPosition,
    'Self diagnosis category': productData?.category || '',
    'Self diagnosis stage': productData?.config?.diagnosisStage || '',
    'Self diagnosis concern': productData?.config?.diagnosisConcern || '',
    'Self diagnosis severity': productData?.config?.diagnosisSeverity || '',
  }

  return dataToReturn
}

export const processBuyNowPd = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {product, cart} = dLayer || {}
  const productData = getProductDetailsForAnalytics(product)
  const cartData = getCartDetailsForAnalytics(cart, false, {})

  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: 'PDP',
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    'source URL of ATC': data?.location || '',
    'Total Cart Amount': cartData.totalAmount,
    'Total Cart Items': cartData.totalItems,
    'Discount Amount': cartData.discountValue,
  }

  return dataToReturn
}

export const processBuyNowClickedOnAllReviews = (data: any) => {
  const productData = getProductDetailsForAnalytics(data)
  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: productData.source || 'All Reviews',
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
  }

  return dataToReturn
}

export const processBuyNowProceedToCheckout = (
  data: any,
  dataLayer: typeof window.customDataLayer
) => {
  const {buyNowItem} = data || {}
  const {cart, product} = dataLayer || {}

  const productData = getProductDetailsForAnalytics(buyNowItem)
  const cartData = getCartDetailsForAnalytics(cart, false, {})
  const originalProductData = getProductDetailsForAnalytics(product)

  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: 'PDP',
    Category: originalProductData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    'Total Cart Amount': cartData.totalAmount,
    'Total Cart Items': cartData.totalItems,
    'Base Product ID': originalProductData.productID,
    'Base Item Type': originalProductData.itemType,
    'Base Product Type': originalProductData.productType,
    'Base SKU Code': originalProductData.sku,
    'Discount Amount': cartData.discountValue,
  }

  return dataToReturn
}
