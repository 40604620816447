import {
  useUserStore,
  useCommsActiveRoute,
} from '@mosaic-wellness/redux-action-library'
import {useCallback, useEffect, useState} from 'react'
import noop from 'lodash/noop'

import {DIRECT_ASSESSMENT_STEPS} from 'src/constants/DirectAssessment'
import {useDirectAssessment} from 'src/context'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useRedirection} from '..'
import {useConsultTracking} from '../tracking/useConsultTracking'
import {IUseDaStepsProps} from './directAssessment.interface'
import {isNativeConsultVersion} from 'src/utils/isNativeConsultVersion'

function useDaStep({
  questionnaireComplete = false,
  isFutureAppointmentAvailable = false,
  imageUploaded = false,
  pendingOrderAppointmentAvailable = false,
}: IUseDaStepsProps) {
  const [activeStep, setActiveStep] = useState(
    DIRECT_ASSESSMENT_STEPS.QUESTIONNAIRE
  )

  const {onConsultStepViewed} = useConsultTracking({source: 'DA'})
  const [{data}] = useCommsActiveRoute()
  const [{currentConsultVersion = ''}, {handleRedirection = noop}] =
    useRedirection()

  const {
    user: {emailRegistered},
  } = useUserStore()
  const {
    state: {category},
  } = useDirectAssessment()
  const isApp = checkIsApp()

  const updateStep = useCallback((step: string) => {
    setActiveStep(step)
  }, [])

  const handleNextStep = useCallback(
    (params: {daType?: string; daScheduleToken?: string} = {}) => {
      switch (activeStep) {
        case DIRECT_ASSESSMENT_STEPS.QUESTIONNAIRE: {
          switch (true) {
            case questionnaireComplete: {
              setActiveStep(DIRECT_ASSESSMENT_STEPS.SCHEDULE_APPOINTMENT)
              return
            }

            case isFutureAppointmentAvailable &&
              !imageUploaded &&
              category !== 'weight' &&
              category !== 'pcos' &&
              category !== 'performance': {
              setActiveStep(DIRECT_ASSESSMENT_STEPS.IMAGE_UPLOAD)
              return
            }

            case isFutureAppointmentAvailable: {
              setActiveStep(DIRECT_ASSESSMENT_STEPS.SCHEDULE_CONFIRMATION)
              return
            }

            case pendingOrderAppointmentAvailable: {
              setActiveStep(DIRECT_ASSESSMENT_STEPS.SCHEDULE_CONFIRMATION)
              return
            }

            default:
              return
          }
        }

        case DIRECT_ASSESSMENT_STEPS.SCHEDULE_APPOINTMENT: {
          if (
            emailRegistered &&
            category !== 'weight' &&
            category !== 'pcos' &&
            category !== 'performance'
          ) {
            setActiveStep(DIRECT_ASSESSMENT_STEPS.IMAGE_UPLOAD)
            return
          }

          if (!emailRegistered) {
            setActiveStep(DIRECT_ASSESSMENT_STEPS.GET_CUSTOMER_INFO)
            return
          }
          if (isNativeConsultVersion({currentConsultVersion}) && isApp) {
            handleRedirection({
              action: 'APP_NAV',
              link: {
                appNavigationData: {
                  path: 'Consult',
                },
              },
              version: currentConsultVersion,
            })
            return
          }
          if (isApp) {
            window.location.href = data?.link
            return
          }
          setActiveStep(DIRECT_ASSESSMENT_STEPS.SCHEDULE_CONFIRMATION)
          return
        }

        case DIRECT_ASSESSMENT_STEPS.GET_CUSTOMER_INFO: {
          setActiveStep(DIRECT_ASSESSMENT_STEPS.IMAGE_UPLOAD)
          return
        }

        case DIRECT_ASSESSMENT_STEPS.IMAGE_UPLOAD: {
          setActiveStep(DIRECT_ASSESSMENT_STEPS.SCHEDULE_CONFIRMATION)
          return
        }

        default:
          //do nothing
          return
      }
    },
    [
      activeStep,
      questionnaireComplete,
      isFutureAppointmentAvailable,
      imageUploaded,
      category,
      pendingOrderAppointmentAvailable,
      emailRegistered,
      currentConsultVersion,
      isApp,
      handleRedirection,
      data?.link,
    ]
  )

  useEffect(() => {
    if (questionnaireComplete) {
      setActiveStep(DIRECT_ASSESSMENT_STEPS.SCHEDULE_APPOINTMENT)
    }
  }, [questionnaireComplete])

  //trigger analytics for step change
  useEffect(() => {
    if (
      activeStep === DIRECT_ASSESSMENT_STEPS.GET_CUSTOMER_INFO ||
      activeStep === DIRECT_ASSESSMENT_STEPS.IMAGE_UPLOAD ||
      activeStep === DIRECT_ASSESSMENT_STEPS.SCHEDULE_CONFIRMATION
    ) {
      onConsultStepViewed({step: activeStep})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  return {activeStep, handleNextStep, updateStep}
}

export {useDaStep}
