import React from 'react'
import {StyledHybridLayoutItem} from './HybridLayoutItem.styles'
import {HybridLayoutItemProps} from '../HybridLayout.types'
import {LottiePlayer, OptimizedImage} from '@web-components'

const HybridLayoutItem = (props: HybridLayoutItemProps) => {
  const {itemData, onItemPress, height} = props
  const {animationLink, imageLink, objectFit} = itemData || {}

  return (
    <StyledHybridLayoutItem
      onClick={onItemPress(itemData)}
      objectFit={objectFit}
    >
      {animationLink ? (
        <LottiePlayer
          src={animationLink}
          autoplay
          loop
          style={{width: '100%', height: `${height}px`}}
          {...itemData}
        />
      ) : imageLink ? (
        <OptimizedImage
          source={imageLink}
          customClassName="animationOrImage"
          {...itemData}
        />
      ) : null}
    </StyledHybridLayoutItem>
  )
}

export default HybridLayoutItem
