import styled from 'styled-components'

export const AppointmentCardContainer = styled.div`
  border: 1px solid var(--category-primary-bg1-color);
  width: 90%;

  border-radius: 12px;
  margin-top: 20px;

  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media (min-width: 768px) {
    width: 450px;
    border-radius: 20px;
  }

  .appointment_card__header {
    color: var(--category-primary-color);
    display: flex;
    width: 100%;
    height: 60px;
    text-align: center;
    align-items: center;
    padding-left: 10px;

    .appointment_card__headerText {
      font-size: 16px;
      font-weight: var(--brand-font-weight-heavy);
      margin: 0 0 0 5px;
    }

    .doctor_image {
      height: max-content;
      text-align: left;
      margin-top: -22px;

      /* @media (min-width: 768px) {
        margin-top: -40px;
      } */
    }

    @media (min-width: 768px) {
      height: 80px;

      .appointment_card__headerText {
        font-size: 20px;
      }

      .doctor_image {
        margin-top: -42px;
      }
    }
  }

  .ribbon_container {
    display: flex;
    justify-content: space-between;
  }
  .subheader_ribbon-left {
    z-index: 1;
    height: 30px;
    width: 30px;
    border-top-right-radius: 15px;
    margin-top: -30px;
    box-shadow: 0 -15px 0 0 var(--category-primary-color);
    transform: rotate(180deg);
  }
  .subheader_ribbon-right {
    z-index: 1;
    height: 30px;
    width: 30px;
    border-top-left-radius: 15px;
    margin-top: -30px;
    box-shadow: 0 -15px 0 0 var(--category-primary-color);
    transform: rotate(180deg);
  }

  .appointment_card__subHeader {
    height: 23px;
    font-size: 12px;
    font-weight: var(--brand-font-weight-light);
    display: grid;
    place-content: center;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: var(--category-primary-color);
    color: var(--brand-primary-white-color);

    @media (min-width: 768px) {
      font-size: 14px;
      height: 30px;
    }
  }

  .appointment_card__bottomContainer {
    padding: 10px;

    @media (min-width: 768px) {
      padding: 20px;
    }
  }

  .checkbox {
    height: 24px;
    width: 24px;
    border-radius: 10px;
    border: 1px solid var(--brand-primary-teal-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check-icon {
    color: var(--brand-primary-white-color);
    font-size: 11px;
    cursor: pointer;
  }

  &[data-is-selected='true'] {
    .checkbox {
      background-color: var(--brand-primary-teal-color);
      border: 0px;
    }
  }

  &[data-is-selected='false'] {
    .check-icon {
      display: none;
    }
  }

  &[data-is-checkbox='true'] {
    background-color: transparent;
  }

  .appointment_card__LSDContainer {
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--category-primary-bg1-color);
  }

  .appointment_card__LSDTitle {
    font-size: 16px;
    color: var(--category-primary-color);
    font-weight: var(--brand-font-weight-bold);
  }

  .appointment_card__LSDSubtitle {
    font-size: 12px;
    margin-top: 6px;
    font-weight: var(--brand-font-weight-light);
  }

  /* Toggle text styles */

  .toggle-text__container {
    display: flex;
    justify-content: space-between;
  }

  .toggle-button__text {
    font-size: 16px;
    color: var(--category-primary-color);
  }

  &[data-is-open='true'] {
    .appointment_card__scheduleOptions {
      max-height: max-content;
      transition: all 500ms ease-in-out;
    }
  }

  &[data-is-open='false'] {
    .appointment_card__scheduleOptions {
      max-height: 0;
      opacity: 0;

      > * {
        max-height: 0;
      }
    }
  }

  .appointment_card__slotsContainer {
  }

  .drop-down-list {
    position: relative;

    ul {
      width: 90% !important;
      max-height: 170px !important;
    }
  }

  .appointment_card__optionTitle {
    font-size: 14px;
    font-weight: var(--brand-font-weight-400);
    color: var(--brand-primary-grey-color);
    margin-bottom: 2px;
    opacity: 50%;
  }

  .appointment_card__optionTitleAlt {
    font-size: 14px;
    font-weight: var(--brand-font-weight-400);
    color: var(--brand-primary-black-color);
    margin-bottom: 2px;
  }

  @media (min-width: 768px) {
    margin-top: 40px;
    .appointment_card__optionTitle {
      font-size: 18px;
    }

    .appointment_card__optionTitleAlt {
      font-size: 18px;
    }

    .toggle-button__text {
      font-size: 18px;
    }

    .appointment_card__LSDTitle {
      font-size: 20px;
    }
  }
`
