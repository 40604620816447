import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'

import {useGenericActions} from '../../hooks'
import {GenericSlider, useDeviceTypeContext} from '@web-components'

import InfoCard from './InfoCard'
import {StyledInfoCardsSlider} from './InfoCardsSlider.styles'
import {InfoCardsSliderProps} from './InfoCardsSlider.interface'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {ArrowLeftWhite} from '@web-components'
import {ArrowRightWhite} from '@web-components'
import {useCallback} from 'react'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

const InfoCardsSlider = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<InfoCardsSliderProps>) => {
  const {items = [], slidesToShow, desktopSlidesToShow} = widgetData || {}
  const {genericAction} = useGenericActions()

  const {isMobile} = useDeviceTypeContext()

  const handleAction = useCallback(
    (action: GenericAction) => {
      genericAction(interceptAndEnhanceAction(action, id))
    },
    [genericAction, id]
  )
  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledInfoCardsSlider>
        <GenericSlider
          noOfSlides={slidesToShow}
          noOfSlidesDesktop={desktopSlidesToShow}
          aspectRatio={1}
          isShowArrows={!isMobile && desktopSlidesToShow !== items.length}
          arrowPositionY={260}
          arrowGap={1120}
          LeftArrowIcon={() => (
            <ArrowLeftWhite size={80} className="arrow-left-container" />
          )}
          RightArrowIcon={() => (
            <ArrowRightWhite size={80} className="arrow-right-container" />
          )}
        >
          {() => (
            <>
              {items.map((item) => {
                return (
                  <InfoCard
                    key={item.slug}
                    state={{
                      ...item,
                    }}
                    actions={{handleAction}}
                  />
                )
              })}
            </>
          )}
        </GenericSlider>
      </StyledInfoCardsSlider>
    </BaseWidgetComponent>
  )
}

export default InfoCardsSlider
