export const processFloatingIslandNudges = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {type = '', state = '', page = '', link = ''} = data

  return {
    Type: type,
    State: state,
    Page: page,
    URL: window.location.href,
    'Redirect url': link,
  }
}
