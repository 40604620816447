import {useCallback, useMemo, useState} from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {ISocialReviewListProps} from './SocialReviewList.interface'
import {Typography, TypographyVariants} from '@web-components'
import {SocialReviewListContainer} from './SocialReviewList.styles'
import SocialReviewCard from './SocialReviewCard/SocialReviewCard'
import isEmpty from 'lodash/isEmpty'
import {useGenericActions} from '../../hooks'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function SocialReviewList(props: ISocialReviewListProps) {
  const {type, widgetData, header, layout, id} = props
  const {reviews, cta} = widgetData || {}

  const {text = '', action} = cta || {}
  const {genericAction} = useGenericActions()

  const [isSocialReviewExpanded, setIsSocialReviewExpanded] = useState(false)

  const handleCtaClick = useCallback(() => {
    setIsSocialReviewExpanded(true)
    genericAction(interceptAndEnhanceAction(action, id))
  }, [action, genericAction, id])

  const reviewsCards = useMemo(
    () => (isSocialReviewExpanded ? reviews : reviews.slice(0, 1)),
    [isSocialReviewExpanded, reviews]
  )

  if (isEmpty(reviews)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <SocialReviewListContainer>
        {reviewsCards.map((review, index) => (
          <SocialReviewCard review={review} key={index} />
        ))}
        {!isSocialReviewExpanded && (
          <div className="cta-container" onClick={handleCtaClick}>
            <Typography
              variant={TypographyVariants.CTA_LABEL_SMALL}
              customClassName="text"
            >
              {text}
            </Typography>
          </div>
        )}
      </SocialReviewListContainer>
    </BaseWidgetComponent>
  )
}

export default SocialReviewList
