import styled from 'styled-components'

export const AppointmentConfirmedCardContainer = styled.div`
  display: flex;
  justify-content: center;

  .detail-pill {
    width: 100%;
    border-radius: 0px 0px 16px 16px;
    background-color: var(--category-primary-color);
    padding: 12px 20px;
    font-size: 14px;
    font-family: var(--brand-font-weight-bold);
    line-height: 1;
    color: var(--brand-primary-white-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .timer-pill {
    padding: 4px;
    margin-left: 12px;
    border-radius: 6px;
    color: var(--brand-primary-teal-color);
    background-color: var(--brand-primary-white-color);
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 12px;
  }

  .know-more {
    outline: 0;
    border: 0;
    font-family: var(--brand-font-family-text);
    padding: 1px 1px 1px 6px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    line-height: 1;
    color: var(--category-primary-color);
    background-color: var(--brand-primary-white-color);
    margin-left: auto;
  }

  .down-arrow-container {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--category-primary-color);
    margin-left: 6px;

    .down-arrow {
      color: var(--brand-primary-white-color);
      line-height: 1;
    }
  }

  @media screen and (min-width: 768px) {
    width: 450px;

    .detail-pill {
      border-radius: 20px;
      font-size: 16px;
    }
    .know-more {
      font-size: 14px;
      padding: 4px 4px 4px 8px;
    }
    .timer-pill {
      font-size: 14px;
    }
  }
`
