import React from 'react'

const TickIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 10 8" fill="none">
      <path
        d="M1 4.6L3.44898 7L6.08163 4L8.71429 1"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TickIcon
