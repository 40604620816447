import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {IProductsGridProps} from './ProductsGrid.interface'

import {ProductsGridContainer} from './ProductsGrid.styles'
import {ProductCardV2} from '@web-components'
import {useGenericActions} from '../../hooks'

function ProductsGrid(props: IProductsGridProps) {
  const {type, widgetData, header, layout, id} = props || {}
  const {products} = widgetData || {}

  const {genericAction} = useGenericActions()

  if (isEmpty(products)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductsGridContainer>
        {products.map((product) => (
          <ProductCardV2
            key={product.id}
            product={{...product, genericAction}}
          />
        ))}
      </ProductsGridContainer>
    </BaseWidgetComponent>
  )
}

export default ProductsGrid
