import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import {useCallback, useEffect, useMemo} from 'react'

import OptimizedImage from '../OptimizedImage'
import {Typography} from '../TypographyV2'
import {ICartAppointmentCardPseudoRx} from './CartAppointmentCard.interface'
import {CartAppointmentCardWrapper} from './CartAppointmentCard.styles'
import {getCardCopy} from './CartAppointmentCard.utils'
import ClockIcon from './ClockIcon'
import LanguageIcon from './LanguageIcon'

const CartAppointmentCardPseudoRx: React.FC<ICartAppointmentCardPseudoRx> = (
  props
) => {
  const {state, actions} = props || {}

  const {
    prescriptionAvailable = false,
    scheduledAppointAvailable = false,
    iaAvailable = false,
    prescriptionNeededReason = '',
    bookSlotsData = {},
    optInForConsult = false,
    appointmentDetails = {},
    bookAppointmentCardCopy = {},
    rxConsultType = '',
    isPseudoRxSlotSet = {},
    languageToSetForInitialOptIn = '',
    isPseudoRxFlow = false,
  } = state || {}

  const {
    toggleScheduleFormOpen = noop,
    toggleOptInForConsult = noop,
    updateSlotsData = noop,
    trackAppointmentCardShown = noop,
    onNotFreeRightNowClick = noop,
    onSwitchToIa = noop,
  } = actions

  const {editIconLink = ''} = bookAppointmentCardCopy

  const {
    date = '',
    slot = '',
    immediate = false,
    language = '',
    selectedLanguageFallback = '',
  } = bookSlotsData || {}

  //calculate if IA is available and is also selected
  const iaSelected: boolean = useMemo(
    () => iaAvailable && !!immediate,
    [iaAvailable, immediate]
  )

  //calculate if schedule is available and selected
  const scheduleSelected = useMemo(() => !!date && !!slot, [date, slot])

  //calculate if schedule is available and selected, and IA is available
  const scheduleSelectedAndIaAvailable = useMemo(
    () => scheduleSelected && !immediate && iaAvailable,
    [iaAvailable, immediate, scheduleSelected]
  )

  const cardCopy = useMemo(
    () =>
      getCardCopy({
        iaSelected,
        scheduleSelected,
        bookAppointmentCardCopy,
        isPseudoRx: isPseudoRxFlow,
        rxConsultType,
      }),
    [
      iaSelected,
      scheduleSelected,
      bookAppointmentCardCopy,
      isPseudoRxFlow,
      rxConsultType,
    ]
  )

  const optedInAndSlotSelected = useMemo(
    () => !isEmpty(bookSlotsData) && optInForConsult,
    [bookSlotsData, optInForConsult]
  )

  const handleCheckboxClicked = useCallback(() => {
    toggleOptInForConsult()
  }, [toggleOptInForConsult])

  const handleNotFreeRightNowClicked = useCallback(() => {
    toggleScheduleFormOpen()
    onNotFreeRightNowClick({slotAvailable: !isEmpty(bookSlotsData)})
  }, [bookSlotsData, onNotFreeRightNowClick, toggleScheduleFormOpen])

  const [showSwitchCta, switchLabelText, switchCtaText, switchCtaCallback] =
    useMemo(() => {
      if (iaSelected) {
        return [
          true,
          'Not free right now',
          'Book For later >',
          handleNotFreeRightNowClicked,
        ]
      }

      if (scheduleSelectedAndIaAvailable) {
        return [true, 'Talk to doctor now?', 'Switch Now >', onSwitchToIa]
      }

      return [false, '', '', noop]
    }, [
      handleNotFreeRightNowClicked,
      iaSelected,
      onSwitchToIa,
      scheduleSelectedAndIaAvailable,
    ])

  useEffect(() => {
    if (
      optInForConsult &&
      !isEmpty(appointmentDetails) &&
      !isPseudoRxSlotSet?.current
    ) {
      isPseudoRxSlotSet.current = true
      updateSlotsData({
        immediate: iaAvailable ? true : false,
        date: appointmentDetails.date,
        slot: appointmentDetails.time,
        language: languageToSetForInitialOptIn,
      })
    }
  }, [
    appointmentDetails,
    bookSlotsData,
    iaAvailable,
    isPseudoRxSlotSet,
    optInForConsult,
    updateSlotsData,
    languageToSetForInitialOptIn,
  ])

  useEffect(() => {
    trackAppointmentCardShown({
      type: 'Pseudo Rx',
      reason: prescriptionNeededReason,
      rxProduct: false,
      rxExists: prescriptionAvailable,
      appointmentExists: scheduledAppointAvailable,
      iaAvailable: iaAvailable,
    })
  }, [
    trackAppointmentCardShown,
    prescriptionNeededReason,
    prescriptionAvailable,
    scheduledAppointAvailable,
    iaAvailable,
  ])

  if (prescriptionAvailable || scheduledAppointAvailable) {
    return null
  }

  return (
    <CartAppointmentCardWrapper data-is-pseudoRx={true}>
      <div className="card-container">
        <div className="callout-chip">
          <Typography variant="x-small" customClassName="callout-text">
            {cardCopy?.calloutText}
          </Typography>
        </div>

        <div className="card-heading-container">
          <Typography variant="subheading-bold">
            {optedInAndSlotSelected ? cardCopy?.title : cardCopy?.optInTitle}
          </Typography>

          {optedInAndSlotSelected && (
            <button className="edit-icon" onClick={toggleScheduleFormOpen}>
              <OptimizedImage
                source={editIconLink}
                aspectWidth="10"
                aspectHeight="10"
                desktopViewWidth="FULL"
                mobileViewWidth="FULL"
                altText="edit icon"
                loading="lazy"
              />
            </button>
          )}
        </div>

        {!optedInAndSlotSelected && (
          <label htmlFor="checkboxinput" className="label-checkbox">
            <input
              onChange={handleCheckboxClicked}
              type="checkbox"
              id="checkboxinput"
              className="checkbox"
              checked={optInForConsult}
            />
            <div className="checkbox-box">
              <div className="check-mark"></div>
            </div>
            <Typography variant="tag-regular">
              {cardCopy?.checkboxText}
            </Typography>
          </label>
        )}

        {optedInAndSlotSelected && (
          <div className="appointment-details-container">
            <div className="appointment-details-section">
              <LanguageIcon />
              <Typography
                variant="tag-regular"
                customClassName="appointment-details-text"
              >
                {language || selectedLanguageFallback}
              </Typography>
            </div>
            <div className="appointment-details-section">
              <ClockIcon />
              <Typography
                variant="tag-regular"
                customClassName="appointment-details-text"
              >
                {iaSelected
                  ? cardCopy?.consultationStartText
                  : `${date}, ${slot}`}
              </Typography>
            </div>
          </div>
        )}
      </div>

      {showSwitchCta && (
        <div className="not-free-container">
          <Typography variant="tag-regular">{switchLabelText}</Typography>
          <button className="not-free-button" onClick={switchCtaCallback}>
            <Typography customClassName="not-free-cta" variant="cta-label-sm">
              {switchCtaText}
            </Typography>
          </button>
        </div>
      )}
    </CartAppointmentCardWrapper>
  )
}

export default CartAppointmentCardPseudoRx
