import {LottiePlayer, OptimizedImage} from '@web-components'
import {CarouselContainer, Container} from './Carousel.styles'
import {ICarousel, ICarouselProps} from './Carousel.types'

const Carousel = (props: ICarouselProps) => {
  const {items, onCarouselItemPress} = props || {}

  const renderCarousel = (carousel: ICarousel, index: number) => {
    const {imageLink = '', animationLink = ''} = carousel || {}
    return (
      <Container
        onClick={onCarouselItemPress(carousel)}
        key={`carousel-${index}`}
      >
        {imageLink && (
          <OptimizedImage
            source={imageLink}
            customClassName="imageorAnimation"
          />
        )}
        {animationLink && (
          //@ts-ignore
          <LottiePlayer
            src={animationLink}
            autoplay
            loop
            style={{width: '100%', height: '100%'}}
          />
        )}
      </Container>
    )
  }

  return <CarouselContainer>{items.map(renderCarousel)}</CarouselContainer>
}

export default Carousel
