import {IconWithLabelSliderContainer} from './IconWithLabelSlider.styles'
import {IIconWithLabelSliderProps} from './IconWithLabelSlider.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import {isEmpty} from 'lodash'
import {GenericSlider, OptimizedImage, Typography} from '@web-components'

function IconWithLabelSlider(props: IIconWithLabelSliderProps) {
  const {id = '', type = '', widgetData, header, layout} = props
  const {items = [], sliderConfig} = widgetData || {}
  const {slidesToShow = 1} = sliderConfig || {}

  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <IconWithLabelSliderContainer>
        <GenericSlider noOfSlides={slidesToShow} aspectRatio={1}>
          {() => {
            return items.map((item) => (
              <div className="item-card" key={item.label}>
                <OptimizedImage
                  source={item?.iconUrl}
                  aspectHeight="40"
                  aspectWidth="40"
                  customClassName="icon-image"
                />
                <Typography variant="body-base-light" customClassName="label">
                  {item.label}
                </Typography>
              </div>
            ))
          }}
        </GenericSlider>
      </IconWithLabelSliderContainer>
    </BaseWidgetComponent>
  )
}

export default IconWithLabelSlider
