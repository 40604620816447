import styled, {keyframes} from 'styled-components'

export const ImageCarouselWrapper = styled.div`
   {
    position: sticky;
    width: 100%;
    top: 120px;
  }
  .image {
    width: 100%;
    aspect-ratio: 4/3;
    .video-embed {
      @media (max-width: 768px) {
        border-radius: 0px !important;
      }
    }
    .inner-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @media (min-width: 768px) {
        border-radius: 20px;
      }
    }
    .react-player {
      width: 100%;
      height: 100%;
      overflow: hidden;
      @media (min-width: 768px) {
        border-radius: 20px;
      }
    }

    flex-shrink: 0;
    scroll-snap-align: start;
  }

  .nav-button-side {
    position: absolute;
    background-color: transparent;
    border: none;
    padding: 5px;
    z-index: 1;

    &.down-button {
      left: -5%;
      bottom: 0;
      transform: translateX(-50%);
      margin-bottom: 10px;
    }

    &.up-button {
      left: -5%;
      margin-top: 10px;
      transform: translateX(-50%);
    }
  }
  .nav-button {
    position: absolute;
    top: 50%;
    background-color: transparent;
    border: none;
    padding: 5px;
    z-index: 1;

    &.left {
      left: 0;
      transform: rotate(-90deg);
      margin-left: 10px;
    }
    &.right {
      right: 0;
      margin-right: 10px;
    }
  }

  .thumbnail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .thumbnail-wrapper {
    width: 80px;
    height: 80px;
    top: 0;
    margin-top: 7px;
  }
  .image-container {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    white-space: nowrap;
    position: relative;
    margin-left: 90px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .image-container-mobile {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    white-space: nowrap;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .carousel-mobile-nav {
    outline: none;
    border: none;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    padding: 50px 10px;
    z-index: 4;
    background-color: transparent;
    box-shadow: none;
    &:hover,
    &:active {
      box-shadow: none !important;
    }

    span {
      height: 30px;
      width: 30px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--brand-primary-white-color);
      border-radius: 50%;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.08);
    }
  }
  .left {
    left: 0;

    span {
      transform: rotate(180deg);
    }
  }

  .right {
    right: 0;
  }
  .oos-banner {
    font-size: 16px;
    font-weight: var(--brand-font-weight-medium);
    color: var(--brand-primary-black-color);
    background-color: var(--brand-primary-white-color);
    width: max-content;
    border-radius: 16px;
    padding: 10px 16px;
  }
  .oos-overlay {
    background: var(--brand-primary-black-color);
    opacity: 0.4;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 5;
  }
  .banner-container {
    height: 100%;
    width: 100%;
    z-index: 6;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const StyledCarouselDots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  .bullet,
  .video {
    display: inline-block;
    width: 6px;
    height: 6px;

    &[data-isactive='true'] {
      width: 8px;
      height: 8px;
    }
  }

  .bullet {
    border-radius: 50%;
    background-color: #6c6c6c;

    &[data-isactive='true'] {
      background-color: var(--category-primary-color);
    }

    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  .video {
    display: inline-block;
    margin-right: 6px;
  }
`
export const GreenBorderThumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 0.3s, border-color 0.3s;
  border: 2px solid transparent;

  &:hover {
    border-color: var(--category-primary-color);
  }

  ${({active}) =>
    active &&
    `
    border-color: var(--category-primary-color);
  `}
`

export const StyledMediaCarouselContainer = styled.div`
  height: 48px;
  bottom: 0px;
  padding: 20px;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  z-index: 3;

  background-color: var(--category-primary-color);
  .media-ribbon-label {
    color: var(--brand-primary-white-color);
    font-size: 16px;
    font-weight: 500;
  }
`
export const placeholderShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0; 
  }
`
export const Box = styled.div`
  height: 48px;
  width: 100%;
`

export const Shimmer = styled.div`
  background-image: linear-gradient(
    to right,
    rgba(246, 247, 248, 0.1) 0%,
    rgba(237, 238, 241, 0.2) 20%,
    rgba(246, 247, 248, 0.9) 40%,
    rgba(237, 238, 241, 0.4) 100%
  );
  filter: blur(25px);

  background-repeat: no-repeat;
  background-size: 100px 100%;
  display: inline-block;
  position: relative;

  animation-duration: 2.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderShimmer};
  animation-timing-function: linear;
`

export const ShimmerWrapper = styled.div`
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`
