import {PushAnalyticsFunction} from '@mosaic-wellness/fe-types'

let analytics: PushAnalyticsFunction = (error, meta) => {
  console.log(error, meta)
}

const initializeAnalytics = (pushAnalytics?: PushAnalyticsFunction) => {
  if (pushAnalytics) {
    analytics = pushAnalytics
  }
}

export {analytics, initializeAnalytics}
