import styled from 'styled-components'

export const RescheduleAppointmentCardWrapper = styled.div`
  border: 1px solid var(--category-primary-bg1-color);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  background: var(--brand-primary-white-color);
  width: 90%;

  .loading-container {
    width: 80%;
  }

  .appointment-id {
    text-transform: uppercase;
  }
  .appointment-card__heading-container {
    padding: 16px 20px 12px 20px;
    display: flex;
    justify-content: center;
  }
  .appointment-card__title {
    font-weight: var(--brand-font-weight-700);
    font-size: 16px;
    line-height: 120%;
    color: var(--category-primary-color);
  }
  .appointment-card__sub-title {
    font-weight: var(--brand-font-weight-light);
    font-size: 12px;
    line-height: 120%;
    color: var(--brand-primary-grey-color);
    opacity: 0.6;
  }
  .appointment-card__line {
    opacity: 0.1;
    border: 1px dashed var(--brand-primary-grey-color);
  }
  .appointment-card__row {
    display: grid;
    justify-content: space-around;
    padding: 12px 20px 12px 20px;
    grid-template-columns: 1fr 1fr;
    row-gap: 12px;
  }
  .appointment-card__col-heading {
    font-weight: var(--brand-font-weight-light);
    font-size: 14px;
    line-height: 120%;
    color: var(--brand-primary-grey-color);
    opacity: 0.6;
    margin-bottom: 6px;
  }
  .appointment-card__col-data {
    font-weight: var(--brand-font-weight-medium);
    font-size: 14px;
    line-height: 120%;
  }
  .appointment-card__button-container {
    padding: 0px 10px 12px 10px;
    display: flex;
    gap: 8px;
  }
  .appointment-card__button-cancel {
    padding: 13px;
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: var(--brand-primary-red-color);
    border: 1px solid var(--brand-primary-red-color);
    border-radius: 12px;
    width: 30%;
    background-color: transparent;
  }
  .appointment-card__button-reschedule {
    padding: 13px;
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: var(--brand-primary-white-color);
    background: var(--category-primary-color);
    border-radius: 12px;
    border: none;
    width: 70%;
  }
  @media screen and (min-width: 768px) {
    width: 450px;
    .appointment-card__heading-container {
      padding: 24px 24px 14px 24px;
    }
    .appointment-card__title {
      font-size: 20px;
    }
    .appointment-card__sub-title {
      font-size: 16px;
    }
    .appointment-card__line {
      border: 1.5px dashed var(--brand-primary-grey-color);
    }
    .appointment-card__row {
      padding: 14px 24px 14px 24px;
      row-gap: 16px;
    }
    .appointment-card__col-heading {
      font-size: 16px;
      line-height: 16px;
    }
    .appointment-card__col-data {
      font-size: 18px;
      line-height: 18px;
    }
    .appointment-card__button-container {
      padding: 10px 16px 16px 16px;
      gap: 8px;
    }
    .appointment-card__button-cancel {
      padding: 17px;
    }
    .appointment-card__button-reschedule {
      padding: 17px;
    }
  }

  &[data-is-cancel-button-visible='true'] {
    .appointment-card__button-reschedule {
      width: 100%;
    }
  }
`
