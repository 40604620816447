import React from 'react'

import Responsive from '../../../HOC/Responsive'
import OptimizedImage from '../../../common/OptimizedImage'
import Typography from '../../../common/Typography/Typography'
import {TrustMarkersWrapper} from './TrustMarkers.styles'

function TrustMarkers(props) {
  const {
    sectionName = 'trustMarkers',
    cards = [],
    bgColor = '',
    dividerBorderColor = '',
    borderColor = '',
    className = '',
  } = props.state || {}
  const {isMobile = ''} = props

  if (!cards || !cards.length) {
    return null
  }
  return (
    <TrustMarkersWrapper
      data-section={sectionName}
      bgColor={bgColor}
      dividerBorderColor={dividerBorderColor}
      borderColor={borderColor}
      className={className}
    >
      <div className="trustMarkers-container">
        {cards.map((card) => {
          const {
            label = '',
            desktopImg = '',
            mobileImg = '',
            aspectHeight = '',
            aspectWidth = '',
          } = card || {}
          const displayImage = isMobile ? mobileImg : desktopImg

          return (
            <div className="each-trustmarker" key={label}>
              <div className="each-trustmarker-image-outer-container">
                <div className="each-trustmarker-image-inner-container">
                  <OptimizedImage
                    source={displayImage}
                    aspectWidth={aspectWidth}
                    aspectHeight={aspectHeight}
                    desktopViewWidth="THIRD"
                    mobileViewWidth="THIRD"
                    altText="trust-marker"
                    loading="lazy"
                  />
                </div>
              </div>
              <Typography
                text={label}
                variant="tag-regular"
                customClassName="each-trustmarker-label"
              />
            </div>
          )
        })}
      </div>
    </TrustMarkersWrapper>
  )
}

export default React.memo(Responsive(TrustMarkers))
