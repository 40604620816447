import React from 'react'
import {CredBannerContainer} from './CredBanner.styles'
import {
  OptimizedImage,
  RightArrowCred,
  Typography,
  TypographyVariants,
} from '@web-components'
import {ICredBannerProps} from './CredBanner.types'

import useCredBanner from './useCredBanner'
import CredClaimSheet from './CredClaimSheet/CredClaimSheet'
import {BaseWidgetComponent} from '../../BaseWidget'

const CredBanner = (props: any) => {
  const {type, widgetData, header, layout, id} = props || {}
  const {credData, showClaimSheet, handleShowClaimSheet} =
    useCredBanner(widgetData)

  const {
    banner = {},
    availableCredCoins = '',
    claimSheet = {},
    isEligible = false,
  } = credData || {}

  const {
    bannerImage = '',
    coinImage = '',
    cta = {},
    heading = '',
    leftText = '',
    rightText = '',
    credLogo = '',
  } = banner as ICredBannerProps

  if (!isEligible) return null

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <CredBannerContainer bannerUrl={bannerImage}>
        <div className="banner-content-wrapper">
          <OptimizedImage source={credLogo} customClassName="cred-logo" />

          <div className="usage-wrapper">
            <div className="tag-container">
              {leftText && (
                <Typography
                  variant={TypographyVariants.TAG_REGULAR}
                  customClassName="use-text"
                >
                  {leftText}
                </Typography>
              )}
              <div className="discount-container">
                <Typography variant={TypographyVariants.TAG_BOLD}>
                  {availableCredCoins}
                </Typography>
                <OptimizedImage
                  source={coinImage}
                  customClassName="coin-image"
                />
              </div>
              {rightText && (
                <Typography
                  variant={TypographyVariants.TAG_REGULAR}
                  customClassName="use-text"
                >
                  {rightText}
                </Typography>
              )}
            </div>
          </div>
          <Typography variant={TypographyVariants.HEADING_SMALL_BOLD}>
            {heading}
          </Typography>

          <button className="cta-button" onClick={handleShowClaimSheet}>
            <Typography
              variant={TypographyVariants.X_SMALL}
              customClassName="button-text"
            >
              {cta?.label}
            </Typography>
            <RightArrowCred />
          </button>
        </div>
      </CredBannerContainer>
      <CredClaimSheet
        state={{
          showClaimSheet,
          claimSheetData: claimSheet,
          availableCoins: availableCredCoins,
        }}
        action={{handleShowClaimSheet}}
      />
    </BaseWidgetComponent>
  )
}

export default CredBanner
