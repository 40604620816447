import styled from 'styled-components'

interface ProductCardGridWrapperProps {
  columns: number
}
export const ProductCardGridWrapper = styled.div<ProductCardGridWrapperProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: var(--dls-size-12);
  .product-card {
    border: 1px solid var(--dls-divider-light-color);
    border-radius: var(--dls-size-16);
    overflow: hidden;
  }
  @media screen and (min-width: 768px) {
    .product-card {
      border: none;
    }
  }
`
