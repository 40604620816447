import styled from 'styled-components'

export const CustomerTestimonialCardContainer = styled.div`
  padding: 8px;
  border-radius: 12px;
  border: 1px solid var(--brand-primary-light-grey-color);
  display: flex;
  width: 272px;
  background-color: #fafaf6;
  flex-shrink: 0;
  align-items: center;

  .customer-image-container {
    max-width: 60px;
    width: 100%;
    margin-right: 8px;
    border-radius: 8px;
    overflow: hidden;
  }

  .customer-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .testimonial {
    font-weight: var(--brand-font-weight-light);
    font-size: 14px;
    margin: 0;
    line-height: 120%;
    height: 67.2px;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .name-and-rating {
    display: flex;
  }

  .name-container {
    display: flex;
    flex-shrink: 0;
    margin-right: 8px;
    align-items: center;
  }

  .name,
  .rating {
    font-weight: var(--brand-font-weight-light);
    font-size: 14px;
    line-height: 1;
    margin-right: 4px;
  }

  .verified-icon {
    height: 16px;
    width: 16px;
    object-fit: cover;
  }

  .rating-container {
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }

  .star-icon {
    height: 18px;
    width: 18px;
    object-fit: cover;
  }
`
