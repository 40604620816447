export const getMappedCategory: (category: string) => string = (
  category: string
) => {
  switch (category?.toLowerCase()) {
    case 'hair':
      return 'is_hc'
    case 'performance':
      return 'is_sh'
    case 'hygiene':
      return 'body-care'
    case 'bodycare':
      return 'body-care'
    default:
      return category
  }
}
