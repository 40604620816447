import isEmpty from 'lodash/isEmpty'

import {
  useConfigStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import {useCallback} from 'react'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {DIRECT_ASSESSMENT_STEPS} from 'src/constants/DirectAssessment'
import {useDirectAssessment} from 'src/context'
import mixpanel from 'mixpanel-browser'
import {getCategoryFromAppointmentCategory} from 'src/utils'

const eventMapForConsultSteps: {[key: string]: string} = {
  questionnaire: EVENT_MAP.VIEW_DA,
  questions: EVENT_MAP.VIEW_QUESTIONS,
  scheduleAppointment: EVENT_MAP.VIEW_SCHEDULING_SCREEN,
  imageUpload: EVENT_MAP.VIEW_IMAGE_UPLOAD,
  getCustomerInfo: EVENT_MAP.VIEW_GET_CUSTOMER_INFO_SCREEN,
  scheduleConfirmation: EVENT_MAP.VIEW_APPOINTMENT_SUMMARY_SCREEN,
}

function useConsultTracking({source = 'DA', entryPoint = ''}) {
  const {analytics} = useConfigStore()
  const {
    state: {category, appointmentDetails},
  } = useDirectAssessment()

  const onConsultStepViewed = useCallback(
    ({
      step = '',
      question = '',
      answer = '',
      slot1 = '',
      slot2 = '',
      slot3 = '',
      iaAvailable = false,
    }) => {
      analytics.trigger(eventMapForConsultSteps[step], {
        entryPoint: 'Header',
        category,
        question,
        answer,
        source,
        slot1,
        slot2,
        slot3,
        iaAvailable,
        iaBooked: appointmentDetails?.type === 'IA',
        language: appointmentDetails?.language,
        appointmentId: appointmentDetails?.id,
      })
    },
    [
      analytics,
      appointmentDetails?.language,
      appointmentDetails?.type,
      appointmentDetails?.id,
      category,
      source,
    ]
  )

  const onCategorySubmit = useCallback(
    (category: string) => {
      analytics.trigger(EVENT_MAP.CATEGORY_SUBMIT, {category})
    },
    [analytics]
  )

  const trackScheduleScreen = useCallback(
    ({
      type = '',
      toggleOn = false,
      language = '',
      slot = '',
      iaBooked = false,
      phoneNumber = '',
      slot1 = '',
      slot2 = '',
      slot3 = '',
      iaAvailable = false,
      appointmentId = '',
      diagnosisData,
      imageAvailable = false,
    }) => {
      switch (type) {
        case 'pageView': {
          onConsultStepViewed({
            step: DIRECT_ASSESSMENT_STEPS.SCHEDULE_APPOINTMENT,
            slot1,
            slot2,
            slot3,
            iaAvailable,
          })
          break
        }

        case 'toggle': {
          const event = toggleOn
            ? EVENT_MAP.NOT_FREE_RIGHT_NOW_TOGGLED_ON
            : EVENT_MAP.NOTE_FREE_RIGHT_NOW_TOGGLED_OFF

          analytics.trigger(event, {category, source})
          break
        }

        case 'languageChanged': {
          analytics.trigger(EVENT_MAP.LANGUAGE_CHANGED_IN_DROPDOWN, {
            category,
            source,
            language,
          })
          break
        }

        case 'submit': {
          analytics.trigger(EVENT_MAP.SCHEDULE_SCREEN_CONFIRMED_CLICKED, {
            category,
            slot,
            iaBooked,
            language,
          })
          break
        }

        case 'booked': {
          analytics.trigger(EVENT_MAP.DA_APPOINTMENT_BOOKED, {
            category,
            iaBooked,
            slot,
            language,
            phoneNumber,
            appointmentId,
            diagnosisData,
            imageAvailable,
          })
          break
        }

        default:
          break
      }
    },
    [onConsultStepViewed, analytics, category, source]
  )

  const onGetCustomerInfoSubmit = useCallback(
    ({name = '', email = ''}) => {
      analytics.trigger(EVENT_MAP.GET_CUSTOMER_INFO_SUBMIT, {
        name,
        email,
        category,
        language: appointmentDetails?.language,
        iaBooked: appointmentDetails?.type === 'IA',
        appointmentId: appointmentDetails?.id,
      })
    },
    [
      analytics,
      appointmentDetails?.language,
      appointmentDetails?.type,
      category,
      appointmentDetails?.id,
    ]
  )

  const trackImageUpload = useCallback(
    ({type = ''}) => {
      const iaBooked = appointmentDetails?.type === 'IA'

      switch (type) {
        case 'skipUpload': {
          analytics.trigger(EVENT_MAP.IMAGE_UPLOAD_SKIPPED, {
            category,
            source,
            iaBooked,
            appointmentId: appointmentDetails?.id,
          })
          break
        }

        case 'uploaded': {
          analytics.trigger(EVENT_MAP.IMAGE_UPLOADED, {
            category,
            source,
            iaBooked,
            appointmentId: appointmentDetails?.id,
          })
          break
        }

        default:
          break
      }
    },
    [
      analytics,
      appointmentDetails?.type,
      category,
      source,
      appointmentDetails?.id,
    ]
  )

  const trackSuccessScreen = useCallback(
    ({type = ''}) => {
      const iaBooked = appointmentDetails?.type === 'IA'
      const language = appointmentDetails?.language || ''
      switch (type) {
        case 'topSheetViewed': {
          analytics.trigger(EVENT_MAP.VIEW_APPOINTMENT_SUMMARY_TOP_SHEET, {
            category,
            source,
            iaBooked,
            language,
          })
          break
        }

        case 'switchToIa': {
          analytics.trigger(EVENT_MAP.S_TO_IA_SWITCH, {
            category,
            source,
            iaBooked,
            language,
          })
          break
        }

        case 'downloadApp': {
          analytics.trigger(EVENT_MAP.DA_DOWNLOAD_APP_CLICKED)
          break
        }

        case 'editAppointment': {
          analytics.trigger(EVENT_MAP.DA_EDIT_APPOINTMENT_CLICKED)
          break
        }

        case 'reschedule': {
          analytics.trigger(EVENT_MAP.DA_RESCHEDULE_CLICKED)
          break
        }

        case 'cancel': {
          analytics.trigger(EVENT_MAP.DA_APPOINTMENT_CANCELLED)
          break
        }

        default:
          break
      }
    },
    [
      analytics,
      appointmentDetails?.language,
      appointmentDetails?.type,
      category,
      source,
    ]
  )

  const daExitClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.DA_EXIT_CLICKED, {
      category,
      url: window.location.href,
      appointmentBooked: !isEmpty(appointmentDetails),
    })
  }, [analytics, category, appointmentDetails])

  const daExitConfirmed = useCallback(() => {
    analytics.trigger(EVENT_MAP.DA_EXIT_CONFIRMED, {
      category,
      url: window.location.href,
      appointmentBooked: !isEmpty(appointmentDetails),
    })
  }, [analytics, category, appointmentDetails])

  const daExitCancelled = useCallback(() => {
    analytics.trigger(EVENT_MAP.DA_EXIT_CANCELLED, {
      category,
      url: window.location.href,
      appointmentBooked: !isEmpty(appointmentDetails),
    })
  }, [analytics, category, appointmentDetails])

  const daBackClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.DA_BACK_CLICKED, {
      category,
      url: window.location.href,
      appointmentBooked: !isEmpty(appointmentDetails),
    })
  }, [analytics, category, appointmentDetails])

  const onAppointmentConfirmSecondaryClicked = useCallback(
    (conformationData) => {
      analytics.trigger(EVENT_MAP.APPOINTMENT_CONFIRM_IMAGE_UPLOAD, {
        appointmentId: conformationData?.appointmentDetails?.appointmentId,
        category: conformationData?.appointmentDetails?.category,
      })
    },
    [analytics]
  )

  const onAppointmentConfirmPrimaryClicked = useCallback(
    (conformationData) => {
      analytics.trigger(EVENT_MAP.APPOINTMENT_CONFIRM_CONTINUE_SHOPPING, {
        appointmentId: conformationData?.appointmentDetails?.appointmentId,
        category: conformationData?.appointmentDetails?.category,
      })
    },
    [analytics]
  )

  const onAppointmentConfirmedViewed = useCallback(
    (conformationData) => {
      analytics.trigger(EVENT_MAP.APPOINTMENT_CONFIRM_VIEWED, {
        appointmentId: conformationData?.appointmentDetails?.appointmentId,
        category: conformationData?.appointmentDetails?.category,
      })
    },
    [analytics]
  )

  const setAnalyticsUserDetails = useCallback((data) => {
    mixpanel.people.set({email: data.updatedEmail})
    mixpanel.people.set({firstName: data.firstName})
    mixpanel.people.set({lastName: data.lastName})
  }, [])

  const pushFutureAppointmentDataToDataLayer = useCallback(
    (futureAppointmentData) => {
      analytics.push({futureAppointmentData})
    },
    [analytics]
  )
  const onSwipeAppointmentSubmitted = useCallback(
    ({productName, productId, category, iaBooked, slotSelected}) => {
      analytics.trigger(EVENT_MAP.SWIPE_APPOINTMENT_SUBMIT, {
        entryPoint,
        productName,
        productId,
        category,
        iaBooked,
        slotSelected,
      })
    },
    [analytics, entryPoint]
  )
  const onSwipeAppointmentBooked = useCallback(
    ({
      productName,
      productId,
      category,
      iaBooked,
      slotSelected,
      appointmentID,
    }) => {
      analytics.trigger(EVENT_MAP.SWIPE_APPOINTMENT_BOOKED, {
        entryPoint,
        productName,
        productId,
        category,
        iaBooked,
        slotSelected,
        appointmentID,
      })
    },
    [analytics, entryPoint]
  )
  const onSwipeAppointmentError = useCallback(
    ({
      productName,
      productId,
      category,
      iaBooked,
      slotSelected,
      errorResponse,
    }) => {
      analytics.trigger(EVENT_MAP.SWIPE_APPOINTMENT_BOOKING_ERROR, {
        entryPoint,
        productName,
        productId,
        category,
        iaBooked,
        slotSelected,
        errorResponse,
      })
    },
    [analytics, entryPoint]
  )
  const onOpenSwipeToBook = useCallback(
    ({productName, productId, category, iaAvailable}) => {
      analytics.trigger(EVENT_MAP.SWIPE_TO_BOOK, {
        entryPoint,
        productName,
        productId,
        category,
        iaAvailable,
      })
    },
    [analytics, entryPoint]
  )

  const onPendingOrderAppointmentSeen = useCallback(
    ({appointmentId, category, status}) => {
      analytics.trigger(EVENT_MAP.PENDING_ORDER_APPOINTMENT, {
        appointmentId,
        category,
        status,
      })
    },
    [analytics]
  )
  const onPendingOrderRescheduleSeen = useCallback(
    ({appointmentId, category, status}) => {
      analytics.trigger(EVENT_MAP.PENDING_ORDER_RESCHEDULE_SEEN, {
        appointmentId,
        category,
        status,
      })
    },
    [analytics]
  )

  const onPendingOrderRescheduleSubmit = useCallback(
    ({category, language, ia, slot}) => {
      analytics.trigger(EVENT_MAP.PENDING_ORDER_RESCHEDULE_SUBMIT, {
        category,
        language,
        ia,
        slot,
      })
    },
    [analytics]
  )
  const onPendingOrderRescheduleConfirmed = useCallback(
    ({category, language, ia, slot}) => {
      analytics.trigger(EVENT_MAP.PENDING_ORDER_RESCHEDULE_CONFIRMED, {
        category: getCategoryFromAppointmentCategory(category),
        language,
        ia,
        slot,
      })
    },
    [analytics]
  )

  return {
    onConsultStepViewed,
    onCategorySubmit,
    trackScheduleScreen,
    onGetCustomerInfoSubmit,
    trackImageUpload,
    trackSuccessScreen,
    onAppointmentConfirmSecondaryClicked,
    onAppointmentConfirmPrimaryClicked,
    onAppointmentConfirmedViewed,
    daExitClicked,
    daExitConfirmed,
    daExitCancelled,
    daBackClicked,
    setAnalyticsUserDetails,
    pushFutureAppointmentDataToDataLayer,
    onSwipeAppointmentSubmitted,
    onSwipeAppointmentBooked,
    onSwipeAppointmentError,
    onOpenSwipeToBook,
    onPendingOrderAppointmentSeen,
    onPendingOrderRescheduleConfirmed,
    onPendingOrderRescheduleSubmit,
    onPendingOrderRescheduleSeen,
  }
}

export {useConsultTracking}
