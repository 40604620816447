import styled from 'styled-components'

export const RatingBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .rating_number_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    min-width: 6px;
    height: 30px;
    @media (max-width: 768px) {
      height: 22px;
    }
  }
  .rating_number {
    width: 12px;
    text-align: center;
    @media (max-width: 768px) {
      width: 10px;
    }
  }
  .leading_text {
    color: black;
    margin-right: 2px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  .star {
    margin-right: -8px;
    margin-bottom: -1px;
    margin-left: 3px;
    @media (max-width: 768px) {
      margin-left: 2px;
      margin-bottom: -4px;
    }
  }
  .strip {
    border-radius: 4px;
    background-color: #e8f4ff;
    height: 6px;
    flex-grow: 1;
    @media (max-width: 768px) {
      height: 5px;
      max-width: 128px;
    }
  }
  .filled_strip {
    width: ${(props) => `${props.figure}%`};
    border-radius: 4px;
    background-color: ${(props) => props.fillerColor};
    height: 6px;
    @media (max-width: 768px) {
      height: 5px;
    }
  }
  .trailing_text_container {
    max-width: 8px;
    margin-left: 10px;
  }
  .trailing_text {
    color: #6c6c6c;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    @media (max-width: 768px) {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
    }
  }
`
