import React from 'react'

import PlayCarouselDot from './Icons/Play'
import {StyledCarouselDots} from './ImageCarousalnew.styles'

const carouselDotTypes = {
  IMAGE: 'bullet',
  VIDEO: 'video',
}

function CarouselDots({items, activeSlideIndex}) {
  return (
    <>
      {items?.length >= 2 && (
        <StyledCarouselDots className="bullets">
          {items.map((item, index) => {
            const isActive = index === activeSlideIndex
            if (item.type === 'video') {
              const size = isActive ? 8 : 6
              return (
                <PlayCarouselDot
                  key={index}
                  isActive={isActive}
                  height={size}
                  width={size}
                  additionalClassName={carouselDotTypes.VIDEO}
                />
              )
            }

            return (
              <span
                className={carouselDotTypes.IMAGE}
                key={index}
                data-isactive={isActive}
              />
            )
          })}
        </StyledCarouselDots>
      )}
    </>
  )
}

export default CarouselDots
