import React, {useCallback, useEffect} from 'react'
import noop from 'lodash/noop'

import {CheckboxListContainer} from './CheckboxList.styles'
import {ALL_OF_THE_ABOVE, NONE_OF_THE_ABOVE} from './CheckboxList.constants'

function CheckBoxList(props) {
  const {
    values = [],
    onValueChange = noop,
    CheckBoxItemRender,
    data = [],
    multipleSelect = false,
    containerStyle = {},
    category = '',
    isCategoryScreen = false,
  } = props

  const handleCheckBoxClicked = useCallback(
    (value) => {
      if (!multipleSelect) {
        return onValueChange([value])
      }

      if (value.text === NONE_OF_THE_ABOVE) {
        return onValueChange([value])
      }

      const exists = values.find((item) => item?.id === value?.id)

      if (exists) {
        if (exists.text === ALL_OF_THE_ABOVE) {
          return onValueChange([])
        }

        return onValueChange(
          values.filter(
            (item) => item.id !== value.id && item.text !== ALL_OF_THE_ABOVE
          )
        )
      }

      if (value.text === ALL_OF_THE_ABOVE) {
        return onValueChange(data)
      }

      return onValueChange(
        values.concat(value).filter((item) => item.text !== NONE_OF_THE_ABOVE)
      )
    },
    [data, multipleSelect, onValueChange, values]
  )

  const initializeActiveItems = useCallback(() => {
    const activeItems = data.filter((item) => item?.isSelected)
    onValueChange(activeItems)
  }, [onValueChange, data])

  useEffect(() => initializeActiveItems(), [])

  return (
    <CheckboxListContainer style={containerStyle}>
      {data?.map((args, index) => {
        const {id = ''} = args || {}
        const isLast = data.length - 1 === index
        const isSelected = values.some((item) => item?.id === id)

        return (
          <CheckBoxItemRender
            {...args}
            key={id}
            onClick={handleCheckBoxClicked}
            isLast={isLast}
            isSelected={isSelected}
            index={index}
            category={category}
            isCheckbox={multipleSelect}
            isCategoryScreen={isCategoryScreen}
          />
        )
      })}
    </CheckboxListContainer>
  )
}

export default CheckBoxList
