import {useDeviceInfoContext} from '../Context/DeviceInfo'

const Responsive = (Component, screenSize) => {
  return function ReturnedComponent(props) {
    const {isMobile, screenWidth} = useDeviceInfoContext()
    return (
      <Component screenWidth={screenWidth} isMobile={isMobile} {...props} />
    )
  }
}

export default Responsive
