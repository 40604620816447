import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.span`
  position: relative;
  display: inline-block;
  .cover {
    background: white;
    ${({blendWithBackground}) =>
      blendWithBackground
        ? `background: var(--character-background, #fff);`
        : ''}
    height: 100%;
    overflow: hidden;
    mix-blend-mode: color;
    position: absolute;
    top: 0;
    left: ${({width, fraction}) => parseInt(width * fraction, 10)}px;
    width: ${({width}) => width}px;
  }
`
function PartialStar({
  height = 21,
  width = 22,
  fraction = 0,
  blendWithBackground,
}) {
  return (
    <StyledWrapper
      fraction={fraction}
      width={width}
      blendWithBackground={blendWithBackground}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 22 20"
      >
        <path
          fill="#FFC820"
          stroke="#FFC820"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 1l3.09 5.925 6.91.95-5 4.613L17.18 19 11 15.925 4.82 19 6 12.488 1 7.875l6.91-.95L11 1z"
        ></path>
      </svg>
      <span className="cover" fraction={fraction}></span>
    </StyledWrapper>
  )
}

export default PartialStar
