export const processNotFreeRightNowToggled = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = '', source = ''} = data

  return {
    Category: category,
    Source: source,
  }
}
