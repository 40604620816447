import * as React from 'react'

function TickIcon(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={26} height={26} rx={13} fill="#379F8E" />
      <path
        d="m8.667 12.897 3.096 2.786 6.19-5.572"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TickIcon
