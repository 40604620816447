export const processCancelOrderClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    orderNo: data.orderNo || '',
  }

  return dataToReturn
}

export const processCancelOrderClickedWalletPoints = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    optionClicked: data.option_clicked || '',
  }

  return dataToReturn
}

export const processMyOrdersViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {}

  return dataToReturn
}

export const processOrderDetailsClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    orderNo: data.orderNo || '',
  }

  return dataToReturn
}

export const processCancelOrder = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    orderNo: data.orderNo || '',
    Reason: data.userReason || '',
    Comment: data.userComment || '-',
    refundSource: data.type || '',
    paymentMethod: data.paymentMethod || '',
  }

  return dataToReturn
}

export const processWalletPayClicked = (data: any) => {
  const {amount = 0, bonus = 0, source = ''} = data || {}
  const dataToReturn = {
    amount,
    bonus,
    source,
  }
  return dataToReturn
}
