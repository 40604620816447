import {IActionLibraryConfig} from '@mosaic-wellness/redux-action-library'
import {toast as reactToast, Renderable} from 'react-hot-toast'
import React from 'react'
import {Subject, throttleTime} from 'rxjs'

const toastObservable = new Subject<IToast>()
type IToast = Parameters<IActionLibraryConfig['environment']['toast']>

export const toast = (...x: IToast) => {
  toastObservable.next([...x])
}

toastObservable
  .pipe(throttleTime(1500, undefined, {leading: true, trailing: true}))
  .subscribe({
    next: (value) => {
      const [type, message] = value
      try {
        switch (type) {
          case 'success':
            toastRender(
              <SuccessIcon />,
              message || 'Success',
              'var(--brand-primary-teal-color)'
            )
            break
          case 'warning':
            toastRender(
              <WarningIcon />,
              message || 'Warning',
              'var(--brand-primary-error-color)'
            )
            break
          case 'internet-resumed':
            toastRender(
              <InternetIcon />,
              message || 'Internet connection resumed!',
              'var(--brand-primary-teal-color)'
            )
            break
          case 'internet-not-connected':
            toastRender(
              <InternetIcon />,
              message || 'No Internet Connection',
              'var(--brand-primary-error-color)'
            )
            break
          case 'alert':
            toastRender(
              <WarningIcon />,
              message || 'No Internet Connection',
              'var(--brand-primary-orange-color)'
            )
            break
          default:
            return
        }
      } catch (e) {
        return
      }
    },
  })

function toastRender(
  icon: Renderable,
  message: string,
  backgroundColor: string
) {
  reactToast(message, {
    position: 'top-center',
    icon,
    style: {
      backgroundColor,
      color: 'white',
      borderRadius: '20px',
    },
    duration: 3000,
  })
}

function SuccessIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7598 14L24.7098 10.5125L25.1348 5.9L20.6223 4.875L18.2598 0.875L14.0098 2.7L9.75977 0.875L7.39726 4.8625L2.88477 5.875L3.30977 10.5L0.259766 14L3.30977 17.4875L2.88477 22.1125L7.39726 23.1375L9.75977 27.125L14.0098 25.2875L18.2598 27.1125L20.6223 23.125L25.1348 22.1L24.7098 17.4875L27.7598 14ZM10.7348 19.0125L7.75976 16.0125C7.27226 15.525 7.27226 14.7375 7.75976 14.25L7.84727 14.1625C8.33477 13.675 9.13476 13.675 9.62226 14.1625L11.6348 16.1875L18.0723 9.7375C18.5598 9.25 19.3598 9.25 19.8473 9.7375L19.9348 9.825C20.4223 10.3125 20.4223 11.1 19.9348 11.5875L12.5348 19.0125C12.0223 19.5 11.2348 19.5 10.7348 19.0125Z"
        fill="#FFEBAD"
      />
    </svg>
  )
}

function WarningIcon() {
  return (
    <svg
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.525775 19.9146L10.6615 2.02795C12.1938 -0.675983 16.0895 -0.675984 17.6217 2.02795L27.7575 19.9146C29.2685 22.5811 27.3423 25.8866 24.2774 25.8866H4.00587C0.940958 25.8866 -0.985263 22.5811 0.525775 19.9146Z"
        fill="#FFEBAD"
      />
      <path
        d="M14.251 10.2223V14.2223"
        stroke="#E86669"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="14.2529" cy="19.2206" r="2" fill="#E86669" />
    </svg>
  )
}

function InternetIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="6" height="24" rx="3" fill="#FFEBAD" />
      <rect x="9" y="7.5" width="6" height="16.5" rx="3" fill="#FFEBAD" />
      <rect x="18" y="15" width="6" height="9" rx="3" fill="#FFEBAD" />
    </svg>
  )
}
