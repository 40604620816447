import {
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import {IReviewCardProps} from './ReviewCard.interface'
import {ReviewCardContainer} from './ReviewCard.styles'
import {useCallback, useState} from 'react'

function ReviewCard(props: IReviewCardProps) {
  const {review} = props
  const {
    author = '',
    body = '',
    dateCreated = '',
    images,
    rating = '',
    title = '',
  } = review || {}
  const [showExpandedReview, setShowExpandedReview] = useState<{
    shouldShow: boolean
    imageIndex: number | null
  }>({
    shouldShow: false,
    imageIndex: null,
  })

  const handleShowExpandedReview = useCallback((index?: number) => {
    setShowExpandedReview((data) => ({
      ...data,
      shouldShow: !data.shouldShow,
      imageIndex: index || null,
    }))
  }, [])

  return (
    <ReviewCardContainer>
      <ReviewRatingStar
        className="ratings-stars"
        value={Number(rating)}
        count={5}
        size={17}
      />
      <Typography
        customClassName="review-title"
        variant={TypographyVariants.BODY_BASE_BOLD}
      >
        {title}
      </Typography>
      <Typography
        customClassName="review-body"
        variant={TypographyVariants.BODY_BASE_REGULAR}
      >
        {body}
      </Typography>
      <div className="images-container">
        {images.map((item, index) => (
          <OptimizedImage
            key={item.image}
            aspectHeight={'56'}
            aspectWidth={'56'}
            source={item.image}
            altText={item.altText}
            customClassName="review-image"
            onImageClick={() => handleShowExpandedReview(index)}
          />
        ))}
      </div>
      <div className="publish-details">
        <Typography
          customClassName="author"
          variant={TypographyVariants.BODY_BASE_REGULAR}
        >
          {author}
        </Typography>
        <Typography
          customClassName="date-created"
          variant={TypographyVariants.X_SMALL}
        >
          {dateCreated}
        </Typography>
      </div>
      {showExpandedReview.shouldShow && (
        <div className="modal-background">
          <div className="modal-content">
            <div onClick={handleShowExpandedReview} className="close-button">
              ×
            </div>
            <OptimizedImage
              source={images[showExpandedReview.imageIndex || 0]?.image}
              customClassName="expanded-review-image"
            />
            <Typography variant={TypographyVariants.CTA_LABEL_BIG}>
              {author}
            </Typography>
            <ReviewRatingStar
              value={rating}
              count={5}
              size={18}
              className="rating"
            />
            <Typography variant={TypographyVariants.TAG_BOLD}>
              {title}
            </Typography>
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="expanded-review-body"
            >
              {body}
            </Typography>
            <Typography
              variant={TypographyVariants.X_SMALL}
              customClassName="expanded-review-date"
            >
              {dateCreated}
            </Typography>
          </div>
        </div>
      )}
    </ReviewCardContainer>
  )
}

export default ReviewCard
