import {
  ClearDivider,
  StickyCartSummaryContainer,
} from './StickyCartSummary.styles'
import {
  Button,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {useStickyCartSummary} from './useStickyCartSummary'

const StickyCartSummary = ({
  widgetData,
  id,
  header,
  layout,
  type,
}: BaseWidgetProps<{showSubTitle: boolean}>) => {
  const {
    productImage,
    title,
    subtitle,
    showSubTitle,
    label,
    handleCtaClick,
    isMilestoneTierEmpty,
  } = useStickyCartSummary({...widgetData, source: id})

  if (isMilestoneTierEmpty) return null

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ClearDivider />
      <StickyCartSummaryContainer>
        <div className="cart-container">
          <OptimizedImage
            source={productImage}
            desktopViewWidth="THIRD"
            mobileViewWidth="THIRD"
            altText="category-image"
            loading="lazy"
            customClassName="product-image"
          />
          <div className="cart-details">
            <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
              {title}
            </Typography>
            {subtitle && showSubTitle && (
              <Typography
                variant={TypographyVariants.X_SMALL}
                customClassName="cart-subtitle"
              >
                {subtitle}
              </Typography>
            )}
          </div>
          <Button
            variant="PRIMARY"
            type="button"
            size="LARGE"
            customClassName="primary-btn-container"
            customLabelClassName="primary-btn-label"
            onClick={handleCtaClick}
            label={label}
          />
        </div>
      </StickyCartSummaryContainer>
    </BaseWidgetComponent>
  )
}

export default StickyCartSummary
