import {LOCAL_STORAGE_CONSTANTS} from './localStorageConstants'

type Keys = keyof typeof LOCAL_STORAGE_CONSTANTS
type keyName = typeof LOCAL_STORAGE_CONSTANTS[Keys]

interface ILocalStorageSet {
  keyName: keyName
  value: any
}

const localStorageMethods = {
  set: (props: ILocalStorageSet) => {
    try {
      if (typeof window !== 'undefined')
        window.localStorage.setItem(props.keyName, JSON.stringify(props.value))
    } catch (e) {
      // do nothing
    }
  },
  remove: (keyName: keyName) => {
    try {
      if (typeof window !== 'undefined') window.localStorage.removeItem(keyName)
    } catch (e) {
      // do nothing
    }
  },
  get: (keyName: keyName) => {
    try {
      if (typeof window !== 'undefined')
        return JSON.parse(window.localStorage.getItem(keyName) || 'null')
      else return null
    } catch (e) {
      // do nothing
    }
  },
}

export {localStorageMethods}
