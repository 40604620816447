import styled from 'styled-components'

export const StyledDoctorAppointmentCard = styled.div`
  border-radius: var(--dls-size-12);
  border: var(--dls-size-1) solid var(--dls-text-field-stroke-color);
  background: var(--dls-backgound-color-500);
  padding: var(--dls-size-12);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--dls-size-2);
    .reference-tag {
      padding: var(--dls-size-4) var(--dls-size-8);
      border-radius: var(--dls-size-16);
      border: 0.5px solid var(--dls-divider-light-color);
      background: var(--dls-default-tag-background-color);
      color: var(--dls-default-tag-text-color);
    }
  }

  .details-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--dls-size-12);
    row-gap: var(--dls-size-12);
    flex-wrap: wrap;
    .detail-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: var(--dls-size-8);
      width: calc((100% - var(--dls-size-12)) / 2);
      .detail-icon {
        width: var(--dls-size-16);
        height: var(--dls-size-16);
      }
      .detail-label {
        color: var(--dls-brand-primary-color-500);
      }
    }
  }

  .divider {
    border-bottom: var(--dls-size-1) solid var(--dls-divider-light-color);
    margin: var(--dls-size-12) 0;
  }

  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--dls-size-12);
    row-gap: var(--dls-size-12);
    flex-wrap: wrap;
    .footer-cta {
      width: calc((100% - var(--dls-size-12)) / 2);
      &[data-isfullwidth='true'] {
        width: 100%;
      }
    }
  }
`
