import styled from 'styled-components'

export const HomeStyledLoading = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  
  .home-left {
    width: 60%;
    margin-right: 10px;
  }

  .home-right-one {
    width: 25%;
    margin-left: 10px;
  }
  .home-right-two {
    width: 20%;
    align-self: flex-end;
    margin-left: 10px;
  }

  .home-horizontal-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

   @media (min-width: 768px) {
    .home-right-one {
      width: 20%;
      margin-left: 10px;
    }
   }
`
