import {Wrapper} from './styles'
import {useState} from 'react'
import noop from 'lodash/noop'
import ReviewAuthor from '../ReviewAuthor'
import Images from '../Images'
import Typography from '../../../../../common/Typography/Typography'
import StarRating from '../../../../../../Components/Atomic/StarRating'
import PhotoPreview from '../../../../../../Components/Screen/ProductDescriptionPage/RatingsAndReviews/PhotoPreview'
import {getFormattedReviews} from '../../utils'

const Card = (props) => {
  const {
    review = {},
    isLastElement = false,
    isUpvoteClicked = false,
    isDownvoteClicked = false,
    votesCount = {},
    showVotes = false,
  } = props.state || {}

  const {
    handleDownvote = noop,
    handleUpvote = noop,
    onImageClick = noop,
    disableBodyScroll = noop,
    enableBodyScroll = noop,
  } = props.actions || {}

  const [isImagePreviewVisible, setIsImagePreviewVisible] = useState(false)
  const [startPositionForPreview, setStartPositionForPreview] = useState(0)

  const {
    id = '',
    title = '',
    body = '',
    images = [],
    rating = 5,
    additionalWrapperClass = '',
    reply = null,
    author = '',
    dateCreated = '',
    voteUp = '0',
    voteDown = '0',
  } = review

  const reviewAuthorArgs = {
    state: {
      id,
      author: author,
      dateCreated: dateCreated,
      countVoteUp: voteUp,
      countVoteDown: voteDown,
      isUpvoteClicked,
      isDownvoteClicked,
      votesCount: votesCount[id] || {voteDown: 0, voteUp: 0},
      showVotes,
    },
    actions: {
      handleDownvote,
      handleUpvote,
    },
  }

  const handleImageClick = (event) => {
    disableBodyScroll()
    const imageNode = event.target
    const imageIndex = imageNode.getAttribute('data-index') || 0
    setStartPositionForPreview(imageIndex)
    setIsImagePreviewVisible(true)
  }
  const handleImagePreviewClose = () => {
    enableBodyScroll()
    setIsImagePreviewVisible(false)
  }

  // const photoPreviewData = getFormattedReviews(review)
  const {reviewImages, reviewMap} = getFormattedReviews(review)
  return (
    <Wrapper borderBottom={isLastElement} className={additionalWrapperClass}>
      <StarRating rating={rating} starType="bigStar" starColor="#FFC820" />
      <Typography
        text={title}
        variant="body-base-bold"
        customClassName="mob__title"
        headingLevel={3}
      />
      <Typography
        text={body}
        variant="body-base-regular"
        customClassName="review__body"
      />
      <Images actions={{onImageClick: handleImageClick}} images={images} />
      {reply && <div className="reply">{reply}</div>}
      <ReviewAuthor {...reviewAuthorArgs} />
      {isImagePreviewVisible && (
        <PhotoPreview
          data={{loadedPhotosPreview: images, loadedReviewsMap: reviewMap[id]}}
          onClose={handleImagePreviewClose}
          startIndex={startPositionForPreview}
        />
      )}
    </Wrapper>
  )
}

export default Card
