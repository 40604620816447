export const processRegimeFilterApplied = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const answers = data.answer || []
  const questions = data.question || []
  const filters = data.filters || {}
  const dataToReturn = {
    answers: answers.map((answer: string) => {
      return {answer: answer}
    }),
    questions: questions.map((question: string) => {
      return {question: question}
    }),
    filters,
  }

  return dataToReturn
}

export const processRegimeFilterClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    categoryPage: data.category,
  }

  return dataToReturn
}
