import React, {useMemo} from 'react'
import {LoadingStatesContainer} from './styles'
import LoadingState from '../LoadingState/LoadingState'

/**
 * To be used in All products and category pages
 * Use Case:- To be used while filtered Products are getting fetched
 * or section products are not loaded yet
 * @param {{isMobile:boolean}} props
 * @returns
 */
function SectionProductsLoadingSkeleton({isMobile}) {
  const [cardHeight, cardWidth] = useMemo(() => {
    return isMobile ? [271, '180px'] : [503, '360px']
  }, [isMobile])

  return (
    <LoadingStatesContainer>
      <LoadingState
        numberOfCards={1}
        cardHeight={cardHeight}
        cardWidth={cardWidth}
      />
      <LoadingState
        numberOfCards={1}
        cardHeight={cardHeight}
        cardWidth={cardWidth}
      />
      <LoadingState
        numberOfCards={1}
        cardHeight={cardHeight}
        cardWidth={cardWidth}
      />
      <LoadingState
        numberOfCards={1}
        cardHeight={cardHeight}
        cardWidth={cardWidth}
      />
    </LoadingStatesContainer>
  )
}

export default SectionProductsLoadingSkeleton
