import React, {useCallback} from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {MobileCategoryPaletteWrapper} from './MobileCategoryPalette.styles'
import {MobilePaletteCard} from './MobilePaletteCard'
import {useGenericActions} from '@web-core'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function MobileCategoryPaletteCard(props: BaseWidget) {
  const {type, widgetData, header, layout, id} = props || {}
  const {items = []} = widgetData || {}

  const {genericAction} = useGenericActions()

  const handleAction = useCallback(
    (action: GenericAction) => {
      genericAction(interceptAndEnhanceAction(action, id))
    },
    [genericAction, id]
  )

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MobileCategoryPaletteWrapper>
        {items.map((data: any, index: number) => (
          <MobilePaletteCard
            cardData={data}
            handleAction={handleAction}
            key={index}
          />
        ))}
      </MobileCategoryPaletteWrapper>
    </BaseWidgetComponent>
  )
}

export default MobileCategoryPaletteCard
