import styled from 'styled-components'

export const RatingsContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
  padding: 20px;
  @media (max-width: 768px) {
    width: 90%;
    justify-content: space-around;
  }
`
