import {initializeWebCore} from '@web-core'
import noop from 'lodash/noop'
import {useEffect} from 'react'
import {analyticsTriggerBypassProcessor} from 'src/analytics'
import {CLIENT_ENV} from 'src/constants/clientEnv'
import {
  requestInterceptor,
  responseErrorInterceptor,
} from 'src/utils/network/interceptors'

export const useInitializeWebUtils = ({
  isApp = false,
}: {isApp?: boolean} = {}) => {
  useEffect(() => {
    initializeWebCore({
      clientDomain: CLIENT_ENV.NEXT_PUBLIC_BACKEND,
      errorLogger: noop,
      pushAnalytics: analyticsTriggerBypassProcessor,
      requestInterceptor: (request: any) => {
        return requestInterceptor(request, isApp)
      },
      responseInterceptor: async (response) => response,
      responseErrorInterceptor,
    })
  }, [isApp])
}
