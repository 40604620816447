import {create} from 'zustand'
import {IAtcBottomSheet} from './atcBottomSheet.interface'

export const useATCBottomSheetStore = create<IAtcBottomSheet>((set) => ({
  showAtcBottomSheet: false,
  bottomSheetProducts: null,
  atcProductImage: '',
  atcBottomSheetSource: '',
  useMiniCart: true,
  hideGoToCartButton: false,
  baseProductUrlKey: '',
  isATCForBuyNow: false,
  atcBottomSheetSourcePage: '',
  setShowAtcBottomSheet: (flag: boolean) =>
    set(() => ({showAtcBottomSheet: flag})),
  setBottomSheetProducts: (data: any) =>
    set(() => ({bottomSheetProducts: data})),
  setAtcProductImage: (image: string) => set(() => ({atcProductImage: image})),
  setAtcBottomSheetSource: (source: string, bottomSheetSourcePage: string) =>
    set(() => ({
      atcBottomSheetSource: source,
      atcBottomSheetSourcePage: bottomSheetSourcePage ?? '',
    })),
  setUseMiniCart: (flag: boolean) =>
    set(() => ({
      useMiniCart: flag,
    })),
  setHideGoToCartButton: (flag) => set(() => ({hideGoToCartButton: flag})),
  setBaseProductUrlKey: (id: string) => set(() => ({baseProductUrlKey: id})),
  setIsATCForBuyNow: (flag: boolean) => set(() => ({isATCForBuyNow: flag})),
}))
