import * as React from 'react'

/*
use {arrow-left__circle} class in css for change circle style
use {arrow-left__arrow} class in css for change arrow style

*/
const LeftArrow = (props) => (
  <svg
    width={100}
    height={100}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <circle
        className="arrow-left__circle"
        r={30}
        transform="matrix(-1 0 0 1 50 40)"
        fill="#000"
      />
    </g>
    <path
      d="m54.5 49-9-9 9-9"
      stroke="#fff"
      className="arrow-left__arrow"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={100}
        height={100}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={10} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2099_4183"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2099_4183"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default LeftArrow
