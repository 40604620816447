import React, {useCallback} from 'react'

import {FloatingIslandNudges} from '../shared/src/ComponentsV2/common/FloatingIslandNudges'
import {useGenericCta, useFloatingIslandNudges} from 'src/hooks'

function FloatingIslandNudgesBar() {
  const {nudgesToShow, showNudge, onNudgeCtaClick} = useFloatingIslandNudges()
  const {handleCta} = useGenericCta()

  const onNudgeClick = useCallback(
    ({link = '', type = ''}) => {
      handleCta({action: 'SPA_LINK', link})
      onNudgeCtaClick({link, state: type})
    },
    [handleCta, onNudgeCtaClick]
  )

  if (showNudge.showNudge && nudgesToShow) {
    return (
      <FloatingIslandNudges
        state={{...nudgesToShow[0], position: showNudge.position}}
        actions={{onNudgeClick}}
      />
    )
  }

  return null
}

export default FloatingIslandNudgesBar
