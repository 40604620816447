import PlayCarouselDot from './PlayCarouselDot'
import {StyledCarouselDots} from './CarouselDots.styles'

const carouselDotTypes = {
  IMAGE: 'bullet',
  VIDEO: 'video',
}

interface ICarouselDotsProps {
  items?: {
    type: 'video' | 'bullet'
  }[]
  activeSlideIndex?: number
  dotsOverlay?: boolean
}

function CarouselDots({
  items = [],
  activeSlideIndex = 0,
  dotsOverlay = false,
}: ICarouselDotsProps) {
  return items?.length >= 2 ? (
    <StyledCarouselDots className="bullets" data-isOverlay={dotsOverlay}>
      {items.map((item, index) => {
        const isActive = index === activeSlideIndex
        if (item?.type === 'video') {
          const size = isActive ? 8 : 6
          return (
            <PlayCarouselDot
              key={index}
              isActive={isActive}
              height={size}
              width={size}
              additionalClassName={carouselDotTypes.VIDEO}
            />
          )
        }

        return (
          <span
            className={carouselDotTypes.IMAGE}
            key={index}
            data-isactive={isActive}
          />
        )
      })}
    </StyledCarouselDots>
  ) : null
}

export default CarouselDots
