import isEmpty from 'lodash/isEmpty'
import {Wrapper} from './styles'
const Images = (props) => {
  const {images} = props
  const {onImageClick = () => {}} = props.actions || {}

  if (isEmpty(images)) return null

  const length = images.length

  return (
    <Wrapper>
      {images.map((item, index) => {
        const maxIndex = 4
        const lastIndex = maxIndex - 1
        if (index >= maxIndex) return null

        const {image = '', altText = 'product'} = item

        return (
          <div
            onClick={onImageClick}
            key={index + ' something'}
            data-index={index}
            className="item"
          >
            <img
              src={image}
              alt={altText}
              onClick={onImageClick}
              data-index={index}
              loading="lazy"
            />
            {index === lastIndex && (
              <div className="overlay">
                <span className="count_image">{`${length - 3}+`}</span>
              </div>
            )}
          </div>
        )
      })}
    </Wrapper>
  )
}

export default Images
