export const processImageUploadViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {appointmentId = '', appointmentType = '', category = ''} = data

  return {
    Category: category,
    'Appointment Type': appointmentType,
    AppointmentID: appointmentId,
  }
}

export const processImageUploadInitiated = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    appointmentId = '',
    appointmentType = '',
    category = '',
  } = dLayer.imageUploadAppointmentDetails

  return {
    Category: category,
    'Appointment Type': appointmentType,
    AppointmentID: appointmentId,
  }
}

export const processWhatToUploadClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    appointmentId = '',
    appointmentType = '',
    category = '',
  } = dLayer?.imageUploadAppointmentDetails

  return {
    Category: category,
    'Appointment Type': appointmentType,
    AppointmentID: appointmentId,
  }
}

export const processImageSizeError = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {size = ''} = data
  const {
    appointmentId = '',
    appointmentType = '',
    category = '',
  } = dLayer.imageUploadAppointmentDetails

  return {
    Category: category,
    'Appointment Type': appointmentType,
    AppointmentID: appointmentId,
    'Image Size': size,
  }
}

export const processImageUploadSuccess = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {numberOfImages = ''} = data
  const {
    appointmentId = '',
    appointmentType = '',
    category = '',
  } = dLayer.imageUploadAppointmentDetails

  return {
    Category: category,
    'Appointment Type': appointmentType,
    AppointmentID: appointmentId,
    'Number of images': numberOfImages,
  }
}

export const processImageFlowExit = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {exitedFrom = ''} = data
  const {
    appointmentId = '',
    appointmentType = '',
    category = '',
  } = dLayer.imageUploadAppointmentDetails

  return {
    'Back/Cross': exitedFrom,
    Category: category,
    'Appointment Type': appointmentType,
    AppointmentID: appointmentId,
  }
}
