import styled from 'styled-components'

export const StyledCalloutWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--brand-font-family-text);
  font-weight: 400;
  font-size: 12px;
  outline: none;
  border: none;
  padding-right: 0;
  color: ${({textColor}) =>
    textColor ? textColor : 'var(--brand-primary-white-color)'};
  background-color: ${({bgColor}) => (bgColor ? bgColor : '#42B682')};

  .info {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  .text {
    max-width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30px;
    padding: 12px 8px 16px;

    @media screen and (min-width: 768px) {
      right: 28px;

      padding: 18px 16px;
    }
  }

  @media screen and (max-width: 390px) {
    .info {
      .text {
        max-width: 65%;
      }
    }
  }
  @media screen and (max-width: 350px) {
    .info {
      justify-content: flex-start;
      .text {
        max-width: 50%;
        margin-right: 5px;
      }
    }
  }
  @media screen and (min-width: 786px) {
    height: 50px;
    font-size: 16px;
  }
`

export const StyledCta = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({bgColor}) => bgColor};
  color: ${({textColor}) => textColor};
  border-radius: 8px;
  height: 30px;
  padding: 0 8px;
  margin-left: 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--brand-font-weight-normal);
  @media screen and (min-width: 786px) {
    padding: 4px 16px;
  }
`
