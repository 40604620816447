import styled from 'styled-components'

export const CustomerSayWrapper = styled.div`
  padding: var(--dls-size-16);
  margin: auto;
  width: 100%;
  max-width: 1120px;
  .customer-say {
    background-color: var(--dls-brand-secondary-color-100);
    border: 1px solid var(--Light-Dividers, #e3e3e3);
    border-radius: var(--dls-size-16);
    padding: var(--dls-size-16);
    display: flex;
    flex-direction: column;
  }
  .customer-say-description {
    margin-top: var(--dls-size-16);
  }
  .customer-say-tags-title {
    margin-top: var(--dls-size-16);
  }
  .customer-say-subText {
    color: var(--brand-primary-color);
  }
  .customer-say-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--dls-size-8);
    margin-top: var(--dls-size-16);
  }
  .tag-text {
    color: var(--brand-primary-color);
  }
  .tag-link {
    padding: var(--dls-size-4) var(--dls-size-16);
    display: flex;
    align-items: center;
    border-radius: var(--dls-size-24);
    border: 1px solid var(--brand-primary-color);
  }
  .verified-image-container {
    display: flex;
    align-items: center;
    gap: var(--dls-size-4);
  }
`
