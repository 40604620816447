import styled from 'styled-components'

export const VideoImageProductContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .image-container {
    position: relative;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--dls-size-8);
    background: linear-gradient(
      180deg,
      #000000 7.43%,
      rgba(0, 0, 0, 0.349665) 24.3%,
      rgba(0, 0, 0, 0) 41.66%
    );
  }
  .base-image {
    border-radius: var(--dls-size-8);
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .video-container {
    display: flex;
    position: relative;
  }
  .video-player {
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: var(--dls-size-8);
  }
  .video-mute-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .tag-container {
    position: absolute;
    top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--dls-white-color);
  }
  .product-image-container {
    transform: translateY(-45px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .product-image {
    background: var(--dls-white-color);
    border-radius: var(--dls-size-8);
    padding: var(--dls-size-4);
    box-shadow: 0px 1px 3px 0px #0000000d, 0px 5px 5px 0px #0000000a,
      0px 11px 7px 0px #00000008, 0px 20px 8px 0px #00000003,
      0px 31px 9px 0px #00000000;
  }
  .text-container {
    margin-top: var(--dls-size-8);
    width: 100px;
    text-align: center;
  }
  .button {
    background: var(--dls-white-color);
    border: 1px solid var(--dls-brand-primary-color-500);
    color: var(--dls-brand-primary-color-500);
    border-radius: var(--dls-size-8);
    padding: var(--dls-size-4) var(--dls-size-8);
    margin-top: var(--dls-size-8);
    height: 32px;
    display: flex;
    align-items: center;
  }
`
