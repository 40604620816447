import useAppActions from 'src/hooks/useAppActions'
import React from 'react'
import {useOnBoardingFlowStore} from 'src/stores/onBoarding/useOnBoarding.store'
import {noop} from 'lodash'

const useOnboardingCompletion = () => {
  const {
    flowId,
    flowData,
    flowContext,
    currentStep,
    skipOnboardingData,
    resetFlowData,
  } = useOnBoardingFlowStore()

  const [, {handleAppAction = noop}] = useAppActions()

  const currentStepData = React.useMemo(() => {
    const step = flowData?.widgetOrder?.[currentStep]
    if (!step) return null
    return flowData?.widgets[step]
  }, [currentStep, flowData?.widgetOrder, flowData?.widgets])

  React.useEffect(() => {
    if (!flowId) {
      isFlowComplete.current = false
    }
  }, [flowId])

  const isFlowComplete = React.useRef(false)

  const handleCompletion = React.useCallback(async () => {
    isFlowComplete.current = true

    setTimeout(() => {
      if (Array.isArray(flowData?.onCompleteCallbackAction)) {
        flowData?.onCompleteCallbackAction
          .filter((action) => !!action)
          .forEach((callbackAction) => {
            if (callbackAction.actionName === 'TRIGGER_EVENTS') {
              handleAppAction({
                action: callbackAction.actionName,
                actionData: {
                  ...callbackAction.actionData,
                  ...flowContext,
                },
                eventData: {
                  ...callbackAction.actionData?.eventData,
                  enableSkip: skipOnboardingData?.enableSkip || false,
                  skipEntireFlow: skipOnboardingData?.skipEntireFlow || false,
                },
              })
            } else {
              handleAppAction({
                action: callbackAction.actionName,
                actionData: {
                  ...callbackAction.actionData,
                  ...flowContext,
                },
              })
            }
          })
      }
    }, 1000)
  }, [
    flowContext,
    flowData,
    handleAppAction,
    resetFlowData,
    skipOnboardingData,
  ])

  React.useEffect(() => {
    if (
      !isFlowComplete.current &&
      flowData?.widgetOrder?.length &&
      flowData?.widgetOrder?.length >= 0 &&
      currentStep >= flowData?.widgetOrder.length
    ) {
      handleCompletion()
    }
  }, [
    currentStep,
    flowData,
    flowContext,
    resetFlowData,
    flowId,
    skipOnboardingData,
    handleCompletion,
  ])

  return {
    flowData,
    currentStepData,
    flowId,
  }
}

export default useOnboardingCompletion
