/**
 * @description Check if the current consult version is greater than v3
 * This is used for the exit RCL exit points which are being used in web view
 * Post version v3 we are moved to native consult
 */
export const isNativeConsultVersion = ({
  currentConsultVersion = '',
}: {
  currentConsultVersion: string
}) => {
  try {
    const derivedVersion = Number(currentConsultVersion?.substring(1))
    return derivedVersion >= 3
  } catch {
    return false
  }
}
