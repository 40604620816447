import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import React, {useCallback} from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {useGenericActions} from '../../hooks'
import Carousel from './Carousel/Carousel.component'
import {ICarouselProps} from './Carousel/Carousel.types'

function DoctorListingCarousel(props: BaseWidgetProps<ICarouselProps>) {
  const {type, widgetData, header, layout, id} = props || {}
  const {genericAction, handleGenericActions} = useGenericActions()
  const handleItemClick = useCallback(
    (item: any) => () => {
      if (!item) {
        return
      }
      if (item?.actions?.length) {
        handleGenericActions(item?.actions)
      } else {
        genericAction(item)
      }
    },
    [genericAction, handleGenericActions]
  )
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <Carousel {...widgetData} onCarouselItemPress={handleItemClick} />
    </BaseWidgetComponent>
  )
}

export default DoctorListingCarousel
