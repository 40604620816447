import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'

import {
  SelectCategoryContainer,
  CategoryCardContainer,
  CategoryScreenCardsContainer,
} from './SelectCategory.styles'

import CategoryCard from './CategoryCard'
import {ConsultStepsCard} from '../GetCustomerInfo/ConsultStepsCard'
import InfoCardSection from '../ScheduleScreen/InfoCardSection'

function SelectCategory(props) {
  const {
    text = '',
    isOptionSelected = false,
    selectedOption = null,
    options = [],
    subText = '',
    categoryScreenData = {},
  } = props.state || {}

  const {onOptionClick = noop} = props.actions || {}

  const {
    getCustomerInfo = {},
    infoCardSection = {},
    cardsOrder = [],
  } = categoryScreenData

  if (isEmpty(options)) {
    return null
  }

  return (
    <SelectCategoryContainer>
      <div className="category-header">
        <h2 className="category-header__title">{text}</h2>
        <h3 className="category-header__subtitle">{subText}</h3>
      </div>
      <CategoryCardContainer>
        {options.map(
          ({text, hide, option, appointment_category, image, display_text}) => (
            <CategoryCard
              state={{
                text,
                option,
                selectedOption,
                appointment_category,
                isOptionSelected,
                image,
                hide,
                display_text,
              }}
              actions={{
                onOptionClick,
              }}
            />
          )
        )}
      </CategoryCardContainer>
      <CategoryScreenCardsContainer order={cardsOrder}>
        {getCustomerInfo?.show && <ConsultStepsCard state={getCustomerInfo} />}
        {infoCardSection?.show && <InfoCardSection state={infoCardSection} />}
      </CategoryScreenCardsContainer>
    </SelectCategoryContainer>
  )
}

export default SelectCategory
