export const processAppointmentSelected = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {immediate} = dLayer.appointmentSelected || {}
  const {location: appointmentShownLocation} = dLayer.showBookAppointment || {}
  const isAaAvailableForSelectedData = immediate ? 'IA' : 'S'

  let eventName = ''
  switch (appointmentShownLocation) {
    case 'MINI_ASSESSMENT':
      eventName = 'MA Appt Selected'
      break
    default:
      break
  }
  const dataToReturn = {
    'Product Name': dLayer?.product?.name || '',
    URL: window.location.href,
    Type: isAaAvailableForSelectedData,
    eventName,
  }

  return dataToReturn
}
