import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {IKitBreakdownProps} from './KitBreakdown.interface'
import {KitBreakdownContainer} from './KitBreakdown.styles'
import {
  GenericSlider,
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import KitProduct from './KitProduct/KitProduct'
import useKitProduct from './KitProduct/useKitProduct'

function KitBreakdown(props: IKitBreakdownProps) {
  const {id = '', type = '', widgetData, header, layout} = props
  const {sliderConfig, kitProducts} = widgetData || {}
  const {aspectRatio = 1, slidesToShow = 1} = sliderConfig || {}

  const [{kitProductModal}, {closeModal, openModal}] = useKitProduct()
  const {isModalOpen = false, showModal = false, modalData} = kitProductModal
  const {description = [], howToUse, productInfo} = modalData || {}
  const {
    image: modalImage = '',
    rating = '',
    reviews = '',
    title = '',
    unitsSold = '',
  } = productInfo || {}
  const {data = [], title: howToUseTitle = ''} = howToUse || {}

  if (isEmpty(kitProducts)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <KitBreakdownContainer data-ismodalopen={isModalOpen}>
        <GenericSlider aspectRatio={aspectRatio} noOfSlides={slidesToShow}>
          {({imageDimensions}: {imageDimensions: number[]}) => {
            return kitProducts.map((kitProduct, index) => (
              <KitProduct
                key={kitProduct.name}
                {...kitProduct}
                isLastElement={index === kitProducts.length - 1}
                imageWidth={imageDimensions[0]?.toString()}
                imageHeight={imageDimensions[1]?.toString()}
                onKitProductClick={openModal}
              ></KitProduct>
            ))
          }}
        </GenericSlider>

        {showModal && (
          <div className="kit-modal" onClick={closeModal}>
            <div
              className="kit-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="scroll-container">
                <OptimizedImage
                  customClassName="modal-image"
                  source={modalImage}
                  aspectHeight="360"
                  aspectWidth="360"
                />

                <div className="kit-modal-data">
                  <div className="rating-and-price-details">
                    <div className="rating-and-price-details-item">
                      <div className="ratings">
                        <ReviewRatingStar
                          value={Number(rating)}
                          count={5}
                          size={16}
                          className="rating-container"
                        />
                        <Typography variant={TypographyVariants.TAG_REGULAR}>
                          {rating} ({reviews})
                        </Typography>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="rating-and-price-details-item">
                      <Typography variant={TypographyVariants.TAG_REGULAR}>
                        {unitsSold} units sold
                      </Typography>
                    </div>
                  </div>
                  <Typography
                    customClassName="kit-modal-product-name"
                    variant={TypographyVariants.HEADING_SMALL_BOLD}
                  >
                    {title}
                  </Typography>
                  {description.map((item) => (
                    <Typography
                      key={item}
                      variant={TypographyVariants.BODY_BASE_REGULAR}
                      customClassName="description-text"
                    >
                      {item}
                    </Typography>
                  ))}

                  <Typography
                    customClassName="how-to-use-title"
                    variant={TypographyVariants.SUBHEADING_BOLD}
                  >
                    {howToUseTitle}
                  </Typography>

                  {data.map((item) => (
                    <Typography
                      key={item}
                      variant={TypographyVariants.BODY_BASE_REGULAR}
                      customClassName="description-text"
                    >
                      {item}
                    </Typography>
                  ))}

                  <div className="okay-button-container">
                    <button className="okay-button" onClick={closeModal}>
                      <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
                        {'Okay'}
                      </Typography>
                    </button>
                  </div>

                  <div className="close-button-container" onClick={closeModal}>
                    ×
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </KitBreakdownContainer>
    </BaseWidgetComponent>
  )
}

export default KitBreakdown
