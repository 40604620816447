import styled from 'styled-components'

export const StyledVideoEmbed = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  .overlay-video {
    position: absolute;
    top: 10%;
    width: 100%;
    height: 70%;

    &[data-ispaused='true'] {
      height: 50%;
    }
  }
`

export const StyledPlayIcon = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 120px;
    height: 120px;
  }
`
