export const processCouponCode = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dLayerApplyCouponClick = dLayer.couponApplyClick

  const dataToReturn = {
    state: data.state ? 'applied' : 'removed',
    isFailed: data.failed || '',
    'Coupon Code': dLayerApplyCouponClick?.code || '',
  }
  return dataToReturn
}
