import {useCallback, useState, useMemo, useEffect, useRef} from 'react'
import noop from 'lodash/noop'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faTimes} from '@fortawesome/free-solid-svg-icons'
import isEmpty from 'lodash/isEmpty'

import {CheckboxList} from '../CheckboxList'
import {QuestionnaireContainer} from './Questionnaire.styles'
import NextButton from '../NextButton'
import SelectCategory from '../SelectCategoryScreen'
import OptionCard from '../OptionCard'
import {CategoryScreenCardsContainer} from '../SelectCategoryScreen/SelectCategory.styles'
import {ConsultStepsCard} from '../GetCustomerInfo/ConsultStepsCard'
import InfoCardSection from '../ScheduleScreen/InfoCardSection'

function Questionnaire(props) {
  const [selectedIds, setSelectedIds] = useState([])

  const {state = {}, actions = {}} = props
  const {
    isLoading = false,
    category = '',
    activeStep = 0,
    currentQuestion,
    stepsData = [],
    categoryScreenData = {},
    shouldSkipCategorySelection = false,
    skipCategorySelectionWith = '',
    pendingOrderAppointmentAvailable = true,
    isFutureAppointmentAvailable = true,
    isFutureAppointmentDataLoaded = false,
    user = {isLoggedIn: false},
    isUserHydration,
    selectedAnswers = {},
  } = state
  const {
    updateRequestBody = noop,
    getQuestionData = noop,
    updateCategory = noop,
    onBackPress = noop,
    updateStepsData = noop,
    incrementActiveStep = noop,
    toggleDismissModal = noop,
    getInitialQuestionData = noop,
    onConsultStepViewed = noop,
    onCategorySubmit = noop,
    skipCategorySelection = noop,
    resetQuestionnaire = noop,
    setDAViewed = noop,
    daExitClicked = noop,
    daBackClicked = noop,
    updateFuQuestions = noop,
    updateRecommendedProducts = noop,
    updateUserDiagnosis = noop,
  } = actions
  const {showCategoryCards = true} = categoryScreenData
  const {
    getCustomerInfo = {},
    infoCardSection = {},
    cardsOrder = [],
    categoryMapper = {},
  } = categoryScreenData

  const isCategoryScreen = useMemo(() => {
    if (currentQuestion?.question === 'category') {
      return true
    }

    return false
  }, [currentQuestion?.question])

  const isGetQuestionsCalled = useRef(false)
  const isBackButtonPressed = useRef(false)

  const isButtonDisabled = useMemo(() => {
    return selectedIds.length === 0
  }, [selectedIds])

  const onValueChange = useCallback((values) => {
    setSelectedIds(values)
    isGetQuestionsCalled.current = true
  }, [])

  const handleClose = useCallback(() => {
    daExitClicked()
    toggleDismissModal()
  }, [toggleDismissModal, daExitClicked])

  const handleNextPressed = useCallback(async () => {
    if (isCategoryScreen) {
      //analytics trigger
      onCategorySubmit(selectedIds[0].text)
      updateCategory(selectedIds[0].text)
    }

    updateFuQuestions({
      [currentQuestion?.text]: selectedIds[0].text,
    })

    const currentAnswerData = currentQuestion?.options.find((item) => {
      return item.option === selectedIds[0].id
    })
    const recommendedProducts = currentAnswerData?.recommended_products
    if (recommendedProducts) updateRecommendedProducts(recommendedProducts)

    updateRequestBody(selectedIds.map((item) => item.id))

    if (activeStep !== 0) {
      //analytics trigger
      onConsultStepViewed({
        step: 'questions',
        question: currentQuestion?.text,
        answer: selectedIds.map((item) => {
          return item.text
        }),
      })
      updateStepsData({selectedIds})
    }

    incrementActiveStep()

    await getQuestionData()
    isGetQuestionsCalled.current = true

    setSelectedIds([])
  }, [
    isCategoryScreen,
    selectedIds,
    updateRequestBody,
    activeStep,
    getQuestionData,
    updateCategory,
    updateStepsData,
    incrementActiveStep,
    onConsultStepViewed,
    currentQuestion,
    onCategorySubmit,
    updateFuQuestions,
    updateRecommendedProducts,
  ])

  useEffect(() => {
    setDAViewed()
  }, [])

  const handleBackPressed = useCallback(() => {
    setSelectedIds(stepsData[activeStep - 1]?.answers || [])
    daBackClicked()
    onBackPress()
    isBackButtonPressed.current = true
  }, [stepsData, activeStep, onBackPress, daBackClicked])

  useEffect(() => {
    async function fetchQuestionData() {
      await getInitialQuestionData()
      if (shouldSkipCategorySelection) {
        updateCategory(skipCategorySelectionWith)
        const mappedCategory = categoryMapper[skipCategorySelectionWith] || []
        // analytics trigger
        setTimeout(() => onCategorySubmit(skipCategorySelectionWith), 500)
        skipCategorySelection(mappedCategory)
      }
    }
    if (isEmpty(currentQuestion) && !isLoading) {
      fetchQuestionData()
    }
  }, [shouldSkipCategorySelection, onCategorySubmit])

  // TODO: this is better tracked from rn app as step of the flow and user info is available there
  useEffect(() => {
    if (!isUserHydration) {
      if (!user.isLoggedIn) {
        onConsultStepViewed({step: 'questionnaire'})
      }
    }
  }, [isUserHydration, user.isLoggedIn])

  // TODO: this is better tracked from rn app as step of the flow and user info is available there
  useEffect(() => {
    if (
      isCategoryScreen &&
      !pendingOrderAppointmentAvailable &&
      !isFutureAppointmentAvailable &&
      isFutureAppointmentDataLoaded
    ) {
      onConsultStepViewed({step: 'questionnaire'})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCategoryScreen,
    pendingOrderAppointmentAvailable,
    isFutureAppointmentAvailable,
  ])

  useEffect(() => {
    return () => resetQuestionnaire()
  }, [])

  const shouldHideNextButtonAndSkipPage = useMemo(
    () => currentQuestion?.input === 'radio',
    [currentQuestion?.input]
  )

  const isValidOption = useCallback(
    (option) => {
      const currentOptionsText = currentQuestion?.options?.map(({text}) => text)
      return currentOptionsText?.includes(option)
    },
    [currentQuestion?.options]
  )

  useEffect(() => {
    if (isBackButtonPressed.current) {
      isBackButtonPressed.current = false
      return
    }
    if (shouldHideNextButtonAndSkipPage && !isButtonDisabled) {
      if (isValidOption(selectedIds[0]?.text) && isGetQuestionsCalled.current) {
        handleNextPressed()
        isGetQuestionsCalled.current = false
      }
    }
  }, [
    handleNextPressed,
    isButtonDisabled,
    isValidOption,
    selectedIds,
    shouldHideNextButtonAndSkipPage,
  ])

  useEffect(() => {
    updateUserDiagnosis(selectedAnswers)
  }, [selectedAnswers, updateUserDiagnosis])

  if (isLoading) {
    return null
  }

  return (
    <QuestionnaireContainer>
      <div className="top-bar">
        {!isCategoryScreen && (
          <button
            onClick={handleBackPressed}
            className="top-bar-button back-button"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="chevron-icon" />{' '}
            Back
          </button>
        )}

        <button className="top-bar-button close-button" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      {isCategoryScreen && showCategoryCards ? (
        <SelectCategory
          state={{
            ...currentQuestion,
            isOptionSelected: !!selectedIds.length,
            selectedOption: selectedIds[0],
            categoryScreenData,
          }}
          actions={{onOptionClick: onValueChange}}
        />
      ) : (
        <>
          <div className="question-text">{currentQuestion?.text}</div>
          <CheckboxList
            data={currentQuestion?.options?.map((option) => ({
              ...option,
              id: option.option,
            }))}
            multipleSelect={currentQuestion?.input === 'checkbox'}
            values={selectedIds}
            onValueChange={onValueChange}
            CheckBoxItemRender={OptionCard}
            isCategoryScreen={isCategoryScreen}
            category={category}
          />
          {isCategoryScreen && (
            <CategoryScreenCardsContainer order={cardsOrder}>
              {getCustomerInfo?.show && (
                <ConsultStepsCard state={getCustomerInfo} />
              )}
              {infoCardSection?.show && (
                <InfoCardSection state={infoCardSection} />
              )}
            </CategoryScreenCardsContainer>
          )}
        </>
      )}

      {!shouldHideNextButtonAndSkipPage && (
        <NextButton
          state={{label: 'NEXT', isDisabled: isButtonDisabled, isLoading}}
          actions={{onClick: handleNextPressed}}
          onClick={handleNextPressed}
        >
          Next
        </NextButton>
      )}
    </QuestionnaireContainer>
  )
}

export default Questionnaire
