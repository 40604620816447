import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 18px;

  .input-label {
    font-weight: var(--brand-font-weight-bold);
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.380208px;
    /* Fern Green */
    color: #42b682;
    color: var(--category-primary-color);
    display: block;
    margin-left: 8px;
  }

  .stars-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
  }
  .star-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background: transparent;
    font-size: 12px;
    line-height: 14px;
    font-weight: var(--brand-font-weight-normal);
    cursor: pointer;
    flex-grow: 1;

    &:hover svg.empty-star .star-path {
      fill: #ffc820;
      stroke: #ffc820;
      opacity: 0.8;
    }

    .label {
      margin-top: 5px;
      color: var(--brand-primary-black-color);
    }
  }
`
