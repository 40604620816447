import React from 'react'

import {SVGContainer} from '../DirectAssesment.styles'

function CalendarIcon(props) {
  const {className, style, category = ''} = props

  return (
    <SVGContainer
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      style={style}
      className={className}
      data-category={category}
    >
      <path d="M3.45092 22C1.56091 22 0.0125374 20.4506 0 18.547V6.88199C0.0125374 4.97944 1.56091 3.43107 3.45092 3.43107H4.58346V0.706273C4.58346 0.316569 4.90003 0 5.28974 0C5.67944 0 5.99601 0.322838 5.99601 0.706273V3.43107H13.7243V0.706273C13.7243 0.323883 14.0482 0 14.4305 0C14.8129 0 15.1368 0.316569 15.1368 0.706273V3.43107H16.2694C18.1719 3.43107 19.7203 4.97944 19.7203 6.88199V18.548C19.7203 20.4506 18.1719 21.999 16.2694 21.999H3.45092V22ZM1.4115 18.5491C1.42822 19.6754 2.3424 20.5885 3.45092 20.5885H16.2704C17.3946 20.5885 18.3098 19.6743 18.3098 18.5491V10.5669H1.41255V18.5491H1.4115ZM3.45092 4.84361C2.3424 4.84361 1.42822 5.75571 1.4115 6.87781V9.15439H18.3088V6.88199C18.3088 5.7578 17.3946 4.84257 16.2694 4.84257H15.1368V5.28974C15.1368 5.67944 14.8202 5.99601 14.4305 5.99601C14.0408 5.99601 13.7243 5.67317 13.7243 5.28974V4.84257H5.99601V5.28974C5.99601 5.67213 5.67213 5.99601 5.28974 5.99601C4.90735 5.99601 4.58346 5.67944 4.58346 5.28974V4.84257H3.45092V4.84361Z" />
    </SVGContainer>
  )
}

export default CalendarIcon
