import {useConfigStore} from '@mosaic-wellness/redux-action-library'
import {useCallback} from 'react'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useCommsBasicDetails() {
  const {analytics} = useConfigStore()

  const onBasicDetailsViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.BASIC_DETAILS_VIEWED)
  }, [analytics])

  const onBasicDetailQuestionAnswered = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.BASIC_DETAILS_QUESTION_ANSWERED, data)
    },
    [analytics]
  )

  const onBasicDetailSubmitted = useCallback(() => {
    analytics.trigger(EVENT_MAP.BASIC_DETAILS_DETAIL_SUBMITTED)
  }, [analytics])

  return {
    onBasicDetailsViewed,
    onBasicDetailQuestionAnswered,
    onBasicDetailSubmitted,
  }
}

export {useCommsBasicDetails}
