import styled from 'styled-components'

export const PillContainer = styled.div`
  display: flex;
  gap: var(--dls-size-8);
  padding: var(--dls-size-8) var(--dls-size-12);
  justify-content: center;
  align-items: center;
  border: var(--dls-size-1) solid var(--dls-divider-light-color);
  border-radius: 200px;
  cursor: pointer;
  .pill-value-text {
    color: var(--dls-secondary-text-colors);
  }
  .icon-container {
    width: var(--dls-size-16);
    height: var(--dls-size-16);
    display: flex;
    align-self: center;
    justify-content: center;
  }
`
