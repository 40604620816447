import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 40px;
`

export const StyledCta = styled.button`
  background-color: var(--brand-primary-black-color);
  border-radius: 16px;
  padding: 16px 27px;
  border: none;
  font-weight: var(--brand-font-weight-normal);
  color: var(--brand-primary-white-color);
  cursor: pointer;

  @media (min-width: 768px) {
    padding: 18px 65px;
    border-radius: 20px;
    font-weight: var(--brand-font-weight-bold);
  }
`

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 6;

  .content {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 14px 14px 0 0;

    padding: 30px 15px;
    opacity: 1;
  }

  h3 {
    font-family: var(--brand-font-family-title);
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  .modal-body {
    height: 300px;
    overflow-y: auto;
  }

  @media (min-width: 768px) {
    align-items: center;

    .content {
      margin: 200px;
      padding: 80px 40px;
      border-radius: 30px;
    }
    h3 {
      font-weight: normal;
      font-size: 60px;
      line-height: 50px;
    }
    .modal-body {
      height: auto;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: -28px;
  transform: translateX(-50%);
  left: 50%;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 8px;
  width: 48px;
  height: 48px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 768px) {
    top: -58px;
    left: unset;
    transform: translateX(10%);
    right: 0;
    height: 50px;
    width: 50px;
  }
`
