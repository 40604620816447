import isEmpty from 'lodash/isEmpty'
import {
  IOrderSummary,
  IBillingDetails,
  Items,
  IPageBanner,
} from 'src/components/ThankYouV2/ThankYouV2.interface'

type anaylticsDataType = {
  orderSummary: IOrderSummary
  billingDetails: IBillingDetails
  pageBanner: IPageBanner
  chatFlow: boolean
}

const checkIfItemsNotEmpty = (arr: any) => Array.isArray(arr) && arr.length > 0

type mappedKeys = 'name' | 'sku' | 'productUrlKey' | 'category'
const keysToMap: mappedKeys[] = ['name', 'sku', 'productUrlKey', 'category']

export const getAnalyticsData = (data: anaylticsDataType) => {
  const {billingDetails, orderSummary, pageBanner, chatFlow} = data
  const {items} = orderSummary || {}
  const {receiptV2} = billingDetails || {}
  const {analyticsInfo} = receiptV2 || {}
  const {
    discountAmount = 0,
    shippingAmount = 0,
    itemTotal = 0,
  } = analyticsInfo || {}
  const genericMapper = (val: any, key: string) => val?.[key] || ''
  const propertiesMapperForOrderData = (
    keysToMap: string[],
    items: Items[] | null | undefined
  ) => {
    let mappedData: any = {}
    if (isEmpty(items)) {
      return ''
    }
    keysToMap.forEach((element) => {
      const val =
        items?.map((val: any) => genericMapper(val, element)).join(',') || ''
      mappedData[element] = val
    })
    return mappedData
  }
  const mappedOrderData = propertiesMapperForOrderData(keysToMap, items)

  const itemsInOrder = checkIfItemsNotEmpty(items)
  const totalItems = itemsInOrder ? items?.length : 0
  const freeGiftItems = itemsInOrder
    ? items?.filter((item) => item.isFree)?.length
    : 0

  return {
    mappedOrderData,
    couponCode: orderSummary?.couponCode,
    chargedId: orderSummary?.chargedId,
    rxProduct: orderSummary?.rxProduct,
    type: orderSummary?.type,
    couponCodeApplied: orderSummary?.couponCode !== '',
    totalItems,
    freeGiftItems,
    total: itemTotal,
    shippingCharged: shippingAmount > 0,
    shipping: shippingAmount,
    discount: discountAmount,
    orderType: orderSummary?.type,
    orderId: pageBanner?.orderId,
    chatFlow,
    chatProduct: orderSummary?.drugSlugs?.toString(),
  }
}
