import {useCallback} from 'react'
import {analyticsTrigger} from 'src/analytics'
export const useCartUpSell = () => {
  const handleUpSellSelection = useCallback((selection) => {
    analyticsTrigger('cartUpSellSelection', selection)
  }, [])
  const handleUpSellShow = useCallback((cartItem) => {
    analyticsTrigger('cartUpSellShow', cartItem)
  }, [])

  return {
    handleUpSellSelection,
    handleUpSellShow,
  }
}
