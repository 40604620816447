import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: inline-block;
  margin-right: 2px;
  svg {
    display: inline;

    :not(:last-child) {
      margin-right: 1px;
    }
  }

  @media (min-width: 768px) {
    svg {
      :not(:last-child) {
        margin-right: 3px;
      }
    }
  }
`
