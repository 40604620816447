import {
  getStoreInstance,
  useAuth,
  useUserStore,
  HttpClient,
  useConfigStore,
} from '@mosaic-wellness/redux-action-library'
import {useRouter} from 'next/router'
import React, {useCallback} from 'react'
import {findElementTop} from 'src/utils/findElementPosition'
import {useTruecallerLogin} from './useTruecallerLogin'
import {useExplicitATCTracking} from './tracking/useExplicitATCTracking'
import {useProductClickTracking} from './tracking/useProductClick'
import {useProductNavigation} from './product/useProductNavigation'
import {useUpdateItemToCart} from './updateCart/useUpdateItemToCart'
import {localStorageMethods} from 'src/utils'
import {useAuthorizedNav} from './useAuthorizedNav'
import {analyticsTriggerBypassProcessor} from 'src/analytics'
import { IUser } from '@mosaic-wellness/redux-action-library/build/redux.reducers/user'

export enum APP_ACTION {
  AUTH = 'AUTH',
  LINK = 'LINK',
  SPA_LINK = 'SPA_LINK',
  SCROLL = 'SCROLL',
  LOGOUT = 'LOGOUT',
  REPLACE = 'REPLACE',
  ADD_TO_CART = 'ADD_TO_CART',
  PRODUCT_NAVIGATION = 'PRODUCT_NAVIGATION',
  ROUTER_REPLACE = 'ROUTER_REPLACE',
  STORE_REF_AND_NAV = 'STORE_REF_AND_NAV',
  APP_NAV = 'APP_NAV',
  RESET_NAV_STACK = 'RESET_NAV_STACK',
  REPLACE_NAV = 'REPLACE_NAV',
  APP_AUTH_NAV = 'APP_AUTH_NAV',
  API_DRIVEN_ACTION = 'API_DRIVEN_ACTION',
  ENDPOINT = 'ENDPOINT',
  START_ON_BOARDING = 'START_ON_BOARDING',
  TRIGGER_EVENTS = 'TRIGGER_EVENTS',
  NAVIGATE_BACK_WEB = 'NAVIGATE_BACK_WEB',
  USER_ENDPOINT = 'USER_ENDPOINT',
}

export interface IHandleAppActionParams {
  action: APP_ACTION
  actionData: Record<string, any>
  analyticsPayload?: {
    eventName: string
    eventData?: Record<string, any>
  }
}

/* Following events to be added in the future 'REFETCH_MINI_CART', 'REFETCH_USER', 'UPDATE_WIDGET' */

function useAppActions() {
  const {
    actions: {setShowLoginModal},
  } = useAuth()
  const router = useRouter()
  const {
    user,
    logoutUser,
  } = useUserStore()
  const {analytics} = useConfigStore()
  const {
    state: {isTruecallerEnabled, isPollingInProgress},
    actions: {triggerTruecallerLogin},
  } = useTruecallerLogin({
    triggerNormalLoginCallback: () =>
      setShowLoginModal({
        isOpen: true,
        triggeredFrom: '',
      }),
    source: 'generic',
  })
  const {trackProductCardClick} = useProductClickTracking()
  const {navigateToProduct} = useProductNavigation()
  const {trackAddToCart} = useExplicitATCTracking()
  const {addToCart} = useUpdateItemToCart()
  const axiosInstance = HttpClient.getAxiosInstance()

  const {handleAuthorizedNav} = useAuthorizedNav()

  const handleAppAction = useCallback(
    async (params: IHandleAppActionParams) => {
      const {action = '', actionData = {}} = params || {}
      const {
        label = '',
        link = '',
        source = '',
        newTab = false,
        offsetTop = 0,
        web_url = '',
        needsAuth = false,
      } = actionData || {}

      const apiResponseEvents = (events: any = []) => {
        if (Array.isArray(events)) {
          events?.forEach((event: any) => {
            handleAppAction({
              action: event.actionName,
              actionData: event.actionData,
            })
          })
        }
      }

      switch (action) {
        case APP_ACTION.AUTH: {
          const {triggeredFrom = ''} = actionData || {}
          if (isTruecallerEnabled) {
            triggerTruecallerLogin()
          } else {
            setShowLoginModal({
              isOpen: true,
              triggeredFrom: triggeredFrom || label,
              redirectTo: link,
            })
          }
          break
        }

        case APP_ACTION.APP_NAV: {
          const {shouldReplace = false} = actionData || {}
          if (!link) return
          if (needsAuth) {
            const {isLoggedIn} = getStoreInstance().getState().user ?? {}

            if (!isLoggedIn) {
              handleAuthorizedNav({link}, `Generic Action - ${label}`)
              return
            }
          }
          if (shouldReplace) {
            router.replace(link)
            return
          }
          router.push(link)
          break
        }

        case APP_ACTION.RESET_NAV_STACK: {
          if (!link) return

          router.replace(link)
          break
        }

        case APP_ACTION.REPLACE_NAV: {
          const {needsAuth = false} = actionData || {}
          if (!link) return
          if (needsAuth) {
            handleAuthorizedNav({link}, `Generic Action - ${label}`)
          } else {
            router.push(link)
          }
          break
        }

        case APP_ACTION.APP_AUTH_NAV: {
          if (!link) return

          router.push(link)
        }

        case APP_ACTION.AUTH: {
          const {triggeredFrom = ''} = actionData || {}
          if (isTruecallerEnabled) {
            triggerTruecallerLogin()
          } else {
            setShowLoginModal({
              isOpen: true,
              triggeredFrom: triggeredFrom || label,
              redirectTo: link,
            })
          }
          break
        }

        case APP_ACTION.PRODUCT_NAVIGATION: {
          const {slugUrl = '', urlKey = ''} = actionData || {}
          trackProductCardClick({...actionData, source})
          navigateToProduct({slugUrl, urlKey})
          break
        }

        case APP_ACTION.ADD_TO_CART: {
          const {sku} = actionData || {}
          trackAddToCart(actionData, source)
          addToCart({sku})
          break
        }

        case APP_ACTION.LINK: {
          if (newTab) {
            window.open(link, '_blank')
          } else {
            window.location.href = link
          }
          break
        }

        case APP_ACTION.REPLACE: {
          window.location.replace(link)
          break
        }

        case APP_ACTION.SPA_LINK: {
          router.push(link)
          break
        }

        case APP_ACTION.SCROLL: {
          const node = document.querySelector(link)
          if (node) {
            window.scrollTo({
              left: 0,
              top: findElementTop(node) + offsetTop,
              behavior: 'smooth',
            })
          }

          break
        }

        case APP_ACTION.APP_NAV:
        case APP_ACTION.APP_AUTH_NAV: {
          if (!web_url) return

          window.location.href = web_url
          break
        }

        case APP_ACTION.REPLACE_NAV: {
          if (!web_url) return

          window.location.href = web_url
          break
        }

        case APP_ACTION.LOGOUT:
          logoutUser()
          break

        case APP_ACTION.ROUTER_REPLACE: {
          router.replace(link)
          break
        }

        case APP_ACTION.STORE_REF_AND_NAV: {
          localStorageMethods.set({
            keyName: 'urlPostAction',
            value: window.location.href,
          })
          if (newTab) {
            window.open(link, '_blank')
          } else {
            window.location.href = link
          }
          break
        }

        case APP_ACTION.API_DRIVEN_ACTION: {
          if (!actionData) return

          const {
            endpointType = '',
            endpointUrl = '',
            endpointBody = {actionObj: {}},
            onSuccessActions = [],
          } = actionData || {}

          axiosInstance({
            method: endpointType,
            url: endpointUrl,
            data: endpointBody,
          }).then((res) => {
            if (onSuccessActions) {
              apiResponseEvents(onSuccessActions)
            }
          })
          break
        }
        case APP_ACTION.ENDPOINT: {
          if (!actionData) return

          const {
            endpointType = '',
            endpointUrl = '',
            endpointBody = {actionObj: {}},
          } = actionData || {}

          axiosInstance({
            method: endpointType,
            url: endpointUrl,
            data: endpointBody,
          }).then((res) => {
            const events = res?.data?.data?.events
            if (events) {
              apiResponseEvents(events)
            }
          })
          break
        }

        case APP_ACTION.START_ON_BOARDING: {
          const {type = '', shouldReplace = false} = actionData || {}
          if (type) {
            const urlSearchParams = new URLSearchParams(actionData).toString()

            if (shouldReplace) {
              return router.replace(`/onboarding?${urlSearchParams}`)
            }
            return router.push(`/onboarding?${urlSearchParams}`)
          } else {
            return router.back()
          }
        }

        case APP_ACTION.TRIGGER_EVENTS: {
          const {eventName = '', eventData = {}} = actionData
          analyticsTriggerBypassProcessor(eventName as string, eventData)
          break
        }

        case APP_ACTION.NAVIGATE_BACK_WEB: {
          if (window.history.length > 1) {
            window.history.back()
          } else {
            router.replace('/')
          }
          break
        }

        case APP_ACTION.USER_ENDPOINT: {
          try {
            if (!actionData) {
              return
            }
            const {
              endpointType = '',
              endpointUrl = '',
              endpointBody = {actionObj: {}},
              userProperties = [],
            } = actionData || {}

            userProperties.forEach((userProperty: string) => {
              if (user.hasOwnProperty(userProperty)) {
                endpointBody[userProperty] = user[userProperty as keyof IUser]
              }
            })

            const res = await axiosInstance({
              method: endpointType,
              url: endpointUrl,
              data: endpointBody,
            })
            const {
              events,
              location,
              locationAction = 'REPLACE',
            } = res?.data?.data || {}
            if (Array.isArray(events)) {
              events?.forEach((event: any) => {
                handleAppAction(event)
              })
            }

            if (location) {
              handleAppAction({
                action: locationAction,
                actionData: {
                  link: location,
                },
              })
            }
          } catch (e) {
            //noop
          }
        }
      }
    },
    [
      logoutUser,
      trackAddToCart,
      addToCart,
      trackProductCardClick,
      navigateToProduct,
      isTruecallerEnabled,
      triggerTruecallerLogin,
      setShowLoginModal,
      router,
      analytics,
      user,
    ]
  )

  const handleAppActions = React.useCallback(
    (actions: IHandleAppActionParams[]) => {
      if (Array.isArray(actions)) {
        actions.forEach((action) => {
          handleAppAction(action)
        })
      }
    },
    [handleAppAction]
  )

  return [
    {
      isTruecallerLoginInProgress: isPollingInProgress,
    },
    {handleAppAction, handleAppActions},
  ]
}

export default useAppActions
