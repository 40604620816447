import styled from 'styled-components'

export const OptionDropDownContainer = styled.div`
  display: flex;
  .option-dropdown__iconContainer {
    background-color: var(--category-primary-bg1-color);
    height: 30px;
    width: 30px;
    display: grid;
    place-content: center;
    border-radius: 6px;
    flex-shrink: 0;
  }
  .option-dropdown__icon {
    height: 20px;
    width: 20px;
  }

  .option-toggle__container {
    color: var(--category-primary-color);
    margin-left: auto;
  }

  &[data-is-drop-down-open='true'] {
    .option-toggle__container {
      transform: rotate(180deg);
    }
  }

  .option-dropdown__selectedItem {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .option-dropdown__container {
    align-self: flex-end;
    display: flex;
    margin-left: 12px;
    width: 100%;
    border-bottom: 1px solid var(--category-primary-bg1-color);
    cursor: pointer;
  }

  .external-dropdown__container {
    margin-left: 12px;
    border: 1px solid red;
  }

  @media (min-width: 768px) {
    .option-dropdown__iconContainer {
      height: 40px;
      width: 40px;
    }
    .option-dropdown__icon {
    }

    .option-dropdown__container {
      margin-left: 20px;
    }

    .option-dropdown__selectedItem {
      font-size: 16px;
      margin-bottom: 12px;
    }

    .external-dropdown__container {
      margin-left: 20px;
    }
    .appointment_card__optionTitle {
      font-size: 18px;
    }

    .appointment_card__optionTitleAlt {
      font-size: 18px;
    }
  }
`

export const OptionDropDownWrapper = styled.div`
  margin-top: 12px;
  .appointment_card__optionTitle {
    font-size: 14px;
    font-weight: var(--brand-font-weight-400);
    color: var(--brand-primary-grey-color);
    margin-bottom: 6px;
    opacity: 50%;
  }

  .appointment_card__optionTitleAlt {
    font-size: 14px;
    font-weight: var(--brand-font-weight-400);
    color: var(--brand-primary-black-color);
    margin-bottom: 6px;
  }

  @media (min-width: 768px) {
    margin-top: 16px;
    .appointment_card__optionTitle {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .appointment_card__optionTitleAlt {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
`

export const ExternalDropDownContainer = styled.div`
  margin-left: 40px;
  padding: 0;
  @media (min-width: 768px) {
    margin-left: 55px;
  }
`

export const DropDownList = styled.ul`
  margin: 0;
  width: 76%;
  position: absolute;
  padding: 0;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  background-color: var(--brand-primary-white-color);
  border-radius: 0 0 12px 12px;
  overflow-y: scroll;
  max-height: 230px;
  z-index: 2;

  @media (min-width: 768px) {
    width: 355px;
  }
`
export const ListItem = styled.li`
  width: 100%;
  padding: 10px;
  font-size: 14px;

  &:hover {
    background-color: var(--category-primary-bg1-color);
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`
