import {CoreContextProvider} from '@web-core'
import React from 'react'
import {useAppActions} from 'src/hooks/useAppActions/useAppActions'

function CoreContextFeMono({children}: React.PropsWithChildren<{}>) {
  const {handleAppAction, handleAppActions} = useAppActions()

  // return <>{children}</>
  return (
    <CoreContextProvider
      genericAction={handleAppAction}
      handleGenericActions={handleAppActions}
      parentAppBaseLink=""
    >
      {children}
    </CoreContextProvider>
  )
}

export default CoreContextFeMono
