import React from 'react'
import {LoadingState} from '../../shared/src/ComponentsV2/common'
import {StyledLoading} from './AllReviewsLoading.styles'
import Responsive from '../../shared/src/ComponentsV2/HOC/Responsive'

function AllReviewsTopSkeleton() {
  return (
    <StyledLoading>
      <div>
        <div className="center">
          <LoadingState cardHeight={30} numberOfCards={1} />
        </div>
        <div className="full-width">
          <LoadingState cardHeight={36} />
        </div>
        <div className="images">
          <LoadingState cardHeight={50} cardWidth="170px" />
          <LoadingState cardHeight={50} cardWidth="170px" />
          <LoadingState cardHeight={50} cardWidth="170px" />
        </div>
        <div className="left-half">
          <LoadingState cardHeight={30} numberOfCards={1} />
        </div>
        <br />
      </div>
    </StyledLoading>
  )
}
export default Responsive(AllReviewsTopSkeleton)
