import React from 'react'
import { LoadingState } from '../../shared/src/ComponentsV2/common'
import { SubcategoryStyledLoading } from './SubCatLoading.styles'
import Responsive from '../../shared/src/ComponentsV2/HOC/Responsive'


function SubCategorySkeleton({ isMobile = false, }) {
    return (
        <>
            {isMobile ? (<SubcategoryStyledLoading>
                <LoadingState cardHeight={149} />
                <LoadingState cardHeight={28} />
                <LoadingState cardHeight={66} />
                <div className='subcategory-horizontal-container'>
                    <div className='subcategory-left'>
                        <LoadingState cardHeight={194} />
                    </div>
                    <div className='subcategory-right'>
                        <LoadingState cardHeight={194} />
                    </div>
                </div>
                <div className='subcategory-text-horizontal-container'>
                    <div className='subcategory-text-block'>
                        <LoadingState cardHeight={20} />
                    </div>
                </div>
                <LoadingState cardHeight={240} />
                <LoadingState cardHeight={28} />

            </SubcategoryStyledLoading>)
                : (<SubcategoryStyledLoading>
                    <LoadingState cardHeight={350} />
                    <div className='subcategory-left'>
                        <LoadingState cardHeight={54} />
                        <LoadingState cardHeight={96} />
                    </div>

                    <div className='subcategory-horizontal-container'>
                        <div className='subcategory-left'>
                            <LoadingState cardHeight={264} />
                        </div>
                        <div className='subcategory-right'>
                            <LoadingState cardHeight={264} />
                        </div>
                    </div>
                    <div className='subcategory-text-horizontal-container'>
                        <div className='subcategory-text-block'>
                            <LoadingState cardHeight={54} />
                        </div>
                    </div>
                    <LoadingState cardHeight={240} />
                    <div className='subcategory-left'>
                        <LoadingState cardHeight={54} />
                    </div>
                </SubcategoryStyledLoading>)
            }
        </>

    )
}
export default Responsive(SubCategorySkeleton)