import noop from 'lodash/noop'
import React, {useCallback, useEffect, useRef} from 'react'

import LightCloseIcon from '../../../assets/icons/close1.svg'
import CloseIcon from '../../../assets/icons/close.svg'
import {
  CloseButton,
  StyledContent as ModalContent,
  StyledTitle as ModalTitle,
  StyledModal,
} from './styles'
import {useToggleBodyScroll} from 'src/hooks/useToggleBodyScroll'

const Modal = ({
  onClose = noop,
  children,
  showModal,
  title = '',
  additionalContentClass = '',
  additionalModalClass = '',
  addtionalButtonClass = '',
  hideCloseButton = false,
  cancelCloseIcon = false,
  isPadding = true,
  isFloatingMobileModal = false,
  disableClose = false,
}) => {
  const modalRef = useRef()
  const scrollDisabled = useRef(false)

  const {disableBodyScroll, enableBodyScroll} = useToggleBodyScroll()

  const handleClick = useCallback(
    (clickEvent) => {
      if (clickEvent.target === modalRef.current && !disableClose) {
        onClose()
      }
    },
    [disableClose, onClose]
  )

  useEffect(() => {
    if (showModal && !scrollDisabled.current) {
      disableBodyScroll()
      scrollDisabled.current = true
    } else if (!showModal && scrollDisabled.current) {
      enableBodyScroll()
      scrollDisabled.current = false
    }
  }, [showModal, disableBodyScroll, enableBodyScroll])

  useEffect(() => {
    return () => {
      if (scrollDisabled.current) {
        enableBodyScroll()
      }
    }
  }, [enableBodyScroll])

  useEffect(() => {
    return () => {
      // enable background scroll when unmount
      window.document.body.style.overflow = 'auto'
    }
  }, [enableBodyScroll])

  if (!showModal) {
    return null
  }

  return (
    <StyledModal
      ref={modalRef}
      onClick={handleClick}
      className={additionalModalClass}
      data-is-floating-modal={isFloatingMobileModal}
    >
      <ModalContent
        className={additionalContentClass}
        data-identifier="modal-content"
        isPadding={isPadding}
        data-is-floating-modal={isFloatingMobileModal}
      >
        {!hideCloseButton && (
          <CloseButton onClick={onClose} className={addtionalButtonClass}>
            <img
              src={cancelCloseIcon ? LightCloseIcon : CloseIcon}
              loading="lazy"
              alt="close"
            />
          </CloseButton>
        )}
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
      </ModalContent>
    </StyledModal>
  )
}

export default Modal
