import styled from 'styled-components'

export const SubcategoryStyledLoading = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  
  .subcategory-left {
    width: 50%;
    margin-right: 10px;

  }

  .subcategory-right {
    width: 50%;
    align-self: flex-end;
    margin-left: 10px;
  }

  .subcategory-horizontal-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .subcategory-text-horizontal-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .subcategory-text-block {
    width: 40%;
  }
`
