import {SESSION_STORAGE_CONSTANTS} from './sessionStorage/sessionStorageConstants'
import sessionStorageMethods from './sessionStorage/sessionStorageMethods'

export const checkIsApp = () => {
  return (
    (typeof window !== 'undefined' && window?.customDataLayer?.isApp) || false
  )
}

/** @description An alternated function to check isApp, it reads the isApp key's value from
 *  session-storage which gets set on webviewIntermidiateTransfer file on initialization of webview
 */
export const checkIsAppViaParam = () => {
  if (typeof window !== 'undefined') {
    try {
      const isApp = sessionStorageMethods.get(SESSION_STORAGE_CONSTANTS.IS_APP)
      return Boolean(isApp)
    } catch (e) {
      return false
    }
  }
  return false
}
