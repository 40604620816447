export const processAppointmentSummary = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category, source, language, iaBooked} = data

  return {
    Category: category,
    Source: source,
    Language: language,
    'IA Booked': iaBooked ? 'Y' : 'N',
  }
}
