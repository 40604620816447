import React from 'react'
import {StickyFooterCtaContainer} from './StickyCtaFooter.styles'
import {IStickyCtaFooterProps} from './StickyCtaFooter.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import isEmpty from 'lodash/isEmpty'
import {Typography, TypographyVariants} from '@web-components'
import {useGenericActions} from '../../hooks'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function StickyCtaFooter(props: IStickyCtaFooterProps) {
  const {id = '', type = '', widgetData, layout} = props
  const {ctas} = widgetData

  const {genericAction} = useGenericActions()

  if (isEmpty(ctas)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <StickyFooterCtaContainer>
        {ctas.map((cta) => (
          <button
            key={cta.label}
            className="cta-button"
            data-type={cta.type}
            onClick={() =>
              genericAction(interceptAndEnhanceAction(cta.action, id))
            }
          >
            <Typography variant={TypographyVariants.CTA_LABEL_BIG}>
              {cta.label}
            </Typography>
          </button>
        ))}
      </StickyFooterCtaContainer>
    </BaseWidgetComponent>
  )
}

export default StickyCtaFooter
