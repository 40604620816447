import styled from 'styled-components'

export const StyledWriteReviewWrapper = styled.div`
  width: 100%;
  padding: 8px;
  max-height: 90vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  .write-review__product-snippet {
    display: flex;
    align-items: center;
    background: var(--category-primary-bg1-color);
    border-radius: 12px;
    padding: 8px;
  }

  .write-review__product-snippet span {
    width: 52px;
    height: 52px;
    margin-right: 0.5rem;
  }

  .write-review__product-snippet span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  .write-review__product-snippet p {
    margin: 0;
    font-weight: var(--brand-font-weight-bold);
    font-size: 14px;
    line-height: 16px;
  }

  @media (min-width: 768px) {
    width: 360px;
    background: #ffffff;
    border-radius: 20px;
  }
`

export const StyledButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`

const StyledCta = styled.button`
  border-radius: 16px;
  padding: 14px 0;
  font-family: var(--brand-font-family-text);
  font-weight: var(--brand-font-weight-bold);
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  flex-grow: 1;
  border: none;

  &[data-disabled='true'] {
    opacity: 0.2;
  }
`

export const StyledSave = styled(StyledCta)`
  background: var(--category-primary-color);
  color: var(--brand-primary-white-color);
  margin-left: 8px;
  flex: 1;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const StyledCancel = styled(StyledCta)`
  background: var(--brand-primary-black-color);
  color: var(--brand-primary-white-color);
  flex: 1;
`
