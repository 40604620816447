import isEmpty from 'lodash/isEmpty'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {useIntersection} from '../../hooks/useIntersection'
import {ImageCarouselWrapper} from './ImageCarousal.styles'
import MemoizedImageCarouselSlide from './ImageCarouselSlide'
import MobileNavArrows from './MobileNavArrows'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import CarouselDots from './CarouselDots/CarouselDots'
import {getImageDimensionFromAspectRatioFor360} from '@web-components'

interface Video {
  source: string
  posterImage: string
  autoplay?: boolean // Optional property for autoplay
  controls?: boolean // Optional property to show controls
  loop?: boolean // Optional property for looping
  type?: string // Optional property for video mime type (defaults to 'video/mp4')
  borderRadius?: number // Optional property for border radius
  playIcon?: string // Optional property for custom play icon
  playsInline?: boolean // Optional property for inline video playback
}

interface CarouselImage {
  src?: string // Optional for video type
  type?: 'image' | 'video' // Define allowed types
  customVideo?: Video // Required for video type, optional for image type
}

interface ImageCarouselProps {
  carouselImages?: CarouselImage[]
  carouselConfig: {
    aspectRatio: string
    showDots?: boolean
    dotsOverlay?: boolean
    aspectRatioDecimalValue: number
  }
}

const ImageCarousel = ({
  widgetData,
  layout,
  type,
  id,
}: BaseWidgetProps<ImageCarouselProps>) => {
  const {carouselImages = [], carouselConfig} = widgetData || {}
  const {
    aspectRatio = '1',
    showDots = true,
    dotsOverlay = true,
    aspectRatioDecimalValue = 1,
  } = carouselConfig || {}

  const {nodeRef, isIntersecting} = useIntersection()
  const [currentIndex, setCurrentIndex] = useState(0)
  const containerRef = useRef(null)

  const [aspectWidth, aspectHeight] = useMemo(
    () =>
      getImageDimensionFromAspectRatioFor360({
        aspectRatio: aspectRatioDecimalValue,
        noOfSlides: 1,
        skipPaddings: true,
      }),
    [aspectRatioDecimalValue]
  )

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current as HTMLDivElement // Assert type
      if (container) {
        const scrollLeft = container.scrollLeft
        const itemWidth = container.offsetWidth
        const newIndex = Math.round(scrollLeft / itemWidth)
        setCurrentIndex(newIndex)
      }
    }
  }, [])

  const handleNext = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current as HTMLDivElement
      const containerWidth = container.offsetWidth

      if (currentIndex >= carouselImages.length - 1) {
        container.scrollLeft = 0
      } else {
        container.scrollLeft += containerWidth
      }
    }

    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, carouselImages.length - 1)
    )
  }, [currentIndex, carouselImages.length])
  const handlePrev = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current as HTMLDivElement
      const containerWidth = container.offsetWidth

      if (container.scrollLeft !== 0) {
        container.scrollLeft -= containerWidth
      }

      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0))
    }
  }, [])

  useEffect(() => {
    const container = containerRef.current as HTMLDivElement | null

    const handleInitialScroll = () => {
      if (container) {
        const scrollLeft = container.scrollLeft
        const itemWidth = container.offsetWidth
        const newIndex = Math.round(scrollLeft / itemWidth)
        setCurrentIndex(newIndex)
      }
    }

    if (isIntersecting) {
      if (container) {
        container.addEventListener('scroll', handleScroll)
        handleInitialScroll()
      }
    } else {
      handleInitialScroll()
    }

    return () => {
      if (containerRef.current) {
        ;(containerRef.current as HTMLDivElement)?.removeEventListener(
          'scroll',
          handleScroll
        )
      }
    }
  }, [currentIndex, handleScroll, isIntersecting])

  if (isEmpty(carouselImages)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <ImageCarouselWrapper ref={nodeRef} aspectRatio={aspectRatio}>
        {currentIndex !== 0 && (
          <MobileNavArrows
            onClick={handlePrev}
            className="carousel-mobile-nav left"
          />
        )}
        <div className="image-container" ref={containerRef}>
          {carouselImages.map((image: any, index: number) => {
            const {src = '', type = '', customVideo = {}} = image || {}

            return (
              <MemoizedImageCarouselSlide
                key={index}
                state={{
                  key: index,
                  type,
                  src,
                  alt: `Image ${index + 1}`,
                  currentIndex,
                  customVideo: customVideo,
                  index,
                  aspectWidth,
                  aspectHeight,
                }}
              />
            )
          })}
        </div>
        <MobileNavArrows
          onClick={handleNext}
          className="carousel-mobile-nav right"
        />
        {showDots && (
          <CarouselDots
            items={carouselImages as any}
            activeSlideIndex={currentIndex}
            dotsOverlay={dotsOverlay}
          />
        )}
      </ImageCarouselWrapper>
    </BaseWidgetComponent>
  )
}

export default ImageCarousel
