import React from 'react'
import {SocialReviewCardContainer} from './SocialReviewCard.styles'
import {ISocialReviewCard} from './SocialReviewCard.interface'
import {
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import isEmpty from 'lodash/isEmpty'

function SocialReviewCard(props: ISocialReviewCard) {
  const {review} = props || {}
  const {rating = 0, title = '', subTitle = '', publishDetails} = review || {}
  const {
    verifiedIcon = '',
    publishTime = '',
    text = '',
    sourceOfPublishImage = '',
    author,
  } = publishDetails || {}

  if (isEmpty(review)) {
    return null
  }
  return (
    <SocialReviewCardContainer>
      <ReviewRatingStar value={rating} count={5} className="rating-container" />
      <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
        {title}
      </Typography>
      <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
        {subTitle}
      </Typography>
      <div className="publish_detail_container">
        <div className="author_and_verified_icon_container">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {author}
          </Typography>
          <OptimizedImage
            source={verifiedIcon}
            aspectWidth={18}
            aspectHeight={18}
          />
        </div>
        <Typography
          variant={TypographyVariants.X_SMALL}
          customClassName="publish_text"
        >
          {publishTime}
        </Typography>
      </div>
      <div className="publish_source_container">
        <Typography variant={TypographyVariants.TAG_REGULAR}>{text}</Typography>
        <OptimizedImage
          source={sourceOfPublishImage}
          aspectWidth={43}
          aspectHeight={15}
        />
      </div>
    </SocialReviewCardContainer>
  )
}

export default SocialReviewCard
