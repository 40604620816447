import styled from 'styled-components'

export const CredBannerContainer = styled.div<{bannerUrl: string}>`
  width: 100%;
  height: auto;
  flex-shrink: 0;
  position: relative;
  background-image: url(${(props) => props.bannerUrl});
  padding: var(--dls-size-16);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .banner-img {
    width: 100%;
  }

  .cred-logo {
    width: 60px;
    height: 22px;
    object-fit: cover;
  }
  .banner-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    gap: 7.5px;
    align-items: center;
    text-align: center;
  }

  .usage-wrapper {
    display: flex;
    padding: 1px 0px;
    align-items: center;
    gap: 6px;
  }

  .use-text {
    color: var(--dls-white-color);
    align-self: stretch;
    margin: auto 0;
  }

  .tag-container {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    line-height: 1.2;
    justify-content: start;
    padding: 1px 0;
  }

  .discount-container {
    align-items: start;
    border-radius: 50px;
    background: var(--dls-secondary-text-colors);
    align-self: stretch;
    position: relative;
    display: flex;
    gap: var(--dls-size-8);
    color: var(--dls-white-color);
    justify-content: start;
    margin: auto 0;
    padding: 4px var(--dls-size-8) 4px 22px;
  }

  .coin-image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 22px;
    position: absolute;
    z-index: 0;
    left: -5px;
    top: 55%;
    transform: translateY(-50%);
    height: 22px;
  }

  .cta-button {
    justify-content: center;
    align-items: center;
    background: var(--dls-white-color);
    align-self: center;
    display: flex;
    max-width: 100%;
    gap: var(--dls-size-8);
    padding: var(--dls-size-8) var(--dls-size-32);
    border: none;
    cursor: pointer;
  }

  .button-text {
    align-self: stretch;
    margin: auto 0;
    color: var(--dls-black-color);
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    .banner-content-wrapper {
      top: 52px;
      gap: 10px;
    }
  }
`
