import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'

import {ProductListingContainer} from './ProductListing.styles'
import ProductListingCard from './ProductListingCard/ProductListingCard'
import {Product} from '../CategoryProductCardGrid/CategoryProductCard/CategoryProductCard.interface'
import {ProductListingProps} from './ProductListing.interface'
import useProductListing from './useProductListing'
import noop from 'lodash/noop'

const ProductListing = (props: BaseWidgetProps<ProductListingProps>) => {
  const {layout, id, type, header, widgetData} = props
  const {products, enableQuantityIndicator = false} = widgetData || {}
  const [
    {},
    {
      handleCtaClick = noop,
      handleCardClick = noop,
      handleReduceQuantity = noop,
      handleIncreaseQuantity = noop,
    },
  ] = useProductListing(props)

  if (!products) return null
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductListingContainer>
        <div className="product-card-list">
          {products?.map((product: Product) => {
            return (
              <ProductListingCard
                key={product?.urlKey}
                product={product}
                handleCtaClick={handleCtaClick}
                handleCardClick={handleCardClick}
                handleReduceQuantity={handleReduceQuantity}
                handleIncreaseQuantity={handleIncreaseQuantity}
                enableQuantityIndicator={enableQuantityIndicator}
              />
            )
          })}
        </div>
      </ProductListingContainer>
    </BaseWidgetComponent>
  )
}

export default ProductListing
