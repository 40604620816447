import algoliasearch from 'algoliasearch/lite'
import {useMemo} from 'react'

const useAlgolia = () => {
  const ALGOLIA_SEARCH_API_KEY: string =
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
  const ALGOLIA_APPLICATION_ID: string =
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || ''
  const ALGOLIA_SEARCH_INDEX: string =
    process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || ''

  const searchClient = useMemo(() => {
    algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY)

    const algoliaClient = algoliasearch(
      ALGOLIA_APPLICATION_ID,
      ALGOLIA_SEARCH_API_KEY
    )
    return {
      ...algoliaClient,
      search(requests: any) {
        if (requests.every(({params}: {params: any}) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
              hitsPerPage: 0,
              exhaustiveNbHits: false,
              query: '',
              params: '',
            })),
          })
        }

        return algoliaClient.search(requests)
      },
    }
  }, [ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY])

  return {
    searchClient,
    algoliaIndex: ALGOLIA_SEARCH_INDEX,
  }
}

export default useAlgolia
