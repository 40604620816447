import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import {useEffect, useState} from 'react'
import {isNativeConsultVersion} from 'src/utils/isNativeConsultVersion'
import {useGenericCta, useGenericCtaV2} from '..'

interface IUseRedirectionProps {
  version: string
  action: string
  link: string
}
const useRedirection = () => {
  const {handleCta = noop} = useGenericCta()
  const {handleCtaV2 = noop} = useGenericCtaV2()
  const [consultVersion, setConsultVersion] = useState('')
  const [source, setSource] = useState('')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const version = urlParams.get('consultVersion')
    const currentSource = urlParams.get('source')

    if (version) {
      setConsultVersion(version)
    }

    if (currentSource) {
      setSource(currentSource)
    }
  }, [])

  const handleRedirection = ({
    action = '',
    link = '',
    version = '',
    source = '',
  }: {
    action: string
    link: string
    version: string
    source: string
  }) => {
    switch (true) {
      case !isEmpty(source): {
        handleCtaV2({
          action: 'APP_NAV',
          data: {
            appNavigationData: {
              path: source,
            },
          },
        })
        break
      }

      case isNativeConsultVersion({currentConsultVersion: version}): {
        handleCtaV2({
          action: 'APP_NAV',
          data: {
            appNavigationData: {
              path: 'Consult',
            },
          },
        })
        break
      }

      default: {
        handleCta({action, link})
      }
    }
  }
  return [
    {currentConsultVersion: consultVersion, currentSource: source},
    {
      handleRedirection,
    },
  ]
}

export {useRedirection}
