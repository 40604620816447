import isEmpty from 'lodash/isEmpty'

import OptimizedImage from '../OptimizedImage'
import {Typography} from '../TypographyV2'
import {IOrderSummary} from './PaymentSummary.interface'
import {
  StyledBottomCallOut,
  StyledLineItem,
  StyledOrderSummaryWrapper,
} from './PaymentSummary.styles'

const PaymentSummaryComponent = (props: IOrderSummary) => {
  const {
    lineItems = [],
    bottomCallout,
    title = '',
    subtitle = '',
    isPaymentSummary = true,
    isBillingSummary = false,
    isNewCartDesign = false,
  } = props.state || {}
  const {icon, text, backgroundColor} = bottomCallout || {}

  if (isEmpty(lineItems)) {
    return null
  }

  return (
    <StyledOrderSummaryWrapper data-isnewcartdesign={isNewCartDesign}>
      <div className="heading-container">
        <Typography variant="subheading-bold" customClassName="title">
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="tag-regular" customClassName="subtitle">
            {subtitle}
          </Typography>
        )}
      </div>
      <div
        className="item-container"
        data-is-bottomcallout={!isEmpty(bottomCallout) && !isNewCartDesign}
      >
        {lineItems.map((item, index) => {
          const {
            label = '',
            labelColor = '',
            value = '',
            valueColor = '',
            isSeparator = false,
            isBoldText = false,
            subText = '',
            subTextColor = '',
            billingSummary = true,
            paymentSummary = true,
          } = item || {}
          if (
            (isPaymentSummary && paymentSummary) ||
            (isBillingSummary && billingSummary)
          ) {
            return (
              <StyledLineItem
                key={index}
                labelColor={labelColor}
                valueColor={valueColor}
                subTextColor={subTextColor}
              >
                <div className="text">
                  {isBoldText && label && value && (
                    <>
                      <Typography
                        variant="body-base-bold"
                        customClassName="label"
                      >
                        {label}
                      </Typography>
                      <Typography
                        variant="body-base-bold"
                        customClassName="value"
                      >
                        {value}
                      </Typography>
                    </>
                  )}
                  {!isBoldText && label && value && (
                    <>
                      <Typography
                        variant="body-base-regular"
                        customClassName="label"
                      >
                        {label}
                      </Typography>
                      <Typography
                        variant="body-base-regular"
                        customClassName="value"
                      >
                        {value}
                      </Typography>
                    </>
                  )}
                  {isSeparator && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="2"
                      fill="none"
                    >
                      <path
                        d="M0 1H1000"
                        stroke="#E3E3E3"
                        strokeLinejoin="round"
                        strokeDasharray="4 4"
                      />
                    </svg>
                  )}
                </div>
                {subText && (
                  <div className="sub-text-container">
                    <Typography variant="tag-regular" customClassName="subText">
                      {subText}
                    </Typography>
                  </div>
                )}
              </StyledLineItem>
            )
          }
        })}
      </div>
      {!isEmpty(bottomCallout) && !isNewCartDesign && (
        <StyledBottomCallOut backgroundColor={backgroundColor}>
          <OptimizedImage
            source={icon}
            aspectWidth="15"
            aspectHeight="15"
            desktopViewWidth="TINY"
            mobileViewWidth="TINY"
            loading="lazy"
          />
          <Typography variant="body-base-regular">
            <span dangerouslySetInnerHTML={{__html: text}} />
          </Typography>
        </StyledBottomCallOut>
      )}
    </StyledOrderSummaryWrapper>
  )
}

export default PaymentSummaryComponent
