import styled from 'styled-components'

export const InfoModalWrapper = styled.div`
  .info-modal__content-container {
    position: relative;
  }
  .info-modal__title {
    margin: 20px;
    font-weight: var(--brand-font-weight-700);
    font-size: 16px;
    line-height: 120%;
    text-align: center;
  }
  .info-modal__step-container {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    border-bottom: 1px solid var(--brand-primary-silver-color);
    :last-child {
      border: none;
    }
  }
  .info-modal__step-image {
    width: 152px;
    height: 152px;
    border-radius: 12px;
  }
  .info-modal__step-index {
    font-weight: var(--brand-font-weight-black);
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    margin-left: 10px;
  }
  .info-modal__step-title {
    font-weight: var(--brand-font-weight-normal);
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  @media screen and (min-width: 768px) {
    .info-modal__step-index {
      padding: 0px;
    }
  }
`
