import styled from 'styled-components'

export const CartAppointmentCardWrapper = styled.div`
  margin-bottom: var(--dls-size-24);

  .not-free-container {
    margin-left: var(--dls-size-16);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: var(--dls-size-8);
  }

  .not-free-button {
    border: 0;
    outline: 0;
    background: transparent;
  }

  .not-free-cta {
    color: var(--brand-primary-color);
  }

  .card-container {
    width: 100%;
    padding: var(--dls-size-0) var(--dls-size-16) var(--dls-size-16)
      var(--dls-size-16);
    border-radius: var(--dls-size-16);
    background-color: var(--brand-light-bg-color);
    border: 1px solid var(--brand-primary-color);
  }

  .callout-chip {
    border-bottom-left-radius: var(--dls-size-4);
    border-bottom-right-radius: var(--dls-size-4);
    padding: var(--dls-size-2) var(--dls-size-8);
    background-color: var(--brand-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    max-width: max-content;
    margin-bottom: var(--dls-size-8);
  }

  .callout-text {
    color: var(--dls-backgound-color-100);
  }

  .card-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: var(--dls-size-8);
    align-items: center;
  }

  .edit-icon {
    height: var(--dls-size-24);
    width: var(--dls-size-24);
    background-color: var(--brand-primary-color);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
  }

  .appointment-details-container {
    margin-bottom: var(--dls-size-8);
    border-bottom: 1px solid var(--dls-divider-light-color);
  }

  &[data-is-pseudoRx='true'] {
    .appointment-details-container {
      border-bottom: 0px;
    }
  }

  .appointment-details-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--dls-size-8);
  }

  .appointment-details-text {
    color: var(--dls-secondary-text-colors);
    margin-left: var(--dls-size-4);
    text-transform: capitalize;
  }

  .confirmation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .confirmation-text {
    color: var(--dls-warning-color-400);
    margin-left: var(--dls-size-4);
  }

  .label-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--dls-primary-text-colors);
    font-size: 12px;
    font-weight: 500;
  }

  .checkbox {
    display: none;
  }

  .checkbox-box {
    height: 18px;
    width: 18px;
    border: 2px solid var(--dls-primary-text-colors);
    margin-right: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .check-mark {
      width: 6px;
      height: 12px;
      border: solid black;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      display: none;
      border-radius: 1px;
    }
  }

  .checkbox:checked + .checkbox-box {
    background-color: white;

    .check-mark {
      display: block;
    }
  }

  @media screen and (min-width: 768px) {
    .card-container {
      padding: var(--dls-size-0) var(--dls-size-24) var(--dls-size-24)
        var(--dls-size-24);
    }

    .edit-icon {
      height: var(--dls-size-32);
      width: var(--dls-size-32);
      border-radius: 16px;
    }

    .edit-icon-image {
      height: var(--dls-size-16);
      width: var(--dls-size-16);
    }
  }
`
