import Responsive from '../../../../ComponentsV2/HOC/Responsive'
import {LoadingState} from '../../../../ComponentsV2/common'
import CalendarIcon from '../CalendarIcon'
import ClockIcon from '../ClockIcon'
import LanguageIcon from '../LanguageIcon'
import OptionDropDown from '../OptionDropDown'
import Slots from '../Slots'
import {AppointmentCardContainer} from './AppointmentCard.styles'
import ToggleButton from './ToggleButton'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useEffect, useMemo, useRef, useState} from 'react'

function AppointmentCard(props) {
  const {isMobile} = props
  const {
    // consult related data
    isIAAvailable = false,
    slotData,
    isLoading = false,
    toggle = false,

    // Slots States
    selectedLanguage = '',
    selectedTime = '',
    selectedSlot = '',
    selectedDate = '',
    lsdSelected = false,
    skipSlotsTransformation = false,

    // Static data for cards
    card = {},
  } = props.state || {}

  const {
    handleLanguageChange = noop,
    handleDateChange = noop,
    handleTimeChange = noop,
    handleSlotChanged = noop,
    handleDoctorId = noop,
    handleLsdSelected = noop,
    triggerToggle = noop,
    trackScheduleScreen = noop,
  } = props.actions || {}

  const {
    doctorImage = {},
    title = '',
    subTitle = '',
    toggleText = '',
    options = [],
    languageList = [],
    lsdSubtitle = '',
  } = card

  const {mobile, desktop} = doctorImage
  const [doctorName, setDoctorName] = useState('')
  const lsdContainerRef = useRef(null)

  // transform data function
  const transformedData = useMemo(() => {
    const dateData = {}
    const {selectDoctor = {}} = slotData || {}
    const {data: doctorsData = []} = selectDoctor

    /*
    if lsd is available and the lsd state is selected then map on lsd slots
    else map on normal slots
    */

    const {title, id} = doctorsData.length > 0 ? doctorsData[0] : {}
    const {selectAvailable = {}} = doctorsData.length > 1 ? doctorsData[1] : {}
    if (skipSlotsTransformation) {
      return selectAvailable?.data?.[1]
    }

    let availableLSDSlots = {}
    if (title) {
      const {selectAvailable = {}} =
        doctorsData.length > 0 ? doctorsData[0] : {}
      availableLSDSlots = selectAvailable
    }

    const lsdSlots = availableLSDSlots
    const allSlots = selectAvailable

    const {data: availableData = []} =
      lsdSelected && !isEmpty(lsdSlots) ? lsdSlots : allSlots
    const {data: slotMap = []} =
      availableData.length > 1 ? availableData[1] : {}

    let dataToMap = slotMap

    if (lsdSelected && !isEmpty(lsdSlots.data)) {
      dataToMap = lsdSlots.data
    }

    dataToMap.forEach((item) => {
      const timeData = {}
      item.times.forEach((item) => {
        timeData[item.time] = item.data.slots
      })
      if (!isEmpty(timeData)) {
        dateData[item.id] = timeData
      }
    })

    setDoctorName(title)
    handleDoctorId(id)
    return dateData
  }, [lsdSelected, slotData])

  // Transform data when the slot data is changed
  const dateList = useMemo(
    () => Object.keys(transformedData) || [],
    [transformedData]
  )

  const timeList = useMemo(() => {
    return dateList.includes(selectedDate)
      ? Object.keys(transformedData[selectedDate] || {})
      : []
  }, [selectedDate, dateList, transformedData])

  const slotList = useMemo(() => {
    const timeData = transformedData[selectedDate] || {}
    const slotData = timeData[selectedTime] || []
    return slotData
  }, [selectedDate, selectedTime, transformedData])

  const showLSD = useMemo(() => {
    return doctorName && toggle
  }, [doctorName, toggle])

  // Update the slot states for dropdowns
  const SelectLanguageState = useMemo(() => {
    return {
      data: languageList,
      selectedItem: selectedLanguage,
      optionTitle: options[0]?.optionTitle,
      OptionIcon: LanguageIcon,
    }
  }, [, languageList, options, selectedLanguage])

  const SelectDateState = useMemo(() => {
    return {
      data: dateList,
      selectedItem: selectedDate,
      optionTitle: options[1]?.optionTitle,
      OptionIcon: CalendarIcon,
    }
  }, [, dateList, options, selectedDate])

  const SelectTimeState = useMemo(() => {
    return {
      data: timeList,
      selectedItem: selectedTime,
      optionTitle: options[2]?.optionTitle,
      OptionIcon: ClockIcon,
    }
  }, [timeList, selectedTime, options])

  // Set default values for the dropdowns
  useEffect(() => {
    handleDateChange(dateList[0] || 'Not Available')
  }, [dateList])

  useEffect(() => {
    handleTimeChange(timeList[0] || 'Not Available')
  }, [selectedDate, timeList])

  useEffect(() => {
    if (slotList[0]?.data) {
      handleSlotChanged(slotList[0]?.data || 'Not Available')
    }
  }, [slotList])

  //analytics trigger
  const pageViewFired = useRef(false)
  useEffect(() => {
    if (slotList.length && !pageViewFired.current) {
      pageViewFired.current = true
      trackScheduleScreen({
        type: 'pageView',
        slot1: slotList[0]?.data,
        slot2: slotList[1]?.data,
        slot3: slotList[2]?.data,
        iaAvailable: isIAAvailable,
      })
    }
  }, [slotList])

  return (
    <AppointmentCardContainer
      data-is-open={toggle}
      data-is-selected={lsdSelected}
    >
      <div className="appointment_card__header">
        <img
          src={isMobile ? mobile : desktop}
          alt=""
          className="doctor_image"
        />
        <h2 className="appointment_card__headerText">{title}</h2>
      </div>
      <div className="ribbon_container">
        <div className="subheader_ribbon-left" />
        <div className="subheader_ribbon-right" />
      </div>
      <div className="appointment_card__subHeader">{subTitle}</div>
      {showLSD && (
        <div className="appointment_card__LSDContainer" ref={lsdContainerRef}>
          <div className="appointment_card__LSDTextContainer">
            <div className="appointment_card__LSDTitle">{doctorName}</div>
            <div className="appointment_card__LSDSubtitle">{lsdSubtitle}</div>
          </div>
          <div className="checkbox" onClick={handleLsdSelected}>
            <FontAwesomeIcon icon={faCheck} className="check-icon" />
          </div>
        </div>
      )}

      <div className="appointment_card__bottomContainer">
        {isIAAvailable && (
          <div className="toggle-text__container" onClick={triggerToggle}>
            <div className="toggle-button__text">{toggleText}</div>
            <ToggleButton state={{toggle}} actions={{triggerToggle}} />
          </div>
        )}
        <div className="appointment_card__slotsContainer">
          {!isLoading ? (
            <OptionDropDown
              state={{...SelectLanguageState, className: 'drop-down-list'}}
              actions={{
                handleSelectedItem: handleLanguageChange,
              }}
            />
          ) : (
            <LoadingState cardHeight={25} key={'Date'} />
          )}
          <div className="appointment_card__scheduleOptions">
            <>
              {!isLoading ? (
                <>
                  <OptionDropDown
                    state={{...SelectDateState, className: 'drop-down-list'}}
                    actions={{
                      handleSelectedItem: handleDateChange,
                    }}
                  />
                  <OptionDropDown
                    state={{...SelectTimeState, className: 'drop-down-list'}}
                    actions={{
                      handleSelectedItem: handleTimeChange,
                    }}
                  />

                  <Slots
                    state={{
                      slotsTitle: 'Choose a slot',
                      data: slotList,
                      selectedSlot,
                    }}
                    actions={{
                      handleSlotChanged,
                    }}
                  />
                </>
              ) : (
                <div
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <LoadingState cardHeight={25} key={'Date'} />
                  <LoadingState cardHeight={25} key={'Time'} />
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </AppointmentCardContainer>
  )
}

export default Responsive(AppointmentCard)
