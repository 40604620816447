import styled from 'styled-components'

export const StyledDoctorDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--dls-size-4);
  &[data-hasActions='true'] {
    cursor: pointer;
  }
  .details-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-start;
    gap: var(--dls-size-12);
    .avatar {
      width: var(--dls-size-32);
      height: var(--dls-size-32);
      border-radius: 50%;
      object-fit: cover;
    }
    .display-name {
      color: var(--dls-brand-primary-color-500);
      margin-bottom: var(--dls-size-1);
    }
    .qualification {
      color: var(--brand-light-secondary-text-color);
    }
  }
`
