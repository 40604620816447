import {create} from 'zustand'

interface Flag {
  enable: boolean
  config?: Record<string, any>
}
type ExperimentsStore = {
  flags: Record<string, Flag>
  setFlags: (flags: Record<string, Flag>) => void
}

export const useFlagsStore = create<ExperimentsStore>()((set) => ({
  flags: {},
  setFlags: (flags) => set({flags}),
}))

export const getFlag = (flagPath: string) => {
  const flags = useFlagsStore.getState().flags

  const flagToReturn = flags[flagPath]

  return flagToReturn || {enable: false}
}
