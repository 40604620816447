import React from 'react'
import {StyledWrapper} from './RatingInput.styles'
import Star from './Star'
import StarFilled from './StarFilled'

const STAR_LABELS = ['Horrible', 'Bad', 'Average', 'Good', 'Excellent']
function RatingInput({value, onChange, error, label}) {
  return (
    <StyledWrapper>
      <label className="input-label" htmlFor={'rating'}>
        {label}
      </label>
      <div className="stars-wrap">
        {[...Array(5)].map((_, index) => {
          const isFilled = index + 1 <= value
          return (
            <button
              key={index}
              className="star-button"
              type="button"
              onClick={() => onChange(index + 1)}
            >
              {isFilled ? <StarFilled /> : <Star />}
              <span className="label">{STAR_LABELS[index]}</span>
            </button>
          )
        })}
      </div>
      {error && <span className="error-field">{error.message}</span>}
    </StyledWrapper>
  )
}

export default RatingInput
