import styled from 'styled-components'

export const IconWithLabelSliderContainer = styled.div`
  width: 100%;

  .item-card {
    padding: var(--dls-size-16);
    border: 1px solid var(--dls-divider-light-color);
    background-color: var(--dls-white-color);
    border-radius: var(--dls-size-16);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .icon-image {
    max-width: 38%;
    height: auto;
    object-fit: cover;
  }

  .label {
    margin-top: var(--dls-size-16);
  }
`
