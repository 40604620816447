import styled from 'styled-components'

export const ConsultStepsCardContainer = styled.div`
  width: 100%;
  background-color: var(--brand-primary-white-color);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 24px 16px;

  .card-title {
    font-size: 16px;
    font-weight: var(--brand-font-weight-dark);
    margin-bottom: 22px;
  }

  .step {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 1;

    ::after {
      content: ' ';
      position: absolute;
      height: 35px;
      border-left: 1px dashed var(--category-primary-color);
      left: 11px;
      bottom: -30px;
      z-index: -1;
    }

    :last-child {
      ::after {
        display: none;
      }
    }

    :not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .step-number {
    padding: 8px;
    border-radius: 10px;
    background-color: var(--category-primary-bg1-color);
    font-size: 14px;
    line-height: 1;
    font-weight: var(--brand-font-weight-normal);
    color: var(--category-primary-color);
    margin-right: 10px;
    height: min-content;
  }

  .step-label {
    font-size: 12px;
    line-height: 1;
    color: var(--category-primary-color);
    font-weight: var(--brand-font-weight-dark);
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    img {
      width: 12px;
      height: auto;
      object-fit: cover;
      margin-left: 4px;
    }
  }

  .step-description {
    font-size: 12px;
    line-height: 1;
    color: var(--brand-primary-grey-color);
    font-weight: var(--brand-font-weight-lightx);
  }
`
