import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useCallback, useEffect, useMemo} from 'react'

import OptimizedImage from '../OptimizedImage'
import {Typography} from '../TypographyV2'
import {ICartAppointmentCardProps} from './CartAppointmentCard.interface'
import {CartAppointmentCardWrapper} from './CartAppointmentCard.styles'
import {getCardCopy} from './CartAppointmentCard.utils'
import ClockIcon from './ClockIcon'
import LanguageIcon from './LanguageIcon'
import WarningIcon from './WarningIcon'

const CartAppointmentCard: React.FC<ICartAppointmentCardProps> = (props) => {
  const {state, actions} = props || {}

  const {
    iaAvailable = false,
    prescriptionAvailable = false,
    scheduledAppointAvailable = false,
    prescriptionNeededReason = '',
    bookSlotsData = {},
    bookAppointmentCardCopy = {},
    rxConsultType = '',
  } = state || {}

  const {
    toggleScheduleFormOpen = noop,
    onSwitchToIa = noop,
    trackAppointmentCardShown = noop,
    onNotFreeRightNowClick = noop,
  } = actions || {}

  const {editIconLink = ''} = bookAppointmentCardCopy

  const {
    immediate = false,
    date = '',
    slot = '',
    language = '',
    selectedLanguageFallback = '',
  } = bookSlotsData || {}

  //calculate if IA is available and is also selected
  const iaSelected: boolean = useMemo(
    () => iaAvailable && !!immediate,
    [iaAvailable, immediate]
  )

  //calculate if schedule is available and selected
  const scheduleSelected = useMemo(() => !!date || !!slot, [date, slot])

  //calculate if schedule is available and selected, and IA is available
  const scheduleSelectedAndIaAvailable = useMemo(
    () => scheduleSelected && !immediate && iaAvailable,
    [iaAvailable, immediate, scheduleSelected]
  )

  const cardCopy = useMemo(() => {
    return getCardCopy({
      iaSelected,
      scheduleSelected,
      scheduleSelectedAndIaAvailable,
      bookAppointmentCardCopy,
      rxConsultType,
    })
  }, [
    bookAppointmentCardCopy,
    iaSelected,
    scheduleSelected,
    scheduleSelectedAndIaAvailable,
    rxConsultType,
  ])

  const handleNotFreeRightNowClicked = useCallback(() => {
    toggleScheduleFormOpen()
    onNotFreeRightNowClick({slotAvailable: !isEmpty(bookSlotsData)})
  }, [bookSlotsData, onNotFreeRightNowClick, toggleScheduleFormOpen])

  const [showSwitchCta, switchLabelText, switchCtaText, switchCtaCallback] =
    useMemo(() => {
      if (iaSelected) {
        return [
          true,
          'Not free right now',
          'Book For later >',
          handleNotFreeRightNowClicked,
        ]
      }

      if (scheduleSelectedAndIaAvailable) {
        return [true, 'Talk to doctor now?', 'Switch Now >', onSwitchToIa]
      }

      return [false, '', '', noop]
    }, [
      handleNotFreeRightNowClicked,
      iaSelected,
      onSwitchToIa,
      scheduleSelectedAndIaAvailable,
    ])

  useEffect(() => {
    trackAppointmentCardShown({
      type: 'Rx',
      reason: prescriptionNeededReason,
      rxProduct: true,
      rxExists: prescriptionAvailable,
      appointmentExists: scheduledAppointAvailable,
      iaAvailable: iaAvailable,
    })
  }, [
    trackAppointmentCardShown,
    prescriptionNeededReason,
    prescriptionAvailable,
    scheduledAppointAvailable,
    iaAvailable,
  ])

  if (prescriptionAvailable || isEmpty(bookSlotsData)) {
    return null
  }

  return (
    <CartAppointmentCardWrapper>
      <div className="card-container">
        <div className="callout-chip">
          <Typography variant="x-small" customClassName="callout-text">
            {cardCopy?.calloutText}
          </Typography>
        </div>

        <div className="card-heading-container">
          <Typography variant="subheading-bold">{cardCopy?.title}</Typography>

          {!scheduledAppointAvailable && (
            <button className="edit-icon" onClick={toggleScheduleFormOpen}>
              <OptimizedImage
                source={editIconLink}
                aspectWidth="12"
                aspectHeight="12"
                desktopViewWidth="TINY"
                mobileViewWidth="TINY"
                altText="edit icon"
                loading="lazy"
                customClassName="edit-icon-image"
              />
            </button>
          )}
        </div>

        <div className="appointment-details-container">
          <div className="appointment-details-section">
            <LanguageIcon />
            <Typography
              variant="tag-regular"
              customClassName="appointment-details-text"
            >
              {language || selectedLanguageFallback}
            </Typography>
          </div>
          <div className="appointment-details-section">
            <ClockIcon />
            <Typography
              variant="tag-regular"
              customClassName="appointment-details-text"
            >
              {iaSelected
                ? cardCopy?.consultationStartText
                : `${date}, ${slot}`}
            </Typography>
          </div>
        </div>
        <div className="confirmation-container">
          <WarningIcon />
          <Typography customClassName="confirmation-text" variant="tag-bold">
            {cardCopy?.subtitle}
          </Typography>
        </div>
      </div>

      {showSwitchCta && (
        <div className="not-free-container">
          <Typography variant="tag-regular">{switchLabelText}</Typography>
          <button className="not-free-button" onClick={switchCtaCallback}>
            <Typography customClassName="not-free-cta" variant="cta-label-sm">
              {switchCtaText}
            </Typography>
          </button>
        </div>
      )}
    </CartAppointmentCardWrapper>
  )
}

export default CartAppointmentCard
