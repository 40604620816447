import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {Wrapper, CardWrapper} from './TestimonialProductCard.styles'
import {OptimizedImage} from '@web-components'
import {Typography} from '@web-components'
import {LeftQuote} from '@web-components'
import {RightQuote} from '@web-components'
import {ReviewRatingStar, TypographyVariants} from '@web-components'
import {useDeviceTypeContext} from '@web-components'
import {useGenericActions} from '../../../hooks'

function TestimonialProductCard(props: any) {
  const {widgetData: product} = props || {}
  const {
    personQuote = '',
    personName = '',
    personDesignation = '',
    name = '',
    rating = 0,
    discountText = '',
    testimonialCardImage = '',
    image = '',
    price = 0,
    currency = '₹',
    discountedPrice = 0,
    whyThisWorks = [],
    btnLabel = 'Learn More',
    cardClickAction,
    cta,
  } = product || {}

  const {genericAction} = useGenericActions()

  const {isMobile = false} = useDeviceTypeContext()
  const ifProductOnDiscount = !!discountedPrice
  const filteredWhyThisWorks = useMemo(
    () => whyThisWorks.filter(({label, text}) => label && text),
    [whyThisWorks]
  )

  const handleCardClickAction = useCallback(
    (e) => {
      const defaultCardAction = {actionName: 'OPEN_PDP', params: product}
      genericAction(cardClickAction || defaultCardAction)
    },
    [cardClickAction, genericAction, product]
  )

  const handleCtaAction = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      const defaultCta = {actionName: 'OPEN_PDP', params: product}
      genericAction(cta || defaultCta)
      genericAction(cta)
    },
    [cta, genericAction, product]
  )

  return (
    <Wrapper>
      <div className="quote-wrapper">
        <div className="quote-container">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {personQuote}
          </Typography>
        </div>
        <div className="quote-person-description">
          <label className="quote-person-name">{personName}</label>
          {` | `}
          <label className="quote-person-designation">
            {personDesignation}
          </label>
        </div>
        <div className="quote-left">
          <LeftQuote isMobile={isMobile} />
        </div>
        <div className="quote-right">
          <RightQuote isMobile={isMobile} />
        </div>
      </div>

      <CardWrapper onClick={handleCardClickAction}>
        <div className="videoCont">
          <OptimizedImage
            source={testimonialCardImage || image}
            aspectWidth="400"
            aspectHeight="400"
            desktopViewWidth="THIRD"
            mobileViewWidth="THIRD"
            altText={name}
            loading="lazy"
            customClassName="autoplayVideo"
          />
        </div>
        <div className="productDetail__new_design">
          <div className="ratingsReview">
            <ReviewRatingStar
              count={5}
              value={Number(rating)}
              size={isMobile ? 14 : 22}
              className="ratingStyle"
            />
          </div>
          <label className="productName">{name}</label>
          <div className="prices">
            <div className="discountP">
              <sup className="rupeeSup">{currency}</sup>
              {ifProductOnDiscount ? discountedPrice : price}
            </div>
            {ifProductOnDiscount && (
              <div className="actualP">
                {currency}
                {price}
              </div>
            )}
            {ifProductOnDiscount && (
              <div className="discountText">{discountText}</div>
            )}
          </div>
          <div className="whyThisWorks">
            {filteredWhyThisWorks.map(({label, text}) => {
              return (
                <div key={label} className="why-this-work-item">
                  <span className="key">{label}</span>
                  {text}
                </div>
              )
            })}
          </div>
        </div>
        <div className="cartAdd" onClick={handleCtaAction}>
          <label className="cardAdd-label">
            <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
              {btnLabel}
            </Typography>
          </label>
        </div>
      </CardWrapper>
    </Wrapper>
  )
}

export default TestimonialProductCard
