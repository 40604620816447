import styled from 'styled-components'

export const SocialReviewListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--dls-size-16);
  gap: var(--dls-size-16);

  .cta-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--dls-brand-primary-color-500);
    padding: var(--dls-size-8) 0;
    border-radius: var(--dls-size-8);

    .text {
      color: var(--dls-brand-primary-color-500);
    }
  }
`
