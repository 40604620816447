import {StyledFloatingSelfDiagnosisToast} from './floatingSelfDiagnosisToast.styles'
import React, {useState, useEffect, useMemo} from 'react'
import IconCross from '../Icons/IconClose'
import {
  IFloatingIconProps,
  TFloatingCTA,
  TFloatingPosition,
} from './floatingSelfDiagnosisToast.types'
import noop from 'lodash/noop'
import Portal from '../Modal/Portal'

const defaultPosition: Partial<TFloatingPosition> = {
  right: '10px',
  bottom: '120px',
}

interface IFloatingSelfDiagnosisToastProps {
  data: IFloatingIconProps
  onClick?: (data: TFloatingCTA) => void
  onClose?: (data: TFloatingCTA) => void
  onLoad?: (data: TFloatingCTA) => void
}

const FloatingSelfDiagnosisToast: React.FC<IFloatingSelfDiagnosisToastProps> =
  ({data, onClick = noop, onLoad = noop, onClose = noop}) => {
    const [shouldShow, setShouldShow] = useState(true)
    const {
      cta = {},
      showCross = true,
      mobilePosition = {},
      desktopPosition = {},
      styles = {},
      enabled = false,
    } = data ?? {}

    const {label = '', icon = null} = cta

    const handleToastClick = () => {
      onClick(data)
    }

    const handleCrossClick = () => {
      setShouldShow(false)
      onClose(data)
    }

    const isLoaded = useMemo(
      () => enabled && shouldShow && !!data,
      [data, enabled, shouldShow]
    )

    useEffect(() => {
      if (isLoaded) {
        onLoad(data)
      }
    }, [isLoaded, data])

    if (!shouldShow || !enabled) {
      return null
    }

    return (
      <Portal>
        <StyledFloatingSelfDiagnosisToast
          id="self-diagnosis-toast"
          mobilePosition={{...defaultPosition, ...mobilePosition}}
          desktopPosition={{...defaultPosition, ...desktopPosition}}
          styles={styles}
        >
          <div className="container">
            {icon && (
              <img
                className="icon"
                src={icon}
                alt={label}
                onClick={handleToastClick}
              />
            )}
            {!!label.length && (
              <span className="label" onClick={handleToastClick}>
                {label}
              </span>
            )}
            {showCross && (
              <div className="crossIcon" onClick={handleCrossClick}>
                <IconCross />
              </div>
            )}
          </div>
        </StyledFloatingSelfDiagnosisToast>
      </Portal>
    )
  }

export default FloatingSelfDiagnosisToast
