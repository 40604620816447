import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import Responsive from '../../../../ComponentsV2/HOC/Responsive'
import {useIntersection} from '../../../../hooks/useIntersection'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import CarouselDots from './CarouselDots'
import Desktop from './Desktop'
import {ImageCarouselWrapper} from './ImageCarousalnew.styles'
import MemoizedImageCarouselSlide from './ImageCarouselSlide'
import MobileNavArrows from './MobileNavArrows'
import DiscontinuedProductOverlay from './DiscontinuedProductOverlay'

const ImageCarousel = (props) => {
  const {state, actions} = props || {}
  const {
    carouselImages = [],
    isOOS = false,
    oosOverlayVisible = false,
    isDiscontinuedProduct = false,
    discontinuedProductOverlayText = '',
    showBannerAction = true,
  } = state || {}
  const {
    onVideoPlay = noop,
    onRibbonClick = noop,
    onRibbonCTALoad = noop,
    triggerThumbnailImageClickedEvent = noop,
  } = actions || {}

  const isMobile = state.isMobile
  const {nodeRef, isIntersecting} = useIntersection()
  const [currentIndex, setCurrentIndex] = useState(0)
  const containerRef = useRef(null)
  const isOOSOverlayVisible = isOOS && oosOverlayVisible
  const handleScroll = useCallback(() => {
    const container = containerRef.current
    if (container) {
      const scrollLeft = container.scrollLeft
      const itemWidth = container.offsetWidth
      const newIndex = Math.round(scrollLeft / itemWidth)
      setCurrentIndex(newIndex)
    }
  }, [])
  const handleNext = useCallback(() => {
    const container = containerRef.current
    const containerWidth = container.offsetWidth

    if (currentIndex >= carouselImages.length - 1) {
      container.scrollLeft = 0
    } else {
      container.scrollLeft += containerWidth
    }

    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, carouselImages.length - 1)
    )
  }, [currentIndex, carouselImages.length])
  const thumbnailContainerRef = useRef(null)
  const handlePrev = useCallback(() => {
    const container = containerRef.current
    const containerWidth = container.offsetWidth

    if (container.scrollLeft === 0) {
    } else {
      container.scrollLeft -= containerWidth
    }

    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }, [])
  const handleThumbnailClick = useCallback((index) => {
    triggerThumbnailImageClickedEvent(currentIndex)
    const container = containerRef.current
    const thumbnailContainer = thumbnailContainerRef.current

    if (container && thumbnailContainer) {
      const containerWidth = container.offsetWidth
      const thumbnailWidth = thumbnailContainer.children[0].offsetWidth

      thumbnailContainer.scrollLeft = Math.max(
        0,
        index * thumbnailWidth - containerWidth / 2 + thumbnailWidth / 2
      )

      container.scrollLeft = index * containerWidth

      setCurrentIndex(index)
    }
  }, [])
  const handleVideoPlay = useCallback(
    (currentSlide) => {
      onVideoPlay(currentSlide)
    },
    [onVideoPlay]
  )

  const handleThumbnailScroll = useCallback(
    (direction) => {
      const thumbnailContainer = thumbnailContainerRef.current
      if (thumbnailContainer) {
        const scrollStep = 40
        if (direction === 'up') {
          thumbnailContainer.scrollTop -= scrollStep
        } else {
          thumbnailContainer.scrollTop += scrollStep
          if (currentIndex >= carouselImages.length - 1) {
            thumbnailContainer.scrollTop = 0
            setCurrentIndex(currentIndex)
          } else {
            setCurrentIndex(currentIndex)
          }
        }
      }
    },
    [currentIndex, carouselImages.length]
  )

  const imageOverlay = useCallback(() => {
    if (isDiscontinuedProduct) {
      return (
        <DiscontinuedProductOverlay message={discontinuedProductOverlayText} />
      )
    }

    if (isOOSOverlayVisible) {
      return (
        <>
          <div className="oos-overlay"></div>
          <div className="banner-container">
            <div className="oos-banner">This product is out of stock</div>
          </div>
        </>
      )
    }
    return null
  }, [isOOSOverlayVisible, isDiscontinuedProduct])

  useEffect(() => {
    const container = containerRef.current

    const handleInitialScroll = () => {
      if (container) {
        const scrollLeft = container.scrollLeft
        const itemWidth = container.offsetWidth
        const newIndex = Math.round(scrollLeft / itemWidth)
        setCurrentIndex(newIndex)
      }
    }

    if (isIntersecting) {
      container.addEventListener('scroll', handleScroll)
      handleInitialScroll()
    } else {
      handleInitialScroll()
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [currentIndex, handleScroll, isIntersecting])

  const showBannerCta = useMemo(() => {
    return !isDiscontinuedProduct && showBannerAction
  }, [isDiscontinuedProduct, showBannerAction])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <ImageCarouselWrapper ref={nodeRef}>
      {imageOverlay()}
      {!isMobile ? (
        <Desktop
          state={{
            carouselImages: carouselImages,
            currentIndex: currentIndex,
            handleThumbnailClick: handleThumbnailClick,
            handlePrev: handlePrev,
            handleNext: handleNext,
            handleThumbnailScroll: handleThumbnailScroll,
            containerRef: containerRef,
            thumbnailContainerRef: thumbnailContainerRef,
            isMobile,
            showBannerCta,
          }}
          actions={{handleVideoPlay, onRibbonClick, onRibbonCTALoad}}
        />
      ) : (
        <>
          {currentIndex !== 0 && (
            <MobileNavArrows
              onClick={handlePrev}
              className="carousel-mobile-nav left"
            />
          )}
          <div className="image-container-mobile" ref={containerRef}>
            {carouselImages.map((image, index) => {
              const {
                original = '',
                cta = '',
                type = '',
                customVideo = {},
                videoLink = '',
              } = image || {}

              return (
                <MemoizedImageCarouselSlide
                  state={{
                    videoLink: videoLink,
                    key: index,
                    type,
                    src: original,
                    alt: `Image ${index + 1}`,
                    isActive: currentIndex === index,
                    currentIndex,
                    cta,
                    customVideo: customVideo,
                    isMobile,
                    index,
                    showBannerCta,
                  }}
                  actions={{handleVideoPlay, onRibbonClick, onRibbonCTALoad}}
                />
              )
            })}
          </div>
          {carouselImages?.length > 1 && (
            <MobileNavArrows
              onClick={handleNext}
              className="carousel-mobile-nav right"
            />
          )}
          <CarouselDots
            items={carouselImages}
            activeSlideIndex={currentIndex}
          />
        </>
      )}
    </ImageCarouselWrapper>
  )
}

export default withCustomErrorBoundary(
  Responsive(ImageCarousel),
  'ImageCarouselnew'
)
