import {UploadCalloutContainer} from './UploadCallout.styles'
import Typography from '../../../../ComponentsV2/common/Typography/Typography'

function UploadCallout(props) {
  const {state = {}} = props
  const {icon = '', text = '', backgroundColor = '', className = ''} = state

  return (
    <UploadCalloutContainer
      backgroundColor={backgroundColor}
      className={className}
    >
      <div className="callout-icon-container">
        <img
          src={icon}
          alt="icon describing the callout"
          className="callout-icon"
        />
      </div>
      <Typography
        text={text}
        variant="body-base-regular"
        customClassName="callout-text"
        isDangerouslySet={true}
      />
    </UploadCalloutContainer>
  )
}

export default UploadCallout
