import styled from 'styled-components'

export const BannerContainer = styled.a<{desktopWidth: string}>`
  text-decoration: none;
  width: 100%;

  .banner-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 786px) {
    .banner-image {
      width: ${(props) => (props.desktopWidth ? props.desktopWidth : '100%')};
    }
  }
`
