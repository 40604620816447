import styled from 'styled-components'

const StyledSVG = styled.svg<{isActive: boolean}>`
  path {
    fill: var(--dls-secondary-text-colors);
    ${({isActive}) => (isActive ? 'fill: var(--category-primary-color)' : '')};
    ${({isActive}) => (isActive ? 'opacity: 1' : '')};
  }
`
function PlayCarouselDot({
  height = 6,
  width = 6,
  isActive = false,
  additionalClassName = '',
  ...rest
}) {
  return (
    <StyledSVG
      width={width}
      height={height}
      viewBox={`0 0 6 6`}
      isActive={isActive}
      className={additionalClassName}
      data-isactive={isActive}
    >
      <path
        d="M4.95 2.394a.7.7 0 010 1.212l-3.9 2.252A.7.7 0 010 5.252V.748A.7.7 0 011.05.142l3.9 2.252z"
        opacity={1}
      ></path>
    </StyledSVG>
  )
}

export default PlayCarouselDot
