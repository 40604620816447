import {IColor} from '../types'

export const Color: IColor = {
  PRIMARY: {
    500: '#005995',
    400: '#4292c7',
    300: '#a1cbe6',
    200: '#badcf0',
    100: '#e6f5ff',
  },
  SECONDARY: {
    500: '#F2F2E8',
    400: '#F5F5ED',
    300: '#F7F7F1',
    200: '#FAFAF6',
    100: '#FCFCFA',
  },
  HAIR: {
    500: '#66509A',
    400: '#8573AE',
    300: '#C2B9D7',
    200: '#E0DCEB',
    100: '#F0EDF5',
  },
  SKIN: {
    500: '#1964AB',
    400: '#4783BC',
    300: '#A3C1DD',
    200: '#D1E0EE',
    100: '#F4F7FB',
  },
  NUTRITION: {
    500: '#00806E',
    400: '#33998B',
    300: '#99CCC5',
    200: '#CCE5E2',
    100: '#F2F9F8',
  },
  PCOS: {
    500: '#CB5C5F',
    400: '#D57D7F',
    300: '#EABEBF',
    200: '#F5DEDF',
    100: '#FDF7F7',
  },
  SUCCESS: {
    500: '#198775',
    400: '#5FB2A5',
    300: '#87C5BB',
    200: '#AFD9D2',
    100: '#D7ECE8',
  },
  WARNING: {
    500: '#FF9800',
    400: '#FFAD33',
    300: '#FFC166',
    200: '#FFD699',
    100: '#FFEACC',
  },
  ERROR: {
    500: '#DF555B',
    400: '#E5777C',
    300: '#EC999D',
    200: '#F2BBBD',
    100: '#F9DDDE',
  },
  PILLS: {
    BACKGROUND_SELECTED: '#005995',
    BACKGROUND_UNSELECTED: '#121212',
    BACKGROUND_DISABLED: '#9E9E9E',
    TEXT_ICON_SELECTED: '#FFFFFF',
    TEXT_ICON_UNSELECTED: '#005995',
    TEXT_ICON_DISABLED: '#9E9E9E',
  },
  BACKGROUND: {
    500: '#FFFFFF',
    400: '#FFFFFF',
    300: '#FFFFFF',
    200: '#FFFFFF',
    100: '#F5F5F5',
  },
  TEXT: {
    BRAND_TEXT: '#212121',
    PRIMARY_TEXT: '#212121',
    SECONDARY_TEXT: '#666666',
    DISABLED_TEXT: '#9E9E9E',
  },
  NEUTRALS: {
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    TEXT_FIELD_STROKE: '#C4C4C4',
  },
  DIVIDERS: {
    DARK: '#2E2E2E',
    LIGHT: '#E3E3E3',
  },
  RATING_STAR: {
    LIGHT: '#F6CA4C',
    DARK: '#F6CA4C',
  },
  TAG: {
    HIGHLIGHTED_TAG_BACKGROUND: '#FBDB37',
    HIGHLIGHTED_TAG_TEXT: '#121212',
    DISCOUNT_TAG_BACKGROUND: '#FFEC6B',
    DISCOUNT_TAG_TEXT: '#379F8E',
    DEFAULT_TAG_BACKGROUND: '#FCDDB0',
    DEFAULT_TAG_TEXT: '#379F8E',
  },
}
