import React, {useMemo} from 'react'

import {useLocalisationContext} from '../../../../../Context/Localisation/Localisation'
import Star from '../../../../../common/RatingStars/star'
import {RatingsCompositionWrapper} from './RatingComposition.styles'
import {IRatingCompositionProps} from './RatingComposition.types'

function RatingComposition(props: IRatingCompositionProps) {
  const {overAllRating = 0, totalNumberOfReviews = '', isMobile = false} = props
  const {REVIEWS_TEXT = ''} = useLocalisationContext()

  const ratingStarsSize = useMemo(() => {
    const size = isMobile ? 22 : 32

    return {
      height: size,
      width: size,
    }
  }, [isMobile])

  return (
    <RatingsCompositionWrapper>
      <div className="ratings_overview">
        <div className="rating_container">
          <span>{overAllRating}</span>
          <div className="star">
            <Star
              height={ratingStarsSize.height}
              width={ratingStarsSize.width}
              isMobile={isMobile}
            />
          </div>
        </div>
        <div className="total_reviews">
          <span>
            {totalNumberOfReviews} {REVIEWS_TEXT}
          </span>
        </div>
      </div>
    </RatingsCompositionWrapper>
  )
}

export default RatingComposition
