import styled from 'styled-components'
import {MedicalTeamCardContainerProps} from './ImageWithDescriptionCard.interface'

export const MedicalTeamCardContainer = styled.div<MedicalTeamCardContainerProps>`
  display: flex;
  border-radius: var(--dls-size-16);
  overflow: hidden;
  height: 380px;

  .medical-team-card__card-container {
    display: flex;
    width: 100%;
  }
  .medical-team-card__image {
    width: 40%;
    object-fit: cover;
    height: auto;
  }
  .medical-team-card__data-container {
    width: 60%;
    background: var(--dls-category-primary-color-100);
    padding: var(--dls-size-8) var(--dls-size-16);

    ${(props) =>
      !!props.descriptionBgColor &&
      `
      background:${props.descriptionBgColor}
    `}
  }
  .medical-team-card__tilte {
    color: var(--dls-category-primary-color-500);
  }
  .medical-team-card__subtitle {
    line-height: 14px;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    color: var(--dls-category-primary-color-500);
  }
  .medical-team-card__description {
    line-height: 20px;
    color: var(--dls-secondary-text-colors);
  }
  .color-primary {
    color: var(--dls-category-primary-color-500);
  }
  .medical-team-card__button {
    background: var(--dls-white-color);
    border-radius: 20px;
    font-weight: var(--dls-brand-font-family);
    font-size: 14px;
    line-height: 19px;
    color: var(--dls-category-primary-color-500);
    border: none;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    margin-bottom: 16px;
    display: flex;
    gap: var(--dls-size-4);
  }
  //TODO: font weight
  @media screen and (min-width: 768px) {
    border-radius: 24px;
    height: 700px;

    .medical-team-card__data-container {
      padding: 30px;
      width: 60%;
    }
    .medical-team-card__subtitle {
      font-weight: var(--dls-brand-font-family);
      font-size: 16px;
      line-height: 22px;
    }
    .medical-team-card__description {
      font-weight: var(--dls-brand-font-family);
      font-size: 24px;
      line-height: 33px;
      padding-right: 40px;
    }
    .medical-team-card__button {
      font-weight: var(--dls-brand-font-family);
      font-size: 21.7568px;
      line-height: 30px;
      padding: 8px 19px;
    }
  }
`
