import {useEffect} from 'react'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import Modal from '../../../ComponentsV2/common/Modal'
import {ScheduleScreen} from '../../DirectAssesment'
import {IScheduleAppointmentFormProps} from './ScheduleAppointmentForm.interface'
import {ScheduleAppointmentFormContainer} from './ScheduleAppointmentForm.styles'
import noop from 'lodash/noop'

function ScheduleAppointmentForm(props: IScheduleAppointmentFormProps) {
  const {
    isFormOpen = false,
    bookSlotsData = {},
    checkPrescriptionData,
    isLoadingCheckPrescriptionData = false,
    appointmentBottomSheetConfig = {},
    languageForCheckPrescription = '',
  } = props.state || {}

  const {
    toggleScheduleFormOpen = noop,
    onSaveChangesClicked = noop,
    onLanguageChangeForCheckPrescription = noop,
  } = props.actions || {}

  const {iaAvailable = false, availableSlots = {}} = checkPrescriptionData || {}
  //call check-prescription to fetch latest slots everytime bottom sheet opens & reset languageOfCheckPrescription if not equal to user selected language
  useEffect(() => {
    if (
      isFormOpen &&
      bookSlotsData?.language &&
      bookSlotsData.language !== languageForCheckPrescription
    ) {
      onLanguageChangeForCheckPrescription(bookSlotsData.language)
    }
  }, [isFormOpen])

  return (
    <ScheduleAppointmentFormContainer>
      <Modal
        showModal={isFormOpen}
        onClose={toggleScheduleFormOpen}
        additionalContentClass="modal-content"
      >
        <div className="schedule-screen-content">
          <ScheduleScreen
            state={{
              isOrderFlow: true,
              isLoading: isLoadingCheckPrescriptionData,
              scheduleScreenUIData: appointmentBottomSheetConfig,
              languageSelectedByUser: bookSlotsData?.language,
              languageListForOrderFlow: checkPrescriptionData?.languageList,
              slotData: {
                isIAAvailable: iaAvailable,
                selectDoctor: {
                  data: [
                    {},
                    {
                      selectAvailable: {
                        data: [{}, {...availableSlots}],
                      },
                    },
                  ],
                },
              },
              skipSlotsTransformation: true,
            }}
            actions={{
              onSaveChangesClicked,
              onLanguageChangeForCheckPrescription,
            }}
          />
        </div>
      </Modal>
    </ScheduleAppointmentFormContainer>
  )
}

export default Responsive(ScheduleAppointmentForm)
