import React, {memo, useEffect, useCallback, useState} from 'react'
import VideoEmbed, {PlayIcon} from '../../../../common/VideoEmbed'
import {StyledVideoWrapper} from './VideoReviews.styles'

const RenderItem = (props) => {
  const {video, isActive, thumbnail, playIconImage = ''} = props
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (isPlaying && !isActive) {
      setIsPlaying(false)
    }
  }, [isActive, isPlaying])

  const handlePause = useCallback(() => {
    setIsPlaying(false)
  }, [])
  const handlePlay = useCallback(() => {
    setIsPlaying(true)
  }, [])

  return (
    <StyledVideoWrapper>
      <div className="video_container">
        <VideoEmbed
          videoLink={video}
          thumbnail={thumbnail}
          playIcon={<PlayIcon playIconImage={playIconImage} />}
          playing={isPlaying}
          onPause={handlePause}
          onPlay={handlePlay}
          controls={true}
        />
      </div>
      <div className="stopper"></div>
    </StyledVideoWrapper>
  )
}

export default memo(RenderItem)
