import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useGenericActions} from '../../hooks'
import {
  SearchResultProductList,
  SearchInputWithRecommendationProps,
} from './SearchInputWithRecommendation.types'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {Product} from '../CategoryProductCardGrid/CategoryProductCard/CategoryProductCard.interface'
import {
  useInfiniteHits,
  useInstantSearch,
  useSearchBox,
} from 'react-instantsearch-hooks'
import debounce from 'lodash/debounce'
import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'
import {useDeviceTypeContext} from '@web-components'
import {useSearchInputStore} from './useSearchInputState.store'
import validator from 'validator'
import {useRouter} from 'next/router'

const generateSlugURL = (_id: string, _slug?: string) => {
  const BRAND = process.env.NEXT_PUBLIC_BRAND || 'lj'

  if (!_slug) {
    return BRAND === 'mm' ? `/dp/${_id}` : `/product/${_id}`
  }

  return BRAND === 'mm' ? `/dp/${_slug}/${_id}` : `/product/${_id}`
}

const useSearchInputWithRecommendation = (
  props: BaseWidgetProps<SearchInputWithRecommendationProps>
) => {
  const {widgetData, id} = props
  const {
    searchBarConfig,
    searchResultConfig,
    source: widgetDataSource = '',
    enableQuantityIndicator = false,
  } = widgetData || {}

  const {
    placeHolder = '',
    enableSearchInputAutoFocusOnMobile = false,
    searchInputAutoFocusTimeoutInMilliSeconds = 3000,
    enableSearchInputAutoFocusOnDesktop = false,
  } = searchBarConfig || {}
  const {
    emptySearchResultConfig,
    inStockCtaLabel = '',
    outOfStockCtaLabel = '',
    inStockCtaAction,
    outOfStockCtaAction,
  } = searchResultConfig || []
  const searchQuery = useSearchInputStore((store) => store.searchQuery)
  const setSearchQuery = useSearchInputStore((store) => store.setSearchQuery)
  const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false)
  const {genericAction, handleGenericActions} = useGenericActions()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const searchBarRef = useRef<HTMLDivElement>(null)
  const {refine} = useSearchBox()
  const {updateItemToCart} = useUpdateItemToCart()
  const {hits}: SearchResultProductList[] = useInfiniteHits()
  const {isMobile} = useDeviceTypeContext()
  const {status} = useInstantSearch()
  const initialIdle = useRef(true)
  const router = useRouter()

  const isLoading = useMemo(() => {
    return (
      status === 'stalled' ||
      status === 'loading' ||
      (initialIdle.current && !!searchQuery)
    )
  }, [searchQuery, status])

  const source = useMemo(() => widgetDataSource || id, [id, widgetDataSource])

  const products = useMemo(() => {
    return hits.map((hit: SearchResultProductList) => {
      return {
        name: hit?.prod_name,
        urlKey: hit?.objectID,
        rating: hit?.rating_score,
        discountText: `${hit?.discount}% off`,
        price: hit?.mrp,
        discountedPrice: hit?.sale_price,
        sku: hit?.prod_sku,
        image: hit?.prod_img,
        discount: hit?.discount,
        tags: hit?.tags,
        id: hit?.prod_id,
        category: hit?.prod_cat,
        slugUrl: generateSlugURL(hit?.objectID, hit?.slug),
        productUrl: '',
        isRx: '',
        outOfStock: hit?.outOfStock,
        ctaLabel: hit?.outOfStock ? outOfStockCtaLabel : inStockCtaLabel,
        cta: hit?.outOfStock ? outOfStockCtaAction : inStockCtaAction,
        For: hit?.card_for_with?.For,
        Rating: hit?.rating_score,
        discountPriceLabel: `₹${hit?.sale_price}`,
        priceLabel: `₹${hit?.mrp}`,
        queryId: hit?.__queryID,
        position: hit?.__position,
      }
    })
  }, [
    hits,
    outOfStockCtaLabel,
    inStockCtaLabel,
    outOfStockCtaAction,
    inStockCtaAction,
  ])

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (searchTerm) {
          refine(searchTerm)
        }
      }, 200),
    [refine]
  )

  const updateURL = useCallback((query: string) => {
    const newURL = query
      ? `${window.location.pathname}?q=${encodeURIComponent(query)}`
      : window.location.pathname

    window.history.replaceState(
      {...window.history.state, searchTerm: query, as: newURL},
      '',
      newURL
    )
  }, [])

  const handleSearch = useCallback(
    (searchTerm: string) => {
      setSearchQuery(searchTerm)
      debouncedChangeHandler(searchTerm)
      updateURL(searchTerm)
    },
    [debouncedChangeHandler, setSearchQuery, updateURL]
  )

  useEffect(() => {
    const searchParamAtLaunch =
      new URLSearchParams(window.location.search).get('q') || ''

    const sanitizedSearchParamAtLaunch = validator.escape(searchParamAtLaunch)
    if (sanitizedSearchParamAtLaunch) {
      setSearchQuery(sanitizedSearchParamAtLaunch)
      handleSearch(sanitizedSearchParamAtLaunch)
    }
  }, [handleSearch, setSearchQuery])

  useEffect(() => {
    if (searchQuery) handleSearch(searchQuery)
  }, [handleSearch, searchQuery])

  useEffect(() => {
    const handleScroll = () => {
      if (!searchBarRef.current) return

      const topOffset = 87
      const isScrolledPast = window.scrollY > topOffset

      if (isScrolledPast) {
        searchBarRef.current.classList.add('sticky-shadow')
      } else {
        searchBarRef.current.classList.remove('sticky-shadow')
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [searchBarRef])

  useEffect(() => {
    if (isMobile && enableSearchInputAutoFocusOnMobile && !isSearchFocused) {
      timeoutRef.current = setTimeout(() => {
        searchInputRef.current?.focus()
      }, searchInputAutoFocusTimeoutInMilliSeconds)

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = null
        }
      }
    }
  }, [
    enableSearchInputAutoFocusOnMobile,
    isSearchFocused,
    searchInputAutoFocusTimeoutInMilliSeconds,
    isMobile,
  ])

  useEffect(() => {
    if (!isMobile && enableSearchInputAutoFocusOnDesktop && !isSearchFocused) {
      searchInputRef.current?.focus()
    }
  }, [enableSearchInputAutoFocusOnDesktop, isMobile, isSearchFocused])

  useEffect(() => {
    if (status === 'loading') {
      initialIdle.current = false
    }
  }, [status])

  useEffect(() => {
    const handlePopState = () => {
      setSearchQuery('')
      window.history.replaceState(null, '', `${router.pathname}`)
    }

    window.addEventListener('popstate', handlePopState)
  }, [])

  const handleFocus = useCallback(() => {
    setIsSearchFocused(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [timeoutRef, setIsSearchFocused])

  const handleSearchQueryChange = useCallback(
    (e: any) => {
      handleSearch(e.target.value)
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    [handleSearch]
  )

  const handleBackClick = useCallback(() => {
    genericAction({
      actionName: 'NAVIGATE_BACK',
      params: {},
    })
    handleGenericActions()
  }, [genericAction])

  const handleCrossClick = useCallback(() => {
    handleSearch('')
  }, [handleSearch])

  const handleCardClick = useCallback(
    (product: Product) => {
      const {id = '', queryId = '', position = ''} = product || {}
      handleGenericActions([
        {
          actionName: 'OPEN_PDP',
          params: {source: source, query: searchQuery, ...product},
        },
        {
          actionName: 'SEND_EVENT_TO_ALGOLIA',
          params: {
            eventName: 'PRODUCT_VIEWED',
            id: id,
            queryId,
            position,
          },
        },
      ])
    },
    [handleGenericActions, searchQuery, source]
  )
  const handleCtaClick = useCallback(
    (product: Product) => {
      const {action = '', actionData = {}} = product?.cta || {}
      const {id = '', queryId = '', position = ''} = product || {}
      if (!action) return
      handleGenericActions([
        {
          actionName: action,
          params: {
            ...product,
            query: searchQuery,
            source: source,
            ...(actionData || {}),
          },
        },
        {
          actionName: 'SEND_EVENT_TO_ALGOLIA',
          params: {
            eventName: 'ADD_TO_CART',
            id: id,
            queryId,
            position,
          },
        },
      ])
    },
    [handleGenericActions, searchQuery, source]
  )

  const handleUpdateCartItem = useCallback(
    (id: number, sku: string, quantity: number) => {
      updateItemToCart({
        sku,
        quantity,
        isMiniCart: true,
      })
    },
    [updateItemToCart]
  )

  const handleReduceQuantity = useCallback(
    (id, sku, quantity) => {
      handleUpdateCartItem(id, sku, quantity)
    },
    [handleUpdateCartItem]
  )

  const handleIncreaseQuantity = useCallback(
    (id, sku, quantity) => {
      handleUpdateCartItem(id, sku, quantity)
    },
    [handleUpdateCartItem]
  )

  const isSearchResultEmpty = useMemo(() => {
    return products && !(products.length > 0)
  }, [products])

  const showProductList = useMemo(() => {
    return searchQuery
  }, [searchQuery])
  const searchBarData = useMemo(() => {
    return {
      searchQuery,
      placeHolder,
      handleSearchQueryChange,
      handleBackClick,
      handleCrossClick,
      handleFocus,
      searchInputRef,
      showCrossIcon: showProductList,
    }
  }, [
    searchQuery,
    placeHolder,
    handleSearchQueryChange,
    handleBackClick,
    handleCrossClick,
    handleFocus,
    searchInputRef,
    showProductList,
  ])

  const searchResultData = useMemo(() => {
    return {
      products: products,
      handleCardClick: handleCardClick,
      handleCtaClick: handleCtaClick,
      handleReduceQuantity: handleReduceQuantity,
      handleIncreaseQuantity: handleIncreaseQuantity,
      enableQuantityIndicator: enableQuantityIndicator,
      emptySearchResultConfig: emptySearchResultConfig,
    }
  }, [
    handleCardClick,
    handleCtaClick,
    handleReduceQuantity,
    handleIncreaseQuantity,
    emptySearchResultConfig,
    products,
    enableQuantityIndicator,
  ])

  return [
    {
      searchBarData,
      searchResultData,
      isSearchResultEmpty,
      showProductList,
      isLoading,
      searchBarRef,
    },
    {},
  ]
}

export default useSearchInputWithRecommendation
