import noop from 'lodash/noop'
import {useCallback} from 'react'

import {StyledCalloutWrapper, StyledCta} from './Callout.styles'
import CalloutCloseIcon from './CalloutCloseIcon'

function Callout({
  showCallout,
  onCloseCallout,
  text = '',
  bgColor = '',
  textColor = '',
  cta = {},
  onClick = noop,
  isSecondaryHandler = false,
}) {
  const handleCloseClick = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      onCloseCallout()
    },
    [onCloseCallout]
  )

  const handleClick = useCallback(() => {
    if (isSecondaryHandler) {
      onClick()
      return
    }
    onClick(cta)
  }, [cta, isSecondaryHandler, onClick])

  if (!text || !showCallout) {
    return null
  }

  const {label, bgColor: ctaBgColor, textColor: ctaTextColor} = cta
  return (
    <>
      <StyledCalloutWrapper
        bgColor={bgColor}
        textColor={textColor}
        as="button"
        type="button"
        onClick={handleClick}
        className="page-call-out"
      >
        <div className="info">
          <span className="text">{text}</span>
          {label && (
            <StyledCta bgColor={ctaBgColor} textColor={ctaTextColor}>
              {label}
            </StyledCta>
          )}
          <span className="close-icon" onClick={handleCloseClick}>
            <CalloutCloseIcon color={textColor} />
          </span>
        </div>
      </StyledCalloutWrapper>
    </>
  )
}

export default Callout
