import {useEffect, useRef, useState} from 'react'

const useInnerHeight = () => {
  const [innerHeight, setInnerHeight] = useState<number | null>(null)

  useEffect(() => {
    setInnerHeight(window.innerHeight)
  }, [])

  return {
    innerHeight,
  }
}

export {useInnerHeight}
