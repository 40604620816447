import styled from 'styled-components'

export const ProductSummaryContainer = styled.div`
  width: 100%;

  .rating-and-price-details {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .rating-and-price-details-item {
    color: var(--dls-secondary-text-colors);
  }

  .ratings {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    line-height: 1;
  }

  .rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .divider {
    width: 1px;
    height: 6px;
    background-color: var(--dls-divider-light-color);
    margin: 0 var(--dls-size-8);
  }

  .product-name {
    margin-bottom: 6px;
  }

  .price {
    display: flex;
    align-items: center;
    gap: var(--dls-size-8);
  }

  .stricked-price {
    color: var(--dls-secondary-text-colors);
    text-decoration: line-through;
  }

  .tax-label {
    color: var(--dls-disabled-text-colors);
  }

  .discount-tag {
    display: flex;
    flex-shrink: 0;
    position: relative;
  }

  .discount-tag-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: var(--dls-white-color);
  }
`
