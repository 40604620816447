import {
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import React, {useEffect} from 'react'
import {docCookies} from 'src/utils/cookie'

function SessionHandling() {
  const {
    user: {isLoggedIn, repeatUser},
  } = useUserStore()
  const {isUserHydration} = useLoadingStore()
  useEffect(() => {
    if (!isUserHydration) {
      if (repeatUser) {
        docCookies.setItem({
          cookieName: 'isRept',
          cookieValue: 1,
          expiry: 2595000,
          skipDomain: true,
          cookiePath: '/',
        })
      } else {
        docCookies.setItem({
          cookieName: 'isRept',
          cookieValue: 0,
          expiry: 2595000,
          skipDomain: true,
          cookiePath: '/',
        })
      }
    }
  }, [isUserHydration, repeatUser])

  return <></>
}

export default SessionHandling
