import {useContext, createContext, useMemo, useCallback} from 'react'

export const CurrencyContext = createContext({
  country: 'IN',
})

function InternationalizationContextProvider({children, country = 'IN'}) {
  const currency = useMemo(() => {
    switch (country) {
      case 'IN':
        return '₹'
      case 'AE':
        return 'AED'
      case 'SA':
        return 'SAR'
      default:
        return '₹'
    }
  }, [country])

  const contextValue = {
    currency,
    country,
  }

  return (
    <CurrencyContext.Provider value={contextValue}>
      {children}
    </CurrencyContext.Provider>
  )
}

export default InternationalizationContextProvider

export const useInternationalizationContext = () => {
  const {currency, country} = useContext(CurrencyContext)

  const getAmountWithCurrency = useCallback(
    (amount = 0) => {
      return `${currency} ${amount}`
    },
    [currency]
  )

  return {
    currency,
    country,
    getAmountWithCurrency,
  }
}
