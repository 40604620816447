let NUDGE_EVENT_PILE: any[] = []
export const recordToExternalNudge = async (
  event: string,
  data: Record<string, any>
) => {
  // @ts-ignore
  if (window.__MWSC__?.externalNudge) {
    if (NUDGE_EVENT_PILE.length > 0) {
      NUDGE_EVENT_PILE.forEach((eventData) => {
        // @ts-ignore
        window.__MWSC__.externalNudge.track(eventData)
      })
      NUDGE_EVENT_PILE = []
    }
    // @ts-ignore
    window.__MWSC__.externalNudge?.track({event: event, properties: data})
  } else {
    NUDGE_EVENT_PILE.push({event: event, properties: data})
  }
}

export const setExternalNudgeUser = async (
  nudgeId: string,
  data: Record<string, any>
) => {}

export const identifyUserToExternalNudge = async (
  nudgeId: string,
  data: Record<string, any>
) => {}
