import styled from 'styled-components'

export const StyledWrapper = styled.div`
  padding: 0 28px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;

  .reason {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 30%;
    flex-direction: column;
    align-items: center;
    justify-content: ce;
    align-content: center;
    .reason-icon {
      width: 28px;
      height: 28px;
      margin-bottom: 5px;
    }

    .reason-text {
      word-wrap: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.25px;
      color: var(--brand-primary-black-color);
      opacity: 0.7;
    }
  }
`
