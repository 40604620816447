import styled from 'styled-components'

export const StyledDrawerWrapper = styled.div`
  .drawer {
    scrollbar-width: none;
    -ms-overflow-style: none;
    background: var(--brand-primary-silver-color);
    position: fixed;
    top: 0;
    right: 0;
    max-width: 450px;
    width: 100%;
    z-index: 9;
    box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.5);
    overflow-y: visible;
    overflow-x: visible;
    border-radius: 0 0 30px 30px;
  }

  .drawer::-webkit-scrollbar {
    width: 0px;
  }

  .drawer[data-drawer-open='true'] {
    body {
      overflow: hidden;
    }

    transition: transform
      ${(props) => `${Number.parseInt(props.transitionTime) || 500}ms`} ease;

    @media screen and (min-width: 768px) {
      margin-top: 30px;
    }
  }

  .drawer[data-drawer-open='false'] {
    transform: translateY(-100%);
    transition: transform
      ${(props) => `${Number.parseInt(props.transitionTime) || 500}ms`} ease;

    @media screen and (min-width: 768px) {
      transform: translate(-50%, -100%);
      left: 50%;
    }
  }

  @media screen and (min-width: 768px) {
    .drawer {
      width: 450px;
      border-radius: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`
