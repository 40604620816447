import {useCallback, useEffect, useRef} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {
  useCancelAppointment,
  useConvertSToIA,
  useFutureAppointments,
  useIsIAAvailable,
  useRescheduleAppointment,
  useGetAppointmentDetails,
  useFloatingIslandNudgesData,
  useCommsActiveRoute,
} from '@mosaic-wellness/redux-action-library'
import {useDirectAssessment} from 'src/context'
import {useGenericCta} from '../useGenericCta'
import {
  IUseSuccessScreenProps,
  IUseSuccessScreenReturnType,
} from './directAssessment.interface'
import {useConsultTracking} from '../tracking/useConsultTracking'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useRedirection} from '..'

const BRAND = process.env.NEXT_PUBLIC_BRAND || 'mm'

function useDaSuccessScreen({
  successScreen,
  supportedCategories,
}: IUseSuccessScreenProps): IUseSuccessScreenReturnType {
  const {
    state: {isIAAvailable},
    actions: {checkIsIAAvailable},
  } = useIsIAAvailable()

  const {
    state: {isConverted, convertError},
    actions: {convertScheduleToIA},
  } = useConvertSToIA()

  const [
    {isRescheduleInProgress, rescheduleAppointmentData},
    {rescheduleAppointment, resetRescheduleAppointmentData},
  ] = useRescheduleAppointment()

  const [
    {futureAppointmentData, loadingFutureAppointments},
    {checkFutureAppointments},
  ] = useFutureAppointments()

  const {
    state: {isCancelled},
    actions: {cancelAppointment},
  } = useCancelAppointment()

  const [{data}] = useCommsActiveRoute()
  const [{currentConsultVersion = ''}, {handleRedirection = noop}] =
    useRedirection()

  const [
    {
      appointmentDetails: appointmentDetailsFromScheduleToken,
      isAppointmentDataLoading,
    },
    {getAppointmentDetails},
  ] = useGetAppointmentDetails()

  const [{}, {getFloatingIslandNudgeData}] = useFloatingIslandNudgesData()

  const {
    state: {category, appointmentDetails},
  } = useDirectAssessment()

  const isApp = checkIsApp()

  const {handleCta} = useGenericCta()
  const {pushFutureAppointmentDataToDataLayer} = useConsultTracking({})

  const {pendingOrderAppointment} = futureAppointmentData || {}
  const {
    scheduleToken,
    rxCategories = [],
    imageUploaded = 0,
  } = pendingOrderAppointment || {}

  const onDownloadCtaClick = useCallback(
    (link) => {
      if (link) {
        handleCta({action: 'LINK', link})
      }
    },
    [handleCta]
  )

  const handleCommsRedirect = useCallback(() => {
    if (isApp) {
      handleRedirection({
        action: 'SPA_LINK',
        link: data?.link,
        version: currentConsultVersion,
      })
    }
  }, [currentConsultVersion, data?.link, handleRedirection, isApp])

  const onReschedulePendingOrderAppointment = useCallback(() => {
    //TODO: refactor and use switch case here in
    if (!imageUploaded && supportedCategories.includes(rxCategories[0])) {
      handleCta({
        action: 'SPA_LINK',
        link: `upload-image?schedule_token=${scheduleToken}`,
      })
    } else if (imageUploaded && supportedCategories.includes(rxCategories[0])) {
      handleCta({
        action: 'SPA_LINK',
        link: `confirmation-screen?schedule_token=${scheduleToken}`,
      })
    } else {
      handleCta({
        action: 'SPA_LINK',
        link: `confirmation-screen?schedule_token=${scheduleToken}`,
      })
    }
  }, [
    imageUploaded,
    supportedCategories,
    rxCategories,
    handleCta,
    scheduleToken,
  ])

  const futureAppointmentLoaded = useRef(false)

  useEffect(() => {
    if (loadingFutureAppointments) {
      futureAppointmentLoaded.current = true
      return
    }

    if (futureAppointmentLoaded.current && !isEmpty(futureAppointmentData)) {
      pushFutureAppointmentDataToDataLayer(futureAppointmentData)
    }
  }, [
    loadingFutureAppointments,
    futureAppointmentData,
    pushFutureAppointmentDataToDataLayer,
  ])

  return [
    {
      category: category,
      brand: BRAND,
      isReschedule: true,
      showInfoCards: false,
      isRescheduleInProgress,
      rescheduleAppointmentData,
      isIAAvailable,
      isConverted,
      convertError,
      futureAppointmentData,
      loadingFutureAppointments,
      isCancelled,
      successScreen,
      showAppointmentCardButtons:
        appointmentDetails.showAppointmentCardButtons === false ? false : true,
      appointmentDetailsFromScheduleToken,
      isAppointmentDataLoading,
    },
    {
      rescheduleAppointment,
      checkIsIAAvailable,
      convertSchToIA: convertScheduleToIA,
      checkFutureAppointments,
      cancelAppointment,
      handleCta,
      resetRescheduleAppointmentData,
      onDownloadCtaClick,
      getAppointmentDetails,
      handleCommsRedirect,
      onReschedulePendingOrderAppointment,
      getFloatingIslandNudgeData,
    },
  ]
}

export {useDaSuccessScreen}
