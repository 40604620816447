import styled from 'styled-components'

export const FloatingIslandNudgesContainer = styled.div`
  z-index: 9;
  cursor: pointer;

  .user-nudge {
    padding: 12px 16px;
    background-color: ${({backgroundColor = ''}) => backgroundColor};
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nudge-text {
      color: ${({textColor = ''}) => textColor};
      font-weight: var(--brand-font-weight-normal);
      font-size: 14px;
      line-height: 16px;
    }
  }

  &[data-position='top'] {
    .user-nudge {
      border-radius: 0px 0px 8px 8px;
    }
  }

  &[data-position='bottom'] {
    position: fixed;
    bottom: 16px;
    left: 16px;
    right: 16px;

    .user-nudge {
      border-radius: 8px;
    }
  }
`
