import React, {memo, useEffect, useMemo} from 'react'
import noop from 'lodash/noop'
import {
  OptimizedImage,
  OptimizedVideo,
  Typography,
  TypographyVariants,
} from '@web-components'
import {DesktopMediaSliderWrapper} from '../MediaSlider.styles'
import {ArrowLeftWhite} from '@web-components'
import {ArrowRightWhite} from '@web-components'
import {DesktopMediaSliderProps, MediaWithCta} from '../MediaSlider.interface'

// Memoized slide content component with key prop for proper remounting
const SlideContent = memo(
  ({
    slide,
    index,
    onImageClick = noop,
  }: {
    slide: MediaWithCta
    index: number
    onImageClick?: (cta: any) => void
  }) => {
    const {
      image = '',
      type = '',
      video = '',
      ctaLabel = '',
      cta: {params: {link = '#'} = {link: '#'}} = {params: {link: '#'}},
    } = slide || {}

    const hrefLink = String(link)

    if (type === 'video') {
      return (
        <div className="media-container">
          <OptimizedVideo
            key={`video-${index}`} // Force remount of video component
            state={{
              source: video,
              videoComponentClassName: `video-slider-${index}`,
              additionalClassNames: 'slider-video',
              autoplay: true,
              playsInline: true,
              loop: true,
              muted: true,
            }}
          />
          {ctaLabel && (
            <div className="cta-overlay">
              <a href={hrefLink}>
                <div className="cta-button">
                  <Typography variant={TypographyVariants.TAG_BOLD}>
                    {ctaLabel}
                  </Typography>
                </div>
              </a>
            </div>
          )}
        </div>
      )
    }

    return (
      <a
        href={hrefLink}
        onClick={(e) => {
          e.preventDefault()
          onImageClick(slide.cta)
        }}
      >
        <div className="media-container">
          <OptimizedImage
            key={`image-${index}`} // Force remount of image component
            customClassName="homepage-carousel-image"
            desktopViewWidth="FULL"
            mobileViewWidth="FULL"
            aspectWidth={600}
            aspectHeight={300}
            source={image}
          />
          {ctaLabel && (
            <div className="cta-overlay">
              <button
                className="cta-button"
                onClick={(e) => {
                  e.stopPropagation()
                  onImageClick(slide.cta)
                }}
              >
                <Typography variant={TypographyVariants.TAG_BOLD}>
                  {ctaLabel}
                </Typography>
              </button>
            </div>
          )}
        </div>
      </a>
    )
  }
)

const DesktopMediaSlider = (props: DesktopMediaSliderProps) => {
  const {widgetData, state, actions} = props || {}
  const {onImageClick = noop} = actions || {}
  const slides = widgetData?.images || []
  const {currentSlide = 0, setCurrentSlide = noop} = state || {}
  const {
    autoScrollTiming = 4000,
    enableAutoScroll = true,
    aspectRatio = 2,
    disablePeek = false,
  } = widgetData || {}

  const showArrow = useMemo(() => {
    return slides.length > 1
  }, [slides.length])

  const getSlideIndex = (offset: number) =>
    (currentSlide + offset + slides.length) % slides.length

  const handleNavigation = (direction: 'prev' | 'next') => {
    const offset = direction === 'prev' ? -1 : 1
    setCurrentSlide(
      (prevSlide: number) =>
        (prevSlide + offset + slides.length) % slides.length
    )
  }

  useEffect(() => {
    if (!enableAutoScroll) return

    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide: number) => (prevSlide + 1) % slides.length)
    }, autoScrollTiming)

    return () => clearInterval(intervalId)
  }, [enableAutoScroll, autoScrollTiming, slides.length, setCurrentSlide])

  return (
    <DesktopMediaSliderWrapper aspectRatio={aspectRatio}>
      {!disablePeek && (
        <div className="slide-radius">
          <div key={`prev-slide-${currentSlide}`}>
            <SlideContent
              slide={slides[getSlideIndex(-1)]}
              index={getSlideIndex(-1)}
            />
          </div>
        </div>
      )}
      <div className="center-slide">
        {showArrow && (
          <div
            className="left-arrow cursor-pointer"
            onClick={() => handleNavigation('prev')}
          >
            <ArrowLeftWhite />
          </div>
        )}
        <div className="slide-radius">
          <div key={`current-slide-${currentSlide}`}>
            <SlideContent
              slide={slides[currentSlide]}
              index={currentSlide}
              onImageClick={onImageClick}
            />
          </div>
        </div>
        {showArrow && (
          <div
            className="right-arrow cursor-pointer"
            onClick={() => handleNavigation('next')}
          >
            <ArrowRightWhite />
          </div>
        )}
      </div>
      {!disablePeek && (
        <div className="slide-radius">
          <div key={`next-slide-${currentSlide}`}>
            <SlideContent
              slide={slides[getSlideIndex(1)]}
              index={getSlideIndex(1)}
            />
          </div>
        </div>
      )}
    </DesktopMediaSliderWrapper>
  )
}

export default memo(DesktopMediaSlider)
