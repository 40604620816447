import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;

  /* .ratings-and-reviews__reviews-container {
    margin-top: min(
      100px,
      calc(40px + (100 - 40) * ((100vw - 360px) / (1440 - 360)))
    );
  } */
  .empty-results {
    font-weight: var(--brand-font-weight-normal);
    font-size: 16px;
    line-height: 18px;
    color: #6c6c6c;
    margin-top: 16px;
    text-align: center;
  }
  .ratings-and-reviews__button-group {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    width: 60%;
    margin: auto;
  }

  .ratings-and-reviews__button-group button {
    width: 100%;
    flex-basis: 328px;
    flex-grow: 1;
    border: none;
    padding: min(
      17px,
      calc(10px + (17 - 10) * ((100vw - 360px) / (1440 - 360)))
    );
    border-radius: min(
      20px,
      calc(12px + (20 - 12) * ((100vw - 360px) / (1440 - 360)))
    );
    font-size: min(
      20px,
      calc(14px + (20 - 14) * ((100vw - 360px) / (1440 - 360)))
    );
    line-height: min(
      28px,
      calc(20px + (28 - 20) * ((100vw - 360px) / (1440 - 360)))
    );
    font-weight: var(--brand-font-weight-heavy);
    background-color: var(--brand-primary-green-color);
    color: var(--brand-primary-white-color);
    cursor: pointer;
    margin-top: 8px;
    font-family: var(--brand-font-family-text);
  }

  .ratings-and-reviews__button-group button:first-child {
    background-color: transparent;
    border: 1px solid var(--brand-primary-green-bg3-color);
    color: var(--brand-primary-green-color);
    margin-right: 20px;
  }

  @media (min-width: 768px) {
    .ratings-and-reviews__button-group {
      width: 100%;
    }

    .empty-results {
      margin-top: 40px;
      font-size: 24px;
      line-height: 28px;
    }
  }
`
