import React, {useCallback, useContext, useRef, useMemo, useState} from 'react'

const contextInitialState = {
  activeSection: '',
}
const ProductSectionScrollContext = React.createContext(contextInitialState)

function ProductSectionScrollContextProvider({children, data}) {
  const {sections} = data || {}
  const [activeSection, setActiveSection] = useState(() => {
    if (sections?.length > 0) {
      return sections[0].section
    }
    return ''
  })

  const isHandlingScrollRef = useRef(false)

  const handleScrollToSection = useCallback((sectionName) => {
    setActiveSection(sectionName)
    const sectionElement = document.querySelector(
      `[data-scrollSection=${sectionName}`
    )
    if (sectionElement) {
      isHandlingScrollRef.current = true

      const pixelsToScrollVertically =
        sectionElement.getBoundingClientRect().top + window.pageYOffset - 150

      window.scrollTo({top: pixelsToScrollVertically, behavior: 'smooth'})

      setTimeout(() => {
        isHandlingScrollRef.current = false
      }, 1000)
    }
  }, [])

  const contextValue = useMemo(() => {
    return {
      activeSection,
      handleScrollToSection,
    }
  }, [activeSection, handleScrollToSection])

  return (
    <ProductSectionScrollContext.Provider value={contextValue}>
      {children}
    </ProductSectionScrollContext.Provider>
  )
}

export default ProductSectionScrollContextProvider

export const useProductSectionScrollContext = () => {
  const {activeSection, handleScrollToSection} = useContext(
    ProductSectionScrollContext
  )

  return {
    activeSection,
    handleScrollToSection,
  }
}
