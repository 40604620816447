import styled from 'styled-components'
import {CarouselDotsProps} from './MediaSlider.interface'

export const MediaSliderContainer = styled.div`
  .image-container {
    width: 100%;
  }
  .slider-image {
    border-radius: var(--dls-size-8);
    height: auto;
    object-fit: cover;
    border: 1px solid var(--dls-divider-light-color);
  }

  .dots {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-top: var(--dls-size-8);
  }

  .dot {
    height: 5px;
    width: 5px;
    flex-shrink: 0;
    margin: 0 2px;
    background-color: #ccc;
    border-radius: 50%;

    &[data-is-active='true'] {
      height: 5px;
      width: 5px;
      background-color: var(--dls-brand-primary-color-500);
    }
  }

  &[data-is-leftspace='true'] {
    margin-left: var(--dls-size-16);
  }

  @media screen and (min-width: 768px) {
    margin: auto;
  }

  .dots-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    overflow-x: auto;
    margin-bottom: 50px;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    align-items: center;
  }
`

export const CarouselDots = styled.span<CarouselDotsProps>`
  width: 15px;
  height: 15px;
  margin: 0 10px;
  background-color: ${({active}) =>
    active ? 'var(--dls-brand-primary-color-500)' : '#ccc'};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  @media (max-width: 768px) {
    width: ${({active}) => (active ? '15px' : '10px')};
    height: ${({active}) => (active ? '15px' : '10px')};
  }
`

export const MobileMediaSliderWrapper = styled.div`
  .slider-image {
    width: 100%;
  }
  .media-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  .cta-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .cta-button {
    padding: 16px 50px;
    border-radius: var(--dls-size-16);
    background: var(--dls-white-color);
    border: none;
    color: var(--dls-brand-primary-color-500);
    margin-bottom: var(--dls-size-16);
  }

  .slider-video {
    border-radius: var(--dls-size-16);
  }
`

export const DesktopMediaSliderWrapper = styled.div<{aspectRatio: number}>`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: auto;
  position: relative; /* Ensure absolute-positioned elements work within this context */
  width: 100%;
  gap: 50px;

  .slide-radius {
    width: 600px;
    aspect-ratio: ${({aspectRatio}) => (aspectRatio ? aspectRatio : 1)};
    height: auto;
    img {
      border-radius: var(--dls-size-16);
      object-fit: cover;
      width: 600px;
      height: auto;
    }
  }

  .left-arrow,
  .right-arrow {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    width: 50px;
  }

  .left-arrow {
    left: 0;
  }

  .right-arrow {
    right: 0;
    justify-content: flex-end;
  }

  .center-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .media-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  .cta-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .cta-button {
    padding: 16px 50px;
    border-radius: var(--dls-size-16);
    background: var(--dls-white-color);
    border: none;
    color: var(--dls-brand-primary-color-500);
    margin-bottom: var(--dls-size-16);
  }

  .slider-video {
    border-radius: var(--dls-size-16);
    aspect-ratio: ${({aspectRatio}) => (aspectRatio ? aspectRatio : 1)};
    width: 600px;
    height: auto;
    object-fit: cover;
  }
`
