export const EVENT_MAP = {
  ADD_ADDRESS_CLICKED: 'addAddressClicked',
  ADDRESS_ID_SWITCHED: 'addressIdSwitched',
  ADDRESS_EDIT: 'addressEdit',
  ADD_TO_CART_CLICK_PD: 'addToCartClickPd', // ! DONE
  ADD_TO_CART_CLICK: 'addToCartClick', // ! DONE
  ADD_TO_CART_CLICKED_RX_CART: 'addToCartClickRxCart',
  ADD_NEW_PAYMENT_METHOD: 'addNewPaymentMethod',
  ADDRESS_VIEWED: 'addressViewed',
  APP_PRESENCE_CHECK: 'appPresenceCheck',
  ASSESSMENT_CARD_CLICKED: 'assessmentCardClicked',
  APPLY_DISCOUNT_CLICKED: 'applyDiscountClicked',
  APPOINTMENT_BOOKED: 'appointmentBooked', // ! DONE
  APPOINTMENT_SELECTED: 'appointmentSelected', // ! DONE
  BOOK_PAYLOAD_ON_PAY_BUTTON: 'bookPayloadOnPayButton',
  BUY_NOW_CLICK_PD: 'buyNowClickPd', // ! DONE
  BUY_NOW_CLICK_CHECKOUT: 'buyNowClickCheckout', // ! DONE
  BUY_NOW_CLICK_ALL_REVIEWS: 'buyNowClickAllReviews',
  CALLOUT_CLICK: 'calloutClick',
  COUPON_COPIED: 'couponCopied',
  COUPON_CLICKED: 'couponClicked',
  COUPON_VIEWED: 'couponViewed',
  CANCELLATION_REASON_VIEWED: 'cancellationReasonViewed',
  CANCEL_ORDER_CLICKED: 'cancelOrderClicked', // ! DONE
  CANCEL_ORDER_CONFIRMED: 'cancelOrderConfirmed',
  CANCEL_ORDER_VIEWED_WALLET_POINTS: 'cancelViewedWalletPoints',
  CANCEL_ORDER_VIEWED_SHIPPED: 'cancelViewedShipped',
  CANCEL_ORDER_CLICKED_WALLET_POINTS: 'cancelClickedWalletPoints',
  CART_VIEWED: 'cartViewed',
  CART_UP_SELL_SELECTION: 'cartUpSellSelection',
  CART_UP_SELL_SHOW: 'cartUpSellShow',
  CHANGE_ADDRESS_CLICK: 'changeAddressClick',
  CHARGED: 'charged',
  CHECKOUT_CLICKED: 'checkoutClicked', // ! DONE
  CHECKOUT_VIEWED: 'checkoutViewed',
  CHECKOUT_STEP: 'checkoutStep',
  CLICKED_FAILED_MODAL: 'clickedFailedModal',
  COD_ELIGIBILITY: 'codEligibility',
  CONCERN_CLICK: 'concernClick',
  COUPON_APPLY_CLICK: 'couponApplyClick',
  COUPON_CODE: 'couponCode',
  CRED_ELIGIBILITY: 'credEligibility',
  CRED_ERROR: 'CRED_ERROR',
  CHECKOUT_CLICKED_ON_RX_CART: 'checkoutClickedOnRxCart',
  DELIVERY_TOAST_SEEN: 'deliveryToastSeen',
  DELIVERY_TOAST_SLIDER_SEEN: 'deliveryToastSliderSeen',
  DETECT_USER_CITY: 'detectUserCity', // ! DONE
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  ADDRESS_DELETE: 'addressDelete',
  ADDRESS_ERROR: 'addressError',
  FILTER_APPLIED_GET_REGIME: 'filterApplied',
  FILTER_CLICKED_GET_REGIME: 'filterClicked',
  GOKWIK_ADDRESS_ELIGIBILITY: 'gokwikEligible',
  GOOGLE_UPI_FAILED: 'googleUPIFailed',
  HOME_VIEW: 'homeView',
  LOGIN: 'LOGIN',
  LOGOUT_CLICK: 'Logout Clicked',
  LOGOUT_SUCCESS: 'logout',
  MINI_ASSESSMENT_QUESTION_ANSWERED: 'miniAssessmentQuestionAnswered',
  MINI_ASSESSMENT_OPTIONS_SELECTED: 'miniAssessmentOptionsSelected',
  MINI_ASSESSMENT_COMPLETE: 'miniAssessmentOptionsComplete',
  MINI_ASSESSMENT_VIEWED: 'miniAssessmentViewed',
  MINI_ASSESSMENT_QUESTION_VIEWED: 'miniAssessmentQuestionViewed',
  MINI_ASSESSMENT_RECOMMENDATIONS_VIEWED: 'miniAssessmentRecommendationsViewed',
  MINI_ASSESSMENT_RECOMMENDATIONS_CTA_CLICKED: 'mini-',
  MY_ORDERS_VIEWED: 'myOrdersViewed',
  MY_PROFILE_VIEWED: 'myProfileViewed',
  NAVBAR_HAMBURGER_CLICK: 'hamburgerClick',
  NAVBAR_MENU_CLICKED: 'menuLinkClicked',
  NAVBAR_SEARCH_CLICKED: 'searchClicked',
  NEW_THANKYOU_PAGE: 'newThankyouPage',
  ORDER_DETAILS_CLICKED: 'orderDetailsClicked',
  OPTIONAL_RX_AVAILABLE: 'optionalRxAvailable',
  OPTIONAL_RX_OPT_IN: 'optionalRxOptIn',
  OPTIONAL_RX_OPT_OUT: 'optionalRxOptOut',
  OTP: 'otp',
  PASSWORD: 'password',
  SOCIAL_LOGIN: 'social',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  OTP_VERIFIED_LOGIN: 'otpVerifiedLogin',
  EMAIL_VERIFIED_LOGIN: 'emailVerifiedLogin',
  OTP_VERIFIED_SIGN_UP: 'otpVerifiedSignup',
  PAGE_VIEWED: 'pageViewed',
  PAY_CLICKED: 'payClicked',
  PAYMENT_FAILED: 'paymentFailed',
  PAYMENT_SELECTED: 'paymentSelected',
  PAYMENT_VIEWED: 'paymentViewed',
  PAYMENT_BANNER_CLICKED: 'paymentBannerClicked',
  PAYMENT_ELIGIBILITY: 'paymentEligibility',
  PD_CAROUSEL_SLIDE: 'pdCarouselSlide',
  PD_DELIVERY_CHECK: 'pdDeliveryCheck',
  PD_RATING_CLICK: 'pdRatingClick',
  PD_SECTION_SCROLLED_INTO_VIEW: 'pdSectionScrolledIntoView',
  PD_SWITCH_CLICK: 'pdSwitchClick',
  PD_SECTION_VIEWED: 'pdSectionViewed',
  PDP_VIEW: 'pdpView',
  POST_ORDER_FEEDBACK: 'postOrderFeedback',
  REWARD_UNLOCKED: 'rewardUnlocked',
  RR_CONSULT_NOW: 'rRConsultNow',
  RR_SELECT_ALL: 'rRSelectAll',
  RR_VIEWED: 'rRViewed',
  RR_ATC: 'rRAtc',
  RR_BN: 'rRBn',
  RR_UP_SHELL: 'rRUpsell',
  SCHEDULE_CONFIRMED: 'scheduleConfirmed',
  SCHEDULE_SELECTED: 'scheduleSelected',
  SEARCH_PDP: 'searchPdp',
  PRODUCT_FILTER_CLICKED: 'productFilterClicked',
  SEARCH_USED: 'searchUsed',
  SEARCH_MODAL_CLOSED: 'searchModalClosed',
  SHOW_BOOK_APPOINTMENT: 'showBookAppointment',
  SHOW_MINI_ASSESSMENT: 'showMiniAssessment',
  SIGN_UP: 'SIGNUP',
  SLOT_CHANGED: 'slotChanged',
  SWIPE_TO_BOOK: 'swipeToBook',
  SWIPE_TO_BOOK_APPOINTMENT_TYPE_CHANGED: 'bookAppointmentTypeChanged',
  THANKYOU_PAGE_VIEW: 'thankyouPageView',
  TRENDING_SEARCHES_CLICKED: 'trendingSearchesClicked',
  TRUECALLER_SEEN: 'truecallerSeen',
  TRUECALLER_SKIPPED: 'truecallerSkipped',
  TRUECALLER_LOGIN: 'truecallerLogin',
  UPI_FAILED: 'upiFailed',
  USER_FETCHED: 'userFetched',
  VIDEO_SHOWCASE_CLICKED: 'videoShowCaseClicked',
  VIEW_APPOINTMENT_CLICKED: 'viewAppointmentClicked',
  VIEW_PRESCRIPTION_CLICKED: 'viewPrescriptionClicked',
  VIEW_THANKYOU_PAGE: 'viewThankYouPage',
  VIEW_DA: 'viewDa',
  VIEW_QUESTIONS: 'viewQuestions',
  VIEW_PRESCRIPTION_CLICKED_RX_CART: 'viewPrescriptionClickedRxCart',
  VIEW_RX_CART: 'viewRxCart',
  VIEW_PRODUCT_INFO_RX_CART: 'viewProductInfoRxCart',
  VIEW_PDP_FROM_RX_CART: 'viewPdpFromRxCart',
  CATEGORY_SUBMIT: 'categorySubmit',
  VIEW_SCHEDULING_SCREEN: 'viewSchedulingScreen',
  NOT_FREE_RIGHT_NOW_TOGGLED_ON: 'notFreeRightNowToggledOn',
  NOTE_FREE_RIGHT_NOW_TOGGLED_OFF: 'notFreeRightNowToggledOff',
  LANGUAGE_CHANGED_IN_DROPDOWN: 'languageChangedInDropdown',
  SCHEDULE_SCREEN_CONFIRMED_CLICKED: 'confirmationButtonClicked',
  DA_APPOINTMENT_BOOKED: 'daAppointmentBooked',
  VIEW_GET_CUSTOMER_INFO_SCREEN: 'viewGetCustomerInfo',
  GET_CUSTOMER_INFO_SUBMIT: 'getCustomerInfoSubmit',
  VIEW_IMAGE_UPLOAD: 'viewImageUpload',
  IMAGE_UPLOAD_SKIPPED: 'imageUploadSkipped',
  IMAGE_UPLOADED: 'imageUploaded',
  VIEW_APPOINTMENT_SUMMARY_TOP_SHEET: 'viewAppointmentSummaryTopSheet',
  VIEW_APPOINTMENT_SUMMARY_SCREEN: 'viewAppointmentSummaryScreen',
  S_TO_IA_SWITCH: 'sToIaSwitch',
  DA_DOWNLOAD_APP_CLICKED: 'daDownloadAppClicked',
  DA_EDIT_APPOINTMENT_CLICKED: 'daEditAppointmentClicked',
  DA_RESCHEDULE_CLICKED: 'daRescheduleClicked',
  DA_APPOINTMENT_CANCELLED: 'daAppointmentCancelled',
  VARIANT_CLICKED: 'variantClicked',
  APPOINTMENT_CONFIRM_VIEWED: 'appointmentConfirmedViewed',
  APPOINTMENT_CONFIRM_IMAGE_UPLOAD: 'imageUploadClicked',
  APPOINTMENT_CONFIRM_CONTINUE_SHOPPING: 'continueShoppingClicked',
  RX_POPUP_CLICKED: 'rXPopUpClicked',
  RX_POPUP_SEEN: 'rXPopUpSeen',
  PDP_CLICKED: 'pdpClicked',
  PRESCRIPTION_DOWNLOAD: 'prescriptionDownload',
  PRODUCT_REMOVED_FROM_RX_CART: 'productRemovedFromRxCart',
  TREATMENT_PAGE_VIEWED: 'treatmentPageViewed',
  CUSTOMISED_PLAN_CLICKED: 'findCustomisedPlanClicked',
  START_TREATMENT_CLICKED: 'startTreatmentClicked',
  FIND_SOLUTION_CLICKED: 'findSolutionClicked',
  TREATMENT_QUESTIONNAIRE_NEXT_CLICKED: 'onnextClicked',
  DA_EXIT_CLICKED: 'daExitClicked',
  DA_EXIT_CONFIRMED: 'daExitConfirmed',
  DA_EXIT_CANCELLED: 'daExitCancelled',
  DA_BACK_CLICKED: 'daBackClicked',
  FUTURE_APPOINTMENT: 'futureAppointment',
  APPOINTMENT_CARD_SHOWN: 'appointmentCardShown',
  APPOINTMENT_CARD_CLICK: 'appointmentCardClick',
  SA_VIEWED: 'saViewed',
  SA_STARTED: 'saStarted',
  SA_CHAT_OPEN: 'saChatOpen',
  SA_NAME_SUBMITTED: 'saNameSubmitted',
  SA_CATEGORY_VIEWED: 'saCategoryViewed',
  SA_CATEGORY_SUBMITTED: 'saCategorySubmitted',
  SA_QUESTION: 'saQuestion',
  SA_QUESTION_UNDO: 'saQuestionUndo',
  SA_BASIC_DETAILS: 'saBasicDetails',
  SA_SUBMITTED: 'saSubmitted',
  SA_REPORT_SEEN: 'saReportSeen',
  SA_APPOINTMENT_VIEWED: 'saAppointmentViewed',
  SA_APPOINTMENT_SUBMITTED: 'saAppointmentSubmitted',
  SA_APPOINTMENT_BOOKED: 'saAppointmentBooked',
  SA_PLAN_CLICKED: 'saPlanClicked',
  SA_PLAN_VIEWED: 'saPlanViewed',
  SA_PLAN_PRODUCT_REMOVE: 'saPlanProductRemove',
  SA_PLAN_PRODUCT_ADD: 'saPlanProductAdd',
  SA_PLAN_PRODUCT_INFO_CARD_CLICKED: 'saPlanProductInfoCardClicked',
  SA_PLAN_APPOINTMENT_CARD_SHOWN: 'saPlanAppointmentCardShown',
  SA_PLAN_TO_PDP: 'saPlanToPDP',
  SA_PLAN_ORDER_CLICKED: 'saPlanOrderClicked',
  SA_PRESCRIPTION_VIEWED: 'saPrescriptionViewed',
  SA_API_ERROR: 'saApiError',
  DA_ERROR: 'daError',
  UPDATE_CLEVER_TAP_USER: 'updateClevertapUser',
  IMAGE_UPLOAD_VIEWED: 'imageUploadViewed',
  IMAGE_UPLOAD_INITIATED: 'uploadInitiated',
  WHAT_TO_UPLOAD_CLICKED: 'whatToUploadClicked',
  IMAGE_SIZE_ERROR: 'imageSizeError',
  IMAGE_UPLOAD_SUCCESS: 'imageUploadSuccess',
  IMAGE_FLOW_EXIT: 'imageFlowExit',
  CONTACT_US_VIEWED: 'contactUsViewed',
  SUPPORT_INITIATED: 'supportInitiated',
  REORDER_CLICKED: 'reOrderClicked',
  RESCHEDULE_SCREEN_VIEWED: 'rescheduleScreenViewed',
  RESCHEDULE_LANGUAGE_CHANGED: 'rescheduleLanguageChanged',
  RESCHEDULE_CONFIRM_CLICKED: 'rescheduleConfirmClicked',
  CONFIRMATION_SCREEN_V2_VIEWED: 'confirmationScreenV2Viewed',
  CONFIRMATION_SCREEN_V2_DOWNLOAD_APP_CLICKED:
    'confirmationScreenV2DownloadAppClicked',
  CONFIRMATION_SCREEN_V2_EDIT_APPOINTMENT_CLICKED:
    'confirmationScreenV2EditAppointmentClicked',
  CONFIRMATION_SCREEN_V2_RESCHEDULE_CLICKED:
    'confirmationScreenV2RescheduleClicked',
  CONFIRMATION_SCREEN_V2_APPOINTMENT_CANCELLED:
    'confirmationScreenV2AppointmentClicked',
  COMMS_CONSULT_SECTION_VIEWED: 'commsConsultSectionViewed',
  COMMS_CONSULT_STATE_CARD: 'commsConsultStateCard',
  COMMS_CONSULT_CARD_CTA_CLICKED: 'commsConsultCardCTAClicked',
  COMMS_EDIT_APPT_CLICKED: 'commsEditAppointmentClicked',
  COMMS_EDIT_APPT_ACTION_CLICKED: 'commsAppointmentActionClicked',
  COMMS_RESCHEDULE_CONFIRMED: 'commsRescheduleConfirmed',
  COMMS_DA_CLICKED: 'commsDaClicked',
  COMMS_DA_VIEWED: 'commsDaViewed',
  COMMS_DA_CATEGORY_SELECTED: 'commsDaCategory',
  COMMS_DA_QUESTION: 'commsDaQuestion',
  COMMS_DA_LANGUAGE: 'commsDaLanguage',
  COMMS_DA_INSTANT_SELECTED: 'commsDaInstantSelected',
  COMMS_DA_BOTTOM_SHEET_OPEN: 'commsDaBottomSheetOpen',
  COMMS_DA_LANGUAGE_CHANGED: 'commsDaLanguageChanged',
  COMMS_DA_SCHEDULE_SUBMIT: 'commsDaScheduleSubmit',
  COMMS_DA_BOOKED: 'commsDaBooked',
  COMMS_DA_IMAGE_FLOW_VIEWED: 'commsDaImageUploadFlowViewed',
  COMMS_DA_IMAGE_UPLOAD_INITIATED: 'commsDaImageUploadInitiated',
  COMMS_DA_IMAGE_UPLOAD_SUBMIT_CLICKED: 'commsDaImageUploadSubmitClicked',
  COMMS_DA_IMAGE_UPLOAD_SUCCESS: 'commsDaImageUploadSuccess',
  COMMS_FORM_CLICKED: 'commsFormClicked',
  COMMS_CONSULT_TRACKER_CLICKED: 'commsConsultTrackerClicked',
  COMMS_PAST_PRESCRIPTIONS_CLICKED: 'commsPastPrescriptionsClicked',
  COMMS_POP_UP_VIEWED: 'commsPopupViewed',
  COMMS_POP_UP_CTA_CLICKED: 'commsPopupCTAClicked',
  COMMS_POP_UP_CLOSED: 'commsPopUpClosed',
  BASIC_DETAILS_VIEWED: 'basicDetailsViewed',
  BASIC_DETAILS_DETAIL_SUBMITTED: 'detailSubmitted',
  BASIC_DETAILS_QUESTION_ANSWERED: 'basicDetailsQuestionAnswered',
  SWIPE_APPOINTMENT_SUBMIT: 'swipeAppointmentSubmit',
  SWIPE_APPOINTMENT_BOOKED: 'swipeAppointmentBooked',
  SWIPE_APPOINTMENT_BOOKING_ERROR: 'swipeAppointmentBookingError',
  WALLET_RECHARGE_BANNER_CLICKED: 'walletRechargeBannerClicked',
  THUMBNAIL_IMAGE_CLICKED: 'thumbnailImageClicked',
  PRODUCT_EXPERIMENTS: 'productExperiments',
  FLOATING_ISLAND_NUDGE_VIEWED: 'floatingIslandNudgeViewed',
  FLOATING_ISLAND_NUDGE_CLICKED: 'floatingIslandNudgeClicked',
  WALLET_MONEY_CHECKBOX: 'walletMoneyCheckbox',
  PENDING_ORDER_APPOINTMENT: 'pendingOrderAppointment',
  PENDING_ORDER_RESCHEDULE_SEEN: 'pendingOrderRescheduleSeen',
  PENDING_ORDER_RESCHEDULE_SUBMIT: 'pendingOrderRescheduleSubmit',
  PENDING_ORDER_RESCHEDULE_CONFIRMED: 'pendingOrderRescheduleConfirmed',
  VIEW_ALL_CLICKED: 'viewAllClicked',
  WRITE_REVIEW_CLICKED: 'writeReviewClicked',
  REVIEW_SUBMITTED: 'reviewSubmitted',
  ASK_QUESTION_CLICKED: 'askQuestionClicked',
  NUDGE_STORY_CLICKED: 'nudgeStoryClicked',
  NUDGE_STORY_TAP: 'nudgeStoryTap',
  QUESTION_ASKED: 'questionAsked',
  /**
   * Common Event for CTA clicks, add new events here
   * and pass the event name in source key.
   * eg: {source :"PDP Floating Icon"}
   */

  // WALLET RECHARGE REORDER
  PICTURE_IN_PICTURE_CLICKED: 'pictureInPictureClicked',
  WALLET_LANDING_PAGE_TIER_SELECTED: 'walletLandingPageTierSelected',
  WALLET_LANDING_PAGE_VIEWED: 'Wallet_Landing_Page_Viewed',
  WALLET_LANDING_PAGE_RECHARGE_CLICKED: 'Wallet_Landing_Page_Recharge_Clicked',
  // WALLET RECHARGE REORDER END
  CTA_CLICKED: 'ctaClicked',
  CTE_CLICKED: 'cteClicked',
  CTA_VIEWED: 'ctaViewed',
  PDP_SELF_DIAGNOSIS_FLOATING_ISLAND_CLOSED: 'crossClicked',
  CART_UP_SELL_INCENTIVE_BUTTON_CLICKED: 'cartUpSellIncentiveButtonClicked',
  GROWTH_APP_DOWNLOAD_MODAL_SEEN: 'growthAppDownloadModalSeen',
  GROWTH_APP_DOWNLOAD_MODAL_CLOSED: 'growthAppDownloadModalClosed',
  GROWTH_APP_DOWNLOAD_MODAL_SUBMITTED: 'growthAppDownloadModalSubmitted',
  BIRTHDAY_TRACKER_SEEN: 'bdaySeen',
  BIRTHDAY_TRACKER_ADD_CHILD_CLICKED: 'bdayClicked',
  BIRTHDAY_TRACKER_CHILD_SAVED: 'bdaySaved',
  BIRTHDAY_TRACKER_CHILD_CANCEL: 'bdayCancel',
  BIRTHDAY_TRACKER_CHILD_CLOSED: 'bdayClose',
  LOGIN_PAGE_SEEN: 'loginPageSeen',
  LOGIN_PAGE_REDIRECTION_SCREEN_SEEN: 'loginPageRedirectionScreenSeen',
  LOGIN_PAGE_REDIRECTION_BUTTON_CLICKED: 'loginPageRedirectionButtonClicked',
  TABBY_ELIGIBILITY: 'tabbyEligibility',
  LOGIN_PAGE_SKIP_BUTTON_CLICKED: 'loginPageSkipButtonClicked',
  NOTIFY_ME: 'Notify Me Clicked',
  NOTIFY_ME_DONE: 'Notify Me Done',
  THANKYOU_PAGE_CTA_CLICK: 'thankYouPageCtaClick',
  APP_DOWNLOAD_BOTTOM_SHEET_SEEN: 'appDownloadBottomSheetSeen',
  APP_DOWNLOAD_BOTTOM_SHEET_SUBMITTED: 'appDownloadBottomSheetSubmitted',
  THANK_YOU_CHAT_QUESTION: 'thankYouChatQuestion',
  BRANCH_CHARGED: 'branchCharged',
  BRANCH_REPEAT_CHARGED: 'branchRepeatCharged',
  HOME_STEPS_SEEN: 'home steps widget seen',
  HOME_STEPS_CTA_CLICKED: 'home steps widget clicked',
  CHILD_DETAILS_FILLED: 'Child details filled',
  LANGUAGE_PREFERENCE_VIEWED: 'LanguagePreferenceViewed',
  LANGUAGE_PREFERENCE_SELECTED: 'LanguagePreferenceSelected',
  MINI_ASSESSMENT_LANG_SEEN: 'miniAssessmentLangSeen',
  MINI_ASSESSMENT_LANG_SELECTED: 'miniAssessmentLangSelected',
  CHILD_DETAILS_BMI_VIEWED: 'Child Details BMI Viewed',
  WALLET_RECHARGE_VIEWED: 'walletRechargeViewed',
  WALLET_PAY_CLICK: 'walletPayClick',
  SELF_ASSESSMENT_DISCOUNT_CLAIMED: 'selfAssessmentDiscountClaimed',
  SELF_ASSESSMENT_DISCOUNT_PROCEED: 'selfAssessmentDiscountProceed',
  SELF_ASSESSMENT_DISCOUNT_SKIPPED: 'selfAssessmentDiscountSkipped',
  NAC_CART_VIEWED: 'nacCartViewed',
  CTA_CARD_BG_CLICKED: 'ctaCardBGClicked',
  CONVERSATION_STARTED_SO: 'conversationStarted',
  CONVERSATION_UPDATED: 'conversationUpdated',
  CONVERSATION_BILLING: 'conversationBilling',
  CONVERSATION_CALL_UPDATE: 'conversationCallUpdate',
  REPORT_COACH_CARD_CLICKED: 'reportCoachCardClicked',
  REPORT_COACH_CARD_VIEWED: 'reportCoachCardViewed',
  NOTIFY_ME_VIEWED: 'notifyMeViewed',
  DOCTOR_VIEWED: 'doctorViewed',
  DOCTORS_VIEWED: 'doctorsViewed',
  CTA_ACTION_CLICKED: 'ctaActionClicked',
  PAYMENT_SUMARY: 'paymentSummary',
  CONVERSATION_BILLING_VIEWED: 'conversationBillingViewed',
  CONVERSATION_STARTED: 'conversationStarted',
  INSTANT_RX_BOTTOM_SHEET_VIEWED: 'instantRxBottomSheetViewed',
  INSTANT_RX_LANGUAGE_SELECTED: 'instantRxLanguageSelected',
  INSTANT_RX_IMAGE_UPLOAD_CLICKED: 'instantRxImageUploadClicked',
  PDP_SNAP_MINT_BANNER_CLICKED: 'pdpSnapMintBannerClicked',
  WALLET_LOADED_FOR_SO: 'walletLoadedForSO',
  WALLET_TRANSACTION_PAGE_VIEWED: 'walletTransactionPageViewed',
  LOGIN_BOTTOM_SHEET_VIEWED: 'loginBottomSheetViewed',
  UPSELL_MILESTONE_UNLOCKED: 'upsellMilestoneUnlocked',
  UPSELL_VIEW_ALL_CLICKED: 'upsellViewAllClicked',
  /** Rook Events STARTS*/
  DEVICE_CONNECTION_PAGE_VIEWED: 'deviceConnectionPageViewed',
  CONNECTION_INSTRUCTION_VIEWED: 'connectionInstructionViewed',
  DEVICE_CONNECTION_CLICKED: 'DeviceConnectionClicked',
  DEVICE_RECONNECTION_INITIATED: 'deviceReconnectionInitiated',
  /** Rook Events ENDS */
  NOTIFY_OPTIONS_VIEWED: 'notifyOptionViewed',
  NOTIFY_OPTION_SELECTED: 'notifyOptionSelected',
  NOTIFY_SLOT_VIEWED: 'notifySlotViewed',
  NOTIFY_SLOT_SELECTED: 'notifySlotSelected',
  NOTIFY_SLOT_SUBMITTED: 'notifySlotSubmitted',
  CONSULT_REPORT_VIEWED: 'consultReportViewed',
  CONSULT_REPORT_SECTION_CLICKED: 'consultReportSectionClicked',
  TWID_PAYMENT_ELIGIBILITY: 'twidPaymentEligibility',
  TWID_METHOD_SELECTED: 'twidMethodSelected',
  TWID_PAYMENT_FAILURE: 'twidPaymentFailure',
  CONSULTATION_FEEDBACK_RATING: 'consultationFeedbackRating',
  CONSULTATION_FEEDBACK_SUBMITTED: 'consultationFeedbackSubmitted',
  WALLET_CALLOUT_SEEN: 'walletCalloutSeen',
  WALLET_CALLOUT_CLICKED: 'walletCalloutClicked',
  AUTO_PAY_VIEWED: 'autoPayViewed',
  AUTO_PAY_INSTRUMENT_SELECTED: 'autoPayInstrumentSelected',
  AUTO_PAY_INITIATED: 'autoPayInitiated',
  CONSULT_PURCHASE_CLICKED: 'consultPurchaseClicked',
  /** Onboarding Events STARTS*/
  GROWTH_ON_BOARDING_VIEWED: 'Growth Onboarding Viewed',
  ON_BOARDING_SKIP: 'ON_BOARDING_SKIP',
  /** Onboarding Events ENDS*/
  CONVERSATION_CATEGORY_VIEWED: 'conversationCategoryViewed',
  CONVERSATION_CATEGORY_SELECTED: 'conversationCategorySelected',
  CONVERSATION_LANGUAGE_VIEWED: 'conversationLanguageViewed',
  CONVERSATION_LANGUAGE_SELECTED: 'conversationLanguageSelected',
  CONVERSATION_LANGUAGE_SUBMITTED: 'conversationLanguageSubmitted',
  DEVICE_RE_CONNECTION_PAGE_VIEWED: 'deviceReConnectionPageViewed',
  DEVICE_RE_CONNECTION_CLICKED: 'deviceReConnectionClicked',
  DEVICE_RE_CONNECTION_HELP_CLICKED: 'deviceReConnectionHelpClicked',
  DEVICE_RE_CONNECTION_HELP_PAGE_VIEWED: 'deviceReConnectionHelpPageViewed',
  BYOK_TOGGLE_CLICKED: 'byokToggleClicked',
  BYOK_MILESTONE_UNLOCKED: 'byokMilestoneUnlocked',
  CATEGORY_CLICKED: 'categoryClicked',
  CRED_VALIDATION_CLICKED: 'credValidationClicked',
  VERIFY_VPA_CLICKED: 'verifyVpaClicked',
  VERIFY_VPA_RESOLVED: 'verifyVpaResolved',
  GO_TO_CART_CLICKED: 'goToCartClicked',
  PRODUCT_INFO_CLICKED: 'productInfoClicked',
}

export const CLEVER_TAP_EVENTS = {
  // WALLET RECHARGE REORDER
  [EVENT_MAP.PICTURE_IN_PICTURE_CLICKED]: 'picture-In-Picture Clicked',
  [EVENT_MAP.WALLET_LANDING_PAGE_TIER_SELECTED]:
    'Wallet Landing Page Tier Selected',
  [EVENT_MAP.WALLET_LANDING_PAGE_VIEWED]: 'Wallet Landing Page Viewed',
  [EVENT_MAP.WALLET_LANDING_PAGE_RECHARGE_CLICKED]:
    'Wallet Landing Page Recharge Clicked',

  // WALLET RECHARGE REORDER END
  /** Rook Events STARTS*/
  [EVENT_MAP.DEVICE_CONNECTION_PAGE_VIEWED]: 'Device Connection Page Viewed',
  [EVENT_MAP.CONNECTION_INSTRUCTION_VIEWED]: 'Connection Instructions Viewed',
  [EVENT_MAP.DEVICE_CONNECTION_CLICKED]: 'Device connection clicked',
  [EVENT_MAP.DEVICE_RECONNECTION_INITIATED]: 'Device Reconnection Initiated',
  /** Rook Events ENDS */
  [EVENT_MAP.ADD_ADDRESS_CLICKED]: 'Add Address Clicked',
  [EVENT_MAP.CTA_ACTION_CLICKED]: 'CTA Action Clicked-SO',
  [EVENT_MAP.PAYMENT_SUMARY]: 'Payment Summary-SO',
  [EVENT_MAP.CONVERSATION_BILLING_VIEWED]: 'Conversation Billing Viewed-SO',
  [EVENT_MAP.CONVERSATION_STARTED]: 'Conversation Started-SO',
  [EVENT_MAP.ADDRESS_ID_SWITCHED]: 'Address Selected',
  [EVENT_MAP.ADDRESS_EDIT]: 'Address Edit',
  [EVENT_MAP.ADD_TO_CART_CLICK_PD]: 'ATC Clicked',
  [EVENT_MAP.ADD_TO_CART_CLICK]: 'ATC Clicked',
  [EVENT_MAP.ADD_TO_CART_CLICKED_RX_CART]: 'ATC on RX Cart',
  [EVENT_MAP.ADDRESS_VIEWED]: 'Address Viewed',
  [EVENT_MAP.ADD_NEW_PAYMENT_METHOD]: 'Add New Payment Method',
  [EVENT_MAP.ASSESSMENT_CARD_CLICKED]: 'Wellness Card Clicked',
  [EVENT_MAP.APPLY_DISCOUNT_CLICKED]: 'Apply Discount Clicked',
  [EVENT_MAP.APPOINTMENT_BOOKED]: 'APPOINTMENT_BOOKED',
  [EVENT_MAP.APPOINTMENT_SELECTED]: 'APPOINTMENT_SELECTED',
  [EVENT_MAP.APP_PRESENCE_CHECK]: 'App Presence Check',
  [EVENT_MAP.BOOK_PAYLOAD_ON_PAY_BUTTON]: 'Book Payload on pay button',
  [EVENT_MAP.BUY_NOW_CLICK_PD]: 'Buy Now Clicked',
  [EVENT_MAP.BUY_NOW_CLICK_CHECKOUT]: 'BO_Checkout',
  [EVENT_MAP.BUY_NOW_CLICK_ALL_REVIEWS]: 'Buy Now Clicked',
  [EVENT_MAP.CALLOUT_CLICK]: 'Top bar Clicked',
  [EVENT_MAP.COUPON_COPIED]: 'Coupon Code Copied',
  [EVENT_MAP.COUPON_CLICKED]: 'Coupon Code Clicked',
  [EVENT_MAP.COUPON_VIEWED]: 'Coupon Code Viewed',
  [EVENT_MAP.CANCELLATION_REASON_VIEWED]: 'Cancellation Reasons - Viewed',
  [EVENT_MAP.CANCEL_ORDER_CLICKED]: 'Cancel Order Clicked',
  [EVENT_MAP.CANCEL_ORDER_VIEWED_WALLET_POINTS]:
    'Wallet points - Cancel viewed',
  [EVENT_MAP.CANCEL_ORDER_VIEWED_SHIPPED]: 'Shipped - Cancel viewed',
  [EVENT_MAP.CANCEL_ORDER_CLICKED_WALLET_POINTS]:
    'Wallet points - Cancel clicked',
  [EVENT_MAP.CANCEL_ORDER_CONFIRMED]: 'Cancellation Confirm', // TODO: confirm from Tushar
  [EVENT_MAP.CART_VIEWED]: 'Cart Viewed',
  [EVENT_MAP.CART_UP_SELL_SELECTION]: 'Upsell Added',
  [EVENT_MAP.CART_UP_SELL_SHOW]: 'Upsell Clicked',
  [EVENT_MAP.CHANGE_ADDRESS_CLICK]: 'Change Address Clicked',
  [EVENT_MAP.CHECKOUT_CLICKED]: 'Checkout Clicked',
  [EVENT_MAP.CHECKOUT_CLICKED_ON_RX_CART]: 'Rx Checkout',
  [EVENT_MAP.CHECKOUT_VIEWED]: 'Cart Viewed',
  [EVENT_MAP.CHECKOUT_STEP]: '', // * Likely Deprecated
  [EVENT_MAP.CLICKED_FAILED_MODAL]: 'Clicked Failed Modal',
  [EVENT_MAP.COD_ELIGIBILITY]: 'RTO Score', // TODO: Confirm from tushar
  [EVENT_MAP.CONCERN_CLICK]: 'Concern Clicked',
  [EVENT_MAP.COUPON_APPLY_CLICK]: '', // TODO: Confirm from Riya
  [EVENT_MAP.COUPON_CODE]: 'Coupon Code',
  [EVENT_MAP.CRED_ELIGIBILITY]: '', // TODO: Confirm from tushar
  [EVENT_MAP.CRED_ERROR]: '', // TODO: Confirm from tushar
  [EVENT_MAP.DELIVERY_TOAST_SEEN]: 'deliveryToastSeen',
  [EVENT_MAP.DELIVERY_TOAST_SLIDER_SEEN]: 'Delivery Toast Slider Seen',
  [EVENT_MAP.DETECT_USER_CITY]: 'Address Filled',
  [EVENT_MAP.ADDRESS_DELETE]: 'Address Delete',
  [EVENT_MAP.ADDRESS_ERROR]: 'Address Error',
  [EVENT_MAP.FILTER_APPLIED_GET_REGIME]: 'Filter Applied',
  [EVENT_MAP.FILTER_CLICKED_GET_REGIME]: 'Filter Clicked',
  [EVENT_MAP.GOKWIK_ADDRESS_ELIGIBILITY]: 'Gokwik Address Eligibility',
  [EVENT_MAP.GOOGLE_UPI_FAILED]: 'Google UPI Failed',
  [EVENT_MAP.HOME_VIEW]: '',
  [EVENT_MAP.LOGIN]: '',
  [EVENT_MAP.LOGOUT_CLICK]: '',
  [EVENT_MAP.LOGOUT_SUCCESS]: 'logout success',
  [EVENT_MAP.MINI_ASSESSMENT_QUESTION_VIEWED]: 'MA Question viewed',
  [EVENT_MAP.MINI_ASSESSMENT_QUESTION_ANSWERED]: 'MA Question answered',
  [EVENT_MAP.MINI_ASSESSMENT_RECOMMENDATIONS_VIEWED]: 'MA recommendation seen',
  [EVENT_MAP.MINI_ASSESSMENT_OPTIONS_SELECTED]:
    'Mini Assessment Option Selected',
  [EVENT_MAP.MINI_ASSESSMENT_COMPLETE]: 'Mini Assessment Complete',
  [EVENT_MAP.MINI_ASSESSMENT_VIEWED]: 'Mini Assessment Viewed',
  [EVENT_MAP.MY_ORDERS_VIEWED]: 'My Orders Viewed',
  [EVENT_MAP.MY_PROFILE_VIEWED]: 'My Profile Viewed',
  [EVENT_MAP.NAVBAR_HAMBURGER_CLICK]: 'Hamburger Clicked',
  [EVENT_MAP.NAVBAR_MENU_CLICKED]: 'Nav Link Clicked',
  [EVENT_MAP.NAVBAR_SEARCH_CLICKED]: 'Search Clicked',
  [EVENT_MAP.NEW_THANKYOU_PAGE]: 'Thankyou Page Viewed',
  [EVENT_MAP.ORDER_DETAILS_CLICKED]: 'Order Details Clicked',
  [EVENT_MAP.OPTIONAL_RX_AVAILABLE]: 'Optional RX available',
  [EVENT_MAP.OPTIONAL_RX_OPT_IN]: 'Optional RX opt-in',
  [EVENT_MAP.OPTIONAL_RX_OPT_OUT]: 'Optional RX opt out',
  [EVENT_MAP.OTP]: 'OTP',
  [EVENT_MAP.PASSWORD]: 'Password',
  [EVENT_MAP.SOCIAL_LOGIN]: 'Social Login',
  [EVENT_MAP.FORGOT_PASSWORD]: 'Forget Password',
  [EVENT_MAP.RESET_PASSWORD]: 'Reset Password',
  [EVENT_MAP.OTP_VERIFIED_LOGIN]: 'Login',
  [EVENT_MAP.EMAIL_VERIFIED_LOGIN]: 'Login',
  [EVENT_MAP.OTP_VERIFIED_SIGN_UP]: 'Signup',
  [EVENT_MAP.PAGE_VIEWED]: 'pageViewed',
  [EVENT_MAP.PAY_CLICKED]: 'Pay Clicked',
  [EVENT_MAP.PAYMENT_FAILED]: 'Payment Failed',
  [EVENT_MAP.PAYMENT_SELECTED]: 'Payment Selected',
  [EVENT_MAP.PAYMENT_VIEWED]: 'Payment Viewed',
  [EVENT_MAP.PAYMENT_BANNER_CLICKED]: 'Payment Banner Clicked',
  [EVENT_MAP.PAYMENT_ELIGIBILITY]: 'Payment Eligibility',
  [EVENT_MAP.PD_CAROUSEL_SLIDE]: 'View All Images Seen',
  [EVENT_MAP.PD_DELIVERY_CHECK]: 'Delivery Date Checked',
  [EVENT_MAP.PD_RATING_CLICK]: 'Rating Clicked',
  [EVENT_MAP.PD_SECTION_SCROLLED_INTO_VIEW]: 'Section viewed',
  [EVENT_MAP.PD_SWITCH_CLICK]: 'Alternate Product Clicked',
  [EVENT_MAP.PD_SECTION_VIEWED]: 'Section viewed',
  [EVENT_MAP.PDP_VIEW]: 'PDP Viewed',
  [EVENT_MAP.POST_ORDER_FEEDBACK]: 'Thank you page Feedback',
  [EVENT_MAP.PRESCRIPTION_DOWNLOAD]: 'Prescription Download',
  [EVENT_MAP.PRODUCT_REMOVED_FROM_RX_CART]: 'Product Removed',
  [EVENT_MAP.REWARD_UNLOCKED]: 'Reward Unlocked',
  [EVENT_MAP.RR_CONSULT_NOW]: 'RR Consult Now',
  [EVENT_MAP.RR_SELECT_ALL]: 'RR Select All',
  [EVENT_MAP.RR_VIEWED]: 'RR viewed',
  [EVENT_MAP.RR_ATC]: 'RR ATC',
  [EVENT_MAP.RR_BN]: 'RR BN',
  [EVENT_MAP.RR_UP_SHELL]: 'RR Upsell',
  [EVENT_MAP.SCHEDULE_CONFIRMED]: 'Schedule Confirmed',
  [EVENT_MAP.SCHEDULE_SELECTED]:
    'Schedule Selected (Not free right now clicked)',
  [EVENT_MAP.SEARCH_USED]: '',
  [EVENT_MAP.SEARCH_PDP]: 'Search PDP',
  [EVENT_MAP.SEARCH_MODAL_CLOSED]: '',
  [EVENT_MAP.SHOW_BOOK_APPOINTMENT]: '',
  [EVENT_MAP.SHOW_MINI_ASSESSMENT]: '',
  [EVENT_MAP.SIGN_UP]: '',
  [EVENT_MAP.SLOT_CHANGED]: 'Slot Changed',
  [EVENT_MAP.SWIPE_TO_BOOK]: 'Swiped Consultation',
  [EVENT_MAP.SWIPE_TO_BOOK_APPOINTMENT_TYPE_CHANGED]: '', // TODO: check with tej
  [EVENT_MAP.THANKYOU_PAGE_VIEW]: '', // * Likely Deprecated
  [EVENT_MAP.TRENDING_SEARCHES_CLICKED]: '', // TODO: check with navin
  [EVENT_MAP.TRUECALLER_SEEN]: 'Truecaller Seen',
  [EVENT_MAP.TRUECALLER_SKIPPED]: 'Truecaller Skipped',
  [EVENT_MAP.TRUECALLER_LOGIN]: 'Truecaller Login',
  [EVENT_MAP.USER_FETCHED]: 'useFetched',
  [EVENT_MAP.UPI_FAILED]: 'UPI failed',
  [EVENT_MAP.VIDEO_SHOWCASE_CLICKED]: 'CTA Clicked',
  [EVENT_MAP.VIEW_APPOINTMENT_CLICKED]: '', // TODO: check with navin
  [EVENT_MAP.VIEW_PRESCRIPTION_CLICKED]: '', // TODO: check with navin
  [EVENT_MAP.VIEW_THANKYOU_PAGE]: '', // * Likely deprecated
  [EVENT_MAP.VIEW_DA]: 'DA Viewed',
  [EVENT_MAP.VIEW_QUESTIONS]: 'DA Questions',
  [EVENT_MAP.CATEGORY_SUBMIT]: 'DA Categories',
  [EVENT_MAP.VIEW_SCHEDULING_SCREEN]: 'Scheduling Screen Viewed',
  [EVENT_MAP.VIEW_PRESCRIPTION_CLICKED_RX_CART]: 'Prescription Viewed',
  [EVENT_MAP.VIEW_RX_CART]: 'Rx Cart Viewed',
  [EVENT_MAP.VIEW_PRODUCT_INFO_RX_CART]: 'Rx Cart Product Info',
  [EVENT_MAP.VIEW_PDP_FROM_RX_CART]: 'Rx Cart to PDP',
  [EVENT_MAP.NOT_FREE_RIGHT_NOW_TOGGLED_ON]: 'Schedule state viewed',
  [EVENT_MAP.NOTE_FREE_RIGHT_NOW_TOGGLED_OFF]: 'IA state viewed',
  [EVENT_MAP.LANGUAGE_CHANGED_IN_DROPDOWN]: 'Language Changed',
  [EVENT_MAP.SCHEDULE_SCREEN_CONFIRMED_CLICKED]: 'DA Submit',
  [EVENT_MAP.DA_APPOINTMENT_BOOKED]: 'DA Booked',
  [EVENT_MAP.VIEW_GET_CUSTOMER_INFO_SCREEN]: 'DA Details Viewed',
  [EVENT_MAP.GET_CUSTOMER_INFO_SUBMIT]: 'DA Details Submitted',
  [EVENT_MAP.VIEW_IMAGE_UPLOAD]: 'Image Upload Viewed',
  [EVENT_MAP.IMAGE_UPLOAD_SKIPPED]: 'Image Upload Skipped',
  [EVENT_MAP.IMAGE_UPLOADED]: 'Image Submitted',
  [EVENT_MAP.VIEW_APPOINTMENT_SUMMARY_TOP_SHEET]: 'top sheet summary',
  [EVENT_MAP.VIEW_APPOINTMENT_SUMMARY_SCREEN]: 'Summary Viewed',
  [EVENT_MAP.S_TO_IA_SWITCH]: 'Summary switch',
  [EVENT_MAP.DA_DOWNLOAD_APP_CLICKED]: 'DA Download app clicked',
  [EVENT_MAP.DA_EDIT_APPOINTMENT_CLICKED]: 'DA Edit Appointment Clicked',
  [EVENT_MAP.DA_RESCHEDULE_CLICKED]: 'DA Reschedule Clicked',
  [EVENT_MAP.DA_APPOINTMENT_CANCELLED]: 'DA Appointment Cancelled',
  [EVENT_MAP.APPOINTMENT_CONFIRM_VIEWED]: 'WA Appointment Confirmed',
  [EVENT_MAP.APPOINTMENT_CONFIRM_IMAGE_UPLOAD]: 'Image Upload Clicked',
  [EVENT_MAP.APPOINTMENT_CONFIRM_CONTINUE_SHOPPING]:
    'Continue Shopping Clicked',
  [EVENT_MAP.RX_POPUP_CLICKED]: 'RX pop up clicked',
  [EVENT_MAP.RX_POPUP_SEEN]: 'RX pop up seen',
  [EVENT_MAP.VARIANT_CLICKED]: 'Variant Clicked',
  [EVENT_MAP.PDP_CLICKED]: 'PDP Clicked',
  [EVENT_MAP.TREATMENT_PAGE_VIEWED]: 'Treatment Page Viewed',
  [EVENT_MAP.CUSTOMISED_PLAN_CLICKED]: 'Find Customised Plan Clicked',
  [EVENT_MAP.START_TREATMENT_CLICKED]: 'Start Treatment Clicked',
  [EVENT_MAP.FIND_SOLUTION_CLICKED]: 'Treatment Form Submitted',
  [EVENT_MAP.TREATMENT_QUESTIONNAIRE_NEXT_CLICKED]: 'Treatment Form Viewed',
  [EVENT_MAP.DA_EXIT_CLICKED]: 'DA Exit Clicked',
  [EVENT_MAP.DA_EXIT_CONFIRMED]: 'DA Exit Confirmed',
  [EVENT_MAP.DA_EXIT_CANCELLED]: 'DA Exit Cancelled',
  [EVENT_MAP.DA_BACK_CLICKED]: 'DA Back Clicked',
  [EVENT_MAP.FUTURE_APPOINTMENT]: 'Future Appointment',
  [EVENT_MAP.APPOINTMENT_CARD_SHOWN]: 'Appointment Card Shown',
  [EVENT_MAP.APPOINTMENT_CARD_CLICK]: 'Appointment Card Clicked',
  [EVENT_MAP.SA_VIEWED]: 'Form Viewed',
  [EVENT_MAP.SA_STARTED]: 'Form Started',
  [EVENT_MAP.SA_CHAT_OPEN]: 'Form Open',
  [EVENT_MAP.SA_NAME_SUBMITTED]: 'Form Name Submitted',
  [EVENT_MAP.SA_CATEGORY_VIEWED]: 'Form Category Viewed',
  [EVENT_MAP.SA_CATEGORY_SUBMITTED]: 'Form category submitted',
  [EVENT_MAP.SA_QUESTION]: 'Form Question',
  [EVENT_MAP.SA_QUESTION_UNDO]: 'Form Question Undo',
  [EVENT_MAP.SA_BASIC_DETAILS]: 'Form Basic Details',
  [EVENT_MAP.SA_SUBMITTED]: 'Form Submitted',
  [EVENT_MAP.SA_REPORT_SEEN]: 'Form Report seen',
  [EVENT_MAP.SA_APPOINTMENT_VIEWED]: 'Form Appointment Viewed',
  [EVENT_MAP.SA_APPOINTMENT_SUBMITTED]: 'Form Appointment Submit',
  [EVENT_MAP.SA_APPOINTMENT_BOOKED]: 'Form Appointment Booked',
  [EVENT_MAP.SA_PLAN_CLICKED]: 'Form Plan clicked',
  [EVENT_MAP.SA_PLAN_VIEWED]: 'Form Plan Viewed',
  [EVENT_MAP.SA_PLAN_PRODUCT_REMOVE]: 'Form Plan Product Removed',
  [EVENT_MAP.SA_PLAN_PRODUCT_ADD]: 'Form Plan ATC',
  [EVENT_MAP.SA_PLAN_PRODUCT_INFO_CARD_CLICKED]:
    'Form Product Info Card Clicked',
  [EVENT_MAP.SA_PLAN_APPOINTMENT_CARD_SHOWN]:
    'Form Plan Appointment Card Shown',
  [EVENT_MAP.SA_PLAN_TO_PDP]: 'Form Plan to PDP',
  [EVENT_MAP.SA_PLAN_ORDER_CLICKED]: 'Form Plan Place Order Clicked',
  [EVENT_MAP.SA_PRESCRIPTION_VIEWED]: 'Form Prescription viewed',
  [EVENT_MAP.SA_API_ERROR]: 'Form Error',
  [EVENT_MAP.DA_ERROR]: 'DA Error',
  [EVENT_MAP.UPDATE_CLEVER_TAP_USER]: 'Update Clevertap User',
  [EVENT_MAP.IMAGE_UPLOAD_VIEWED]: 'Image Upload Viewed',
  [EVENT_MAP.IMAGE_UPLOAD_INITIATED]: 'Upload Initiated',
  [EVENT_MAP.WHAT_TO_UPLOAD_CLICKED]: 'What to upload clicked',
  [EVENT_MAP.IMAGE_SIZE_ERROR]: 'Image Size Error',
  [EVENT_MAP.IMAGE_UPLOAD_SUCCESS]: 'Image upload success',
  [EVENT_MAP.IMAGE_FLOW_EXIT]: 'Image Flow Exit',
  [EVENT_MAP.CONTACT_US_VIEWED]: 'Contact Us Viewed',
  [EVENT_MAP.SUPPORT_INITIATED]: 'Support Initiated',
  [EVENT_MAP.PRODUCT_FILTER_CLICKED]: 'Product Filter Clicked',
  [EVENT_MAP.REORDER_CLICKED]: 'ReOrder Clicked',
  [EVENT_MAP.RESCHEDULE_SCREEN_VIEWED]: 'Reschedule Viewed',
  [EVENT_MAP.RESCHEDULE_LANGUAGE_CHANGED]: 'Reschedule Language changed',
  [EVENT_MAP.RESCHEDULE_CONFIRM_CLICKED]: 'Reschedule Confirm clicked',
  [EVENT_MAP.CONFIRMATION_SCREEN_V2_VIEWED]: 'Confirmation Screen V2 Viewed',
  [EVENT_MAP.CONFIRMATION_SCREEN_V2_DOWNLOAD_APP_CLICKED]:
    'Confirmation Screen Download app clicked',
  [EVENT_MAP.CONFIRMATION_SCREEN_V2_EDIT_APPOINTMENT_CLICKED]:
    'Confirmation Screen Edit Appointment Clicked',
  [EVENT_MAP.CONFIRMATION_SCREEN_V2_RESCHEDULE_CLICKED]:
    'Confirmation Screen Reschedule Clicked',
  [EVENT_MAP.CONFIRMATION_SCREEN_V2_APPOINTMENT_CANCELLED]:
    'Confirmation Screen Appointment Cancel clicked',
  [EVENT_MAP.COMMS_CONSULT_SECTION_VIEWED]: 'Consult Section Viewed',
  [EVENT_MAP.COMMS_CONSULT_STATE_CARD]: 'Consult State Card',
  [EVENT_MAP.COMMS_CONSULT_CARD_CTA_CLICKED]: 'Consult card CTA clicked',
  [EVENT_MAP.COMMS_EDIT_APPT_CLICKED]: 'Edit Appt Clicked',
  [EVENT_MAP.COMMS_EDIT_APPT_ACTION_CLICKED]: 'Edit Appt action clicked',
  [EVENT_MAP.COMMS_RESCHEDULE_CONFIRMED]: 'Reschedule Confirmed',
  [EVENT_MAP.COMMS_DA_CLICKED]: 'DA Clicked',
  [EVENT_MAP.COMMS_DA_VIEWED]: 'DA Viewed',
  [EVENT_MAP.COMMS_DA_CATEGORY_SELECTED]: 'DA Category Selected',
  [EVENT_MAP.COMMS_DA_QUESTION]: 'DA Question',
  [EVENT_MAP.COMMS_DA_LANGUAGE]: 'DA Language',
  [EVENT_MAP.COMMS_DA_INSTANT_SELECTED]: 'DA Instant Selected',
  [EVENT_MAP.COMMS_DA_BOTTOM_SHEET_OPEN]: 'DA Bottom Sheet open',
  [EVENT_MAP.COMMS_DA_LANGUAGE_CHANGED]: 'DA Language Changed',
  [EVENT_MAP.COMMS_DA_SCHEDULE_SUBMIT]: 'DA Schedule Submit',
  [EVENT_MAP.COMMS_DA_IMAGE_FLOW_VIEWED]: 'DA Image Flow Viewed',
  [EVENT_MAP.COMMS_DA_IMAGE_UPLOAD_INITIATED]: 'DA Image Upload Initiated',
  [EVENT_MAP.COMMS_DA_IMAGE_UPLOAD_SUBMIT_CLICKED]:
    'DA Image Upload Submit Clicked',
  [EVENT_MAP.COMMS_DA_IMAGE_UPLOAD_SUCCESS]: 'DA Image Upload Submit Success',
  [EVENT_MAP.COMMS_FORM_CLICKED]: 'Form Clicked',
  [EVENT_MAP.COMMS_CONSULT_TRACKER_CLICKED]: 'Consult Tracker Clicked',
  [EVENT_MAP.COMMS_PAST_PRESCRIPTIONS_CLICKED]: 'Past Prescriptions clicked',
  [EVENT_MAP.COMMS_POP_UP_VIEWED]: 'Pop up viewed',
  [EVENT_MAP.COMMS_POP_UP_CTA_CLICKED]: 'Pop up CTA clicked',
  [EVENT_MAP.COMMS_POP_UP_CLOSED]: 'Pop up Closed',
  [EVENT_MAP.BASIC_DETAILS_VIEWED]: 'Basic Details Section Viewed',
  [EVENT_MAP.BASIC_DETAILS_DETAIL_SUBMITTED]: 'Detail Submitted',
  [EVENT_MAP.BASIC_DETAILS_QUESTION_ANSWERED]: 'Basic Detail Question Answered',
  [EVENT_MAP.SWIPE_APPOINTMENT_SUBMIT]: 'Swipe Appt Submit',
  [EVENT_MAP.SWIPE_APPOINTMENT_BOOKED]: 'Swipe Appt booked',
  [EVENT_MAP.SWIPE_APPOINTMENT_BOOKING_ERROR]: 'Swipe Appt booking Error',
  [EVENT_MAP.WALLET_RECHARGE_BANNER_CLICKED]: 'Wallet Recharge Banner Clicked',
  [EVENT_MAP.THUMBNAIL_IMAGE_CLICKED]: 'Thumbnail image clicked',
  [EVENT_MAP.PRODUCT_EXPERIMENTS]: 'Experiments',
  [EVENT_MAP.FLOATING_ISLAND_NUDGE_VIEWED]: 'Notification Toast Viewed',
  [EVENT_MAP.FLOATING_ISLAND_NUDGE_CLICKED]: 'Notification Toast Clicked',
  [EVENT_MAP.WALLET_MONEY_CHECKBOX]: 'Wallet Money Checkbox',
  [EVENT_MAP.PENDING_ORDER_APPOINTMENT]: 'Pending O appointment',
  [EVENT_MAP.PENDING_ORDER_RESCHEDULE_SEEN]: 'Pending O reschedule seen',
  [EVENT_MAP.PENDING_ORDER_RESCHEDULE_SUBMIT]: 'Pending O reschedule Submit',
  [EVENT_MAP.PENDING_ORDER_RESCHEDULE_CONFIRMED]:
    'Pending O reschedule Confirmed',
  [EVENT_MAP.CART_UP_SELL_INCENTIVE_BUTTON_CLICKED]: 'Milestone Apply/Copy',
  [EVENT_MAP.CTA_CLICKED]: 'CTA Clicked',
  [EVENT_MAP.CTE_CLICKED]: 'CTE Clicked',
  [EVENT_MAP.CTA_VIEWED]: 'CTA Viewed',
  [EVENT_MAP.CART_UP_SELL_INCENTIVE_BUTTON_CLICKED]: 'Milestone Apply/Copy',
  [EVENT_MAP.PDP_SELF_DIAGNOSIS_FLOATING_ISLAND_CLOSED]: 'Cross Clicked',
  [EVENT_MAP.GROWTH_APP_DOWNLOAD_MODAL_SEEN]: 'Growth App Download Modal Seen',
  [EVENT_MAP.GROWTH_APP_DOWNLOAD_MODAL_CLOSED]:
    'Growth App Download Modal Closed',
  [EVENT_MAP.GROWTH_APP_DOWNLOAD_MODAL_SUBMITTED]:
    'Growth App Download Modal Submitted',
  [EVENT_MAP.VIEW_ALL_CLICKED]: 'View All Clicked',
  [EVENT_MAP.WRITE_REVIEW_CLICKED]: 'Write a Review Clicked',
  [EVENT_MAP.REVIEW_SUBMITTED]: 'Review Submitted',
  [EVENT_MAP.ASK_QUESTION_CLICKED]: 'Ask A Question Clicked',
  [EVENT_MAP.QUESTION_ASKED]: 'Question Asked',
  [EVENT_MAP.BIRTHDAY_TRACKER_SEEN]: 'Bday Seen',
  [EVENT_MAP.BIRTHDAY_TRACKER_ADD_CHILD_CLICKED]: 'Bday Clicked',
  [EVENT_MAP.BIRTHDAY_TRACKER_CHILD_SAVED]: 'Bday Saved',
  [EVENT_MAP.BIRTHDAY_TRACKER_CHILD_CANCEL]: 'Bday Cancel',
  [EVENT_MAP.BIRTHDAY_TRACKER_CHILD_CLOSED]: 'Bday Close',
  [EVENT_MAP.LOGIN_PAGE_SEEN]: 'Login Page Seen',
  [EVENT_MAP.LOGIN_PAGE_REDIRECTION_SCREEN_SEEN]:
    'Login Page Redirection Screen Seen',
  [EVENT_MAP.LOGIN_PAGE_REDIRECTION_BUTTON_CLICKED]:
    'Login Page Redirection Button Clicked',
  [EVENT_MAP.TABBY_ELIGIBILITY]: 'Tabby Eligibility',
  [EVENT_MAP.LOGIN_PAGE_SKIP_BUTTON_CLICKED]: 'Login Page Skip Button Clicked',
  [EVENT_MAP.NOTIFY_ME]: 'Notify Me Clicked',
  [EVENT_MAP.NOTIFY_ME_DONE]: 'Notify Me Done',
  [EVENT_MAP.THANKYOU_PAGE_CTA_CLICK]: 'Thankyou Page CTA Click',
  [EVENT_MAP.APP_DOWNLOAD_BOTTOM_SHEET_SEEN]: 'App Download Bottom Sheet Seen',
  [EVENT_MAP.APP_DOWNLOAD_BOTTOM_SHEET_SUBMITTED]:
    'App Download Bottom Sheet Submitted',
  [EVENT_MAP.THANK_YOU_CHAT_QUESTION]: 'Chat Question',
  [EVENT_MAP.BRANCH_CHARGED]: 'charged',
  [EVENT_MAP.BRANCH_REPEAT_CHARGED]: 'rep_charged',
  [EVENT_MAP.HOME_STEPS_SEEN]: 'Home steps seen',
  [EVENT_MAP.HOME_STEPS_CTA_CLICKED]: 'Home cta clicked',
  [EVENT_MAP.CHILD_DETAILS_FILLED]: 'Child details filled',
  [EVENT_MAP.LANGUAGE_PREFERENCE_VIEWED]: 'Language Preference Viewed',
  [EVENT_MAP.LANGUAGE_PREFERENCE_SELECTED]: 'Language Preference Selected',
  [EVENT_MAP.MINI_ASSESSMENT_LANG_SEEN]: 'Mini Assessment Language Seen',
  [EVENT_MAP.MINI_ASSESSMENT_LANG_SELECTED]:
    'Mini Assessment Language Selected',
  [EVENT_MAP.CHILD_DETAILS_BMI_VIEWED]: 'Child Details BMI Viewed',
  [EVENT_MAP.WALLET_RECHARGE_VIEWED]: 'Wallet Viewed',
  [EVENT_MAP.WALLET_PAY_CLICK]: 'Wallet Pay Click',
  [EVENT_MAP.SELF_ASSESSMENT_DISCOUNT_CLAIMED]:
    'Self Assessment Discount Claimed',
  [EVENT_MAP.SELF_ASSESSMENT_DISCOUNT_SKIPPED]:
    'Self Assessment Discount Skipped',
  [EVENT_MAP.SELF_ASSESSMENT_DISCOUNT_PROCEED]:
    'Self Assessment Discount Proceed Clicked',
  [EVENT_MAP.NAC_CART_VIEWED]: 'NAC Cart Viewed', //NAC (Non Auto Clear)
  [EVENT_MAP.CTA_CARD_BG_CLICKED]: 'PDP Wallet Banner Click',
  [EVENT_MAP.CONVERSATION_STARTED_SO]: 'Conversation Started-SO',
  [EVENT_MAP.CONVERSATION_UPDATED]: 'Conversation Updated-SO',
  [EVENT_MAP.CONVERSATION_BILLING]: 'Conversation Billing-SO',
  [EVENT_MAP.CONVERSATION_CALL_UPDATE]: 'Conversation Call Update-SO',
  [EVENT_MAP.REPORT_COACH_CARD_CLICKED]: 'Coach Card Clicked-SO',
  [EVENT_MAP.REPORT_COACH_CARD_VIEWED]: 'Coach Card Viewed-SO',
  [EVENT_MAP.NOTIFY_ME_VIEWED]: 'SO Notify Me Viewed-SO',
  [EVENT_MAP.DOCTOR_VIEWED]: 'Doctor Viewed-SO',
  [EVENT_MAP.DOCTORS_VIEWED]: 'Doctors Viewed-SO',
  [EVENT_MAP.INSTANT_RX_BOTTOM_SHEET_VIEWED]: 'Instant Rx bottom sheet viewed',
  [EVENT_MAP.INSTANT_RX_LANGUAGE_SELECTED]: 'Instant Rx Language selected',
  [EVENT_MAP.INSTANT_RX_IMAGE_UPLOAD_CLICKED]:
    'Instant Rx Image upload clicked',
  [EVENT_MAP.PDP_SNAP_MINT_BANNER_CLICKED]:
    'Growth PDP SnapMint Banner Clicked ',
  [EVENT_MAP.WALLET_LOADED_FOR_SO]: 'Wallet Loaded for SO',
  [EVENT_MAP.LOGIN_BOTTOM_SHEET_VIEWED]: 'Bottom Sheet Login Viewed',
  [EVENT_MAP.UPSELL_MILESTONE_UNLOCKED]: 'Milestone Unlocked',
  [EVENT_MAP.UPSELL_VIEW_ALL_CLICKED]: 'Upsell View All Clicked',
  [EVENT_MAP.NOTIFY_OPTIONS_VIEWED]: 'Notify Options Viewed - SO',
  [EVENT_MAP.NOTIFY_OPTION_SELECTED]: 'Notify Option Selected - SO',
  [EVENT_MAP.NOTIFY_SLOT_VIEWED]: 'Notify Slot Viewed - SO',
  [EVENT_MAP.NOTIFY_SLOT_SELECTED]: 'Notify Slot Selected - SO',
  [EVENT_MAP.NUDGE_STORY_CLICKED]: 'Nudge Story Clicked',
  [EVENT_MAP.NUDGE_STORY_TAP]: 'Nudge Story Tap',
  [EVENT_MAP.NOTIFY_SLOT_SUBMITTED]: 'Notify Slot Submitted - SO',
  [EVENT_MAP.CONSULT_REPORT_VIEWED]: 'Consult Report Viewed - SO',
  [EVENT_MAP.CONSULT_REPORT_SECTION_CLICKED]: 'Consult Section Clicked - SO',
  [EVENT_MAP.TWID_PAYMENT_ELIGIBILITY]: 'Twid Payment Eligibility',
  [EVENT_MAP.TWID_METHOD_SELECTED]: 'Pay with rewards selected',
  [EVENT_MAP.TWID_PAYMENT_FAILURE]: 'Twid Reward Failed',
  [EVENT_MAP.CONSULTATION_FEEDBACK_RATING]: 'Consultation Feedback Rating - SO',
  [EVENT_MAP.CONSULTATION_FEEDBACK_SUBMITTED]:
    'Consultation Feedback Submitted - SO',
  [EVENT_MAP.WALLET_CALLOUT_SEEN]: 'Wallet Callout Seen',
  [EVENT_MAP.WALLET_CALLOUT_CLICKED]: 'Wallet Callout Clicked',
  [EVENT_MAP.AUTO_PAY_VIEWED]: 'Auto Pay Viewed',
  [EVENT_MAP.AUTO_PAY_INSTRUMENT_SELECTED]: 'Auto Pay Instrument Selected',
  [EVENT_MAP.AUTO_PAY_INITIATED]: 'Auto Pay Initiated',
  [EVENT_MAP.CONSULT_PURCHASE_CLICKED]: 'Consult Purchase Clicked - SO',
  /** Onboarding Events STARTS*/
  [EVENT_MAP.GROWTH_ON_BOARDING_VIEWED]: 'Growth Onboarding Viewed',
  [EVENT_MAP.ON_BOARDING_SKIP]: 'Onboarding Skip',
  /** Onboarding Events ENDS*/
  [EVENT_MAP.CONVERSATION_CATEGORY_VIEWED]: 'Conversation Category Viewed - SO',
  [EVENT_MAP.CONVERSATION_CATEGORY_SELECTED]:
    'Conversation Category Selected - SO',
  [EVENT_MAP.CONVERSATION_LANGUAGE_VIEWED]: 'Conversation Language Viewed - SO',
  [EVENT_MAP.CONVERSATION_LANGUAGE_SELECTED]:
    'Conversation Language Selected - SO',
  [EVENT_MAP.CONVERSATION_LANGUAGE_SUBMITTED]:
    'Conversation Language Submitted - SO',
  [EVENT_MAP.DEVICE_RE_CONNECTION_PAGE_VIEWED]: 'Reconnection Page Viewed',
  [EVENT_MAP.DEVICE_RE_CONNECTION_CLICKED]: 'Reconnection Clicked',
  [EVENT_MAP.DEVICE_RE_CONNECTION_HELP_PAGE_VIEWED]:
    'Reconnection Help Page Viewed',
  [EVENT_MAP.DEVICE_RE_CONNECTION_HELP_CLICKED]: 'Reconnection Help Clicked',
  [EVENT_MAP.BYOK_TOGGLE_CLICKED]: 'BYOK Toggle Clicked',
  [EVENT_MAP.BYOK_MILESTONE_UNLOCKED]: 'BYOK Milestone Unlocked',
  [EVENT_MAP.CATEGORY_CLICKED]: 'CTA Clicked',
  [EVENT_MAP.WALLET_TRANSACTION_PAGE_VIEWED]: 'Wallet Transactions Page Viewed',
  [EVENT_MAP.CRED_VALIDATION_CLICKED]: 'Cred Pass Claim Clicked',
  [EVENT_MAP.VERIFY_VPA_CLICKED]: 'Verify VPA Clicked',
  [EVENT_MAP.VERIFY_VPA_RESOLVED]: 'Verify VPA Resolved',
  [EVENT_MAP.GO_TO_CART_CLICKED]: 'Go To Cart Clicked',
  [EVENT_MAP.PRODUCT_INFO_CLICKED]: 'Product Info Clicked',
}

export const MIX_PANEL_EVENTS = CLEVER_TAP_EVENTS

export const FB_EVENTS = {}
