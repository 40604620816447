import styled from 'styled-components'

export const ReviewCardContainer = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-4);

  .ratings-stars {
    display: flex;
  }

  .review-title,
  .review-body,
  .author {
    color: var(--dls-primary-text-colors);
  }

  .images-container {
    display: flex;
    padding: var(--dls-size-8) 0;
    gap: var(--dls-size-8);
    margin-bottom: var(--dls-size-4);
    overflow-x: scroll;

    -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .review-image {
    object-fit: cover;
    border-radius: 10px;
    flex-shrink: 0;
  }

  .date-created {
    color: var(--dls-secondary-text-colors);
  }

  .modal-background {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 8;
  }

  .modal-content {
    margin: 0 var(--dls-size-16);
    background-color: var(--dls-white-color);
    border-radius: var(--dls-size-16);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px var(--dls-size-16);
    gap: var(--dls-size-8);
    max-width: 500px;
  }

  .close-button {
    font-size: 24px;
    margin-left: auto;
  }

  .expanded-review-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .rating {
    display: flex;
  }

  .expanded-review-body,
  .expanded-review-date {
    color: var(--dls-secondary-text-colors);
  }

  .publish-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`
