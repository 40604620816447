import React, {useCallback, useState} from 'react'

import ChevronDown from '../../../../common/Arrows/DownArrow'
import OptimizedImage from '../../../../common/OptimizedImage'
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  StyledContent,
  StyledTitle,
} from './styles'

function NaturalIngredients({ingredients}) {
  const [openItems, setOpenItems] = useState({})

  const togglePosition = useCallback((index) => {
    setOpenItems((currentOpenItems) => ({
      ...currentOpenItems,
      [index]: !currentOpenItems[index],
    }))
  }, [])

  return (
    <div>
      {ingredients?.map((ingredient, index) => {
        const {icon, title, description, image} = ingredient
        const isOpen = !!openItems[index]

        return (
          <Accordion data-open={isOpen} key={title}>
            <AccordionTitle
              onClick={() => togglePosition(index)}
              data-open={isOpen}
            >
              <StyledTitle>
                <OptimizedImage
                  source={icon}
                  aspectWidth="28"
                  aspectHeight="28"
                  desktopViewWidth="SMALL"
                  mobileViewWidth="SMALL"
                  altText={title}
                  loading="lazy"
                  customClassName="title-icon"
                />
                {title}
              </StyledTitle>
              <span className="icon">
                <ChevronDown />
              </span>
            </AccordionTitle>
            <AccordionContent data-open={isOpen}>
              <StyledContent>
                <div
                  className="left"
                  dangerouslySetInnerHTML={{__html: description}}
                />
                <div className="right">
                  <OptimizedImage
                    source={image}
                    aspectWidth="28"
                    aspectHeight="28"
                    desktopViewWidth="THIRD"
                    mobileViewWidth="THIRD"
                    altText={title}
                    loading="lazy"
                    customClassName="title-icon"
                  />
                </div>
              </StyledContent>
            </AccordionContent>
          </Accordion>
        )
      })}
    </div>
  )
}

export default NaturalIngredients
