import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 12px;
  padding: 0;
  margin-bottom: 0;
  gap: 8px;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .item {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .count_image {
    color: white;
    font-size: 24px;
    font-weight: var(--brand-font-weight-black);
    opacity: 1;
  }

  @media (min-width: 768px) {
    gap: 12px;
    .item {
      width: 100px;
      height: 100px;
      border-radius: 16px;
    }
  }
`
