import React, {useCallback, useState} from 'react'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'

import {GenericSlider} from '@web-components'
import {VideoImageProductCardSliderContainer} from './VideoImageProductCardSlider.styles'
import VideoImageProductCard from './VideoImageProductCard/VideoImageProductCard'
import {VideoImageProductCardSliderProps} from './VideoImageProductCardSlider.interface'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'
import {useGenericActions} from '../../hooks'

function VideoImageProductCardSlider({
  widgetData,
  id,
  header,
  layout,
  type,
}: BaseWidgetProps<VideoImageProductCardSliderProps>) {
  const {
    products = [],
    videoMutedIcon = '',
    videoUnMutedIcon = '',
    aspectRatio = 1,
    slidesToShow = 1,
  } = widgetData

  // State to track the currently active video
  const [activeVideoIndex, setActiveVideoIndex] = useState<number | null>(null)

  const {genericAction} = useGenericActions()

  const handleAction = useCallback(
    (action: GenericAction) => {
      genericAction(interceptAndEnhanceAction(action, id))
    },
    [genericAction, id]
  )

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <VideoImageProductCardSliderContainer>
        <GenericSlider noOfSlides={slidesToShow} aspectRatio={aspectRatio}>
          {({imageDimensions}: {imageDimensions: number[]}) =>
            products.map((product, index) => {
              return (
                <VideoImageProductCard
                  product={product}
                  imageDimensions={imageDimensions}
                  aspectRatio={aspectRatio}
                  videoMutedIcon={videoMutedIcon}
                  videoUnMutedIcon={videoUnMutedIcon}
                  handleAction={handleAction}
                  key={index}
                  isActive={activeVideoIndex === index}
                  setActiveVideo={() => setActiveVideoIndex(index)} // Set the current active video
                />
              )
            })
          }
        </GenericSlider>
      </VideoImageProductCardSliderContainer>
    </BaseWidgetComponent>
  )
}

export default VideoImageProductCardSlider
