import styled from 'styled-components'

export const SlotsContainer = styled.div`
  .slots {
    display: flex;
    overflow: scroll;
    padding: 10px 0;

    > * + * {
      margin-left: 16px;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
  .slot {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--brand-font-weight-medium);
    font-size: 14px;
    border: 1px solid var(--category-primary-bg1-color);
    padding: 10px;
    flex-shrink: 0;
    color: var(--category-primary-color);
    border-radius: 12px;

    &[data-is-active='true'] {
      border: 0px;
      background-color: var(--category-primary-color);
      color: var(--brand-primary-white-color);
    }
  }

  .appointment_card__optionTitleAlt {
    font-size: 14px;
    font-weight: var(--brand-font-weight-400);
    color: var(--brand-primary-black-color);
    margin-bottom: 2px;
  }

  @media (min-width: 768px) {
    .slot {
      font-size: 16px;
    }
    .appointment_card__optionTitleAlt {
      font-size: 18px;
    }
  }
`
