import {noop} from 'lodash'
import {ISuggestedProductCardProps} from './SuggestedProductCard.interface'
import {SuggestedProductCardContainer} from './SuggestedProductCard.styles'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {useMemo} from 'react'

function SuggestedProductCard(props: ISuggestedProductCardProps) {
  const {
    actualPrice = 0,
    discountedPrice = 0,
    discountedPriceLabel = '',
    image = '',
    name = '',
    priceLabel = '',
    urlKey = '',
    recommendedLabel = 'Recommended product for you',
    viewProductLabel = 'View Product',
    discountText = '',
    discountTagImage = 'https://i.mscwlns.co/media/misc/others/Offer%20bg_4vdutz.png?tr=w-50',
    onViewProductClick = noop,
  } = props

  const showDiscountedPrice = useMemo(() => {
    if (!discountedPrice) {
      return false
    }

    return !!(actualPrice > discountedPrice)
  }, [actualPrice, discountedPrice])

  if (!urlKey) {
    return null
  }

  return (
    <SuggestedProductCardContainer>
      <Typography
        customClassName="recommended-label"
        variant={TypographyVariants.SUBHEADING_BOLD}
      >
        {recommendedLabel}
      </Typography>
      <OptimizedImage
        source={image}
        aspectWidth="252"
        aspectHeight="189"
        customClassName="product-image"
      />
      <Typography
        customClassName="product-name"
        variant={TypographyVariants.HEADING_SMALL_BOLD}
      >
        {name}
      </Typography>
      <div className="price-container">
        {showDiscountedPrice ? (
          <div className="price">
            <Typography variant={TypographyVariants.HEADING_SMALL_BOLD}>
              {discountedPriceLabel}
            </Typography>
            <Typography
              customClassName="actual-price"
              variant={TypographyVariants.BODY_BASE_REGULAR}
            >
              {priceLabel}
            </Typography>

            <div className="discount-tag">
              <OptimizedImage
                source={discountTagImage}
                aspectHeight={'16'}
                aspectWidth={'60'}
              />
              <Typography
                customClassName="discount-tag-text"
                variant={TypographyVariants.TAG_BOLD}
              >
                {discountText}
              </Typography>
            </div>
          </div>
        ) : (
          <Typography variant={TypographyVariants.HEADING_SMALL_BOLD}>
            {priceLabel}
          </Typography>
        )}
        <Typography
          variant={TypographyVariants.TAG_REGULAR}
          customClassName="tax-label"
        >
          {'Incl of all taxes'}
        </Typography>

        <button
          className="view-product-button"
          onClick={() => onViewProductClick(urlKey)}
        >
          <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
            {viewProductLabel}
          </Typography>
        </button>
      </div>
    </SuggestedProductCardContainer>
  )
}

export default SuggestedProductCard
