import React, {useMemo} from 'react'
import Star from './star'
import PartialStar from './partialStar'
import EmptyStar from './EmptyStar'
import {StyledWrapper} from './styles'

function RatingStars({rating, height, width, blendWithBackground}) {
  const [fullStars, fraction, emptyStars] = useMemo(() => {
    const roundedRating = Math.floor(rating)
    let blankStars = 5 - roundedRating

    if (rating > roundedRating) {
      blankStars -= 1
      const fraction = Number((rating - roundedRating).toFixed(2))

      return [roundedRating, fraction, blankStars]
    }

    return [roundedRating, , blankStars]
  }, [rating])

  if (Number.isNaN(fullStars)) {
    return null
  }

  return (
    <StyledWrapper>
      {[...Array(fullStars)].map((value, index) => (
        <Star key={index} height={height} width={width} />
      ))}
      {!!fraction && (
        <PartialStar
          height={height}
          width={width}
          fraction={fraction}
          blendWithBackground={blendWithBackground}
        />
      )}
      {emptyStars > 0 &&
        [...Array(emptyStars)].map((_, index) => (
          <EmptyStar height={height} width={width} key={index} />
        ))}
    </StyledWrapper>
  )
}

export default RatingStars
