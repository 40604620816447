import {LOCAL_STORAGE_CONSTANTS, localStorageMethods} from 'src/utils'
import {logError} from 'src/utils/logger'

export const handleReadingTimedStorage = (
  key: typeof LOCAL_STORAGE_CONSTANTS[keyof typeof LOCAL_STORAGE_CONSTANTS]
) => {
  let returnValue

  try {
    if (key) {
      const valueFromStorage = localStorageMethods.get(key)

      if (valueFromStorage) {
        const {value, expiryTime} = JSON.parse(valueFromStorage)
        const currentTime = new Date().getTime()

        if (currentTime < expiryTime) {
          returnValue = value
        }
      }
    }
  } catch (error) {
    logError(error as Error, {
      location: 'handleReadingTimedStorage',
    })
  }

  return returnValue
}
