import React, {useMemo} from 'react'
import StarV2 from '../../../../../../common/RatingStars/StarV2'
import {RatingBarWrapper} from './RatingBar.styles'
import {ISingleRatingBar} from '../RatingBars.types'

function RatingBar(props: ISingleRatingBar) {
  const {ratingData, totalRatingCount, isMobile = false} = props
  const {
    fillerColor = 'var(--brand-primary-filler-color)',
    numberOfReviews = 0,
    rating,
  } = ratingData || {}

  const figure = useMemo(() => {
    if (!totalRatingCount) {
      return 0
    }
    return Math.ceil((numberOfReviews / totalRatingCount) * 100)
  }, [totalRatingCount, numberOfReviews])

  const ratingStarSize = useMemo(() => {
    const size = isMobile ? 28 : 42

    return {
      height: size,
      width: size,
    }
  }, [isMobile])

  return (
    <RatingBarWrapper fillerColor={fillerColor} figure={figure}>
      {!!rating && (
        <div className="rating_number_container">
          <div className="rating_number">
            <span className="leading_text">{rating}</span>
          </div>
          <div className="star">
            <StarV2
              color="var(--brand-primary-black-color)"
              width={ratingStarSize.width}
              height={ratingStarSize.height}
            />
          </div>
        </div>
      )}
      <div className="strip">
        <div className="filled_strip" />
      </div>
      <div className="trailing_text_container">
        <span className="trailing_text">{numberOfReviews}</span>
      </div>
    </RatingBarWrapper>
  )
}

export default RatingBar
