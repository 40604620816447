import styled from 'styled-components'

export const StyledTrustMarkersWidget = styled.div`
  .trust-markers-widget {
    padding-top: var(--dls-size-32);
  }
  .widget-title {
    color: var(--dls-brand-primary-color-500);
  }
`

export const TrustContainer = styled.div`
  .outer {
    display: flex;
    padding-right: var(--dls-size-0);
    padding-left: var(--dls-size-0);
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: var(--dls-size-24);
    background: #fff;
  }

  .inner {
    display: flex;
    flex-direction: row;
    padding-top: var(--dls-size-0);
    padding-left: var(--dls-size-8);
    padding-right: var(--dls-size-8);
    padding-bottom: var(--dls-size-0);
    justify-content: space-between;
    align-items: center;
    gap: var(--dls-size-8);

    @media screen and (min-width: 768px) {
      justify-content: center;
      align-items: center;
      gap: var(--dls-size-16);
    }
  }

  .inner_card_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--dls-size-16);
    flex-shrink: 1;
  }

  .inner_card_imgs_container {
    display: flex;
    width: 68px;
    height: 68px;
    padding: var(--dls-size-2);
    justify-content: center;
    align-items: center;
    gap: var(--dls-size-8);
    border-radius: var(--dls-size-8);
    background: #fff;
  }
  .card_details_title {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  .detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--dls-size-4);
  }
  .card_details_subtitle {
    color: var(--Light-Text-Secondary, #666);
    text-align: center;
    line-height: 15px;
  }
`
