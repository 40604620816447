import React, {useMemo, useCallback} from 'react'
import noop from 'lodash/noop'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'

import {CategoryCardContainer} from './CategoryCard.styles'
import TickIcon from '../TickIcon'

function CategoryCard(props) {
  const {
    option = null,
    text = '',
    image = '',
    selectedOption = null,
    isOptionSelected = false,
    hide = false,
    display_text = '',
  } = props.state || {}
  const {onOptionClick = noop} = props.actions || {}

  const handleOnCardClick = useCallback(() => {
    onOptionClick([{id: option, text}])
  }, [onOptionClick, option, text])

  const isSelected = useMemo(
    () => selectedOption?.id === option,
    [selectedOption, option]
  )

  if (hide) return null

  return (
    <CategoryCardContainer
      className="category-card-container"
      data-category={text.toLowerCase()}
      onClick={handleOnCardClick}
      data-is-selected={isSelected}
    >
      <div className="tick-icon__container">{isSelected && <TickIcon />}</div>
      <img
        src={image}
        alt={text.toLowerCase()}
        className="category-card__image"
        loading="lazy"
      />
      <div className="category-card__text-banner">
        <div className="category-card-text">{display_text}</div>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      {isOptionSelected && (
        <div className="overlay-div" data-is-selected={isSelected}></div>
      )}
    </CategoryCardContainer>
  )
}

export default CategoryCard
