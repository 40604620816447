import {BaseWidget} from '@mosaic-wellness/fe-types'
import {BASE_WIDGET_MAP, WidgetType} from './Widgets.Map'

export interface MapWidgetsFromWidgetData {
  widgets: BaseWidget[]
  CLIENT_SIDE_WIDGETS?: Record<WidgetType, React.ComponentType<any>>
}

export const mapWidgetsFromWidgetData = ({
  widgets,
  CLIENT_SIDE_WIDGETS = {},
}: MapWidgetsFromWidgetData) => {
  return widgets
    .filter((widget) => !!widget)
    .map((widget) => {
      const {type, ...restOfWidget} = widget

      const Component =
        CLIENT_SIDE_WIDGETS[type] || BASE_WIDGET_MAP[type] || null
      return {
        type,
        ...restOfWidget,
        Component,
      }
    })
    .filter((item) => !!item.Component)
}
