import styled from 'styled-components'

export const StyledPhotoPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  background-color: rgba(108, 108, 108, 0.9);
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    max-width: 1120px;
    overflow: hidden;
    border-radius: 20px;

    .center {
      height: 100%;
      width: 100%;
      height: auto;
    }

    .left_arrow,
    .right_arrow {
      z-index: 10;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      position: relative;
      justify-content: center;
      height: 100%;
      max-width: 390px;
      width: 95%;
      .icon {
        position: absolute;
        top: 25%;
      }
      .left_arrow {
        left: 15px;
      }
      .right_arrow {
        right: 15px;
      }
    }
    .center {
      display: flex;
      gap: 30px;
      position: relative;
      background: #ffffff;
      border-radius: 24px;
      padding: 30px;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        height: 100%;
        padding: 16px;
        align-items: center;
        max-width: 360px;
        overflow-x: hidden;
        overflow-y: hidden;
      }
      .image {
        overflow: hidden;
        width: 100%;
        /* width: min(
          700px,
          calc(300px + (700 - 320) * ((100vw - 360px) / (1440 - 360)))
        ); */
        max-height: 500px;
        max-width: 500px;
        object-fit: contain;
        @media (max-width: 768px) {
          min-height: 300px;
          max-height: 300px;
          width: 100%;
          margin-top: 30px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }
      .review_container {
        width: 340px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        @media (min-width: 768px) {
          overflow-y: auto !important;
          ::-webkit-scrollbar {
            width: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background: var(--brand-primary-grey-color);
          }
        }
        .review_section {
          margin-bottom: 20px;
          margin-right: 5px;
        }
        .name {
          margin: 15px;
          margin-left: 3px;
          font-size: 16px;
          font-weight: 900;
        }
        .review_heading_container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 7px 0;
        }
        .review {
          margin: 7px 0;
        }
        .votes-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
          font-size: 12px;
          font-weight: 300;
          color: var(--brand-primary-grey-color);
          margin-top: 10px;

          .voteUp-wrapper,
          .voteDown-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }

          p {
            margin: 0;
          }

          .active-vote {
            font-weight: var(--brand-font-weight-normal);
            color: var(--brand-primary-black-light-color-2);
          }
        }
        .review_date-created {
          font-size: 12px;
          font-weight: 300;
          color: var(--brand-primary-grey-color);
          margin: 0;
        }
        .review_title {
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          color: var(--brand-default-black-color);
        }
        .review {
          font-size: 12px;
          font-weight: 300;
          color: var(--brand-primary-grey-color);
        }
        .ratings_section {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .likes_dislikes_section {
        display: flex;
        justify-content: flex-end;
        gap: 30px;
        .count {
          margin-right: 5px;
        }
        .likes,
        .dislikes {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      hr {
        opacity: 0.2;
      }
      .original_product {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        .product_name {
          margin: 7px 0;
          font-size: 12px;
          font-weight: 400;
        }
        .product_image {
          overflow: hidden;
          cursor: pointer;
          img {
            object-fit: cover;
            border-radius: 20px;
          }
          h4 {
            cursor: pointer;
            font-size: 12px;
            font-weight: var(--brand-font-weight-normal);
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 100px;
    max-width: 1120px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .image_slide img {
      transition: all 0.5s ease-in-out;
    }
    .image_slider {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      img {
        cursor: pointer;
        border-radius: 10px;
      }
    }
  }

  .cross_icon {
    position: absolute;
    top: 18px;
    right: 20px;
    cursor: pointer;
  }
`
export const PhotoPreviewImage = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
`
