import {useCallback, useMemo} from 'react'
import {ProductListingCardProps} from './ProductListingCard.types'
import noop from 'lodash/noop'
import {useCartLoadingContext} from 'src/components/shared/src/ComponentsV2/Context/CartUpdatingContext'
import {useCartStore} from '@mosaic-wellness/redux-action-library'

const useProductListingCard = (props: ProductListingCardProps) => {
  const {
    product,
    handleCtaClick = noop,
    handleCardClick = noop,
    handleReduceQuantity = noop,
    handleIncreaseQuantity = noop,
    enableQuantityIndicator,
  } = props

  const {discountedPrice = '', price = '', sku = '', id = ''} = product || {}

  const {cart} = useCartStore()
  const {checkoutInfo} = cart || {}
  const {items} = checkoutInfo || {}
  const quantity = useMemo(() => items?.[sku]?.qty || 0, [items, sku])
  const showQuantity = useMemo(
    () => quantity > 0 && enableQuantityIndicator,
    [quantity, enableQuantityIndicator]
  )

  const onReduceQuantity = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleReduceQuantity(id, sku, quantity - 1)
    },
    [id, sku, quantity, handleReduceQuantity]
  )

  const onIncreaseQuantity = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleIncreaseQuantity(id, sku, quantity + 1)
    },
    [id, sku, quantity, handleIncreaseQuantity]
  )
  const onCardClick = useCallback(() => {
    handleCardClick(product)
  }, [handleCardClick, product])
  const onCtaClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleCtaClick(product)
    },
    [handleCtaClick, product]
  )

  // TODO: Remove hook
  const {isCartUpdating, itemBeingAddedToCart} = useCartLoadingContext()

  const showLoader = useMemo(() => {
    return isCartUpdating && itemBeingAddedToCart === sku
  }, [isCartUpdating, itemBeingAddedToCart, sku])

  const showDiscountedPrice = useMemo(() => {
    return discountedPrice && discountedPrice !== 0 && price > discountedPrice
  }, [discountedPrice, price])
  return [
    {showLoader, showDiscountedPrice, showQuantity, quantity},
    {
      onCardClick,
      onCtaClick,
      onReduceQuantity,
      onIncreaseQuantity,
    },
  ]
}

export default useProductListingCard
