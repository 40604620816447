import styled from 'styled-components'

export const IconGridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--dls-size-16);
  row-gap: var(--dls-size-16);

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
  }
`
