import {GenericAction} from '@mosaic-wellness/fe-types'
import {isEmpty} from 'lodash'

export function interceptAndEnhanceAction(
  action: GenericAction | null | undefined,
  source: string
): GenericAction {
  // Handle null or undefined input
  if (!action) {
    throw new Error('Action cannot be null or undefined')
  }

  const {params} = action
  const {triggerAnalytics = false, analyticsData} = params || {}
  const {eventData} = analyticsData || {}

  if (triggerAnalytics && !isEmpty(analyticsData) && !isEmpty(eventData)) {
    return {
      ...action,
      params: {
        ...params,
        analyticsData: {
          ...analyticsData,
          eventData: {
            ...eventData,
            source,
          },
        },
      },
    }
  }

  return action
}
