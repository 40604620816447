import {useCallback} from 'react'

import {DoctorAppointmentCardProps} from './DoctorAppointmentCard.interface'
import {useGenericActions} from '../../hooks'
import {GenericAction} from '@mosaic-wellness/fe-types'

const useDoctorAppointmentCard = (props: DoctorAppointmentCardProps) => {
  const {handleGenericActions} = useGenericActions()

  const handleDoctorCardClick = useCallback(() => {
    if (props?.doctorDetails?.cardActions) {
      handleGenericActions(props?.doctorDetails?.cardActions)
    }
  }, [handleGenericActions, props?.doctorDetails?.cardActions])

  const handleFooterCtaClick = useCallback(
    (actions: GenericAction[]) => () => {
      if (actions) {
        handleGenericActions(actions)
      }
    },
    [handleGenericActions]
  )

  return {
    handleDoctorCardClick,
    handleFooterCtaClick,
  }
}

export default useDoctorAppointmentCard
