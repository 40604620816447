import styled from 'styled-components'

export const ToggleButtonContainer = styled.div`
  .wrg-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  [data-is-checked='true'] {
    .wrg-toggle {
      opacity: 1;
    }
    .wrg-toggle-circle {
      left: 27px;
    }
  }

  [data-is-checked='false'] {
    /* opacity: 0.5; */
    -webkit-transition: opacity 0.25s;
    /* transition: opacity 0.25s; */
  }
  .wrg-toggle-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .wrg-toggle-check,
  .wrg-toggle-uncheck {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    opacity: 0;
    transition: opacity 0.25s ease;
  }
  .wrg-toggle-check {
    left: 8px;
  }
  .wrg-toggle-uncheck {
    opacity: 1;
    right: 10px;
  }

  .wrg-toggle-uncheck span,
  .wrg-toggle-check span {
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px;
  }

  .wrg-toggle-container {
    width: 38px;
    height: 12px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    transition: all 0.2s ease;
    background-color: var(--brand-primary-teal-color);
    opacity: 30%;
  }

  .wrg-toggle-circle {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 0.25s ease;
    margin-top: -10%;
    background-color: var(--brand-primary-teal-color);
    opacity: 1 !important;
  }

  .wrg-toggle--checked .wrg-toggle-circle {
    left: 25px;
  }

  @media (min-width: 768px) {
    .wrg-toggle-container {
      width: 50px;
      height: 16px;
    }

    .wrg-toggle-circle {
      height: 24px;
      width: 24px;
    }
  }
`
