import {CtaConfig} from 'src/components/shared/src/Screens/PDP/MiniProductPageBottomSheet/MiniProductPageBottomSheet.interface'
import {create} from 'zustand'

interface IMiniProductPageBottomSheet {
  isVisible: boolean
  data: null | {
    urlKey: string
    source: string
    name: string
  }
  ctaConfig: CtaConfig | null
  openMiniProductPageBottomSheet: (data: any, ctaConfig: CtaConfig) => void
  closeMiniProductPageBottomSheet: () => void
}

export const useMiniProductPageBottomSheetStore =
  create<IMiniProductPageBottomSheet>((set) => ({
    isVisible: false,
    data: null,
    ctaConfig: null,
    openMiniProductPageBottomSheet: (data, ctaConfig) =>
      set({
        isVisible: true,
        data,
        ctaConfig,
      }),

    closeMiniProductPageBottomSheet: () =>
      set({
        isVisible: false,
        data: null,
        ctaConfig: null,
      }),
  }))
