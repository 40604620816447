import React from 'react'

import {SVGContainer} from '../../../DirectAssesment.styles'

function ConsultationIcon(props) {
  const {className, style, category = ''} = props

  return (
    <SVGContainer
      width="22"
      height="22"
      viewBox="0 0 20 20"
      style={style}
      className={className}
      data-category={category}
    >
      <path d="M2.5 10.0056C2.5 7.6508 2.5 5.29596 2.5 2.94112C2.5 1.41948 3.80124 0.0425612 5.31741 0.0230028C7.49005 -0.00829075 9.6666 -0.00829076 11.8393 0.0269144C12.1792 0.0308261 12.5934 0.206852 12.8396 0.441554C14.3519 1.8967 15.8329 3.38314 17.3138 4.86959C17.7359 5.29205 17.857 5.83186 17.8531 6.42644C17.8414 9.98999 17.8492 13.5535 17.8453 17.1171C17.8453 18.5488 16.587 19.9374 15.1607 19.9609C12.4645 20.0078 9.77211 19.9883 7.07584 19.9961C6.48188 19.9961 5.88401 20.0117 5.29005 19.9804C3.75044 19.8983 2.50391 18.5722 2.50391 17.031C2.5 14.6879 2.5 12.3487 2.5 10.0056ZM4.01226 9.96652C4.01226 12.2314 4.01226 14.4963 4.01226 16.7572C4.01226 17.8368 4.61403 18.4705 5.68082 18.4744C8.68579 18.4862 11.6908 18.4862 14.6957 18.4744C15.7078 18.4705 16.3487 17.8329 16.3526 16.8276C16.3604 13.5809 16.3565 10.3342 16.3487 7.08751C16.3487 6.42644 16.0908 6.17609 15.4265 6.16435C14.5355 6.14871 13.6407 6.14871 12.7497 6.12915C11.9838 6.1135 11.765 5.90227 11.7455 5.13949C11.7181 4.23198 11.7298 3.32056 11.6986 2.41304C11.6751 1.70112 11.4368 1.48206 10.7412 1.48206C9.04529 1.48989 7.34938 1.4938 5.65346 1.50944C4.65311 1.51727 4.01616 2.16661 4.01226 3.17191C4.00835 5.44069 4.01226 7.70165 4.01226 9.96652Z" />
      <path d="M10.1824 15.3809C8.94758 15.3809 7.71277 15.3809 6.48186 15.3809C5.94652 15.3809 5.59092 15.0953 5.5792 14.6689C5.56748 14.223 5.92307 13.8905 6.47014 13.8905C8.96712 13.8866 11.4641 13.8944 13.965 13.8944C14.3714 13.8944 14.6605 14.0783 14.7543 14.4773C14.8403 14.8371 14.684 15.1266 14.3597 15.2948C14.2229 15.3652 14.047 15.3769 13.8868 15.3769C12.6481 15.3848 11.4172 15.3809 10.1824 15.3809Z" />
      <path d="M10.1824 7.72053C11.4289 7.72053 12.6794 7.71662 13.9259 7.72053C14.4535 7.72053 14.7543 7.98262 14.77 8.42855C14.7856 8.87057 14.4964 9.17959 13.965 9.18351C11.4368 9.19915 8.91243 9.19915 6.38419 9.18742C5.8801 9.18351 5.55968 8.8471 5.57922 8.42464C5.59875 8.00218 5.92699 7.72053 6.43499 7.72053C7.68543 7.72053 8.93197 7.72053 10.1824 7.72053Z" />
      #010101
      <path d="M9.42825 12.2664C8.41617 12.2664 7.40409 12.2703 6.39201 12.2625C5.89965 12.2586 5.59485 11.9848 5.57922 11.5662C5.56359 11.1477 5.84885 10.823 6.34512 10.8191C8.38491 10.7956 10.4247 10.7956 12.4645 10.8074C12.949 10.8113 13.2304 11.132 13.2187 11.5662C13.2108 12.0043 12.9217 12.2625 12.4176 12.2625C11.4211 12.2664 10.4247 12.2664 9.42825 12.2664Z" />
    </SVGContainer>
  )
}

export default ConsultationIcon
