import {useCallback} from 'react'
import {DoctorListingCardProps} from './DoctorListingCard.interface'
import {useGenericActions} from '../../hooks'
import {GenericAction} from '@mosaic-wellness/fe-types'

const useDoctorListingCard = (props: DoctorListingCardProps) => {
  const {cardActions = [], notifyMeData} = props || {}
  const {handleGenericActions} = useGenericActions()

  const handleDoctorListingCardClick = useCallback(() => {
    handleGenericActions(cardActions)
  }, [handleGenericActions, cardActions])

  const handleNotifyMeClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      if (notifyMeData?.cta?.actions) {
        handleGenericActions(notifyMeData?.cta?.actions)
      }
    },
    [handleGenericActions, notifyMeData?.cta?.actions]
  )

  const handleFooterCtaClick = useCallback(
    (actions: GenericAction[]) => () => {
      handleGenericActions(actions)
    },
    [handleGenericActions]
  )

  return {
    handleDoctorListingCardClick,
    handleNotifyMeClick,
    handleFooterCtaClick,
  }
}

export default useDoctorListingCard
