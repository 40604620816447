import React from 'react'
// import './backdrop.css';
import {StyledBackdrop} from './styles'

class Backdrop extends React.Component {
  render() {
    return (
      <StyledBackdrop
        data-backdrop-open={this.props.isOpen?.toString() ?? 'false'}
        className="backdrop"
        transitionTime={this.props.transitionTime || 500}
        onClick={this.props.close}
      />
    )
  }
}

export default Backdrop
