import React from 'react'
import {TestimonialsWrapper} from './Testimonials.styles'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {
  getImageDimensionFromAspectRatioFor360,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'

interface TestimonialsWidget {
  labelColor?: string
  backgroundColor?: string
  verifiedIcon?: string
  starIcon?: string
  aspectRatio?: number
  slidesToShow?: number
  testimonials: {
    imageUrl: string
    altText: string
    aspectRatio: number
    title: string
    pill?: string
    testimonialBody: string
    authorInfo?: string
    rating?: string
  }[]
}

const Testimonials = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<TestimonialsWidget>) => {
  const {
    testimonials = [],
    labelColor,
    backgroundColor,
    verifiedIcon = '',
    starIcon = '',
    aspectRatio = 1,
    slidesToShow = 1,
  } = widgetData

  const [slideWidth, slideHeight] = getImageDimensionFromAspectRatioFor360({
    aspectRatio,
    noOfSlides: slidesToShow,
  })

  if (!testimonials || isEmpty(testimonials)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <TestimonialsWrapper
        backgroundColor={backgroundColor}
        labelColor={labelColor}
        noOfSlides={slidesToShow}
      >
        <div className="review_card_lists">
          {testimonials.map((review, index) => {
            const {
              imageUrl = '',
              altText = '',
              title = '',
              pill = '',
              testimonialBody = '',
              authorInfo = '',
              rating = '',
            } = review || {}

            return (
              <div className="review_card_item" key={index}>
                {imageUrl && (
                  <div className="review_card_imgs_container">
                    <OptimizedImage
                      source={imageUrl}
                      aspectWidth={slideWidth}
                      aspectHeight={slideHeight}
                      desktopViewWidth="FULL"
                      mobileViewWidth="FULL"
                      altText={altText}
                      loading="lazy"
                      customClassName="review_card_img"
                    />
                  </div>
                )}
                <div className="review_card_details">
                  {title && (
                    <Typography
                      variant={TypographyVariants.BODY_BASE_BOLD}
                      customClassName="review_card_details_title"
                    >
                      {title}
                    </Typography>
                  )}
                  {pill && (
                    <Typography
                      variant={TypographyVariants.X_SMALL}
                      customClassName="review_card_details_pill"
                    >
                      {pill}
                    </Typography>
                  )}
                  {testimonialBody && (
                    <Typography
                      variant={TypographyVariants.BODY_BASE_REGULAR}
                      customClassName="review_card_details_description"
                    >
                      {testimonialBody}
                    </Typography>
                  )}
                  {authorInfo && (
                    <span className="author-container">
                      <span className="author-text">
                        <Typography
                          variant={TypographyVariants.TAG_BOLD}
                          customClassName="review_card_details_description"
                        >
                          {authorInfo}
                        </Typography>
                        <OptimizedImage
                          source={verifiedIcon}
                          aspectWidth={16}
                          aspectHeight={16}
                          desktopViewWidth="SMALL"
                          mobileViewWidth="FULL"
                          altText={altText}
                          loading="lazy"
                          customClassName="verified-icon"
                        />
                      </span>
                      {rating && (
                        <span className="rating-container">
                          <OptimizedImage
                            source={starIcon}
                            aspectWidth={18}
                            aspectHeight={18}
                            desktopViewWidth="SMALL"
                            mobileViewWidth="FULL"
                            altText={altText}
                            loading="lazy"
                            customClassName="verified-icon"
                          />
                          <Typography
                            variant={TypographyVariants.CTA_LABEL_SMALL}
                            customClassName="review_card_details_description"
                          >
                            {rating}
                          </Typography>
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </TestimonialsWrapper>
    </BaseWidgetComponent>
  )
}

export default Testimonials
