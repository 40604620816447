import {getQueryParamFromUrl} from 'src/utils/url'

export const processSelfAssessmentViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  return {
    URL: window.location.href,
    Page: document.referrer,
    UTM: getQueryParamFromUrl('utm_source'),
    isLoggedIn: dLayer.user?.isLoggedIn || false,
  }
}

export const processSelfAssessmentUserName = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {name = ''} = data

  return {
    Name: name,
  }
}

export const processSelfAssessmentCategory = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = ''} = data

  return {
    Category: category,
  }
}

export const processSelfAssessmentQuestions = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {question = '', answer = '', option = ''} = data

  return {
    Question: question,
    Answer: answer,
    'Answer Number': option,
  }
}

export const processSelfAssessmentBasicDetails = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  return data
}

export const processSelfAssessmentSubmitted = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = ''} = data

  return {
    Category: category,
    'Phone Number': dLayer.user?.phoneNumber,
    UTM: getQueryParamFromUrl('utm_source'),
  }
}

export const transformPriceToNumber = (price: string) => {
  try {
    return Number(price.replace(/[^0-9.-]+/g, ''))
  } catch (error) {
    return -1
  }
}

export const processSelfAssessmentReport = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {imageAvailable = false} = data || {}

  const pricingInReport = dLayer?.form?.pricing ?? {}
  const priceAfterDiscount = pricingInReport?.afterDiscount ?? 0
  const pricingBeforeDiscount = pricingInReport?.beforeDiscount ?? 0
  return {
    Category: dLayer?.form?.category,
    'Phone number': dLayer?.user?.phoneNumber,
    UTM: getQueryParamFromUrl('utm_source'),
    'Overall Score': dLayer?.form?.score,
    Products: dLayer?.form?.products?.toString(),
    Condition: dLayer?.form?.condition,
    Diagnosis: dLayer?.form?.diagnosis,
    'Provisional diagnosis category':
      dLayer?.form?.diagnosisData?.category || '',
    'Provisional diagnosis stage':
      dLayer?.form?.diagnosisData?.stage?.join(',') || '',
    'Provisional diagnosis concern':
      dLayer?.form?.diagnosisData?.concerns?.join(',') || '',
    'Provisional diagnosis severity':
      dLayer?.form?.diagnosisData?.severity?.join(',') || '',
    'Image Available': imageAvailable,
    'Pricing Before Discount': transformPriceToNumber(pricingBeforeDiscount),
    'Pricing After Discount': transformPriceToNumber(priceAfterDiscount),
  }
}

export const processSelfAssessmentPlanAppointment = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {type = '', reason = '', iaAvailable = false} = data

  return {
    Category: dLayer?.form?.category,
    'IA Available': iaAvailable,
    Reason: reason,
    Type: type,
  }
}

export const processSelfAssessmentApiError = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {api = '', status = '', errorMessage = '', toastMessage = ''} = data

  return {
    Api: api,
    'Error Code': status,
    'Error Name': errorMessage,
    'Error Toast': toastMessage || errorMessage,
  }
}
