import React, {useCallback, useState} from 'react'
import {AccordionItemContainer} from './AccordionItem.styles'
import {IAccordionItemProps} from './AccordionItem.interface'
import {
  ChevronDown,
  ChevronUp,
  Typography,
  TypographyVariants,
} from '@web-components'

function AccordionItem(props: IAccordionItemProps) {
  const {content = '', title = ''} = props || {}

  const [isOpen, setIsOpen] = useState(false)

  const handleAccordionClick = useCallback(() => {
    setIsOpen((flag) => !flag)
  }, [])

  if (!title) {
    return null
  }

  return (
    <AccordionItemContainer className="accordion-item" data-isopen={isOpen}>
      <div onClick={handleAccordionClick}>
        <div className="title-container">
          <Typography
            variant={TypographyVariants.BODY_BASE_BOLD}
            customClassName="title-text"
          >
            {title}
          </Typography>
          <div className="chevron-icon">
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>

        <Typography
          variant={TypographyVariants.BODY_BASE_REGULAR}
          customClassName="content-text"
        >
          {content}
        </Typography>
      </div>
    </AccordionItemContainer>
  )
}

export default AccordionItem
