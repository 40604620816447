import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--brand-primary-light-grey-color);
  border-radius: 12px;
  margin-bottom: 8px;
  margin-top: 8px;

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }

  .walletImage {
    aspect-ratio: 1;
    object-fit: cover;
  }

  .walletText {
    font-size: 14px;
    line-height: 120%;
    color: var(--brand-primary-grey-color);
    font-family: var(--brand-font-family-text);
    font-weight: var(--brand-font-weight-light);
  }
`
