import styled from 'styled-components'

export const CategoryCardContainer = styled.div`
  height: 176px;
  max-width: 216px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  background-color: var(--category-primary-bg1-color);

  &[data-is-selected='true'] {
    border: 2px solid var(--brand-primary-white-color);
    filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.15));
  }

  .overlay-div {
    background-color: var(--brand-primary-black-color);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 20px;

    &[data-is-selected='true'] {
      border: 2px solid var(--brand-primary-white-color);
      opacity: 0;
    }
    &[data-is-selected='false'] {
      opacity: 0.2;
    }
  }

  .tick-icon__container {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .category-card__image {
    height: 128px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    object-fit: cover;

    @media screen and (min-width: 768px) {
      height: 196px;
      object-fit: cover;
    }
  }

  .category-card__text-banner {
    color: var(--brand-primary-white-color);
    font-size: 16px;
    font-weight: var(--brand-font-weight-semiBold);
    height: 48px;

    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .category-card-text {
      margin-right: 6px;
    }

    background: linear-gradient(
      97.38deg,
      var(--category-primary-dark-color) 0%,
      rgba(var(--category-primary-color-value), 1) 100%
    );
  }

  @media screen and (min-width: 768px) {
    .category-card__text-banner {
      height: 74px;
    }

    height: 270px;
    :nth-child(odd) {
      justify-self: right;
    }
  }
`
