import React from 'react'

import {InfoCardContainer} from './InfoCard.styles'
import {iconMapper} from './InfoCard.utils'

function InfoCard(props) {
  const {ctaNumber = '', text = ''} = props.state || {}

  return (
    <InfoCardContainer>
      <div className="info-card__imageContainer">{iconMapper(text)}</div>
      <div className="info-card__textContainer">
        <p className="info-card__ctaNumber">{ctaNumber}</p>
        <p className="info-card__text">{text}</p>
      </div>
    </InfoCardContainer>
  )
}

export default InfoCard
