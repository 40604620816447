export const processDeliveryToastSeen = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    Type: data.type || '',
    City: data.city || '',
  }

  return dataToReturn
}
