import styled from 'styled-components'

export const StyledArrowHead = styled.div`
  border: ${({isSelected, color}) =>
    !isSelected
      ? color
        ? `solid ${color}`
        : 'solid var(--brand-primary-text-color)'
      : 'solid var(--dls-brand-primary-color-500)'};
  border-width: ${({borderWidth = '1px'}) =>
    `0 ${borderWidth} ${borderWidth} 0`};
  display: inline-block;
  padding: 4px;

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 4px;
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: -4px;
  }
`

export const ArrowHead = ({
  direction,
  isSelected = false,
  color = '',
  borderWidth = '2px',
}) => {
  return (
    <StyledArrowHead
      className={`${direction}`}
      isSelected={isSelected}
      color={color}
      borderWidth={borderWidth}
    />
  )
}
