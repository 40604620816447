import React, {useCallback, useEffect} from 'react'

import VideoRender from './Components/VideoPlayer'

function OptimizedVideoV2(props) {
  const {state = {}} = props || {}
  const {videoComponentClassName = 'video-render-component', autoplay = false} =
    state

  const playPauseVideo = useCallback(() => {
    let videos = document.querySelectorAll(`.${videoComponentClassName}`)
    videos.forEach((video) => {
      let playPromise = video.play()
      if (playPromise !== undefined) {
        playPromise.then((_) => {
          let observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (!entry.isIntersecting && !video.paused) {
                  video.pause()
                } else if (
                  entry.isIntersecting &&
                  video.paused &&
                  !video.ended &&
                  autoplay
                ) {
                  video.play()
                }
              })
            },
            {threshold: 0.2}
          )
          observer.observe(video)
        })
      }
    })
  }, [autoplay, videoComponentClassName])

  useEffect(() => {
    if (document.readyState === 'complete' && autoplay) {
      playPauseVideo()
    } else if (autoplay) {
      window.addEventListener('load', playPauseVideo)
      return () => document.removeEventListener('load', playPauseVideo)
    }
  }, [autoplay, playPauseVideo, videoComponentClassName])

  return (
    <VideoRender
      state={{additionalClassNames: videoComponentClassName, ...state}}
      actions={{playPauseVideo}}
    />
  )
}

export default OptimizedVideoV2
