import styled from 'styled-components'

export const StyledWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: ${(props) => `${props.borderRadius}px`};
  height: ${(props) =>
    props.dynamicHeight ? `${props.dynamicHeight}px` : '100%'};
  width: ${(props) =>
    props.dynamicWidth ? `${props.dynamicWidth}px` : '100%'};
  .video {
    width: 100%;
    height: 100%;
  }
  .video-overlay {
    position: absolute;
    left: 0;
    top: 25%;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }

  .video-label-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loading-skeleton {
    width: 100%;
    height: 100%;
    background-color: var(
      --brand-primary-light-grey-color
    ); /* Placeholder color */
    animation: loading 1.5s infinite alternate; /* Placeholder animation */
  }

  @keyframes loading {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`
