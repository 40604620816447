import styled from 'styled-components'

export const QuestionnaireContainer = styled.div`
  padding-bottom: 80px;

  .top-bar {
    max-width: 1120px;
    padding: 16px 16px 0px 16px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }

  .top-bar-button {
    border: 0;
    outline: 0;
    font-family: var(--brand-font-family-text);
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: var(--brand-font-weight-medium);
    line-height: 1;
  }

  .back-button {
    color: var(--category-primary-color);
    background-color: var(--category-primary-bg1-color);

    .chevron-icon {
      margin-right: 6px;
    }
  }

  .close-button {
    margin-left: auto;
    color: var(--brand-primary-red-color);
    background-color: var(--brand-primary-red-bg1-color);
  }

  .question-text {
    font-size: 20px;
    font-weight: var(--brand-font-weight-medium);
    color: var(--brand-primary-black-color);
    line-height: 1;
    margin: 24px 0 30px 0;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    padding-bottom: 100px;

    .top-bar {
      padding: 30px;
    }

    .top-bar-button {
      padding: 10px 12px;
      font-size: 16px;
    }

    .question-text {
      font-size: 28px;
      margin: 0 0 40px 0;
    }
  }
`
