import styled from 'styled-components'

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow: scroll;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  white-space: nowrap;
`

export const Container = styled.div`
  margin-right: var(--dls-size-16);
  scroll-snap-type: x mandatory;
  flex: none;
  max-width: var(--dls-size-320);
  .imageorAnimation {
    width: 100%;
  }
`
