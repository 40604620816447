import React from 'react'
import isEmpty from 'lodash/isEmpty'

import {WeGotAnswersContainer} from './WeGotAnswers.styles'
import {IngredientAccordion} from '../KeyIngredients/IngredientAccordion'
import HeadingComponent from '../../../common/HeadingComponent'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import Typography from '../../../common/Typography/Typography'

function WeGotAnswers(props) {
  const {
    subHeadingLevel = 2,
    headingsFor = {},
    heading = '',
    subHeading = '',
    answers = [],
    sectionName = 'faq',
  } = props.state || {}

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <WeGotAnswersContainer
      data-scrollSection="weGotAnswers"
      data-section={sectionName}
    >
      <Typography
        text={heading}
        variant="heading-sm-bold"
        customClassName="heading"
      />
      <HeadingComponent
        customClassName="subHeading"
        headingLevel={subHeadingLevel}
        label={subHeading}
      />
      {answers?.map((accordion) => {
        const {title = ''} = accordion || {}
        return (
          <IngredientAccordion
            state={{accordion, headingsFor}}
            isAccordionKeyExist={true}
            key={title}
          />
        )
      })}
    </WeGotAnswersContainer>
  )
}

export default withCustomErrorBoundary(WeGotAnswers, 'WeGotAnswers')
