import {EVENT_MAP} from 'src/analytics/eventMap'

export const processConsultStepViewed = (
  event: string,
  data: {
    entryPoint: string
    category: string
    question: ''
    answer: ''
    source: string
    iaAvailable: boolean
    slot1: string
    slot2: string
    slot3: string
    language: string
    iaBooked: boolean
    appointmentId: string
  },
  dLayer: typeof window.customDataLayer
) => {
  switch (event) {
    case EVENT_MAP.VIEW_DA: {
      return {
        'Entry Point': data.entryPoint || 'Other',
      }
    }

    case EVENT_MAP.VIEW_QUESTIONS: {
      return {
        Question: data.question || '',
        Answer: data.answer || '',
        Category: data.category || '',
      }
    }

    case EVENT_MAP.VIEW_SCHEDULING_SCREEN: {
      return {
        Category: data.category || '',
        Source: data.source || '',
        'IA Available': data?.iaAvailable ? 'Y' : 'N',
        slot1: data.slot1 || '',
        slot2: data.slot2 || '',
        slot3: data.slot3 || '',
      }
    }

    case EVENT_MAP.VIEW_GET_CUSTOMER_INFO_SCREEN: {
      return {
        Category: data.category || '',
        Language: data.source || '',
        'IA Booked': data.iaBooked ? 'Y' : 'N',
        'Appointment Id': data.appointmentId || '',
      }
    }

    case EVENT_MAP.VIEW_IMAGE_UPLOAD: {
      return {
        Category: data.category || '',
        'IA Booked': data.iaBooked ? 'Y' : 'N',
        Source: data.source || '',
        'Appointment Id': data.appointmentId || '',
      }
    }

    case EVENT_MAP.VIEW_APPOINTMENT_SUMMARY_SCREEN: {
      return {
        Category: data.category || '',
        Language: data.source || '',
        'IA Booked': data.iaBooked ? 'Y' : 'N',
        Source: data.source || '',
        'Appointment Id': data.appointmentId || '',
      }
    }

    default:
      return {}
  }
}
