import {useEffect, useMemo, useState} from 'react'

/**
 * @deprecated
 */
const Responsive = (Component, screenSize) => {
  return function ReturnedComponent(props) {
    const [screenWidth, setScreenWidth] = useState(() => {
      if (typeof window !== 'undefined') {
        return window.innerWidth
      } else if (typeof process !== 'undefined' && process.env.SCREEN_WIDTH) {
        return Number.parseInt(process.env.SCREEN_WIDTH)
      } else if (screenSize) {
        return Number.parseInt(screenSize)
      } else return 1440
    })
    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth)
      }
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    const isMobile = useMemo(() => {
      let isMobileDevice = true

      if (screenWidth && screenWidth > 768) {
        isMobileDevice = false
      }

      return isMobileDevice
    }, [screenWidth])

    return (
      <Component screenWidth={screenWidth} isMobile={isMobile} {...props} />
    )
  }
}

export default Responsive
