import {useCallback} from 'react'
import {useExplicitATCTracking} from '../tracking/useExplicitATCTracking'
import {useProductClickTracking} from 'src/hooks/tracking/useProductClick'
import isEmpty from 'lodash/isEmpty'
import {useProductNavigation} from './useProductNavigation'
import {useUpdateItemToCart} from '../updateCart/useUpdateItemToCart'
import {useGenericCta} from '../useGenericCta'
import {useRxCartTracking} from '../tracking/useRxCartTracking'
import noop from 'lodash/noop'

function useProductCard() {
  const {addToCart} = useUpdateItemToCart()
  const {trackAddToCart} = useExplicitATCTracking()
  const {trackProductCardClick} = useProductClickTracking()
  const {navigateToProduct} = useProductNavigation()
  const {trackDocCardClick = noop} = useRxCartTracking()
  const {handleCta} = useGenericCta()

  const onAddToCart = useCallback(
    (product, source = '', otherOptions = {}) => {
      const {sku} = product
      trackAddToCart(product, source, otherOptions)
      addToCart({sku})
    },
    [addToCart, trackAddToCart]
  )

  const onProductClick = useCallback(
    (card: any, source = '', _?: number) => {
      const {slugUrl = '', urlKey = ''} = card || {}
      if (!isEmpty(source)) {
        trackProductCardClick({...card, source})
      }
      navigateToProduct({slugUrl, urlKey})
    },
    [navigateToProduct, trackProductCardClick]
  )
  const handleDeepLink = useCallback(
    (action = 'LINK', deeplink = '', product = {}) => {
      trackDocCardClick(product)
      handleCta({
        action: action,
        link: deeplink,
      })
    },
    [handleCta]
  )

  return {onProductClick, onAddToCart, handleDeepLink}
}

export {useProductCard}
