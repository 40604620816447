import {MediaWithHeaderCardContainer} from './MediaWithHeaderCard.styles'
import {IMediaWithHeaderCardProps} from './MediaWithHeaderCard.interface'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {useMemo} from 'react'

function MediaWithHeaderCard(props: IMediaWithHeaderCardProps) {
  const {
    header = '',
    image = '',
    uiType = '',
    imageDimensions,
    topCalloutLabel = '',
    cardBackground = '',
    title,
    subtitle,
    checkIcon = '',
  } = props
  const {width = '140', height = '120'} = imageDimensions || {}
  const {color = '', text = ''} = title || {}
  const {color: subtitleColor = '', text: subtitleText = ''} = subtitle || {}

  const showHeader = useMemo(() => {
    return !!header || !!text || !!subtitleText
  }, [header, subtitleText, text])

  if (!image) {
    return null
  }

  return (
    <MediaWithHeaderCardContainer
      data-uiType={uiType}
      style={{background: cardBackground}}
    >
      {!!topCalloutLabel && (
        <div className="top-callout">
          <Typography variant={TypographyVariants.X_SMALL}>
            {topCalloutLabel}
          </Typography>
        </div>
      )}
      {!!showHeader && (
        <div className="header-container">
          <div className="header-text">
            {!!header && (
              <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                <span dangerouslySetInnerHTML={{__html: header}} />
              </Typography>
            )}

            {!!text && (
              <Typography
                style={{color: color}}
                variant={TypographyVariants.BODY_BASE_REGULAR}
              >
                {text}
              </Typography>
            )}

            {!!subtitleText && (
              <Typography
                style={{color: subtitleColor}}
                variant={TypographyVariants.BODY_BASE_BOLD}
              >
                {subtitleText}
              </Typography>
            )}
          </div>

          {!!checkIcon && (
            <div className="icon">
              <OptimizedImage
                source={checkIcon}
                aspectHeight="20"
                aspectWidth="20"
                customClassName="icon-image"
              />
            </div>
          )}
        </div>
      )}
      <div className="card-image-container">
        <OptimizedImage
          source={image}
          aspectWidth={width}
          aspectHeight={height}
          customClassName="card-image"
        />
      </div>
    </MediaWithHeaderCardContainer>
  )
}

export default MediaWithHeaderCard
