import React, {useCallback, useState} from 'react'
import {IKitProduct} from '../KitBreakdown.interface'

function useKitProduct() {
  const [kitProductModal, setKitProductModal] = useState<{
    showModal: boolean
    isModalOpen: boolean
    modalData: IKitProduct['modalData'] | null
  }>({
    showModal: false, //flag to indicate if we should handle modal or not
    isModalOpen: false, //flag to indicate if modal is visible on the UI
    modalData: null,
  })

  const openModal = useCallback((modalData: any) => {
    let timeoutId: any = null

    setKitProductModal((data) => ({
      ...data,
      showModal: true,
      modalData,
    }))

    timeoutId = setTimeout(() => {
      setKitProductModal((data) => ({
        ...data,
        isModalOpen: true,
      }))
      clearTimeout(timeoutId)
    }, 100)
  }, [])

  const closeModal = useCallback(() => {
    let timeoutId: any = null

    setKitProductModal((data) => ({
      ...data,
      isModalOpen: false,
    }))

    timeoutId = setTimeout(() => {
      setKitProductModal(() => ({
        isModalOpen: false,
        showModal: false,
        modalData: null,
      }))
      clearTimeout(timeoutId)
    }, 200)
  }, [])

  return [{kitProductModal}, {openModal, closeModal}] as const
}

export default useKitProduct
