import {useCallback, useState} from 'react'

import {SELF_ASSESSMENT_STEPS} from 'src/constants/SelfAssessment'

function useSaStep() {
  const [activeStep, setActiveStep] = useState(SELF_ASSESSMENT_STEPS.HOME)

  const updateStep = useCallback((step: string) => {
    setActiveStep(step)
  }, [])

  const handleNextStep = useCallback(() => {
    switch (activeStep) {
      case SELF_ASSESSMENT_STEPS.HOME: {
        setActiveStep(SELF_ASSESSMENT_STEPS.ASSESSMENT_FORM)
        return
      }

      default:
        return
    }
  }, [activeStep])

  return {activeStep, handleNextStep, updateStep}
}

export {useSaStep}
