import styled from 'styled-components'

export const KitProductContainer = styled.div`
  display: flex;

  .kit-product-data {
    display: flex;
    flex-direction: column;
    border-radius: var(--dls-size-8);
    overflow: hidden;
    border: 0.5px solid var(--dls-divider-light-color);
  }

  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .product-details-container {
    padding: var(--dls-size-8);
    text-align: center;
    flex: 1;
  }

  .product-name {
    color: var(--dls-category-primary-color-500);
  }

  .plus-sign {
    align-self: center;
    padding: 0 var(--dls-size-8);
    font-size: var(--dls-size-24);
    color: var(--dls-category-primary-color-500);

    &[data-islastelement='true'] {
      visibility: hidden;
    }
  }
`
