import styled from 'styled-components'

export const CancelCardContainer = styled.div`
  margin: 0 10px;
  background-color: var(--brand-primary-white-color);
  width: 100%;
  border-radius: 20px;
  padding: 20px 16px;
  max-width: 400px;

  .title {
    font-size: 18px;
    font-weight: var(--brand-font-weight-dark);
    line-height: 140%;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    margin-bottom: 24px;
    color: var(--brand-primary-teal-color);
  }

  .info-section-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .gift-image {
    width: 188px;
    height: 96px;
  }
  .button {
    outline: 0;
    border: 0;
    font-family: var(---brand-font-family-text);
    font-size: 14px;
    font-weight: var(--brand-font-weight-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 0;
    background-color: transparent;
  }

  .primary-cta {
    border-radius: 12px;
    background-color: var(--brand-primary-color);
    color: var(--brand-primary-white-color);
    margin-bottom: 4px;
  }

  .secondary-cta {
    color: var(--brand-primary-color);
  }
`
