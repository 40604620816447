import styled from 'styled-components'

export const StyledVideoSliderContainer = styled.div`
  width: 100%;

  .thumbnail {
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: var(--dls-size-16);
  }

  /* VideoModal.css */
  .modal-overlay {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 7;
  }

  .modal-content {
    padding: 10px 16px 16px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    max-width: 500px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 0.3);
    width: 90%;
    background-color: var(--dls-white-color);
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }

  .close-button {
    background-color: transparent;
    border: none;
    text-align: end;
    cursor: pointer;
  }

  .video-player {
    height: unset;
    min-height: 500px;
    border-radius: var(--dls-size-16);
  }
`
