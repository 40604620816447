import {TFloatingPosition} from './floatingSelfDiagnosisToast.types'
import styled from 'styled-components'

interface IStyledFloatingSelfDiagnosisToast {
  mobilePosition: TFloatingPosition
  desktopPosition: TFloatingPosition
  styles?: {
    bgColor?: string
    textColor?: string
  }
}

export const StyledFloatingSelfDiagnosisToast = styled.div<IStyledFloatingSelfDiagnosisToast>`
  position: relative;
  z-index: 5;
  .container {
    position: fixed;
    right: ${(props) => props.mobilePosition.right};
    left: ${(props) => props.mobilePosition.left};
    bottom: ${(props) => props.mobilePosition.bottom};
    margin: auto;
    transform: ${(props) => props.mobilePosition.transform};
    width: max-content;
    z-index: 3;
    border-radius: 20px;
    padding: 10px 20px;
    background-color: ${(props) =>
      props.styles?.bgColor || 'var(--category-primary-color)'};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 8px;
    > .icon {
      height: 20px;
      width: 20px;
    }

    @media (min-width: 768px) {
      right: ${(props) => props.desktopPosition.right};
      left: ${(props) => props.desktopPosition.left};
      bottom: ${(props) => props.desktopPosition.bottom};
      transform: ${(props) => props.desktopPosition.transform};
    }
  }
  .label {
    color: ${(props) =>
      props.styles?.textColor || 'var(--brand-primary-white-color);'};
  }
  .crossIcon {
    position: absolute;
    background-color: black;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: -10px;
    cursor: pointer;
  }
`
