import styled from 'styled-components'

export const StyledCarouselDots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--dls-size-8);
  &[data-isOverlay='true'] {
    position: absolute;
    bottom: var(--dls-size-8);
  }
  .bullet,
  .video {
    display: inline-block;
    width: 6px;
    height: 6px;

    &[data-isactive='true'] {
      width: 8px;
      height: 8px;
    }
  }

  .bullet {
    border-radius: 50%;
    background-color: var(--dls-secondary-text-colors);

    &[data-isactive='true'] {
      background-color: var(--category-primary-color);
    }

    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  .video {
    display: inline-block;
    margin-right: 6px;
  }
`
