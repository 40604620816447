import {useCallback, useEffect, useMemo, useState} from 'react'
import noop from 'lodash/noop'
import {
  useCheckoutConfig,
  useConfigStore,
  useFeatureEnabled,
  useTruecaller,
} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'

const PARTNER_KEY = process.env.NEXT_PUBLIC_TRUECALLER_LOGIN_PARTNER_KEY

function useTruecallerLogin({
  triggerNormalLoginCallback = noop,
  source = 'checkout',
}) {
  const [isTruecallerPresent, setIsTruecallerPresent] = useState(false)
  const [isFeatureSupported, setIsFeatureSupported] = useState(false)

  const {truecallerLogin} = useCheckoutConfig()
  const {analytics} = useConfigStore()

  const triggerSourceData = useMemo(() => {
    if (truecallerLogin?.[source]) {
      return truecallerLogin[source]
    }

    return
  }, [source, truecallerLogin])

  const [isTruecallerEnabled] = useFeatureEnabled(
    'truecallerLogin',
    triggerSourceData
  )

  let config = useMemo(() => {
    return (
      triggerSourceData?.config || {
        partnerName: 'Man Matters',
        loginPrefix: 'placeorder',
        loginSuffix: 'verifymobile',
        skipOption: 'useanothernum',
        type: 'modal',
        lang: 'en',
        ctaPrefix: 'use',
        btnShape: 'round',
        ctaColor: '%2322548a',
        ctaTextColor: '%23ffffff',
        privacyUrl: 'https://manmatters.com/privacy-policy/',
        termsUrl: 'https://manmatters.com/terms-of-service',
      }
    )
  }, [triggerSourceData?.config])

  const {
    state: {loginRejectedOrFailed, isPollingInProgress, truecallerLoginStatus},
    actions: {pollTruecallerLoginSuccess, setTruecallerRequestId},
  } = useTruecaller({source})

  const triggerTruecallerLogin = useCallback(() => {
    const uniqueId =
      (+Date.now() * Math.random()).toString(36).replace(/[.]/g, '') +
      Date.now()

    window.location.href = `truecallersdk://truesdk/web_verify?requestNonce=${uniqueId}&partnerKey=${PARTNER_KEY}&partnerName=${config.partnerName}&title=Login&loginPrefix=${config.loginPrefix}&loginSuffix=${config.loginSuffix}&skipOption=${config.skipOption}&type=${config.type}&lang=${config.lang}&ctaPrefix=${config.ctaPrefix}&btnShape=${config.btnShape}&ctaColor=${config.ctaColor}&ctaTextColor=${config.ctaTextColor}&privacyUrl=${config.privacyUrl}&termsUrl=${config.termsUrl}`

    setTimeout(async function () {
      if (document.hasFocus()) {
        setIsTruecallerPresent(false)
        triggerNormalLoginCallback()
      } else {
        setIsTruecallerPresent(true)
        analytics.push({truecallerSeen: true})
        analytics.trigger(EVENT_MAP.TRUECALLER_SEEN, {})
        await setTruecallerRequestId(uniqueId)
        pollTruecallerLoginSuccess(uniqueId)
      }
    }, 600)
  }, [
    pollTruecallerLoginSuccess,
    setTruecallerRequestId,
    triggerNormalLoginCallback,
    config,
    analytics,
  ])

  // Listen to truecaller login failure or user rejection to trigger fallback login
  useEffect(() => {
    if (loginRejectedOrFailed) {
      triggerNormalLoginCallback()
    }
  }, [loginRejectedOrFailed])

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor

    const isAndroid = /Android|android/i.test(userAgent)

    setIsFeatureSupported(isAndroid)
  }, [])

  useEffect(() => {
    switch (truecallerLoginStatus) {
      case 'truecaller_skip':
        analytics.trigger(EVENT_MAP.TRUECALLER_SKIPPED, {})
        break
      case 'success':
        analytics.trigger(EVENT_MAP.TRUECALLER_LOGIN, {})
        break
      default:
        break
    }
  }, [truecallerLoginStatus])

  return {
    state: {
      isTruecallerPresent,
      isTruecallerEnabled: isTruecallerEnabled && isFeatureSupported,
      isPollingInProgress,
      loginRejectedOrFailed,
    },
    actions: {triggerTruecallerLogin},
  }
}

export {useTruecallerLogin}
