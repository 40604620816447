import styled from 'styled-components'

export const ProductCardV2Container = styled.a<{
  outOfStock: boolean
  showImageBorder: boolean
  productImageAspectRatio: number
}>`
  display: flex;
  flex-direction: column;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  height: 100%;

  .img-container {
    display: flex;
    margin: ${(props) => (props.showImageBorder ? 'var(--dls-size-8)' : '0px')};
    border-radius: var(--dls-size-8);

    border: ${(props) =>
      props.showImageBorder
        ? '0.5px solid var(--dls-divider-light-color)'
        : ''};
    overflow: hidden;
    position: relative;
  }

  .product-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: ${(props) => props.productImageAspectRatio};
    opacity: ${(props) => (props.outOfStock ? 0.4 : 1)};
  }

  .product-info {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: ${(props) => (props.outOfStock ? 0.4 : 1)};
  }

  .rating-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dls-black-color);
    font-family: var(--brand-font-family-text);
    line-height: 1;
    position: absolute;
    background-color: var(--brand-light-bg-color);
    padding: var(--dls-size-4) var(--dls-size-8);
    top: var(--dls-size-8);
    left: var(--dls-size-8);
    overflow: hidden;
    border-radius: var(--dls-size-4);
  }

  .rating {
    display: flex;

    > * {
      height: 12px;
      width: 12px;
    }
  }

  .ratinginfo {
    display: flex;
    align-items: center;
    margin-left: 2px;
    line-height: 1;
    text-align: center;
    color: var(--dls-secondary-text-colors);
  }

  .for-label {
    color: var(--dls-brand-primary-color-400);
  }

  .product-name-container {
    font-style: normal;
    color: var(--brand-primary-text-color);
    margin-top: var(--dls-size-4);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-space;
  }

  .product-name {
    font-size: 12px;
  }

  .price-ctn {
    display: flex;
    align-items: baseline;
    margin-top: 2px;
  }

  .price {
    color: var(--brand-primary-text-color);
    font-size: 12px;
  }

  .discounted-price {
    text-decoration: line-through;
    color: var(--dls-disabled-text-colors);
    margin-left: 5px;
  }

  .pricing {
    margin-top: auto;
  }

  .whyThisWorks {
    margin-bottom: 0px;
    line-height: 1;

    .why-this-work-item {
      display: flex;
    }
    .key {
      display: inline-block;
      margin-right: var(--dls-size-4);
      color: var(--dls-brand-primary-color-500);
    }
    .text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .button-container {
    padding: var(--dls-size-8);
  }

  .qty-button-container {
    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-brand-primary-color-500);
    height: var(--dls-size-40);
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--dls-brand-primary-color-100);
  }

  .qty-label-container {
    flex-shrink: 1;
    padding: 0 var(--dls-size-8);
  }

  .qty-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: 100%;
    flex: 1;
    display: flex;
  }

  .qty-btn:disabled {
    opacity: 0.3;
  }

  .remove-btn {
    justify-content: flex-start;
    align-items: center;
    padding-left: var(--dls-size-8);
  }

  .add-btn {
    justify-content: flex-end;
    align-items: center;
    padding-right: var(--dls-size-8);
  }

  .qty-label {
    color: var(--dls-brand-primary-color-500);
  }

  .atc-btn {
    margin-top: auto;
    border-radius: var(--dls-size-8);
    outline: none;
    border: 1px solid var(--dls-brand-primary-color-500);
    background-color: var(--brand-primary-white-color);
    white-space: nowrap;
    transition: all 0.5s ease;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--dls-brand-primary-color-500);
    overflow: hidden;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;
    padding: var(--dls-size-8);
    height: var(--dls-size-40);
  }

  .atc-label {
    color: var(--dls-brand-primary-color-500);
  }

  .price-top-container {
    display: flex;
  }

  @media screen and (min-width: 768px) {
    border: 1px solid var(--dls-divider-light-color);
    border-radius: var(--dls-size-16);
    .product-name {
      font-size: 18px;
    }

    .price {
      font-size: 18px;
    }

    .product-info {
      margin-top: var(--dls-size-8);
    }

    .atc-btn,
    .qty-button-container {
      height: var(--dls-size-48);
    }

    .img-container {
      margin: 0;
      border: 0px;
    }
  }
`
