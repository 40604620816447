import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import React, {useCallback, useMemo, useState} from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {MediaSliderContainer, CarouselDots} from './MediaSlider.styles'
import {MediaSliderProps, MediaWithCta} from './MediaSlider.interface'
import {useGenericActions} from '../../hooks'
import DesktopMediaSlider from './Desktop/DesktopMediaSlider'
import MobileMediaSlider from './Mobile/MobileMediaSlider'
import {useDeviceTypeContext} from '@web-components'
import {isEmpty} from 'lodash'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function MediaSlider(props: BaseWidgetProps<MediaSliderProps>) {
  const {widgetData, id, header, layout, type} = props || {}
  const {
    showDots,
    leftSpace = false,
    isMobileView = false,
    images = [],
  } = widgetData || {}
  const {isMobile = false} = useDeviceTypeContext()
  const {genericAction} = useGenericActions()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [activeSlide, setActiveSlide] = useState(0)

  const onSlideChange = useCallback((index: number) => {
    setActiveSlide(index)
  }, [])

  const onImageClick = useCallback(
    (cta: GenericAction) => {
      genericAction(interceptAndEnhanceAction(cta, id))
    },
    [genericAction, id]
  )

  const showMobileView = useMemo(() => {
    return isMobile || isMobileView
  }, [isMobile, isMobileView])

  if (isEmpty(images)) {
    return null
  }

  return (
    <BaseWidgetComponent
      layout={{desktopFullWidth: true, ...layout}}
      widgetType={type}
      widgetId={id}
    >
      <BaseWidgetComponent.Header header={header} />
      <MediaSliderContainer data-is-leftspace={leftSpace}>
        {!showMobileView ? (
          <>
            <DesktopMediaSlider
              widgetData={widgetData}
              actions={{onImageClick}}
              state={{currentSlide, setCurrentSlide}}
            />
            {showDots && (
              <div className="dots-container">
                {widgetData?.images.map((imageData: MediaWithCta, index) => (
                  <CarouselDots
                    key={index}
                    active={currentSlide === index}
                    onClick={() => setCurrentSlide(index)}
                  ></CarouselDots>
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            <MobileMediaSlider
              actions={{onSlideChange, onImageClick}}
              state={{currentSlide, setCurrentSlide}}
              widgetData={widgetData}
            />
            {showDots && (
              <div className="dots">
                {widgetData?.images.map((imageData: MediaWithCta, index) => (
                  <div
                    className="dot"
                    key={index}
                    data-is-active={activeSlide === index}
                  ></div>
                ))}
              </div>
            )}
          </>
        )}
      </MediaSliderContainer>
    </BaseWidgetComponent>
  )
}

export default MediaSlider
