import styled from 'styled-components'

export const StyledAppDownloadWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: 6;
  font-family: var(--brand-font-family-text);
  font-weight: -var(--brand-font-weight-400);
  font-size: 14px;
  outline: none;
  border: none;
  padding-right: 0;
  background-color: ${({backgroundColor}) =>
    backgroundColor ? backgroundColor : ' #2F815C'};
  transition: all 0.4 ease-out;
  top: -400px;

  .container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 110px;
  }

  .app-download-bottom-sheet-content-wrap {
    background-color: ${({backgroundColor}) =>
      backgroundColor ? backgroundColor : ' #2F815C'};
    max-height: 90%;
    overflow: scroll;
  }
  &.open {
    top: 0;
  }

  .app-download-bottom-sheet-modal-hide {
    display: none;
  }

  .title-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    padding-bottom: 10px;
  }

  .title {
    color: var(--Light-Brand-Primary-Light-Primary-500, #2f815c);
    /* Mobile/Heading/Sm/Bold */
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
  }

  .divTitle {
    color: var(--Light-Text-Light-Primary, #212121);
    /* Mobile/Body base/Bold */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    text-transform: capitalize;
  }

  .icon-container {
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }
  .icon {
    width: 40px;
    height: 36px;
  }

  .subtitle {
    color: var(--Light-Text-Light-Primary, #212121);
    /* Mobile/Body base/Regular */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin-top: -20px;
  }

  .parent-options {
    display: flex;
  }

  .parent-options label {
    flex: 1;
  }

  .accordion-item {
    border-radius: 16px;
    background-color: var(--Light-Brand-Secondary-Light-Secondary-200, #fafaf6);
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .rotateArrow {
    rotate: 180deg;
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .childGender {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  .child {
    flex: 1;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .childImg {
    border-radius: 50%;
    padding: 3px;
    width: 56px;
    border: 1px solid #e3e3e3;
    margin-right: 8px;
  }

  .checkedChild {
    box-shadow: 0 0 0 2px #2f815c;
  }

  .genderContainer {
    display: flex;
    margin-top: 12px;
  }

  .childDiv {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
  }

  .childDiv input {
    width: 100%;
    min-height: 48px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--Neutrals-Text-field-stroke_default, #c4c4c4);
    background: var(--Neutrals-White, #fff);
    outline: none;
    outline-color: red;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .divTitle.height {
    display: block;
    margin: 12px 0 8px 0;
  }

  .childHeight {
    display: flex;
    gap: 5px;
  }

  .childHeight select,
  .childHeight input {
    min-height: 48px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--Neutrals-Text-field-stroke_default, #c4c4c4);
    background: var(--Neutrals-White, #fff);
    outline: none;
  }

  .childHeight input {
    flex: 1;
    width: 110px;
  }

  .heightMeasure {
    width: 88px;
  }

  .sm-text {
    color: var(--Light-Text-Light-Primary, #212121);
    text-align: center;
    /* Mobile/Body base/Regular */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }

  .parentSelector {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .parentOption {
    width: 18px;
    height: 18px;
    margin-top: 4px;
  }

  .addChild {
    border-radius: 8px;
    border: 1px solid var(--Light-Brand-Primary-Light-Primary-500, #2f815c);
    align-self: center;
    color: var(--Light-Brand-Primary-Light-Primary-500, #2f815c);
    /* Mobile/CTA/CTA Lable (small) */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    background-color: inherit;
    padding: 10px 16px;
  }

  .accordioin-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .focused-accordion {
    border: 1px solid red;
  }
  .submitBtn {
    width: 100%;
    border-radius: 8px;
    color: #fff;
    align-self: center;
    background-color: var(--Light-Brand-Primary-Light-Primary-500, #2f815c);
    /* Mobile/CTA/CTA Lable (small) */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    border: none;
    padding: 16px;
  }

  .childDiv .checkbox {
    width: 14px;
    min-height: 14px;
  }

  .updateContainer {
    display: flex;
    align-items: center;
  }

  .updateContainer:nth-of-type(2) {
    margin-top: 4px;
  }

  .progressText {
    font-size: 14px;
    padding: 8px 16px;
    background: #e7f3f1;
    border-radius: 8px;
    text-align: center;
  }

  .btnDisabled {
    opacity: 0.6;
  }

  .buttonContainer {
    background-color: #fff;
    position: fixed;
    padding: 0 16px 16px;
    bottom: 0px;
    width: 100%;
    margin-left: -20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 99;
  }

  .headerContainer {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .headerContainer img {
    width: 40px;
    height: 40px;
  }
`
export const ToastWrapper = styled.div`
  position: fixed;
  top: 3%;
  padding: 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: #ffc820;
  font-family: var(--brand-primary-font);
  transform: translate(60%, 10px);
`
