export const processPageViews = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    page: window.location.href,
    hash: window.location.hash,
  }

  return dataToReturn
}
