import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useGenericActions} from '../../hooks'
import {IApiResponse} from './MiniAssessment.interface'

export const ALL_OF_THE_ABOVE = 'All of the above' || 'Both'
export const NONE_OF_THE_ABOVE = 'None of the above'

function useMiniAssessment(props: any) {
  const {action} = props || {}
  const {params} = action || {}
  const {endpointBody} = params

  const [currentQuestionData, setCurrentQuestionData] =
    useState<null | IApiResponse>()
  const [selectedIds, setSelectedIds] = useState<any[]>([])
  const [pdpData, setPdpData] = useState<any>()
  const [isFetchingPdpData, setIsFetchingPdpData] = useState(false)

  const {genericAction} = useGenericActions()

  const requestBody = useRef(endpointBody)
  const initialCallMade = useRef(false)

  const multipleSelect = useMemo(() => {
    return currentQuestionData?.input === 'checkbox'
  }, [currentQuestionData?.input])

  const isFormComplete = useMemo(() => {
    return currentQuestionData?.submitForm
  }, [currentQuestionData?.submitForm])

  const isNextButtonDisabled = useMemo(() => {
    return selectedIds.length === 0
  }, [selectedIds.length])

  const normalizedPdpData = useMemo(() => {
    if (!pdpData) {
      return {}
    }
    const {prod_img = '', productInfo, growthLanding} = pdpData
    const {
      name = '',
      actualPrice = 0,
      discountedPrice = '',
      priceLabel = '',
      discountedPriceLabel = '',
      urlKey = '',
      discountText = '',
    } = productInfo || {}
    const {maData} = growthLanding || {}
    const {
      loadingCompleted = 'Recommended product for you',
      finalBtnText = 'View Product',
    } = maData || {}

    return {
      image: prod_img,
      name,
      actualPrice,
      discountedPrice,
      priceLabel,
      discountedPriceLabel,
      urlKey,
      recommendedLabel: loadingCompleted,
      viewProductLabel: finalBtnText,
      discountText,
    }
  }, [pdpData])

  const updateRequestBody = useCallback(
    (selectedIds: string[] | number[]) => {
      requestBody.current = {
        ...requestBody.current,
        questionNumber: currentQuestionData?.questionNumber ?? 1,
        question: currentQuestionData?.question,
        selectedAnswers: {
          ...currentQuestionData?.selectedAnswers,
          [currentQuestionData?.question || '']: selectedIds,
        },
        formId: currentQuestionData?.finalFormId,
      }
    },
    [
      currentQuestionData?.finalFormId,
      currentQuestionData?.question,
      currentQuestionData?.questionNumber,
      currentQuestionData?.selectedAnswers,
    ]
  )

  const onValueChange = useCallback(
    (value) => {
      if (!multipleSelect) {
        return setSelectedIds([value])
      }

      if (value.text === NONE_OF_THE_ABOVE) {
        return setSelectedIds([value])
      }

      const exists = selectedIds.find((item) => item?.id === value?.id)

      if (exists) {
        if (exists.text === ALL_OF_THE_ABOVE) {
          return setSelectedIds([])
        }

        return setSelectedIds(
          selectedIds.filter(
            (item) => item.id !== value.id && item.text !== ALL_OF_THE_ABOVE
          )
        )
      }

      if (value.text === ALL_OF_THE_ABOVE) {
        return setSelectedIds((selectedIds) => selectedIds)
      }

      return setSelectedIds(
        selectedIds
          .concat(value)
          .filter((item) => item.text !== NONE_OF_THE_ABOVE)
      )
    },
    [multipleSelect, selectedIds]
  )

  const handlePdpFetching = useCallback(
    async (finalReport) => {
      if (!finalReport) {
        return
      }

      const urlkey = finalReport?.actionData?.productKey
      try {
        setIsFetchingPdpData(true)
        const pdpData = await genericAction({
          actionName: 'ENDPOINT',
          params: {
            endpointType: 'GET',
            endpointUrl: `page/mwsc/v2/product/${urlkey}`,
            showToast: false,
          },
        })

        setPdpData(pdpData)
      } catch (err) {
      } finally {
        setIsFetchingPdpData(false)
      }
    },
    [genericAction]
  )

  const onViewProductClick = useCallback(
    (urlKey: string) => {
      genericAction({
        actionName: 'SPA_LINK',
        params: {
          link: `/dp/${urlKey}`,
        },
      })
    },
    [genericAction]
  )

  const onNextPressed = useCallback(async () => {
    if (initialCallMade.current) {
      updateRequestBody(selectedIds.map((item) => item.id))
    }
    const data = await genericAction({
      ...action,
      params: {
        ...action.params,
        endpointBody: requestBody.current,
        triggerAnalytics: !initialCallMade.current ? false : true,
      },
    })

    if (data?.data) {
      setCurrentQuestionData(data.data)
      setSelectedIds([])

      if (data.data.submitForm) {
        handlePdpFetching(data.data?.finalReport)
      }
    }

    initialCallMade.current = true
  }, [action, genericAction, handlePdpFetching, selectedIds, updateRequestBody])

  const onRetakeTest = useCallback(() => {
    requestBody.current = endpointBody
    initialCallMade.current = false
    setPdpData(null)
    setSelectedIds([])
    onNextPressed()
  }, [endpointBody, onNextPressed])

  useEffect(() => {
    onNextPressed()
  }, [])

  return [
    {
      currentQuestionData,
      selectedIds,
      isFormComplete,
      normalizedPdpData,
      isNextButtonDisabled,
      isFetchingPdpData,
    },
    {onValueChange, onNextPressed, onRetakeTest, onViewProductClick},
  ] as const
}

export default useMiniAssessment
