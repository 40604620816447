import React from 'react'
import {ConsultStepsCardContainer} from './ConsultStepsCard.styles'

function ConsultStepsCard(props) {
  const {state = {}} = props

  const {title = '', steps = []} = state

  if (!steps.length) {
    return null
  }

  return (
    <ConsultStepsCardContainer data-section="getCustomerInfo">
      <div className="card-title">{title}</div>

      <div className="steps-container">
        {steps.map((step) => (
          <div className="step" index={step.step}>
            <span className="step-number" key={step.step}>
              {step.stepNumber}
            </span>
            <div className="step-data">
              <div className="step-label">
                {step.step}
                <img src={step.icon} alt="" loading="lazy" />
              </div>
              <div className="step-description">{step.description}</div>
            </div>
          </div>
        ))}
      </div>
    </ConsultStepsCardContainer>
  )
}

export default ConsultStepsCard
