export const getEncodedUrl = (products = []) => {
  let stringToEncode = ''
  products.forEach((product, index) => {
    if (index !== products.length - 1) {
      stringToEncode = stringToEncode + `${product},`
      return
    }

    stringToEncode = stringToEncode + `${product}`
  })
  return encodeURIComponent(stringToEncode)
}
