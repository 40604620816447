import React from 'react'
import { LoadingState } from '../../shared/src/ComponentsV2/common'
import { StyledLoading } from './PageLoading.styles'
import Responsive from '../../shared/src/ComponentsV2/HOC/Responsive'

function PDPSkeleton({ isMobile = false, }) {
    return (
        <>
            {isMobile ? (<StyledLoading>
                <LoadingState cardHeight={16} numberOfCards={1} />
                <LoadingState cardHeight={280} />
                <LoadingState cardHeight={16} />
                <LoadingState cardHeight={50} />
                <div className="left">
                    <LoadingState cardHeight={28} />
                    <LoadingState cardHeight={14} />
                    <LoadingState cardHeight={16} />
                </div>
                <LoadingState cardHeight={42} />
                <LoadingState cardHeight={44} />
                <LoadingState cardHeight={346} />
            </StyledLoading>)
                : (<StyledLoading>
                    <LoadingState cardHeight={22} numberOfCards={1} />
                    <div className='horizontal-container'>
                        <div className="left">
                            <div className='banner-image'>
                                <LoadingState cardHeight={375} />
                            </div>

                        </div>
                        <div className="right">
                            <LoadingState cardHeight={56} />
                            <LoadingState cardHeight={74} />
                            <div className="left">
                                <LoadingState cardHeight={55} />
                                <LoadingState cardHeight={23} />
                                <LoadingState cardHeight={27} />
                            </div>
                            <LoadingState cardHeight={42} />
                            <LoadingState cardHeight={50} />
                            <LoadingState cardHeight={346} />
                            <div className='horizontal-container'>
                                <div className="left">
                                    <LoadingState cardHeight={67} />
                                </div>
                                <div className="right">
                                    <LoadingState cardHeight={67} />
                                </div>
                            </div>
                            <LoadingState cardHeight={140} />
                        </div>
                    </div>
                    <div className="left-half">
                        <LoadingState cardHeight={28} />
                    </div>
                    <div className='horizontal-container'>
                        <div className='left'>
                            <LoadingState cardHeight={140} />
                            <LoadingState cardHeight={140} />
                        </div>
                        <div className='right'>
                            <LoadingState cardHeight={140} />
                            <LoadingState cardHeight={140} />
                        </div>
                    </div>
                </StyledLoading>)
            }
        </>

    )
}
export default Responsive(PDPSkeleton)