import styled from 'styled-components'

export const HeroBannerContainer = styled.a`
  text-decoration: none;
  position: relative;

  .hero-banner-image {
    width: 100%;
    height: auto;
  }
  display: flex;
  width: 100%;

  .hero-banner-button-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: var(--dls-size-16);
  }
  .hero-banner-button {
    width: 100%;
    background: var(--dls-white-color);
    color: var(--dls-brand-primary-color-500);
    border: 1px solid var(--dls-brand-primary-color-500);
    border-radius: var(--dls-size-8);
    padding: var(--dls-size-8);
    max-width: 500px;
    display: flex;
    align-items: center;
    gap: var(--dls-size-8);
    justify-content: center;
  }
`
