import {useCallback} from 'react'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'

// hook responsible for tracking events related to self diagnosis
const useSelfDiagnosisEvents = () => {
  const triggerCTAClickedEvent = useCallback((data = {}) => {
    analyticsTrigger(EVENT_MAP.CTA_CLICKED, {...data})
  }, [])
  const triggerSelfDiagnosisNudgeDismissEvent = useCallback((data) => {
    analyticsTrigger(EVENT_MAP.PDP_SELF_DIAGNOSIS_FLOATING_ISLAND_CLOSED, {
      ...data,
    })
  }, [])

  const triggerSelfDiagnosisCTAViewEvent = useCallback((data = {}) => {
    analyticsTrigger(EVENT_MAP.CTA_VIEWED, {...data})
  }, [])

  return [
    {
      triggerCTAClickedEvent,
      triggerSelfDiagnosisNudgeDismissEvent,
      triggerSelfDiagnosisCTAViewEvent,
    },
  ]
}

export default useSelfDiagnosisEvents
