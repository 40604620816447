import styled from 'styled-components'

export const ProductsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--dls-size-8);

  @media screen and (min-width: 786px) {
    display: flex;
    flex-wrap: wrap;
  }
`
