export const EXPERIMENTS_FEATURE_FLAGS = {
  PDP_ATC_BOTTOM_SHEET: 'PDP_ATC_BOTTOM_SHEET',
  PDP_BUY_NOW_BOTTOM_SHEET: 'PDP_BUY_NOW_BOTTOM_SHEET',
  HIDE_PDP_SIDE_CART: 'HIDE_PDP_SIDE_CART',
  SHOW_PDP_OFFER_TAG: 'SHOW_PDP_OFFER_TAG',
  PDP_SNAP_MINT_ABOVE_BUY_NOW: 'PDP_SNAP_MINT_ABOVE_BUY_NOW',
  PDP_STICKY_BUY_NOW_TEXT: 'PDP_STICKY_BUY_NOW_TEXT',
  SHOW_ATC_BUTTON_SWITCHES: 'SHOW_ATC_BUTTON_SWITCHES',
  HIDE_ATC_BUTTON_SWITCHES: 'HIDE_ATC_BUTTON_SWITCHES',
  SHOW_WALLET_CALLOUT_CART: 'SHOW_WALLET_CALLOUT_CART',
  HIDE_WALLET_CALLOUT_CART: 'HIDE_WALLET_CALLOUT_CART',
}
