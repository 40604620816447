type THeadingLevel = 1 | 2 | 3 | 4 | 5 | 6

export const HEADING_TAGS_MAPPING: Record<THeadingLevel, React.ElementType> = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
}
