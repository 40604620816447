import React, {useMemo} from 'react'
import OptimizedImage from '../OptimizedImage'
import {StyledWrapper} from './CartWalletCallout.styles'

function CartWalletCallout(props) {
  const {text = '', image = '', show = false, walletPoints} = props.state || {}

  const textToDisplay = useMemo(() => {
    if (typeof text === 'string' && text.indexOf('__WALLET_POINTS__') > -1)
      return text.replace('__WALLET_POINTS__', walletPoints)
    return ''
  }, [text, walletPoints])

  if (!show || !walletPoints) return null
  return (
    <StyledWrapper>
      <div className="imageContainer">
        <OptimizedImage
          source={image}
          aspectWidth="40"
          aspectHeight="40"
          desktopViewWidth="HALF"
          mobileViewWidth="HALF"
          altText="wallet"
          loading="lazy"
          customClassName="walletImage"
        />
      </div>
      <label
        className="walletText"
        dangerouslySetInnerHTML={{__html: textToDisplay}}
      ></label>
    </StyledWrapper>
  )
}

export default CartWalletCallout
