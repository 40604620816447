import styled from 'styled-components'

export const GenericStyledLoading = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  
`
