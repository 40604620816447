import React from 'react'

import {CustomerTestimonialCardContainer} from './CustomerTestimonialCard2.styles'

function CustomerTestimonialCard2(props) {
  const {state = {}} = props

  const {
    image = '',
    testimonial = '',
    name = '',
    rating = '',
    verifiedIcon = '',
    starIcon = '',
    className = '',
  } = state

  return (
    <CustomerTestimonialCardContainer className={className}>
      <div className="customer-image-container">
        <img
          src={image}
          alt="face of the customer"
          className="customer-image"
          loading="lazy"
        />
      </div>
      <div className="testimonial-content-container">
        <p className="testimonial">{testimonial}</p>
        <div className="name-and-rating">
          <div className="name-container">
            <span className="name">{name}</span>
            <img
              src={verifiedIcon}
              alt="verified icon"
              className="verified-icon"
            />
          </div>
          <div className="rating-container">
            <img src={starIcon} alt="star icon" className="star-icon" />
            <span className="rating">{rating}</span>
          </div>
        </div>
      </div>
    </CustomerTestimonialCardContainer>
  )
}

export default CustomerTestimonialCard2
