import styled from 'styled-components'

export const CalloutWithImageContainer = styled.div`
  padding: 12px var(--dls-size-16);
  background-color: var(--dls-success-color-100);
  display: flex;
  align-items: center;

  .callout-image-container {
    margin-right: 16px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .callout-text {
    color: var(--dls-success-color-500);
  }
`
