import React from 'react'

function WarningIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.99989 5.33333V7.99999M7.99989 10.6667H8.00656M6.85989 1.90666L1.21323 11.3333C1.0968 11.5349 1.0352 11.7635 1.03455 11.9963C1.0339 12.2292 1.09422 12.4581 1.20951 12.6603C1.3248 12.8626 1.49104 13.0312 1.6917 13.1492C1.89235 13.2673 2.12042 13.3308 2.35323 13.3333H13.6466C13.8794 13.3308 14.1074 13.2673 14.3081 13.1492C14.5087 13.0312 14.675 12.8626 14.7903 12.6603C14.9056 12.4581 14.9659 12.2292 14.9652 11.9963C14.9646 11.7635 14.903 11.5349 14.7866 11.3333L9.13989 1.90666C9.02105 1.71073 8.85371 1.54874 8.65402 1.43632C8.45434 1.32389 8.22905 1.26483 7.99989 1.26483C7.77074 1.26483 7.54545 1.32389 7.34576 1.43632C7.14608 1.54874 6.97874 1.71073 6.85989 1.90666Z"
        stroke="#EB8D03"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default WarningIcon
