import React from 'react'

import {HEADING_TAGS_MAPPING} from '../../../constants/headingTagsMapping'
import {TypographyWrapper} from './Typography.styles'
import {ITypography} from './Typography.types'

const Typography: React.FC<ITypography> = (props) => {
  const {
    variant = 'body-base-regular',
    customClassName = '',
    headingLevel,
    children = '',
    testId = '',
    ...rest
  } = props

  return (
    <TypographyWrapper
      className={`${variant} ${customClassName}`}
      as={headingLevel ? HEADING_TAGS_MAPPING[headingLevel] : ''}
      data-testid={testId}
      {...rest}
    >
      {children}
    </TypographyWrapper>
  )
}

export default Typography
