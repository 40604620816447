import {useContext, createContext, useMemo} from 'react'

export const CartLoadingContext = createContext({
  isCartUpdating: false,
  itemBeingAddedToCart: '',
})

function CartLoadingContextProvider({
  children,
  isCartUpdating,
  itemBeingAddedToCart,
}) {
  const contextValue = useMemo(
    () => ({
      isCartUpdating,
      itemBeingAddedToCart,
    }),
    [isCartUpdating, itemBeingAddedToCart]
  )

  return (
    <CartLoadingContext.Provider value={contextValue}>
      {children}
    </CartLoadingContext.Provider>
  )
}

export default CartLoadingContextProvider

/**
 * Hook to be mostly used to show loading state in
 * Add to cart CTA of product card
 * @returns {{
 *  isCartUpdating: boolean;
 *  itemBeingAddedToCart: string
 *  }}
 */
export const useCartLoadingContext = () => {
  const {isCartUpdating, itemBeingAddedToCart} = useContext(CartLoadingContext)

  return {
    isCartUpdating,
    itemBeingAddedToCart,
  }
}
