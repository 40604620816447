import React from 'react'

import {SVGContainer} from '../DirectAssesment.styles'

function ClockIcon(props) {
  const {className, style, category = ''} = props

  return (
    <SVGContainer
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      style={style}
      className={className}
      data-category={category}
    >
      <path
        d="M16.335 9.70898C16.955 8.96898 16.955 7.88898 16.335 7.14898L15.935 6.66898C15.595 6.25898 15.435 5.73898 15.475 5.20898L15.525 4.58898C15.615 3.59898 14.965 2.69898 13.995 2.46898L13.575 2.36898C13.035 2.23898 12.565 1.88898 12.295 1.40898L12.075 1.01898C11.565 0.118977 10.455 -0.251023 9.515 0.178977L9.215 0.308977C8.695 0.538977 8.095 0.538977 7.575 0.308977L7.275 0.178977C6.335 -0.241023 5.225 0.118977 4.715 1.01898L4.495 1.39898C4.225 1.88898 3.755 2.22898 3.215 2.35898L2.795 2.45898C1.825 2.68898 1.175 3.58898 1.265 4.57898L1.315 5.20898C1.365 5.73898 1.195 6.25898 0.865 6.65898L0.465 7.14898C-0.155 7.88898 -0.155 8.96898 0.465 9.70898L0.865 10.189C1.205 10.599 1.365 11.119 1.325 11.649L1.265 12.279C1.175 13.269 1.825 14.169 2.795 14.399L3.215 14.499C3.755 14.629 4.215 14.979 4.495 15.459L4.715 15.839C5.225 16.739 6.335 17.099 7.285 16.679L7.575 16.549C8.095 16.309 8.695 16.309 9.225 16.549L9.515 16.679C10.455 17.099 11.565 16.739 12.075 15.839L12.295 15.449C12.565 14.969 13.035 14.619 13.575 14.489L13.995 14.389C14.965 14.159 15.615 13.259 15.525 12.269L15.475 11.649C15.425 11.119 15.595 10.599 15.935 10.189L16.335 9.70898V9.70898Z"
        fill="#FBFBFB"
      />
      <path d="M5.53453 9.46899L6.85453 10.839C7.04453 11.029 7.30453 11.149 7.57453 11.149C7.84453 11.149 8.10453 11.039 8.29453 10.839L11.3845 7.63899C11.7645 7.23899 11.7545 6.60899 11.3545 6.22899C10.9545 5.84899 10.3245 5.85899 9.94453 6.25899L7.57453 8.70899L6.97453 8.07899C6.59453 7.67899 5.95453 7.66899 5.56453 8.04899C5.16453 8.42899 5.15453 9.06899 5.53453 9.45899V9.46899Z" />
    </SVGContainer>
  )
}

export default ClockIcon
