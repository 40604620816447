import styled from 'styled-components'

export const TilesWrapper = styled.div<{
  noOfColumns?: number
  scaleFullWidthDesktop?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .tiles-container {
    display: grid;
    grid-template-columns: ${({noOfColumns}) =>
      `repeat(${noOfColumns || 3}, 1fr)`};
    column-gap: var(--dls-size-8);
    row-gap: var(--dls-size-8);
    width: 100%;
  }
  .tile-container {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    height: 100%;
  }
  .tile-image {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
  .shadow {
    box-shadow: 0px 1px 4px 0px #88888833;
  }

  .text-container {
    text-align: center;
  }

  @media screen and (min-width: 786px) {
    justify-content: center;
    .tiles-container {
      display: grid;
      flex-wrap: wrap;
      gap: var(--dls-size-16);
      align-items: center;
      justify-content: center;
      width: ${(props) => (props.scaleFullWidthDesktop ? '100%' : 'auto')};
    }
    .tile-image {
      object-fit: contain;
      width: 100%;
      height: auto;
      max-width: ${(props) => (props.scaleFullWidthDesktop ? '100%' : '154px')};
    }
  }
`
