import React from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {InfoModalWrapper} from './InfoModal.styles'
import Modal from '../../../../ComponentsV2/common/Modal'

const InfoModal = (props) => {
  const {heading = '', steps = [], showInfoModal = false} = props.state || {}

  const {handleInfoModalClose = noop} = props.actions || {}

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <InfoModalWrapper>
      <Modal showModal={showInfoModal} onClose={handleInfoModalClose}>
        <div className="info-modal__content-container">
          <div className="info-modal__title">{heading}</div>
          {steps.map((step, index) => {
            const {title = '', image = '', alt = ''} = step
            return (
              <>
                <div className="info-modal__step-container" key={title}>
                  <img
                    src={image}
                    className="info-modal__step-image"
                    alt={alt}
                  />
                  <div className="info-modal__step-index">{index + 1}</div>
                  <div className="info-modal__step-title">{title}</div>
                </div>
              </>
            )
          })}
        </div>
      </Modal>
    </InfoModalWrapper>
  )
}

export default InfoModal
