export const getMappedProductsForFormCartNative = (products: string[]) => {
  if (!products) {
    return null
  }

  const mappedProducts: {[key: string]: number} = {}

  products.forEach((product) => {
    mappedProducts[product] = 1
  })

  return mappedProducts
}
