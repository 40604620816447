export const processLanguageChangedInDropdown = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = '', source, language} = data

  return {
    Category: category,
    Source: source,
    language,
  }
}
