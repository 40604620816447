import styled from 'styled-components'

export const ComparisonTableContainer = styled.div`
  width: 100%;
  padding: 0 var(--dls-size-16);

  .header-container {
    display: flex;
    gap: var(--dls-size-8);
    width: 100%;
  }

  .header-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px var(--dls-size-8);
    flex: 1;
    color: var(--dls-white-color);
  }

  .header-item-us {
    background-color: var(--dls-success-color-400);
  }

  .header-item-others {
    background-color: var(--dls-error-color-400);
  }

  .table-data-row {
    display: flex;
    gap: var(--dls-size-8);
    border-bottom: 1px solid var(--dls-divider-light-color);
  }

  .table-data-row-item {
    display: flex;
    align-items: center;
    gap: var(--dls-size-8);
    flex: 1;
    padding: 14px 0;
  }
`
