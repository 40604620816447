import React, {useCallback} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {SlotsContainer} from './Slots.styles'

function Slots(props) {
  const {slotsTitle = '', data = [], selectedSlot = ''} = props.state || {}
  const {handleSlotChanged = noop} = props.actions || {}

  const handleSelectedSlot = useCallback(
    (slot) => {
      handleSlotChanged(slot)
    },
    [handleSlotChanged]
  )

  if (isEmpty(data)) return null

  return (
    <SlotsContainer>
      <p className="appointment_card__optionTitleAlt">{slotsTitle}</p>
      <div className="slots">
        {data.map((slot) => (
          <div
            onClick={() => handleSelectedSlot(slot.data)}
            key={slot.id}
            className="slot"
            data-is-active={slot.data === selectedSlot}
          >
            {slot.data}
          </div>
        ))}
      </div>
    </SlotsContainer>
  )
}

export default Slots
