import DoctorIcon from './icons/DoctorIcon'
import StethoscopeIcon from './icons/StethoscopeIcon'
import ConsultationIcon from './icons/ConsultationIcon'

export const iconMapper = (key) => {
  switch (key) {
    case 'Daily Consultations':
      return <StethoscopeIcon />
    case 'Consultations':
      return <ConsultationIcon />
    case 'Doctors':
      return <DoctorIcon />
    default:
      return null
  }
}
