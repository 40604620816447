export const processHamburgerClick = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {}

  return dataToReturn
}

export const processNavMenuClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    link: data.link,
    location: data.location,
    text: data.text,
    URL: window.location.pathname,
  }

  return dataToReturn
}

export const processSearchClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    URL: window.location.href,
  }

  return dataToReturn
}

export const processSearchPdp = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    cardPosition = -1,
    query = '',
    tagPosition = -1,
    name = '',
    urlKey = '',
  } = data
  const dataToReturn = {
    name: name,
    url: urlKey,
    cardPosition,
    query,
    tagPosition,
  }

  return dataToReturn
}
