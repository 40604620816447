import styled from 'styled-components'

const StyledOutOfStockBanner = styled.div`
  position: absolute;
  top: ${({top}: {top?: number}) => (top ? top : 0)}px;
  width: 100%;
  background-color: var(--dls-highlighted-tag-background-color);
  padding: var(--dls-size-8) 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

export {StyledOutOfStockBanner}
