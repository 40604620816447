import React from 'react'

import {CustomerTestimonialsContainer} from './CustomerTestimonials.styles'
import {CustomerTestimonialCard2} from './CustomerTestimonialCard2'
import Typography from '../../../ComponentsV2/common/Typography/Typography'

function CustomerTestimonials(props) {
  const {state = {}} = props
  const {title = '', testimonials = [], className = ''} = state

  if (!testimonials.length) {
    return null
  }

  return (
    <CustomerTestimonialsContainer className={className}>
      <Typography
        text={title}
        variant="heading-sm-bold"
        customClassName="section-title"
        headingLevel={2}
      />
      <div className="testimonials-carousel">
        {testimonials.map((testimonial) => (
          <CustomerTestimonialCard2
            key={testimonial?.testimonial}
            state={testimonial}
          />
        ))}
      </div>
    </CustomerTestimonialsContainer>
  )
}

export default CustomerTestimonials
