import styled from 'styled-components'

export const GridContainer = styled.div`
  gap: var(--dls-size-8);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
`

export const Container = styled.div`
  width: 100%;
  flex: 1;
  overflow: hidden;
  .imageorAnimation {
    width: 100%;
    height: 100%;
  }
`
