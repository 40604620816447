import {useUpdateCart} from '@mosaic-wellness/redux-action-library'
import {useCallback} from 'react'
import useScreenName from '../useScreenName/useScreenName'

interface ICartUpdateData {
  sku: string
  quantity?: number
  getPrescription?: boolean
  isMiniCart?: boolean
  source?: string
}

export function useUpdateItemToCart() {
  const {updateCartItem} = useUpdateCart()
  const {screenName} = useScreenName()

  const addToCart = useCallback(
    ({
      sku,
      getPrescription,
      quantity = 1,
      isMiniCart = true,
      source = '',
    }: ICartUpdateData) => {
      updateCartItem({
        info: {
          action: 'add',
          qty: quantity,
          sku,
        },
        payload: {
          getPrescription,
          isMiniCart,
          source,
          screen: screenName,
        },
      })
    },
    [screenName, updateCartItem]
  )

  const removeFromCart = useCallback(
    ({
      sku,
      getPrescription,
      isMiniCart = true,
      quantity = 1,
    }: ICartUpdateData) => {
      updateCartItem({
        info: {
          action: 'remove',
          qty: quantity,
          sku,
        },
        payload: {
          getPrescription,
          isMiniCart,
          screen: screenName,
        },
      })
    },
    [screenName, updateCartItem]
  )

  const updateItemToCart = useCallback(
    ({
      sku,
      getPrescription,
      quantity = 1,
      isMiniCart = true,
    }: ICartUpdateData) => {
      updateCartItem({
        info: {
          action: 'update',
          qty: quantity,
          sku,
        },
        payload: {
          getPrescription,
          isMiniCart,
          screen: screenName,
        },
      })
    },
    [screenName, updateCartItem]
  )

  return {
    addToCart,
    removeFromCart,
    updateItemToCart,
  }
}
