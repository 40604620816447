import noop from 'lodash/noop'
import {useEffect} from 'react'

import {useIntersection} from '../../../../hooks/useIntersection'
import {ArrowHead} from '../../../common/Arrows/ArrowHead'
import {
  Box,
  Shimmer,
  ShimmerWrapper,
  StyledMediaCarouselContainer,
} from './ImageCarousalnew.styles'

/**
 * Returns CTA for ImageGallery Item
 * Action can be a SCROLL or LINK
 */
const MediaRibbon = ({
  data = null,
  actions: {onRibbonClick = noop, onRibbonCTALoad = noop} = {},
}) => {
  const {nodeRef, isIntersecting} = useIntersection()

  useEffect(() => {
    if (isIntersecting) {
      onRibbonCTALoad(data)
    }
  }, [data, isIntersecting, onRibbonCTALoad])

  const {label = ''} = data ?? {}

  if (!label || !data) {
    return null
  }

  const handleRibbonCTAClick = () => {
    const callout = document.querySelector('.app-download-incentive-wrapper')
    onRibbonClick({...data, offsetTop: callout ? -200 : 0})
  }

  return (
    <StyledMediaCarouselContainer
      ref={nodeRef}
      className="media-ribbon-cta"
      onClick={handleRibbonCTAClick}
    >
      <div className="media-ribbon-label">{label}</div>
      <ArrowHead direction="right" isSelected />
      <ShimmerWrapper>
        <Shimmer as={Box} />
      </ShimmerWrapper>
    </StyledMediaCarouselContainer>
  )
}

export default MediaRibbon
