import isEmpty from 'lodash/isEmpty'
import React from 'react'

import OptimizedImage from '../../../common/OptimizedImage'
import {BoostItem, StyledBoosts} from './Description.styles'

function Boosts({data}) {
  const {label = '', icons = []} = data || {}

  if (isEmpty(data)) {
    return null
  }

  return (
    <StyledBoosts>
      <div className="title">{label}</div>
      <ul className="items">
        {icons.map(({text, icon}) => {
          return (
            <BoostItem key={text}>
              {icon && (
                <OptimizedImage
                  source={icon}
                  aspectWidth="70"
                  aspectHeight="70"
                  desktopViewWidth="SMALL"
                  mobileViewWidth="SMALL"
                  altText={text}
                  loading="lazy"
                />
              )}
              <span className="boost-label">{text}</span>
            </BoostItem>
          )
        })}
      </ul>
    </StyledBoosts>
  )
}

export default Boosts
