export const processCommsSectionViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    stateViewed = '',
    futureAppointment,
    pendingAppointment,
    rxExists = false,
  } = data
  return {
    'Future Appointment': futureAppointment,
    'Pending Appointment': pendingAppointment,
    'Rx Exists': rxExists,
    Customer: dLayer.repeatUser,
    'State Viewed': stateViewed,
  }
}

export const processCommsConsultStateCard = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    type = '',
    appointmentReference = '',
    imageUploaded = false,
    basicDetailsUpdated = false,
    source = '',
    appointmentType = '',
    mode = '',
  } = data
  return {
    'User Consult State': type,
    'Appointment ID': appointmentReference,
    'Image Uploaded': imageUploaded,
    'Basic Info Submitted': basicDetailsUpdated,
    Source: source,
    Type: appointmentType,
    Mode: mode,
  }
}

export const processCommsConsultCardCTAClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    type = '',
    appointmentState = '',
    appointmentReference = '',
    imageUploaded = false,
    basicDetailsUpdated = false,
    source = '',
    appointmentType = '',
    mode = '',
  } = data
  return {
    'User Consult State': type,
    'Appointment State': appointmentState,
    'Appointment ID': appointmentReference,
    'Image Uploaded': imageUploaded,
    'Basic Info Submitted': basicDetailsUpdated,
    Source: source,
    Type: appointmentType,
    Mode: mode,
  }
}

export const processCommsEditApptClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    categoryTitle = '',
    appointmentReference = '',
    imageUploaded = false,
    basicDetailsUpdated = false,
    source = '',
    appointmentType = '',
    mode = '',
  } = data
  return {
    Category: categoryTitle,
    'Appointment ID': appointmentReference,
    'Image Uploaded': imageUploaded,
    'Basic Info Submitted': basicDetailsUpdated,
    Source: source,
    Type: appointmentType,
    Mode: mode,
  }
}

export const processCommsEditApptActionClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    type = '',
    appointmentState = '',
    categoryTitle = '',
    appointmentReference = '',
    imageUploaded = false,
    basicDetailsUpdated = false,
    source = '',
    appointmentType = '',
    mode = '',
    action = '',
  } = data
  return {
    'User Consult State': type,
    'Appointment State': appointmentState,
    Category: categoryTitle,
    'Appointment ID': appointmentReference,
    'Image Uploaded': imageUploaded,
    'Basic Info Submitted': basicDetailsUpdated,
    Source: source,
    Type: appointmentType,
    Mode: mode,
    action,
  }
}

export const processCommsRescheduleConfirmed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    appointmentState = '',
    categoryTitle = '',
    appointmentReference = '',
    imageUploaded = false,
    basicDetailsUpdated = false,
    source = '',
    appointmentType = '',
    mode = '',
  } = data
  return {
    'Appointment State': appointmentState,
    Category: categoryTitle,
    'Appointment ID': appointmentReference,
    'Image Uploaded': imageUploaded,
    'Basic Info Submitted': basicDetailsUpdated,
    Source: source,
    Type: appointmentType,
    Mode: mode,
  }
}

export const processCommsDaClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {disabled, stateViewed = '', entryPoint = ''} = data
  return {
    isLoggedIn: '',
    Customer: dLayer.repeatUser,
    'Entry point': entryPoint,
    'State Viewed': stateViewed,
    disabled,
  }
}

export const processCommsFormClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {disabled, stateViewed = '', entryPoint} = data
  return {
    Customer: dLayer.repeatUser,
    'Entry point': entryPoint,
    'State Viewed': stateViewed,
    disabled,
  }
}

export const processCommsConsultTrackerClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {disabled, stateViewed = '', entryPoint} = data
  return {
    isLoggedIn: '',
    Customer: dLayer.repeatUser,
    'Entry point': entryPoint,
    'State Viewed': stateViewed,
    disabled,
  }
}

export const processCommsPastPrescriptionsClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {disabled, stateViewed = '', entryPoint} = data
  return {
    isLoggedIn: '',
    Customer: dLayer.repeatUser,
    'Entry point': entryPoint,
    'State Viewed': stateViewed,
    disabled,
  }
}

export const processCommsPopUpViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {type, stateViewed} = data
  return {
    isLoggedIn: '',
    'Pop up Type': type,
    Customer: dLayer.repeatUser,
    'State Viewed': stateViewed,
  }
}

export const processCommsPopUpCTAClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {type, stateViewed} = data
  return {
    'Pop up Type': type,
    Customer: dLayer.repeatUser,
    'State Viewed': stateViewed,
  }
}

export const processCommsPopUpClosed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {type, stateViewed = ''} = data
  return {
    'Pop up Type': type,
    Customer: dLayer.repeatUser,
    'State Viewed': stateViewed,
  }
}
