import {useCallback} from 'react'
import {
  useCartStore,
  useConfigStore,
} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useSATracking() {
  const {analytics} = useConfigStore()
  const {cart} = useCartStore()

  const onSelfAssessmentViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_VIEWED, {})
  }, [analytics])

  const onSelfAssessmentStarted = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_STARTED, {})
  }, [analytics])

  const onSelfAssessmentOpen = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_CHAT_OPEN, {})
  }, [analytics])

  const onSelfAssessmentNameSubmitted = useCallback(
    (name) => {
      analytics.trigger(EVENT_MAP.SA_NAME_SUBMITTED, {name})
    },
    [analytics]
  )

  const onSelfAssessmentCategorySelectionViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_CATEGORY_VIEWED, {})
  }, [analytics])

  const onSelfAssessmentCategorySelected = useCallback(
    (category) => {
      analytics.trigger(EVENT_MAP.SA_CATEGORY_SUBMITTED, {category})
      analytics.push({
        form: {category},
      })
    },
    [analytics]
  )

  const onSelfAssessmentQuestion = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_QUESTION, data)
    },
    [analytics]
  )

  const onSelfAssessmentBasicDetails = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_BASIC_DETAILS, data)
    },
    [analytics]
  )

  const onSelfAssessmentQuestionUndo = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_QUESTION_UNDO, data)
    },
    [analytics]
  )

  const onSelfAssessmentSubmit = useCallback(
    (category) => {
      analytics.trigger(EVENT_MAP.SA_SUBMITTED, {category})
    },
    [analytics]
  )

  const onSelfAssessmentReportViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_REPORT_SEEN, {})
  }, [analytics])

  const onSelfAssessmentAppointmentViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_APPOINTMENT_VIEWED, {})
  }, [analytics])

  const onSelfAssessmentAppointmentSubmitted = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_APPOINTMENT_SUBMITTED, {})
  }, [analytics])

  const onSelfAssessmentAppointmentBooked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_APPOINTMENT_BOOKED, data)
    },
    [analytics]
  )

  const onSelfAssessmentPlanClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_PLAN_CLICKED, {})
  }, [analytics])

  const onSelfAssessmentPlanViewed = useCallback(
    ({selectLanguage = false}) => {
      analytics.trigger(EVENT_MAP.SA_PLAN_VIEWED, {})
      analytics.trigger(EVENT_MAP.CART_VIEWED, {selectLanguage, cart})
    },
    [analytics, cart]
  )

  const trackProductRemoved = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_PLAN_PRODUCT_REMOVE, data)
    },
    [analytics]
  )

  const trackAddToCart = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_PLAN_PRODUCT_ADD, data)
    },
    [analytics]
  )

  const trackProductInfoViewed = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_PLAN_PRODUCT_INFO_CARD_CLICKED, data)
    },
    [analytics]
  )

  const trackPdpViewedFromRxCart = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_PLAN_TO_PDP, data)
    },
    [analytics]
  )

  const trackCheckoutClicked = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_PLAN_ORDER_CLICKED, data)
    },
    [analytics]
  )

  const trackAppointmentCardShown = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_PLAN_APPOINTMENT_CARD_SHOWN, data)
    },
    [analytics]
  )

  const trackPrescriptionViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_PRESCRIPTION_VIEWED, {})
  }, [analytics])

  const pushReportToDataLayer = useCallback(
    (data) => {
      const dLayer = window?.customDataLayer
      const category = dLayer?.form?.category
      analytics.push({
        form: {
          category,
          score: data?.score,
          condition: data?.condition,
          products: data?.products,
          diagnosis: data?.diagnosis,
          diagnosisData: data?.diagnosisData,
          pricing: data?.pricing,
        },
      })
    },
    [analytics]
  )

  const onSaApiError = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.SA_API_ERROR, data)
    },
    [analytics]
  )

  return {
    onSelfAssessmentViewed,
    onSelfAssessmentStarted,
    onSelfAssessmentOpen,
    onSelfAssessmentNameSubmitted,
    onSelfAssessmentCategorySelectionViewed,
    onSelfAssessmentCategorySelected,
    onSelfAssessmentQuestion,
    onSelfAssessmentBasicDetails,
    onSelfAssessmentQuestionUndo,
    onSelfAssessmentSubmit,
    onSelfAssessmentReportViewed,
    onSelfAssessmentAppointmentViewed,
    onSelfAssessmentAppointmentSubmitted,
    onSelfAssessmentAppointmentBooked,
    trackProductRemoved,
    trackAddToCart,
    trackProductInfoViewed,
    trackPdpViewedFromRxCart,
    trackCheckoutClicked,
    trackAppointmentCardShown,
    pushReportToDataLayer,
    onSelfAssessmentPlanClicked,
    onSelfAssessmentPlanViewed,
    trackPrescriptionViewed,
    onSaApiError,
  }
}

export default useSATracking
