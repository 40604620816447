import styled from 'styled-components'

export const MediaWithHeaderCardContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header-container {
    display: flex;
    justify-content: space-between;
    gap: var(--dls-size-16);
  }

  .header-text {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .icon {
    flex-shrink: 0;
  }

  .icon-image {
    object-fit: contain;
  }

  &[data-uiType='SHADOWED'] {
    background-color: var(--dls-backgound-color-500);
    border: 0.5px solid var(--dls-divider-light-color);
    border-radius: var(--dls-size-8);
    box-shadow: 0px 65px 18px 0px rgba(0, 0, 0, 0);

    .header-container {
      padding: var(--dls-size-16) 10px;
    }
  }

  &[data-uiType='FLAT'] {
    .header-container {
      margin-bottom: 10px;
    }
  }

  &[data-uiType='SOLID'] {
    .header-container {
      padding: var(--dls-size-16) 10px;
    }
    border-radius: var(--dls-size-8);
  }

  .card-image-container {
    display: flex;
    margin-top: auto;
  }

  .card-image {
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  .top-callout {
    text-align: center;
    padding: 6px var(--dls-size-8);
    background-color: var(--dls-success-color-100);
    color: var(--dls-success-color-500);
    border-radius: var(--dls-size-4);
    margin-bottom: var(--dls-size-8);
  }
`
