import {createContext, useContext} from 'react'

import {CONSTANTS} from './constants'

interface ILocalisationContextType {
  [key: string]: string
}

const initialValue = {...CONSTANTS}

export const LocalisationContext =
  createContext<ILocalisationContextType>(initialValue)

export const LocalisationProvider = ({
  value,
  children,
}: {
  value: any
  children: React.ReactNode
}) => {
  return (
    <LocalisationContext.Provider value={value}>
      {children}
    </LocalisationContext.Provider>
  )
}

export const useLocalisationContext = () => useContext(LocalisationContext)
