import React, {useCallback} from 'react'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {ImageWithDescriptionCard} from './ImageWithDescriptionCard'
import {
  ArrowLeftWhite,
  ArrowRightWhite,
  GenericSlider,
  useDeviceTypeContext,
} from '@web-components'
import {StyledImageWithDescriptionSlider} from './ImageWithDescriptionSlider.styles'
import {useGenericActions} from '../../hooks'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

const ImageWithDescriptionCarousel = (props: BaseWidget) => {
  const {type, widgetData, header, layout, id} = props || {}
  const {team = [], slidesToShow, desktopSlidesToShow = 2} = widgetData || {}

  const {isMobile} = useDeviceTypeContext()

  const {genericAction} = useGenericActions()

  const onButtonClick = useCallback(
    (action: GenericAction) => {
      genericAction(interceptAndEnhanceAction(action, id))
    },
    [genericAction, id]
  )

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledImageWithDescriptionSlider>
        <GenericSlider
          noOfSlides={slidesToShow}
          noOfSlidesDesktop={desktopSlidesToShow}
          slideGap={20}
          isShowArrows={!isMobile}
          arrowPositionY={260}
          arrowGap={1120}
          aspectRatio={1}
          LeftArrowIcon={() => (
            <ArrowLeftWhite size={80} className="arrow-left-container" />
          )}
          RightArrowIcon={() => (
            <ArrowRightWhite size={80} className="arrow-right-container" />
          )}
        >
          {() => (
            <>
              {team.map((cardData, index) => (
                <ImageWithDescriptionCard
                  widgetData={{...cardData, onButtonClick}}
                  key={index}
                />
              ))}
            </>
          )}
        </GenericSlider>
      </StyledImageWithDescriptionSlider>
    </BaseWidgetComponent>
  )
}

export default ImageWithDescriptionCarousel
