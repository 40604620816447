import noop from 'lodash/noop'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'

function MobileNavArrows(props) {
  const {onClick = noop, disabled = false, ...rest} = props

  return (
    <button onClick={onClick} disabled={disabled} {...rest}>
      <span>
        <FontAwesomeIcon icon={faChevronRight} color="black" />
      </span>
    </button>
  )
}

export default MobileNavArrows
