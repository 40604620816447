import React, {useCallback} from 'react'
import {HeroBannerContainer} from './HeroBanner.styles'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {useGenericActions} from '../../hooks'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

interface HeroWidget {
  imageUrl: string
  altText: string
  buttonIcon?: string
  buttonText?: string
  aspectRatio: number
  action: GenericAction
}

const HeroBanner = ({
  widgetData,
  id,
  layout,
  header,
  type,
}: BaseWidgetProps<HeroWidget>) => {
  const {
    imageUrl = '',
    altText = '',
    buttonText = '',
    buttonIcon = '',
    aspectRatio = 1,
    action,
  } = widgetData
  const {genericAction} = useGenericActions()
  const targetLink = (action?.params?.path as string) || '#'
  const handleBannerClick: React.MouseEventHandler<HTMLAnchorElement> =
    useCallback(
      (event) => {
        event.preventDefault()
        if (action) {
          genericAction(interceptAndEnhanceAction(action, id))
        }
      },
      [action, genericAction, id]
    )

  if (!imageUrl) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type}>
      <BaseWidgetComponent.Header header={header} />
      <HeroBannerContainer href={targetLink} onClick={handleBannerClick}>
        <OptimizedImage
          source={imageUrl}
          aspectWidth={360}
          aspectHeight={360 / aspectRatio}
          desktopViewWidth="FULL"
          mobileViewWidth="FULL"
          altText={altText}
          loading="lazy"
          customClassName="hero-banner-image"
        />
        {buttonText && (
          <div className="hero-banner-button-container">
            <button className="hero-banner-button" onClick={handleBannerClick}>
              {buttonIcon && (
                <OptimizedImage
                  source={buttonIcon}
                  aspectHeight="13"
                  aspectWidth="13"
                  mobileViewWidth="TINY"
                />
              )}
              <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
                {buttonText}
              </Typography>
            </button>
          </div>
        )}
      </HeroBannerContainer>
    </BaseWidgetComponent>
  )
}

export default HeroBanner
