import {ChevronUp} from '@web-components'
import noop from 'lodash/noop'

interface MobileNavArrowsProps {
  onClick?: () => void
  disabled?: boolean
  [key: string]: any // Allow any other props
}

function MobileNavArrows(props: MobileNavArrowsProps) {
  const {onClick = noop, disabled = false, ...rest} = props

  return (
    <button onClick={onClick} disabled={disabled} {...rest}>
      <span>
        <ChevronUp />
      </span>
    </button>
  )
}

export default MobileNavArrows
