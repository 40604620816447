import styled from 'styled-components'

export const WebViewHeaderContainer = styled.div`
  padding: 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--brand-primary-white-color);
  z-index: 8;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid var(--dls-divider-light-color);

  .back-button {
    outline: 0;
    border: 0;
    background-color: transparent;
    color: var(--brand-primary-black-color);
    padding: 0px;
    height: 24px;
    width: 24px;
    &:focus {
      box-shadow: none !important;
    }
  }

  .header-title {
    font-size: 16px;
    line-height: 1;
    font-weight: var(--brand-font-weight-semiBold);
    margin-left: 8px;
    flex: 1;
  }
`
