export const processCommsDaViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {commsDaEntryPoint = 'Consult'} = dLayer

  return {
    'Entry Point': commsDaEntryPoint,
  }
}

export const processCommsDaCategorySelected = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = ''} = data
  const {commsDaEntryPoint = 'Consult'} = dLayer

  return {
    Category: category,
    'Entry Point': commsDaEntryPoint,
  }
}

export const processCommsDaQuestion = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {question = '', answer = ''} = data
  const {
    commsDaCategory: {category = ''},
  } = dLayer

  return {
    Question: question,
    Answer: answer,
    Category: category,
  }
}

export const processCommsDaLanguage = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {language = ''} = data
  const {
    commsDaCategory: {category = ''},
  } = dLayer

  return {
    Language: language,
    category: category,
  }
}

export const processCommsDaInstantSelected = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {iaSelected = 'No'} = data
  const {
    commsDaCategory: {category = ''},
    commsDaLanguage: {language = ''},
  } = dLayer

  return {
    Category: category,
    IA: iaSelected,
    Language: language,
  }
}

export const processCommsDaLanguageChanged = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {language} = data
  const {
    commsDaCategory: {category = ''},
  } = dLayer

  return {
    category: category,
    'Changed Language': language,
  }
}

export const processCommsDaBottomSheetOpen = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    commsDaCategory: {category = ''},
    commsDaLanguage: {language = ''},
  } = dLayer

  return {
    Language: language,
    Category: category,
  }
}

export const processCommsDaScheduleSubmit = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {slot = ''} = data
  const {
    commsDaCategory: {category = ''},
    commsDaLanguage: {language = ''},
  } = dLayer

  return {
    Language: language,
    Category: category,
    'Slot Selected': slot,
  }
}

export const processCommsDaImageUploadViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {appointmentId = '', appointmentDate = '', slot = '', phone = ''} = data
  const {
    commsDaCategory: {category = ''} = {},
    commsDaLanguage: {language = ''} = {},
    commsDaInstantSelected: {iaSelected = 'No'} = {},
  } = dLayer

  return {
    'Appointment Id': appointmentId,
    Category: category,
    Language: language,
    Date: appointmentDate,
    'Slot Selected': slot,
    'IA Booked': iaSelected,
    'Phone Number': phone,
  }
}

export const processCommsDaImageUploadInitiated = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    commsDaCategory: {category = ''} = {},
    commsDaLanguage: {language = ''} = {},
    commsDaInstantSelected: {iaSelected = 'No'} = {},
    commsDaAppointment: {
      appointmentId = '',
      appointmentDate = '',
      slot = '',
      phone = '',
    },
  } = dLayer

  return {
    'Appointment Id': appointmentId,
    Category: category,
    Language: language,
    Date: appointmentDate,
    'Slot Selected': slot,
    'IA Booked': iaSelected,
    'Phone Number': phone,
  }
}

export const processCommsDaImageUploadSubmitClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    commsDaCategory: {category = ''} = {},
    commsDaLanguage: {language = ''} = {},
    commsDaInstantSelected: {iaSelected = 'No'} = {},
    commsDaAppointment: {
      appointmentId = '',
      appointmentDate = '',
      slot = '',
      phone = '',
    },
  } = dLayer

  return {
    'Appointment Id': appointmentId,
    Category: category,
    Language: language,
    Date: appointmentDate,
    'Slot Selected': slot,
    'IA Booked': iaSelected,
    'Phone Number': phone,
  }
}

export const processCommsDaImageUploadSuccess = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    commsDaCategory: {category = ''} = {},
    commsDaLanguage: {language = ''} = {},
    commsDaInstantSelected: {iaSelected = 'No'} = {},
    commsDaAppointment: {
      appointmentId = '',
      appointmentDate = '',
      slot = '',
      phone = '',
    },
  } = dLayer

  return {
    'Appointment Id': appointmentId,
    Category: category,
    Language: language,
    Date: appointmentDate,
    'Slot Selected': slot,
    'IA Booked': iaSelected,
    'Phone Number': phone,
  }
}
