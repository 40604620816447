import React, {useMemo} from 'react'

import RatingBar from './RatingBar'
import {RatingBarsWrapper} from './RatingBars.styles'
import {IRatingBarsProps} from './RatingBars.types'

const RatingBars: React.FC<IRatingBarsProps> = (props) => {
  const {ratingsStats = [], isMobile = false} = props

  const totalRatingCount = useMemo(() => {
    return ratingsStats?.reduce((previous, rating) => {
      if (
        rating &&
        rating.numberOfReviews &&
        typeof rating.numberOfReviews == 'number'
      ) {
        previous += Math.abs(rating.numberOfReviews)
      }
      return previous
    }, 0)
  }, [ratingsStats])

  if (!ratingsStats || ratingsStats?.length <= 0) return null

  return (
    <RatingBarsWrapper>
      {ratingsStats.map((rating) => (
        <RatingBar
          ratingData={rating}
          key={rating?.rating}
          totalRatingCount={totalRatingCount}
          isMobile={isMobile}
        />
      ))}
    </RatingBarsWrapper>
  )
}

export default RatingBars
