import React from 'react'

const ErrorIcon = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="error"
        d="M9.5 14.7308C9.72883 14.7308 9.92067 14.6533 10.0755 14.4985C10.2303 14.3437 10.3077 14.1518 10.3077 13.923C10.3077 13.6942 10.2303 13.5023 10.0755 13.3475C9.92067 13.1928 9.72883 13.1155 9.5 13.1155C9.27117 13.1155 9.07933 13.1928 8.9245 13.3475C8.76967 13.5023 8.69225 13.6942 8.69225 13.923C8.69225 14.1518 8.76967 14.3437 8.9245 14.4985C9.07933 14.6533 9.27117 14.7308 9.5 14.7308ZM9.50025 11.077C9.71292 11.077 9.891 11.0051 10.0345 10.8613C10.1782 10.7176 10.25 10.5395 10.25 10.327V5.827C10.25 5.6145 10.1781 5.43633 10.0343 5.2925C9.89042 5.14883 9.71225 5.077 9.49975 5.077C9.28708 5.077 9.109 5.14883 8.9655 5.2925C8.82183 5.43633 8.75 5.6145 8.75 5.827V10.327C8.75 10.5395 8.82192 10.7176 8.96575 10.8613C9.10958 11.0051 9.28775 11.077 9.50025 11.077ZM9.50175 19.5C8.18775 19.5 6.95267 19.2507 5.7965 18.752C4.64033 18.2533 3.63467 17.5766 2.7795 16.7218C1.92433 15.8669 1.24725 14.8617 0.74825 13.706C0.249417 12.5503 0 11.3156 0 10.0017C0 8.68775 0.249333 7.45267 0.748 6.2965C1.24667 5.14033 1.92342 4.13467 2.77825 3.2795C3.63308 2.42433 4.63833 1.74725 5.794 1.24825C6.94967 0.749417 8.18442 0.5 9.49825 0.5C10.8123 0.5 12.0473 0.749333 13.2035 1.248C14.3597 1.74667 15.3653 2.42342 16.2205 3.27825C17.0757 4.13308 17.7528 5.13833 18.2518 6.294C18.7506 7.44967 19 8.68442 19 9.99825C19 11.3123 18.7507 12.5473 18.252 13.7035C17.7533 14.8597 17.0766 15.8653 16.2218 16.7205C15.3669 17.5757 14.3617 18.2528 13.206 18.7518C12.0503 19.2506 10.8156 19.5 9.50175 19.5ZM9.5 18C11.7333 18 13.625 17.225 15.175 15.675C16.725 14.125 17.5 12.2333 17.5 10C17.5 7.76667 16.725 5.875 15.175 4.325C13.625 2.775 11.7333 2 9.5 2C7.26667 2 5.375 2.775 3.825 4.325C2.275 5.875 1.5 7.76667 1.5 10C1.5 12.2333 2.275 14.125 3.825 15.675C5.375 17.225 7.26667 18 9.5 18Z"
        fill="white"
      />
    </svg>
  )
}

export default ErrorIcon
