import {useConfigStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useState} from 'react'
import {useGenericActions} from '../../../../hooks'

const useClaimSheet = () => {
  const {genericAction} = useGenericActions()
  const {
    environment: {toast},
    analytics,
  } = useConfigStore()
  const [credValidationData, setCredValidationData] = useState()
  const [loading, setLoading] = useState(false)

  const credValidationResponse = useCallback(async (action: any) => {
    try {
      setLoading(true)
      const response = await genericAction(action)

      setCredValidationData(response)
    } catch (error) {
      //noop
      toast('warning', 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }, [])

  const handleConsultationClick = useCallback(() => {
    genericAction(credValidationData?.cta?.action)
  }, [credValidationData])

  return {
    credValidationData,
    loading,
    credValidationResponse,
    handleConsultationClick,
  }
}

export {useClaimSheet}
