import styled from 'styled-components'

export const CustomerTestimonialsContainer = styled.div`
  width: 100%;
  margin-right: 16px;

  .section-title {
    color: var(--brand-primary-color);
    margin-bottom: 8px;
  }

  .testimonials-carousel {
    display: flex;
    overflow-x: scroll;
    width: 100%;

    > * + * {
      margin-left: 8px;
    }
  }
`
