function AlertIcon(props) {
  const {iconColor = '#F3AA3C'} = props

  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.72543 14.501H14.2754C15.5588 14.501 16.3588 13.1094 15.7171 12.001L9.44209 1.15938C8.80043 0.0510417 7.20043 0.0510417 6.55876 1.15938L0.28376 12.001C-0.357907 13.1094 0.442093 14.501 1.72543 14.501ZM8.00043 8.66771C7.54209 8.66771 7.16709 8.29271 7.16709 7.83438V6.16771C7.16709 5.70937 7.54209 5.33437 8.00043 5.33437C8.45876 5.33437 8.83376 5.70937 8.83376 6.16771V7.83438C8.83376 8.29271 8.45876 8.66771 8.00043 8.66771ZM8.83376 12.001H7.16709V10.3344H8.83376V12.001Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default AlertIcon
