export const processTreatmentCustomised = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = '', url = '', score = ''} = data

  return {
    Category: category,
    URL: url,
    Type: score,
  }
}

export const processTreatmentAdopted = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    product = '',
    url = '',
    category = '',
    sku = '',
    amount = '',
    month = 3,
    custom = '',
  } = data

  return {
    Category: category,
    URL: url,
    Product: product,
    SKU: sku,
    Amount: amount,
    Month: month,
    'Customised Product': custom,
  }
}

export const processTreatmentViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = '', url = ''} = data

  return {
    Category: category,
    URL: url,
  }
}
