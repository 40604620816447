import styled from 'styled-components'

export const StyledWrapper = styled.div`
  margin-bottom: 40px;

  .rcl-section-title {
    text-align: center;
  }

  .write-review-modal {
    padding: 0;
  }

  .button-wrap {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .ratings-and-reviews__button-group {
    align-content: space-between;
    flex-wrap: wrap;
    display: flex;
    width: 60%;
    margin: auto;
    justify-content: stretch;
    justify-items: stretch;
  }

  .ratings-and-reviews__button {
    position: relative;
    overflow: hidden;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
  }

  .ratings-and-reviews__button-group .ratings-and-reviews__button {
    width: 100%;
    flex-basis: 328px;
    flex-grow: 1;
    border: none;
    padding: min(
      17px,
      calc(10px + (17 - 10) * ((100vw - 360px) / (1440 - 360)))
    );
    border-radius: min(
      20px,
      calc(12px + (20 - 12) * ((100vw - 360px) / (1440 - 360)))
    );
    font-size: min(
      20px,
      calc(14px + (20 - 14) * ((100vw - 360px) / (1440 - 360)))
    );
    line-height: min(
      28px,
      calc(20px + (28 - 20) * ((100vw - 360px) / (1440 - 360)))
    );
    font-weight: var(--brand-font-weight-bold);
    background-color: var(--category-primary-color);
    color: var(--brand-primary-white-color);
    cursor: pointer;
    margin-top: 8px;
    font-family: var(--brand-font-family-text);
  }

  .ratings-and-reviews__button-group
    .ratings-and-reviews__button[data-loadmore='true'] {
    background-color: var(--brand-primary-white-color);
    border: 1px solid #f6f6f6;
    color: var(--category-primary-color);
  }

  .title-wrapper {
    padding: 0 20px;
  }

  .search-bar__wrapper {
    padding: 0 8px;
  }

  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 100px;

    .container {
      width: 100%;
      max-width: 1120px;
    }
    .ratings-and-reviews__button-group {
      width: 100%;
      max-width: 1120px;
    }

    .ratings-and-reviews__button-group
      .ratings-and-reviews__button[data-loadmore='true'] {
      margin-right: 20px;
    }
    .title-wrapper {
      padding: 0;
    }

    .search-bar__wrapper {
      padding: 0;
    }
  }
`

export const StyledReviewsWrapper = styled.div`
  .reviews_wrapper {
    padding: 0;
  }
`
