import {getCartDetailsForAnalytics} from 'src/utils/getCartDetailsForAnalytics'

export const processDetectUserCity = (
  originalPayload: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    state,
    city,
    pincode,
    addressEmail,
    email,
    firstName,
    lastName,
    source,
    action = 'add',
  } = originalPayload
  const {user, gokwikAddresses} = dLayer || {}
  const {addressCount = 0, ifEmailExistsInAddress = false} =
    gokwikAddresses || {}
  const {emailRegistered = false} = user
  const dataToTrack = {
    'Gokwik Address available': addressCount > 0,
    'Count of Gokwik Addresses': addressCount,
    'Gokwik Address Used': source === 'gokwik',
    'Gokwik Email Exists': ifEmailExistsInAddress,
    ...(!emailRegistered && {
      trackUser: true,
      city,
      email: addressEmail || email,
      firstName,
      lastName,
    }),
    'Email Field ': !emailRegistered,
    City: city,
    State: state,
    Pincode: pincode,
    'Address Source': source,
    Action: action,
  }
  return dataToTrack
}

export const processAddressViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    source = '',
    noOfAddress = 0,
    isEmailRequired = false,
    action,
  } = data || {}
  const {user, gokwikAddresses} = dLayer || {}
  const {addressCount = 0, ifEmailExistsInAddress = false} =
    gokwikAddresses || {}
  const dataToTrack = {
    'Gokwik Address available': addressCount > 0,
    'Count of Gokwik Addresses': addressCount,
    'Gokwik Address Used': source === 'gokwik',
    'Gokwik Email Exists': ifEmailExistsInAddress,
    'Address Source': source,
    'Existing Address': noOfAddress,
    'Email Field': isEmailRequired,
    Action: action,
  }

  return dataToTrack
}

export const processAddAddressClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {source = ''} = data || {}
  const {gokwikAddresses} = dLayer || {}
  const {addressCount = 0, ifEmailExistsInAddress = false} =
    gokwikAddresses || {}
  return {
    'Gokwik Address available': addressCount > 0,
    'Count of Gokwik Addresses': addressCount,
    'Gokwik Email Exists': ifEmailExistsInAddress,
    'Address Source': source,
  }
}

export const processEditAddress = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {source = '', index = 0} = data || {}
  const {gokwikAddresses} = dLayer || {}
  const {addressCount = 0, ifEmailExistsInAddress = false} =
    gokwikAddresses || {}
  return {
    'Gokwik Address available': addressCount > 0,
    'Count of Gokwik Addresses': addressCount,
    'Gokwik Email Exists': ifEmailExistsInAddress,
    'Gokwik Address Used': addressCount > 0,
    'Address Rank': index + 1,
    'Address Source': source,
  }
}

export const processDeleteAddress = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {state, city, pincode, source} = data || {}
  const dataToTrack = {
    City: city,
    State: state,
    Pincode: pincode,
    'Address Source': source,
  }
  return dataToTrack
}

export const processAddressError = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {error = {}, source} = data || {}
  const {user, gokwikAddresses} = dLayer || {}
  const {emailRegistered = false} = user
  const {addressCount = 0, ifEmailExistsInAddress = false} =
    gokwikAddresses || {}
  const dataToTrack = {
    'Gokwik Address available': addressCount > 0,
    'Count of Gokwik Addresses': addressCount,
    'Gokwik Address Used': source === 'gokwik',
    'Gokwik Email Exists': ifEmailExistsInAddress,
    'Email Field ': !emailRegistered,
    ...error,
    'Address Source': source,
  }
  return dataToTrack
}
