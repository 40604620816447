import styled from 'styled-components'

export const SuccessScreenWrapper = styled.div`
  position: relative;

  .success-screen__heading-card {
    height: 210px;
    width: 100%;
    background: var(--category-primary-color);
    color: var(--brand-primary-white-color);
    background-image: url(${(props) => props.confettiLink});
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .success-screen__heading-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    gap: 4px;
  }

  .success-screen__heading-image {
    width: 18px;
    height: 18px;
  }

  .success-screen__heading-text {
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 20px;
    line-height: 20px;
  }

  .success-screen__sub-heading {
    font-weight: var(--brand-font-weight-normal);
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    margin-top: 4px;
  }

  .success-screen__offer-container {
    background: rgba(255, 255, 255, 0.1);
    margin-top: 16px;
  }

  .success-screen__offer-text {
    font-weight: var(--brand-font-weight-normal);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 10px 0px;
  }

  .success-screen__content {
    position: absolute;
    top: 160px;
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .success-screen__download-app {
    margin-bottom: 32px;
  }

  .success-screen__callout {
    margin-bottom: 8px;
  }

  .success-screen__convert-to-IA-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: var(--brand-primary-white-color);
    background: var(--category-primary-color);
    border-radius: 8px;
    border: none;
    width: 100%;
    margin-bottom: 24px;
  }

  .trust-markers {
    margin-bottom: 20px !important;
  }

  .trustMarkers-container {
    width: 100% !important;
  }

  .success-screen__customer-testimonials {
    margin-bottom: 20px;
  }

  .success-screen__info-card {
    border: 1px solid var(--brand-primary-white-color);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 20px;
    width: 100%;
  }

  .success-screen__info-card-title {
    padding-bottom: 16px;
  }

  .success-screen__info-card-step {
    color: var(--brand-primary-grey-color);
    padding-bottom: 16px;
    position: relative;
    img {
      position: absolute;
      width: 50px;
      height: 16px;
    }
  }

  .reschedule-modal__wrapper {
    padding: 0;
  }

  .schedule-screen-content {
    height: 80vh;
    overflow-y: auto;
    padding: 0;
  }

  @media screen and (min-width: 768px) {
    .success-screen__heading-card {
      height: 300px;
    }

    .success-screen__heading-container {
      display: flex;
      justify-content: center;
      padding-top: 40px;
      gap: 6px;
    }

    .success-screen__heading-image {
      width: 28px;
      height: 28px;
    }

    .success-screen__heading-text {
      font-size: 28px;
      line-height: 28px;
    }

    .success-screen__sub-heading {
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      margin-top: 8px;
    }

    .success-screen__offer-container {
      margin-top: 20px;
    }

    .success-screen__offer-text {
      font-size: 16px;
      line-height: 22px;
      padding: 13px 0px;
    }

    .success-screen__content {
      top: 220px;
      width: 450px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .success-screen__info-card {
      padding: 30px;
      width: 450px;
    }

    .success-screen__info-card-step {
      padding-bottom: 20px;
      text-indent: -18px;
      padding-left: 20px;
      img {
        top: 3%;
        width: 60px;
        height: 22px;
      }
    }
    .schedule-screen-content {
      padding: 20px;
    }
  }
  /* Trust Markers Overrides */

  .each-trustmarker-image {
    height: 40px !important;
    width: 40px !important;
  }
`
