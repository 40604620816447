import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import renderNodes, {BaseNode} from './renderNodes'

interface CustomWidgetProps {
  style?: React.CSSProperties
  nodes: BaseNode
}
function CustomWidget({
  header,
  layout,
  widgetData,
  type,
  id = '',
}: BaseWidgetProps<CustomWidgetProps>) {
  const {nodes} = widgetData

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      {renderNodes(nodes)}
    </BaseWidgetComponent>
  )
}

export default CustomWidget
