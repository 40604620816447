import isEmpty from 'lodash/isEmpty'
import Typography from '../../../common/Typography/Typography'

import {SectionHeadingContainer} from './SectionHeading.styles'

function SectionHeading(props) {
  const {
    headingLevel = 2,
    subHeadingLevel = 3,
    heading = '',
    subHeading = '',
  } = props.state || {}

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <SectionHeadingContainer>
      <Typography
        text={heading}
        variant="heading-sm-bold"
        customClassName="section-heading__heading rcl-title-md"
        headingLevel={headingLevel}
      />
      {subHeading && (
        <Typography
          text={subHeading}
          variant="body-base-regular"
          customClassName="section-heading__sub-heading"
          headingLevel={subHeadingLevel}
        />
      )}
    </SectionHeadingContainer>
  )
}

export default SectionHeading
