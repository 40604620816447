import {analyticsTriggerBypassProcessor} from 'src/analytics'
import {toast} from 'src/toast'

export const logError = (
  error: Error,
  meta?: {
    location?: string
    source?: string
    parsedData?: string
  }
) => {
  // @ts-ignore
  if (typeof window && window.newrelic) {
    window.newrelic.noticeError(error, {...meta, custom: true})
  } else {
    console.error(error)
  }
}

export const logInfo = (
  message: string,
  meta?: {
    location?: string
    source?: string
  }
) => {
  console.info(message, meta)
}

export const errorToastAndEvent = ({
  event = '',
  error,
  api = '',
  toastMsg = '',
  showToast = false,
  triggerAnalytics = false,
  analyticsData = {},
}) => {
  const data = error?.response?.data || {}
  const statusCode = error?.response?.status

  if (showToast) {
    toast('warning', toastMsg || data?.message || error?.message)
  }

  if (triggerAnalytics && !!event) {
    analyticsTriggerBypassProcessor(event, {
      api,
      errorCode: data?.errorCode,
      errorName: data?.message,
      errorToast: toastMsg || data?.message || error?.message,
      errorStatusCode: statusCode,
      ...analyticsData,
    })
  }

  logError(error as Error)
}
