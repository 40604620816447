import { GenericAction } from "@mosaic-wellness/fe-types";
import { create } from "zustand";

interface WidgetData {
  widgetOrder: string[];
  widgets: {
    [key: string]: {
      type: string;
      enableSkip: boolean;
      skipEntireFlow: boolean;
      [key: string]: Record<string, any> | string | number | boolean;
    };
  };
  onCompleteCallbackAction: null | GenericAction;
}

export interface OnboardingStore {
  currentStep: number;
  flowData: WidgetData | null;
  setFlowData: (flowData: OnboardingStore["flowData"]) => void;
  setFlowId: (
    flowId: OnboardingStore["flowId"],
    flowContext?: OnboardingStore["flowContext"]
  ) => void;
  flowContext: Record<string, string | boolean | null> | null;
  moveToNextStep: () => void;
  resetFlowData: () => void;
  skipOnboardingData: Record<string, string | boolean | null> | null;
  skipOnboarding: (
    flowData: OnboardingStore["flowData"],
    skipData: Record<string, any>
  ) => void;
  /**
   * @description - the flowId should not be used for any logical purposes, until and unless it is of type string
   */
  flowId?:
    | string
    | {
        forPage?: string;
        type: string;
        widgetOrder?: string[];
        activityType?: string;
        activityCode?: string;
        taskId?: number;
        deepLink?: {
          actionName: string;
          actionData?: Record<string, any>;
        };
      };
}

export const useOnBoardingFlowStore = create<OnboardingStore>()((set) => ({
  currentStep: 0,
  flowData: null,
  flowContext: null,
  setFlowId: (flowId, flowContext) =>
    set({ flowId, flowContext: flowContext || {} }),
  moveToNextStep: () =>
    set((state) => ({ currentStep: state.currentStep + 1 })),
  setFlowData: (flowData) =>
    set({
      flowData,
      currentStep: 0,
    }),
  resetFlowData: () =>
    set({ flowData: null, currentStep: 0, flowId: undefined }),
  skipOnboardingData: null,
  skipOnboarding: (flowData, skipData) =>
    set((state: any) => {
      state?.setFlowData({
        ...flowData,
        widgetOrder: ["SKIP"],
        widgets: {},
      });
      state?.setFlowId("", {});
      return { currentStep: 1, skipOnboardingData: skipData };
    }),
}));
