import {GenericAction} from '@mosaic-wellness/fe-types'
import {useCallback} from 'react'
import {useGenericCtaV2} from '../useGenericCtaV2'

export const useAppActions = () => {
  const {handleCtaV2} = useGenericCtaV2()

  const handleAppAction = useCallback(
    async (action: GenericAction) => {
      const data = await handleCtaV2({
        action: action?.actionName,
        data: action?.params,
      })

      return data /* Returning data for endpoint action */
    },
    [handleCtaV2]
  )

  const handleAppActions = useCallback(
    async (actions: GenericAction[]) => {
      actions.forEach((action) => {
        handleAppAction(action)
      })
    },
    [handleAppAction]
  )

  return {
    handleAppAction,
    handleAppActions,
  }
}
