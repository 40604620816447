import styled from 'styled-components'

export const ProductListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-12);

  .product-card-list {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-12);
  }
  @media (min-width: 768px) {
    .product-card-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`
