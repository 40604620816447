import styled from 'styled-components'

export const WalletSectionWrapper = styled.section`
  display: flex;
  width: 100%;

  .mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: var(--dls-size-16);
    margin-top: 16px;
    margin-bottom: 4px;
    border-radius: 12px;
    background-color: var(--dls-white-color);
  }

  .topContainer {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .bottomInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }

  .image {
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 16px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .title-text {
    color: var(--dls-secondary-text-colors);
    margin-bottom: 4px;
  }

  .checkBoxContainer {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 4px;
    border: 2px solid var(--category-primary-color);
    background-color: ${(props) =>
      props.walletChecked
        ? 'var(--category-primary-color)'
        : 'var(--brand-primary-white-color)'};
    cursor: pointer;
  }

  .horizontalLine {
    width: 100%;
    border: 0;
    border-bottom-color: var(--brand-primary-light-grey-color);
    border-bottom-width: 1px;
    border-style: solid;
    margin-top: 10px;
  }

  .bottomContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding-top: 16px;
    position: relative;
  }

  .text {
    color: var(--dls-primary-text-colors);
    margin-right: 6px;
  }

  .infoIconContainer {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &:hover {
      .toolTipContainer {
        display: flex;
        z-index: 10;
      }
    }
  }

  .infoIcon {
    aspect-ratio: 1;
    object-fit: cover;
  }

  .calloutContainer {
    display: flex;
    align-self: flex-start;
    height: 30px;
    padding-top: 16px;
  }

  .callout {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--brand-font-family-text);
    color: var(--brand-primary-teal-color);
    align-self: flex-start;
  }

  .toolTipHead {
    position: absolute;
    top: 48px;
    left: 40px;
  }

  .toolTipContainer {
    display: none;
    position: absolute;
    top: 90%;
    left: -120px;
    background-color: var(--brand-primary-white-color);
  }

  .infoContainer {
    padding: 0;
    border-radius: 8px;
    border-width: 2px;
    border-color: var(--category-primary-light-color);
  }

  .toolTip {
    position: absolute;
    top: -16px;
    left: 96px;
    width: 0;
    height: 0;
    border-left-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 18px;
    border-left-color: var(--brand-primary-black-color);
    border-right-color: var(--brand-primary-black-color);
    z-index: 2;
    border-bottom-color: var(--category-primary-color);
  }

  .toolTipBorder {
    position: absolute;
    top: -18px;
    left: 64px;
    width: 0;
    height: 0;
    border-left-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 18px;
    border-left-color: var(--brand-primary-black-color);
    border-right-color: var(--brand-primary-black-color);
    border-bottom-color: var(--category-primary-color);
  }

  .loaderContainer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }

  .checkBoxLoader {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .zero-order-button {
    margin-top: var(--dls-size-16);
    width: 100%;
    background: var(--dls-brand-primary-color-500);
    padding: var(--dls-size-16);
    border-radius: 8px;
    color: var(--dls-white-color);
    border: none;
  }
  .payment-summary-wrap {
    padding: var(--dls-size-0) var(--dls-size-16) var(--dls-size-16)
      var(--dls-size-16);
  }
  @media (min-width: 768px) {
    .toolTipContainer {
      left: 10%;
    }
  }
`
