function QuantityIcon(props) {
  const {type = 'increment'} = props

  if (type === 'increment') {
    return (
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 4.53143V3.46857H3.47887V1H4.52113V3.46857H7V4.53143H4.52113V7H3.47887V4.53143H1Z"
          fill="white"
          stroke="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0.5H7V1.5H1V0.5Z" fill="white" stroke="white" />
    </svg>
  )
}

export default QuantityIcon
