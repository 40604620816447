import {OptionWithImageContainer} from './OptionWithImage.styles'
import {IOptionWithImageProps} from './OptionWithImage.interface'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {useCallback, useMemo} from 'react'

function OptionWithImage(props: IOptionWithImageProps) {
  const {
    display_text = '',
    image = '',
    isSelected = false,
    option = '',
    text = '',
    onOptionClicked,
  } = props

  const textToUse = useMemo(() => {
    return display_text || text
  }, [display_text, text])

  const handleOnCardClick = useCallback(() => {
    onOptionClicked({id: option, text})
  }, [onOptionClicked, option, text])

  return (
    <OptionWithImageContainer
      data-isselected={isSelected}
      onClick={handleOnCardClick}
    >
      <div className="option-data">
        <div className="image-container">
          <OptimizedImage
            source={image}
            aspectHeight={'40'}
            aspectWidth={'40'}
          />
        </div>

        <Typography
          customClassName="option-text"
          variant={TypographyVariants.BODY_BASE_REGULAR}
        >
          {textToUse}
        </Typography>
      </div>

      <input checked={isSelected} type="radio" className="radio-button" />
    </OptionWithImageContainer>
  )
}

export default OptionWithImage
