import styled from 'styled-components'

export const ViewPrescriptionCardContainer = styled.div`
  padding: 20px 16px;
  width: 100%;

  .view-prescription-card__heading-container {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
  }
  .view-prescription-card__image-container {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    width: 80px;
    height: 80px;
  }
  .view-prescription-card__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .view-prescription-card__rating-container {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--brand-primary-color);
    border-radius: 11.25px;
    padding: 4px 6px;
    gap: 3px;
  }
  .view-prescription-card__rating {
    font-size: 12px;
    line-height: 12px;
    font-weight: var(--brand-font-weight-normal);
    color: var(--brand-primary-white-color);
  }
  .view-prescription-card__heading {
    font-weight: var(--brand-font-weight-light);
    font-size: 14px;
    line-height: 120%;
    padding-bottom: 8px;
  }
  .view-prescription-card__name {
    font-weight: var(--brand-font-weight-700);
    font-size: 16px;
    line-height: 131%;
    padding-bottom: 4px;
  }
  .view-prescription-card__qualification {
    font-weight: var(--brand-font-weight-semiBold);
    font-size: 12px;
    line-height: 120%;
    color: var(--brand-primary-color);
    padding-bottom: 2px;
  }
  .view-prescription-card__no-of-consult {
    font-weight: var(--brand-font-weight-700);
    font-size: 12px;
    line-height: 120%;
    color: var(--brand-primary-grey-color);
  }
  .view-prescription-card__diagnosis-heading {
    font-size: 10px;
    line-height: 120%;
    font-weight: var(--brand-font-weight-normal);
    color: var(--brand-primary-grey-color);
    padding-bottom: 4px;
  }
  .view-prescription-card__diagnosis {
    font-weight: var(--brand-font-weight-medium);
    font-size: 14px;
    line-height: 120%;
    color: var(--brand-primary-grey-color);
    padding-bottom: 10px;
  }
  .view-prescription-card__button {
    border-radius: 16px;
    background: var(--category-primary-bg2-color);
    font-weight: var(--brand-font-weight-medium);
    font-size: 14px;
    line-height: 120%;
    color: var(--brand-primary-color);
    width: 100%;
    padding: 14px;
    border: none;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 768px) {
    .view-prescription-card__image {
      width: 100px;
      height: 100px;
    }
    .view-prescription-card__rating-container {
      padding: 5px 8px;
    }
    .view-prescription-card__heading {
      font-size: 18px;
    }
    .view-prescription-card__name {
      font-size: 20px;
    }
    .view-prescription-card__qualification {
      font-size: 16px;
    }
    .view-prescription-card__no-of-consult {
      font-size: 16px;
    }
    .view-prescription-card__diagnosis-heading {
      font-size: 14px;
    }
    .view-prescription-card__diagnosis {
      font-size: 18px;
    }
    .view-prescription-card__button {
      font-size: 18px;
    }
  }
`
