import {useUserStore} from '@mosaic-wellness/redux-action-library'
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react'
import {
  IAppConfig,
  IAppConfigContext,
  IAppConfigProviderProps,
} from './AppConfigContext.types'
import assign from 'lodash/assign'

const getPageRegex = (config: IAppConfig, repeatUser: boolean) => {
  const {pageCallout} = config || {}
  const {new: newUser, repeat} = pageCallout || {}
  const {pageRegEx: newUserPageRegex} = newUser || {}
  const {pageRegEx: repeatUserPageRegex} = repeat || {}
  return repeatUser ? repeatUserPageRegex : newUserPageRegex
}

const AppConfigContext = createContext<IAppConfigContext>({
  state: {
    whatsappConfig: {
      text: '',
      phoneNumber: '',
      isHidden: true,
    },
    pageCallout: {
      new: {},
      repeat: {},
    },
    pageRegEx: '',
    floatingIcon: undefined,
  },
  actions: {
    updateAppConfig: (update: object) => {},
    resetUpdateConfig: () => {},
  },
})

const AppConfigProvider = (props: IAppConfigProviderProps) => {
  const {children, value} = props
  const {
    user: {repeatUser = false},
  } = useUserStore()

  const [appConfig, setAppConfig] = useState<IAppConfig>({
    ...value,
    pageRegEx: getPageRegex(value, false),
  })

  useEffect(() => {
    if (repeatUser) {
      setAppConfig((prevConfig: any) => {
        return {
          ...prevConfig,
          pageRegEx: getPageRegex(prevConfig, true),
        }
      })
    }
  }, [repeatUser])

  const updateAppConfig = useCallback(
    (update: Partial<IAppConfig>) => {
      setAppConfig((prevConfig: any) => {
        let newState = assign(prevConfig, update)
        newState = {
          ...newState,
          pageRegEx: getPageRegex(newState, repeatUser),
        }
        return {...newState}
      })
    },
    [setAppConfig, repeatUser]
  )

  const resetUpdateConfig = useCallback(() => {
    setAppConfig({
      ...value,
      pageRegEx: getPageRegex(value, repeatUser),
    })
  }, [repeatUser, value])

  return (
    <AppConfigContext.Provider
      value={{
        state: appConfig,
        actions: {
          updateAppConfig,
          resetUpdateConfig,
        },
      }}
    >
      {children}
    </AppConfigContext.Provider>
  )
}

export const useAppConfigContext = () => {
  const {state, actions} = useContext(AppConfigContext)
  return [state, actions] as const
}

export default AppConfigProvider
