import React, {useState, useEffect, useCallback, useRef} from 'react'
import noop from 'lodash/noop'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {
  OptionDropDownWrapper,
  ExternalDropDownContainer,
  OptionDropDownContainer,
  DropDownList,
  ListItem,
} from './OptionDropDown.styles'

function OptionDropDown(props) {
  const {
    optionTitle = '',
    OptionIcon = null,
    data = [],
    selectedItem = '',
    className = '',
  } = props.state || {}

  const {handleSelectedItem = noop} = props.actions || {}

  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const dropDownRef = useRef(null)
  const selectedItemRef = useRef(null)

  const collapseDropDown = useCallback(() => {
    setIsDropDownOpen(false)
  }, [])

  const toggleDropDown = useCallback(() => {
    setIsDropDownOpen(!isDropDownOpen)
  }, [isDropDownOpen])

  useEffect(() => {
    if (!selectedItem) {
      handleSelectedItem(data[0])
    }
  }, [data, handleSelectedItem, selectedItem])

  const handleListItemClicked = useCallback(
    (item) => {
      handleSelectedItem(item)
      collapseDropDown()
    },
    [collapseDropDown, handleSelectedItem]
  )

  const onClickOutside = useCallback(() => {
    collapseDropDown()
  }, [collapseDropDown])

  // outside click dismiss
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target) &&
        !selectedItemRef.current.contains(event.target)
      ) {
        onClickOutside()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [onClickOutside])

  return (
    <OptionDropDownWrapper className={className}>
      <div className="appointment_card__optionTitle">{optionTitle}</div>
      <OptionDropDownContainer data-is-drop-down-open={isDropDownOpen}>
        <div className="option-dropdown__iconContainer">
          <OptionIcon />
        </div>
        <div
          className="option-dropdown__container"
          ref={selectedItemRef}
          onClick={() => toggleDropDown()}
        >
          <div className="option-dropdown__selectedItem">{selectedItem}</div>
          <div className="option-toggle__container">
            <FontAwesomeIcon
              icon={faChevronDown}
              className="option-toggle__icon"
            />
          </div>
        </div>
      </OptionDropDownContainer>
      <ExternalDropDownContainer>
        {isDropDownOpen && (
          <div className="external-dropdown__container" ref={dropDownRef}>
            <DropDownList>
              {data.map((item) => (
                <ListItem
                  onClick={() => handleListItemClicked(item)}
                  key={item}
                >
                  {item}
                </ListItem>
              ))}
            </DropDownList>
          </div>
        )}
      </ExternalDropDownContainer>
    </OptionDropDownWrapper>
  )
}

export default OptionDropDown
