import React from 'react'
import {getGridItemWidth} from './utils'
import useHybridLayout from './hook'
import HybridLayoutItem from './Item/HybridLayoutItem.component'
import {
  Container,
  Item,
  CarouselContainer,
  RowFlexContainer,
} from './HybridLayout.styles'
import {HybridLayoutItemType, HybridLayoutProps} from './HybridLayout.types'

const HybridLayout = (props: HybridLayoutProps) => {
  const {
    items = [],
    type = 'flex',
    widthRatio = 2,
    itemsPerRow = 1,
    height = 80,
    onItemPress,
  } = props || {}

  const {parentWidth, containerRef} = useHybridLayout(props)

  if (!items?.length) {
    return null
  }

  return (
    <Container>
      {type === 'carousel' ? (
        <CarouselContainer>
          {items.map((item: HybridLayoutItemType, index: number) => (
            <Item
              key={`hybridLayoutItem${index}`}
              height={height}
              width={`${widthRatio * height}px`}
            >
              <HybridLayoutItem
                height={height}
                itemData={item}
                onItemPress={onItemPress}
              />
            </Item>
          ))}
        </CarouselContainer>
      ) : (
        <RowFlexContainer ref={containerRef} isGrid={type === 'grid'}>
          {items.map((item: HybridLayoutItemType, itemIndex: number) => (
            <Item
              key={`hybridLayoutItem_${itemIndex}`}
              height={height}
              width={
                type === 'grid'
                  ? `${getGridItemWidth(
                      itemIndex,
                      items,
                      itemsPerRow,
                      parentWidth
                    )}px`
                  : 'auto'
              }
              isFlex={type === 'flex'}
            >
              <HybridLayoutItem
                height={height}
                itemData={item}
                onItemPress={onItemPress}
              />
            </Item>
          ))}
        </RowFlexContainer>
      )}
    </Container>
  )
}

export default HybridLayout
