import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useMemo} from 'react'

import {useTabs} from '../../../../hooks/useTabs'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import Responsive from '../../../HOC/Responsive'
import OptimizedImage from '../../../common/OptimizedImage'
import Typography from '../../../common/Typography/Typography'
import AllIngredients from './AllIngredients'
import {
  BackgroundImage,
  ContainerWrap,
  FooterImage,
  StyledContent,
  StyledTabs,
  StyledTextContent,
  StyledWrapper,
} from './Ingredients.styles'
import NaturalIngredients from './NatIngredients'

function Ingredients({data, isMobile, actions}) {
  const {
    titleImageDesktop,
    titleImageMobile,
    sections,
    tabTitleHeadingLevel = 2,
    sectionData,
    fullIngredients,
    decoration = {},
    showAllIngredients = true,
  } = data || {}

  const {disableBodyScroll = noop, enableBodyScroll = noop} = actions || {}
  const {activeTab, changeTab} = useTabs(sections?.[0]?.key)

  const {background, backgroundImage, footerImage} = decoration
  const backgroundImageToPick = useMemo(() => {
    let imageToUse
    if (isMobile) {
      imageToUse = backgroundImage?.mobile
    } else {
      imageToUse = backgroundImage?.desktop
    }

    return imageToUse
  }, [backgroundImage, isMobile])

  if (isEmpty(data) || isEmpty(sections)) {
    return null
  }

  const titleImageToRender = isMobile ? titleImageMobile : titleImageDesktop

  return (
    <StyledWrapper data-scrollSection="ingredients">
      <OptimizedImage
        source={titleImageToRender}
        aspectWidth="100"
        aspectHeight="100"
        desktopViewWidth="FULL"
        mobileViewWidth="FULL"
        altText="ingredients background"
        loading="lazy"
        customClassName="banner-image"
      />
      <ContainerWrap background={background}>
        {backgroundImageToPick && (
          <BackgroundImage>
            <OptimizedImage
              source={backgroundImageToPick}
              aspectWidth="100"
              aspectHeight="100"
              desktopViewWidth="FULL"
              mobileViewWidth="FULL"
              altText="ingredients-bg"
              loading="lazy"
              customClassName="container-bg"
            />
          </BackgroundImage>
        )}
        {footerImage && (
          <FooterImage>
            <OptimizedImage
              source={footerImage}
              aspectWidth="100"
              aspectHeight="100"
              desktopViewWidth="FULL"
              mobileViewWidth="FULL"
              altText="ingredients-footer-bg"
              loading="lazy"
              customClassName="footer-bg"
            />
          </FooterImage>
        )}
        <div className="container">
          <div className="">
            <StyledTabs>
              {sections.map((section) => {
                const {label, key} = section

                return (
                  <button
                    className="tab"
                    data-active={activeTab === key}
                    onClick={() => changeTab(key)}
                  >
                    <Typography
                      text={section.label}
                      variant="subheading-bold"
                      customClassName="tab-title"
                      headingLevel={tabTitleHeadingLevel}
                    />
                  </button>
                )
              })}
            </StyledTabs>
            <StyledContent>
              {activeTab === 'natural_ingredients' && (
                <div>
                  <StyledTextContent>
                    <NaturalIngredients ingredients={sectionData[activeTab]} />
                  </StyledTextContent>
                </div>
              )}
              {activeTab === 'vitamins_and_minerals' && (
                <StyledTextContent>
                  <NaturalIngredients ingredients={sectionData[activeTab]} />
                </StyledTextContent>
              )}
              {activeTab === 'proteins' && (
                <StyledTextContent>
                  <NaturalIngredients ingredients={sectionData[activeTab]} />
                </StyledTextContent>
              )}
            </StyledContent>
            {showAllIngredients && (
              <AllIngredients
                state={fullIngredients}
                actions={{enableBodyScroll, disableBodyScroll}}
              />
            )}
          </div>
        </div>
      </ContainerWrap>
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(Responsive(Ingredients), 'Ingredients')
