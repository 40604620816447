import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {IIconGridProps} from './IconGrid.interface'
import {IconGridContainer} from './IconGrid.styles'
import {OptimizedImage, Typography} from '@web-components'

function IconGrid(props: IIconGridProps) {
  const {id, type, widgetData, header, layout} = props
  const {icons = []} = widgetData || {}

  if (isEmpty(icons)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <IconGridContainer>
        {icons.map((icon) => (
          <div className="icon-container" key={icon.label}>
            <OptimizedImage
              source={icon.iconUrl}
              aspectHeight="48"
              aspectWidth="48"
            />
            <Typography variant="tag-regular">{icon.label}</Typography>
          </div>
        ))}
      </IconGridContainer>
    </BaseWidgetComponent>
  )
}

export default IconGrid
