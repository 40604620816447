import isEmpty from 'lodash/isEmpty'

export const getTransformSlotsData = (slotData: any, lsdSelected = false) => {
  const dateData = {}
  const {selectDoctor = {}} = slotData || {}
  const {data: doctorsData = []} = selectDoctor

  const {title, id} = doctorsData.length > 0 ? doctorsData[0] : {}
  const {selectAvailable = {}} = doctorsData.length > 1 ? doctorsData[1] : {}

  let availableLSDSlots = {}
  if (title) {
    const {selectAvailable = {}} = doctorsData.length > 0 ? doctorsData[0] : {}
    availableLSDSlots = selectAvailable
  }

  const lsdSlots = availableLSDSlots
  const allSlots = selectAvailable

  const {data: availableData = []} =
    lsdSelected && !isEmpty(lsdSlots) ? lsdSlots : allSlots
  const {data: slotMap = []} = availableData.length > 1 ? availableData[1] : {}

  let dataToMap = slotMap

  if (lsdSelected && !isEmpty(lsdSlots.data)) {
    dataToMap = lsdSlots.data
  }

  dataToMap.forEach((item) => {
    const timeData = {}
    item.times.forEach((item) => {
      timeData[item.time] = item.data.slots
    })
    if (!isEmpty(timeData)) {
      dateData[item.id] = timeData
    }
  })

  let firstAvailableSlots: any = []

  if (!isEmpty(dateData)) {
    const firstDateObj = Object.keys(dateData)[0]
    const firstTimeObj = Object.keys(dateData?.[firstDateObj])[0]
    const firstSlotObj = Object.values(dateData?.[firstDateObj]?.[firstTimeObj])
    firstAvailableSlots = firstSlotObj.map(({data = ''}: any) => data)
  }

  return firstAvailableSlots
}
