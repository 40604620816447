import {useCartStore} from '@mosaic-wellness/redux-action-library'
import {useGenericActions} from '../../hooks'
import isEmpty from 'lodash/isEmpty'
import {useCallback, useMemo} from 'react'
import {StickyCartSummaryState} from './StickyCartSummary.interface'

export const useStickyCartSummary = (widgetData: {
  showSubTitle: boolean
  source: string
}): StickyCartSummaryState => {
  // TODO: Remove cart store
  const {cart} = useCartStore()
  const {genericAction} = useGenericActions()
  const {checkoutInfo} = cart || {}
  const {milestoneTier} = checkoutInfo || {}

  const {
    cta,
    productImage = '',
    title = '',
    subtitle = '',
  } = milestoneTier || {}

  const {label = '', action = '', actionData = {}} = cta || {}
  const {showSubTitle = true, source = ''} = widgetData || {}

  const isMilestoneTierEmpty = useMemo(
    () => isEmpty(milestoneTier),
    [milestoneTier]
  )

  const handleCtaClick = useCallback(() => {
    genericAction({
      actionName: action,
      params: {
        triggerAnalytics: true,
        analyticsData: {
          eventName: 'Go To Cart Clicked',
          eventData: {
            Source: source,
            label: label,
          },
        },
        ...(actionData || {}),
      },
    })
  }, [action, actionData, genericAction, label, source])

  return {
    productImage,
    title,
    subtitle,
    showSubTitle,
    label,
    isMilestoneTierEmpty,
    handleCtaClick,
  }
}
