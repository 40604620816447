export const processImageUpload = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    category = '',
    source = '',
    iaBooked = false,
    appointmentId = '',
  } = data

  return {
    Category: category,
    Source: source,
    'IA Booked': iaBooked ? 'Y' : 'N',
    'Appointment Id': appointmentId,
  }
}
