import React, {useMemo, useCallback} from 'react'
import noop from 'lodash/noop'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faChevronRight} from '@fortawesome/free-solid-svg-icons'

import {OptionCardContainer} from './OptionCard.styles'
import isEmpty from 'lodash/isEmpty'

function OptionCard(props) {
  const {
    option = null,
    text = '',
    image = '',
    isSelected = false,
    hide = false,
    category = '',
    onClick = noop,
    isCheckbox = true,
    isCategoryScreen = false,
    display_text = '',
  } = props

  const textToDisplay = useMemo(() => {
    return display_text || text
  }, [display_text, text])

  const handleOnCardClick = useCallback(() => {
    onClick({id: option, text})
  }, [onClick, option, text])

  const isOptionWithImage = useMemo(() => !isEmpty(image), [image])

  const activeCategory = isCategoryScreen ? text.toLowerCase() : category
  if (hide) return null

  return (
    <OptionCardContainer
      className="category-card-container"
      data-category={activeCategory}
      onClick={handleOnCardClick}
      data-is-selected={isSelected}
      data-is-checkbox={isCheckbox}
      data-is-image-container={isOptionWithImage}
      data-is-category-screen={isCategoryScreen}
    >
      <div className="option_wrapper">
        {isOptionWithImage && (
          <div className="option_image__container">
            <img
              className="option_image"
              loading="lazy"
              alt={textToDisplay}
              src={image}
            />
          </div>
        )}
        <div
          className="option_text"
          data-is-image-container={isOptionWithImage}
        >
          {textToDisplay}
        </div>
      </div>

      {isCheckbox ? (
        <div className="checkbox">
          <FontAwesomeIcon icon={faCheck} className="check-icon" />
        </div>
      ) : (
        <div className="icon_container">
          <FontAwesomeIcon icon={faChevronRight} className="chevron-icon" />
        </div>
      )}
    </OptionCardContainer>
  )
}

export default OptionCard
