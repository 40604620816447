import {useEffect, useState} from 'react'
import {useIntersection} from '../../../../hooks/useIntersection'
import {loadScript} from '../../../../helpers/loadScript'
import {
  GlobalStampedModalStyles,
  StyledReviewImagesWrapper,
} from './SampedImageWidget.styles'

function StampedImageWidget(props) {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const {
    productId,
    stampedWidgetConfig = {},
    reviewsProvider = '',
  } = props.state || {}
  const {stampedApiKeyPublic = '', stampedStoreUrl = ''} = stampedWidgetConfig

  const {nodeRef, isIntersecting} = useIntersection({
    rootMargin: '500px',
    threshold: 1,
  })

  useEffect(() => {
    ;(async function () {
      if (isIntersecting && !scriptLoaded) {
        try {
          await loadScript(
            'https://cdn1.stamped.io/files/widget.min.js',
            () => {
              window.StampedFn?.init({
                apiKey: stampedApiKeyPublic,
                storeUrl: stampedStoreUrl,
              })
            }
          )
          setScriptLoaded(true)
        } catch (err) {
          setScriptLoaded(false)
        }
      }
    })()
  }, [isIntersecting, scriptLoaded, stampedApiKeyPublic, stampedStoreUrl])

  return (
    <>
      <GlobalStampedModalStyles />
      {reviewsProvider === 'stamped' && (
        <StyledReviewImagesWrapper ref={nodeRef}>
          <div
            id="stamped-reviews-widget"
            data-widget-type="visual-gallery"
            data-feed-type="carousel"
            data-height="150"
            data-auto-play="false"
            data-fill-empty="false"
            data-with-photos="true"
            data-product-ids={productId}
          ></div>
        </StyledReviewImagesWrapper>
      )}
    </>
  )
}

export default StampedImageWidget
