import {useRouter} from 'next/router'
import {NavTarget, useAuthorizationStore} from './useAuthorizationStore'
import {useCallback, useEffect} from 'react'
import {useUserStore} from '@mosaic-wellness/redux-action-library'

/**
 * @description this hook is a single use hook, sits at the top of the app
 * listens to anyone pushing things to authNavSTore and then redirects the user once they login and clears the store
 */
export const useAuthBoot = () => {
  const navTarget = useAuthorizationStore((store) => store.navTarget)
  const setNavTarget = useAuthorizationStore((store) => store.setNavTarget)
  const resetNavTarget = useAuthorizationStore((store) => store.resetNavTarget)
  const router = useRouter()
  const {
    user: {isLoggedIn},
  } = useUserStore()
  const handleAuthBoot = useCallback(
    (navData: NavTarget) => {
      const {link} = navData
      router.push(link)
      resetNavTarget()
    },
    [resetNavTarget, router]
  )

  useEffect(() => {
    if (navTarget && isLoggedIn) {
      handleAuthBoot(navTarget)
    }
  }, [handleAuthBoot, isLoggedIn, navTarget, resetNavTarget])
}
