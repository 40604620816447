import styled from 'styled-components'

export const SkipModalWrapper = styled.div`
  .skip-modal {
    padding: 12px;
    position: relative;
  }
  .skip-modal__title-container {
    font-weight: var(--brand-font-weight-medium);
    font-size: 20px;
    display: flex;
    margin: 30px 0px;
  }
  .skip-modal__question {
    line-height: 20px;
  }
  .skip-modal__title {
    line-height: 23px;
  }
  .skip-modal__images-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
  }
  .skip-modal__image-container {
    border: 1px solid rgba(81, 148, 209, 0.37);
    border-radius: 16px;
    padding: 20px;
    margin: 10px 5px;
    &[data-is-active='true'] {
      background: var(--brand-primary-select-blue-shadow);
    }
  }
  .skip-modal__image {
    width: 70px;
    height: 70px;
  }
  .skip-modal__error-text {
    font-size: 12px;
    text-align: center;
    color: var(--brand-primary-error-color);
    margin-bottom: 50px;
  }
  .skip-modal__submit-button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
  }
  .skip-modal__submit-button {
    width: 100%;
    font-weight: var(--brand-font-weight-700);
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: var(--brand-primary-white-color);
    background: var(--brand-consult-primary-button-color);
    padding: 12px;
    border: none;
    border-radius: 15px;
  }
  .skip-modal__submit-button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`
