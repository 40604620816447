import React from 'react'
import {Wrapper} from './DiscontinuedProductOverlay.styles'
import {Typography} from '../../../../common/TypographyV2'
import ErrorIcon from './ErrorIcon'

const DiscontinuedProductOverlay: React.FC<{message: string}> = ({
  message = '',
}) => {
  if (!message) return
  return (
    <Wrapper>
      <div className="overlay-background" />
      <div className="overlay-content-container">
        <div className="overlay-content">
          <ErrorIcon />
          <div className="message-container">
            <Typography variant="body-base-regular">{message}</Typography>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default DiscontinuedProductOverlay
