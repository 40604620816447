import React from 'react'
import {OptionsListContainer} from './OptionsList.styles'
import {IOptionsListProps} from './OptionsList.interface'
import OptionWithImage from '../OptionWithImage/OptionWithImage'
import {isEmpty} from 'lodash'

function OptionsList(props: IOptionsListProps) {
  const {options, values = [], onValueChange} = props

  if (isEmpty(options)) {
    return null
  }

  return (
    <OptionsListContainer>
      {options.map((option) => {
        const isSelected = values.some((item) => item?.id === option?.option)

        return (
          <OptionWithImage
            key={option.name}
            {...option}
            onOptionClicked={onValueChange}
            isSelected={isSelected}
          />
        )
      })}
    </OptionsListContainer>
  )
}

export default OptionsList
