import {useFutureAppointments} from '@mosaic-wellness/redux-action-library'
import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useRef,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import {getFirstCategory} from 'src/utils'
import {useConsultTracking} from 'src/hooks/tracking/useConsultTracking'

interface IDirectAssessmentContext {
  state: {
    category: string
    appointmentDetails: any
    isFutureAppointmentAvailable: boolean
    futureAppointmentsLoading: boolean | undefined
    FUQuestions: {
      [key: string]: string
    }
    recommendedProducts: Array<string>
    pendingOrderAppointmentAvailable: boolean | null
    pendingOrderAppointment: IPendingOrderAppointment | {}
    isFutureAppointmentDataLoaded: boolean
    userDiagnosis: Record<string, any>
  }
  actions: {
    updateCategory: (category: string) => void
    updateAppointmentDetails: (appointmentDetails: IBookSlotResponse) => void
    fetchAppointmentDetails: () => void
    updateFuQuestions: () => void
    updateRecommendedProducts: () => void
    checkFutureAppointments: () => {}
    updateUserDiagnosis: () => {}
  }
}

interface IBookSlotResponse {
  appointment_date?: string
  brand?: string
  category?: string
  email?: string
  follow_up?: number
  from_time?: string
  id?: string
  phone?: string
  schedule_token?: string
  scheduleToken?: string
  slot_end_time?: string
  slot_start_time?: string
  language?: string
  type?: string
}

interface IPendingOrderAppointment {
  appointmentDate: string
  slotStartTime: string
  slotEndTime: string
  appointmentId: string
  category: string
  type: string
  language: string
  imageUploaded: number
  source: string
  scheduleToken: string
  doctorName: string
}

interface IDirectAssessmentProvider {
  children: React.ReactNode | React.ReactNode[]
}

const DirectAssessmentContext = createContext<IDirectAssessmentContext>({
  state: {
    category: '',
    appointmentDetails: {},
    isFutureAppointmentAvailable: false,
    futureAppointmentsLoading: false,
    FUQuestions: {},
    recommendedProducts: [],
    pendingOrderAppointmentAvailable: null,
    pendingOrderAppointment: {},
    isFutureAppointmentDataLoaded: false,
    userDiagnosis: {},
  },
  actions: {
    updateCategory: () => {},
    updateAppointmentDetails: () => {},
    fetchAppointmentDetails: () => {},
    updateFuQuestions: () => {},
    updateRecommendedProducts: () => {},
    checkFutureAppointments: () => {},
    updateUserDiagnostics: () => {},
  },
})

export const DirectAssessmentProvider = ({
  children,
}: IDirectAssessmentProvider) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [appointmentDetails, setAppointmentDetails] = useState({})
  const [isFutureAppointmentAvailable, setIsFutureAppointmentAvailable] =
    useState(false)
  const [
    pendingOrderAppointmentAvailable,
    setPendingOrderAppointmentAvailable,
  ] = useState<boolean | null>(null)
  const [pendingOrderAppointment, setPendingOrderAppointment] = useState({})
  const [FUQuestions, setFUQuestions] = useState({})
  const [recommendedProducts, setRecommendedProducts] = useState<Array<string>>(
    []
  )
  const [
    {futureAppointmentData, loadingFutureAppointments},
    {checkFutureAppointments},
  ] = useFutureAppointments()
  const [userDiagnosis, setUserDiagnosis] = useState({})
  const isFutureAppointmentDataLoaded = useRef(false)
  const {onPendingOrderAppointmentSeen} = useConsultTracking({})

  const updateCategory = useCallback((category: string) => {
    setSelectedCategory(category.toLowerCase())
  }, [])

  const updateAppointmentDetails = useCallback((appointmentDetails: any) => {
    setAppointmentDetails(appointmentDetails)
  }, [])

  const updateFuQuestions = useCallback(
    (data) => {
      setFUQuestions({
        ...FUQuestions,
        ...data,
      })
    },
    [FUQuestions]
  )

  const updateRecommendedProducts = useCallback(
    (data: Array<string>) => {
      setRecommendedProducts([...recommendedProducts, ...data])
    },
    [recommendedProducts]
  )

  const updateUserDiagnosis = useCallback((data) => setUserDiagnosis(data), [])

  const fetchAppointmentDetails = useCallback(() => {
    if (checkFutureAppointments) {
      checkFutureAppointments()
    }
  }, [checkFutureAppointments])

  const resetContextValues = useCallback(() => {
    setSelectedCategory('')
    setAppointmentDetails({})
    setIsFutureAppointmentAvailable(false)
  }, [])

  const value = useMemo(() => {
    return {
      state: {
        category: getFirstCategory(selectedCategory),
        appointmentDetails,
        isFutureAppointmentAvailable,
        pendingOrderAppointment,
        futureAppointmentsLoading: loadingFutureAppointments,
        pendingOrderAppointmentAvailable,
        FUQuestions,
        recommendedProducts,
        isFutureAppointmentDataLoaded: isFutureAppointmentDataLoaded.current,
        userDiagnosis,
      },
      actions: {
        updateCategory,
        updateAppointmentDetails,
        fetchAppointmentDetails,
        updateFuQuestions,
        updateRecommendedProducts,
        checkFutureAppointments,
        updateUserDiagnosis,
      },
    }
  }, [
    userDiagnosis,
    updateUserDiagnosis,
    selectedCategory,
    appointmentDetails,
    isFutureAppointmentAvailable,
    loadingFutureAppointments,
    pendingOrderAppointmentAvailable,
    pendingOrderAppointment,
    FUQuestions,
    recommendedProducts,
    updateCategory,
    updateAppointmentDetails,
    fetchAppointmentDetails,
    updateFuQuestions,
    updateRecommendedProducts,
    checkFutureAppointments,
  ])

  useEffect(() => {
    return () => resetContextValues()
  }, [])

  useEffect(() => {
    if (futureAppointmentData?.scheduledAppointAvailable) {
      setAppointmentDetails({
        type: futureAppointmentData.type,
        scheduleToken: futureAppointmentData.scheduleToken,
        imageUploaded: futureAppointmentData?.imageUploaded,
        appointment_date: futureAppointmentData.appointmentDetails?.date,
        slot_start_time: futureAppointmentData.appointmentDetails?.time,
      })
      setIsFutureAppointmentAvailable(true)
      setSelectedCategory(futureAppointmentData.category as string)
    }
  }, [futureAppointmentData])

  useEffect(() => {
    if (!isEmpty(futureAppointmentData)) {
      isFutureAppointmentDataLoaded.current = true
      if (futureAppointmentData?.pendingOrderAppointmentAvailable) {
        const {
          appointmentId = '',
          category = '',
          appointmentStatus = '',
        } = futureAppointmentData?.pendingOrderAppointment || {}
        onPendingOrderAppointmentSeen({
          appointmentId,
          category,
          status: appointmentStatus,
        })
        setPendingOrderAppointmentAvailable(true)
        setPendingOrderAppointment(
          futureAppointmentData?.pendingOrderAppointment
        )
        setSelectedCategory(
          futureAppointmentData?.pendingOrderAppointment?.category as string
        )
        return
      }

      setPendingOrderAppointmentAvailable(false)
    }
  }, [futureAppointmentData])

  return (
    <DirectAssessmentContext.Provider value={value}>
      {children}
    </DirectAssessmentContext.Provider>
  )
}

export const useDirectAssessment = () => useContext(DirectAssessmentContext)
