export const processSendBookSlotDataOnPayButton = (
  data: any,
  dataLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    bookSlotData: data,
  }

  return dataToReturn
}
