import styled from 'styled-components'

export const SectionContainer = styled.div`
  margin-bottom: 30px !important;
  max-width: 1124px;
  margin: auto;
  width: 100%;

  /* Element selectors overrides for proper json config match with des */
  h2,
  p {
    margin: 0;
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: var(--brand-font-weight-heavy);
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: var(--brand-primary-grey-color);
    margin-top: 10px;
    font-weight: var(--brand-font-weight-normal);
    max-width: 500px;
  }

  b {
    font-weight: var(--brand-font-weight-black);
  }

  mark {
    display: inline-block;
    line-height: 0;
    padding-bottom: 8px;
    color: inherit;
    background-color: #ffec6b;
  }
  /* End elements selectors overrides */

  .subtitle-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .subtitle {
    width: 100%;
  }

  .subtitle-p {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .view-all-button {
    border: 0px;
    outline: 0px;
    background-color: transparent;
    font-family: var(--brand-font-family-text);
    font-size: 24px;
    color: var(--category-primary-bg1-color);
    align-self: flex-end;
    margin-left: auto;
    display: flex;
    white-space: nowrap;
    align-items: center;

    .arrow {
      margin-left: 16px;
    }
  }

  /* Classes to control text behavior and weights */
  .text-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .bold {
    font-weight: var(--brand-font-weight-black);
  }

  .normal {
    font-weight: var(--brand-font-weight-normal);
  }
  /* End text classes */

  /* Classes to control colors */
  .color-primary {
    color: var(--category-primary-color);
  }

  .color-dark {
    color: var(--category-primary-dark-color);
  }

  .color-bg1 {
    color: var(--category-primary-bg1-color);
  }
  /* End classes for colors */

  &.margin-zero {
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 40px !important;

    h2 {
      font-size: 40px;
      line-height: 54px;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      margin-top: 20px;
    }

    mark {
      padding: 18px 0px;
    }
  }
`
