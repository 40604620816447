import styled from 'styled-components'

export const ImageUploadWrapper = styled.div`
  margin: auto;
  max-width: 1120px;
  padding: 20px 20px 0px 20px;
  font-weight: var(--brand-font-weight-700);
  font-size: 14px;
  line-height: 120%;
  position: relative;
  margin-bottom: 100px;

  .heading-container {
    display: flex;
  }

  .heading-icon-container {
    flex-shrink: 0;
    width: 24px;
    height: 38px;
    margin-right: 20px;
  }

  .heading-icon {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .image-upload__heading {
    :last-child {
      margin-top: 4px;
    }
  }
  .image-upload__sub-heading {
    color: var(--brand-primary-grey-color);
    opacity: 0.7;
    margin-top: 8px;
    display: block;
  }
  .image-upload__info-text {
    text-decoration-line: underline;
    color: var(--category-primary-color);
    margin-top: 20px;
    cursor: pointer;
  }
  .image-upload__input-text {
    margin-top: 20px;
  }
  .image-upload__inputs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .image-upload__input-sub-text {
    opacity: 0.7;
    text-align: center;
    margin-top: 10px;
    display: block;
  }
  .image-upload__skip-text {
    font-size: 12px;
    text-decoration-line: underline;
    text-align: center;
    color: var(--category-primary-color);
    margin-top: 60px;
    cursor: pointer;
  }
  .image-upload__skip-sub-text {
    font-weight: var(--brand-font-weight-normal);
    font-size: 12px;
    text-align: center;
    color: var(--brand-primary-grey-color);
    margin-top: 4px;
  }
  .image-upload__error-text {
    font-size: 12px;
    text-align: center;
    color: var(--brand-primary-error-color);
    margin-top: 40px;
  }
  .image-upload__submit-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
  }
  .image-upload__submit-button {
    width: 100%;
    font-weight: var(--brand-font-weight-700);
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: var(--brand-primary-white-color);
    background: var(--brand-consult-primary-button-color);
    padding: 12px;
    border: none;
    border-radius: 15px;
  }
  .image-upload__submit-button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .teal {
    color: var(--brand-primary-teal-color);
  }
  @media screen and (min-width: 768px) {
    max-width: 500px;
    margin-bottom: 90px;
    .image-upload__sub-heading {
      margin-top: 12px;
    }
    .image-upload__info-text {
      font-size: 18px;
      line-height: 120%;
      margin-top: 30px;
    }
    .image-upload__input-text {
      font-size: 18px;
      line-height: 18px;
      margin-top: 30px;
    }
    .image-upload__inputs-container {
      margin-top: 30px;
    }
    .image-upload__skip-text {
      font-size: 16px;
      line-height: 120%;
      margin-top: 80px;
    }
    .image-upload__skip-sub-text {
      font-size: 16px;
      line-height: 120%;
      margin-top: 8px;
    }
    .image-upload__error-text {
      font-size: 16px;
      line-height: 120%;
      margin-top: 80px;
    }
  }
`
