import {createGlobalStyle} from 'styled-components'
import {config, dom} from '@fortawesome/fontawesome-svg-core'
import {memo} from 'react'
config.autoAddCss = false

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .hidden {
    display: none !important
  }


  html,
  body {
    padding: 0;
    margin: 0;
    font-family: var(--brand-font-family), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    font-family: var(--brand-font-family);
    -webkit-appearance: button;
    background-position: center;
    transition: 0.1s; 

    &.category-Primary-btn::before {
      display: none;
      overflow: hidden;
    }
    &.category-Primary-btn:active,
    &.category-Primary-btn:hover{
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        transition: background 0.8s;
        
      }
    }
    &.category-Primary-btn:hover {
      &::before {
        background: var(--category-primary-color) radial-gradient(circle, transparent 1%, var(--category-primary-color) 1%) center/15000%;
        opacity: 0.1;
      }
      &.category-Primary-btn:active {
        &::before {
        background-color: var(--category-primary-bg1-color);
        background-size: 100%;
        transition: background 0s;
        opacity: 0.6;
        }
      }
    }

    &.brand-Primary-btn::before {
      display: none;
      overflow: hidden;
    }
    &.brand-Primary-btn:active,
    &.brand-Primary-btn:hover{
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        transition: background 0.8s;
        
      }
    }
    &.brand-Primary-btn:hover {
      &::before {
        background: var(--brand-primary-color) radial-gradient(circle, transparent 1%, var(--brand-primary-color) 1%) center/15000%;
        opacity: 0.1;
      }
      &.brand-Primary-btn:active {
        &::before {
        background-color: var(--category-primary-bg1-color);
        background-size: 100%;
        transition: background 0s;
        opacity: 0.6;
        }
      }
    }


    &.brand-neutral-btn::before {
      display: none;
      overflow: hidden;
    }
    &.brand-neutral-btn:active,
    &.brand-neutral-btn:hover{
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        transition: background 0.8s;
        
      }
    }
    &.brand-neutral-btn:hover {
      &::before {
        background: var(--brand-primary-black-color) radial-gradient(circle, transparent 1%, var(--brand-primary-black-color) 1%) center/15000%;
        opacity: 0.2;
      }
      &.brand-neutral-btn:active {
        &::before {
        background-color: var(--brand-primary-white-color-1);
        background-size: 100%;
        transition: background 0s;
        opacity: 0.4;
        }
      }
    }

    &.category-primary-bg2-btn::before {
      display: none;
      overflow: hidden;
    }
    &.category-primary-bg2-btn:active,
    &.category-primary-bg2-btn:hover{
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        transition: background 0.8s;
        
      }
    }
    &.category-primary-bg2-btn:hover {
      &::before {
        background: var(--category-primary-bg2-color) radial-gradient(circle, transparent 1%, var(--category-primary-bg2-color) 1%) center/15000%;
        opacity: 0.1;
      }
      &.category-primary-bg2-btn:active {
        &::before {
        background-color: var(--category-primary-bg2-color);
        background-size: 100%;
        transition: background 0s;
        opacity: 0.2;
        }
      }
    }


    &.category-primary-atc-btn::before {
      display: none;
      overflow: hidden;
    }
    &.category-primary-atc-btn:active,
    &.category-primary-atc-btn:hover{
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100;
        height: 100%;
        background-position: center;
        transition: background 0.8s;
        
      }
    }
    &.category-primary-atc-btn:hover {
      &::before {
        background: var(--category-primary-color) radial-gradient(circle, transparent 1%, var(--category-primary-color) 1%) center/15000%;
        opacity: 0.2;
      }
      &.category-primary-atc-btn:active {
        &::before {
        background-color: var(--category-primary-bg1-color);
        background-size: 100%;
        transition: background 0s;
        opacity: 0.6;
        }
      }
    }

  }

  input {
    font-family: var(--brand-font-family);
  }

  ${dom.css()}
.flex {
    display: flex;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-center-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .flex-row {
    flex-direction: row;
  }
  .flex-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-col {
    flex-direction: column;
  }
  .flex-col-reverse {
    flex-direction: column-reverse;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-end {
    align-items: flex-end;
  }
  .items-center {
    align-items: center;
  }
  .items-baseline {
    align-items: baseline;
  }
  .items-stretch {
    align-items: stretch;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }
  .justify-evenly {
    justify-content: space-evenly;
  }
  .self-start {
    align-self: flex-start;
  }
  .self-end {
    align-self: flex-end;
  }
  .self-center {
    align-self: center;
  }
  .self-baseline {
    align-self: baseline;
  }
  .self-stretch {
    align-self: stretch;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .grow-1 {
    flex-grow: 1;
  }
  .grow-2 {
    flex-grow: 2;
  }
  .grow-3 {
    flex-grow: 3;
  }
  .grow-4 {
    flex-grow: 4;
  }
  .grow-5 {
    flex-grow: 5;
  }
  .grow-6 {
    flex-grow: 6;
  }


  .text-xxs {
    font-size: 0.625rem;
  }
  .text-xs {
    font-size: 0.75rem;
  }
  .text-sm {
    font-size: 0.875rem;
  }
  .text-base {
    font-size: 1rem;
  }
  .text-lg {
    font-size: 1.125rem;
  }
  .text-xl {
    font-size: 1.25rem;
  }
  .text-xxl {
    font-size: 1.5rem;
  }
  .text-3xxl {
    font-size: 1.75rem;
  }
  .text-4xxl {
    font-size: 2rem;
  }
  .text-5xxl {
    font-size: 2.25rem;
  }
  .text-6xxl {
    font-size: 2.5rem;
  }
  .text-7xxl {
    font-size: 2.75rem;
  }
  .text-8xxl {
    font-size: 3rem;
  }
  .text-9xxl {
    font-size: 5rem;
  }
  .text-10xxl {
    font-size: 6.25rem;
  }
  .text-11xxl {
    font-size: 7.5rem;
  }

  /* FONT - WEIGHTS */
  .font-thin {
    font-weight: 100;
  }
  .font-extraLight {
    font-weight: 200;
  }
  .font-light {
    font-weight: 300;
  }
  .font-normal {
    font-weight: 400;
  }
  .font-medium {
    font-weight: 500;
  }
  .font-semiBold {
    font-weight: 600;
  }
  .font-bold {
    font-weight: 700;
  }
  .font-extraBold {
    font-weight: 800;
  }
  .font-black {
    font-weight: 900;
  }

  .w-0 {
    width: 1.75rem;
  }
  .w-0_5 {
    width: 0.125rem;
  }
  .w-1 {
    width: 0.25rem;
  }
  .w-1_5 {
    width: 0.375rem;
  }
  .w-2 {
    width: 0.5rem;
  }
  .w-2_5 {
    width: 0.625rem;
  }
  .w-3 {
    width: 0.75rem;
  }
  .w-3_5 {
    width: 0.875rem;
  }
  .w-4 {
    width: 1rem;
  }
  .w-5 {
    width: 1.25rem;
  }
  .w-6 {
    width: 1.5rem;
  }
  .w-7 {
    width: 1.75rem;
  }
  .w-8 {
    width: 2rem;
  }
  .w-9 {
    width: 2.25rem;
  }
  .w-10 {
    width: 2.5rem;
  }
  .w-11 {
    width: 2.75rem;
  }
  .w-12 {
    width: 3rem;
  }
  .w-14 {
    width: 3.5rem;
  }
  .w-16 {
    width: 4rem;
  }
  .w-20 {
    width: 5rem;
  }
  .w-24 {
    width: 6rem;
  }
  .w-28 {
    width: 7rem;
  }
  .w-32 {
    width: 8rem;
  }
  .w-36 {
    width: 9rem;
  }
  .w-40 {
    width: 10rem;
  }
  .w-44 {
    width: 11rem;
  }
  .w-48 {
    width: 12rem;
  }
  .w-52 {
    width: 13rem;
  }
  .w-56 {
    width: 14rem;
  }
  .w-60 {
    width: 15rem;
  }
  .w-64 {
    width: 16rem;
  }
  .w-72 {
    width: 18rem;
  }
  .w-80 {
    width: 20rem;
  }
  .w-96 {
    width: 24rem;
  }

  .w-full {
    width: 100%;
  }
  .w-screen {
    width: 100vw;
  }
  .w-min {
    width: min-content;
  }
  .w-max {
    width: max-content;
  }

  /* HEIGHTS */

  .h-0 {
    height: 0rem;
  }
  .h-px {
    height: 0.0625rem;
  }
  .h-0_5 {
    height: 0.125rem;
  }
  .h-1 {
    height: 0.25rem;
  }
  .h-1_5 {
    height: 0.375rem;
  }
  .h-2 {
    height: 0.5rem;
  }
  .h-2_5 {
    height: 0.625rem;
  }
  .h-3 {
    height: 0.75rem;
  }
  .h-3_5 {
    height: 0.875rem;
  }
  .h-4 {
    height: 1rem;
  }
  .h-5 {
    height: 1.25rem;
  }
  .h-6 {
    height: 1.5rem;
  }
  .h-7 {
    height: 1.75rem;
  }
  .h-8 {
    height: 2rem;
  }
  .h-9 {
    height: 2.25rem;
  }
  .h-10 {
    height: 2.5rem;
  }
  .h-11 {
    height: 2.75rem;
  }
  .h-12 {
    height: 3rem;
  }
  .h-14 {
    height: 3.5rem;
  }
  .h-16 {
    height: 4rem;
  }
  .h-20 {
    height: 5rem;
  }
  .h-24 {
    height: 6rem;
  }
  .h-28 {
    height: 7rem;
  }
  .h-32 {
    height: 8rem;
  }
  .h-36 {
    height: 9rem;
  }
  .h-40 {
    height: 10rem;
  }
  .h-44 {
    height: 11rem;
  }
  .h-48 {
    height: 12rem;
  }
  .h-52 {
    height: 13rem;
  }
  .h-56 {
    height: 14rem;
  }
  .h-60 {
    height: 15rem;
  }
  .h-64 {
    height: 16rem;
  }
  .h-72 {
    height: 18rem;
  }
  .h-80 {
    height: 20rem;
  }
  .h-96 {
    height: 24rem;
  }
  .h-full {
    height: 100%;
  }
  .h-screen {
    height: 100vh;
  }

/* 
  .nav_bar_partition {
    padding-top: 54px;
  }

  @media (max-width: 768px) {
    .nav_bar_partition {
      padding-top: 0px;
    }
  } */


  .cursor-pointer {
    cursor: pointer;
  }

  .non-clickable-toaster.non-clickable-toaster.non-clickable-toaster{
    pointer-events: none;
    *{
      pointer-events: none;
    }
  }

  .p-1 {
    padding: 0.25rem;
  }
  .p-2 {
    padding: 0.5rem;
  }
  .p-2_5 {
    padding: 0.625rem;
  }
  .p-3 {
    padding: 0.75rem;
  }
  .p-4 {
    padding: 1rem;
  }
  .p-4_5 {
    padding: 1.125rem;
  }
  .p-5 {
    padding: 1.25rem;
  }
  .p-5_5 {
    padding: 1.375rem;
  }
  .p-6 {
    padding: 1.5;
  }
  .p-6_5 {
    padding: 1.625rem;
  }
  .p-7 {
    padding: 1.75rem;
  }
  .p-7_5 {
    padding: 1.875rem;
  }
  .p-8 {
    padding: 2rem;
  }
  .p-8_5 {
    padding: 2.125rem;
  }
  .p-10 {
    padding: 2.5rem;
  }
  .p-20 {
    padding: 5rem;
  }
  .p-25 {
    padding: 6.25rem;
  }


  .m-0 {
    margin: 0px;
  }
  .m-px {
    margin: 1px;
  }
  .m-0_5 {
    margin: 0.125rem;
  }
  .m-1 {
    margin: 0.25rem;
  }
  .m-1_5 {
    margin: 0.375rem;
  }
  .m-2 {
    margin: 0.5rem;
  }
  .m-2_5 {
    margin: 0.625rem;
  }
  .m-3 {
    margin: 0.75rem;
  }
  .m-3_5 {
    margin: 0.875rem;
  }
  .m-4 {
    margin: 1rem;
  }
  .m-5 {
    margin: 1.25rem;
  }
  .m-6 {
    margin: 1.5rem;
  }
  .m-7 {
    margin: 1.75rem;
  }
  .m-8 {
    margin: 2rem;
  }
  .m-9 {
    margin: 2.25rem;
  }
  .m-10 {
    margin: 2.5rem;
  }
  .m-11 {
    margin: 2.75rem;
  }
  .m-12 {
    margin: 3rem;
  }
  .m-14 {
    margin: 3.5rem;
  }
  .m-16 {
    margin: 4rem;
  }
  .m-20 {
    margin: 5rem;
  }
  .m-24 {
    margin: 6rem;
  }
  .m-28 {
    margin: 7rem;
  }
  .m-32 {
    margin: 8rem;
  }
  .m-36 {
    margin: 9rem;
  }
  .m-40 {
    margin: 10rem;
  }
  .m-auto {
    margin: auto;
  }

  .image-gallery-icon {
    color: #fff;
    transition: all 0.3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a);
  }
  @media (hover: hover) and (pointer: fine) {
    .image-gallery-icon:hover {
      color: #337ab7;
    }
    .image-gallery-icon:hover .image-gallery-svg {
      transform: scale(1.1);
    }
  }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7;
  }
  .image-gallery-using-mouse .image-gallery-icon:focus {
    outline: none;
  }
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    bottom: 0;
    padding: 20px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px;
  }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px;
    }
    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
      height: 24px;
      width: 24px;
    }
  }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px;
    }
    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
      height: 16px;
      width: 16px;
    }
  }
  .image-gallery-fullscreen-button {
    right: 0;
  }
  .image-gallery-play-button {
    left: 0;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px;
  }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 72px;
      width: 36px;
    }
  }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px;
    }
  }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: 0.6;
    pointer-events: none;
  }
  .image-gallery-left-nav {
    left: 0;
  }
  .image-gallery-right-nav {
    right: 0;
  }
  .image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
  }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;
  }
  .image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%);
  }
  .image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
  }
  .image-gallery-content.fullscreen {
    background: #000;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px);
  }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image,
  .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
  }
  .image-gallery-slide-wrapper {
    position: relative;
  }
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px);
  }
  @media (max-width: 768px) {
    .image-gallery-slide-wrapper.left,
    .image-gallery-slide-wrapper.right {
      width: calc(100% - 87px);
    }
  }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl;
  }
  .image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
  }
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .image-gallery-slide.center {
    position: relative;
  }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain;
  }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
  }
  @media (max-width: 768px) {
    .image-gallery-slide .image-gallery-description {
      bottom: 45px;
      font-size: 0.8em;
      padding: 8px 15px;
    }
  }
  .image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4;
  }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 2px 2px #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: all 0.2s ease-out;
  }
  @media (max-width: 768px) {
    .image-gallery-bullets .image-gallery-bullet {
      margin: 0 3px;
      padding: 3px;
    }
  }
  @media (max-width: 480px) {
    .image-gallery-bullets .image-gallery-bullet {
      padding: 2.7px;
    }
  }
  .image-gallery-bullets .image-gallery-bullet:focus {
    transform: scale(1.2);
    background: #337ab7;
    border: 1px solid #337ab7;
  }
  .image-gallery-bullets .image-gallery-bullet.active {
    transform: scale(1.2);
    border: 1px solid #fff;
    background: #fff;
  }
  @media (hover: hover) and (pointer: fine) {
    .image-gallery-bullets .image-gallery-bullet:hover {
      background: #337ab7;
      border: 1px solid #337ab7;
    }
    .image-gallery-bullets .image-gallery-bullet.active:hover {
      background: #337ab7;
    }
  }
  .image-gallery-thumbnails-wrapper {
    position: relative;
  }
  .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    touch-action: pan-y;
  }
  .image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
    touch-action: pan-x;
  }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl;
  }
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px;
  }
  @media (max-width: 768px) {
    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
      width: 81px;
    }
  }
  .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
  .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    height: 100%;
    width: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
  }
  .image-gallery-thumbnails-wrapper.left
    .image-gallery-thumbnails
    .image-gallery-thumbnail,
  .image-gallery-thumbnails-wrapper.right
    .image-gallery-thumbnails
    .image-gallery-thumbnail {
    display: block;
    margin-right: 0;
    padding: 0;
  }
  .image-gallery-thumbnails-wrapper.left
    .image-gallery-thumbnails
    .image-gallery-thumbnail
    + .image-gallery-thumbnail,
  .image-gallery-thumbnails-wrapper.right
    .image-gallery-thumbnails
    .image-gallery-thumbnail
    + .image-gallery-thumbnail {
    margin-left: 0;
    margin-top: 2px;
  }
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px;
  }
  @media (max-width: 768px) {
    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
      margin: 0 3px;
    }
  }
  .image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0;
  }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0;
    }
  }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
  }
  .image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border 0.3s ease-out;
    width: 100px;
    background: transparent;
    padding: 0;
  }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 3px solid transparent;
      width: 81px;
    }
  }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: block;
    position: relative;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
  }
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7;
  }
  @media (max-width: 768px) {
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus {
      border: 3px solid #337ab7;
    }
  }
  @media (hover: hover) and (pointer: fine) {
    .image-gallery-thumbnail:hover {
      outline: none;
      border: 4px solid #337ab7;
    }
  }
  @media (hover: hover) and (pointer: fine) and (max-width: 768px) {
    .image-gallery-thumbnail:hover {
      border: 3px solid #337ab7;
    }
  }
  .image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: #fff;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 0 2px 2px #1a1a1a;
    transform: translateY(-50%);
    white-space: normal;
    width: 100%;
  }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: 0.8em;
      line-height: 0.8em;
    }
  }
  .image-gallery-index {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
  }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: 0.8em;
      padding: 5px 10px;
    }
  }
  .with-app-download-incentive{
    top:200px !important;
    transition: all 0.4s ease-in-out;
  }
  .with-app-download-incentive-pdp{
    margin-top: 200px !important;
  }
  .with-app-download-incentive-pdp-tab-nav{
    top: 250px !important;
  }

`

const BasicLayout = ({children}: {children: any}) => {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  )
}

export default memo(BasicLayout)
