import styled from 'styled-components'

export const SectionWithOffsetContainer = styled.div`
  width: 100vw;
  position: relative;
  padding-bottom: 8%;
  padding-top: 30px;
  background-color: #fff;

  .image-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 210px;
  }

  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
    height: 210px;
  }

  .content {
    position: relative;
    width: 100%;
    padding: 0 16px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        97.62deg,
        rgba(0, 0, 0, 0) 5.08%,
        rgba(0, 0, 0, 0.15) 95.23%
      ),
      linear-gradient(0deg, rgba(22, 18, 30, 0.05), rgba(22, 18, 30, 0.05));
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &[data-showbackground='false'] {
    padding-top: 0;

    .image-container {
      background-color: var(--brand-primary-silver-color);
    }

    .view-all-button {
      color: var(--category-primary-color) !important;
    }
  }

  @media screen and (min-width: 768px) {
    padding-top: 60px;

    .image-container {
      height: 340px;
    }

    .image {
      height: 340px;
    }

    .content {
      padding: 0px;
    }

    .section {
      margin-bottom: 60px !important;
    }
  }
`
