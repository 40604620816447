import styled from 'styled-components'

export const StyledReviewContainer = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 15px;
    margin-bottom: 10px;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-around;
      margin: 0;
    }
    .review_images_container {
      display: flex;
      gap: 7px;
      @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        margin: 0;
        gap: 7px;
      }
      .image_item {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        object-fit: cover;
        img {
          border-radius: 20px;
          @media (max-width: 768px) {
            object-fit: cover;
            width: 100px;
            max-width: 112px;
            max-height: 112px;
            height: 100px;
          }
        }
        .more_images {
          position: absolute;
          top: 47%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          @media (max-width: 768px) {
            font-size: 24px;
            height: 100px;
            width: 100px;
          }
          span {
            font-size: 36px;
            font-weight: 800;
            pointer-events: none;
          }
        }
      }
    }
  }
`
export const StyledSearchBar = styled.div`
  /* width: 100%; */
  max-width: 1120px;
  margin: auto;
  margin-top: min(
    100px,
    calc(40px + (100 - 40) * ((100vw - 360px) / (1440 - 360)))
  );
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  input {
    width: 100%;
    padding: min(
      16px,
      calc(10px + (16 - 10) * ((100vw - 360px) / (1440 - 360)))
    );
    border-radius: min(
      24px,
      calc(14px + (24 - 14) * ((100vw - 360px) / (1440 - 360)))
    );
    border: 1px solid var(--brand-primary-black-lightest-color);
    outline: none;
    box-sizing: border-box;
  }
  span {
    position: absolute;
    height: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--category-primary-color);
    padding: 0.5rem 0.65rem;
    color: var(--brand-primary-silver-color);
    border-radius: min(
      20px,
      calc(12px + (20 - 12) * ((100vw - 360px) / (1440 - 360)))
    );
    right: 8px;
    cursor: pointer;
  }
  span svg {
    font-size: min(
      24px,
      calc(12px + (24 - 12) * ((100vw - 360px) / (1440 - 360)))
    );
  }

  &[data-theme='little_joys'] {
    margin-top: min(
      36px,
      calc(18px + (36 - 18) * ((100vw - 360px) / (1440 - 360)))
    );
    font-family: var(--brand-font-family-text);

    span {
      background-color: var(--brand-primary-green-color);
    }
  }

  @media (max-width: 768px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`
