import React, {useCallback} from 'react'
import noop from 'lodash/noop'

import {FomoCardContainer} from './FomoCard.styles'
import {ModalBackground} from '../../../ComponentsV2/common'

function FomoCard(props) {
  const {state = {}, actions = {}} = props

  const {
    title = '',
    subtitle = '',
    secondaryCtaLabel = '',
    primaryCtaLabel = '',
    info = [],
    showCard = true,
  } = state

  const {
    onPrimaryCtaClick = noop,
    onSecondaryCtaClick = noop,
    daExitCancelled = noop,
    daExitConfirmed = noop,
  } = actions

  const handlePrimaryCTAClicked = useCallback(() => {
    daExitCancelled()
    onPrimaryCtaClick()
  }, [daExitCancelled, onPrimaryCtaClick])

  const handleSecondaryCTAClicked = useCallback(() => {
    daExitConfirmed()
    onSecondaryCtaClick()
  }, [daExitConfirmed, onSecondaryCtaClick])

  if (!showCard) {
    return null
  }

  return (
    <ModalBackground>
      <FomoCardContainer>
        <div className="title">{title}</div>
        <div
          className="subtitle"
          dangerouslySetInnerHTML={{__html: subtitle}}
        />

        <div className="info-section-container">
          {info.map((item) => (
            <div className="info-section">
              {item.image ? (
                <img src={item.image} alt="" loading="lazy" className="image" />
              ) : (
                <span className="text">{item.text}</span>
              )}
              <span className="label">{item.label}</span>
            </div>
          ))}
        </div>

        <button
          className="button primary-cta"
          onClick={handlePrimaryCTAClicked}
        >
          {primaryCtaLabel}
        </button>
        <button
          className="button secondary-cta"
          onClick={handleSecondaryCTAClicked}
        >
          {secondaryCtaLabel}
        </button>
      </FomoCardContainer>
    </ModalBackground>
  )
}

export default FomoCard
