import noop from 'lodash/noop'
import {StyledDoctorDetails} from './DoctorDetails.styles'
import {useMemo} from 'react'
import {
  AccordionRight,
  Button,
  OptimizedImage,
  Spacer,
  Typography,
  TypographyVariants,
} from '@web-components'
import {DoctorDetailsProps} from './DoctorDetails.interface'

const DoctorDetails = (props: DoctorDetailsProps) => {
  const {
    title = '',
    displayName = '',
    avatar = '',
    status = '',
    tag = '',
    stats = [],
    qualifications = [],
    languages = [],
    id = '',
    notifyMeData,
    handleCardClick = noop,
    handleNotifyMeClick = noop,
  } = props || {}

  const renderStatus = useMemo(() => {
    if (status === 'AWAY') {
      return 'Offline'
    } else if (status === 'ACTIVE') {
      return 'Online'
    } else if (status === 'BUSY') {
      return 'Busy'
    }
    return ''
  }, [status])

  return (
    <StyledDoctorDetails>
      <div className="detail-card-wrapper" onClick={handleCardClick}>
        <div className="left-container">
          <div className="avatar-wrapper">
            {avatar && (
              <OptimizedImage source={avatar} customClassName="avatar-icon" />
            )}
            {renderStatus && (
              <div className="status-wrapper" data-status={status}>
                <Typography variant={TypographyVariants.TAG_REGULAR}>
                  {renderStatus}
                </Typography>
              </div>
            )}

            <div className="overlay">
              {displayName && (
                <Typography
                  variant={TypographyVariants.TAG_BOLD}
                  customClassName="name"
                >
                  {displayName}
                </Typography>
              )}
              <div className="stats-wrapper">
                {stats?.map((item, index: number) => (
                  <div
                    key={`doctor_${id}_stat_${index}`}
                    className="stat-container"
                  >
                    {item?.icon && (
                      <OptimizedImage
                        source={item?.icon}
                        customClassName="stat-icon"
                      />
                    )}
                    {item.label && (
                      <Typography variant={TypographyVariants.X_SMALL}>
                        {item.label}
                      </Typography>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="details-container">
            <div className="tag-header">
              <div className="tag-container">
                {tag ? (
                  <OptimizedImage source={tag} customClassName="tag-icon" />
                ) : (
                  <Typography variant={TypographyVariants.SUBHEADING_BOLD}>
                    {title}
                  </Typography>
                )}
              </div>
              <div className="right-icon">
                <AccordionRight
                  height={16}
                  width={16}
                  strokeWidth={3}
                  stroke="var(--dls-brand-primary-color-500)"
                />
              </div>
            </div>
            {tag && (
              <>
                <Spacer variant="x-small" />
                <Typography variant={TypographyVariants.SUBHEADING_BOLD}>
                  {title}
                </Typography>
              </>
            )}
            <Spacer variant="x-small" />
            {Array.isArray(qualifications) && qualifications?.length > 0 && (
              <Typography
                variant={TypographyVariants.TAG_REGULAR}
                customClassName="qualification"
              >
                {(qualifications as string[])?.map(
                  (item: string, index: number) => (
                    <Typography
                      variant={TypographyVariants.TAG_REGULAR}
                      customClassName="qualification"
                      key={`doctor_qualification_${index}`}
                    >
                      {item}{' '}
                    </Typography>
                  )
                )}
              </Typography>
            )}
            <div className="languages-wrapper">
              {languages?.map((item: string, index: number) => (
                <div
                  key={`doctor_${id}_language_${index}`}
                  className="language-container"
                >
                  <Typography variant={TypographyVariants.TAG_REGULAR}>
                    {item}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {notifyMeData?.cta ? (
        <div className="notifyme-container">
          {notifyMeData?.label && (
            <div className="notifyme-label">
              <Typography variant={TypographyVariants.TAG_REGULAR}>
                {notifyMeData?.label}
              </Typography>
            </div>
          )}
          <Button
            {...notifyMeData?.cta}
            customClassName={notifyMeData?.cta?.fullWidth ? 'notifyme-cta' : ''}
            onClick={handleNotifyMeClick}
          />
        </div>
      ) : null}
    </StyledDoctorDetails>
  )
}

export default DoctorDetails
