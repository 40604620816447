import React, {memo} from 'react'
import {StyledWrapper} from './styles'
import OptimizedImage from '../../../../common/OptimizedImage'

const RenderItem = (props) => {
  const {
    image = '',
    width = 100,
    height = 100,
    link = '#',
    lazyLoad = true,
  } = props
  return (
    <StyledWrapper>
      <a href={link}>
        <OptimizedImage
          source={image}
          aspectWidth={width}
          aspectHeight={height}
          desktopViewWidth="FULL"
          mobileViewWidth="FULL"
          altText="firstfold"
          loading={lazyLoad ? 'lazy' : 'eager'}
          customClassName="firstfold-inner-image"
        />
      </a>
    </StyledWrapper>
  )
}

export default memo(RenderItem)
