import React from 'react'

function CalloutCloseIcon(props) {
  const {color = ''} = props
  const iconColor = color ? color : 'white'

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2L2 14"
        stroke={iconColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2L14 14"
        stroke={iconColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CalloutCloseIcon
