import dynamic from 'next/dynamic'

export const ProductDetailCard = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-ProductDetailCard" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ProductDetails'
    )
)

export const KitItems = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-KitItems" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ProductKit'
    )
)

export const CheckDeliveryDate = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-CheckDeliveryDate" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/CheckDeliveryDate'
    )
)

export const Claims = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-Claims" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/Claims'
    )
)

export const DoctorVideo = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-DoctorVideo" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/DoctorVideo'
    )
)

export const SubscribeAndSave = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-SubscribeAndSave" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/SubscribeAndSave'
    )
)

export const HowToUse = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-HowToUse" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/HowToUse'
    )
)

export const ProductEquivalence = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-ProductEquivalence" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ProductEquivalence'
    )
)

export const SugarNarrativeAndFactsCharacter = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-SugarNarrativeAndFactsCharacter" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/SugarNarrativeAndFactsCharacter'
    )
)

export const SugarNarrativeAndFacts = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-SugarNarrativeAndFacts" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/SugarNarrativeAndFacts'
    )
)

export const ConsumerStudy = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-ConsumerStudy" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ConsumerStudy'
    )
)

export const BuyOptions = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-BuyOptions" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/BuyOptions'
    )
)

export const Faqs = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-Faqs" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/Faqs'
    )
)

export const TabNavigation = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-TabNavigation" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/TabNavigation'
    )
)

export const Ingredients = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-Ingredients" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/Ingredients'
    )
)

export const RatingsAndReviews = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-RatingsAndReviews" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/RatingsAndReview'
    )
)

export const QnA = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-QnA" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/QnA'
    )
)

export const SafetyNarrative = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-SafetyNarrative" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/SafetyNarrative'
    )
)

export const HowWeCompare = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-HowWeCompare" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/HowWeCompare'
    )
)

export const ClinicalProof = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-ClinicalProof" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ClinicalProof'
    )
)

export const FrequentlyBoughtTogether = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-FrequentlyBoughtTogether" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/FrequentlyBoughtTogether'
    )
)

export const SafeAndEffective = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-SafeAndEffective" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/SafeAndEffective'
    )
)

export const ConsumerStudyV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-ConsumerStudyV2" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ConsumerStudyV2'
    )
)

export const ConsultCardSection = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-ConsultCardSection" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ConsultCardSection'
    )
)

export const HowWeCompareV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-HowWeCompareV2" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/HowWeCompareV2'
    )
)

export const FeelingConfused = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-FeelingConfused" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/FeelingConfused'
    )
)

export const CustomerJourney = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-CustomerJourney" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/CustomerJourney'
    )
)

export const TopFeatures = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-TopFeatures" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/TopFeatures'
    )
)

export const RecentlyViewed = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-RecentlyViewed" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/RecentlyViewed'
    )
)

export const WeGotAnswers = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-WeGotAnswers" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/WeGotAnswers'
    )
)

export const CustomerReviews = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-CustomerReviews" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/CustomerReviews'
    )
)

export const HowItWorks = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-HowItWorks" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/HowItWorks'
    )
)

export const MiniAssessment = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-MiniAssessment" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/MiniAssessment'
    )
)

export const AssessmentQuestions = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-AssessmentQuestions" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/MiniAssessment/AssessmentQuestions'
    )
)

export const MMProductDetails = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-MMProductDetails" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/MMProductDetails'
    )
)

export const HowDoesitDo = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-HowDoesitDo" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/HowDoesitDo'
    )
)

export const MMHowToUse = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-MMHowToUse" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/MMHowTouse'
    )
)

export const MMHowToUseV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-MMHowToUseV2" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/MMHowToUseV2'
    )
)

export const WhatItWorksBestWith = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-WhatItWorksBestWith" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/WhatItWorksBestWith'
    )
)

export const WhatItWontDo = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-WhatItWontDo" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/WhatItWontDo'
    )
)

export const NutritionalInformation = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-NutritionalInformation" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/NutritionalInformation'
    )
)

export const WhatsInTheKit = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-WhatsInTheKit" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/WhatsInTheKit'
    )
)

export const Stories = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-Stories" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/Stories'
    )
)

export const WhatProsSay = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-WhatProsSay" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/WhatProsSay'
    )
)

export const ThingsToNote = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-ThingsToNote" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ThingsToNote'
    )
)

export const WhyChooseMM = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-WhyChooseMM" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/WhyChooseMM'
    )
)

export const WhyEndure = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-WhyEndure" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/WhyEndure'
    )
)

export const MMBlog = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-MMBlog" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/MMBlog'
    )
)

export const ExpertsOnFingerPrints = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-ExpertsOnFingerPrints" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/ExpertsOnFingerPrints'
    )
)

export const HowItsUsed = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-HowItsUsed" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/HowItsUsed'
    )
)

export const GiftCallout = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-GiftCallout" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/GiftCallout'
    )
)

export const SecondaryDescription = dynamic(() =>
  import(
    /* webpackChunkName: "pd-SecondaryDescription" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/SecondaryDescription'
  ).then((mod) => mod.SecondaryDescription)
)

export const KeyIngredients = dynamic(() =>
  import(
    /* webpackChunkName: "pd-KeyIngredients" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/KeyIngredients'
  ).then((mod) => mod.KeyIngredients)
)

export const SwipeToBook = dynamic(() =>
  import(
    /* webpackChunkName: "pd-SwipeToBook" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/SwipeToBook'
  ).then((mod) => mod.SwipeToBook)
)

export const SwipeToBookModal = dynamic(() =>
  import(
    /* webpackChunkName: "pd-SwipeToBookModal" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/SwipeToBook/SwipeToBookModal'
  ).then((mod) => mod.SwipeToBookModal)
)

export const AvailableOffers = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-AvailableOffers" */ '../../components/shared/src/ComponentsV2/Screens/LJ-PDP/AvailableOffers'
    )
)

export const ScrollToTopButton = dynamic(
  () =>
    import(
      /* webpackChunkName: "pd-AvailableOffers" */ '../../components/shared/src/ComponentsV2/common/ScrollToTopButton/index.js'
    )
)
