import {memo} from 'react'

import {useIntersection} from '../../hooks/useIntersection'
import {OptimizedImage, OptimizedVideo} from '@web-components'

const thursholdValue = 0
const rootMarginValue = '0px 150px 0px 0px'

const ImageCarouselSlide = ({state, actions, ...rest}: any) => {
  const {src, index, alt, type, customVideo, aspectWidth, aspectHeight} =
    state || {}

  const {isIntersecting: isVisible, nodeRef} = useIntersection({
    rootMargin: rootMarginValue,
    threshold: thursholdValue,
  })
  return (
    <div className={`image index-${index}`} ref={nodeRef}>
      {index === 0 || isVisible ? (
        type === 'video' ? (
          <OptimizedVideo
            state={{
              ...customVideo,
              videoComponentClassName: `video-carousel-${index}`,
              autoplay: false,
              playsInline: true,
            }}
          />
        ) : (
          <OptimizedImage
            source={src}
            customClassName="inner-image"
            alt={alt}
            loading={index === 0 ? 'eager' : 'lazy'}
            fetchpriority={index === 0 ? 'high' : 'auto'}
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
            desktopViewWidth="THIRD"
            mobileViewWidth="HALF"
            {...rest}
          />
        )
      ) : null}
    </div>
  )
}

const MemoizedImageCarouselSlide = memo(
  ImageCarouselSlide,
  (prevProps, nextProps) => {
    return prevProps.state.index === nextProps.state.index
  }
)

export default MemoizedImageCarouselSlide
