import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'

import {useGenericActions} from '../../hooks'
import {ProductCardV2, Typography, TypographyVariants} from '@web-components'
import {useCallback, useMemo, useState} from 'react'
import {ICategoryTagsProductCardsV2GridProps} from './CategoryTagsProductCardsV2Grid.interface'
import {CategoryTagsProductCardsV2GridContainer} from './CategoryTagsProductCardsV2Grid.styles'
import {OptimizedImage} from '@web-components'
import {CategoryProductCard} from '../CategoryProductCardGrid/CategoryProductCard'

function CategoryTagsProductCardsV2Grid(
  props: ICategoryTagsProductCardsV2GridProps
) {
  const {type, widgetData, header, layout, id} = props || {}
  const {tags = [], categoryProducts = {}} = widgetData || {}

  const {genericAction} = useGenericActions()

  const [selectedTag, setSelectedTag] = useState(tags[0]?.keyName || 'all')

  const handleTagChange = useCallback(
    (tag) => {
      genericAction({
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA Clicked',
          eventData: {
            Text: tag.name,
            Source: 'Category Switch',
            URL: window.location.href,
          },
        },
      })
      setSelectedTag(tag.keyName)
    },
    [genericAction]
  )

  const handleCtaClick = useCallback(
    (product: Product) => {
      genericAction({
        actionName: 'ADD_TO_CART',
        params: product,
      })
    },
    [genericAction]
  )

  const handleCardClick = useCallback(
    (product: Product) => {
      genericAction({actionName: 'OPEN_PDP', params: product})
    },
    [genericAction]
  )

  const products = useMemo(() => {
    return categoryProducts?.[selectedTag]?.products || []
  }, [categoryProducts, selectedTag])

  if (isEmpty(categoryProducts)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <CategoryTagsProductCardsV2GridContainer>
        <div className="tags">
          {tags.map((tag) => {
            const {name, keyName, image} = tag
            return (
              <>
                {!isEmpty(image) ? (
                  <div
                    className="image_cta_container"
                    onClick={() => handleTagChange(tag)}
                    data-is-Selected={keyName === selectedTag}
                  >
                    <div
                      className="image_cta"
                      data-image-is-Selected={keyName === selectedTag}
                    >
                      <OptimizedImage
                        source={image}
                        aspectWidth="76"
                        aspectHeight="76"
                        desktopViewWidth="SMALL"
                        mobileViewWidth="SMALL"
                        altText="pills-image"
                        loading="lazy"
                        customClassName="image"
                      />
                    </div>
                    <Typography
                      variant={TypographyVariants.TAG_REGULAR}
                      customClassName="pill-title"
                    >
                      {name}
                    </Typography>
                  </div>
                ) : (
                  <div
                    key={keyName}
                    className="tag"
                    onClick={() => handleTagChange(tag)}
                    data-is-Selected={keyName === selectedTag}
                  >
                    <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                      {name}
                    </Typography>
                  </div>
                )}
              </>
            )
          })}
        </div>
        <div className="products">
          {products.map((product: any) => (
            <CategoryProductCard
              key={product.urlKey}
              product={{...product, genericAction}}
              handleCardClick={handleCardClick}
              handleCtaClick={handleCtaClick}
            />
          ))}
        </div>
      </CategoryTagsProductCardsV2GridContainer>
    </BaseWidgetComponent>
  )
}

export default CategoryTagsProductCardsV2Grid
