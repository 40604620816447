import React from 'react'
import noop from 'lodash/noop'

import {CancelCardContainer} from './CancelCard.styles'
import {ModalBackground} from '../../../../ComponentsV2/common'

function CancelCard(props) {
  const {state = {}, actions = {}} = props

  const {
    title = '',
    subtitle = '',
    secondaryCtaLabel = '',
    primaryCtaLabel = '',
    image= '',
    showCancelCard = true,
  } = state

  const {onPrimaryCtaClick = noop, onSecondaryCtaClick = noop} = actions

  if (!showCancelCard) {
    return null
  }

  return (
    <ModalBackground>
      <CancelCardContainer>
        <div className="title" dangerouslySetInnerHTML={{__html: title}} />
        <div
          className="subtitle"
          dangerouslySetInnerHTML={{__html: subtitle}}
        />

        <div className="info-section-container">
          <img src={image} className="gift-image" />
        </div>

        <button className="button primary-cta" onClick={onPrimaryCtaClick}>
          {primaryCtaLabel}
        </button>
        <button className="button secondary-cta" onClick={onSecondaryCtaClick}>
          {secondaryCtaLabel}
        </button>
      </CancelCardContainer>
    </ModalBackground>
  )
}

export default CancelCard
