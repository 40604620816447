import styled from 'styled-components'

export const MiniAssessmentContainer = styled.div`
  .header-container {
    display: flex;
    align-items: flex-start;
  }

  .retake-test-button {
    outline: 0;
    border: 0;
    background-color: var(--dls-white-color);
    flex-shrink: 0;
    color: var(--dls-category-primary-color-500);
    padding: var(--dls-size-8);
    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-divider-light-color);
  }

  .assessment-container {
    background: linear-gradient(
      180deg,
      var(--dls-category-primary-color-100) 0,
      #fff 100.01%
    );
    border-radius: var(--dls-size-16);
    padding: 20px var(--dls-size-24);
    min-height: 240px;
    display: flex;
    flex-direction: column;
  }

  .assessment-questions-title {
    text-align: center;
    color: var(--dls-category-primary-color-500);
    margin-bottom: var(--dls-size-16);
  }

  .next-button {
    width: 100%;
    outline: 0;
    border: 0;
    padding: 12px 0;
    border-radius: var(--dls-size-8);
    background-color: var(--dls-category-primary-color-500);
    color: var(--dls-white-color);

    :disabled {
      opacity: 0.5;
    }
  }

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
  }

  .loading-gif {
    object-fit: cover;
  }

  .loading-text {
    margin-top: var(--dls-size-16);
    text-align: center;
    color: var(--dls-brand-primary-color-500);
  }
`
