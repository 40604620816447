export const processSwipeApp = (data: any) => {
  const {entryPoint, productName, productId, category, iaAvailable} = data

  const dataToReturn = {
    'Entry point': entryPoint,
    'Product Name': productName || '',
    'Product id': window.location.pathname,
    Category: category,
    'IA available': iaAvailable,
  }
  return dataToReturn
}
export const processSwipeAppBooked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {immediate} = dLayer.appointmentSelected || {}

  const isAaAvailableForSelectedData = immediate ? 'IA' : 'S'
  const {
    entryPoint,
    productName,
    productId,
    category,
    iaBooked,
    slotSelected,
    appointmentID,
  } = data

  const dataToReturn = {
    'Entry point': entryPoint,
    'Product Name': productName || '',
    'Product id': window.location.pathname,
    Category: category,
    'IA Booked': iaBooked || 'false',
    'Slot selected ': slotSelected,
    'Appointment id': appointmentID,
    'Type of call': 'Audio',
    RCL: true,
    'Appointment Type': isAaAvailableForSelectedData,
    URL: window.location.href,
    eventName: 'Swipe Appt booked',
  }

  return dataToReturn
}
export const processSwipeAppError = (data: any) => {
  const {
    entryPoint,
    productName,
    productId,
    category,
    iaBooked,
    slotSelected,
    errorResponse,
  } = data

  const dataToReturn = {
    'Entry point': entryPoint,
    'Product Name': productName || '',
    'Product id': window.location.pathname,
    Category: category,
    'IA Booked': iaBooked || 'false',
    'Slot selected ': slotSelected,
    'error response': errorResponse,
  }

  return dataToReturn
}
export const processSwipeAppSubmit = (data: any) => {
  const {entryPoint, productName, productId, category, iaBooked, slotSelected} =
    data

  const dataToReturn = {
    'Entry point': entryPoint,
    'Product Name': productName || '',
    'Product id': window.location.pathname,
    Category: category,
    'IA Booked': iaBooked || 'false',
    'Slot selected ': slotSelected,
  }

  return dataToReturn
}
