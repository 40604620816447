import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: min(
    24px,
    calc(17px + (24 - 17) * ((100vw - 360px) / (1440 - 360)))
  );

  .ratings-and-reviews__bottom-left {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .ratings-and-reviews__author {
    display: flex;
    align-items: center;
  }
  .author_name {
    margin: 0;
    margin-right: 3px;
  }
  .ratings-and-reviews__author span {
    width: auto;
    border: none;
  }

  .ratings-and-reviews__date {
    font-size: min(
      14px,
      calc(10px + (14 - 10) * ((100vw - 360px) / (1440 - 360)))
    );
    font-weight: var(--brand-font-weight-light);
    color: var(--brand-primary-grey-color);
    margin-top: min(
      4px,
      calc(1px + (4 - 1) * ((100vw - 360px) / (1440 - 360)))
    );
  }
  .ratings-and-reviews__author span img {
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
    margin-top: 2px;
    height: 12px;
  }

  .ratings-and-reviews__votes {
    justify-content: flex-end;
    width: 100px;
    display: flex;
    font-size: min(
      16px,
      calc(12px + (16 - 12) * ((100vw - 360px) / (1440 - 360)))
    );
  }

  .ratings-and-reviews__up-vote,
  .ratings-and-reviews__down-vote {
    display: flex;
    align-items: center;
  }

  .ratings-and-reviews__up-vote span,
  .ratings-and-reviews__down-vote span {
    cursor: pointer;
  }

  .ratings-and-reviews__down-vote {
    margin-left: min(
      40px,
      calc(25px + (40 - 25) * ((100vw - 360px) / (1440 - 360)))
    );
    /* color: var(--brand-primary-grey-color); */
  }

  .selected_vote {
    color: var(--brand-primary-black);
    color: red;
  }
  .deselected_vote {
    color: var(--brand-primary-grey-color);
  }

  .ratings-and-reviews__down-vote button,
  .ratings-and-reviews__up-vote button {
    border: none;
    background-color: transparent;
  }

  .ratings-and-reviews__down-vote p,
  .ratings-and-reviews__up-vote p {
    &[data-selected='true'] {
      color: var(--brand-primary-black);
    }
    &[data-selected='false'] {
      color: var(--brand-primary-grey-color);
    }
  }

  .ratings-and-reviews__votes p {
    margin: 0;
    margin-right: 0.5rem;
  }

  @media (min-width: 768px) {
    .ratings-and-reviews__author span img {
      height: var(--dls-size-16);
    }
  }
`
