import {ErrorLoggerFunction} from '@mosaic-wellness/fe-types'

let logger: ErrorLoggerFunction = (error, meta) => {
  console.error(error, meta)
}

const initializeLogger = (loggerFunction: ErrorLoggerFunction) => {
  logger = loggerFunction
}

export {initializeLogger, logger}
