import {HttpClient, useConfigStore, useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useEffect, useState} from 'react'
import {useGenericActions} from '../../../hooks'

const useCredBanner = (props: any) => {
  const axiosInstance = HttpClient.getAxiosInstance()
  const {genericAction} = useGenericActions()

  const {
    user: {isLoggedIn},
  } = useUserStore()
  const [credData, setCredData] = useState(null)
  const [showClaimSheet, setShowClaimSheet] = useState(false)
  const {analytics} = useConfigStore()

  const credResponse = useCallback(async () => {
    try {
      if (!props?.onLoadAction) {
        return
      }
      const response = await genericAction(props?.onLoadAction)
      analytics.push({
        'CRED Coin Eligibility': response?.isEligible,
      })
      setCredData(response)
    } catch (error) {
      //noop
    }
  }, [axiosInstance, props?.onLoadAction])

  useEffect(() => {
    if (isLoggedIn) {
      credResponse()
    } else {
      setCredData(null)
    }
  }, [isLoggedIn])

  const handleShowClaimSheet = useCallback(() => {
    if (credData?.isUserValidated) {
      genericAction(credData?.banner?.cta?.action)
    } else {
      setShowClaimSheet((prev) => !prev)
    }
  }, [credData, genericAction])

  return {credData, showClaimSheet, handleShowClaimSheet}
}

export default useCredBanner
