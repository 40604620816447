import styled from 'styled-components'

export const TrustMarkersWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .trustMarkers-container {
    width: calc(100% - 42px);
    padding: 14px;
    border-radius: 16px;
    display: flex;
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-normal);
    background-color: ${(props) => props.bgColor};
    border: 1px solid ${(props) => props.borderColor};
    border-radius: 16px;
  }

  .each-trustmarker {
    flex: 1 1 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${(props) => props.dividerBorderColor};
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  .each-trustmarker-image-outer-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .each-trustmarker-image-inner-container {
    overflow: hidden;
  }

  .each-trustmarker-image {
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  .each-trustmarker-label {
    color: var(--brand-font-default-color);
  }

  @media (min-width: 768px) {
    margin-bottom: 100px;
    .trustMarkers-container {
      width: 900px;
    }
  }
`
