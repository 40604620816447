// @ts-nocheck
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useEffect, useMemo, useState} from 'react'

import Responsive from '../../../../../ComponentsV2/HOC/Responsive'
import RatingsContainer from '../../../../../ComponentsV2/Screens/LJ-PDP/RatingsAndReview/RatingsContainer'
import OptimizedImage from '../../../../../ComponentsV2/common/OptimizedImage'
import PhotoPreview from '../PhotoPreview'
import {StyledReviewContainer} from './styles'

function Ratings(props) {
  const {data, isMobile = false, actions} = props || {}
  const {
    handleLoadReviewsWithImages = noop,
    handleLoadMoreReviewsWithImages = noop,
    disableBodyScroll = noop,
    enableBodyScroll = noop,
  } = actions || {}
  const {
    loadedPhotosPreview = [],
    loadedReviewsMap,
    moreImagesToLoad,
    ratingsData,
    rating,
    overAllRating,
    totalNumberOfReviews,
    ratingsStats,
    avgRating = 4,
    reviewsProvider,
  } = data || {}
  const {category = ''} = ratingsData || {}
  const [showMoreImages, setShowMoreImages] = useState(false)
  const [startPositionForMoreImage, setStartPositionForMoreImage] = useState(0)

  const toggleMoreImages = (event) => {
    if (showMoreImages) {
      enableBodyScroll()
    } else {
      disableBodyScroll()
    }
    const imageNode = event.target
    const itemPosition = imageNode.getAttribute('data-index')
    setShowMoreImages((p) => !p)
    setStartPositionForMoreImage(itemPosition)
  }

  const ratingsContainerArgs = useMemo(() => {
    return {
      overAllRating: overAllRating || avgRating || rating,
      totalNumberOfReviews,
      ratingsStats,
      isMobile,
    }
  }, [
    overAllRating,
    avgRating,
    rating,
    totalNumberOfReviews,
    ratingsStats,
    isMobile,
  ])

  useEffect(() => {
    handleLoadReviewsWithImages()
  }, [])

  if (isEmpty(data)) {
    return null
  }

  let showPlus = loadedPhotosPreview.length - 2

  if (showPlus > 10) {
    showPlus = 10
  }
  return (
    <div data-product-category={category} data-reviewssection="top">
      <StyledReviewContainer>
        <RatingsContainer {...ratingsContainerArgs} />
        {reviewsProvider === 'yotpo' && loadedPhotosPreview.length > 0 && (
          <div className="right">
            <div className="review_images_container">
              {loadedPhotosPreview?.map((image, index) => {
                if (index > 2) return null
                return (
                  <div className="image_item" key={image + index}>
                    <OptimizedImage
                      source={image?.image}
                      aspectWidth="150"
                      aspectHeight="150"
                      desktopViewWidth="THIRD"
                      mobileViewWidth="THIRD"
                      altText={'user uploaded image- ' + image.altText}
                      loading="lazy"
                      customClassName=""
                      isMobile={false}
                      onImageClick={toggleMoreImages}
                      data-index={index}
                    />
                    {index === 2 && (
                      <div
                        className="more_images"
                        onClick={toggleMoreImages}
                        data-index={index}
                      >
                        <span>{showPlus}+</span>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </StyledReviewContainer>
      {showMoreImages && (
        <PhotoPreview
          data={{loadedPhotosPreview, loadedReviewsMap}}
          state={{loadedPhotosPreview, moreImagesToLoad}}
          actions={{handleLoadMoreReviewsWithImages}}
          onClose={toggleMoreImages}
          startIndex={startPositionForMoreImage}
        />
      )}
    </div>
  )
}

export default Responsive(Ratings)
