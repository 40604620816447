import React from 'react'

function Icon({height = 20, width = 22}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        fill="#6C6C6C"
        stroke="#6C6C6C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        opacity={0.2}
        d="M11 1l3.09 5.925 6.91.95-5 4.613L17.18 19 11 15.925 4.82 19 6 12.488 1 7.875l6.91-.95L11 1z"
      ></path>
    </svg>
  )
}

export default Icon
