import styled from 'styled-components'

export const Wrapper = styled.div`
  .overlay-background {
    background: var(--brand-primary-black-color);
    opacity: 0.3;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 5;
  }
  .overlay-content-container {
    font-size: 16px;
    font-weight: var(--brand-font-weight-medium);
    color: var(--dls-white-color);
    border-radius: var(--dls-size-32);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 6;
    height: 100%;
    width: 100%;
  }

  .overlay-content {
    background-color: var(--dls-primary-text-colors);
    padding: var(--dls-size-12) var(--dls-size-12);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--dls-size-40);
  }

  .message-container {
    margin-left: var(--dls-size-8);
  }
`
