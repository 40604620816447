import {useState, useEffect, useRef} from 'react'
import {HybridLayoutProps} from './HybridLayout.types'

const useHybridLayout = (props: HybridLayoutProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [parentWidth, setParentWidth] = useState(0)

  useEffect(() => {
    if (containerRef.current) {
      setParentWidth(containerRef.current.offsetWidth)
    }
  }, [])

  return {parentWidth, containerRef}
}

export default useHybridLayout
