import React, {useCallback, useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {SkipModalWrapper} from './SkipModal.styles'
import Modal from '../../../../ComponentsV2/common/Modal'
import {Spinner} from '../../../../ComponentsV2/common'

const SkipModal = (props) => {
  const {
    que = '',
    heading = '',
    buttonText = '',
    fallbackImages = [],
    showSkipModal = false,
    skipImageFirst = null,
    skipImageSecond = null,
    errorMessage = '',
    uploadError = false,
    isLoading = false,
  } = props.state || {}

  const {
    handleSkipImageClick = noop,
    handleSubmit = noop,
    handleSkipModalClose = noop,
  } = props.actions || {}

  const buttonDisable = useMemo(() => {
    return !(skipImageFirst || skipImageSecond)
  }, [skipImageFirst, skipImageSecond])

  const handleImageClicked = useCallback(
    (image) => {
      handleSkipImageClick(image)
    },
    [handleSkipImageClick]
  )

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <SkipModalWrapper>
      <Modal
        showModal={showSkipModal}
        onClose={handleSkipModalClose}
        additionalContentClass="skip-modal"
      >
        <div className="skip-modal__title-container">
          <div className="skip-modal__question">{que}</div>
          <div className="skip-modal__title">{heading}</div>
        </div>
        <div className="skip-modal__images-container">
          {fallbackImages.map((imageData, index) => {
            const {image = '', alt = ''} = imageData
            return (
              <>
                <div
                  className="skip-modal__image-container"
                  key={index}
                  onClick={() => handleImageClicked(image)}
                  data-is-active={
                    image === skipImageFirst || image === skipImageSecond
                  }
                >
                  <img src={image} alt={alt} className="skip-modal__image" />
                </div>
              </>
            )
          })}
        </div>
        {uploadError && (
          <div className="skip-modal__error-text">{errorMessage}</div>
        )}
        <div className="skip-modal__submit-button-container">
          <button
            className="skip-modal__submit-button"
            disabled={buttonDisable}
            onClick={handleSubmit}
          >
            {isLoading ? <Spinner size={25} /> : buttonText}
          </button>
        </div>
      </Modal>
    </SkipModalWrapper>
  )
}

export default SkipModal
