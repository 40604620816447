import React from 'react'
import {HeadingComponentWrapper} from './HeadingComponentWrapper.styles'
import {HEADING_TAGS_MAPPING} from '../../../constants/headingTagsMapping'

function HeadingComponent({
  headingLevel,
  customClassName,
  label,
  isDangerouslySet = false,
}) {
  if (isDangerouslySet) {
    return (
      <HeadingComponentWrapper
        className={customClassName}
        dangerouslySetInnerHTML={{__html: label}}
      />
    )
  }

  return (
    <HeadingComponentWrapper
      as={HEADING_TAGS_MAPPING[headingLevel]}
      className={customClassName}
    >
      {label}
    </HeadingComponentWrapper>
  )
}

export default HeadingComponent
