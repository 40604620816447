import React from 'react'

import {ScheduleHeaderContainer} from './ScheduleHeader.styles'

function ScheduleHeader(props) {
  const {title = '', subtitle = ''} = props.state || {}
  return (
    <ScheduleHeaderContainer>
      <h1 className="schedule-header__title">{title}</h1>
      <h2 className="schedule-header__subTitle">{subtitle}</h2>
    </ScheduleHeaderContainer>
  )
}

export default ScheduleHeader
