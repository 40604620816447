import React from 'react'
import {StyledTrustMarkersWidget, TrustContainer} from './styles'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'

interface TrustMarkersWidget {
  items: {
    iconUrl: string
    aspectRatio: number
    title: string
    subTitle: string
  }[]
}

const TrustMarkers = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<TrustMarkersWidget>) => {
  const {items = []} = widgetData

  if (!items || isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledTrustMarkersWidget>
        <TrustContainer>
          <div className="outer">
            <div className="inner">
              {items.map((trust, index) => (
                <div className="inner_card_item" key={index}>
                  <div className="inner_card_imgs_container">
                    <OptimizedImage
                      source={trust?.iconUrl}
                      aspectWidth={64}
                      aspectHeight={64}
                      desktopViewWidth="FULL"
                      mobileViewWidth="FULL"
                      altText="oops"
                      loading="lazy"
                      customClassName="trust_card_img"
                    />
                  </div>

                  <div className="detail">
                    <div
                      className="card_details_title"
                      dangerouslySetInnerHTML={{
                        __html: trust?.title,
                      }}
                    />

                    <Typography
                      variant={TypographyVariants.X_SMALL}
                      customClassName="card_details_subtitle"
                    >
                      {trust?.subTitle}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </TrustContainer>
      </StyledTrustMarkersWidget>
    </BaseWidgetComponent>
  )
}

export default TrustMarkers
