import {checkIsApp} from 'src/utils/checkIsApp'
import {getAnalyticsData} from './thankYouV2.util'

export const processThankYouPageCTAClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const analyticsData = getAnalyticsData(data)
  const {mappedOrderData} = analyticsData || {}
  const {productUrlKey, name, sku, category} = mappedOrderData || {}
  const {
    couponCodeApplied,
    couponCode,
    chargedId,
    rxProduct,
    type,
    shippingCharged,
    shipping,
    discount,
    freeGiftItems,
    total,
    totalItems,
  } = analyticsData || {}

  const dataToTrack = {
    'Product ID': productUrlKey,
    'Product Name': name,
    'SKU Code': sku,
    Categories: category,
    'Coupon Applied ': couponCodeApplied,
    'Coupon Code ': couponCode,
    'Charged ID': chargedId,
    'Rx Product ': rxProduct,
    Type: type,
    'Total Items ': totalItems,
    amount: total,
    'Shipping Charged': shippingCharged,
    'Shipping Amount': shipping,
    'Discount Amount': discount,
    'Free Gift Items': freeGiftItems,
    'CTA label': data?.label,
    Section: data?.section,
  }

  return dataToTrack
}

export const processThankYouPageViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const analyticsData = getAnalyticsData(data)
  const {mappedOrderData} = analyticsData || {}
  const {productUrlKey, name, sku, category} = mappedOrderData || {}
  const {
    couponCodeApplied,
    couponCode,
    chargedId,
    rxProduct,
    type,
    shippingCharged,
    shipping,
    discount,
    freeGiftItems,
    total,
    totalItems,
    orderType,
    orderId,
    chatFlow,
    chatProduct,
  } = analyticsData || {}
  const isApp = checkIsApp()

  const dataToTrack = {
    'Product ID': productUrlKey,
    'Product Name': name,
    'SKU Code': sku,
    Categories: category,
    'Coupon Applied ': couponCodeApplied,
    'Coupon Code ': couponCode,
    'Charged ID': chargedId,
    'Rx Product ': rxProduct,
    Type: type,
    'Total Items ': totalItems,
    amount: total,
    'Shipping Charged': shippingCharged,
    'Shipping Amount': shipping,
    'Discount Amount': discount,
    'Free Gift Items': freeGiftItems,
    Webview: isApp ? 'Y' : 'N',
    'Chat flow': chatFlow ? 'Y' : 'N',
    'Chat product': chatProduct,
    'Order Type': orderType,
    'Order Id': orderId,
  }

  return dataToTrack
}

export const processThankYouBranchPurchased = (data: any) => {
  const {billingDetails = {}, orderSummary = {}} = data || {}
  const {receiptV2} = billingDetails || {}
  const {analyticsInfo} = receiptV2 || {}
  const {itemTotal = 0} = analyticsInfo || {}

  return {
    transactionID: orderSummary?.chargedId || '',
    revenue: itemTotal,
  }
}
