const {withErrorBoundary} = require('react-error-boundary')

function ErrorFallback({error, resetErrorBoundary}) {
  return null
}

export const withCustomErrorBoundary = (Component, name) =>
  withErrorBoundary(Component, {
    FallbackComponent: ErrorFallback,
    onError: (error, info) => {
      window?.newrelic?.noticeError?.(error, {name, stack: info.componentStack})
    },
  })
