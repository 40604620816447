import {IMediaWithHeaderCardProps} from './MediaWithFooterCard.interface'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {MediaWithFooterCardContainer} from './MediaWithFooterCard.styles'
import {useCallback, useEffect, useRef, useState} from 'react'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'

function MediaWithFooterCard(props: IMediaWithHeaderCardProps) {
  const {
    header = '',
    image = '',
    imageDimensions,
    description = '',
    readMoreLabel = '',
    miniCtas = [],
    onCardClick = noop,
    handleAction = noop,
  } = props
  const {width = '140', height = '120'} = imageDimensions || {}

  const [showReadMoreButton, setShowReadMoreButton] = useState(false)
  const [showFullText, setShowFullText] = useState(false)
  const descriptionRef = useRef<HTMLElement>()

  useEffect(() => {
    if (!descriptionRef.current) {
      return
    }

    if (
      descriptionRef.current.offsetHeight <
        descriptionRef.current.scrollHeight ||
      descriptionRef.current.offsetWidth < descriptionRef.current.scrollWidth
    ) {
      setShowReadMoreButton(true)
    }
  }, [])

  const handleReadMoreClick = useCallback((e) => {
    e.stopPropagation()
    setShowFullText(true)
  }, [])

  const handleCardClick = useCallback(() => {
    onCardClick({description, imageDimensions, image})
  }, [description, image, imageDimensions, onCardClick])

  if (!image) {
    return null
  }

  return (
    <MediaWithFooterCardContainer onClick={handleCardClick}>
      <div className="card-image-container">
        <OptimizedImage
          source={image}
          aspectWidth={width}
          aspectHeight={height}
          customClassName="card-image"
        />
      </div>
      {!!header && (
        <div className="header-container">
          <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
            {header}
          </Typography>
        </div>
      )}
      {!!description && (
        <div className="description-container">
          <Typography
            variant={TypographyVariants.BODY_BASE_REGULAR}
            customClassName={`description-text ${
              !showFullText && 'line-clamp-4'
            }`}
            ref={descriptionRef}
          >
            {description}
          </Typography>
          {showReadMoreButton && !showFullText && (
            <button className="read-more-button" onClick={handleReadMoreClick}>
              <Typography variant={TypographyVariants.TAG_BOLD}>
                ... {readMoreLabel}
              </Typography>
            </button>
          )}
          {!isEmpty(miniCtas) && (
            <div className="mini-ctas-container">
              {miniCtas.map((minicta) => (
                <div
                  className="mini-cta"
                  onClick={() => handleAction(minicta.cta)}
                >
                  <OptimizedImage
                    source={minicta.icon}
                    aspectHeight="20"
                    aspectWidth="20"
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </MediaWithFooterCardContainer>
  )
}

export default MediaWithFooterCard
