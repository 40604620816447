export const processPendingOrderAppointmentSeen = (data: any) => {
  const {appointmentId, category, status} = data
  const dataToReturn = {
    'Appointment id': appointmentId,
    Category: category || '',
    Status: status || '',
  }

  return dataToReturn
}

export const processPendingOrderRescheduleSeen = (data: any) => {
  const {appointmentId, category, status} = data
  const dataToReturn = {
    'Appointment id': appointmentId,
    Category: category || '',
    Status: status || '',
  }

  return dataToReturn
}

export const processPendingOrderRescheduleSubmit = (data: any) => {
  const {category, language, ia, slot} = data
  const dataToReturn = {
    Category: category,
    Language: language,
    IA: ia,
    slot: slot || '',
  }

  return dataToReturn
}

export const processPendingOrderRescheduleConfirmed = (data: any) => {
  const {category, language, ia, slot} = data
  const dataToReturn = {
    Category: category,
    Language: language,
    IA: ia,
    slot: slot || '',
  }

  return dataToReturn
}
