import {useCallback, useEffect, useState} from 'react'
import {getFlag, useFlagsStore} from './useUtmExperimentsStore'
import {logError} from 'src/utils/logger'
import {HttpClient, useUserStore} from '@mosaic-wellness/redux-action-library'
import {BACKEND_BASE_PATH} from 'src/utils/constants/breakPoints'
import {isEmpty} from 'lodash'

const UTM_EXPERIMENT_TIMEOUT = 7 * 24 * 60 * 60 * 1000
const UTM_EXPERIMENT_KEY = 'UTMExperiment'
const UTM_EXPERIMENT_TIMEOUT_KEY = 'UTMExperimentTimeout'

const getUtmExperimentFromStorage = () => {
  try {
    const utmExperiment = window.localStorage.getItem(UTM_EXPERIMENT_KEY)
    const utmExperimentTimeout = window.localStorage.getItem(
      UTM_EXPERIMENT_TIMEOUT_KEY
    )
    if (utmExperimentTimeout) {
      const currentTime = new Date().getTime()

      if (currentTime > parseInt(utmExperimentTimeout)) {
        window.localStorage.removeItem(UTM_EXPERIMENT_KEY)
        window.localStorage.removeItem(UTM_EXPERIMENT_TIMEOUT_KEY)
        return null
      }
    }
    if (utmExperiment) {
      return JSON.parse(utmExperiment)
    }
  } catch (error) {
    logError(error as Error, {
      location: 'Error while fetching UTM Experiment from session storage',
      source: 'useUtmExperiments',
    })
  }

  return null
}

const setUTMExperimentInStorage = (utmExperiment: Record<string, string>) => {
  try {
    window.localStorage.setItem(
      UTM_EXPERIMENT_KEY,
      JSON.stringify(utmExperiment)
    )
    window.localStorage.setItem(
      UTM_EXPERIMENT_TIMEOUT_KEY,
      (new Date().getTime() + UTM_EXPERIMENT_TIMEOUT).toString()
    )
  } catch (error) {
    logError(error as Error, {
      location: 'Error while setting UTM Experiment in session storage',
      source: 'useUtmExperiments',
    })
  }
}

export const getFirstTouchUtmExperiment = async () => {
  const utmExperimentFromStorage = getUtmExperimentFromStorage()
  if (utmExperimentFromStorage) {
    return utmExperimentFromStorage
  }
  const urlParams = new URLSearchParams(window.location.search)
  const utmExperiment: Record<string, string> = {}

  Array.from(urlParams.keys())
    .filter((key) => key.startsWith('utm_'))
    .forEach((key) => {
      const paramValue = urlParams.get(key)
      if (paramValue) {
        utmExperiment[key] = paramValue
      }
    })

  if (utmExperiment) {
    try {
      setUTMExperimentInStorage(utmExperiment)
      return utmExperiment
    } catch (error) {
      logError(error as Error, {
        location: 'Error while setting UTM Experiment in session storage',
        source: 'useUtmExperiments',
      })
    }
  }
  return utmExperiment
}

export function objectToQueryString(obj: Record<string, string>) {
  return Object.keys(obj)
    .filter((key) => obj[key] !== undefined && obj[key] !== null)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

const getFlagsAgainstUtmExperiment = async (
  utmExperiment: Record<string, string>
) => {
  const axiosInstance = HttpClient.getAxiosInstance()
  const queryString = objectToQueryString(utmExperiment)
  try {
    const {data: response} = await axiosInstance.get(
      `${BACKEND_BASE_PATH}utility/campaign-events?${queryString}`
    )

    if (response?.data) {
      const flags = response.data
      return flags
    }
  } catch (error) {
    logError(error as Error, {
      location: 'Error while fetching flags against UTM Experiment',
      source: 'useUtmExperiments',
    })
    return null
  }
}
export const useUtmExperimentsBoot = () => {
  const setFlags = useFlagsStore((state) => state.setFlags)
  const {
    user: {repeatUser},
  } = useUserStore()
  const handleUtmExperiment = useCallback(async () => {
    const utmExperiment = await getFirstTouchUtmExperiment()

    if (utmExperiment) {
      const flagsToSet = await getFlagsAgainstUtmExperiment(utmExperiment)
      if (flagsToSet) {
        setFlags(flagsToSet)
      }
    }
  }, [setFlags])

  useEffect(() => {
    handleUtmExperiment()
  }, [handleUtmExperiment, repeatUser])
}

export const useExperimentEnabled = (flagPath: string) => {
  const {flags} = useFlagsStore()

  const [flagEnabled, setFlagEnabled] = useState(false)

  useEffect(() => {
    if (!isEmpty(flags)) {
      setFlagEnabled(getFlag(flagPath).enable)
    }
  }, [flagPath, flags])

  return {
    flagEnabled,
  }
}
