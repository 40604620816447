import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {MenuRowItemWidgetProps} from './types'
import {useCallback} from 'react'
import {useGenericActions} from '../../hooks'
import {MenuRowItem} from '@web-components'
import {StyledMenuRowItem} from './MenuRowItem.styles'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

const MenuRowItemWidget = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<MenuRowItemWidgetProps>) => {
  const {genericAction} = useGenericActions()

  const handleCardClick = useCallback(() => {
    const {action} = widgetData || {}
    if (action) {
      genericAction(interceptAndEnhanceAction(action, id))
    }
  }, [genericAction, id, widgetData])

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledMenuRowItem>
        <MenuRowItem {...widgetData} onClick={handleCardClick} />
      </StyledMenuRowItem>
    </BaseWidgetComponent>
  )
}

export default MenuRowItemWidget
