import styled from 'styled-components'

export const StyledWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;
  @media (min-width: 768px) {
    margin-bottom: 100px;
  }

  .container {
    width: 100%;
    max-width: 1120px;
    align-self: center;
    /* background-color: var(--category-primary-bg1-color); */
  }

  .banner-image {
    width: 100%;
    height: auto;
  }
`

export const ContainerWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: var(--category-primary-bg1-color);
  background-color: ${({background}) =>
    background ? 'transparent' : 'var(--category-primary-bg1-color)'};

  margin-top: -4px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding-bottom: 100px;
  }
`
export const StyledTabs = styled.div`
  min-height: 44px;
  display: flex;
  justify-content: center;
  padding: 0 11px;
  margin-top: 41px;

  .tab-title {
    display: inline-block;

    @media (min-width: 768px) {
      height: 64px;
      border-width: 4px;
    }
  }

  .tab {
    display: inline-block;
    background: transparent;
    border: none;
    min-height: 35px;
    color: #2b2929;
    height: auto;
    padding: 0 0 10px;
    width: max-content;
    font-weight: var(--brand-font-weight-medium);
    font-size: 18px;
    line-height: 140%;
    cursor: pointer;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    opacity: 0.5;
    transition: all 0.4s;
    &[data-active='true'] {
      opacity: 1;
      border-color: #2b2929;
      color: var(--brand-primary-black-color);
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    @media (min-width: 768px) {
      font-weight: var(--brand-font-weight-bold);
      font-size: 30px;
      line-height: 52px;
      height: 64px;
      border-width: 4px;

      &:not(:last-child) {
        margin-right: 21px;
      }
    }
  }
`

export const StyledContent = styled.div`
  padding: 20px 20px;

  @media (min-width: 768px) {
    padding-top: 60px;
  }
`

export const StyledTextContent = styled.div``

export const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  ${({background}) => (background ? `background-color: ${background}` : '')};

  .container-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const FooterImage = styled.div`
  position: absolute;
  width: 100%;
  height: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  .footer-bg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    height: 249px;
  }
`
