import styled from 'styled-components'

export const EmptySearchResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--dls-size-16);
  width: 100%;
  background-color: white;
  padding: 20px var(--dls-size-16) 20px var(--dls-size-16);
  .img-container {
    width: var(--dls-size-64);
    height: var(--dls-size-64);
  }
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--dls-size-4);
  }
  .sub-title-text {
    color: var(--dls-secondary-text-colors);
  }
`
