import styled from 'styled-components'

export const ImageGridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--dls-size-8);

  .image-item {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`
