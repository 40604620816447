import {useAuth} from '@mosaic-wellness/redux-action-library'
import {useCallback, useEffect} from 'react'
import {useAuthorizationStore} from './useAuthorizationStore'

interface NavigationData {
  link: string
}
export const useAuthorizedNav = () => {
  const {
    state: {
      showLoginModal: {isOpen},
    },
    actions: {setShowLoginModal},
  } = useAuth()
  const setNavTarget = useAuthorizationStore((store) => store.setNavTarget)
  const handleAuthorizedNav = useCallback(
    (navData: NavigationData, triggeredFrom: string) => {
      setNavTarget(navData)
      setShowLoginModal({isOpen: true, triggeredFrom: triggeredFrom})
    },
    [setNavTarget, setShowLoginModal]
  )

  return {
    handleAuthorizedNav,
  }
}
