import {BACKEND_BASE_PATH} from 'src/utils/constants/breakPoints'

export const API_END_POINTS = {
  GET_PRODUCTS_BY_IDS: 'product/recently-viewed', // ? using an existing endpoint and hence the no-intutiveness of API endpoint
  GET_FORM_DATA: 'growth/post-order-redirect',
  SUBMIT_FORM_DATA: 'growth/add-child-multiple',
  PINCODE_V2: 'utility/mwsc/v2/pincode',
  SET_APPOINTMENT_LANGUAGE: '/consult/appointment/language',
  CHECK_PRESCRIPTION_V2: 'consult/v2/check-prescription',
  GET_REWARD_METHODS_DATA: 'wallet/twid', //TWID Pay
  VERIFY_CARD_FOR_REWARD: 'wallet/twid/verify', //verify card against bins for card reward option
  ROOK_CONNECTION_PAGE_V2: `${BACKEND_BASE_PATH}growth/get-connection-page-details`,
  ROOK_MODIFY_GOALS: `${BACKEND_BASE_PATH}growth/rook-modify-patient-goals`,
  ROOK_HEALTH_PAGE: `${BACKEND_BASE_PATH}growth/rook-health-page`,
  ROOK_COLLECT_GOAL_REWARD: `${BACKEND_BASE_PATH}growth/goals/reward`,
  ROOK_CHOOSE_GOALS_API: `${BACKEND_BASE_PATH}growth/fetch-goal-selection-details`,
  ROOK_CONFIRM_GOALS: `${BACKEND_BASE_PATH}growth/set-user-health-goals`,
  AUTO_PAY_REQUEST: `${BACKEND_BASE_PATH}payment/mandate/request`,
  GET_REGION_DATA: `${BACKEND_BASE_PATH}utility/mwsc/v2/pincode`,
  WALLET_TRANSACTIONS: `${BACKEND_BASE_PATH}wallet/transactions`,
  GET_WALLET_TRANSACTION_REWARDS: `${BACKEND_BASE_PATH}wallet/reward-points`,
  VERIFY_VPA: `${BACKEND_BASE_PATH}payment/vpa/verify`,
  POST_ATTRIBUTION_DATA: `${BACKEND_BASE_PATH}user/data`,
  ORDER_DETAILS: `${BACKEND_BASE_PATH}order/v2`,
  THANK_YOU_PAGE: `${BACKEND_BASE_PATH}order/v3`,
  ORDER_LISTING: `${BACKEND_BASE_PATH}order`,
  CHANGE_ADDRESS_FOR_ORDER: 'order/change-address',
  TRACK_ORDER: 'order/track-order',
  CANCEL_ORDER: 'order/v2/cancelOrder',
}
