import React from 'react'
import {Button, ButtonProps, Typography} from '@web-components'

interface NodeProps {
  type: string
  props?: Record<string, any>
  children?: BaseNode[] | string
}

export type BaseNode = NodeProps | string

export function renderNodes(node: BaseNode): React.ReactNode {
  if (typeof node === 'string') {
    return node
  }

  const {type, props = {}, children = []} = node

  const childNodes = Array.isArray(children)
    ? children.map(renderNodes)
    : [children].map(renderNodes)

  switch (type) {
    case 'View':
      return <div {...props}>{childNodes}</div>
    case 'Text':
      return <Typography {...props}>{childNodes}</Typography>
    case 'Button':
      return <Button {...(props as ButtonProps)} />
    case 'Link':
      return <a {...props}>{childNodes}</a>
    case 'Image':
      return <img alt="default alt text" {...props} />
    default:
      console.warn(`Unknown node type: ${type}`)
      return null
  }
}

export default renderNodes
