import React from 'react'

import {SVGContainer} from '../DirectAssesment.styles'

function CheckIcon(props) {
  const {className, style, category = ''} = props

  return (
    <SVGContainer
      width="18"
      height="18"
      viewBox="0 0 18 18"
      style={style}
      className={className}
      data-category={category}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00008 1.91699C5.09425 1.91699 1.91675 5.09449 1.91675 9.00033C1.91675 12.9062 5.09425 16.0837 9.00008 16.0837C12.9059 16.0837 16.0834 12.9062 16.0834 9.00033C16.0834 5.09449 12.9059 1.91699 9.00008 1.91699M9.00008 17.3337C4.40508 17.3337 0.666748 13.5953 0.666748 9.00033C0.666748 4.40533 4.40508 0.666992 9.00008 0.666992C13.5951 0.666992 17.3334 4.40533 17.3334 9.00033C17.3334 13.5953 13.5951 17.3337 9.00008 17.3337"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8594 12.0773C11.7503 12.0773 11.6403 12.0489 11.5394 11.9898L8.39777 10.1156C8.20944 10.0023 8.09277 9.79809 8.09277 9.57809V5.53809C8.09277 5.19309 8.37277 4.91309 8.71777 4.91309C9.06361 4.91309 9.34277 5.19309 9.34277 5.53809V9.22309L12.1803 10.9148C12.4761 11.0923 12.5736 11.4756 12.3969 11.7723C12.2794 11.9681 12.0719 12.0773 11.8594 12.0773"
      />
    </SVGContainer>
  )
}

export default CheckIcon
