import {memo, useEffect, useMemo} from 'react'
import {createPortal} from 'react-dom'

const Portal = ({children}) => {
  const hasWindow = typeof window !== 'undefined'
  const mount = useMemo(
    () => hasWindow && window?.document?.getElementById('portal-root'),
    [hasWindow]
  )
  const el = useMemo(
    () => hasWindow && window?.document?.createElement('div'),
    [hasWindow]
  )
  useEffect(() => {
    mount?.appendChild(el)
    return () => mount?.removeChild(el)
  }, [el, mount])

  if (!hasWindow) {
    return null
  }

  return createPortal(children, el)
}

export default memo(Portal)
