import {useMemo} from 'react'
import noop from 'lodash/noop'
import Spinner from '../../../ComponentsV2/common/Spinner'

import {NextButtonContainer} from './NextButton.styles'
import Responsive from '../../../ComponentsV2/HOC/Responsive'

function NextButton(props) {
  const {state = {}, actions = {}, isMobile = false} = props
  const {
    label = 'Next',
    className = '',
    isDisabled = false,
    isLoading = false,
  } = state
  const {onClick = noop} = actions

  const spinnerSize = useMemo(() => (isMobile ? 16 : 20), [isMobile])

  return (
    <NextButtonContainer className={className}>
      <button
        disabled={isDisabled || isLoading}
        className="next-button"
        onClick={onClick}
      >
        {isLoading ? <Spinner size={spinnerSize} color={'black'} /> : label}
      </button>
    </NextButtonContainer>
  )
}

export default Responsive(NextButton)
