import React from 'react'

import DownArrow from '../../../Screens/Checkout/Payment/BillDetails/DownArrow'
import {
  ChildComponentProps,
  ChildDetails,
  ChildGender,
  FormInput,
} from './ChildDetails.interface'
import DeleteIcon from './DeleteIcon'
import Switch from './Switch/Switch'
import {SwitchWrapper} from './Switch/Switch.styles'

const ChildForm = (props: ChildComponentProps) => {
  const {
    childDetails,
    expandedIndex,
    childSection,
    childLabel,
    genders,
    formData,
  } = props.state || {}
  const {
    toggleAccordionItem,
    removeChild,
    handleChildGenderChange,
    handleChildDetailChange,
    handleUpdateLater,
    handleHeightUnitChange,
  } = props.actions || {}

  const [value, setValue] = React.useState(true)
  function handleToggleSwitch(index) {
    const newValue = !value ? 'feet' : 'cms'
    setValue(!value)
    handleHeightUnitChange(index, newValue)
  }

  return childDetails.map((item: ChildDetails, index: number) => {
    const {childName = ''} = item || {}
    const header = childName ? childName : `Child ${index + 1}`
    return (
      <>
        <div key={index} className="accordion-item">
          <div
            className={`accordion-header ${
              expandedIndex === index ? 'expanded' : ''
            }`}
            onClick={() => toggleAccordionItem(true, index)}
          >
            <div className="accordioin-title">
              {item.deleteIcon && (
                <span onClick={() => removeChild(index)}>
                  <DeleteIcon />
                </span>
              )}

              <span className="divTitle">{header}</span>
            </div>
            <span className={`${expandedIndex === index ? 'rotateArrow' : ''}`}>
              <DownArrow width={14} height={14} />
            </span>
          </div>
          {expandedIndex === index && (
            <div className="accordion-content">
              <div id={childSection}>
                <span className="divTitle">{childLabel}</span>
                <div className="genderContainer">
                  {genders?.map((gender: ChildGender, genderId: number) => {
                    const {
                      id = '',
                      title = '',
                      icon = '',
                      iconAlt = 'child image',
                    } = gender || {}
                    return (
                      <label
                        htmlFor={id}
                        className="child"
                        onClick={() => handleChildGenderChange(id, index)}
                        key={genderId}
                      >
                        <input
                          type="radio"
                          id={id}
                          data-index={index}
                          name="childGender"
                          value={id}
                          className="childGender"
                          checked={item.childGender === id}
                          required
                        />
                        <img
                          src={icon}
                          alt={iconAlt}
                          className={`childImg ${
                            item.childGender === id ? 'checkedChild' : ''
                          }`}
                        />
                        <span>{title}</span>
                      </label>
                    )
                  })}
                </div>

                {formData?.map((element: FormInput) => {
                  const {
                    id,
                    label,
                    type,
                    disabled,
                    class: className,
                    selectorOptions = ['cms', 'feet'],
                    required,
                    placeholder,
                    updateLater,
                  } = element || {}
                  switch (type) {
                    case 'text':
                    case 'date':
                    case 'number':
                      return (
                        <div key={id} className={`${className} childDiv`}>
                          <label htmlFor={id} className="divTitle">
                            {label}
                          </label>
                          <input
                            type={type}
                            id={id}
                            name={id}
                            value={item[id] || ''}
                            onChange={(e) =>
                              handleChildDetailChange(index, id, e.target.value)
                            }
                            placeholder={placeholder}
                            required={
                              id == 'currentWeight'
                                ? !childDetails[index].updateLatercurrentWeight
                                : required
                            }
                            disabled={disabled}
                          />
                          {updateLater?.type && (
                            <div className="updateContainer">
                              <input
                                type={updateLater.type}
                                className="checkbox"
                                checked={
                                  childDetails[index].updateLatercurrentWeight
                                }
                                onChange={(e) =>
                                  handleUpdateLater(
                                    e,
                                    index,
                                    'updateLatercurrentWeight'
                                  )
                                }
                              />
                              <label>{updateLater.title}</label>
                            </div>
                          )}
                        </div>
                      )
                    case 'selector':
                      return (
                        <div key={id} className={`${className}`}>
                          <label
                            htmlFor={id}
                            className={`${className} height divTitle`}
                          >
                            {label}
                          </label>
                          <div className="childHeight">
                            {value && (
                              <>
                                <input
                                  type="number"
                                  value={item.currentHeight.feet}
                                  placeholder="feet"
                                  name="currentHeight"
                                  required={
                                    childDetails[index].updateLatercurrentHeight
                                      ? false
                                      : required
                                  }
                                  onChange={(e) =>
                                    handleChildDetailChange(
                                      index,
                                      'currentHeight',
                                      {
                                        ...item.currentHeight,
                                        feet: e.target.value,
                                      }
                                    )
                                  }
                                />
                                <input
                                  type="number"
                                  value={item.currentHeight.inches}
                                  placeholder="inches"
                                  name="currentHeight"
                                  required={
                                    childDetails[index].updateLatercurrentHeight
                                      ? false
                                      : required
                                  }
                                  onChange={(e) =>
                                    handleChildDetailChange(
                                      index,
                                      'currentHeight',
                                      {
                                        ...item.currentHeight,
                                        inches: e.target.value,
                                      }
                                    )
                                  }
                                />
                              </>
                            )}

                            {!value && (
                              <input
                                type="number"
                                value={item.currentHeight.cms}
                                placeholder="cms"
                                name="currentHeight"
                                required={
                                  childDetails[index].updateLatercurrentHeight
                                    ? false
                                    : required
                                }
                                onChange={(e) =>
                                  handleChildDetailChange(
                                    index,
                                    'currentHeight',
                                    {
                                      ...item.currentHeight,
                                      cms: e.target.value,
                                    }
                                  )
                                }
                              />
                            )}
                            <SwitchWrapper>
                              <Switch
                                isOn={value}
                                handleToggle={() =>
                                  handleToggleSwitch(
                                    index,
                                    item.currentHeight.unit
                                  )
                                }
                                selected={value ? 'feet' : 'cms'}
                              />
                            </SwitchWrapper>
                          </div>
                          {updateLater?.type && (
                            <div className="updateContainer">
                              <input
                                type={updateLater.type}
                                className="checkbox"
                                checked={
                                  childDetails[index].updateLatercurrentHeight
                                }
                                onChange={(e) =>
                                  handleUpdateLater(
                                    e,
                                    index,
                                    'updateLatercurrentHeight'
                                  )
                                }
                              />
                              <label>{updateLater.title}</label>
                            </div>
                          )}
                        </div>
                      )
                    default:
                      return null
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </>
    )
  })
}

export default ChildForm
