import {HttpClient, useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useEffect, useRef} from 'react'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {docCookies} from 'src/utils/cookie'
import {logError} from 'src/utils/logger'

const WISHLINK_ID = process.env.NEXT_PUBLIC_WISHLINK_ID
const buildURL = (baseUrl: string, params: Record<string, any>) => {
  // Create URL object from the base URL
  const url = new URL(baseUrl)

  // Loop through params and only add non-empty values
  Object.entries(params).forEach(([key, value]) => {
    // Add parameter only if it's not null, undefined, or empty string
    if (value !== null && value !== undefined && value !== '') {
      url.searchParams.set(key, value)
    }
  })

  return url.toString()
}

/**
 * ? WHY all of this?
 * we would want to attribute user interaction to the traffic source even if user abandons a session and,
 * returns to the site later with in a pre-specified time period - currently 7 Days
 */
export const useUTM = () => {
  const {
    user: {isLoggedIn},
  } = useUserStore()

  const identifiedParams = useRef<null | Record<string, any>>(null)
  const handleUTMReporting = useCallback(
    async (params: Record<string, any>) => {
      if (!params.atgSessionId) {
        return
      }

      const axiosInstance = HttpClient.getAxiosInstance()

      try {
        await axiosInstance.post(API_END_POINTS.POST_ATTRIBUTION_DATA, params)
      } catch (error) {
        logError(error as Error, {
          location: 'useUTM.ts',
          source: 'Error while handling UTM reporting',
        })
      }
    },
    []
  )

  const handleWishLink = useCallback(async () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const utmSource = urlParams.get('utm_source') || ''
    try {
      if (utmSource === 'wishlink') {
        const utmMedium = urlParams.get('utm_medium') || ''
        const utmCampaign = urlParams.get('utm_campaign') || ''
        const atgSessionId = urlParams.get('atgSessionId') || ''
        const fbclid = urlParams.get('fbclid') || ''
        const gclid = urlParams.get('gclid') || ''

        const baseURL = 'https://api.wishlink.com/api/brandUserLanding'
        const params = {
          platform: WISHLINK_ID,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          atgSessionId,
          ...(fbclid && {fbclid}),
          ...(gclid && {gclid}),
        }

        identifiedParams.current = params

        const finalURL = buildURL(baseURL, params)

        const img = document.createElement('img')
        img.src = finalURL
        img.id = 'wishlink-attribution'
        img.alt = 'wishlink-attribution'
        document.body.appendChild(img)
      }
    } catch (error) {
      logError(error as Error, {
        location: 'useUTM.ts',
        source: 'Error while handling wishlink',
      })
    }
  }, [])

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const utmSource = urlParams.get('utm_source') || ''
    const utmMedium = urlParams.get('utm_medium') || ''
    const utmCampaign = urlParams.get('utm_campaign') || ''

    /**
     * * We need to check if any of the values are present.
     * ? WHY?
     * * even if one of the utm parameter being present implies the user landed through a different traffic source
     * and hence we need to update the other parameters as well even if they are empty
     */
    if (utmSource || utmMedium || utmCampaign) {
      docCookies.setItem({
        cookieName: 'utm_source',
        cookieValue: utmSource,
        expiry: 7 * 24 * 60 * 60,
      })
      docCookies.setItem({
        cookieName: 'umt_medium',
        cookieValue: utmMedium,
        expiry: 7 * 24 * 60 * 60,
      })
      docCookies.setItem({
        cookieName: 'utm_campaign',
        cookieValue: utmCampaign,
        expiry: 7 * 24 * 60 * 60,
      })
    }
  }, [])

  useEffect(() => {
    if (WISHLINK_ID) {
      handleWishLink()
    }
  }, [handleWishLink])

  useEffect(() => {
    if (isLoggedIn) {
      if (identifiedParams.current) {
        handleUTMReporting(identifiedParams.current)
      }
    }
  }, [handleUTMReporting, isLoggedIn])
}
