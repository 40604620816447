import {getCategoryFromAppointmentCategory} from 'src/utils'

export const processDaSubmit = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = '', language = '', iaBooked = false, slot} = data

  return {
    Category: category,
    Language: language,
    'IA Booked': iaBooked ? 'Y' : 'N',
    'Slot Selected': slot,
  }
}

export const processDaBooked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    category = '',
    language = '',
    phoneNumber = '',
    slot = '',
    iaBooked = false,
    appointmentId = '',
    diagnosisData = {},
    imageAvailable = false,
  } = data

  return {
    Category: category,
    'IA Booked': iaBooked ? 'Y' : 'N',
    Language: language,
    'phone number': phoneNumber,
    slot: slot,
    'Appointment Id': appointmentId,
    'Self diagnosis category':
      getCategoryFromAppointmentCategory(diagnosisData?.category) || '',
    'Self diagnosis stage': diagnosisData?.stage?.join(',') || '',
    'Self diagnosis concern': diagnosisData?.concerns?.join(',') || '',
    'Self diagnosis severity': diagnosisData?.severity?.join(',') || '',
    'Image Available': imageAvailable,
  }
}

export const processDaError = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {api = '', errorCode = '', errorName = '', errorToast = ''} = data

  return {
    Api: api,
    'Error Code': errorCode,
    'Error Name': errorName,
    'Error Toast Message': errorToast,
  }
}
