import {TilesProps} from './Tiles.interface'
import {useGenericActions} from '../../hooks'
import {useMemo} from 'react'
import {GenericAction} from '@mosaic-wellness/fe-types'
import {useDeviceTypeContext} from '@web-components'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

export const useTiles = (widgetData: TilesProps) => {
  const {isMobile} = useDeviceTypeContext()
  const {genericAction} = useGenericActions()

  const {
    tiles = [],
    noOfColumnsForMobile = 3,
    noOfColumnsForDesktop = 3,
    aspectRatio = 1,
    scaleFullWidthDesktop = false,
    id,
  } = widgetData

  const noOfColumns = useMemo(
    () => (isMobile ? noOfColumnsForMobile : noOfColumnsForDesktop),
    [isMobile, noOfColumnsForDesktop, noOfColumnsForMobile]
  )

  const handleCtaClick = (cta: GenericAction) => {
    genericAction(interceptAndEnhanceAction(cta, id))
  }

  return {
    noOfColumns,
    handleCtaClick,
    tiles,
    aspectRatio,
    scaleFullWidthDesktop,
  }
}
