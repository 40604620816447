import React from 'react'
import {ToggleButtonContainer} from './ToggleButton.styles'

const ToggleButton = (props) => {
  const {toggle} = props.state || {}
  const {triggerToggle} = props.actions || {}

  return (
    <ToggleButtonContainer>
      <div
        onClick={triggerToggle}
        className="wrg-toggle"
        data-is-checked={toggle}
      >
        <div className="wrg-toggle-container" />
        <div className="wrg-toggle-circle" />
        <input
          type="checkbox"
          aria-label="Toggle Button"
          className="wrg-toggle-input"
        />
      </div>
    </ToggleButtonContainer>
  )
}

export default ToggleButton
