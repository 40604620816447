import {useEffect} from 'react'

const useOrientationChange = () => {
  useEffect(() => {
    const handleOrientationChange = () => {
      location.reload()
    }

    window.addEventListener('orientationchange', handleOrientationChange)

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])
}

export default useOrientationChange
