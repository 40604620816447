import styled from 'styled-components'

export const ImageSliderContainer = styled.div`
  .image-container {
    width: 100%;
  }
  .slider-image {
    border-radius: var(--dls-size-8);
    height: auto;
    object-fit: cover;
  }

  .dots {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-top: var(--dls-size-8);
  }

  .dot {
    height: 5px;
    width: 5px;
    flex-shrink: 0;
    margin: 0 2px;
    background-color: var(--dls-backgound-color-100);
    border-radius: 50%;

    &[data-is-active='true'] {
      height: 5px;
      width: 5px;
      background-color: var(--dls-black-color);
    }
  }

  &[data-is-LeftSpace='true'] {
    margin-left: var(--dls-size-16);
  }
`
