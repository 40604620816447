import styled from 'styled-components'

export const StyledLoading = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  .left {
    width: 49%;
    margin-right: 10px;

  }

  .right {
    width: 49%;
    align-self: flex-end;
    margin-left: 10px;
    
  }

  .horizontal-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .left-half {
    width: 25%;
    margin-right: 10px;
  }

  .banner-image {
    width: 100%;
    max-width: 500px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
  }
`
