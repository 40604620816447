import styled from 'styled-components'

export const IngredientAccordionContainer = styled.div`
  .ingredient-accordion__container {
    display: flex;
    justify-content: space-between;
    padding: 16px 0px;
    font-weight: var(--brand-font-weight-normal);
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
  }

  .ingredient-accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .ingredient-accordion__arrow {
    color: var(--category-primary-color);
  }
  .ingredient-accordion__end-line {
    border: 1px solid var(--brand-primary-black-color);
    opacity: 0.05;
  }
  .ingredient-accordion__content-container {
    font-weight: var(--brand-font-weight-normal);
    font-size: 12px;
    line-height: 16px;
    color: var(--brand-primary-grey-color);
    padding-bottom: 16px;
  }

  .ingredient-accordion__content {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .ingredient-accordion__content-title {
    color: var(--brand-primary-black-color);
  }

  .color-black {
    color: var(--brand-primary-black-color);
  }
  @media screen and (min-width: 768px) {
    .ingredient-accordion__container {
      padding: 20px 0px;
      font-size: 20px;
      line-height: 27px;
    }
    .ingredient-accordion__content-container {
      display: grid;
      grid-auto-flow: column;
      column-gap: 20px;
      font-size: 18px;
      line-height: 25px;
      padding-bottom: 16px;
    }
    .ingredient-accordion__content-title {
      color: var(--brand-primary-black-color);
    }
  }
`
