import styled from 'styled-components'

export const CategoryTagsProductCardsV2GridContainer = styled.div`
  .tags {
    display: flex;
    gap: var(--dls-size-8);
    margin-bottom: var(--dls-size-16);
    overflow: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    align-items: baseline;
  }
  .tags::-webkit-scrollbar {
    display: none;
  }
  .tag {
    background: var(--dls-white-color);
    border: 1px solid var(--dls-divider-light-color);
    border-radius: 30px;
    padding: var(--dls-size-8) 10px;
    flex-shrink: 0;
    &[data-is-Selected='true'] {
      background: var(--dls-brand-primary-color-500);
      border: 1px solid var(--dls-brand-primary-color-500);
      color: var(--dls-white-color);
    }
  }
  .products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--dls-size-8);
  }

  .image_cta_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--dls-size-8);
    color: var(--brand-secondary-text-color);
    &[data-is-Selected='true'] {
      color: var(--dls-brand-primary-color-500);
    }
  }

  .image_cta {
    display: flex;
    background-color: var(--brand-light-bg-color);
    border-radius: var(--dls-size-16);
    border: var(--dls-size-1) solid var(--brand-light-bg-color);
    width: fit-content;
    &[data-image-is-Selected='true'] {
      background-color: var(--dls-brand-primary-color-100);
      border: var(--dls-size-1) solid var(--dls-brand-primary-color-500);
    }
  }

  .image {
    border-radius: var(--dls-size-16);
    background-size: cover;
    max-width: var(--dls-size-80);
  }

  .pill-title {
    text-align: center;
    font-size: 12px;
  }
`
