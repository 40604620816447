export const categoryToAppointmentCategoryMapping: {[key: string]: string} = {
  is_hc: 'hair',
  is_sh: 'performance',
  skin: 'skin',
  beard: 'beard',
  'body-care': 'body-care',
  pcos: 'pcos',
  weight: 'weight',
  general: 'general',
}

export const getCategoryFromAppointmentCategory = (category: string = '') => {
  if (categoryToAppointmentCategoryMapping[category]) {
    return categoryToAppointmentCategoryMapping[category]
  }

  return category
}

export const getFirstCategory = (categoryString: string = '') => {
  if (!categoryString) return ''
  const categoryArray = categoryString.split(',') || []

  if (categoryArray.length > 1) {
    return categoryArray[0].toLowerCase()
  }

  return categoryString.toLowerCase()
}
