export const processAppointmentConfirm = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {category = '', appointmentId = ''} = data

  return {
    Category: category,
    'Appointment ID': appointmentId,
  }
}
