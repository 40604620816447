import Typography from '../../../../common/Typography/Typography'
import {IngredientAccordionContainer} from './IngredientAccordion.styles'
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import React, {useCallback, useState, useMemo} from 'react'

function IngredientAccordion(props) {
  const {state = {}, isAccordionKeyExist = false} = props || {}
  const {
    title = '',
    contents = [],
    content = '',
    items = [],
    headingsFor = {},
  } = isAccordionKeyExist ? state.accordion : state
  const {accordions: accordionsHeadingLevel = 3} = headingsFor

  const [isActive, setIsActive] = useState(false)

  const handleToggle = useCallback(() => {
    setIsActive(!isActive)
  }, [isActive])

  const chevronIcon = useMemo(
    () => (isActive ? faChevronUp : faChevronDown),
    [isActive]
  )

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <IngredientAccordionContainer>
      <div className="ingredient-accordion__container" onClick={handleToggle}>
        <Typography
          text={title}
          variant="body-base-bold"
          customClassName="ingredient-accordion-header"
          headingLevel={accordionsHeadingLevel}
        />
        <div className="ingredient-accordion__arrow">
          <FontAwesomeIcon icon={chevronIcon} />
        </div>
      </div>
      {isActive && (
        <div className="ingredient-accordion__content-container">
          {content && (
            <Typography
              text={content}
              variant="body-base-regular"
              customClassName="ingredient-accordion__content"
              isDangerouslySet
            />
          )}
          {contents.map((content, index) => {
            return (
              <Typography
                key={index}
                text={content}
                variant="body-base-regular"
                customClassName="ingredient-accordion__content"
                isDangerouslySet={true}
              />
            )
          })}
          {items.map((item, index) => {
            const {title = '', content = ''} = item
            return (
              <div className="ingredient-accordion__content" key={title}>
                <Typography
                  text={title}
                  variant="body-base-bold"
                  customClassName="ingredient-accordion__content-title"
                />
                <Typography
                  text={content}
                  variant="body-base-regular"
                  isDangerouslySet={true}
                />
              </div>
            )
          })}
        </div>
      )}
      <div className="ingredient-accordion__end-line"></div>
    </IngredientAccordionContainer>
  )
}

export default IngredientAccordion
