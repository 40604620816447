import {RatingsAndReviewsContainer} from './RatingsAndReviews.styles'
import {IRatingsAndReviewsProps} from './RatingsAndReviews.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import RatingsSummary from './RatingsSummary/RatingsSummary'
import ReviewsList from './ReviewsList/ReviewsList'
import {ChevronDown, Typography, TypographyVariants} from '@web-components'
import {useCallback} from 'react'
import {useGenericActions} from '../../hooks'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function RatingsAndReviews(props: IRatingsAndReviewsProps) {
  const {id = '', type = '', widgetData, header, layout} = props
  const {
    allReviewsCta,
    allReviewsCtaLabel = '',
    ratingOverview,
    topReviews,
    topReviewsLabel = 'Top Reviews',
  } = widgetData || {}

  const {genericAction} = useGenericActions()

  const handleViewMoreClick = useCallback(() => {
    genericAction(interceptAndEnhanceAction(allReviewsCta, id))
  }, [allReviewsCta, genericAction, id])

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <RatingsAndReviewsContainer>
        <RatingsSummary ratingOverview={ratingOverview} />
        <Typography
          customClassName="top-reviews-label"
          variant={TypographyVariants.BODY_BASE_BOLD}
        >
          {topReviewsLabel}
        </Typography>
        <ReviewsList reviews={topReviews} />
        <button
          onClick={handleViewMoreClick}
          className="view-all-reviews-button"
        >
          <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
            {allReviewsCtaLabel}
          </Typography>
          <ChevronDown color="var(--dls-category-primary-color-500)" />
        </button>
      </RatingsAndReviewsContainer>
    </BaseWidgetComponent>
  )
}

export default RatingsAndReviews
