import React, {useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import noop from 'lodash/noop'

import {useTabs} from '../../../../hooks/useTabs'
import Boosts from './Boosts'
import {
  StyledContent,
  StyledTabs,
  StyledTextContent,
  StyledWrapper,
} from './Description.styles'
import Typography from '../../../common/Typography/Typography'

function Details({data = {}}) {
  const {details = {}, usage = {}, allDetails = {}} = data
  const {headingLevel = 2} = details
  const {activeTab, changeTab} = useTabs(details.title || usage.title)

  const [showDetails, showUsage, showAllDetails] = useMemo(() => {
    return [
      !isEmpty(details),
      !isEmpty(usage),
      !isEmpty(allDetails) && !allDetails?.hideAllDetailsCta,
    ]
  }, [details, usage, allDetails])

  const {handleAllDetailsClick = noop} = allDetails

  if (!showDetails && !showUsage) {
    return null
  }
  return (
    <StyledWrapper data-show-all-details={showAllDetails}>
      <StyledTabs>
        {showDetails && (
          <button
            className="tab"
            data-active={activeTab === details.title}
            onClick={() => changeTab(details.title)}
          >
            <Typography
              text={details.title}
              variant="body-base-bold"
              customClassName="tab-title"
              headingLevel={headingLevel}
            />
          </button>
        )}
        {showUsage && (
          <button
            className="tab"
            data-active={activeTab === usage.title}
            onClick={() => changeTab(usage.title)}
          >
            <Typography
              text={usage.title}
              variant="body-base-bold"
              customClassName="tab-title"
              headingLevel={headingLevel}
            />
          </button>
        )}
        {showAllDetails && (
          <span className="all-details" onClick={handleAllDetailsClick}>
            All Details <FontAwesomeIcon icon={faChevronRight} />
          </span>
        )}
      </StyledTabs>
      <StyledContent>
        {activeTab === details?.title && (
          <div className="product-description-tab">
            <Typography
              text={details.text}
              variant="body-base-regular"
              isDangerouslySet={true}
            />
            <Boosts data={details.boosts} />
          </div>
        )}
        {activeTab === usage?.title && (
          <StyledTextContent data-is-padding={true}>
            <Typography
              text={usage.text}
              variant="body-base-regular"
              isDangerouslySet={true}
            />
          </StyledTextContent>
        )}
      </StyledContent>
    </StyledWrapper>
  )
}

export default Details
