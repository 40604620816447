import {getCategoryFromAppointmentCategory} from 'src/utils'

export const processRescheduleScreenViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    categories = [],
    language = '',
    appointmentId = '',
    appointmentType,
    appointmentTime,
    iaAvailable,
    availableSlot,
    appointmentState = '',
    categoryTitle = '',
    appointmentReference = '',
    imageUploaded = false,
    basicDetailsUpdated = false,
    source = '',
    mode = '',
  } = data

  return {
    'Earlier Appointment ID': appointmentId,
    'Earlier Appointment Type': appointmentType,
    'Earlier Slot': appointmentTime,
    'Earlier Category': Array.isArray(categories)
      ? getCategoryFromAppointmentCategory(categories[0])
      : '',
    'Earlier Language': language,
    'IA available': iaAvailable ? 'Y' : 'N',
    'Slot available': availableSlot,
    'Appointment State': appointmentState,
    Category: categoryTitle,
    'Appointment ID': appointmentReference,
    'Image Uploaded': imageUploaded,
    'Basic Info Submitted': basicDetailsUpdated,
    Source: source,
    Type: appointmentType,
    Mode: mode,
  }
}

export const processRescheduleLanguageChanged = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    language,
    appointmentState = '',
    categoryTitle = '',
    appointmentReference = '',
    imageUploaded = false,
    basicDetailsUpdated = false,
    source = '',
    appointmentType = '',
    mode = '',
  } = data
  return {
    Language: language,
    'Appointment State': appointmentState,
    Category: categoryTitle,
    'Appointment ID': appointmentReference,
    'Image Uploaded': imageUploaded,
    'Basic Info Submitted': basicDetailsUpdated,
    Source: source,
    Type: appointmentType,
    Mode: mode,
  }
}

export const processRescheduleConfirmClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    immediate = false,
    category = '',
    slot = '',
    language = '',
    appointmentId = '',
  } = data
  return {
    'IA Booked': immediate ? 'Y' : 'N',
    Slot: slot,
    Language: language,
    Category: category,
    'Appointment Id': appointmentId,
  }
}
