import {
  CoreUtilsInitializationConfig,
  initializeSharedCore,
} from '@mosaic-wellness/core-utils'
import {
  ErrorLoggerFunction,
  PushAnalyticsFunction,
} from '@mosaic-wellness/fe-types'
import {initializeAnalytics, initializeLogger} from '../utils'

export type WebCoreCoreInitializationConfig = Pick<
  CoreUtilsInitializationConfig,
  | 'clientDomain'
  | 'requestInterceptor'
  | 'responseErrorInterceptor'
  | 'responseInterceptor'
> & {
  errorLogger: ErrorLoggerFunction
  pushAnalytics?: PushAnalyticsFunction
}

const initializeWebCore = (config: WebCoreCoreInitializationConfig) => {
  const {
    requestInterceptor,
    clientDomain,
    responseInterceptor,
    responseErrorInterceptor,
    errorLogger,
    pushAnalytics,
  } = config

  initializeSharedCore({
    clientDomain,
    requestInterceptor,
    responseInterceptor,
    responseErrorInterceptor,
  })
  initializeLogger(errorLogger)
  initializeAnalytics(pushAnalytics)
}

export {initializeWebCore}
