import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {RxConsultDetails} from '@web-components'
import {useGenericActions} from '../../hooks'
import {useCallback} from 'react'
import {RxConsultDetailsWidgetProps} from './types'

const RxConsultDetailsWidget = ({
  widgetData,
  layout,
  header,
  type,
}: BaseWidgetProps<RxConsultDetailsWidgetProps>) => {
  const {genericAction} = useGenericActions()

  const handleInfoClick = useCallback(() => {
    const {infoAction} = widgetData || {}
    if (infoAction) {
      genericAction(infoAction)
    }
  }, [genericAction, widgetData])

  return (
    <BaseWidgetComponent layout={layout} widgetType={type}>
      <BaseWidgetComponent.Header header={header} />
      <RxConsultDetails {...widgetData} onInfoClick={handleInfoClick} />
    </BaseWidgetComponent>
  )
}

export default RxConsultDetailsWidget
