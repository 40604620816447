export const processBirthdayTrackerSeen = () => {
  const dataToReturn = {
    Source: window.location.href,
  }

  return dataToReturn
}

export interface IKidsData {
  name: string
  gender: string
  date: string
  noOfKidsSave: number
}

export const processBirthdayTrackerSaved = (data: IKidsData) => {
  const {name = '', gender = '', date = '', noOfKidsSave = ''} = data ?? {}
  const dataToReturn = {
    Name: name,
    Gender: gender,
    DOB: date,
    'Number of Children': noOfKidsSave,
  }

  return dataToReturn
}
