import styled from 'styled-components'

export const MediaWithFooterCardContainer = styled.div`
  overflow: hidden;

  .header-container {
    margin-top: 10px;
    margin-bottom: 6px;
  }

  .card-image-container {
    display: flex;
  }

  .card-image {
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  .description-container {
    position: relative;
  }

  .description-text,
  .read-more-button {
    color: var(--dls-secondary-text-colors);
    color: var(--dls-secondary-text-colors);
  }

  .line-clamp-4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .read-more-button {
    outline: none;
    border: none;
    background-color: var(--dls-white-color);
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
