import styled from 'styled-components'

export const OptionWithImageContainer = styled.div`
  padding: var(--dls-size-4);
  border-radius: var(--dls-size-8);
  background-color: var(--dls-white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--dls-brand-primary-color-100);

  .option-data {
    align-items: center;
    display: flex;
  }

  .image-container {
    margin-right: var(--dls-size-8);
  }

  .option-text {
    padding: 12px 0;
  }

  .radio-button {
    width: 18px;
    height: 18px;
    margin: 0;
    margin-right: 10px;
    accent-color: var(--dls-brand-primary-color-500);

    :checked {
      border-color: var(--dls-brand-primary-color-500);
    }
  }

  &[data-isselected='true'] {
    border: 1.5px solid var(--dls-brand-primary-color-500);
  }
`
