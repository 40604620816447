import React from 'react'

function Down({disabled}) {
  const stroke = disabled ? '#6C6C6C' : 'var(--category-primary-color)'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      fill="none"
      viewBox="0 0 16 22"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.97 1v18M1 12.929L8 20l7-7.071"
      ></path>
    </svg>
  )
}

export default Down
