import React, {useEffect} from 'react'
import useScreenName from '../useScreenName/useScreenName'
import {useConfigStore} from '@mosaic-wellness/redux-action-library'

export default function useScreenNameToDataLayer() {
  const {screenName} = useScreenName()
  const {analytics} = useConfigStore()

  useEffect(() => {
    analytics.push({screenName})
  }, [analytics, screenName])
}
