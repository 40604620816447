import styled from 'styled-components'

export const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6;
  top: 0;
  right: 0;

  &[data-backdrop-open='true'] {
    transition: ${(props) =>
        `${Number.parseInt(props.transitionTime) || 500}ms`}
      ease;
  }

  &[data-backdrop-open='false'] {
    opacity: 0;
    visibility: hidden;
    transition: ${(props) =>
        `${Number.parseInt(props.transitionTime) || 500}ms`}
      ease;
  }
`
