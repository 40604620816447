import noop from 'lodash/noop'
import {useCallback} from 'react'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useGenericCta, useOutOfStockProduct} from '..'
import {useProductNavigation} from '../product/useProductNavigation'
import {IUseCta, CTA_ACTION} from './useGenericCtaV2.interface'
import {useRouter} from 'next/router'
import {useUpdateItemToCart} from '../updateCart/useUpdateItemToCart'
import {
  HttpClient,
  useConfigStore,
  useWalletPoints,
} from '@mosaic-wellness/redux-action-library'
import {analyticsPush, analyticsTriggerBypassProcessor} from 'src/analytics'
import {GenericAction} from '@mosaic-wellness/fe-types'
import isEmpty from 'lodash/isEmpty'
import {useExplicitATCTracking} from '../tracking/useExplicitATCTracking'
import {useProductClickTracking} from '../tracking/useProductClick'
import {useSendEventsToAlgolia} from '../search/useSendEventsToAlgolia'

export const useGenericCtaV2 = () => {
  const {addToCart} = useUpdateItemToCart()
  const {navigateToProduct} = useProductNavigation()
  const {handleCta = noop} = useGenericCta()
  const {trackAddToCart} = useExplicitATCTracking()
  const {trackProductCardClick} = useProductClickTracking()
  const {
    state: {walletPoints},
    actions: {fetchWalletPoints},
  } = useWalletPoints()
  const router = useRouter()
  const isApp = checkIsApp()
  const {sendEventToAlgolia} = useSendEventsToAlgolia()
  const {
    environment: {toast},
  } = useConfigStore()

  const {actions} = useOutOfStockProduct({
    outOfStockConfig: {
      outOfStockToastMessage:
        'Thank you for your request. We will notify you as soon as the product is back in stock.',
    },
  })

  const {handleNotifyMeWithProductDetails} = actions || {}

  const handleCtaV2 = useCallback(
    async (cta: IUseCta) => {
      const {action, data} = cta
      const {analyticsData, triggerAnalytics = true} = data || {}
      if (!isEmpty(analyticsData) && triggerAnalytics) {
        const {eventName, eventData} = analyticsData || {}
        analyticsTriggerBypassProcessor(eventName, eventData)
      }

      switch (action) {
        case CTA_ACTION.ADD_TO_CART: {
          const {sku} = data || {}

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {
                  isWebView: true,
                  product: {sku},
                },
              })
            )
            return
          }
          trackAddToCart(data, data.source)
          addToCart({sku})
          break
        }

        case CTA_ACTION.OPEN_PDP: {
          const {slugUrl, urlKey, replaceNav = false} = data

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {
                  isWebView: true,
                  productKey: urlKey,
                  replaceNav,
                },
              })
            )
            return
          }

          trackProductCardClick({...data})
          navigateToProduct({slugUrl, urlKey})
          break
        }

        case CTA_ACTION.RESET_NAV_STACK:
        case CTA_ACTION.REPLACE_NAV:
        case CTA_ACTION.APP_NAV: {
          const {webNavigationData, appNavigationData} = data

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {
                  ...appNavigationData,
                  isWebView: true,
                },
              })
            )
            return
          }
          handleCta(webNavigationData)
          break
        }

        case CTA_ACTION.SPA_LINK: {
          handleCta({action, ...data})
          break
        }

        case CTA_ACTION.BOTTOM_TAB: {
          const {webNavigationData, appNavigationData} = data

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {
                  ...appNavigationData,
                  isWebView: true,
                },
              })
            )
            return
          }
          handleCta(webNavigationData)
          break
        }

        case CTA_ACTION.GO_BACK: {
          if (isApp) {
            // @ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {
                  isWebView: true,
                },
              })
            )
          } else {
            router.back()
          }
          break
        }

        case CTA_ACTION.CLEAR_APPOINTMENT_DETAILS_CONTEXT: {
          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {
                  isWebView: true,
                },
              })
            )
            return
          }
          break
        }

        case CTA_ACTION.AUTHORIZED_APP_NAV: {
          const {webNavigationData, appNavigationData} = data
          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: {
                  ...appNavigationData,
                  isWebView: true,
                },
              })
            )
            return
          } else {
            handleCta(webNavigationData)
          }
        }

        case CTA_ACTION.TRIGGER_EVENTS_FOR_WEB: {
          const {event, eventData} = data
          if (isApp) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: 'TRIGGER_EVENTS_WEB_VIEW',
                actionData: {
                  event: event,
                  data: {
                    ...eventData,
                    isWebView: true,
                  },
                },
              })
            )
          } else {
            analyticsTriggerBypassProcessor(event, eventData)
          }
          return
        }

        case CTA_ACTION.UPDATE_DATA_LAYER: {
          const {eventData} = data
          analyticsPush(eventData)
          return
        }

        case CTA_ACTION.SHARE: {
          const {url} = data
          if (!url) {
            toast('warning', 'Invalid URL!')
            return
          }
          if (window.navigator.share) {
            navigator.share(data)
          } else {
            if (
              navigator &&
              navigator.clipboard &&
              navigator.clipboard.writeText
            ) {
              await navigator.clipboard.writeText(url)
              toast('success', 'Link Copied to Clipboard')
            }
          }
        }

        case CTA_ACTION.FETCH_WALLET_POINTS: {
          if (isApp) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: 'FETCH_WALLET_POINTS',
                actionData: {
                  isWebView: true,
                },
              })
            )
          } else {
            fetchWalletPoints()
          }
          break
        }
        case CTA_ACTION.ENDPOINT: {
          const axiosInstance = HttpClient.getAxiosInstance()
          const {
            endpointType = '',
            endpointUrl = '',
            endpointBody = {actionObj: {}},
            headers = {},
            showToast = true,
          } = data

          const res = await axiosInstance({
            method: endpointType,
            url: endpointUrl,
            data: endpointBody,
            headers,
          })
          const message = res?.data?.message
          if (message && showToast) {
            toast('success', message)
          }

          const {
            events,
            location,
            locationActionData = {
              newTab: true,
            },
            locationAction = 'LINK',
            isDelayed = true,
            delay = 0,
          } = res?.data?.data || {}
          if (Array.isArray(events)) {
            events?.forEach((event: any) => {
              handleCta(event)
            })
          }

          if (location) {
            const locationCtaAction = {
              action: locationAction,
              link: location,
              ...locationActionData,
            }
            if (isDelayed) {
              setTimeout(() => {
                handleCta(locationCtaAction)
              }, delay)
            } else {
              handleCta(locationCtaAction)
            }
          }

          return res?.data?.data
        }

        case CTA_ACTION.SCROLL_TO_WIDGET: {
          const {widgetId} = data
          const widgetElement = document.querySelector(
            `[data-widgetid=${widgetId}`
          )
          if (widgetElement) {
            const pixelsToScrollVertically =
              widgetElement.getBoundingClientRect().top +
              window.pageYOffset -
              150

            window.scrollTo({top: pixelsToScrollVertically, behavior: 'smooth'})
          }
        }

        case CTA_ACTION.OPEN_DEEPLINK: {
          const {webNavigationData, appNavigationData} = data

          if (isApp) {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: action,
                actionData: appNavigationData,
              })
            )
            return
          }
          handleCta(webNavigationData)
          break
        }

        case CTA_ACTION.HANDLE_OOS_NOTIFY_ME: {
          handleNotifyMeWithProductDetails(data)
          break
        }

        case CTA_ACTION.SEND_EVENT_TO_ALGOLIA: {
          sendEventToAlgolia(data)
          break
        }

        default:
          handleCta({action, ...data})
      }
    },
    [
      addToCart,
      handleCta,
      isApp,
      navigateToProduct,
      router,
      toast,
      handleNotifyMeWithProductDetails,
      fetchWalletPoints,
      sendEventToAlgolia,
      fetchWalletPoints,
    ]
  )

  const handleCtaActions = useCallback(
    (actions: IUseCta[]) => {
      if (Array.isArray(actions) && actions.length > 0) {
        actions.forEach((action) => {
          handleCtaV2(action)
        })
      }
    },
    [handleCtaV2]
  )

  const handleActionsFromFEMono = useCallback(
    (action: GenericAction) => {
      const {actionName, params} = action
      handleCtaV2({
        action: actionName,
        data: params,
      })
    },
    [handleCtaV2]
  )

  return {
    handleCtaV2,
    handleCtaActions,
    handleActionsFromFEMono,
  }
}
