import {useCallback} from 'react'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'

// TODO: Why?
export const useReOrderClicked = () => {
  const reOrderClick = useCallback((productObj: Object) => {
    analyticsTrigger(EVENT_MAP.REORDER_CLICKED, productObj)
  }, [])
  return {
    reOrderClick,
  }
}
