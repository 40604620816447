import React, {useEffect, useCallback, useState, useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {SuccessScreenWrapper} from './SuccessScreen.styles'
import AppointmentCard from './AppointmentCard'
import CancelCard from './CancelCard'
import Modal from '../../../ComponentsV2/common/Modal'
import {ScheduleScreen} from '..'
import CheckIcon from './CheckIcon'
import {UploadCallout} from '../ImageUpload'
import Typography from '../../../ComponentsV2/common/Typography/Typography'
import TrustMarkers from '../../../ComponentsV2/Screens/HomePage/TrustMarkers'
import {CustomerTestimonials} from '../../../ComponentsV2/common/CustomerTestimonials'

const SuccessScreen = (props) => {
  const {
    bannerData = {},
    appointmentCard = {},
    cancelCard = {},
    infoCard = {},
    downloadApp = {},
    trustMarkers = {},
    customerTestimonials = {},
    // Future app
    futureAppointmentData = {},
    loadingFutureAppointments = false,
    firstName = '',
    // cancel card data
    showAppointmentCardButtons = true,
    scheduleScreenProps = {},
    rescheduleAppointmentData = {},
    isRescheduleInProgress = false,
  } = props.state || {}

  const {
    handleCta = noop,
    cancelAppointment = noop,
    checkFutureAppointments = noop,
    rescheduleAppointment = noop,
    resetRescheduleAppointmentData = noop,
    onDownloadCtaClick = noop,
    trackSuccessScreen = noop,
    handleCommsRedirect = noop,
    onReschedulePendingOrderAppointment = noop,
    getFloatingIslandNudgeData = noop,
    onPendingOrderRescheduleSeen = noop,
    onPendingOrderRescheduleSubmit = noop,
    onPendingOrderRescheduleConfirmed = noop,
  } = props.actions || {}

  const {
    appointmentDetails = {},
    scheduleToken = '',
    appointmentId = '',
    category = 'Hair',
    source = '',
    pendingOrderAppointment = {},
    pendingOrderAppointmentAvailable = false,
    isOnGoing = false,
    rxCategories = [],
    imageUploaded = false,
  } = futureAppointmentData || {}

  const {
    heading = '',
    confettiLink = '',
    subHeading = '',
    offerText = '',
  } = bannerData

  const {date = '', time = '', slotEndTime = ''} = appointmentDetails
  const {toast = noop} = scheduleScreenProps?.actions || {}
  const {callout = {}, downloadCta = {}} = downloadApp
  const {cardTitle = '', steps = []} = infoCard

  const [showModal, setShowModal] = useState(false)
  const [showCancelCard, setShowCancelCard] = useState(false)

  const appointmentCardData = useMemo(() => {
    if (pendingOrderAppointmentAvailable && !isEmpty(pendingOrderAppointment)) {
      const {
        appointmentId: pendingOrderAppointmentId = '',
        appointmentDate = '',
        slotStartTime = '',
        slotEndTime: pendingOrderSlotEndTime = '',
        category: pendingOrderCategory = '',
      } = pendingOrderAppointment
      return {
        appointmentId: pendingOrderAppointmentId,
        appointmentDate,
        appointmentTime: `${slotStartTime} - ${pendingOrderSlotEndTime}`,
        category: pendingOrderCategory,
      }
    }
    return {
      appointmentDate: date,
      appointmentTime: `${time} - ${slotEndTime}`,
      appointmentId: appointmentId,
      category,
    }
  }, [
    appointmentId,
    category,
    date,
    pendingOrderAppointment,
    pendingOrderAppointmentAvailable,
    slotEndTime,
    time,
  ])

  const showCancelButton = useMemo(() => {
    return source !== 'O' && pendingOrderAppointment?.source !== 'O'
  }, [pendingOrderAppointment?.source, source])

  useEffect(() => {
    if (pendingOrderAppointmentAvailable) {
      // this is for analytics
      const {
        appointmentId = '',
        category = '',
        appointmentStatus = '',
      } = pendingOrderAppointment || {}

      onPendingOrderRescheduleSeen({
        appointmentId,
        category,
        status: appointmentStatus,
      })
    }
  }, [
    pendingOrderAppointmentAvailable,
    pendingOrderAppointment,
    onPendingOrderRescheduleSeen,
  ])

  useEffect(() => {
    if (pendingOrderAppointmentAvailable) {
      toast(
        'alert',
        'Your order is pending because this appointment was not completed'
      )
      handleShowModal()
      // change the scheduleToken of past order appointment
    }
  }, [handleShowModal, pendingOrderAppointmentAvailable])

  const handleShowModal = useCallback(
    () => setShowModal(!showModal),
    [showModal]
  )

  const closeRescheduleModal = useCallback(() => {
    if (pendingOrderAppointmentAvailable) {
      onReschedulePendingOrderAppointment()
    }
    setShowModal(false)
  }, [onReschedulePendingOrderAppointment, pendingOrderAppointmentAvailable])

  const handleCancelClicked = useCallback(() => {
    setShowCancelCard(true)
  }, [])

  const onSecondaryCtaClick = useCallback(async () => {
    await cancelAppointment({
      appointmentId: appointmentId.split('-')[2],
    })

    //refetch nudge data on cancel appointment
    await getFloatingIslandNudgeData()
    handleCta({action: 'LINK', link: '/'})
  }, [appointmentId, cancelAppointment, handleCta, getFloatingIslandNudgeData])

  const onPrimaryCtaClick = useCallback(() => {
    setShowCancelCard(false)
  }, [])

  const handleDownloadCtaClicked = useCallback(() => {
    onDownloadCtaClick(downloadCta?.link)
    trackSuccessScreen({type: 'downloadApp'})
  }, [downloadCta?.link, onDownloadCtaClick, trackSuccessScreen])

  //initial check future appointment call to get appointment details if it exists
  useEffect(() => {
    if (isEmpty(futureAppointmentData)) {
      checkFutureAppointments()
    }
  }, [])

  useEffect(() => {
    handleCommsRedirect()
  }, [handleCommsRedirect])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <SuccessScreenWrapper confettiLink={confettiLink}>
      <div className="success-screen__heading-card">
        <div className="success-screen__heading-container">
          <CheckIcon />
          <div className="success-screen__heading-text">{heading}</div>
          <div className="success-screen__heading-text">{firstName}</div>
        </div>
        <div className="success-screen__sub-heading">{subHeading}</div>
        <div className="success-screen__offer-container">
          <div
            dangerouslySetInnerHTML={{__html: offerText}}
            className="success-screen__offer-text"
          />
        </div>
      </div>

      <div className="success-screen__content">
        <AppointmentCard
          state={{
            ...appointmentCard,
            ...appointmentCardData,
            showAppointmentCardButtons,
            showCancelButton,
            isOnGoing,
            isAppointmentDataLoading: loadingFutureAppointments,
          }}
          actions={{
            handleCancelClicked,
            handleRescheduleClicked: handleShowModal,
            onDownloadCtaClick,
            trackSuccessScreen,
          }}
        />

        <div className="success-screen__download-app">
          <UploadCallout
            state={{
              ...callout,
              className: 'success-screen__callout',
            }}
          />

          <button
            className="success-screen__convert-to-IA-button"
            onClick={handleDownloadCtaClicked}
          >
            <img src={downloadCta?.icon} alt={downloadCta?.alt} />
            <div>
              <Typography text={downloadCta?.text} variant="cta-label-lg" />
            </div>
          </button>
        </div>

        <TrustMarkers state={{...trustMarkers, className: 'trust-markers'}} />

        <div className="success-screen__customer-testimonials">
          <CustomerTestimonials state={customerTestimonials} />
        </div>

        <div className="success-screen__info-card-container">
          <div className="success-screen__info-card">
            <div className="success-screen__info-card-title">
              <Typography
                text={cardTitle}
                variant="body-base-bold"
                isDangerouslySet={true}
              />
            </div>
            {steps.map((step, index) => {
              return (
                <div className="success-screen__info-card-step" key={index}>
                  <Typography
                    text={step}
                    variant="body-base-regular"
                    isDangerouslySet={true}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {/* Modal that opens up the reschedule modal to allow for rescheduling of appointment */}
      <Modal
        onClose={handleShowModal}
        showModal={showModal}
        additionalContentClass="reschedule-modal__wrapper"
      >
        <div className="schedule-screen-content">
          <ScheduleScreen
            state={{
              ...scheduleScreenProps.state,
              isReschedule: true,
              schedule_token: scheduleToken,
              rescheduleAppointmentData,
              isRescheduleInProgress,
              pendingOrderAppointment,
              pendingOrderAppointmentAvailable,
              loadingFutureAppointments,
              rxCategories,
              imageUploaded,
            }}
            actions={{
              ...scheduleScreenProps.actions,
              resetRescheduleAppointmentData,
              rescheduleAppointment,
              closeRescheduleModal,
              checkFutureAppointments,
              handleCta,
              getFloatingIslandNudgeData,
              onPendingOrderRescheduleSubmit,
              onPendingOrderRescheduleConfirmed,
            }}
          />
        </div>
      </Modal>
      <CancelCard
        state={{...cancelCard, showCancelCard}}
        actions={{onPrimaryCtaClick, onSecondaryCtaClick}}
      />
    </SuccessScreenWrapper>
  )
}

export default SuccessScreen
