import styled from 'styled-components'

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;

  .react-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
  }

  .react-switch {
    cursor: pointer;
    min-width: var(--dls-size-88);
    height: var(--dls-size-40);
    position: relative;
    transition: background-color 0.2s;
    border-radius: var(--dls-size-24);
    border: 1px solid var(--brand-primary-color);
    display: flex;
  }

  .react-switch .react-switch-button {
    position: absolute;
    top: -1px;
    left: var(--dls-size-42, 42px);
    width: var(--dls-size-45, 45px);
    height: var(--dls-size-40);
    transition: 0.2s;
    background: var(--brand-primary-color);
    border-radius: var(--dls-size-20, 20px);
    opacity: 0.8;
  }

  .react-switch-checkbox:checked + .react-switch-button {
    left: calc(100% - 42px);
    transform: translateX(-100%);
  }

  .react-switch-checkbox:active + .react-switch-button {
    width: var(--dls-size-60, 60px);
  }

  .react-switch-labels {
    display: relative;
    z-index: 0;
    height: 100%;
    font: var(--dls-size-16);
  }

  .react-switch-labels span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }

  .react-switch-labels span:first-child {
    left: 0;
  }
  .react-switch-labels span:last-child {
    right: 0;
  }

  .react-switch .react-switch-labels .active {
    color: white;
  }
`
