import SearchBar from './SearchBar/SearchBar'
import useSearchInputWithRecommendation from './useSearchInputWithRecommendation'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {SearchInputWithRecommendationProps} from './SearchInputWithRecommendation.types'
import SearchResultProductList from './SearchResultProductList/SearchResultProductList'
import {SearchInputWithRecommendationContainer} from './SearchInputWithRecommendation.styles'
import SearchResultsLoader from './SearchResultsLoader'

const SearchInputWithRecommendation = (
  props: BaseWidgetProps<SearchInputWithRecommendationProps>
) => {
  const [
    {
      searchBarData,
      searchResultData,
      isSearchResultEmpty,
      showProductList,
      isLoading,
      searchBarRef,
    },
    {},
  ] = useSearchInputWithRecommendation(props)

  return (
    <SearchInputWithRecommendationContainer
      isSearchResultEmpty={isSearchResultEmpty}
    >
      <div className="search-bar-proxy-wrapper" />

      <div ref={searchBarRef} className="search-bar-wrapper">
        <div className="content-container">
          <SearchBar {...searchBarData} />
        </div>
      </div>

      {isLoading ? <SearchResultsLoader /> : null}

      {showProductList && !isLoading && (
        <div className="search-result-wrapper">
          <SearchResultProductList {...searchResultData} />
        </div>
      )}
    </SearchInputWithRecommendationContainer>
  )
}

export default SearchInputWithRecommendation
