import {DEVICE_TYPE_CONTEXT} from '@web-components'
import React, {useContext, useEffect, useMemo, useState} from 'react'

const DEVICE_TYPES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}

const DeviceInfoContext = React.createContext({
  device: DEVICE_TYPES.DESKTOP,
})
function DeviceInfoContextProvider({children, presetDeviceInfo = {}}) {
  const {device} = presetDeviceInfo
  const [detectedScreenWidth, setDetectedScreenWidth] = useState(0)

  const screenWidth = useMemo(() => {
    let screenWidthToReturn = detectedScreenWidth

    if (!detectedScreenWidth) {
      // TODO: need to figure out support an use it in dev
      if (typeof window !== 'undefined') {
        // if (window.visualViewport.width) {
        //   screenWidthToReturn = window.visualViewport.width
        // } else {
        screenWidthToReturn = window.innerWidth
        // }
      }
    }

    return screenWidthToReturn
  }, [detectedScreenWidth])

  const isMobile = useMemo(() => {
    let isMobileDevice = true

    if (device) {
      isMobileDevice = device === DEVICE_TYPES.MOBILE
    }

    if (screenWidth > 0) {
      isMobileDevice = !(screenWidth >= 768)
    }

    return isMobileDevice
  }, [device, screenWidth, presetDeviceInfo])

  useEffect(() => {
    const handleResize = () => {
      setDetectedScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const contextValue = useMemo(() => {
    return {
      isMobile,
      screenWidth,
    }
  }, [isMobile, screenWidth])

  return (
    <DeviceInfoContext.Provider value={contextValue}>
      <DEVICE_TYPE_CONTEXT.Provider value={contextValue}>
        {children}
      </DEVICE_TYPE_CONTEXT.Provider>
    </DeviceInfoContext.Provider>
  )
}

export default DeviceInfoContextProvider

export const useDeviceInfoContext = () => {
  const {isMobile, screenWidth} = useContext(DeviceInfoContext)

  return {
    isMobile,
    screenWidth,
  }
}
