export const CONSTANTS = {
  EMPTY_CART_CTA_LABEL: 'Start Shopping',
  EMPTY_CART_TITLE: 'Missing Cart Items?',
  EMPTY_CART_TEXT: 'Get started with your wellness today!',
  CART_DELIVER_TEXT: 'Deliver to : ',
  CART_CHANGE_LABEL: 'CHANGE',
  CART_EDIT_LABEL: 'Edit',
  CART_PLACE_ORDER_TEXT: 'Place Order',
  CART_LOGIN_TEXT: 'Login',
  CART_ADD_ADDRESS_TEXT: 'Add Address',
  CART_PRICE_DETAILS_TEXT: 'View Price Details',
  CART_COUPON_SUBTITLE: 'View Offers',
  CART_COUPON_TITLE: 'Apply Coupon',
  CART_COUPON_APPLY_LABEL: 'APPLY',
  CART_COUPON_REMOVE_LABEL: 'REMOVE',
  CART_SURPRISE_GIFT_TEXT: 'Surprise Gift',
  CART_VALUE_TEXT: 'Cart Value',
  NOTIFY_ME: "You will be notified when it's back",
  CART_RECOMMENDED_TEXT: 'Recommended for you',
  CART_PRESCRIPTION_PRODUCT_TEXT: '*This is a prescription product',
  CART_PRESCRIPTION_TITLE: 'Prescription Required',
  CART_PRESCRIPTION_DETAILS:
    'Your order contains some products that require a prescription. In case the doctor finds it unsuitable for you, the order will be shipped without that product and appropriate refund will be processed.\n\nYou will get a free doctor consultation and prescription when you place an order.',
  CART_PRESCRIPTION_SUBTEXT: 'Need help with this order?',
  CART_PRESCRIPTION_BUTTON_TEXT: 'Contact Us',
  CART_COUPON_ENTER_PLACEHOLDER: 'Enter Coupon Code',
  CART_TREATMENT_PLAN: 'Your Treatment Plan',
  ADDRESS_TITLE: 'Billing Address',
  ADDRESS_SUBTITLE:
    'Following address will be used on the checkout page by default',
  ADDRESS_SAVED_ADDRESS_TITLE: 'Saved Addresses:',
  ADDRESS_SAVE_BUTTON_LABEL: 'Save',
  ADDRESS_CANCEL_BUTTON_LABEL: 'Cancel',
  ADDRESS_DELIVERY_ESTIMATE_TEXT: 'Estimated',
  ADDRESS_FNAME_LABEL: 'Name',
  ADDRESS_FNAME_PLACEHOLDER: 'Warren',
  ADDRESS_FNAME_ASSISTIVE_TEXT: 'Enter first name and last name',
  ADDRESS_FNAME_REQUIRED_TEXT: 'First Name is required.',
  ADDRESS_FNAME_VALID_TEXT: 'Please enter a valid First Name.',
  ADDRESS_FNAME_MAX_TEXT: 'First Name must be max 20 characters long.',
  ADDRESS_IS_REQUIRED_TEXT: 'is required.',
  ADDRESS_ADDRESS_LABEL: 'Address',
  ADDRESS_ADDRESS_PLACEHOLDER: 'Address',
  ADDRESS_ADDRESS_ASSISTIVE_TEXT: 'Enter your Address',
  ADDRESS_ADDRESS_REQUIRED_TEXT: 'Address  is required.',
  ADDRESS_PINCODE_LABEL: 'Pincode',
  ADDRESS_PINCODE_PLACEHOLDER: '000000',
  ADDRESS_PINCODE_ASSISTIVE_TEXT: 'Enter your 6 digit Pincode',
  ADDRESS_PINCODE_REQUIRED_TEXT: 'Pincode is required.',
  ADDRESS_PINCODE_MAX_TEXT: 'Pincode must be max 6 characters long.',
  ADDRESS_PINCODE_MIN_TEXT: 'Pincode must be min 6 characters long.',
  ADDRESS_PINCODE_VALID_TEXT: 'Please enter a valid Pincode.',
  ADDRESS_CITY_LABEL: 'City',
  ADDRESS_CITY_ASSISTIVE_TEXT: 'Enter city',
  ADDRESS_CITY_REQUIRED_TEXT: 'City is required.',
  ADDRESS_CITY_ERROR: 'City can have A-Z, a-z, 0-9, -().&/',
  ADDRESS_TRUSTED_TEXT: 'Secure and fast delivery',
  ADDRESS_CITY_VALID_TEXT: 'Please enter a valid City.',
  ADDRESS_CITY_MAX_TEXT: 'City must be max 20 characters long.',
  ADDRESS_CITY_PLACEHOLDER: '-',
  ADDRESS_PHONE_LABEL: 'Phone Number',
  ADDRESS_PHONE_ASSISTIVE_TEXT: 'Enter your Phone Number',
  ADDRESS_STATE_LABEL: 'State',
  ADDRESS_STATE_ASSISTIVE_TEXT: 'Enter state',
  ADDRESS_STATE_REQUIRED_TEXT: 'State is required.',
  ADDRESS_INVALID_STATE_DROPDOWN_ERROR:
    'Invalid input. Please select a state from the dropdown',
  ADDRESS_ITEM_EDIT_LABEL: 'Edit',
  ADDRESS_ITEM_DELETE_LABEL: 'Delete',
  ADDRESS_ITEM_CANCEL_LABEL: 'Cancel',
  ADDRESS_ITEM_SAVE_LABEL: 'Save',
  ADDRESS_EMAIL_LABEL: 'Email',
  ADDRESS_EMAIL_ASSISTIVE_TEXT: 'Enter Email ID',
  ADDRESS_DELETE_TITLE: 'Delete Address',
  ADDRESS_DELETE_SUBTITLE: 'Are you sure you want to delete this address?',
  ADDRESS_DELETE_BUTTON_LABEL: 'Delete',
  ADDRESS_ADD_BUTTON_LABEL: '+ Add New Address',
  ADDRESS_SELECT_STATE: 'Select state',
  ADDRESS_SELECT_CITY: 'Select city',
  ADDRESS_REGEX: '',
  ADDRESS_SPECIAL_CHARACTER_ONLY: '',
  ADDRESS_ERROR: "Address can have A-Z, a-z, 0-9, .'-#&%@/,",
  ADDRESS_REMOVABLE_CHARACTERS: [],
  EDIT_ACCOUNT_TITLE: 'Edit Account Details',
  EDIT_ACCOUNT_FNAME_LABEL: 'First Name',
  EDIT_ACCOUNT_FNAME_PLACEHOLDER: 'John',
  EDIT_ACCOUNT_LNAME_LABEL: 'Last Name',
  EDIT_ACCOUNT_LNAME_PLACEHOLDER: 'Doe',
  EDIT_ACCOUNT_EMAIL_LABEL: 'Email',
  EDIT_ACCOUNT_EMAIL_PLACEHOLDER: 'johndoe@gmail.com',
  EDIT_ACCOUNT_PHONE_LABEL: 'Phone Number',
  EDIT_ACCOUNT_PHONE_PLACEHOLDER: '+91 9876543210',
  EDIT_ACCOUNT_SAVE_LABEL: 'Save',
  EDIT_ACCOUNT_DELETE_LABEL: 'Delete User Account',
  EDIT_ACCOUNT_DELETE_TITLE: 'Delete account permanently',
  EDIT_ACCOUNT_DELETE_SUBTITLE:
    'Deleting your account will mean losing of all data so far including consultations, order history and more across our platforms. Delete only if necessary.',
  EDIT_ACCOUNT_DELETEFORM_LABEL: 'Delete',
  PRODUCT_BUY_LABEL: 'Buy Now',
  PRODUCT_ADD_CART_LABEL: 'Add to Cart',
  PRODUCT_NOTIFY_ME_LABEL: 'Notify Me',
  PRODUCT_UNITS_TEXT: ' Units ',
  PRODUCT_SOLD_TEXT: 'sold',
  PRODUCT_REVIEWED_TEXT: ' Reviewed',
  PRODUCT_CHECK_PINCODE_TEXT: 'Check Pincode',
  PRODUCT_MRP_DISCLAIMER_TEXT: 'MRP Inclusive of all Taxes',
  PRODUCT_VERIFIED_BUYER_TEXT: 'Verified Buyer',
  PRODUCT_LOAD_MORE_TEXT: 'Load More',
  PRODUCT_ASK_TITLE: 'Ask A Question',
  PRODUCT_ASK_NAME_LABEL: 'Name',
  PRODUCT_ASK_NAME_VALID: 'Please enter a valid name',
  PRODUCT_ASK_NAME_PLACEHOLDER: 'John Doe',
  PRODUT_ASK_EMAIL_LABEL: 'Email',
  PRODUCT_ASK_EMAIL_VALID: 'Please enter a valid email',
  PRODUCT_ASK_EMAIL_PLACEHOLDER: 'johndoe@gmail.com',
  PRODUCT_ASK_QUESTION_LABEL: 'Question',
  PRODUCT_ASK_QUESTION_PLACEHOLDER: 'Type your question here...',
  PRODUCT_ASK_SUBMIT_BUTTON_LABEL: 'Submit',
  PAYMENT_CARD_NUMBER_PLACEHOLDER: 'Card number',
  PAYMENT_CARD_NUMBER_HOVER_LABEL: 'Card number',
  PAYMENT_CARD_VALID_PLACEHOLDER: 'Valid through (MM/YY)',
  PAYMENT_CARD_VALID_HOVER_LABEL: 'Valid through (MM/YY)',
  PAYMENT_CARD_CVV_PLACEHOLDER: 'CVV',
  PAYMENT_CARD_CVV_HOVER_LABEL: 'CVV',
  PAYMENT_CARD_NAME_PLACEHOLDER: 'Name on card',
  PAYMENT_CARD_NAME_HOVER_LABEL: 'Name on card',
  PAYMENT_PROCEED_TO_PAY_TEXT: 'Proceed to Pay ₹ ',
  PROFILE_LOGOUT_LABEL: 'Logout',
  ORDER_HISTORY_ORDERID_TEXT: 'Order Id ',
  ORDER_HISTORY_ORDER_PLACED_TEXT: 'Order placed on ',
  ORDER_HISTORY_ORDER_EXPECTED_LABEL: 'Expected Delivery Date ',
  CART_ADDON: 'Cart Add On',
  VIEW_BUTTON_TEXT: 'View All',
  REQUEST_PERMISSION_TITLE: 'Enable Phone Book Access',
  REQUEST_PERMISSION_SUBTITLE:
    'Go to settings and enable phone book access to find out which of your contacts trust Little Joys',
  REQUEST_PERMISSION_CTA_LABEL: ' Go To Settings',
  // RCL Text Copies
  STEP_TEXT: 'Step',
  REVIEWS_TEXT: 'Reviews',
  WRITE_A_REVIEW_TEXT: 'Write A Review',
  ASK_A_QUESTION_TEXT: 'Ask a Question',
  CHECKOUT_TEXT: 'Checkout',
  CART_TEXT: 'Cart',
  PAYMENT_TEXT: 'Payment',
  ADDRESS_TEXT: 'Address',
  TOTAL_TEXT: 'Total',
  SAVED_TEXT: 'Saved',
  DELIVERY_TO_TEXT: 'Delivery to',
  VIEW_ALL_TEXT: 'View All',
  ENTER_PINCODE_TEXT: 'Enter Pincode',
  VIEW_ALL_REVIEWS_TEXT: 'View All Reviews',
  VIEW_ALL_QUESTIONS_TEXT: 'View All Questions',
  SELECT_BANK_TEXT: 'Select Bank',
  SELECT_YOUR_BANK_TEXT: 'Select your bank',
  ALL_BANKS_TEXT: 'All Banks',
  ORDER_ID_TEXT: 'Order ID',
  ORDER_STATUS_TEXT: 'Order status',
}
