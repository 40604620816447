import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {IProductSummaryProps} from './ProductSummary.interface'
import {ProductSummaryContainer} from './ProductSummary.styles'
import {
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useCallback, useMemo} from 'react'
import {useGenericActions} from '../../hooks'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function ProductSummary(props: IProductSummaryProps) {
  const {id = '', type = '', widgetData, layout} = props
  const {productSummary, ratingClickAction} = widgetData || {}
  const {
    actualPrice = 0,
    discountedPrice = 0,
    name = '',
    rating = '',
    reviewCount = '',
    taxesLabel = 'Incl of all taxes',
    unitsSold = '',
    discountText = '',
    discountTagImage = 'https://i.mscwlns.co/media/misc/others/Offer%20bg_4vdutz.png?tr=w-50',
    actualPriceLabel = '',
    discountedPriceLabel = '',
  } = productSummary || {}
  const {genericAction} = useGenericActions()

  const showDiscountedPrice = useMemo(() => {
    return actualPrice > discountedPrice
  }, [actualPrice, discountedPrice])

  const onRatingsClick = useCallback(() => {
    genericAction(interceptAndEnhanceAction(ratingClickAction, id))
  }, [genericAction, id, ratingClickAction])

  if (isEmpty(productSummary)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <ProductSummaryContainer>
        <div className="rating-and-price-details">
          <div
            className="rating-and-price-details-item"
            onClick={onRatingsClick}
          >
            <div className="ratings">
              <ReviewRatingStar
                value={Number(rating)}
                count={5}
                size={16}
                className="rating-container"
              />
              <Typography variant="tag-regular">
                {rating} ({reviewCount})
              </Typography>
            </div>
          </div>
          <div className="divider"></div>
          <div className="rating-and-price-details-item">
            <Typography variant="tag-regular">
              {unitsSold} units sold
            </Typography>
          </div>
        </div>
        <div className="pricing-and-name-details">
          <Typography variant="heading-sm-bold" customClassName="product-name">
            {name}
          </Typography>
          <div className="price">
            {showDiscountedPrice ? (
              <>
                <Typography variant="heading-sm-bold">
                  {discountedPriceLabel}
                </Typography>
                <Typography
                  variant="body-base-regular"
                  customClassName="stricked-price"
                >
                  {actualPriceLabel}
                </Typography>
                <div className="discount-tag">
                  <OptimizedImage
                    source={discountTagImage}
                    aspectHeight={'16'}
                    aspectWidth={'60'}
                  />
                  <Typography
                    customClassName="discount-tag-text"
                    variant={TypographyVariants.TAG_BOLD}
                  >
                    {discountText}
                  </Typography>
                </div>
              </>
            ) : (
              <Typography variant="heading-sm-bold">
                {actualPriceLabel}
              </Typography>
            )}
          </div>
          <Typography variant="tag-regular" customClassName="tax-label">
            {taxesLabel}
          </Typography>
        </div>
      </ProductSummaryContainer>
    </BaseWidgetComponent>
  )
}

export default ProductSummary
