import styled from 'styled-components'

export const RatingBarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 68%;
  margin-left: 10px;
  @media (max-width: 768px) {
    width: 60%;
    justify-content: center;
  }
`
