export const Size = {
  0: '0px',
  0.25: '1px',
  0.5: '2px',
  0.75: '4px',
  1: '8px',
  1.5: '12px',
  2: '16px',
  3: '24px',
  4: '32px',
  5: '40px',
  6: '48px',
  7: '56px',
  8: '64px',
  9: '72px',
  10: '80px',
  11: '88px',
  12: '96px',
  13: '104px',
  14: '112px',
  15: '120px',
  16: '128px',
  17: '136px',
  18: '144px',
  19: '152px',
  20: '160px',
  21: '168px',
  22: '176px',
  23: '184px',
  24: '192px',
  25: '200px',
  26: '208px',
  27: '216px',
  28: '224px',
  29: '232px',
  30: '240px',
  31: '248px',
  32: '256px',
  33: '264px',
  34: '272px',
  35: '280px',
  36: '288px',
  37: '296px',
  38: '304px',
  39: '312px',
  40: '320px',
  41: '328px',
  42: '336px',
  43: '344px',
  44: '352px',
  45: '360px',
}
