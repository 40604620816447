import styled from 'styled-components'

export const BottomSheetWrapper = styled.div<{
  bottomSheetHeight: number | null
}>`
  .modal {
    padding: 0px;
  }

  .modal-content {
    padding-top: var(--dls-size-16);
    max-width: 768px;

    @media screen and (min-width: 768px) {
      width: 768px;
    }
  }

  .product-preview-wrapper {
    height: ${({bottomSheetHeight}) =>
      `calc(${
        bottomSheetHeight ? `${bottomSheetHeight}px` : 'calc(100vh - 160px)'
      })`};
    overflow-y: scroll;
    width: 100%;

    @media screen and (min-width: 768px) {
      height: auto;
      max-height: ${({bottomSheetHeight}) =>
        `calc(${
          bottomSheetHeight ? `${bottomSheetHeight}px` : '100vh'
        } - 160px)`};
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .image-carousel-wrapper {
    margin-bottom: var(--dls-size-16);
  }
  .cta-wrapper {
    padding: var(--dls-size-8) var(--dls-size-16);
    position: relative;
    bottom: 0;
    z-index: 10;
    background-color: var(--dls-white-color);

    &[data-sticky='true'] {
      position: sticky;
      box-shadow: rgba(0, 0, 0, 0.333) 0px 18px 16px 10px;
    }
  }
`
