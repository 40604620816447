import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  height: auto;

  a {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
