import styled from 'styled-components'
import {DesktopCategoryPaletteCardProps} from './DesktopCategoryPaletteCard.interface'

export const DesktopCategoryPaletteContainer = styled.a`
  flex-direction: column;
  max-width: 220px;
  width: auto;
  flex: 1 1;
  border: 1px solid;
  border-color: white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 0;
  border-radius: 20px;
  cursor: pointer;
  background-color: var(--dls-white-color);
  overflow: hidden;
  text-decoration: none;
  color: var(--dls-black-color);
  &:hover {
    color: var(--dls-white-color);
    background-color: var(--dls-black-color);

    .chevron-icon path {
      fill: white;
    }
  }

  .content-container {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 12px;
    font-family: var(--dls-brand-font-family);
    font-style: normal;

    .categoryName {
      display: inline;
      margin-right: 20px;
    }
  }
  .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .image-container {
    width: 100%;
    height: 201px;
    display: flex;
    .card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
