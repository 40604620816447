import {InputSectionWrapper} from './styles'

export default function InputSection({
  label,
  id,
  type = 'text',
  placeholder,
  registerKey,
  error,
  register,
  errorText,
  isTextArea = false,
  maxLength,
  rows = 4,
  disabled = false,
}) {
  return (
    <InputSectionWrapper>
      <div className="input-section">
        <label className="input-label" htmlFor={id}>
          {label}
        </label>
        {isTextArea ? (
          <textarea
            className={`input-box ${error && 'input-box-error'}`}
            id={id}
            placeholder={placeholder}
            {...register}
            rows={rows}
            {...(maxLength && {maxLength})}
          />
        ) : (
          <input
            className={`input-box ${error && 'input-box-error'}`}
            type={type}
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            {...register}
            {...(maxLength && {maxLength})}
          />
        )}
        {error && (
          <span className="error-field">{error.message || errorText}</span>
        )}
      </div>
    </InputSectionWrapper>
  )
}
