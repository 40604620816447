import styled from 'styled-components'

export const DownloadAppCalloutContainer = styled.div`
  width: 100%;
  background-color: var(--dls-brand-primary-color-500);

  .callout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--dls-size-8);
    padding: 4px 0;
  }

  .callout-title {
    color: var(--dls-white-color);
  }

  .download-button {
    outline: 0;
    border: 0;
    background-color: var(--dls-white-color);
    border-radius: var(--dls-size-8);
    padding: var(--dls-size-8) var(--dls-size-8);
  }

  .send-link-container {
    padding: 20px;
  }

  .cashback-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--dls-size-8);
  }

  .cashback-text {
    color: var(--dls-white-color);
  }

  .close-button {
    outline: 0;
    border: 0;
    background-color: transparent;
    color: var(--dls-white-color);
  }

  .amount-container {
    display: flex;
    align-items: center;
    gap: var(--dls-size-4);
    margin-bottom: var(--dls-size-8);
  }

  .download-text {
    color: var(--dls-white-color);
  }

  .amount-text {
    color: var(--dls-warning-color-400);
  }

  .mobile-number-container {
    display: flex;
    justify-content: space-between;
    gap: var(--dls-size-4);
    margin-bottom: var(--dls-size-16);
  }

  .mobile-number-input {
    width: 100%;
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 6px;
    outline: 0;
    border: 0;
  }

  .send-link {
    outline: 0;
    border: 0;
    background-color: var(--dls-white-color);
    padding: var(--dls-size-8) var(--dls-size-16);
    border-radius: 6px;
    flex-shrink: 0;
    font-size: 16px;
  }

  .time-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .timer-icon {
    font-size: 24px;
    color: var(--dls-white-color);
    line-height: 1;
  }

  .timer-text {
    display: flex;
    align-items: center;
    gap: var(--dls-size-4);
  }

  .timer {
    color: var(--dls-warning-color-400);
    line-height: 1;
  }

  .time-running-out {
    color: var(--dls-warning-color-400);
  }
`
