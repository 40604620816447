import isEmpty from 'lodash/isEmpty'
import {StyledBlogCard} from './InfoCardsSlider.styles'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {useCallback} from 'react'
import {InfoCardProps} from './InfoCardsSlider.interface'

const InfoCard = (props: InfoCardProps) => {
  const {state, actions} = props
  const {
    title,
    content,
    image,
    width = 200,
    height = 200,
    aspectRatio = 1,
    buttonText,
    cta,
    slug,
  } = state || {}
  const {handleAction} = actions

  const handleClick = useCallback(
    (event) => {
      event.preventDefault()
      handleAction(cta)
    },
    [handleAction, cta]
  )

  if (isEmpty(state)) {
    return null
  }

  return (
    <StyledBlogCard href={slug} onClick={handleClick}>
      {image && (
        <div
          className="image-container"
          style={{height: `${height}px`, aspectRatio: aspectRatio}}
        >
          <OptimizedImage
            source={image}
            aspectWidth={width}
            aspectHeight={height}
            desktopViewWidth="THIRD"
            mobileViewWidth="THIRD"
            loading="lazy"
            customClassName="usp-image"
          />
        </div>
      )}
      <Typography
        variant={TypographyVariants.SUBHEADING_BOLD}
        customClassName="expectation-title"
      >
        {title}
      </Typography>

      <Typography
        variant={TypographyVariants.BODY_BASE_REGULAR}
        customClassName="expectation-content"
      >
        {content}
      </Typography>
      {buttonText && (
        <button type="button" className="learn-more brand-Primary-btn">
          <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
            {buttonText}
          </Typography>
        </button>
      )}
    </StyledBlogCard>
  )
}

export default InfoCard
