import React from 'react'

function Up({disabled}) {
  const stroke = disabled ? '#6C6C6C' : 'var(--category-primary-color)'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      fill="none"
      viewBox="0 0 16 22"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.03 21V3M15 9.071L8 2 1 9.071"
      ></path>
    </svg>
  )
}

export default Up
